import { offers } from "../../services/apiVariables"
import { offersType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getOfferList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        addQuery(query, offers.getOfferList)

        api(offers.getOfferList).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: offersType.updateOfferDetails, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            reject(true)

            Toast({ type: 'error', message })

            dispatch({ type: common.updateLoader, payload: false })

        })

    })

}

export const getSingleOfferList = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        offers.getSingleOffer.addParams = id;

        api(offers.getSingleOffer).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: offersType.updateSingleOfferDetails, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            reject(true)

            Toast({ type: 'error', message })

            dispatch({ type: common.updateLoader, payload: false })

        })

    })

}

export const createOffer = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api({ ...offers.createOffer, body }).then(({ data, message }) => {

            resolve(true)

            Toast({ type: 'success', message })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch((err) => {

            reject(true)
            let message=err.err.message;
            Toast({ type: 'error', message })

            dispatch({ type: common.updateLoader, payload: false })

        })

    })

}

export const deleteOffer = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        offers.deleteOffer.addParams = id;

        api(offers.deleteOffer).then(({ data, message }) => {

            resolve(true)

            Toast({ type: 'success', message })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            reject(true)

            Toast({ type: 'error', message })

            dispatch({ type: common.updateLoader, payload: false })

        })

    })

}

export const updateOffer = (id, body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        offers.updateOffer.addParams = id;

        api({ ...offers.updateOffer, body }).then(({ data, message }) => {

            resolve(true)

            Toast({ type: 'success', message })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            reject(true)

            Toast({ type: 'error', message })

            dispatch({ type: common.updateLoader, payload: false })

        })

    })

}