
import { authentication, commonUtilities } from "../../services/apiVariables"
import { AuthType, common } from "../../services/actionType"

import { history } from "../../helpers"

import { Toast } from "../../services/toast"

import { addQuery } from "../../services/helperFunctions"

export const handleLogin = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		api({ ...authentication.login, body }).then(({ data }) => {

			localStorage.setItem('AuthToken', data.token)

			dispatch({ type: AuthType.updateUserDetails, payload: data })

			resolve(data)

		}).catch(({ message }) => {

			Toast({ type: 'error', message })

			reject(message)

		})

	})

}


export const ForgotPasswordApi = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		api({ ...commonUtilities.forgotPassword, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			resolve(message)


		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(message)

		})

	})

}

export const VerifyPasswordChangeToken = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {


		api({ ...commonUtilities.verifyPasswordChangeToken, body }).then(({ data, message, status }) => {

			if (status == 200) {
				resolve(true)
			}


		}).catch(({ status, message }) => {

			if (status == 403) {
				history.push('/auth/login')
				reject()
			}

			reject(status)

		})

	})

}

export const UpdateResetPassword = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		api({ ...commonUtilities.updateResetPassword, body }).then(({ data, message }) => {

			resolve()

		}).catch((error) => {

			reject(error)

		})

	})

}

export const getAdminDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		api(commonUtilities.getAdminDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: common.updateUserDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const getAllCustomers = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, authentication.getAllCustomers)

		api(authentication.getAllCustomers).then(({ data }) => {

			dispatch({ type: AuthType.updateCustomerDetails, payload: data })

			resolve(data)

			dispatch({ type: common.updateLoader, payload: false })

		}).catch((message) => {

			Toast({ type: 'error', message })

			reject(message)

			dispatch({ type: common.updateLoader, payload: false })

		})

	})

}