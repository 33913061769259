import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import Logo from "../../assets/images/logo.png"
import UserLogo from "../../assets/images/user.png"
import { logout } from "../../services/utilities"
import { getAdminDetails } from "../../redux/actions/common"
import { LoaderWrapper } from "../../HOC"

class HeaderClass extends Component {

	handleNavbar = () => {



	}

	handleLogout = () => {

		logout()

	}

	render() {

		let { userDetails: { email, first_name, last_name } } = this.props

		return (
			<header>
				<nav className="navbar navbar-expand-xl navbar-light fixed-top sair-navbar">
					{/* <a
						id="navbar_toggle_btn"
						className="navbar-toggle-btn nav-link-hover"
						onClick={() => this.handleNavbar()}
					>
						<i className="ion ion-ios-menu"></i>
					</a> */}
					<a className="navbar-brand" >
						<img className="brand-img d-inline-block mr-5" src={Logo} alt="brand" />
					</a>

					<ul className="navbar-nav sair-navbar-content">

						<li className="nav-item">
							<a id="settings_toggle_btn" className="nav-link nav-link-hover"  ><i className="ion ion-ios-settings"></i></a>
						</li>

						<li className="nav-item dropdown dropdown-notifications">
							<a className="nav-link dropdown-toggle no-caret" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="ion ion-ios-notifications"></i>
								<span className="badge-wrap">
									<span className="badge badge-primary badge-indicator badge-indicator-sm badge-pill pulse">
									</span>
								</span>
							</a>

						</li>
						<li>
							<UncontrolledDropdown setActiveFromChild className="shadow-dropdown  cursor-pointer">
								<DropdownToggle tag="a" className="nav-link p-0 pointer">
									<div className="media">
										<div className="media-img-wrap">
											<div className="avatar">
												<img src={UserLogo} alt="user" className="avatar-img rounded-circle" />
											</div>
											{/* <span className="badge badge-success badge-indicator"></span> */}
										</div>
										<div className="media-body pl-1 d-flex align-self-center">
											<span>{first_name} {last_name}<i className="zmdi zmdi-chevron-down"></i></span>
										</div>
									</div>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem
										tag="a"
										href="/Profile"
									>My Profile</DropdownItem>
									<DropdownItem
										onClick={this.handleLogout}
									>Logout
                                     </DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</li>
					</ul>

				</nav>
			</header>
		)
	}
}


const mapStateToProps = state => {

	return {
		userDetails: state.common.userDetails,
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getAdminDetails

	}, dispatch)
};


let actions = ['getAdminDetails']

let component = LoaderWrapper(HeaderClass)({ actions })

export const Header = connect(mapStateToProps, mapDispatchToProps)(component)


