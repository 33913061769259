import React, { Component } from 'react'

import SimpleReactValidator from 'simple-react-validator';

import { ReactSelect } from "../common/ReactSelect";

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { createVendorActivityMapping, updateVendorActivityMapping, getVendorMappingList } from '../../redux/actions/vendorMapping';

import { LoaderWrapper } from '../../HOC';


class VendorMappingModalClass extends Component {

    state = {
        formDetails: {
            activity_id: "",
            vendor_id: "",
        },
        id: null,
        activityList: [],
        vendorList: [],
    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message ">{message}</span>,
            autoForceUpdate: this,
            messages: {}
        });

    }

    componentWillReceiveProps(props) {

        let { vendorMappingDropdownList, details } = props;

        let { Activity, Vendors } = vendorMappingDropdownList;

        let activityList = this.state.activityList.slice(0);

        let vendorList = this.state.vendorList.slice(0);

        let formDetails = Object.assign({}, this.state.formDetails);

        let id = null;

        if (details) {

            formDetails.activity_id = details.activity_id;

            formDetails.vendor_id = details.vendor_id;

            id = details.id;

        } else {

            formDetails = {
                activity_id: "",
                vendor_id: "",
            }

        }
        activityList = [];

        vendorList = [];

        Activity.map((item) => {

            activityList.push({
                label: item.title,
                value: item.id,
            })

        })
        Vendors.map((item) => {

            vendorList.push({
                label: item.company_name,
                value: item.id,
            })

        })

        this.setState({
            activityList,
            vendorList,
            formDetails,
            id,
        })

    }

    handleInputs = ({ value, name }) => {

        let formDetails = Object.assign({}, this.state.formDetails)

        formDetails[name] = value

        this.setState({
            formDetails
        })


    }

    handleSubmit = () => {

        if (this.validator.allValid()) {

            let { formDetails, id } = this.state;

            if (!id) {

                this.props.createVendorActivityMapping(formDetails).then(() => {

                    this.props.getVendorMappingList({ pageNo: 1 });

                    this.props.toggle();

                })

            } else {

                this.props.updateVendorActivityMapping(id, formDetails).then(() => {

                    this.props.getVendorMappingList({ pageNo: 1 });

                    this.props.toggle();

                })

            }

        } else {

            this.validator.showMessages();

        }
    }

    render() {

        let { toggle } = this.props;

        let { formDetails, activityList, vendorList } = this.state;

        let { activity_id, vendor_id } = formDetails;

        this.validator.purgeFields();

        return (
            <section className="side-slider h-100">

                <div className="slider-header p-4">
                    <div className="d-flex align-items-center">
                        <h5 className="sair-sec-title">Add Vendor Activity Mapping</h5>
                        <div className="ml-auto">
                            <i
                                className="icon-close font-md px-2 cursor-pointer"
                                onClick={toggle}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="slider-body p-4">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Activity</label>
                            <ReactSelect
                                isMulti={false}
                                options={activityList}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="activity_id"
                                value={activity_id}
                                disabled={false}
                            />
                            {this.validator.message('Activity', activity_id, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Vendors</label>
                            <ReactSelect
                                isMulti={false}
                                options={vendorList}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="vendor_id"
                                value={vendor_id}
                                disabled={false}
                            />
                            {this.validator.message('Vendors', vendor_id, 'required')}
                        </div>
                    </div>
                    <hr />
                    <button
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                    >Submit</button>
                </div>

            </section>
        )
    }
}

const mapStateToProps = state => ({
    vendorMappingDropdownList: state.vendorMapping.vendorMappingDropdownList
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getVendorMappingList,
        createVendorActivityMapping,
        updateVendorActivityMapping
    }, dispatch)
};

let component = VendorMappingModalClass

export const VendorMappingModal = connect(mapStateToProps, mapDispatchToProps)(component)