import React, { Component } from 'react'

export class Pagination extends Component {

    state={
        gotoPage:1
    }
    handlePagination = (direction) => {

        try {

            let { page = 1 } = this.props.pageMeta || {}

            let pageNumber = eval(`${page} ${direction} ${1}`)

            this.props.handleChange(pageNumber)

        }

        catch (err) {
            console.log(err)
        }

    }
    handlePageChange = (name,value) => {
            try {
                let { page = 1 } = this.props.pageMeta || {}
                this.setState({"gotoPage":value})
                if(value){
                    if(this.timeout) clearTimeout(this.timeout);
                    this.timeout  =  setTimeout(() => this.props.handleChange(value), 1500); 
                }
            }
            catch (err) {
                console.log(err)
            }
    }
    render() {
        const {isLoading} =this.props;
        let { page = 1, totalPages = 10 } = this.props.pageMeta || {}

        if (totalPages <= 1) {
            return ''
        }

        return (
            <>


                <div className="d-flex text-center w-100 justify-content-center">
                    <p className="m-0 d-flex align-items-center font-sm">

                        <button
                            className="btn btn-effect"
                            disabled={page == 1 || isLoading}
                            onClick={e => this.handlePagination('-')}
                        >
                            <i class="ion ion-ios-arrow-round-back"

                            ></i>


                        </button>

                        <span className="pb-1">Page {page} of {totalPages}</span>
                        
                        <button
                            className="btn btn-effect"
                            disabled={page == totalPages || isLoading}
                            onClick={e => this.handlePagination('+')}
                        >
                            <i class="ion ion-ios-arrow-round-forward"></i>

                        </button>
                        Goto <input className="form-control" style={{'width':'50%'}} value={this.state.gotoPage} onChange={(e)=>this.handlePageChange('goto',e.target.value)} type="text" disabled={isLoading} />
                    </p>
                </div >
            </>
        )
    }
}
