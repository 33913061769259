import { vendor } from "../../services/apiVariables"
import { vendorType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getVendorMappingList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        addQuery(query, vendor.getVendorMappingList)

        api(vendor.getVendorMappingList).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: vendorType.updateVendorMappingDetails, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const createVendorActivityMapping = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api({ ...vendor.createVendorActivityMapping, body }).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(true)

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const updateVendorActivityMapping = (id, body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        vendor.updateVendorActivityMapping.addParams = id;

        api({ ...vendor.updateVendorActivityMapping, body }).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(true)

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const deleteVendorActivityMapping = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        vendor.deleteVendorActivityMapping.addParams = id;

        api(vendor.deleteVendorActivityMapping).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(true)

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const getVendorMappingDropdownList = () => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api(vendor.getVendorMappingDropdown).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: vendorType.updateVendorMappingDropdown, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}