export const themeOptionList = [
    {id:1, label: "Must Do", value: "mustDo"},
    {id:2, label: "Adventure", value: "adventure"},
    {id:3, label: "Romance", value: "romance"},
    {id:4, label: "Family", value: "family"},
    {id:5, label: "Friends", value: "friends"},
    {id:6, label: "Religious", value: "religious"}
];

export const interLocationVehicleList = [
    {id:1, label: "Flight", value: "1"},
    {id:2, label: "Train", value: "2"},
    {id:3, label: "AC Bus", value: "3"},
    {id:4, label: "Sedan", value: "4"},
];

export const stayOptionsList = [
    { id: 1, label: '3* Hotel', value: '3*Hotel'},
    { id: 2, label: '4* Hotel', value: '4*Hotel'},
    { id: 3, label: '5* Hotel/Resort', value: '5*Hotel/Resort'},
    { id: 4, label: 'Oyo style clean budget hotel', value: 'Oyo style clean budget hotel' },
    { id: 5, label: 'Home Stay', value: 'Home Stay' },
    { id: 6, label: 'Luxury Homestay', value: 'Luxury homestay'},
    { id: 7, label: 'Hostel / guesthouse', value: 'Hostel / guesthouse'},
    { id: 8, label: 'Heritage / Specialty Stay ', value: 'Heritage /Specialty Stay'}
];

export const mealOptionsList = [
    {id:1, label: 'Dinner Only', value: 'Dinner Only'},
    {id:2, label: 'Lunch & Dinner Both', value: 'Lunch & Dinner Both'}
];