import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import moment from "moment";

import { UPDATE_INVENTORY_DETAILS } from "../../graphql/mutation/updateInventoryListDetails";

export const CodeRun = () => {
  const [isBtnDisabld, setIsBtnDisabled] = useState(false);

  const [updateInventoryListDetails] = useMutation(UPDATE_INVENTORY_DETAILS, {
    onCompleted: () => {
      onMutationComplete();
    },
    onError: () => {
      onMutationError();
    },
  });

  const onMutationComplete = (data) => {
    console.log("onMutationComplete", data);
    const date = moment().add(5, "minutes").toDate();
    Cookies.set("ak-code-run", "true", { expires: date });
    setIsBtnDisabled(true);
  };

  const onMutationError = (error) => {
    console.log("onMutationError", error);
    setIsBtnDisabled(false);
  };

  const onRefreshStatus = () => {
    const codeRunValue = Cookies.get("ak-code-run");
    console.log("codeRunValue", codeRunValue);
    setIsBtnDisabled(!!codeRunValue);
  };

  useEffect(() => {
    onRefreshStatus();
  }, []);

  const onRunMutation = () => {
    updateInventoryListDetails();
  };

  return (
    <div>
      <div className="sair-pg-header">
        <h4 className="sair-pg-title align-self-center">
          <span className="pg-title-icon">
            <span class="feather-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                className="feather feather-align-left"
              >
                <line x1="17" y1="10" x2="3" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="17" y1="18" x2="3" y2="18"></line>
              </svg>
            </span>
          </span>
          Code Run
        </h4>
      </div>

      <section className="sair-sec-wrapper">
        <div className="row">
          <div className="col-md-8">
            <pre>
              <code>
                {`
                mutation {
                  updateInventoryListDetails(doNext: true)
                }
                `}
              </code>
            </pre>
          </div>
          <div className="col-md-4">
            <button
              disabled={isBtnDisabld}
              className="btn btn-primary btn-sm"
              onClick={onRunMutation}
            >
              Run Mutation
            </button>
            <button
              className="btn btn-primary btn-sm ml-2"
              onClick={onRefreshStatus}
            >
              Refresh Status
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
