import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import SimpleReactValidator from 'simple-react-validator';
import { LoaderWrapper } from "../../HOC"
import { ContentMainHeader, ReactSelect, FileUpload } from "../../component/common"
import { createNotificationRequest} from "../../redux/actions/notification"
import {addImage} from '../../redux/actions/reseller'


export class createNotificationClass extends Component {

	state = {
		notificationData: {
			title: '',
			body: '',
			link: '',
			userGroup: '',
			imgLinks:[]
		},
		userGroupList:[{
			label:"All",
			value:"all"
		}]
	}

	componentWillMount() {

		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
		this.validators = new SimpleReactValidator({
			element: message => {

				let messages = message.split('/');
				return (
					<span className="error-message font-md">
						{`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
					</span>
				)
			},

		});

	}
	
	handleInputs = ({ target: { value, name } }) => {

		let notificationData = Object.assign({}, this.state.notificationData)

		notificationData[name] = value

		this.setState({
			notificationData
		})
	}

	handleSelectInputs = ({ value, name }) => {
		let notificationData = Object.assign({}, this.state.notificationData)
		notificationData[name] = value
		this.setState({
			notificationData
		})
	}

	handleSubmit = () => {

		if (this.validator.allValid()) {
			
			let { notificationData } = this.state
			// if(notificationData.imgLinks && notificationData.imgLinks.length ===0){
			// 	return false;
			// }
			let body = {
					"message": {
						"topic": notificationData.userGroup[0],
						"notification": {
							"title": notificationData.title,
							"body": notificationData.body,
						},
						"webpush": {
							"headers": {
								"Urgency": "high"
							},
							"notification": {
								"body": notificationData.body,
								"icon":"https://sair-images.s3.ap-south-1.amazonaws.com/reseller/7609a58301430d407387apnaklub-green-logo-vertical.png",
								"requireInteraction": "true"
							},
							"fcm_options": {
								  "link": notificationData.link
							}
						}
					}
				}
				if(notificationData.imgLinks && notificationData.imgLinks.length > 0){
					body['message']['notification']["image"]=notificationData.imgLinks[0];
					body['message']['webpush']['notification']["image"]=notificationData.imgLinks[0];
				}
			let { match: { params: { id } } } = this.props;
			this.props.createNotificationRequest(body).then(() => {
				let { history } = this.props
				history.push('/notification/list')
			})
		} else {

			this.validator.showMessages();
		}
	}
	ImagePreview = (key, event) => {
		let files = event.target.files[0]
		let notificationData = Object.assign({}, this.state.notificationData)
		let formData = new FormData()
		formData.append("resellerImage", files)
		if (typeof (files) != "undefined") {
			var reader = new FileReader();
			reader.readAsDataURL(files);
			let vm=this;
			reader.onload = function (e) {
				var image = new Image();
				image.src = e.target.result;
				image.onload = function () {
					var height = this.height;
					var width = this.width;
					if (width > 512 || height > 256) {
						vm.setState({"fileErr":"At max you can upload a 512*256 photo size."});
						return false;
					}else{
						vm.setState({"fileErr":""});
						vm.props.addImage(formData).then((data) => {
							notificationData.imgLinks[0] = data.imageUrl
							vm.setState({notificationData})
						})
						return true;
					}
				};
			}
		}
	  }
	render() {

		let { notificationData,id,userGroupList } = this.state

		let {
			title,
			body,
			link,
			userGroup,
			imgLinks
		} = notificationData
		let tempFile;
		return (
			<>

				<ContentMainHeader title={`${id ? "Edit" : "Add"} Notification`}></ContentMainHeader>

				<section className="sair-sec-wrapper">
					<div className="row">

						<div className="col-md-6 form-group">
							<label>Title</label>
							<input
								className={`form-control ${this.validator.message('title', title, 'required') ? 'inValid' : ''}`} id="business_details"
								placeholder=""
								name="title"
								value={title}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('Title', title, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Body</label>
							<textarea
								className={`form-control ${this.validator.message('body', body, 'required') ? 'inValid' : ''}`} id="company_name"
								placeholder=""
								name="body"
								value={body}
								type="textarea"
								onChange={this.handleInputs}

							/>
							{this.validator.message('Body', body, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label><b>Image</b><span className="error-message">* Max you can upload a 512*256 photo size.</span></label>
							<FileUpload
							fileUpload={this.fileUpload}
							ImagePreview={(e) => this.ImagePreview("brandImage", e)}
							filename={imgLinks && imgLinks[0]? imgLinks[0] : ""}
							tempFile={tempFile}
							/>
							<img src={imgLinks && imgLinks[0]} style={{ width: "200px", marginTop: "15px" }} />
							{/* {this.validator.message('image', imgLinks, 'required')} */}
							{this.state.fileErr && <p className="error-message">{this.state.fileErr}</p>}
						</div>
						<div className="col-md-6 form-group">
							<label>App Link</label>
							<input
								className={`form-control ${this.validator.message('link', link, 'required') ? 'inValid' : ''}`} id="company_website"
								placeholder=""
								name="link"
								value={link}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('App link', link, 'required')}
						</div>

					
						<div className="col-md-6 form-group ">
							<label >User Group</label>
							<ReactSelect
								isMulti={true}
								options={userGroupList}
								handleChange={(keyName, data) => {
									this.handleSelectInputs({ name: keyName, value: data })
								}}
								keyName="userGroup"
								value={userGroup}
								disabled={false}
							/>
							{this.validator.message('User Group', userGroup, 'required')}
						</div>

					</div>
					
					<button
						className="btn btn-primary mt-3"
						onClick={() => this.handleSubmit()}
					>
						Submit
					</button>

				</section>

			</>
		)
	}
}


const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createNotificationRequest,
		addImage,
	}, dispatch)
};

let component =createNotificationClass;



export const CreateNotification = connect(null, mapDispatchToProps)(component)
