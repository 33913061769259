import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal } from "../../component/common"
import { VehicleTypeModal} from "../../component/master"
import SimpleReactValidator from 'simple-react-validator';
import { getVehicleTypeDetails, deleteVehicleType } from "../../redux/actions/master"
import { getSNo } from 	"../../services/helperFunctions"
import _ from 'lodash'

import { LoaderWrapper } from "../../HOC"


class VehicleTypeClass extends Component {
	state = {
		headerDetails: [
			{
				label: 'S.No',
			},
			{
				label: 'Vehicle Type',
			},
			{
				label: 'Status',
			},
			{
				label: 'Action'
			},

		],
		filter: [

			{
				label: 'Active',
				value: 1
			},
			{
				label: 'Deactive',
				value: 4
			}

		],
		vehicleTypeList: {

		},
		selectedFilter: 'Active',
		vehicleTypeDetails: {
			title: '',

		},
		isError: false,
		isOpen: false,
		isEdit: false,
		ConfirmModalOpenStatus: false,
		id: ''
	}


	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.setState({
			vehicleTypeList: this.props.vehicleTypeList
		})

	}


	requestStatus = (status) => {

		if (status === 1) {
			return <Badge
				type={1}
				label={"Active"}
			/>
		} else if (status === 2) {
			return <Badge
				type={2}
				label={"Deactive"}
			/>
		}

	}

	handleFilter = (query, value, name) => {

		if (name == "filter") {
			this.setState({
				selectedFilter: query
			})

			query = {
				pageNo: 1,
				filter: query
			}
		}
		this.props.getVehicleTypeDetails(query)
	}


	handleAdd = () => {

		let temp = {
			name: ''
		}
		this.setState({
			isOpen: true,
			vehicleTypeDetails: temp
		})
	}

	handleEdit = (index) => {

		let { vehicleTypeLists } = this.props

		// let { list = [] } = vehicleTypeList

		let vehicleTypeDetails = vehicleTypeLists[index]

		this.setState({
			isEdit: true,
			vehicleTypeDetails,
			currentIndex: index

		})

		this.toggle("isOpen")

	}

	toggle = item => {
		this.setState(prevState => ({
			[item]: !prevState[item]
		}));
	}

	handleModal = () => {

		let temp = {
			name: '',
		}

		this.setState({
			isError: false,
			isOpen: false,
			isEdit: false,
			vehicleTypeDetails: temp

		})
	}

	handleDeleteToggle = (id) => {

		this.setState({
			id
		})

		this.toggleDelete("ConfirmModalOpenStatus")

	}


	handleDelete = () => {
		let { id } = this.state

		this.props.deleteVehicleType(id).then(() => {

			this.props.getVehicleTypeDetails()

			this.toggleDelete("ConfirmModalOpenStatus")

		})

	}

	toggleDelete = (name) => {
		this.setState({
			[name]: !this.state[name]
		})
	}



	render() {

		let { headerDetails, filter, selectedFilter, vehicleTypeDetails, isError, isOpen, isEdit, ConfirmModalOpenStatus } = this.state
		let { vehicleTypeLists } = [];
		vehicleTypeLists= _.filter(this.props.vehicleTypeLists,(vehicleType)=>{
			return vehicleType.status===1;
		})
		let IsEmpty = vehicleTypeLists.length > 0

		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Vehicle Type
					</h4>
					<div className="col-auto ">
						<button className="btn btn-primary btn-sm"
							onClick={() => this.handleAdd()}
						>Add Vehicle Type</button>
					</div>
				</div>
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<h5 className="sair-sec-title align-self-center">Vehicle Type List</h5>
								<div className="ml-auto d-flex align-items-center">
								

									<div className="search-box position-relative  ml-3">

										
									</div>
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									queryHandler={(query) => this.handleFilter(query)}
								>
									{vehicleTypeLists.map((item, index) => {
										let { id, title, status } = item

										return (
											<tr
												key={index}  >
												<td>{index + 1}</td>
												<td>{title}</td>
												<td>{this.requestStatus(status)}</td>
												<td>
													<a className="mr-25"
														onClick={e => this.handleEdit(index)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"
														onClick={e => this.handleDeleteToggle(id)}
													></i></a>
												</td>

											</tr>

										)

									})}


								</TableWrapper>
							</div>
						</div>
					</div>
				</section>



				<SliderOverlay isOpen={isOpen} toggle={this.handleModal} size='30%'>
					<div className="side-slider h-100 position-relative">
						<div className="h-100 flex-with-center">
							<VehicleTypeModal
								isError={isError}
								toggle={this.handleModal}
								isEdit={isEdit}
								modalDetails={vehicleTypeDetails}
							/>
						</div>

					</div>
				</SliderOverlay>

				<ConfirmModal
					keyName="ConfirmModalOpenStatus"
					toggle={this.toggle}
					isOpen={ConfirmModalOpenStatus}
					handleSubmit={() => this.handleDelete()}
					submitText='Delete'
				>
					<h5>
						Are you sure want to delete vehicle type?
					</h5>
				</ConfirmModal>

			</div>
		)
	}
}

const mapStateToProps = state => {

	return {
		vehicleTypeLists: state.master.vehicleTypeLists
	}

};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getVehicleTypeDetails,
		deleteVehicleType
	}, dispatch)
};

let actions = ['getVehicleTypeDetails']

let component = LoaderWrapper(VehicleTypeClass)({ actions })


export const VehicleType = connect(mapStateToProps, mapDispatchToProps)(component)