
// listing page for advertiseement type one

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { getAdvertisementTypeOneListDetails , deleteAdvertisementTypeOne} from "../../redux/actions/advertisementtypeone"


import { TableWrapper, ConfirmModal,Badge, Filters, } from "../../component/common/"

import { getSNo } from "../../services/helperFunctions"


import { LoaderWrapper } from "../../HOC"

class advertisementTypeOneClass extends Component{
    
    state = {
        headerDetails: [
            {
                label: 'S.No',
            },
            {
                label: 'Name'
            },
            {
                label: 'Image',
            },
            {
                label: 'Status',
            },
            {
                label: 'Action'
            },
            
        ],
        filter: [
            
            
            {
                label: 'Active',
                value: 1
            },
            {
                label: 'Deactive',
                value: 4
            }
            
        ],
        selectedFilter: 'Active',
        deleteIndex: null,
        isOpen: false,
        ConfirmModalOpenStatus: false    
        
    }
    
    
    componentDidMount() {
        this.setState({
            advertisementTypeOneDetails: this.props.advertisementTypeOneDetails
        })
        
    }
    
    
    requestStatus = (status) => {
        
        if (status === 1) {
            return <Badge
            type={1}
            label={"Active"}
            />
        } else if (status === 2) {
            return <Badge
            type={2}
            label={"Deactive"}
            />
        }
        
    }
    
    handleFilter = (query, value, name) => {
        
        if (name == "filter") {
            this.setState({
                selectedFilter: query
            })
            
            query = {
                page: 1,
                filter: query
            }
        }
        
        this.props.getAdvertisementTypeOneListDetails(query)
    }
    
    
    handleDeleteToggle = (deleteIndex) => {     
        this.setState({
            deleteIndex
        })
        this.toggleDelete("ConfirmModalOpenStatus")
        
    }
    
    
    
    
    toggleDelete = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }
    
    toggle = item => {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
    }
    
    handleEdit = (id) => {
        let { history } = this.props
        history.push(`/advertisement_type_one/edit/${id}`)
    }

    handleDelete = () => {
        let { deleteIndex } = this.state
        this.props.deleteAdvertisementTypeOne(deleteIndex).then(() => {
            
            this.props.getAdvertisementTypeOneListDetails()
            
            this.toggleDelete("ConfirmModalOpenStatus")
            
        })
    }
    
    handleNavigation = () => {
        let { history } = this.props
        history.push('/advertisement_type_one/create')
    }
    
    render(){
        
        let { headerDetails , filter, selectedFilter, ConfirmModalOpenStatus} = this.state
        let { advertisementTypeOneDetails   } = this.props;
     
         let IsEmpty =(advertisementTypeOneDetails.list.results.length) > 0
        
        return (
            <div>

            <div className="sair-pg-header">
              <h4 className="sair-pg-title align-self-center">
                <span className="pg-title-icon">
                  <span className="feather-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" className="feather feather-align-left">
                      <line x1="17" y1="10" x2="3" y2="10"></line>
                      <line x1="21" y1="6" x2="3" y2="6"></line>
                      <line x1="21" y1="14" x2="3" y2="14"></line>
                      <line x1="17" y1="18" x2="3" y2="18"></line>
                    </svg>
                  </span>
                </span> Advertisement Landing Page
              </h4>
          
              <div className="col-auto ">
                <button className="btn btn-primary btn-sm" onClick={()=> this.handleNavigation()}
                  >Add an Advertisement</button>
              </div>
            </div>
          
            <section className="sair-sec-wrapper">
              <div className="row">
                <div className="col-sm">
                  <div className="row no-gutters">
                    <h5 className="sair-sec-title align-self-center">Advertisement Landing Page List</h5>
                    <div className="ml-auto d-flex align-items-center">
          
                      <div className="search-box position-relative  ml-3">
          
                        <Filters queryHandler={(query)=> this.handleFilter(query)}
                          isDropDownFilter={false}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="table-wrap mt-3">
                    <TableWrapper headerDetails={headerDetails} isEmpty={IsEmpty} pageMeta={advertisementTypeOneDetails.pageMeta}
                      queryHandler={(query)=> this.handleFilter(query)}
                      >
                      {advertisementTypeOneDetails.list.results.map((item, index) => {
                      let { id, name, AdvertisementTypeOneImages, status} = item
          
                      return (
                      <tr key={index}>
                        <td>{getSNo(advertisementTypeOneDetails.pageMeta, index)}</td>
                        <td>{name}</td>
                        <td>
                          {AdvertisementTypeOneImages ?
                          <img src={AdvertisementTypeOneImages.imageUrl} style={{ width: '100px' }} />: "-"}
                        </td>
          
          
                        <td>{this.requestStatus(status)}</td>
                        <td>
                          <a className="mr-25" onClick={e=> this.handleEdit(id)}>
                            <i className="icon-pencil  text-primary cursor-pointer"></i>
                          </a>
                          <a>
                            <i className="icon-trash txt-danger text-primary cursor-pointer" onClick={e=>
                              this.handleDeleteToggle(id)}
                              ></i></a>
                        </td>
          
                      </tr>
          
                      )
          
                      })}
          
                    </TableWrapper>
                  </div>
                </div>
              </div>
            </section>
          
            <ConfirmModal keyName="ConfirmModalOpenStatus" toggle={this.toggle} isOpen={ConfirmModalOpenStatus} handleSubmit={()=>
              this.handleDelete()}
              submitText='Delete'
              >
              <h5>
                Are you sure want to delete Advetisement?
              </h5>
            </ConfirmModal>
          
          </div>
            );
                
            }
            
        }
        
const mapStateToProps = state => {
    
    return {
        advertisementTypeOneDetails: state.advertisementtypeone.advertisementTypeOneLists                
    }
};
        
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAdvertisementTypeOneListDetails,
        deleteAdvertisementTypeOne
        
    }, dispatch)
};
        
let actions = ['getAdvertisementTypeOneListDetails']

let component = LoaderWrapper(advertisementTypeOneClass)({ actions })

export const AdvertisementTypeOne = connect(mapStateToProps, mapDispatchToProps)(component)
