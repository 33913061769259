
import { cloneDeep, filter, uniq, map, keyBy, groupBy, get } from "lodash"
import moment from "moment"

export const _ = {
    cloneDeep, filter, uniq, map, keyBy, groupBy, get
}

export const isEmptyObject = (data) => {

    return Object.keys(data).length != 0

}

export const getFormatDate = (date) => {

    return moment(date).format('DD MMM,hh:mm a')

}
export const getFormatDateOnly = (date) => {

    return moment(date).format('DD MMM YYYY')

}

export const scrollTop = (id = 'content-page', selector = 'getElementById') => {

    var contentPageEle = document[selector](id)

    if (selector != 'getElementById') {
        contentPageEle = contentPageEle[0]
    }


    contentPageEle && contentPageEle.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export const addQuery = (dataObject, apiObject) => {
    if (!dataObject) {
        return ''
    }
    let keys = ['pageNo', 'chunk', 'search', 'sort', 'id', 'tag_id', 'category', 'filter', 'target', 'url_id', 'sortBy', 'sortOrder', 'withPosition', 'searchText', 'searchByContact', 'searchByEmail', 'searchByStatus', 'searchByOrder', 'searchByOrderid', 'sellerId', 'approvalStatus', 'brand', 'seller_id', 'percentage', 'awb', 'purchaseOrderId', 'userId', 'user_id', 'wholesale', 'searchByWarehouse', 'fromDate', 'toDate', 'sellmore', 'bareilly', 'autopush'];

    keys.map((key) => {

        if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != 'object') {
            if (apiObject.query.hasOwnProperty(key)) {
                apiObject.addQuery = { key, payload: dataObject[key] }
            }


        } else {

            dataObject[key] && Object.keys(dataObject[key]).map((keyName) => {
                if (apiObject.query.hasOwnProperty(keyName)) {
                    apiObject.addQuery = { key: keyName, payload: dataObject[key][keyName] }
                }
            })
        }
    })
}
export const removeQuery = (apiObject) => {
    apiObject.removeQuery = { key: "", payload: [] }
}
export const generateQuery = (query) => {

    let url = ''

    if (query.hasOwnProperty('url_id')) {
        url = `/${query.url_id}`
    }


    return url + Object.keys(query).reduce((accumulator, key, index, array) => {

        if (query[key] == '' || query[key] == null || key == 'url_id') {
            return accumulator
        } else {
            return accumulator + `${index != 0 ? '&' : '?'}${key}=${query[key]}`
        }

    }, '')


}

export const getSNo = ({ page, limit }, index) => {

    return parseInt((page - 1) * limit) + index + 1

}


export const hidePastDate = (current) => {
    return current.isAfter(moment().subtract(1, 'day'))
}

export const IsAfter = (current, nextDate) => {
    if (!nextDate) {
        return true
    }
    return current.isAfter(nextDate)
}

export const IsBefore = (current, nextDate) => {
    if (!nextDate) {
        return true
    }
    return !current.isBefore(nextDate, 'day')
}

export const validateEndDate = (val, params) => {

    let startDate = moment(params[0].replace(/@/g, ":")).add(15, 'minutes')

    let endDate = moment(val).utc().format('MM-DD-YYYY HH:mm:ss')

    let formatStartDate = moment(startDate).utc().format('MM-DD-YYYY HH:mm:ss')

    return !moment(endDate).isBefore(formatStartDate, ['day', 'month', 'year', 'hour', 'minute', 'second'])

}
export const validateEndDatePast = (val, params) => {

    let startDate = moment(params[0].replace(/@/g, ":")).add(15, 'minutes')

    let endDate = moment(val).utc().format('MM-DD-YYYY HH:mm:ss')

    let formatStartDate = moment(startDate).utc().format('MM-DD-YYYY HH:mm:ss')

    return !moment(formatStartDate).isAfter(endDate, ['day', 'month', 'year', 'hour', 'minute', 'second'])

}
