import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { ReactSelect } from '../../component/common';
import SimpleReactValidator from 'simple-react-validator';
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal } from "../../component/common"
import { MappingActivityModal } from "../../component/activity"
import { createTravelExperienceGroup, getTravelExperienceGroupListDetails, editTravelExperienceGroup, deleteTravelExperienceGroup } from "../../redux/actions/master"
import { getSNo } from "../../services/helperFunctions"
import {website_url} from '../../config/config'
import {
	getCategoryMappingListDetails,
	getCategoryMappingDropDown,
	deleteActivityMapping
} from "../../redux/actions/activity"

import { LoaderWrapper } from "../../HOC"

class PartialPaymentsLinkClass extends Component {

	state = {
        payment_link:"",
		id: '',
		activityList: [],
		errMessage:false,
		details: {
			activity_id: 0,
			referal_link:""
		},
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		let { mappingActivityLists, categoryMappingDropDownLists: { Activity = [], Category = [], City = [] } } = this.props

		let { activityList} = this.state

		Activity.forEach(item => {
			activityList.push({
				label: item.title,
				value: item.id
			})

		})
		this.setState({
			mappingActivityLists
		})

	}
	
    handleInputs = ({ value, name }) => {
        let details = Object.assign({}, this.state.details);
        details[name] = value;
        this.setState({
            details
        })
	}
	getQueryParams = ( params, url ) => {
		let href = url;
		//this expression is to get the query strings
		let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
		let queryString = reg.exec(href);
		return queryString ? queryString[1] : null;
	};
	copyToClipBoard =() => {
		/* Get the text field */
		var copyText = document.getElementById("payment_link");
		
		/* Select the text field */
		copyText.select();
		copyText.setSelectionRange(0, 99999); /*For mobile devices*/
		
		/* Copy the text inside the text field */
		document.execCommand("copy");
	}
    createPaymentLink = () =>{
		let tap_s=null;
		let tap_a=null;
		if(this.state.details.referal_link !=null){
			tap_s = this.getQueryParams('tap_s',this.state.details.referal_link );
			tap_a = this.getQueryParams('tap_a',this.state.details.referal_link );
		}
		let activityId= typeof(parseInt(this.state.details.activity_id)) === 'number' && this.state.details.activity_id > 0 ? this.state.details.activity_id : false; 
		if(activityId){
			this.setState({errMessage:false})
				this.setState({errMessage:false})
				let query={
					"partialPayment":true
				}
				if(tap_s!=null && tap_a!==null ){
					query["tap_s"]=tap_s
					query["tap_a"]=tap_a
				}
				query=btoa(JSON.stringify(query));
				let link ="";
				if(tap_s!=null && tap_a!==null ){
					link = website_url+"activity/"+activityId+"?query="+query+"&tap_s="+tap_s+"&tap_a="+tap_a;
				}else{
					link = website_url+"activity/"+activityId+"?query="+query	
				}
				this.setState({payment_link:link})
		}else{
			this.setState({
				"errMessage":"Not able to create link due to missing activity"
			})
		}
    }
	render() {

		let {activityList,details , payment_link , errMessage} = this.state
        let {activity_id,firstInstallmentPercent,secondInstallmentPercent,thirdInstallmentPercent} = details

		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Partial Payment Link
					</h4>
				</div>
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="table-wrap mt-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label>Activity</label>
                                    <ReactSelect
                                        isMulti={false}
                                        options={activityList}
                                        handleChange={(keyName, data) => {
                                            this.handleInputs({ name: keyName, value: data })
                                        }}
                                        keyName="activity_id"
                                        value={activity_id}
                                        disabled={false}
                                    />
                                    {this.validator.message('Activity', activity_id, 'required')}
                                </div>
                            </div>
							<div className="row">
                                <div className="col-md-12 form-group">
                                    <label>Reseller Referal Link</label>
									<input type="text" onChange={(e)=> this.handleInputs({value: e.target.value,name:"referal_link"})} className="form-control"/>
                                    
                                </div>
                            </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-3 form-group">
                                <button
                                    className="btn btn-primary"
                                    onClick={this.createPaymentLink}
                                >Create Payment Link
                                </button>
                            </div>
							<div className="col-md-8 form-group">
								{errMessage && <p className="text-danger">{errMessage}</p> }
							</div>
						</div>
						<div className="row">
							{payment_link && 
								<>
									<div className="col-md-9 form-group">
										<label className="text-center">Partial Payment Link</label>
										<input id="payment_link" onChange={()=>{}} type="text" className="form-control" value={payment_link} />
										
									</div>
									<div className="col-md-3 form-group">
										<button  className="btn btn-primary m-top" onClick={()=>this.copyToClipBoard()}>Copy Payment Link</button>
									</div>
								</>
							}
						</div>
						</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

const mapStateToProps = state => {

	return {
		mappingActivityLists: state.activity.mappingActivityLists,
		categoryMappingDropDownLists: state.activity.categoryMappingDropDownLists

	}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getCategoryMappingListDetails,
		getCategoryMappingDropDown,
		deleteActivityMapping,
	}, dispatch)
};

let actions = ['getCategoryMappingListDetails', 'getCategoryMappingDropDown']

let component = LoaderWrapper(PartialPaymentsLinkClass)({ actions })



export const PartialPaymentsLink = connect(mapStateToProps, mapDispatchToProps)(component)

