import React, { Component } from 'react'

export class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="row">
					<div className="col-md-6 col-sm-12">
          
					</div>
					<div className="col-md-6 col-sm-12 text-right">
          <p>Developed by<a href="https://www.sair.in/" target="_blank"><b>Sair</b></a> &copy; {new Date().getFullYear()}</p>
						{/* <p className="d-inline-block">Follow us</p>
						<a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-facebook"></i></span></a>
						<a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-twitter"></i></span></a>
						<a href="#" className="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span className="btn-icon-wrap"><i className="fa fa-google-plus"></i></span></a> */}
					</div>
				</div>
			</footer>
		)
	}
}
