import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import {Link} from 'react-router-dom';
import { ReactSelect } from "../../component/common/ReactSelect";
import SimpleReactValidator from 'simple-react-validator';
import {editCategoryDetailsById ,getCategoryNames} from "../../redux/actions/reseller"

import { NormalInput} from "../../component/common"
class AddCategoryPositionClass extends Component {

	state = {
        tempFile: '',
        allCategoryList: [],
        categoryDetails:{
            _id:1,
            position:""
        }
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.props.getCategoryNames().then((data) => {
			let categoryList=data;
			let allCategoryList=[];
			categoryList && categoryList.forEach(item => {
				allCategoryList.push({
					label: item.name,
					value: item._id
				})
			})
			this.setState({
				allCategoryList,
			})
		})
	}
	handleInputs = ({value, name }) => {
		
		let categoryDetails = Object.assign({}, this.state.categoryDetails)

		categoryDetails[name] = value
		this.setState({
			categoryDetails
		})
	}
    handleSubmit() {
        let {categoryDetails} = this.state
		let id = categoryDetails._id
		if (this.validator.allValid()) {
        if(id) {
          // EDIT FLOW
          let body = {
            ...categoryDetails
          }
          this.props.editCategoryDetailsById(id,body).then((data) => {
            let { history } = this.props
            this.setState({disabled:false})
            history.push('/reseller/category/position')
          }).catch((ex) => {
            this.setState({disabled:false})
          })
        }
		}else {

			this.validator.showMessages();
		}
      }
	render() {

		let { isError, isEdit, toggle } = this.props

		this.validator.purgeFields();

		let { categoryDetails, tempFile,allCategoryList } = this.state
        let {_id,position}= categoryDetails
		return (
			<section className="sair-sec-wrapper">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> Add Category position</h5>
					</div>
					<div className="col-auto" style={{"float":'right','marginTop':'-50px'}}>
						<Link to="/reseller/category/position">
							<button className="btn btn-primary btn-sm m-1"
							>Category Positions</button></Link>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Category List</label>
							{allCategoryList && <ReactSelect
								isMulti={false}
								options={allCategoryList}
								handleChange={(keyName, data) => {
									this.handleInputs({ name: keyName, value: data })
								}}
								keyName="_id"
								value={_id}
								disabled={false}
							/>
							}
							{this.validator.message('Category id', _id, 'required')}
						</div>
					</div>
					<div>
					<label><b>Position</b></label>
						<NormalInput
							className="form-control"
							name="position"
							type="text"
							value={position}
							onChange={({ name, value }) => {
							this.handleInputs({ name: name, value: value })
							}}
							validationError={this.validator.message('Position ', position, 'required')}
							number={false}
						/>
					</div>
					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>Submit</button>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {

	return {
        categoryList: state.reseller.categoryList // data from reducer to categoryList
        }

};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getCategoryNames,
		editCategoryDetailsById
	}, dispatch)
};

export const AddCategoryPosition= connect(mapStateToProps, mapDispatchToProps)(AddCategoryPositionClass)
