import React, { Component } from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"

import { getTopCategoryList, deleteTopCategory } from '../../redux/actions/topcategorymapping';
import { TableWrapper, SliderOverlay, ConfirmModal } from '../../component/common';

import { getSNo } from '../../services/helperFunctions';

import { TopCategoryMappingModal } from '../../component/TopCategoryMapping';

class TopCategoryMappingClass extends Component {

    state = {
        headerDetails: [{
            label: "S.No",
        }, {
            label: "Category Title",
        }, {
            label: "City Name",
        },
        // {
        //     label: "Traveller Description",
        // }, 
        {
            label: "Action",
        }],
        isOpen: false,
        category_id: null,
        editId: null,
        city_id: null,
        is_must_see: false,
        is_city_page: false,
        ConfirmModalOpenStatus: false,
        currentId: null,
    }

    handleFilter = (query, value, name) => {
        if (name == "filter") {
            this.setState({
                selectedFilter: query
            })

            query = {
                pageNo: 1,
                filter: query
            }
        }

        this.props.getTopCategoryList(query)

    }

    handleAdd = () => {

        this.setState({
            category_id: "",
            city_id: "",
            is_must_see: false,
            is_city_page: false,
        })

        this.toggle('isOpen')

    }

    toggle = name => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }));
    }

    handleEdit = ({ id, category_id, city_id, is_must_see, is_city_page }) => {

        this.setState({
            editId: id,
            category_id,
            city_id,
            is_must_see,
            is_city_page,
        })

        console.log(is_must_see, is_city_page, "asdasdasdhkjh3871238127381293")

        this.toggle('isOpen')

    }

    handleDeleteToggle = (id) => {

        this.setState({
            currentId: id
        })

        this.toggle("ConfirmModalOpenStatus")

    }

    handleDelete = () => {

        let { currentId } = this.state;

        this.props.deleteTopCategory(currentId).then(() => {

            this.props.getTopCategoryList({ pageNo: 1 })

            this.toggle("ConfirmModalOpenStatus")

        })

    }

    render() {

        let { topCategoryList } = this.props;

        let {
            list = [], pageMeta = {},
        } = topCategoryList;

        let {
            headerDetails,
            isOpen,
            category_id,
            city_id,
            is_must_see,
            is_city_page,
            ConfirmModalOpenStatus,
            editId
        } = this.state;

        let IsEmpty = list.length > 0;

        return (
            <div>
                <div class="sair-pg-header">
                    <h4 class="sair-pg-title align-self-center">
                        <span class="pg-title-icon">
                            <span class="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>
                        Top Category Mapping

                    </h4>
                    <div className="col-auto ">
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={this.handleAdd}
                        >
                            Add Top Category Mapping
                        </button>
                    </div>
                </div>
                <section className="sair-sec-wrapper">
                    <div className="row">
                        <div className="col-sm">
                            <div className="row no-gutters">
                                <h5 className="sair-sec-title align-self-center">
                                    Top Category Mapping List
                                </h5>
                            </div>
                            <div className="table-wrap mt-3">
                                <TableWrapper
                                    headerDetails={headerDetails}
                                    isEmpty={IsEmpty}
                                    pageMeta={pageMeta}
                                    queryHandler={(query) => this.handleFilter(query)}
                                >
                                    {list.map((item, index) => {

                                        let {
                                            City = {},
                                            Category,
                                            category_id,
                                            city_id,
                                            id,
                                            is_must_see: IsMustSee,
                                            is_city_page: IsCityPage,
                                        } = item;

                                        let getCategory = Category ? Category : {};

                                        let { title: CategoryTitle } = getCategory;

                                        let CurrentCity = City ? City : {};

                                        let {
                                            city_name = "",
                                            traveller_info = "",
                                        } = CurrentCity;

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {getSNo(pageMeta, index)}
                                                </td>
                                                <td>{CategoryTitle}</td>
                                                <td>{city_name}</td>
                                                {/* <td>{traveller_info}</td> */}
                                                <td>
                                                    <a
                                                        className="mr-25"
                                                        onClick={() => this.handleEdit({ id, category_id, city_id, is_must_see: IsMustSee, is_city_page: IsCityPage })}
                                                    >
                                                        <i className="icon-pencil  text-primary cursor-pointer"
                                                        ></i>
                                                    </a>
                                                    <a >
                                                        <i
                                                            className="icon-trash txt-danger text-primary cursor-pointer"
                                                            onClick={e => this.handleDeleteToggle(id)}
                                                        ></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )

                                    })}
                                </TableWrapper>
                            </div>
                        </div>
                    </div>
                </section>
                <SliderOverlay isOpen={isOpen} toggle={() => this.toggle('isOpen')} size='30%'>
                    <div className="side-slider h-100 position-relative">
                        <div className="h-100 flex-with-center">
                            <TopCategoryMappingModal
                                toggle={() => this.toggle('isOpen')}
                                details={{ editId, category_id, city_id, is_must_see, is_city_page }}
                            />
                        </div>
                    </div>
                </SliderOverlay>
                <ConfirmModal
                    keyName="ConfirmModalOpenStatus"
                    toggle={this.toggle}
                    isOpen={ConfirmModalOpenStatus}
                    handleSubmit={() => this.handleDelete()}
                    submitText='Delete'
                >
                    <h5>
                        Are you sure want to delete Top Category Mapping?
					</h5>
                </ConfirmModal>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        topCategoryList: state.topcategorymapping.topCategoryList,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getTopCategoryList,
        deleteTopCategory
    }, dispatch)
};


let actions = ['getTopCategoryList']

let component = LoaderWrapper(TopCategoryMappingClass)({ actions })

export const TopCategoryMapping = connect(mapStateToProps, mapDispatchToProps)(component)