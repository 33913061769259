import React, { Component } from 'react'

export class DriverDetailModal extends Component {
	render() {

		let { isError, modalDetails, handleInputs, validator, handleSubmit, title, toggle } = this.props
		validator.purgeFields();

		let { name, number, experience, licenseId, homeTown, description } = modalDetails

		return (
			<section className="side-slider h-100">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {title}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Driver Name</label>
							<input
								className={`form-control ${validator.message('name', name, 'required') ? 'inValid' : ''}`} id="title"
								placeholder=""
								name="name"
								value={name}
								type="text"
								onChange={handleInputs}


							/>
							{validator.message('name', name, 'required')}
						</div>
						<div className="col-md-12 form-group">
							<label>Contact number</label>
							<input
								className={`form-control ${validator.message('number', number, 'required | alpha_num') ? 'inValid' : ''}`} id="number"
								placeholder=""
								name="number"
								value={number}
								type="text"
								onChange={handleInputs}


							/>
							{validator.message('number', number, 'required | alpha_num')}
						</div>
						<div className="col-md-12 form-group">
							<label>License Id</label>
							<input
								className={`form-control ${validator.message('licenseId', licenseId, 'required') ? 'inValid' : ''}`} id="licenseId"
								placeholder=""
								name="licenseId"
								value={licenseId}
								type="text"
								onChange={handleInputs}


							/>
							{validator.message('licenseId', licenseId, 'required')}
						</div>
						<div className="col-md-12 form-group">
							<label>Driver Experience</label>
							<input
								className={`form-control ${validator.message('experience', experience, 'required | number') ? 'inValid' : ''}`} id="experience"
								placeholder=""
								name="experience"
								value={experience}
								type="text"
								onChange={handleInputs}


							/>
							{validator.message('experience', experience, 'required | number')}
						</div>

						<div className="col-md-12 form-group">
							<label>Home town</label>
							<input
								className={`form-control ${validator.message('homeTown', homeTown, 'required') ? 'inValid' : ''}`} id="homeTown"
								placeholder=""
								name="homeTown"
								value={homeTown}
								type="text"
								onChange={handleInputs}


							/>
							{validator.message('homeTown', homeTown, 'required')}
						</div>

						<div className="col-md-12 form-group">
							<label>Taxi description</label>
							<input
								className={`form-control ${validator.message('description', description, 'required') ? 'inValid' : ''}`} id="description"
								placeholder=""
								name="description"
								value={description}
								type="text"
								onChange={handleInputs}


							/>
							{validator.message('description', description, 'required')}
						</div>


						<hr />
						<button
							className="btn btn-primary"

							onClick={() => handleSubmit()}

						>Submit</button>
					</div>
				</div>
			</section>
		)
	}
}
