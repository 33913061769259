import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getLeadList,
  confirmLead,
  deleteLead,
} from "../../redux/actions/wholesale";
import { TableWrapper } from "../../component/common";
import LeadItemRow from "./LeadItemRow";
import { LoaderWrapper } from '../../HOC';

const Columns = [
  {
    label: "S. No",
  },
  {
    label: "Partner Details",
  },
  {
    label: "Product",
  },
  {
    label: "Confirmed",
  },
  {
    label: "Variant",
  },
  {
    label: "Final Price",
  },
  {
    label: "",
  },
];

export const LeadsList = ({
  leadList,
  getLeadList,
  confirmLead,
  history,
  deleteLead,
}) => {
  let { list = [], pageMeta = {} } = leadList;

  useEffect(() => {
    getLeadList();
  }, []);

  const onUpdateProduct = async (values, item) => {
    try {
      await confirmLead({
        ...item,
        ...values,
        active: true,
      });
      getLeadList(pageMeta);
    } catch (e) {
      console.log("e");
    }
  };

  const onDeleteLead = async (id) => {
    try {
      await deleteLead(id);
      getLeadList(pageMeta);
    } catch (e) {
      console.log("e");
    }
  };

  const handleFilter = (query) => {
    getLeadList(query);
  };

  return (
    <section className="sair-sec-wrapper">
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-secondary"
          onClick={() => history.push("/wholesale/products-list")}
        >
          Products
        </button>
        <button
          className="ml-4 btn btn-primary"
          onClick={() => history.push("/wholesale/leads-list")}
        >
          Leads
        </button>
      </div>
      <div className="table-wrap mt-3">
        <TableWrapper
          headerDetails={Columns}
          isEmpty={list.length > 0}
          pageMeta={pageMeta}
          queryHandler={(query) => handleFilter(query)}
        >
          {list.map((item, index) => {
            return (
              <LeadItemRow
                key={`LEAD_${index}`}
                item={item}
                index={pageMeta.limit * (pageMeta.page - 1) + index}
                onItemSubmit={(values) => onUpdateProduct(values, item)}
                onDelete={() => onDeleteLead(item._id)}
              />
            );
          })}
        </TableWrapper>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  leadList: state.wholesale.leadList,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLeadList,
      confirmLead,
      deleteLead,
    },
    dispatch
  );
};

const actions = ['getLeadList']
const component = LoaderWrapper(LeadsList)({ actions })

export const WholesaleLeadList = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
