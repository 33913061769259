import React, { Component } from 'react'
import ReactDateTime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import "./style.scss"
import moment from "moment"
export class DateTime extends Component {

    state = {
        defaultValue: '',
        stateChanged: false
    }

    componentDidMount() {

        let { value } = this.props

        if (value && !this.state.stateChanged) {

            this.setState({
                defaultValue: typeof value === "string" && value.indexOf('T') != -1 && value.indexOf('Z') != -1 ? moment(value) : value,
                stateChanged: true,
            })

        }


    }

    componentWillReceiveProps({ value, name, isControlled }) {

        if (value && !this.state.stateChanged) {

            this.setState({
                defaultValue: typeof value === "string" && value.indexOf('T') != -1 && value.indexOf('Z') != -1 ? moment(value) : value,
                stateChanged: true,
            }, () => {

                if (!moment(value, 'MM/DD/YYYY hh:mm a', true).isValid() &&
                    !moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() &&
                    !moment(value, 'YYYY-MM-DDTHH:mm:ss.000Z', true).isValid()) {

                    this.refs.dateInput.querySelector('input').focus()

                }

            })

        }

        if (isControlled) {
            this.setState({
                defaultValue: moment(value)
            })
        }
    }

    getTimeConstrain = () => {

        let dateTime = ReactDateTime.moment()

        return {
            hours: {
                min: dateTime.hours(),
                max: 23,
                step: 1,
            },
            minutes: {
                min: dateTime.minutes(),
                max: 59,
                step: 1,
            },
        }
    }

    changeDateBasedOnSelection = (date) => {

        // let { timeConstrainParams, value } = this.props

        // if (!moment(date).isSame(value, 'day') && moment(timeConstrainParams).isSame(date, 'day')) {
        //     return moment(timeConstrainParams).add(15, 'minutes')
        // }

        return date

    }

    render() {

        let { placeholder = "MM/DD/YYYY HH:MM AM/PM", value = '', isControlled = false, timeConstrainParams, timeConstraints, handleChange, name = '', disabled = false, isValidDate = e => true,timeFormat= true } = this.props

        let { defaultValue, stateChanged } = this.state

        // value = ReactDateTime.moment(defaultValue)

        return (
            <div className="date-input-wrapper" ref="dateInput">

                {!isControlled ? <ReactDateTime

                    inputProps={{
                        placeholder,
                        className: "form-control form-control-md",
                        disabled,
                        // readOnly: true
                    }}
                    timeFormat={timeFormat}
                    key={stateChanged}
                    defaultValue={defaultValue}
                    isValidDate={isValidDate}
                    // utc={true}
                    onChange={date => {
                        handleChange(name, moment(date, 'MM/DD/YYYY hh:mm a', true).isValid() ? moment(date).utc().format() : date) //
                    }}

                /> :
                    <ReactDateTime

                        inputProps={{
                            placeholder,
                            className: "form-control form-control-md",
                            disabled,
                            // readOnly: true
                        }}
                        key={stateChanged}
                        value={defaultValue}
                        isValidDate={isValidDate}
                        timeFormat={timeFormat}
                        // utc={true}
                        onChange={date => {
                            handleChange(name, moment(date, 'MM/DD/YYYY hh:mm a', true).isValid() ? moment(date).utc().format() : date) //
                        }}

                    />}


                <span className="icon-calendar font-lg icon"></span>

            </div>
        )
    }
}