import { blog } from "../../services/apiVariables"
import { blogType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getBlogListDetails = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        addQuery(query, blog.getBlogList)

        dispatch({ type: common.updateLoader, payload: true })

        api(blog.getBlogList).then(({ data, message }) => {

            dispatch({ type: blogType.updateBlogDetails, payload: data })

            dispatch({ type: common.updateLoader, payload: false })

            resolve(true)


        }).catch(({ message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'error', message })

        })

    })

}


export const createBlog = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api({ ...blog.createBlog, body }).then(({ data, message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'success', message })

            resolve(true)


        }).catch(({ message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'error', message })

        })

    })

}

export const deleteBlog = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        blog.deleteBlog.addParams = id;

        api(blog.deleteBlog).then(({ data, message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'success', message })

            resolve(true)


        }).catch(({ message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'error', message })

        })

    })

}

export const getBlogDetails = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        blog.getBlogDetails.addParams = id;

        api(blog.getBlogDetails).then(({ data, message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            // Toast({ type: 'success', message })

            resolve(data)


        }).catch(({ message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'error', message })

        })

    })

}

export const updateBlog = (id, body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        blog.updateBlog.addParams = id;

        api({ ...blog.updateBlog, body }).then(({ data, message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            // Toast({ type: 'success', message })

            resolve(data)


        }).catch(({ message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'error', message })

        })

    })

}