import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import {Link} from 'react-router-dom'
import { ReactSelect } from "../../component/common/ReactSelect";
import SimpleReactValidator from 'simple-react-validator';
import {editProductDetailsById ,getProductNames} from "../../redux/actions/reseller"

import { NormalInput} from "../../component/common"
class AddProductPositionClass extends Component {

	state = {
        tempFile: '',
        allProductList: [],
        productDetails:{
            _id:1,
            position:""
        }
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.props.getProductNames().then((data) => {
			let productList=data;
			let allProductList=[];
			productList && productList.forEach(item => {
				allProductList.push({
					label: item.name,
					value: item._id
				})

			})
			this.setState({
				allProductList,
			})
		})
	}


	componentWillReceiveProps(props) {
		let {productList}=props;
		let allProductList=[];
		productList && productList.list.forEach(item => {
			allProductList.push({
				label: item.name,
				value: item._id
			})

		})
		this.setState({
			allProductList,
		})
	}

	handleInputs = ({value, name }) => {
		
		let productDetails = Object.assign({}, this.state.productDetails)

		productDetails[name] = value
		this.setState({
			productDetails
		})
	}
    handleSubmit() {
        let {productDetails} = this.state
		let id = productDetails._id
		if (this.validator.allValid()) {
        if(id) {
          // EDIT FLOW
          let body = {
            ...productDetails
          }
          this.props.editProductDetailsById(id,body).then((data) => {
            let { history } = this.props
            this.setState({disabled:false})
            history.push('/reseller/product/position')
          }).catch((ex) => {
            this.setState({disabled:false})
          })
        }
		}else {

			this.validator.showMessages();
		}
      }
	render() {

		let { isError,} = this.props

		this.validator.purgeFields();

		let { productDetails, tempFile,allProductList } = this.state
        let {_id,position}= productDetails
		return (
			<section className="sair-sec-wrapper">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> Add Product position</h5>
					</div>
					<div className="col-auto" style={{"float":'right','marginTop':'-50px'}}>
						<Link to="/reseller/product/position">
							<button className="btn btn-primary btn-sm m-1"
							>Product Positions</button></Link>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Product List</label>
							{allProductList && <ReactSelect
								isMulti={false}
								options={allProductList}
								handleChange={(keyName, data) => {
									this.handleInputs({ name: keyName, value: data })
								}}
								keyName="_id"
								value={_id}
								disabled={false}
							/>
							}
							{this.validator.message('Product id', _id, 'required')}
						</div>
					</div>
					<div>
					<label><b>Position</b></label>
						<NormalInput
							className="form-control"
							name="position"
							type="text"
							value={position}
							onChange={({ name, value }) => {
							this.handleInputs({ name: name, value: value })
							}}
							validationError={this.validator.message('Position ', position, 'required')}
							number={false}
						/>
					</div>
					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>Submit</button>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {

	return {
        productList: state.reseller.productList // data from reducer to brandList
        }

};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getProductNames,
		editProductDetailsById
	}, dispatch)
};

export const AddProductPosition= connect(mapStateToProps, mapDispatchToProps)(AddProductPositionClass)
