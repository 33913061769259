import React, { Component } from 'react'

export class TravellerInformation extends Component {

	handleInputs = (data, tempValue, index) => {

		var name, value, key

		let details = Object.assign({}, this.props.details)


		let { handleChange } = this.props

		if (typeof data === 'object') {

			let { target: { name: fieldName, value: fieldValue } } = data

			name = fieldName

			if (name === "title") {

				value = fieldValue;

			} else {

				value = /(\d+(\.\d+)?)/.test(fieldValue) ? isNaN(Number(fieldValue)) ? fieldValue : Number(fieldValue) : fieldValue

			}

		} else {

			name = data

			value = tempValue

		}

		if (name === "height" || name === "weight") {

			details[name] = value === "" ? "" : Number(value)

		} else {

			details[name] = value;

		}


		handleChange(details)


	}


	render() {

		let { details = {}, disabled = false, genderList = [], validator } = this.props;
		let { title, height, weight, age, gender } = details

		return (
			<div className="row">
				<div className="col-md-6 form-group">
					<label for="firstName">Name</label>
					<input
						type="text"
						className="form-control"
						value={title}
						name="title"
						onChange={e =>
							this.handleInputs(e)
						}
						disabled={disabled}
					/>
					{validator.message('title', title, 'required')}
				</div>
				<div className="col-md-6 form-group">
					<label for="firstName">Height</label>
					<input
						type="number"
						className="form-control"
						value={height}
						name="height"
						onChange={e =>
							this.handleInputs(e)
						}
						disabled={disabled}
					/>
					{validator.message('height', height, 'required|numeric')}

				</div>
				<div className="col-md-6 form-group">
					<label for="firstName">Weight</label>
					<input
						type="number"
						className="form-control"
						value={weight}
						name="weight"
						onChange={e =>
							this.handleInputs(e)
						}
						disabled={disabled}
					/>
					{validator.message('weight', weight, 'required|numeric')}

				</div>
				<div className="col-md-6 form-group">
					<label for="firstName">Age</label>
					<input
						type="number"
						className="form-control"
						value={age}
						name="age"
						onChange={e =>
							this.handleInputs(e)
						}
						disabled={disabled}
					/>
					{validator.message('age', age, 'required|numeric')}

				</div>
				<div className="col-md-6 form-group">
					<label for="firstName">Gender</label>
					<div className="row">
						{genderList.map((item, index) => {

							return (
								<div key={index} className="col-md-4 mt-15">
									<div className="custom-control custom-radio-btn">
										<label className=" "
										>
											<input
												class="custom-control-input"
												type="radio"
												name={`gender${index}`}
												value={item.value}
												checked={gender.toLowerCase() === item.value}
												key={gender}
												id="gender"
												onChange={e => this.handleInputs('gender', e.target.value, index)}
												disabled={disabled}
											/>
											{item.label}
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
							)
						})}
						{validator.message('gender', gender, 'required')}

					</div>
				</div>
			</div>
		)
	}
}
