import React from "react"
import { InputSearch } from "../InputSearch"
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu,
    DropdownItem

} from 'reactstrap';
import PropTypes from 'prop-types';


export const Filters = ({ queryHandler, isDropDownFilter = true, value = '', handleChange = '',className= '' }) => {

    const handleSearch = (e) => {

        let { target: { name, value } } = e

        handleChange && handleChange(e)

        queryHandler && queryHandler({ search: value, page: 1,searchText:value })

    }

    const handleOrders = (e, val) => {

        let { target: { name, value } } = e

        handleChange && handleChange(e)

        queryHandler && queryHandler({ search: val, page: 1,searchText:value })

    }

    return (
        <div className={className ? className : "d-flex p-3 align-items-center"}>
            <div className="ml-auto d-flex align-items-center">
                {isDropDownFilter && <UncontrolledDropdown setActiveFromChild className="filter-dropdown">
                    <DropdownToggle tag="a" className="custom-caret dropdown-toggle-link">
                        Filter by: Order Status
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => handleOrders(e, 'placed')}>Placed</DropdownItem>
                        <DropdownItem onClick={(e) => handleOrders(e, 'confirmed')}>Confirmed</DropdownItem>
                        <DropdownItem onClick={(e) => handleOrders(e, 'cancelled')}>Cancelled</DropdownItem>
                        <DropdownItem onClick={(e) => handleOrders(e, 'waiting')}>Waiting</DropdownItem>
                        <DropdownItem onClick={(e) => handleOrders(e, 'pushed')}>Pushed</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>}
                <div>
                    {value ? [] : <InputSearch
                            onChange={handleSearch}
                        />}
                </div>
            </div>
        </div>
    )

}

Filters.propTypes = {
    queryHandler: PropTypes.func.isRequired,
}
