import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import {Link} from 'react-router-dom'
import { ReactSelect } from "../../component/common/ReactSelect";
import SimpleReactValidator from 'simple-react-validator';
import {editBrandDetailsById ,getBrandNames} from "../../redux/actions/reseller"

import { NormalInput} from "../../component/common"
class AddBrandPositionClass extends Component {

	state = {
        tempFile: '',
        allBrandList: [],
        brandDetails:{
            _id:1,
            position:""
        }
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.props.getBrandNames().then((data) => {
			let brandList=data;
			let allBrandList=[];
			brandList && brandList.forEach(item => {
				allBrandList.push({
					label: item.name,
					value: item._id
				})
			})
			this.setState({
				allBrandList,
			})
		})
	}
	handleInputs = ({value, name }) => {
		
		let brandDetails = Object.assign({}, this.state.brandDetails)

		brandDetails[name] = value
		this.setState({
			brandDetails
		})
	}
    handleSubmit() {
        let {brandDetails} = this.state
		let id = brandDetails._id
		if (this.validator.allValid()) {
        if(id) {
          // EDIT FLOW
          let body = {
            ...brandDetails
          }
          this.props.editBrandDetailsById(id,body).then((data) => {
            let { history } = this.props
            this.setState({disabled:false})
            history.push('/reseller/brand/position')
          }).catch((ex) => {
            this.setState({disabled:false})
          })
        }
		}else {

			this.validator.showMessages();
		}
      }
	render() {

		let { isError,} = this.props

		this.validator.purgeFields();

		let { brandDetails, tempFile,allBrandList } = this.state
        let {_id,position}= brandDetails
		return (
			<section className="sair-sec-wrapper">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> Add Shop position</h5>
					</div>
					<div className="col-auto" style={{"float":'right','marginTop':'-50px'}}>
						<Link to="/reseller/brand/position">
							<button className="btn btn-primary btn-sm m-1"
							>Shop Positions</button></Link>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Shop List</label>
							{allBrandList && <ReactSelect
								isMulti={false}
								options={allBrandList}
								handleChange={(keyName, data) => {
									this.handleInputs({ name: keyName, value: data })
								}}
								keyName="_id"
								value={_id}
								disabled={false}
							/>
							}
							{this.validator.message('Category id', _id, 'required')}
						</div>
					</div>
					<div>
					<label><b>Position</b></label>
						<NormalInput
							className="form-control"
							name="position"
							type="text"
							value={position}
							onChange={({ name, value }) => {
							this.handleInputs({ name: name, value: value })
							}}
							validationError={this.validator.message('Position ', position, 'required')}
							number={false}
						/>
					</div>
					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>Submit</button>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {

	return {
        brandList: state.reseller.brandList // data from reducer to brandList
        }

};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getBrandNames,
		editBrandDetailsById
	}, dispatch)
};

export const AddBrandPosition= connect(mapStateToProps, mapDispatchToProps)(AddBrandPositionClass)
