import React, { Component } from 'react';
import { ReactSelect, NormalInput, FileUpload } from '../common';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"

import {
    uploadCityfestivalImage,
    createCityfestival,
    updateCityFestival
} from '../../redux/actions/cityfestival';

import SimpleReactValidator from 'simple-react-validator';

import { getCategoryMappingDropDown } from "../../redux/actions/activity";
import { isString } from 'util';

export class CityFestivalModalClass extends Component {

    state = {
        activityOptions: [],
        cityOptions: [],
        formDetails: {
            title: '',
            festival_image: '',
            description: '',
            city_list_id: '',
            activity_id: [''],
        },
        cityList: [],
        activityList: [],
    }

    componentWillReceiveProps({ details, categoryMappingDropDownLists: { Activity = [], City = [] } }) {

        let cityList = this.state.cityList.slice(0);

        let activityList = this.state.activityList.slice(0);

        City.forEach(item => {
            cityList.push({
                label: item.city_name,
                value: item.id
            })

        })

        Activity.forEach(item => {
            activityList.push({
                label: item.title,
                value: item.id
            })

        })
        const formDetails = details.formDetails

        this.setState({
            cityList,
            activityList,
            formDetails
        })

    }

    componentWillMount() {
        let { formDetails } = this.props.details
        this.setState({ formDetails })

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message ">{message}</span>,
            autoForceUpdate: this,
            messages: {}
        });

        this.validators = new SimpleReactValidator({
            element: message => {

                let messages = message.split('/');

                return (
                    <span className="error-message font-md">
                        {`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
                    </span>
                )
            },

        });

    }

    // componentDidMount() {

    //     this.fileUpload = React.createRef();
    //     this.ImagePreview = this.ImagePreview.bind(this);

    // }

    // handleInputs = ({ value, name }) => {

    //     let formDetails = Object.assign({}, this.state.formDetails);

    //     formDetails[name] = value;

    //     this.setState({
    //         formDetails
    //     })


    // }

    handleSubmit = () => {

        const { action } = this.props

        if (action === 'add') {

            if (this.validator.allValid() && this.validators.allValid()) {

                let { toggle, details } = this.props;

                this.props.createCityfestival(details.formDetails).then(() => {
                    this.props.stateReset()
                    toggle();
                    this.props.getCityFestivalList()

                })

            } else {

                this.validator.showMessages()

            }
        } else if (action === 'edit') {
            if (this.validator.allValid() && this.validators.allValid()) {

                let { toggle, details } = this.props;
                console.log(toggle, details, 'MODAL CONSOLE LOG')
                this.props.updateCityFestival(details.formDetails).then(() => {
                    this.props.stateReset()
                    toggle();
                    this.props.getCityFestivalList()

                })

            } else {

                this.validator.showMessages()

            }
        }


    }

    // ImagePreview = ({ target: { files } }) => {

    //     let formData = new FormData()

    //     formData.append("cityFestivalImage", files[0])

    //     this.props.uploadCityfestivalImage(formData).then((data) => {

    //         let formDetails = Object.assign({}, this.state.formDetails);

    //         let { imageUrl } = data;

    //         formDetails.festival_image = imageUrl;

    //         this.setState({
    //             formDetails,
    //         })

    //     })

    // }

    handleAdd = () => {
        console.log('Here')
        let formDetails = Object.assign({}, this.state.formDetails)

        if (formDetails.activity_id.length < 3) {
            console.log('Here if less than 3')
            formDetails.activity_id.push('');
            this.props.handleAddActivity(formDetails)
            // this.setState({
            //     formDetails,
            // })

        }

    }

    // handleMultipleInputs = (index, { name, value }) => {

    //     let formDetails = Object.assign({}, this.state.formDetails)

    //     formDetails[name][index] = value;

    //     this.setState({
    //         formDetails,
    //     })

    // }

    render() {

        let { details, toggle, action } = this.props;
        let { formDetails } = details
        // if (formDetails) {
        //     this.setState({ formDetails })
        // }

        let { activityList, cityList } = this.state;

        let {
            title,
            festival_image,
            description,
            city_list_id,
            activity_id,
            festival_image_url
        } = formDetails;

        return (
            <>
                <div className="slider-header p-4">
                    <div className="d-flex align-items-center">
                        <h5 className="sair-sec-title">Add City Festival</h5>
                        <div className="ml-auto">
                            <i
                                className="icon-close font-md px-2 cursor-pointer"
                                onClick={toggle}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="slider-body p-4">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Title</label>
                            <NormalInput
                                name="title"
                                value={title}
                                className="form-control"
                                onChange={(name, value) => {
                                    this.props.festivalData(name, value)
                                }
                                }
                            />
                            {this.validator.message('title', title, 'required')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Festival Image</label>
                            <FileUpload
                                fileUpload={this.props.fileUpload}
                                ImagePreview={(data) => this.props.ImagePreview(data)}
                                filename={festival_image}
                            // tempFile={tempFile}
                            />
                            {isString(festival_image) && festival_image.includes('https') ? <img src={festival_image} className="img-fluid my-3" /> : <img src={festival_image_url} className="img-fluid my-3" />}
                            {this.validator.message('festival image', festival_image, 'required')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Description</label>
                            <textarea
                                name="description"
                                value={description}
                                onChange={({ target: { name, value } }) => this.props.handleInputs({ name, value })}
                                className="form-control"
                            ></textarea>
                            {this.validator.message('description', description, 'required')}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>City</label>
                            <ReactSelect
                                isMulti={false}
                                options={cityList}
                                handleChange={(keyName, data) => {
                                    this.props.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="city_list_id"
                                value={city_list_id}
                                disabled={false}
                            />
                            {this.validator.message('city', city_list_id, 'required')}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="d-flex align-items-center">
                                Activity
                                <button
                                    className={`btn btn-primary btn-sm ml-auto ${activity_id.length === 3 ? " disabled " : ""} `}
                                    onClick={this.handleAdd}

                                >
                                    Add Activity
                                </button>
                            </label>
                            {activity_id.map((item, index) => {

                                return (
                                    <div key={index} className="mb-3">
                                        <ReactSelect
                                            isMulti={false}
                                            options={activityList}
                                            handleChange={(keyName, data) => {
                                                if (action === 'add') {

                                                    this.props.handleMultipleInputs(index, { name: keyName, value: data })
                                                } else if (action === 'edit') {
                                                    this.props.handleMultipleInputsEdit(index, { name: keyName, value: data })

                                                }
                                            }}
                                            keyName="activity_id"
                                            value={action === 'add' ? item : item.activity_id}
                                            disabled={false}
                                        />
                                        {this.validators.message(`/activity/${index}/`, item, 'required')}
                                    </div>
                                )

                            })}

                        </div>
                    </div>

                    <hr />
                    <button
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                    >Submit</button>
                </div>

            </>
        )

    }

}

const mapStateToProps = state => {
    return {
        categoryMappingDropDownLists: state.activity.categoryMappingDropDownLists
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        uploadCityfestivalImage,
        getCategoryMappingDropDown,
        createCityfestival,
        updateCityFestival
    }, dispatch)
};


let actions = ['getCategoryMappingDropDown']

let component = LoaderWrapper(CityFestivalModalClass)({ actions });

export const CityFestivalModal = connect(mapStateToProps, mapDispatchToProps)(component)