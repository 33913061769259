import React, { Component } from 'react'
import logo from "../assets/images/logo_sair.png"
import bgSide from "../assets/images/carousel/bg1.jpg"

export class AuthLayout extends Component {

    render() {

        let { children } = this.props

        return (
            <>
                <div className="sair-wrapper">
                    <div className="sair-pg-wrapper sair-auth-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-5 pa-0">
                                    <div className="owl-carousel dots-on-item owl-theme">
                                        <div className="fadeOut item auth-cover-img overlay-wrap" style={{ backgroundImage: `url(${bgSide})` }} >
                                            <div className="auth-cover-info py-xl-0 pt-100 pb-50">
                                                <div className="auth-cover-content text-center w-xxl-75 w-sm-90 w-xs-100">
                                                <img className="d-inline-block mr-5" src={logo} alt="brand" style = {{height: 190+'px'}} />
                                                </div>
                                            </div>
                                            <div className="bg-overlay bg-trans-dark-50"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-7 pa-0">
                                    <div className="auth-form-wrap py-xl-0 py-50">
                                        <div className="auth-form w-xxl-55 w-xl-75 w-sm-90 w-xs-100">
                                            {children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
