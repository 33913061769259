import { common, AuthType } from "../../services/actionType";

const initialState = {
  cityLists: [
    {
      id: 1,
      label: "Chennai",
      value: "Chennai",
    },
    {
      id: 2,
      label: "Salem",
      value: "salem",
    },
  ],
  daysList: [
    {
      id: "1",
      label: "Sunday",
      value: "sunday",
    },
    {
      id: "2",
      label: "Monday",
      value: "monday",
    },
    {
      id: "3",
      label: "Tuesday",
      value: "tuesday",
    },
    {
      id: "4",
      label: "Wednesday",
      value: "wednesday",
    },
    {
      id: "5",
      label: "Thursday",
      value: "thursday",
    },
    {
      id: "6",
      label: "Friday",
      value: "friday",
    },
    {
      id: "7",
      label: "Saturday",
      value: "saturday",
    },
  ],
  sideNavDetails: [
    {
      name: "Dashboard",
      path: "/dashboard/list",
    },
    {
      name: "Reseller",
      path: "",
      subCategory: [
        {
          name: "Categories",
          path: "/reseller/category",
        },
        {
          name: "Level Two Categories",
          path: "/reseller/category-l2",
        },
        {
          name: "Level Three Categories",
          path: "/reseller/category-l3",
        },
        {
          name: "Shops",
          path: "/reseller/brand",
        },
        {
          name: "Warehouses",
          path: "/reseller/warehouses",
        },
        {
          name: "Products",
          path: "/reseller/product",
        },
        {
          name: "Sellers",
          path: "/reseller/sellers",
        },
        {
          name: "Reseller Banner",
          path: "/reseller/banner",
        },
        {
          name: "Customer Banner",
          path: "/customer-banner",
        },
      ],
    },
    {
      name: "Notification",
      path: "/notification/list",
    },
    {
      name: "Activity",
      path: "/activity/list",
    },
    {
      name: "Customer List",
      path: "/customer/list",
    },
    //Commented so can be use later
    {
      name: "Code Run",
      path: "/code-run",
    },
    {
      name: "Traders Day",
      path: "/discount",
    },
    {
      name: "Wholesale",
      path: "/wholesale/products-list",
    },
    {
      name: "New Orders",
      path: "/orders/list",
    },
    {
      name: "New GTM Orders",
      path: "/gtmorders/list",
    },
    {
      name: "Purchase Orders",
      path: "/purchaseOrder/list",
    },
    {
      name: "Partial Payment",
      path: "/partial-payment-link",
    },
  ],
  userDetails: {},
  loading: false,
  customerDetails: {},
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case common.updateUserDetails:
      return {
        ...state,
        userDetails: payload,
      };

    case common.sideBarDetails:
      return {
        ...state,
        sideNavDetails: payload,
      };
    case common.updateLoader:
      return {
        ...state,
        loading: payload,
      };
    case AuthType.updateCustomerDetails:
      return {
        ...state,
        customerDetails: payload,
      };
    default:
      return state;
  }
};
