import React, { Component } from "react";
import {
  FileUpload,
  ReactSelect,
  NormalInput,
  SliderOverlay,
} from "../../component/common";
import { TextEditorActivity } from "../../component/common/TextEditorActivity";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SAILMALL_BASE_URL } from "../../config/config";

import SimpleReactValidator from "simple-react-validator";
import {
  getProductDetailsById,
  addImage,
  editProductDetailsById,
  createProduct,
  getBrandNames,
  getCategoryLevelThreeNames,
  getSellerNames,
  getWareHouseNames,
} from "../../redux/actions/reseller";

import { TextEditor, DateTime } from "../../component/common";

import { hidePastDate } from "../../services/helperFunctions";
import { AddBrandModal } from "../../component/reseller/AddBrandModal";

import _, { findLastIndex } from "lodash";
import { Toast } from "reactstrap";

class ProductCreateClass extends Component {
  state = {
    productDetails: {
      tap_comm_id: "",
      name: "",
      shareLink: "",
      imgLinks: [],
      brand_id: "",
      brandPrice: "",
      resellerCostPrice: "",
      preferredResellerCostPrice: "",
      sairCostPrice: "",
      sairCosts: "",
      sairMargin: "",
      maxPrice: "",
      customerPrice: "",
      commission: "",
      description: "",
      guaranteeOffered: "",
      valueProposition: "",
      customerTestimonials: "",
      marketingVideos: [""],
      marketingBanners: [""],
      inclusions: "",
      exclusions: "",
      faqs: "",
      refundReturnCancellation: "",
      activityId: "",
      image_type: "",
      length: "",
      breadth: "",
      height: "",
      weight: "",
      no_of_unit: "",
      ean: "",
      hsn: "",
      tax_percentage: "",
      seller_id: "",
      category_id: "",
      status: "approved",
      hideOnApp: false,
      hotDeal: false,
      variants: [],
      warehouse_id: "",
      sizes: [],
      isError: false,
      isOpen: false,
      isEdit: false,
      mappingDetails: {
        sellerId: "",
      },
      ingredients: "",
      how_to_use: "",
      key_features: "",
      precautions: "",
      benefits: "",
      serviceableBy: "",
    },
    imageTypes: [
      { value: "horizontal", label: "Horizontal" },
      { value: "vertical", label: "Vertical" },
      { value: "square", label: "Square" },
    ],
    statusList: [
      { value: "pending", label: "Pending" },
      { value: "approved", label: "Approved" },
      { value: "rejected", label: "Rejected" },
    ],
    showInAppStatusList: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    serviceableList: [
      { value: "All", label: "All" },
      { value: "SFX Only", label: "Shadowfax Only" },
    ],
    descState: {
      description: "",
    },
    brandNames: [],
    warehouseNames: [],
    categoryNames: [],
    sellerNames: [],
    selectedWarehouse: 0,
    hotDealList: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    submitErr: "",
  };

  componentWillMount() {
    this.textEditorState = {};

    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="error-message font-md">{message}</span>
      ),
      autoForceUpdate: this,
      messages: {
        int: "this :attribute required here",
      },
    });
    this.validators = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => {
        let messages = message.split("/");
        return (
          <span className="error-message font-md">
            {`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
          </span>
        );
      },
    });

    this.handleInputs = ({ value, name }) => {
      let productDetails = this.state.productDetails;

      if (
        [
          "description",
          "valueProposition",
          "resultsExpected",
          "guaranteeOffered",
          "brandProfile",
          "customerTestimonials",
          "inclusions",
          "exclusions",
          "targetCustomerSegment",
          "faqs",
          "refundReturnCancellation",
        ].includes(name)
      ) {
        productDetails[name] = value;
        productDetails[name] =
          productDetails[name] &&
            productDetails[name].length <
            (this.textEditorState.description &&
              this.textEditorState.description.length)
            ? this.textEditorState[name]
            : productDetails[name];
      } else {
        productDetails[name] = value;
      }

      this.setState({
        productDetails: productDetails,
      });
    };

    this.handleTextEditorInputs = ({ value, name }) => {
      this.textEditorState[name] = value;
    };

    //category select
    this.handleBrandSelect = ({ name, value }) => {
      let productDetails = this.state.productDetails;
      let brandNames = this.state.brandNames;
      productDetails.brand_id = value;
      let selectedBrand = value;
      let brand = brandNames.filter((i) => i._id == value);
      this.setState({
        productDetails: productDetails,
        selectedBrand: selectedBrand,
      });
    };
    this.handleWarehouseSelect = ({ name, value }) => {
      let productDetails = this.state.productDetails;
      productDetails.warehouse_id = value;
      let selectedWarehouse = value;

      this.setState({
        productDetails: productDetails,
        selectedWarehouse: selectedWarehouse,
      });
    };
  }

  handleCategorySelect = ({ name, value }) => {
    let productDetails = this.state.productDetails;
    productDetails.category_id = value;
    let selectedCategory = value;
    this.setState({
      productDetails: productDetails,
      selectedCategory: selectedCategory,
    });
  };
  handleImageSelect = ({ name, value, index }) => {
    let productDetails = this.state.productDetails;
    productDetails.image_type = value;
    if (typeof productDetails.imgLinks[index] == "object") {
      productDetails.imgLinks[index].image_type = value;
    }
    let image_type = value;
    this.setState({
      productDetails: productDetails,
      image_type: image_type,
    });
  };
  handleSelect = ({ name, value }) => {
    let productDetails = this.state.productDetails;
    productDetails[name] = value;
    if (name === "seller_id") {
      this.getBrandsOnSellerChange(value);
    }
    this.setState({
      productDetails: productDetails,
    });
  };
  componentDidMount() {
    this.props.getCategoryLevelThreeNames().then((data) => {
      let categoryNames = data;
      this.setState({ categoryNames: categoryNames });
    });
    this.props.getSellerNames().then((data) => {
      let sellerNames = data;
      this.setState({ sellerNames: sellerNames });
    });
    this.props.getWareHouseNames().then((data) => {
      this.setState({ warehouseNames: data });
    });

    let brandNames;
    let {
      match: {
        params: { id },
      },
      history,
    } = this.props;

    if (id) {
      this.props.getProductDetailsById(id).then((data) => {
        let productDetails = data;
        if (productDetails.imgLinks && productDetails.imgLinks[0]) {
          if (typeof productDetails.imgLinks[0] == "string") {
            productDetails.imgLinks = [
              {
                link: productDetails.imgLinks[0],
                image_type: productDetails.image_type,
              },
            ];
          }
        } else {
          productDetails["imgLinks"] = [];
        }
        this.getBrandsOnSellerChange(
          productDetails.seller_id,
          productDetails.warehouse_id,
          productDetails.brand_id
        );
        this.setState({
          productDetails,
          selectedBrand: productDetails.brand_id,
          selectedWarehouse: productDetails.warehouse_id,
          selectedCategory: productDetails.category_id,
        });
      });
    } else {
      let productDetails = this.state.productDetails;
      this.setState({
        selectedBrand: productDetails.brand_id,
        selectedWarehouse: productDetails.warehouse_id,
        productDetails: productDetails,
        selectedCategory: productDetails.category_id,
      });
    }
  }

  handleVariantOptions = (index, { name, value }) => {
    let productDetails = Object.assign({}, this.state.productDetails);
    productDetails.variants[index][name] = value;

    this.setState({
      productDetails,
    });
  };
  handleSizeOptions = (index, { name, value }) => {
    let productDetails = Object.assign({}, this.state.productDetails);
    productDetails.sizes[index][name] = value;
    this.setState({
      productDetails,
    });
  };

  handleAddVariamtOptions = () => {
    let productDetails = Object.assign({}, this.state.productDetails);
    let variantsOptionsLength = productDetails.variants.length;

    if (variantsOptionsLength < 10) {
      productDetails.variants.push({
        slNo: "",
        name: "",
        tap_comm_id: "",
        brandPrice: "",
        sairMallPrice: "",
        resellerCostPrice: "",
        preferredResellerCostPrice: "",
        no_of_unit: "",
        sairCostPrice: "",
        sairCosts: "",
        sairMargin: "",
        maxPrice: "",
        length: "",
        breadth: "",
        height: "",
        customerPrice: "",
        ean: "",
        hotDealQuantity: "",
      });
    }
    this.setState({
      productDetails,
    });
  };

  handleAddSizeOptions = () => {
    let productDetails = Object.assign({}, this.state.productDetails);
    productDetails.sizes.push({
      slNo: "",
      name: "",
    });
    this.setState({
      productDetails,
    });
  };

  handleDeleteSizeOptions = (index) => {
    let productDetails = Object.assign({}, this.state.productDetails);
    productDetails.sizes.splice(index, 1);
    this.setState({
      productDetails,
    });
  };

  handleDeleteVariantOptions = (index) => {
    let productDetails = Object.assign({}, this.state.productDetails);
    productDetails.variants.splice(index, 1);
    this.setState({
      productDetails,
    });
  };
  handleDeleteImage = (index) => {
    let productDetails = Object.assign({}, this.state.productDetails);
    productDetails.imgLinks.splice(index, 1);
    this.setState({
      productDetails,
    });
  };
  handleSubmit() {
    let { productDetails } = this.state;
    let id = productDetails._id;
    console.log("productDetails", productDetails);

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      return;
    }
    const sellMoreProducts = productDetails.variants.filter(
      (variant) => variant.type === "sell_more"
    );
    if (sellMoreProducts.length > 1) {
      this.setState({
        submitErr: "Can not have more than 1 variant as sell more",
      });
      return;
    }
    this.setState({
      submitErr: "",
    });
    const visibleVariants = productDetails.variants.filter(
      (variant) => !variant.hideVariantOnApp
    );
    visibleVariants.sort((a, b) => a.no_of_unit - b.no_of_unit);

    let variantPrice = null;
    let variantMorePrice = null;
    visibleVariants.map((visibleVariant, index) => {
      if (index === visibleVariants.length - 1) return;
      const currentSlabPrice =
        visibleVariant.priceToRetailer / visibleVariant.no_of_unit;

      const nextItems = visibleVariants.slice(index + 1);
      const isMorePrice = nextItems.find((nextItem) => {
        const nextSlabPrice = nextItem.priceToRetailer / nextItem.no_of_unit;
        return currentSlabPrice < nextSlabPrice;
      });
      if (isMorePrice) {
        variantPrice = visibleVariant;
        variantMorePrice = isMorePrice;
      }
    });
    console.log("variantMorePrice", variantMorePrice);
    if (variantMorePrice) {
      this.setState({
        submitErr: `Variant of ${variantPrice.no_of_unit} has less price than other higher unit variant of ${variantMorePrice.no_of_unit}`,
      });
      return;
    }
    if (id) {
      // EDIT FLOW
      if (productDetails.variants.length > 0) {
        productDetails.variants.map((item, index) => {
          if (item.slNo == "") {
            item.slNo = index + 1;
          }
        });
      }
      if (productDetails.sizes && productDetails.sizes.length > 0) {
        productDetails.sizes.map((item, index) => {
          if (item.slNo == "") {
            item.slNo = index + 1;
          }
        });
      }
      let body = {
        ...productDetails,
      };

      if (body.imgLinks && body.imgLinks[0]) {
        if (typeof body.imgLinks[0] == "object") {
          body.imgLinks[0].type = body.image_type;
        } else if (typeof body.imgLinks[0] == "string") {
          body.imgLinks[0] = {
            link: body.imgLinks[0],
            type: body.image_type,
          };
        }
      }

      this.props
        .editProductDetailsById(id, body)
        .then((data) => {
          let { history } = this.props;
          this.setState({ disabled: false });
          history.push("/reseller/product");
        })
        .catch((ex) => {
          this.setState({ disabled: false });
        });
    } else {
      // CREATE FLOW
      let body = {
        ...productDetails,
      };
      if (productDetails.variants.length > 0) {
        productDetails.variants.map((item, index) => {
          item.slNo = index + 1;
        });
      }
      this.props
        .createProduct(body)
        .then((data) => {
          let { history } = this.props;
          this.setState({ disabled: false });
          history.push("/reseller/product");
        })
        .catch((ex) => {
          this.setState({ disabled: false });
        });
    }
  }

  ImagePreview = (key, event, index) => {
    let files = event.target.files[0];
    let productDetails = Object.assign({}, this.state.productDetails);
    let formData = new FormData();
    formData.append("resellerImage", files);
    this.props.addImage(formData).then((data) => {
      if (key == "product image") {
        if (typeof productDetails.imgLinks[index] == "object") {
          productDetails.imgLinks[index].link = data.imageUrl;
        } else if (typeof productDetails.imgLinks[0] == "string") {
          productDetails.imgLinks[index] = { link: data.imageUrl };
        }
      } else if (key == "marketing banner") {
        productDetails.marketingBanners[0] = data.imageUrl;
      }
    });
    this.setState({ productDetails: productDetails });
  };
  getBrandsOnSellerChange = (sellerId, warehouse_id, brand_id) => {
    let query = {
      sellerId: sellerId,
    };

    this.props.getBrandNames(query).then((data) => {
      let brandNames = data;

      if (brand_id && warehouse_id) {
        let brand = brandNames.filter((i) => i._id == brand_id);
      }

      this.setState({ brandNames: brandNames });
    });
  };
  handleModal = () => {
    let temp = {
      seller_id: "",
    };

    this.setState({
      isError: false,
      isOpen: false,
      isEdit: false,
      mappingDetails: temp,
    });
  };
  handleAddBrand = () => {
    if (this.state.productDetails && this.state.productDetails.seller_id) {
      let temp = {
        sellerId: this.state.productDetails.seller_id,
      };
      this.setState({
        isOpen: true,
        mappingDetails: temp,
      });
    } else {
      Toast({ type: "error", message: "Choose Seller first!" });
    }
  };
  toggle = (item) => {
    this.setState((prevState) => ({
      [item]: !prevState[item],
    }));
  };

  handleAddProductImages = () => {
    let productDetails = Object.assign({}, this.state.productDetails);
    let image = {
      link: "",
      image_type: "",
    };
    if (productDetails.imgLinks.length < 10) {
      productDetails.imgLinks.push(image);
    }
    this.setState({ productDetails: productDetails });
  };

  render() {
    let {
      productDetails,
      brandNames,
      warehouseNames,
      selectedBrand,
      selectedWarehouse,
      selectedCategory,
      imageTypes,
      showInAppStatusList,
      statusList,
      categoryNames,
      sellerNames,
      isError,
      isOpen,
      isEdit,
      mappingDetails,
      serviceableList,
      hotDealList,
      submitErr,
    } = this.state;
    categoryNames = _.map(categoryNames, (category) => {
      return { value: category._id, label: category.name };
    });
    brandNames = _.map(brandNames, (brand) => {
      return { value: brand._id, label: brand.name };
    });
    // console.log("productDetails",productDetails)
    warehouseNames = _.map(warehouseNames, (warehouse) => {
      return { value: warehouse._id, label: warehouse.name };
    });
    sellerNames = _.map(sellerNames, (seller) => {
      return { value: seller.id, label: seller.first_name };
    });
    let {
      tap_comm_id,
      name,
      shareLink,
      imgLinks,
      brand_id,
      brandPrice,
      sairmallPrice,
      resellerCostPrice,
      preferredResellerCostPrice,
      maxPrice,
      sairCostPrice,
      sairCosts,
      sairMargin,
      customerPrice,
      commission,
      description,
      duration,
      resultsExpected,
      guaranteeOffered,
      valueProposition,
      brandProfile,
      customerTestimonials,
      marketingVideos,
      marketingBanners,
      inclusions,
      exclusions,
      targetCustomerSegment,
      faqs,
      no_of_unit,
      priceToRetailer,
      length,
      breadth,
      height,
      refundReturnCancellation,
      activityId,
      image_type,
      weight,
      seller_id,
      warehouse_id,
      status,
      hideOnApp,
      variants,
      sizes,
      tax_percentage,
      ean,
      hsn,
      ingredients,
      how_to_use,
      key_features,
      benefits,
      precautions,
      serviceableBy,
      hotDeal,
      type,
    } = productDetails;

    let tempFile = "";
    let imageLink = "";
    if (imgLinks && imgLinks[0]) {
      if (typeof imgLinks[0] == "object") {
        imageLink = imgLinks[0].link;
      } else if (typeof imgLinks[0] == "string") {
        imageLink = imgLinks[0];
      }
    }

    return (
      <div>
        {/* Header */}
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="feather feather-align-left"
                >
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>
            Product
          </h4>
        </div>

        <div className="row">
          <div className="col-md-6 form-group">
            <label>
              Name
              <sup className="text-danger">*</sup>
            </label>
            <NormalInput
              className="form-control"
              name="name"
              type="text"
              value={name}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
              validationError={this.validator.message(
                "Product Name",
                name,
                "required"
              )}
            />
          </div>

          <div className="col-md-6 form-group">
            <label>
              Category
              <sup className="text-danger">*</sup>
            </label>
            <ReactSelect
              isMulti={true}
              options={categoryNames}
              handleChange={(keyName, data) => {
                this.handleCategorySelect({ name: keyName, value: data });
              }}
              keyName="category"
              value={selectedCategory}
              disabled={false}
            />
            {this.validator.message("Category", selectedCategory, "required")}
          </div>

          <div className="col-md-6 form-group">
            <label>
              <b>MRP</b>
              <sup className="text-danger">*</sup>
            </label>
            <NormalInput
              className="form-control"
              name="brandPrice"
              type="text"
              value={brandPrice}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
              validationError={this.validator.message(
                "Mrp",
                brandPrice,
                "required"
              )}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Sair Cost Price</b>
            </label>
            <NormalInput
              className="form-control"
              name="sairCostPrice"
              type="text"
              value={sairCostPrice}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Sair Costs</b>
            </label>
            <NormalInput
              className="form-control"
              name="sairCosts"
              type="text"
              value={sairCosts}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Sair Margin</b>
            </label>
            <NormalInput
              className="form-control"
              name="sairMargin"
              type="text"
              value={sairMargin}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Reseller Cost Price</b>
            </label>
            <NormalInput
              className="form-control"
              name="resellerCostPrice"
              type="text"
              value={resellerCostPrice}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Preferred Reseller Cost Price</b>
            </label>
            <NormalInput
              className="form-control"
              name="preferredResellerCostPrice"
              type="text"
              value={preferredResellerCostPrice}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Max Reseller Price</b>
            </label>
            <NormalInput
              className="form-control"
              name="maxPrice"
              type="text"
              value={maxPrice}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Customer Price</b>
            </label>
            <NormalInput
              className="form-control"
              name="customerPrice"
              type="text"
              value={customerPrice}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Price to retailers</b>
            </label>
            <NormalInput
              className="form-control"
              name="priceToRetailer"
              type="text"
              value={priceToRetailer}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
            // validationError={this.validator.message('Sair mall price', name, 'required')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Weight(in Gram)</b>
            </label>
            <NormalInput
              className="form-control"
              name="weight"
              type="number"
              value={weight}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
              number={true}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>no of unit</b>
            </label>
            <NormalInput
              className="form-control"
              name="no_of_unit"
              type="text"
              value={no_of_unit}
              onChange={({ name, value }) => {
                if (/^\d+$/.test(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
              number={true}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Length(in cm)</b>
            </label>
            <NormalInput
              className="form-control"
              name="length"
              type="number"
              value={length}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
              number={true}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Breadth(in cm)</b>
            </label>
            <NormalInput
              className="form-control"
              name="breadth"
              type="number"
              value={breadth}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
              number={true}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Height(in cm)</b>
            </label>
            <NormalInput
              className="form-control"
              name="height"
              type="number"
              value={height}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
              number={true}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Ean</b>
              <sup className="text-danger">*</sup>
            </label>
            <NormalInput
              className="form-control"
              name="ean"
              type="text"
              value={ean}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
              validationError={this.validator.message("EAN", ean, "required")}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>HSN</b>
              <sup className="text-danger">*</sup>
            </label>
            <NormalInput
              className="form-control"
              name="hsn"
              type="text"
              value={hsn}
              onChange={({ name, value }) => {
                this.handleInputs({ name: name, value: value });
              }}
              validationError={this.validator.message("HSN", hsn, "required")}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Ingredients</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "ingredients",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "ingredients", value: html });
              }}
              html={ingredients}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>How to use </b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({ name: "how_to_use", vaue: html });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "how_to_use", value: html });
              }}
              html={how_to_use}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Key Features</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "key_features",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "key_features", value: html });
              }}
              html={key_features}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Precautions </b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "precautions",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "precautions", value: html });
              }}
              html={precautions}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Benefits</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({ name: "benefits", value: html });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "benefits", value: html });
              }}
              html={benefits}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Description</b>
              <sup className="text-danger">*</sup>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "description",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "description", value: html });
              }}
              html={description}
            />
            {this.validator.message(
              "Product description",
              description,
              "required"
            )}
          </div>

          <div className="col-md-6 form-group">
            <label>
              <b>Other Details</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "valueProposition",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "valueProposition", value: html });
              }}
              html={valueProposition}
            />
            {/* {this.validator.message('valueProposition', valueProposition, 'required')} */}
          </div>

          <div className="col-md-6 form-group">
            <label>
              <b>Product Specifications</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "guaranteeOffered",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "guaranteeOffered", value: html });
              }}
              html={guaranteeOffered}
            />
            {/* {this.validator.message('guaranteeOffered', guaranteeOffered, 'required')} */}
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Customer Testimonials</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "customerTestimonials",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({
                  name: "customerTestimonials",
                  value: html,
                });
              }}
              html={customerTestimonials}
            />
            {/* {this.validator.message('customerTestimonials', customerTestimonials, 'required')} */}
          </div>

          <div className="col-md-6 form-group">
            <label>
              <b>Inclusions</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "inclusions",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "inclusions", value: html });
              }}
              html={inclusions}
            />
            {/* {this.validator.message('inclusions', inclusions, 'required')} */}
          </div>

          <div className="col-md-6 form-group">
            <label>
              <b>Exclusions</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "exclusions",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "exclusions", value: html });
              }}
              html={exclusions}
            />
            {/* {this.validator.message('exclusions', exclusions, 'required')} */}
          </div>

          <div className="col-md-6 form-group">
            <label>
              <b>FAQs</b>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({ name: "faqs", value: html });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({ name: "faqs", value: html });
              }}
              html={faqs}
            />
            {/* {this.validator.message('faqs', faqs, 'required')} */}
          </div>

          <div className="col-md-6 form-group">
            <label>
              <b>Refund / Return / Cancellation Policy</b>
              <sup className="text-danger">*</sup>
            </label>
            <TextEditorActivity
              handleTextEditor={(html) => {
                this.handleTextEditorInputs({
                  name: "refundReturnCancellation",
                  value: html,
                });
              }}
              handleTextEditorInput={(html) => {
                this.handleInputs({
                  name: "refundReturnCancellation",
                  value: html,
                });
              }}
              html={refundReturnCancellation}
            />
            {this.validator.message(
              "refundReturnCancellation",
              refundReturnCancellation,
              "required"
            )}
          </div>
          <div className="col-md-6 form-group">
            <label>Hide In App?</label>
            <ReactSelect
              isMulti={false}
              options={showInAppStatusList}
              handleChange={(keyName, data) => {
                this.handleSelect({ name: keyName, value: data });
              }}
              keyName="hideOnApp"
              value={hideOnApp}
              disabled={false}
            />
            {/* {this.validator.message('Image type', image_type , 'required')} */}
          </div>
          <div className="col-md-6 form-group">
            <label>Is it a hot deal Item?</label>
            <ReactSelect
              isMulti={false}
              options={hotDealList}
              handleChange={(keyName, data) => {
                this.handleSelect({ name: keyName, value: data });
              }}
              keyName="hotDeal"
              value={hotDeal}
              disabled={false}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>
              <b>Choose Seller</b>
              <sup className="text-danger">*</sup>
            </label>
            <ReactSelect
              isMulti={false}
              options={sellerNames}
              handleChange={(keyName, data) => {
                this.handleSelect({ name: keyName, value: data });
              }}
              keyName="seller_id"
              value={seller_id}
              disabled={false}
            />
            {this.validator.message("Seller", seller_id, "required")}
          </div>
          <div className="col-md-4 form-group">
            <label>
              <b>Shop</b>
              <sup className="text-danger">*</sup>
            </label>
            <ReactSelect
              isMulti={false}
              options={brandNames}
              handleChange={(keyName, data) => {
                this.handleBrandSelect({ name: keyName, value: data });
              }}
              keyName="brand"
              value={selectedBrand}
              disabled={false}
            />
            {this.validator.message("Shop", selectedBrand, "required")}
          </div>
          <div className="col-md-2 form-group">
            <label>
              <b>No Shops Found?</b>
            </label>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.handleAddBrand()}
            >
              Add New Shop{" "}
            </button>
          </div>
          <div className="col-md-4 form-group">
            <label>
              <b>Warehouse</b>
              <sup className="text-danger">*</sup>
            </label>
            <ReactSelect
              isMulti={false}
              options={warehouseNames}
              handleChange={(keyName, data) => {
                console.log(keyName, data);
                this.handleWarehouseSelect({ name: keyName, value: data });
              }}
              keyName="warehouse"
              value={selectedWarehouse}
              disabled={false}
            />
            {this.validator.message("warehouse", selectedWarehouse, "required")}
          </div>

          <div className="col-md-6 form-group">
            <label>Product Status</label>
            <sup className="text-danger">*</sup>
            <ReactSelect
              isMulti={false}
              options={statusList}
              handleChange={(keyName, data) => {
                this.handleSelect({ name: keyName, value: data });
              }}
              keyName="status"
              value={status}
              disabled={false}
            />
            {this.validator.message("Product tatus", status, "required")}
          </div>
          <div className="col-md-6 form-group">
            <label>Tax Percentage</label>
            <sup className="text-danger">*</sup>
            <NormalInput
              className="form-control"
              name="tax_percentage"
              type="text"
              value={tax_percentage}
              onChange={({ name, value }) => {
                if (!isNaN(value)) {
                  this.handleInputs({ name: name, value: value });
                }
              }}
              number={true}
              validationError={this.validator.message(
                "tax_percentage",
                name,
                "required"
              )}
            />

            {(!hideOnApp || hideOnApp === undefined || hideOnApp === null) && this.validator.message(`tax_percentage`, tax_percentage, "required")}
          </div>
          <div className="col-md-6 form-group">
            <label>Serviceable By</label>
            <ReactSelect
              isMulti={false}
              options={serviceableList}
              handleChange={(keyName, data) => {
                this.handleSelect({ name: keyName, value: data });
              }}
              keyName="serviceableBy"
              value={serviceableBy}
              disabled={false}
            />
            {this.validator.message("Serviceable by", status, "required")}
          </div>

          <div className="col-md-4 form-group">
            <label>
              <b>Sell More</b>
            </label>
            &nbsp;
            <input
              name="type"
              type="checkbox"
              checked={type === "sell_more"}
              onChange={(e) => {
                this.handleInputs({
                  name: "type",
                  value: e.target.checked ? "sell_more" : "",
                });
              }}
            />
          </div>
        </div>
        <h6 className="mb-15 row">
          <div className="col-sm-6">
            Images<sup className="text-danger">*</sup>
          </div>
          <div className="col-sm-6 text-right">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.handleAddProductImages()}
            >
              Add image
            </button>
          </div>
        </h6>
        {imgLinks && imgLinks.length > 0
          ? imgLinks.map((item, index) => {
            let { link, image_type } = item;
            return (
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>
                    <b> Product Image </b> Index: {index}
                    <sup className="text-danger">*</sup>
                  </label>
                  <FileUpload
                    fileUpload={this.fileUpload}
                    ImagePreview={(e) =>
                      this.ImagePreview("product image", e, index)
                    }
                    filename={link}
                    tempFile={tempFile}
                  />
                  <img
                    src={link}
                    style={{ width: "200px", marginTop: "15px" }}
                  />
                  {this.validator.message("Image", link, "required")}
                </div>
                <div className="col-md-4 form-group">
                  <label>Product Image Type</label>
                  <ReactSelect
                    isMulti={false}
                    options={imageTypes}
                    handleChange={(keyName, data) => {
                      this.handleImageSelect({
                        name: keyName,
                        value: data,
                        index,
                      });
                    }}
                    keyName="image_type"
                    value={image_type}
                    disabled={false}
                  />
                </div>
                <div className="col-md-2 align-self-center">
                  <i
                    className="icon-trash text-danger cursor-pointer"
                    onClick={(e) => this.handleDeleteImage(index)}
                  ></i>
                </div>
              </div>
            );
          })
          : ""}


        <h6 className="mb-15 row">
          <div className="col-sm-6">
            Variants<sup className="text-danger">*</sup>
          </div>
          <div className="col-sm-6 text-right">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.handleAddVariamtOptions()}
            >
              Add Variants
            </button>
          </div>
        </h6>

        {variants &&
          variants
            .filter((x) => !x.deleted)
            .map((item, index) => {
              let {
                slNo,
                no_of_unit,
                weight,
                length,
                breadth,
                height,
                hsn,
                ean,
                name,
                tap_comm_id,
                priceToRetailer,
                brandPrice,
                sairMallPrice,
                resellerCostPrice,
                maxPrice,
                sairCostPrice,
                sairCosts,
                sairMargin,
                customerPrice,
                imageIndex,
                hideVariantOnApp,
                hotDealQuantity,
                preferredResellerCostPrice,
                type,
                warehouse_id,
                warehouse_name
              } = item;

              return (
                <div className="row" key={`VARIANT_${index}`}>
                  <div className="col-md-6 form-group">
                    <label>
                      Name{index + 1}
                      <sup className="text-danger">*</sup>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="name"
                      type="text"
                      value={name}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={false}
                      validationError={this.validator.message(
                        "Name",
                        name,
                        "required"
                      )}
                    />
                    {/**M */}
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      {" "}
                      MRP {index + 1}
                      <sup className="text-danger">*</sup>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="brandPrice"
                      type="text"
                      value={brandPrice}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                      validationError={this.validator.message(
                        "Brand Price",
                        brandPrice,
                        "required"
                      )}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Sair Mall Price {index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="sairMallPrice"
                      type="text"
                      value={sairMallPrice}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Sair Cost Price{index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="sairCostPrice"
                      type="text"
                      value={sairCostPrice}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Sair Costs{index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="sairCosts"
                      type="text"
                      value={sairCosts}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Sair Margin{index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="sairMargin"
                      type="text"
                      value={sairMargin}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      Reseller Cost Price{index + 1}
                      <sup className="text-danger">*</sup>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="resellerCostPrice"
                      type="text"
                      value={resellerCostPrice}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                      validationError={this.validator.message(
                        "Reseller cost price",
                        resellerCostPrice,
                        "required"
                      )}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Preferred Reseller Cost Price{index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="preferredResellerCostPrice"
                      type="text"
                      value={preferredResellerCostPrice}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      Max Price to reseller{index + 1}
                      <sup className="text-danger">*</sup>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="maxPrice"
                      type="text"
                      value={maxPrice}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                      validationError={this.validator.message(
                        "Max Price",
                        maxPrice,
                        "required"
                      )}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Customer Price{index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="customerPrice"
                      type="text"
                      value={customerPrice}
                      onChange={({ name, value }) => {
                        if (!isNaN(value)) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                        }
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <b>Price to retailers</b>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="priceToRetailer"
                      type="text"
                      value={priceToRetailer}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                    // validationError={this.validator.message('Sair mall price', name, 'required')}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Weight(in Gram) {index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="weight"
                      type="number"
                      value={weight}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <b>No of unit</b>
                      <sup className="text-danger">*</sup>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="no_of_unit"
                      type="text"
                      value={no_of_unit}
                      onChange={({ name, value }) => {
                        if (/^\d+$/.test(value) || !value) {
                          this.handleVariantOptions(index, {
                            name: name,
                            value: value,
                          });
                          this.handleVariantOptions(index, {
                            name: "name",
                            value: `Pack of ${value}`,
                          });
                        }
                      }}
                      number={true}
                      validationError={this.validator.message(
                        "No of unit",
                        no_of_unit,
                        "required"
                      )}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <b>Length(in cm)</b>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="length"
                      type="number"
                      value={length}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <b>Breadth(in cm)</b>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="breadth"
                      type="number"
                      value={breadth}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      <b>Height(in cm)</b>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="height"
                      type="number"
                      value={height}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Image Index{index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="imageIndex"
                      type="text"
                      value={imageIndex}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Ean {index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="ean"
                      type="text"
                      value={ean}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={false}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>
                      HSN {index + 1}
                      <sup className="text-danger">*</sup>
                    </label>
                    <NormalInput
                      className="form-control"
                      name="hsn"
                      type="text"
                      value={hsn}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={false}
                      validationError={this.validator.message(
                        "Hsn",
                        hsn,
                        "required"
                      )}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Hot Deal Quantity {index + 1}</label>
                    <NormalInput
                      className="form-control"
                      name="hotDealQuantity"
                      type="number"
                      value={hotDealQuantity}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: name,
                          value: value,
                        });
                      }}
                      number={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Warehouse Name: {warehouse_name ? warehouse_name : 'NA'}</label>
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Warehouse ID: {warehouse_id ? warehouse_id : 'NA'}</label>
                  </div>
                  <div className="col-md-4 form-group">
                    <label>
                      <b>Hide on App</b>
                    </label>
                    &nbsp;
                    <input
                      name="hideVariantOnApp"
                      type="checkbox"
                      checked={hideVariantOnApp}
                      onChange={({ name, value }) => {
                        this.handleVariantOptions(index, {
                          name: "hideVariantOnApp",
                          value: !hideVariantOnApp,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <label>
                      <b>Sell More</b>
                    </label>
                    &nbsp;
                    <input
                      name="type"
                      type="checkbox"
                      checked={type === "sell_more"}
                      onChange={(e) => {
                        this.handleVariantOptions(index, {
                          name: "type",
                          value: e.target.checked ? "sell_more" : "",
                        });
                      }}
                    />
                  </div>
                  {/* <div className="col-md-1 align-self-center pt-3">
                  <i
                    className="icon-trash text-danger cursor-pointer"
                    onClick={e => this.handleDeleteVariantOptions(index)}
                  ></i>
                </div>
                 */}
                </div>
              );
            })}
        <hr />

        <h6 className="mb-15 row">
          <div className="col-sm-6">Size</div>
          <div className="col-sm-6 text-right">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.handleAddSizeOptions()}
            >
              Add Size
            </button>
          </div>
        </h6>

        {sizes &&
          sizes
            .filter((x) => !x.deleted)
            .map((item, index) => {
              let { name } = item;

              return (
                <>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label>Name {index + 1}</label>
                      <NormalInput
                        className="form-control"
                        name="name"
                        type="text"
                        value={name}
                        onChange={({ name, value }) => {
                          this.handleSizeOptions(index, {
                            name: name,
                            value: value,
                          });
                        }}
                        number={false}
                      />
                      {this.validator.message("Name", name, "required")}
                    </div>
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={(e) => this.handleDeleteSizeOptions(index)}
                      ></i>
                    </div>
                  </div>
                </>
              );
            })}
        <hr />
        {submitErr && <p className="text-danger">{submitErr}</p>}
        <hr />
        <button
          className="btn btn-primary"
          onClick={() => this.handleSubmit()}
          disabled={this.state.disabled}
        >
          Submit
        </button>

        <SliderOverlay isOpen={isOpen} toggle={this.handleModal} size="30%">
          <div className="side-slider h-100 position-relative">
            <div className="h-100 flex-with-center">
              <AddBrandModal
                isError={isError}
                toggle={this.handleModal}
                isEdit={false}
                modalDetails={mappingDetails}
                updateShopName={(seller_id) =>
                  this.getBrandsOnSellerChange(seller_id)
                }
              />
            </div>
          </div>
        </SliderOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductDetailsById,
      getBrandNames,
      getCategoryLevelThreeNames,
      addImage,
      editProductDetailsById,
      createProduct,
      getSellerNames,
      getWareHouseNames,
    },
    dispatch
  );
};

export const ProductCreate = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCreateClass);
