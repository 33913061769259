import { topActivityCity } from "../../services/apiVariables"
import { topActivityCityType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getTopActivityCityList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        addQuery(query, topActivityCity.getTopActivityCityList)

        api(topActivityCity.getTopActivityCityList).then(({ data, message }) => {

            dispatch({ type: topActivityCityType.updateTopActivityCityDetails, payload: data })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })


        })

    })
}

export const getTopActivityCityDropdown = () => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api(topActivityCity.getTopActivityCityDropdown).then(({ data, message }) => {

            dispatch({ type: topActivityCityType.updateTopActivityCityDropdown, payload: data })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const createTopActivityCity = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api({ ...topActivityCity.createTopActivityCity, body }).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const updateTopActivityCity = (id, body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        topActivityCity.updateTopActivityCity.addParams = id;

        api({ ...topActivityCity.updateTopActivityCity, body }).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const deleteTopActivityCity = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        topActivityCity.deleteTopActivityCity.addParams = id;

        api(topActivityCity.deleteTopActivityCity).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}