import { generateQuery } from "./helperFunctions";

// ========================================= common ==============================

export const commonUtilities = {
  forgotPassword: {
    api: "api/v1/admin/forgetPassword",
    method: "post",
  },
  verifyPasswordChangeToken: {
    api: "api/v1/admin/verifyToken",
    method: "post",
  },
  updateResetPassword: {
    api: "api/v1/admin/resetPassword",
    method: "put",
  },
  getAdminDetails: {
    api: "api/v1/admin/getAdminProfile",
    method: "get",
  },
};

// ========================================= Auth =================================

export const authentication = {
  login: {
    api: "v1/admin/login",
    method: "post",
  },

  getAllCustomers: {
    url: "api/v1/admin/getAllCustomer",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

/** Master Details */

export const master = {
  /** City */

  getCityListDetails: {
    url: "api/v1/admin/city/readCity",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  changeCityStatus: {
    api: "api/v1/admin/city/CityStatusChange",
    method: "patch",
  },

  cityImageUpload: {
    api: "api/v1/admin/city/addCityImage",
    method: "post",
  },

  createCity: {
    api: "api/v1/admin/city/addCity",
    method: "post",
  },

  editCity: {
    url: "api/v1/admin/city/updateCity",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteCity: {
    url: `v1/admin/city/deleteCity`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  /** Category */

  getCategoryListDetails: {
    url: "api/v1/admin/category/readCategory",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createCategory: {
    api: "api/v1/admin/category/addCategory",
    method: "post",
  },

  editCategory: {
    url: "api/v1/admin/category/updateCategory",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getCityDetails: {
    url: "api/v1/admin/city/readOneCity",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteCategory: {
    url: `api/v1/admin/category/deleteCategory`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  /** Language */

  getLanguageListDetails: {
    url: "api/v1/admin/language/readLanguage",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createLanguage: {
    api: "api/v1/admin/language/addLanguage",
    method: "post",
  },

  editLanguage: {
    url: "api/v1/admin/language/updateLanguage",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteLanguage: {
    url: `api/v1/admin/language/deleteLanguage`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  createDriver: {
    api: "api/createDriver",
    method: "post",
  },

  /** Experience */

  getExperienceGroupListDetails: {
    url: "api/v1/admin/experienceGroup/getExperienceGroup",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createExperienceGroup: {
    api: "api/v1/admin/experienceGroup/addExperienceGroup",
    method: "post",
  },
  editExperienceGroup: {
    url: "api/v1/admin/experienceGroup/updateExperienceGroup",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteExperienceGroup: {
    url: `api/v1/admin/experienceGroup/deleteExperienceGroup`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  /** Travel experience group */

  getTravelExperienceGroupListDetails: {
    url: "api/v1/admin/travelerExperienceGroup/getTravelerExperienceGroup",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createTravelExperienceGroup: {
    api: "api/v1/admin/travelerExperienceGroup/addTravelerExperienceGroup",
    method: "post",
  },
  editTravelExperienceGroup: {
    url: "api/v1/admin/travelerExperienceGroup/updateTravelerExperienceGroup",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteTravelExperienceGroup: {
    url: `api/v1/admin/travelerExperienceGroup/deleteTravelerExperienceGroup`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  /** PhysicalRestriction  */

  getPhysicalRestrictionListDetails: {
    url: "api/v1/admin/physicalRestriction/getRestriction",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createPhysicalRestriction: {
    api: "api/v1/admin/physicalRestriction/addRestriction",
    method: "post",
  },

  editPhysicalRestriction: {
    url: "api/v1/admin/physicalRestriction/updateRestriction",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deletePhysicalRestriction: {
    url: `api/v1/admin/physicalRestriction/deleteRestriction`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  /** Service Provider  */

  getServiceProviderListDetails: {
    url: "api/v1/admin/serivceProvider/getServiceProvider",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createServiceProvider: {
    api: "api/v1/admin/serivceProvider/addServiceProvider",
    method: "post",
  },

  editServiceProvider: {
    url: "api/v1/admin/serivceProvider/updateServiceProvider",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteServiceProvider: {
    url: `api/v1/admin/serivceProvider/deleteServiceProvider`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  /** PickupDrop  */

  getPickupDropListDetails: {
    url: "api/v1/admin/activity/pickup-drop-options",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createPickupDrop: {
    api: "api/v1/admin/activity/pickup-drop-options",
    method: "post",
  },

  editPickupDrop: {
    url: "api/v1/admin/activity/pickup-drop-options",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deletePickupDrop: {
    url: `api/v1/admin/activity/pickup-drop-options`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  //************************Vehicle type *****************************

  getVehicleTypeListDetails: {
    url: "api/v1/admin/activity/vehicle-types",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createVehicleType: {
    api: "api/v1/admin/activity/vehicle-types",
    method: "post",
  },

  editVehicleType: {
    url: "api/v1/admin/activity/vehicle-types",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteVehicleType: {
    url: `api/v1/admin/activity/vehicle-types`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  //************************Stay type *****************************
  getStayTypeListDetails: {
    url: "api/v1/admin/activity/stay-types",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createStayType: {
    api: "api/v1/admin/activity/stay-types",
    method: "post",
  },

  editStayType: {
    url: "api/v1/admin/activity/stay-types",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteStayType: {
    url: `api/v1/admin/activity/stay-types`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  //************************Ticket Nationality type *****************************
  getTicketNationalityTypeListDetails: {
    url: "api/v1/admin/activity/ticket-nationality-types",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  createTicketNationalityType: {
    api: "api/v1/admin/activity/ticket-nationality-types",
    method: "post",
  },

  editTicketNationalityType: {
    url: "api/v1/admin/activity/ticket-nationality-types",
    method: "put",
    id: "",
    get api() {
      console.log(this.url + "/" + this.id);
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteTicketNationalityType: {
    url: `api/v1/admin/activity/ticket-nationality-types`,
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

export const dashboard = {};

// ========================================= Vendor =================================

export const vendor = {
  deleteVendor: {
    url: "api/v1/admin/vendor/deleteVendor/",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  getVendorListDetails: {
    url: "api/v1/admin/vendor/getVendorList",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createVendor: {
    api: "api/v1/admin/vendor",
    method: "post",
  },
  addDocument: {
    api: "api/v1/admin/vendor/addVendorDocument",
    method: "post",
  },
  getVendorMappingList: {
    url: "api/v1/admin/vendorActivityMapping/readVendorActivityMappingg",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createVendorActivityMapping: {
    api: "api/v1/admin/vendorActivityMapping/addVendorActivityMapping",
    method: "post",
  },
  updateVendorActivityMapping: {
    url: "api/v1/admin/vendorActivityMapping/updateVendorActivityMapping/",
    method: "patch",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteVendorActivityMapping: {
    // url: 'api/v1/admin/bannerMapping/deleteBannerMapping/',
    url: "api/v1/admin/vendorActivityMapping/deleteVendorActivityMapping/",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getVendorMappingDropdown: {
    api: "api/v1/admin/vendorActivityMapping/addVendorActivityMappingDrop",
    method: "get",
  },
  editVendor: {
    url: "api/v1/admin/vendor/updateVendor/",
    method: "patch",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getSingleVendor: {
    url: "api/v1/admin/vendor/viewOneVendor/",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  removeVendorDocument: {
    url: "api/v1/admin/vendor/removeVendorDocument/",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

// ==================================Activity =======================================

export const activity = {
  addImage: {
    api: "api/v1/admin/activity/addActivityImage",
    method: "post",
  },

  addItenaryImage: {
    api: "api/v1/admin/activity/addItineraryImage",
    method: "post",
  },
  addSliderActivityImage: {
    api: "api/v1/admin/activity/addSliderActivityImage",
    method: "post",
  },
  createActivity: {
    api: "api/v1/admin/activity/addActivity",
    method: "post",
  },
  updateActivity: {
    url: "api/v1/admin/activity/updateActivity",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getActivityDropDown: {
    api: "api/v1/admin/activity/getActivityDropdown",
    method: "get",
  },
  getActivityListDetails: {
    url: "api/v1/admin/activity/readActivity",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  changeActivityStatus: {
    api: "api/v1/admin/activity/activityStatusChange",
    method: "patch",
  },
  getActivityDetailsById: {
    url: "api/v1/admin/activity/readOneActivity",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteActivity: {
    url: "api/v1/admin/activity/deleteActivity",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getCategoryMappingListDetails: {
    url: "api/v1/admin/categoryMapping/readCategoryMapping",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCategoryMappingDropDown: {
    api: "api/v1/admin/categoryMapping/addCategoryMappingDrop",
    method: "get",
  },
  createCategoryMapping: {
    api: "api/v1/admin/categoryMapping/addCategoryMapping",
    method: "post",
  },
  deleteCategoryMapping: {
    url: "api/v1/admin/categoryMapping/deleteCategoryMapping",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  updateCategoryMapping: {
    url: "api/v1/admin/categoryMapping/updateCategoryMapping",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

//=============Itenary Creation

export const iternaryCreation = {
  generate: {
    api: "api/v1/admin/itinerary/generate",
    method: "post",
  },
  getMasterData: {
    api: "api/v1/admin/itinerary/master",
    method: "post",
  },
  getBase64Images: {
    api: "api/v1/admin/itinerary/base64Images",
    method: "post",
  },
  saveItinerary: {
    api: "api/v1/admin/itinerary/config",
    method: "post",
  },
  getConfigData: {
    url: "api/v1/admin/itinerary/config",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getItineraryDetailsById: {
    url: "api/v1/admin/itinerary/config",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getCityWiseInfo: {
    url: "api/v1/admin/itinerary/travel-info",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      cities: "delhi",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCityList: {
    url: "api/v1/admin/itinerary/master/city-names",
    method: "get",
    get api() {
      return this.url;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

//=============Reseller

export const Reseller = {
  categoryList: {
    url: "api/v1/admin/reseller/categories/l1",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      sorBy: null,
      withPosition: null,
      sortOrder: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  categoryNames: {
    url: "api/v1/admin/reseller/category-names/l1",
    method: "get",
    get api() {
      return this.url;
    },
  },

  categoryDetails: {
    url: "api/v1/admin/category/l1",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  addCategory: {
    api: "api/v1/admin/category/l1",
    method: "post",
  },

  editCategory: {
    url: "api/v1/admin/category/l1",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteCategory: {
    url: "api/v1/admin/category/l1",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  //Category level 2
  categoryLevelTwoList: {
    url: "api/v1/admin/reseller/categories/l2",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      sorBy: null,
      category: null,
      withPosition: null,
      sortOrder: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  categoryLevelTwoNames: {
    url: "api/v1/admin/reseller/category-names/l2",
    method: "get",
    get api() {
      return this.url;
    },
  },

  categoryLevelTwoDetails: {
    url: "api/v1/admin/category/l2",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  addCategoryLevelTwo: {
    api: "api/v1/admin/category/l2",
    method: "post",
  },

  editCategoryLevelTwo: {
    url: "api/v1/admin/category/l2",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteCategoryLevelTwo: {
    url: "api/v1/admin/category/l2",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  //Category Level 3
  categoryLevelThreeList: {
    url: "api/v1/admin/reseller/categories/l3",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      sorBy: null,
      category: null,
      withPosition: null,
      sortOrder: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  categoryLevelThreeDetails: {
    url: "api/v1/admin/category/l3",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  categoryLevelThreeNames: {
    url: "api/v1/admin/reseller/category-names/l3",
    method: "get",
    get api() {
      return this.url;
    },
  },
  addCategoryLevelThree: {
    api: "api/v1/admin/category/l3",
    method: "post",
  },

  editCategoryLevelThree: {
    url: "api/v1/admin/category/l3",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteCategoryLevelThree: {
    url: "api/v1/admin/category/l3",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  brandList: {
    url: "api/v1/admin/reseller/brands",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      sorBy: null,
      withPosition: null,
      sortOrder: null,
      sellerId: null,
      searchText: null,
      category: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    set removeQuery({ key, payload }) {
      this.query = {
        pageNo: 1,
        limit: null,
        search: null,
        orderBy: null,
        filter: null,
        sorBy: null,
        withPosition: null,
        sortOrder: null,
        sellerId: null,
        searchText: null,
        category: null,
      };
    },
  },

  brandNames: {
    url: "api/v1/admin/reseller/brand-names",
    method: "get",
    query: {
      sellerId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  productNames: {
    url: "api/v1/admin/reseller/product-names",
    method: "get",
    get api() {
      return this.url;
    },
  },
  brandDetails: {
    url: "api/v1/admin/reseller/brand",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  addBrand: {
    api: "api/v1/admin/reseller/brand",
    method: "post",
  },

  editBrand: {
    url: "api/v1/admin/reseller/brand",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteBrand: {
    url: "api/v1/admin/reseller/brand",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  bannerList: {
    url: "api/v1/reseller/banner",
    method: "get",
    get api() {
      return this.url;
    },
  },

  updateBanner: {
    url: "api/v1/admin/reseller/banner/update",
    method: "post",
    get api() {
      return this.url;
    },
  },
  customerBannerList: {
    url: "api/v1/customer/banner",
    method: "get",
    get api() {
      return this.url;
    },
  },

  UpdateCustomerBanner: {
    url: "api/v1/admin/customer/banner/update",
    method: "post",
    get api() {
      return this.url;
    },
  },

  productList: {
    url: "api/v1/admin/reseller/products",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      sorBy: null,
      withPosition: null,
      sortOrder: null,
      sellerId: null,
      searchText: null,
      approvalStatus: null,
      brand: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    set removeQuery({ key, payload }) {
      this.query = {
        pageNo: 1,
        limit: null,
        search: null,
        orderBy: null,
        filter: null,
        sorBy: null,
        withPosition: null,
        sortOrder: null,
        sellerId: null,
        searchText: null,
        approvalStatus: null,
        brand: null,
      };
    },
  },
  productListBySearch: {
    url: "api/v1/admin/products/search",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      orderBy: null,
      searchText: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    set removeQuery({ key, payload }) {
      this.query = {
        pageNo: 1,
        limit: null,
        orderBy: null,
        searchText: null,
      };
    },
  },
  productDetails: {
    url: "api/v1/admin/reseller/product",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  addProduct: {
    api: "api/v1/admin/reseller/product",
    method: "post",
  },

  editProduct: {
    url: "api/v1/admin/reseller/product",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteProduct: {
    url: "api/v1/admin/reseller/product",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  createCatalogue: {
    api: "api/v1/admin/reseller/product-catalogue",
    method: "post",
  },

  addImage: {
    api: "api/v1/admin/reseller/addImage",
    method: "post",
  },
  sellerNames: {
    url: "api/v1/admin/reseller/product-seller",
    method: "get",
    get api() {
      return this.url;
    },
  },
  sellerList: {
    url: "api/v1/admin/reseller/list",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      searchText: null,
      orderBy: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  sellerDetails: {
    url: "api/v1/admin/reseller/info",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  editSeller: {
    url: "api/v1/admin/reseller/account/update",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  commissionList: {
    url: "api/v1/admin/commission-codes",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      percentage: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  wareHouseList: {
    url: "api/v1/admin/reseller/warehouse",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      sorBy: null,
      sortOrder: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  wareHouseNames: {
    url: "api/v1/admin/reseller/warehouse-names?fullDetails=true",
    method: "get",
    get api() {
      return this.url;
    },
  },

  wareGtmHouseNames: {
    url: "api/v1/admin/reseller/warehouse-names?fullDetails=true&type=vinretail",
    method: "get",
    get api() {
      return this.url;
    },
  },

  wareHouseDetails: {
    url: "api/v1/admin/reseller/warehouse",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },

  addWareHouse: {
    api: "api/v1/admin/reseller/warehouse",
    method: "post",
  },
};

//==============AdvertisementTypeOne

export const advertisementTypeOne = {
  addImage: {
    api: "api/v1/admin/advertisement_type_one_images",
    method: "post",
  },
  addSliderAdvertisementImage: {
    api: "api/v1/admin/advertisement_slider_images",
    method: "post",
  },

  createAdvertisementTypeOne: {
    api: "api/v1/admin/advertisement_type_ones",
    method: "post",
  },
  getAdvertisementTypeOneListDetails: {
    url: "api/v1/admin/advertisement_type_ones",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      rnd: new Date().getTime(),
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAdvertisementTypeOneDetailsById: {
    url: "api/v1/admin/advertisement_type_ones",
    method: "get",
    id: "",
    get api() {
      return this.url + "/" + this.id + "/show";
    },
    set addParams(id) {
      this.id = id;
    },
  },
  updateAdvertisementTypeOne: {
    url: "api/v1/admin/advertisement_type_ones",
    method: "patch",
    id: "",
    get api() {
      return this.url + "/" + this.id + "/update";
    },
    set addParams(id) {
      this.id = id;
    },
  },

  deleteAdvertisementTypeOne: {
    url: "api/v1/admin/advertisement_type_ones",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id + "/delete";
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getCityListDetails: {
    url: "api/v1/admin/city/readCity",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getCategoryMappingListDetails: {
    url: "api/v1/admin/categoryMapping/readCityActivityCategoryMapping",
    method: "get",
    city_id: "",
    get api() {
      return this.url + "/" + this.city_id;
    },
    set addParams(city_id) {
      if (!isNaN(city_id)) this.city_id = city_id;
      else this.city_id = 25;
    },
  },
  getActivityListDetails: {
    url: "api/v1/admin/activity/readActivityNames",
    method: "get",
    query: {
      pageNo: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },

    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

//==================Orders===============

export const orders = {
  getOrdersList: {
    url: "api/v1/admin/orders",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      //search: null,
      searchText: null,
      searchByEmail: null,
      searchByContact: null,
      searchByOrder: null,
      searchByStatus: null,
      orderBy: null,
      filter: null,
      cod: null,
      userId: null,
      wholesale: null,
      sellmore: null,
      bareilly: null,
      autopush: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    reset() {
      this.query = {
        pageNo: 1,
        limit: null,
        searchText: null,
        searchByEmail: null,
        searchByContact: null,
        searchByOrder: null,
        searchByStatus: null,
        orderBy: null,
        filter: null,
        cod: null,
        userId: null,
        wholesale: null,
        sellmore: null,
        bareilly: null,
        autopush: null,
      };
    },
  },

  getGtmOrdersList: {
    // todop change url with filter
    url: "api/v1/admin/orders",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      //search: null,
      searchText: null,
      searchByEmail: null,
      searchByContact: null,
      searchByOrder: null,
      searchByStatus: null,
      orderBy: null,
      filter: null,
      cod: null,
      userId: null,
      wholesale: null,
      sellmore: null,
      bareilly: null,
      autopush: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    reset() {
      this.query = {
        pageNo: 1,
        limit: null,
        searchText: null,
        searchByEmail: null,
        searchByContact: null,
        searchByOrder: null,
        searchByStatus: null,
        orderBy: null,
        filter: null,
        cod: null,
        userId: null,
        wholesale: null,
        sellmore: null,
        bareilly: null,
        autopush: null,
      };
    },
  },
  confirmOrder: {
    api: "api/v1/admin/customerOrder/confirmOrder",
    method: "post",
  },
  getSingleOrder: {
    url: "api/v1/admin/customerOrder/viewOneOrderDetail/",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ payload }) {
      this.id = payload;
    },
  },
  getVendorListDropdown: {
    api: "api/v1/admin/vendor/vendorListDropdown",
    method: "get",
  },
  getOrderRemarks: {
    url: "api/v1/admin/reseller/order-remarks/",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery(payload) {
      this.id = payload;
    },
  },
  addOrderRemarks: {
    url: "api/v1/admin/reseller/order-remarks/",
    method: "post",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery(payload) {
      this.id = payload;
    },
  },
};

//==================Top Activity Mapping===============

export const topActivity = {
  getTopActivityList: {
    url: "api/v1/admin/bannerMapping/readBannerMapping",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getActivityListDropdown: {
    api: "api/v1/admin/activity/getActivityListDropdown",
    method: "get",
  },
  createTopActivity: {
    api: "api/v1/admin/bannerMapping/addBannerMapping",
    method: "post",
  },
  updateTopActivity: {
    url: "api/v1/admin/bannerMapping/updateBannerMapping/",
    method: "patch",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteTopActivity: {
    url: "api/v1/admin/bannerMapping/deleteBannerMapping/",
    method: "delete",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

//==================Top Activity City===============

export const topActivityCity = {
  getTopActivityCityList: {
    url: "api/v1/admin/topCityActivity/readtopCityActivity",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTopActivityCityDropdown: {
    api: "api/v1/admin/topCityActivity/dropdown",
    method: "get",
  },
  createTopActivityCity: {
    api: "api/v1/admin/topCityActivity/addtopCityActivity",
    method: "post",
  },
  updateTopActivityCity: {
    url: "api/v1/admin/topCityActivity/updateTopCityActivity/",
    method: "patch",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  deleteTopActivityCity: {
    url: "api/v1/admin/topCityActivity/deleteTopCityActivity/",
    method: "delete",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

//==================Top Category Mapping===============

export const topCategoryMapping = {
  getTopCategoryList: {
    url: "api/v1/admin/topCategoryMapping/readtopCategoryMapping",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTopCategoryDropdown: {
    api: "api/v1/admin/topCategoryMapping/dropdown",
    method: "get",
  },
  createTopCategoryMapping: {
    api: "api/v1/admin/topCategoryMapping/addtopCategoryMapping",
    method: "post",
  },
  deleteTopCategoryMapping: {
    url: "api/v1/admin/topCategoryMapping/deleteTopCategoryMapping/",
    method: "delete",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  updateTopCategoryMapping: {
    url: "api/v1/admin/topCategoryMapping/updateTopCategoryMapping/",
    method: "patch",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

//==================Blogs===============

export const blog = {
  getBlogList: {
    url: "api/v1/admin/blog/readBlog",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBlogDetails: {
    url: "api/v1/admin/blog/readOneBlog/",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  updateBlog: {
    url: "api/v1/admin/blog/updateBlog/",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  createBlog: {
    api: "api/v1/admin/blog/addBlog",
    method: "post",
  },
  deleteBlog: {
    url: "api/v1/admin/blog/deleteBlog/",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

// ========================================= City Festival =================================

export const cityfestival = {
  uploadCityfestivalImage: {
    api: "api/v1/admin/city_festival/addCityFestivalImage",
    method: "post",
  },
  createCityFestival: {
    api: "api/v1/admin/city_festival/addCityFestival",
    method: "post",
  },

  updateCityFestival: {
    api: "api/v1/admin/city_festival/updateCityFestival",
    method: "patch",
  },

  deleteCityFestival: {
    url: "api/v1/admin/city_festival/deleteCityFestival",
    method: "delete",
    query: {
      url_id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },

    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    // set addParams(cityFestTitle, cityid) {
    // 	this.cityFestTitle = cityFestTitle;
    // 	this.cityid = cityid;
    // }
  },

  getCityFestivalList: {
    url: "api/v1/admin/city_festival/getCityFestival",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getOneFestivalDetails: {
    url: "api/v1/admin/city_festival/viewCityFestival",
    method: "get",
    query: {
      url_id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

// ========================================= Offers =================================

export const offers = {
  getOfferList: {
    url: "api/v1/admin/offer/readOffer",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createOffer: {
    api: "api/v1/admin/offer/addOffer",
    method: "post",
  },
  deleteOffer: {
    url: "api/v1/admin/offer/deleteOffer/",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  updateOffer: {
    url: "api/v1/admin/offer/updateOffer/",
    method: "patch",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getSingleOffer: {
    url: "api/v1/admin/offer/readOneOffer",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

// ========================================= Offers =================================

export const purchaseOrder = {
  createPurchaseOrder: {
    api: "api/v1/admin/purchase-orders",
    method: "post",
  },
  listPurchaseOrders: {
    url: "api/v1/admin/purchase-orders",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
      searchByOrderid: null,
      searchByWarehouse: null,
      fromDate: null,
      toDate: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    set removeQuery({ key, payload }) {
      this.query = {
        pageNo: 1,
        limit: null,
        search: null,
        orderBy: null,
        filter: null,
        searchByOrderid: null,
        searchByWarehouse: null,
        fromDate: null,
        toDate: null,
      };
    },
  },
  detailPurchaseOrder: {
    url: "api/v1/admin/shadowfax-order/details",
    method: "get",
    query: {
      awb: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  cancelPurchaseOrder: {
    api: "api/v1/admin/shadowfax-order/cancel",
    method: "post",
  },
  downloadPurchaseOrder: {
    url: "v1/admin/download-purchase-order",
    method: "get",
    query: {
      purchaseOrderId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

// ========================================= Vendor =================================

export const notification = {
  getNotificationListDetails: {
    url: "api/v1/admin/notifications",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      search: null,
      orderBy: null,
      filter: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createNotification: {
    api: "api/v1/admin/notifications/send",
    method: "post",
  },
};

//==================Wholesale===============

export const wholesale = {
  getProductsList: {
    url: "api/v1/admin/reseller/wholesale/products",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
    reset() {
      this.query = {
        pageNo: 1,
        limit: null,
      };
    },
  },
  productCreate: {
    api: "api/v1/admin/reseller/wholesale/products",
    method: "post",
  },
  productUpdate: {
    url: "api/v1/admin/reseller/wholesale/products",
    method: "put",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  productDelete: {
    url: "api/v1/admin/reseller/wholesale/products",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
  getProductNames: {
    api: "api/v1/admin/reseller/wholesale/product-names",
    method: "get",
  },
  getWholesaleProductNames: {
    api: "api/v1/admin/reseller/wholesale/wholesale-product-names",
    method: "get",
  },
  getLeadsList: {
    url: "api/v1/admin/reseller/wholesale/leads",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },

    reset() {
      this.query = {
        pageNo: 1,
        limit: null,
      };
    },
  },
  getLeadsListByUser: {
    url: "api/v1/admin/reseller/wholesale/leads",
    method: "get",
    query: {
      pageNo: 1,
      limit: null,
      user_id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  leadCreate: {
    api: "api/v1/admin/reseller/wholesale/create-lead",
    method: "post",
  },
  leadConfirm: {
    api: "api/v1/admin/reseller/wholesale/confirm-lead",
    method: "post",
  },
  leadCreateConfirm: {
    api: "api/v1/admin/reseller/wholesale/create-confirmed-lead",
    method: "post",
  },
  deleteLead: {
    url: "api/v1/admin/reseller/wholesale/lead",
    method: "delete",
    id: "",
    get api() {
      return this.url + "/" + this.id;
    },
    set addParams(id) {
      this.id = id;
    },
  },
};

//==================Special Discount===============

export const specialDiscount = {
  getSpecialDiscount: {
    api: "api/v1/admin/reseller/special-discount",
    method: "get",
  },
  updateSpecialDiscount: {
    api: "api/v1/admin/reseller/special-discount",
    method: "put",
  },
};
