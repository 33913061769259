import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal, ToggleCheckbox } from "../../component/common"
import { deleteCategoryById } from "../../redux/actions/reseller"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getCategories } from "../../redux/actions/reseller"
import { getSNo } from "../../services/helperFunctions"
import { LoaderWrapper } from "../../HOC"
import { directive } from '@babel/types';
import { Link } from 'react-router-dom'
import _ from 'lodash'

class CategoryListClass extends Component {

	state = {
		categories: [],
		headerDetails: [
			{
				label: 'S.No'
			},
			{
				label: 'Name',
			},
			{
				label: 'Description'
			},
			// commented so can use later 
			// {
			// 	label: 'Link',
			// }
		]
	}

	componentWillMount() {

	}

	componentDidMount() {

	}

	handleFilter = (query, value, name) => {
		this.props.getCategories(query)
	}

	handleAddorEdit = (id) => {
		if (id) {
			this.props.history.push(`/reseller/category/edit/${id}`)
		} else {
			this.props.history.push('/reseller/category/create')
		}
	}

	handleDelete = (id) => {
		this.props.deleteCategoryById(id).then(() => {
			window.location.reload()
		})
	}

	render() {

		let { headerDetails } = this.state
		let { categoryList } = this.props
		let IsEmpty = categoryList && categoryList.list && categoryList.list.length > 0

		return (
			<div>
				{/* Header */}
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>L1 Category
					</h4>
					<div className="col-auto ">
						<Link to="/reseller/category/position"><button className="btn btn-primary btn-sm m-1"

						>Category Positions</button></Link>
						{/* commented so can use later */}
						{/* <button className="btn btn-primary btn-sm"
            onClick={() => this.handleAddorEdit()}
          >Add Category</button> */}
					</div>
				</div>

				{/* Content */}
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<h5 className="sair-sec-title align-self-center">L1 Category List</h5>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									pageMeta={categoryList.pageMeta}
									queryHandler={(query) => this.handleFilter(query)}
								>
									{categoryList.list.map((item, index) => {
										let { id, _id, name, descriptions, shareLink } = item

										return (
											<tr
												key={index}  >
												<td>{index + 1}</td>
												<td>{name}</td>
												<td>{descriptions}</td>
												<td>{shareLink}</td>
												{/* commented so can use later */}
												{/* <td>
													<a className="mr-25"
														onClick={e => this.handleAddorEdit(_id)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"
														onClick={e => this.handleDelete(_id)}
													></i>
													</a>
												</td> */}
											</tr>
										)
									})}
								</TableWrapper>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		categoryList: state.reseller.categoryList // data from reducer to categoryList
	}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getCategories,
		deleteCategoryById
	}, dispatch)
};

let actions = ["getCategories"]

let component = LoaderWrapper(CategoryListClass)({ actions })

export const CategoryList = connect(mapStateToProps, mapDispatchToProps)(component)
