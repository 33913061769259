import { activityType } from "../../services/actionType"

const initialState = {
	activityDropDownLists: [],
	activityLists: [], 
	activityDetails: {},
	mappingActivityLists: {},
	categoryMappingDropDownLists: {}

}


export default (state = Object.assign({}, initialState), { type, payload }) => {

	switch (type) {

		case activityType.updateActivityDropDown:
			return {
				...state,
				activityDropDownLists: payload
			}

		case activityType.updateActivityListDetails:
			return {
				...state,
				activityLists: payload
			}
		case activityType.getActivityDetailsById:
			return {
				...state,
				activityDetails: payload
			}
		case activityType.updateCategoryMappingListDetails:
			return {
				...state,
				mappingActivityLists: payload
			}
		case activityType.updateCategoryMappingDropDown:
			return {
				...state,
				categoryMappingDropDownLists: payload
			}

		default:
			return state

	}
}