import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import {Link} from 'react-router-dom'
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal, ToggleCheckbox } from "../../component/common"
import {ReactSelect} from '../../component/common/ReactSelect'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getSellerList} from "../../redux/actions/reseller"
import { getSNo } from "../../services/helperFunctions"
import { LoaderWrapper } from "../../HOC"
import { directive } from '@babel/types';
import _ from 'lodash'

class SellerListClass extends Component {

  state = {
    sellerList: [],
    headerDetails: [
      {
        label: 'S.No'
      },
      {
        label: 'Name',
      },
      {
        label: 'Email'
      },
      {
        label: 'GST Number',
      },
      {
        label: 'PAN Number',
      },
      {
        label: 'Address',
      },
      {
        label: 'Edit'
      }
    ]
  }


  componentWillMount() {

  }

  componentDidMount() {
		let query={
			pageNo:1
    };
    this.props.getSellerList(query)
  }

  handleAddorEdit = (id) => {
		if (id) {
			this.props.history.push(`/reseller/sellers/edit/${id}`)
    }
  }
  
  handleSearchFilter = (query, value, name) => {
		this.props.getSellerList(query)
  }
  handleTopFilter = () => {
    let query={
      pageNo:1
    };
    query['sellerId']=this.state.sellerId;
    query['category']=this.state.category;
    this.props.getBrands(query)
  }
  handleFilter = (query) =>{
    this.props.getSellerList(query)
  }
  handleSelect = ({name, value }) => {
    this.setState({
    [name]: value
    },this.setInLocaStorage(name,value))
  }
  setInLocaStorage =(name,value)=>{
		if(name==='category' || name==='sellerId'){
			window.localStorage.setItem(name,value)
		}
	}
  
  render() {
    let { headerDetails} = this.state
    let { sellerList } = this.props
    let IsEmpty = sellerList && sellerList.list && sellerList.list.length > 0
    return (
      <div>
        {/* Header */}
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Seller List
          </h4>
          <div className="col-auto ">
          </div>
        </div>
        {/* Content */}
        <section className="sair-sec-wrapper">
          <div className="row">
            <div className="col-sm">
            <div className="row no-gutters">
            <div className="col-md-3 form-group">
            <label><b>Search by Seller name/email</b></label>
                <Filters
                    className="floatp-0"
                    queryHandler={(query) => this.handleSearchFilter(query)}
                    isDropDownFilter={false}
                />
            </div>
            </div>
              <div className="table-wrap mt-3">
                <TableWrapper
                  headerDetails={headerDetails}
                  isEmpty={IsEmpty}
                  pageMeta={sellerList && sellerList.pageMeta}
                  queryHandler={(query) => this.handleFilter(query)}
                >
                  {sellerList && sellerList.list && sellerList.list.map((item, index) => {
                    let { id,first_name, last_name, email, gst, pan ,address  } = item
                    return (
                      <tr
                        key={index}  >
                        <td>{index + 1}</td>
                        <td>{first_name}</td>
                        <td>{email}</td>
                        <td>{gst}</td>
                        <td>{pan}</td>
                        <td>{address}</td>
                        <td>
                          <a className="mr-25"
                            onClick={e => this.handleAddorEdit(id)}
                          >
                            <i className="icon-pencil  text-primary cursor-pointer"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </TableWrapper>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    sellerList: state.reseller.sellerList // data from reducer to brandList
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getSellerList,
  }, dispatch)
};

let actions = []

let component = LoaderWrapper(SellerListClass)({ actions })

export const SellerList = connect(mapStateToProps, mapDispatchToProps)(component)
