import { vendorType } from "../../services/actionType"


const initialState = {
    vendorMappingList: {},
    vendorMappingDropdownList: {},
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {

        case vendorType.updateVendorMappingDetails:
            return {
                ...state,
                vendorMappingList: payload
            }
        case vendorType.updateVendorMappingDropdown:
            return {
                ...state,
                vendorMappingDropdownList: payload
            }
        default:
            return state

    }
}