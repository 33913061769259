import { resellerType } from "../../services/actionType"
const initialState = {
  categoryList: [],
  categoryLevelTwoList: [],
  categoryLevelThreeList: [],
  categoryDetails: {},
  brandList: [],
  brandDetails: {},
  productList: [],
  productDetails: {},
  sellerList:[],
  bannerList:[],
  customerBannerList:[],
  wareHouseList:[]
}

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case resellerType.getCategories:
      return {
        ...state,
        categoryList: payload
      }
    case resellerType.getLevelTwoCategories:
      return {
        ...state,
        categoryLevelTwoList: payload
      }
    case resellerType.getLevelThreeCategories:
      return {
        ...state,
        categoryLevelThreeList: payload
      }
    case resellerType.getCategoryDetailsById:
      return {
        ...state,
        categoryDetails: payload
      }
    case resellerType.getBrands:
      return {
        ...state,
        brandList: payload
      }
    case resellerType.getBrandDetailsById:
      return {
        ...state,
        brandDetails: payload
      }
    case resellerType.getProducts:
      return {
        ...state,
        productList: payload
      }
    case resellerType.getBrandDetailsById:
      return {
        ...state,
        productDetails: payload
      }
    case resellerType.sellerList:
      return {
        ...state,
        sellerList: payload
      }
    case resellerType.getbannerList:
      return {
        ...state,
        bannerList: payload
      }
    case resellerType.getcustomerBannerList:
      return {
        ...state,
        customerBannerList: payload
      }
    case resellerType.getWareHouseList:
      return {
        ...state,
        wareHouseList: payload
      }
    default:
      return state
  }
}
