import { orders } from "../../services/apiVariables"
import { ordersType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"
import { api } from "../../services/api"

export const getOrdersList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })
        console.log(query);
        addQuery(query, orders.getOrdersList)
        console.log('orders.getOrdersList', orders.getOrdersList);
        dispatch({ type: ordersType.fetchOrders })

        api(orders.getOrdersList).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: ordersType.updateOrdersDetails, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const getGtmOrdersList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })
        console.log(query);
        addQuery(query, orders.getGtmOrdersList)
        console.log('orders.getGtmOrdersList', orders.getGtmOrdersList);
        dispatch({ type: ordersType.fetchOrders })

        api(orders.getGtmOrdersList).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: ordersType.updateOrdersDetails, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const getVendorListDropdown = () => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api(orders.getVendorListDropdown).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: ordersType.getVendorListDropdown, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const getOrderDetail = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        orders.getSingleOrder.addQuery = { payload: id }

        api(orders.getSingleOrder).then(({ data, message }) => {

            resolve(data)

            dispatch({ type: ordersType.updateSingleOrderDetail, payload: data })

            dispatch({ type: common.updateLoader, payload: false })


        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const confirmOrder = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api({ ...orders.confirmOrder, body }).then(({ data, message }) => {

            resolve(true)

            dispatch({ type: ordersType.confirmOrder, payload: data })

            dispatch({ type: common.updateLoader, payload: false })

            Toast({ type: 'success', message })

        }).catch(({ message }) => {

            Toast({ type: 'error', message })

        })

    })

}

export const updatePaymentStatus = (body) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        api({ api: 'api/v1/admin/reseller/commission/payment-status', method: 'put', body }).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    });
}

export const cancelOrder = (body) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        api({ api: 'api/v1/admin/orders/cancel', method: 'put', body }).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    });
}

export const addOrderRemarks = (id, body) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let url = 'api/v1/admin/reseller/order-remarks/' + id
        api({ api: url, method: 'post', body }).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    });
}

export const getOrderRemarks = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let url = 'api/v1/admin/reseller/order-remarks/' + id
        api({ api: url, method: 'get' }).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    });
}
export const getOrderInventory = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let url = 'api/v1/admin/order/stock-info/?orderId=' + id
        api({ api: url, method: 'get' }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    });
}
export const generateInvoiceByOrderId = (orderId) => () => {
    return new Promise((resolve, reject) => {
        let url = `api/v1/admin/zoho-books-invoice?orderId=${orderId}`
        api({ api: url, method: 'post' }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    });
}

export const updateOrderDocStatus = (orderId, body) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        api({ api: `api/v1/admin/orders/doc-status/${orderId}`, method: 'put', body }).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    });
}

export const saveDraftOrder = (orderId, body) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        api({ api: `api/v1/admin/orders/draft/${orderId}`, method: 'post', body }).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    });
}