import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import SimpleReactValidator from 'simple-react-validator';


import { createExperienceGroup, getExperienceGroupListDetails, editExperienceGroup } from "../../redux/actions/master"

export class ExperienceGroupModalClass extends Component {

	state = {
		experienceGroupDetails: {
			name_of_group: '',

		},
		isEdit: false,

	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}


	componentWillReceiveProps(props) {

		let { modalDetails, isEdit } = props


		this.setState({
			experienceGroupDetails: modalDetails,
			isEdit: isEdit,
		})
	}


	handleInputs = ({ target: { value, name } }) => {

		let experienceGroupDetails = Object.assign({}, this.state.experienceGroupDetails)

		experienceGroupDetails[name] = value

		this.setState({
			experienceGroupDetails
		})


	}

	handleSubmit = () => {

		if (this.validator.allValid()) {

			let { experienceGroupDetails: { name_of_group, id = '' }, isEdit } = this.state

			let body = {
				name_of_group: name_of_group
			}

			if (!isEdit) {

				this.props.createExperienceGroup(body).then(() => {

					this.props.getExperienceGroupListDetails().then(() => {
						let temp = {
							name: '',
						}

						this.setState({
							isEdit: false,
							experienceGroupDetails: temp

						})

					})
				})
			} else {

				this.props.editExperienceGroup(id, body).then((message) => {

					this.props.getExperienceGroupListDetails().then(() => {
						let temp = {
							name: '',
						}

						this.setState({
							isEdit: false,
							experienceGroupDetails: temp

						})

					})

				})
			}

			this.props.toggle()

			this.validator.hideMessages();

		} else {
			this.setState({
				isError: true
			})
			this.validator.showMessages();

		}
	}

	render() {

		let { isError, isEdit, toggle } = this.props

		let { experienceGroupDetails } = this.state
		this.validator.purgeFields();

		let { name_of_group } = experienceGroupDetails

		return (
			<section className="side-slider h-100">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {isEdit ? "Edit Experience Group" : "Add Experience Group"}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Name of the group</label>
							<input
								className={`form-control ${this.validator.message('name', name_of_group, 'required') ? 'inValid' : ''}`} id="title"
								placeholder=""
								name="name_of_group"
								value={name_of_group}
								type="text"
								onChange={this.handleInputs}


							/>
							{this.validator.message('name', name_of_group, 'required')}
						</div>

					</div>

					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>{isEdit ? "Update" : "Submit"}</button>
				</div>
			</section>
		)
	}
}



const mapStateToProps = state => {

	return {}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createExperienceGroup,
		getExperienceGroupListDetails,
		editExperienceGroup,
	}, dispatch)
};



export const ExperienceGroupModal = connect(mapStateToProps, mapDispatchToProps)(ExperienceGroupModalClass)

