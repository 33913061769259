import React, { Component } from 'react'
import { FileUpload } from "../common/fileUpload"

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import SimpleReactValidator from 'simple-react-validator';
import { createCategory, getCategoryListDetails, editCategory } from "../../redux/actions/master"


class CategoryModalClass extends Component {

	state = {
		categoryDetails: {
			title: '',
			cover_image: ' ',
			description: '',
		},
		tempFile: '',
		isEdit: false
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.fileUpload = React.createRef();
		this.ImagePreview = this.ImagePreview.bind(this);
	}


	componentWillReceiveProps(props) {
		let { modalDetails, isEdit } = props
		let { url = '' } = modalDetails

		let tempFile = ''

		if (url) {
			tempFile = url
		}

		console.log(modalDetails, "asdasd21312312312dsdasdasd")

		this.setState({
			categoryDetails: modalDetails,
			isEdit: isEdit,
			tempFile
		})
	}

	handleInputs = ({ target: { value, name } }) => {

		let categoryDetails = Object.assign({}, this.state.categoryDetails)

		categoryDetails[name] = value

		this.setState({
			categoryDetails
		})


	}

	ImagePreview = (event) => {

		let categoryDetails = this.state.categoryDetails

		categoryDetails.cover_image = event.target.files[0]

		this.setState({
			tempFile: URL.createObjectURL(event.target.files[0]),
			categoryDetails,
		});

	}

	handleSubmit = () => {

		if (this.validator.allValid()) {

			let { categoryDetails, isEdit } = this.state

			let { id, title, cover_image, description } = categoryDetails

			let formData = new FormData()

			formData.append("title", title)

			formData.append("cover_image", cover_image)

			formData.append("description", description)

			if (!isEdit) {

				this.props.createCategory(formData).then((data) => {

					this.props.getCategoryListDetails().then(() => {
						let temp = {
							title: '',
							cover_image: '',
							tempFile: ''
						}

						this.setState({
							isEdit: false,
							categoryDetails: temp

						})

					})
					this.props.toggle()

				})

			} else {

				this.props.editCategory(id, formData).then((data) => {

					this.props.getCategoryListDetails().then(() => {

						let temp = {
							title: '',
							cover_image: '',
							tempFile: ''
						}

						this.setState({
							isEdit: false,
							categoryDetails: temp

						})


					})
					this.props.toggle()
				})
			}



			this.validator.hideMessages();

		} else {
			this.setState({
				isError: true
			})
			this.validator.showMessages();

		}
	}

	render() {

		let { isError, isEdit, toggle } = this.props

		this.validator.purgeFields();

		let { categoryDetails, tempFile } = this.state

		let { title, cover_image, description } = categoryDetails


		return (
			<section className="side-slider h-100">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {isEdit ? "Edit Category" : "Add Category"}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Category name</label>
							<input
								className={`form-control ${this.validator.message('title', title, 'required') ? 'inValid' : ''}`} id="title"
								placeholder=""
								name="title"
								value={title}
								type="text"
								onChange={this.handleInputs}


							/>
							{this.validator.message('title', title, 'required')}
						</div>

						<div className="col-md-12 form-group">
							<label>Category Images </label>
							<FileUpload
								fileUpload={this.fileUpload}
								ImagePreview={this.ImagePreview}
								filename={cover_image}
								tempFile={tempFile}
							/>
							{this.validator.message('image', cover_image, 'required')}

						</div>
						<div className="col-md-12 form-group">
							<label>Description</label>
							<textarea
								className={`form-control ${this.validator.message('description', description, 'required') ? 'inValid' : ''}`} id="title"
								placeholder=""
								name="description"
								value={description}
								type="text"
								onChange={this.handleInputs}
							></textarea>
							{this.validator.message('description', description, 'required')}
						</div>
					</div>

					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>{isEdit ? "Update" : "Submit"}</button>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {

	return {}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getCategoryListDetails,
		createCategory,
		editCategory,
	}, dispatch)
};

export const CategoryModal = connect(mapStateToProps, mapDispatchToProps)(CategoryModalClass)
