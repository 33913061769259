import React from "react";

export const NormalInput = ({
  number = true,
  className,
  placeholder,
  onChange,
  isNumeric = false,
  value = "",
  name,
  maxChar = "",
  validationError = "",
  extraMessage = "",
  disabled = false,
  type = "text",
}) => {
  return (
    <>
      <input
        className={className}
        placeholder={placeholder}
        value={value}
        name={name}
        disabled={disabled}
        type={type}
        onChange={(e) => {
          let body = {};

          body = {
            name: e.target.name,
            value: /\d+(\.\d+)?$/.test(e.target.value)
              ? isNaN(Number(e.target.value))
                ? e.target.value
                : Number(e.target.value)
              : e.target.value,
          };

          if (maxChar && e.target.value.length > maxChar) {
            body = {
              name: e.target.name,
              value: /(\d+(\.\d+)?)/.test(value)
                ? isNaN(Number(value))
                  ? value
                  : Number(value)
                : value,
            };
          }

          if (!number) {
            body.value = e.target.value;
          }
          if (body) {
            // console.log('====================================');
            // console.log(body, "body");
            // console.log('====================================');
            onChange(body);
          }
        }}
      />
      {validationError ? (
        validationError
      ) : maxChar && value.length > maxChar ? (
        <span className="error-message font-md">Character limit Exceed</span>
      ) : (
        extraMessage
      )}
    </>
  );
};
