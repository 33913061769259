import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { NormalInput } from "../../component/common";

export const Schema = Yup.object().shape({
  final_qty: Yup.string().required("Required"),
  final_price: Yup.string().required("Required"),
});

const AddProductLeadItem = ({ item, onItemSubmit }) => {
  const formikProps = useFormik({
    initialValues: {
      final_qty: item.final_qty || "",
      final_price: item.final_price || "",
      wholesale_id: item._id,
    },
    onSubmit: (values) => {
      onItemSubmit(values);
    },
    validationSchema: Schema,
  });

  const productInfo =
    item.product_info && item.product_info.length > 0
      ? item.product_info[0]
      : {};

  const imgLink =
    productInfo.imgLinks && productInfo.imgLinks.length > 0
      ? productInfo.imgLinks[0].link
      : "";

  console.log("item", item);

  return (
    <div className="row mt-20">
      <div className="col-sm-12">
        <div className="d-flex align-items-center">
          <div className="mr-10">
            <img
              className="w-90p h-110p"
              src={imgLink}
              alt={productInfo.name}
            />
          </div>
          <div className="text-center">
            <p>{productInfo.name}</p>
            <p className="mt-10 font-12">
              case of {item.wholesale_info ? item.wholesale_info.case_size : ""}
              pcs
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-12 mt-10">
        <div className="row align-items-end">
          <div className="col-sm-4">
            <p className="text-center">Variant</p>
            <div className="row mt-4">
              <div className="col-sm-6">
                <ul>
                  <li className="font-12 text-center mb-10">Case Qty.</li>
                  {item.variants.map((variant, index) => (
                    <li className="text-center mb-10" key={`variant_${index}`}>
                      {variant.qty_range}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-6">
                <ul>
                  <li className="font-12 text-center mb-10">
                    Apnaklub Price/Unit
                  </li>
                  {item.variants.map((variant, index) => (
                    <li className="text-center mb-10" key={`variant_${index}`}>
                      {variant.price_range}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <p className="text-center">Final Price</p>

            <div className="text-center mt-2 w-120p mx-auto">
              <label className="font-12 mb-2">Case qty.</label>
              <NormalInput
                name="final_qty"
                value={formikProps.values.final_qty}
                className="form-control text-center text-dark-20"
                onChange={(e) => {
                  formikProps.setFieldValue("final_qty", e.value);
                }}
              />
              {formikProps.touched.final_qty &&
                formikProps.errors.final_qty && (
                  <span className="error-message font-md">
                    {formikProps.errors.final_qty}
                  </span>
                )}
            </div>

            <div className="text-center mt-1 w-120p mx-auto">
              <label className="font-12 mb-2">Apnaklub Price/Unit</label>
              <NormalInput
                name="final_price"
                value={formikProps.values.final_price}
                className="form-control text-center text-dark-20"
                onChange={(e) => {
                  formikProps.setFieldValue("final_price", e.value);
                }}
              />
              {formikProps.touched.final_price &&
                formikProps.errors.final_price && (
                  <span className="error-message font-md">
                    {formikProps.errors.final_price}
                  </span>
                )}
            </div>
          </div>
          <div className="col-sm-3">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              onClick={formikProps.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductLeadItem;
