import { topCategoryMapping } from "../../services/apiVariables"
import { topCategoryType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getTopCategoryList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        addQuery(query, topCategoryMapping.getTopCategoryList)

        api(topCategoryMapping.getTopCategoryList).then(({ data, message }) => {

            dispatch({ type: topCategoryType.updateTopCategoryDetails, payload: data })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })


        })

    })
}

export const getTopCategoryDropdown = () => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api(topCategoryMapping.getTopCategoryDropdown).then(({ data, message }) => {

            dispatch({ type: topCategoryType.updateTopCategoryDropdown, payload: data })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const createTopCategory = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api({ ...topCategoryMapping.createTopCategoryMapping, body }).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const deleteTopCategory = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        topCategoryMapping.deleteTopCategoryMapping.addParams = id;

        api(topCategoryMapping.deleteTopCategoryMapping).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const updateTopCategory = (id, body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        topCategoryMapping.updateTopCategoryMapping.addParams = id;

        api({ ...topCategoryMapping.updateTopCategoryMapping, body }).then(({ data, message }) => {

            Toast({ type: 'success', message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}