import React, { Component } from 'react'
import { FileUpload, ReactAutoComplete, NormalInput, ReactSelect} from "../../component/common"
import { TextEditorActivity} from "../../component/common/TextEditorActivity"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { SAILMALL_BASE_URL } from "../../config/config"

import SimpleReactValidator from 'simple-react-validator';
import {createWareHouse} from "../../redux/actions/reseller"

import { TextEditor, DateTime } from '../../component/common';

import { hidePastDate } from "../../services/helperFunctions"

import _ from "lodash"
import { log } from 'util';

class WareHouseCreateClass extends Component {
    state = {
            name:"",
            email:"",
            contact:"",
            address_line_1:"",
            address_line_2:"",
            city:"",
            state:"",
            pincode:"",
            gstin:""
    }
    componentWillMount() {
        this.validator = new SimpleReactValidator({
          element: message => <span className="error-message font-md">{message}</span>,
          autoForceUpdate: this,
          messages: {
            int: 'this :attribute required here'
          }
        });
    }
    handleInputs=  ({name, value }) => {
        this.setState({
            [name]: value,
        })
    }
    handleSubmit() {
        let { name,
        email,
        contact,
        address_line_1,
        address_line_2,
        city,
        state,
        pincode,gstin} = this.state
        // CREATE FLOW
        if (this.validator.allValid()) {
            let body = { name,
                email,
                contact,
                address_line_1,
                address_line_2,
                city,
                state,
                pincode ,gstin}
            this.props.createWareHouse(body).then((data) => {
            let { history } = this.props
            this.setState({ disabled: false })
            history.push('/reseller/warehouses')
            }).catch((ex) => {
            this.setState({ disabled: false })
            })
        } else {
            this.validator.showMessages();
        }
    }
  render() {
    let {name,email,contact,address_line_1,address_line_2,city,state,pincode,gstin}= this.state;
    return (
      <div>
          {/* Header */}
          <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span> Warehouse
					</h4>
        </div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Name</label>
                    <NormalInput
                      className="form-control"
                      name="name"
                      type="text"
                      value={name}
                      onChange={({ name, value }) => {
                        this.handleInputs({ name: name, value: value })
                      }}
                      validationError={this.validator.message('warehouse name', name, 'required')}
                      number={false}
                  />
                  </div>
                  <div className="col-md-6 form-group">
                    <label > Email </label>
                    <NormalInput
                        className="form-control"
                        name="email"
                        type="text"
                        value={email}
                        onChange={({ name, value }) => {
                            this.handleInputs({ name: name, value: value })
                        }}
                        number={false}
                        validationError={this.validator.message('warehouse email', contact, "required")}
                    />
                    </div>
                  <div className="col-md-6 form-group">
                    <label > contact</label>
                    <NormalInput
                        className="form-control"
                        name="contact"
                        type="text"
                        value={contact}
                        onChange={({ name, value }) => {
                        this.handleInputs({ name: name, value: value })
                        }}
                        number={false}
                        validationError={this.validator.message('warehouse contact starts with 6-9 and have 10 character length', contact, "required|regex:^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$")}
                        
                    />
                    </div>
                <div className="col-md-6 form-group">
                  <label >address line 1 </label>
                  <NormalInput
                    className="form-control"
                    name="address_line_1"
                    type="text"
                    value={address_line_1}
                    onChange={({ name, value }) => {
                      this.handleInputs({ name: name, value: value })
                    }}
                    validationError= {this.validator.message(`Warehouse business address `, address_line_1, 'min:12')}
                    number={false}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label >address line 2 </label>
                  <NormalInput
                    className="form-control"
                    name="address_line_2"
                    type="text"
                    value={address_line_2}
                    onChange={({ name, value }) => {
                      this.handleInputs({ name: name, value: value })
                    }}
                    validationError={this.validator.message('warehouse business address line two', name, 'required')}
                    number={false}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label > city </label>
                  <NormalInput
                    className="form-control"
                    name="city"
                    type="text"
                    value={city}
                    onChange={({ name, value }) => {
                      this.handleInputs({ name: name, value: value })
                    }}
                    validationError={this.validator.message('warehouse city', name, 'required')}
                    number={false}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label > State</label>
                  <NormalInput
                    className="form-control"
                    name="state"
                    type="text"
                    value={state}
                    onChange={({ name, value }) => {
                      this.handleInputs({ name: name, value: value })
                    }}
                    validationError={this.validator.message('warehouse state', name, 'required')}
                    number={false}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label >pincode </label>
                  <NormalInput
                    className="form-control"
                    name="pincode"
                    type="text"
                    value={pincode}
                    onChange={({ name, value }) => {
                      this.handleInputs({ name: name, value: value })
                    }}
                    validationError={this.validator.message('warehouse pincode', name, 'required')}
                    number={false}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label >GSTIN</label>
                  <NormalInput
                    className="form-control"
                    name="gstin"
                    type="text"
                    value={gstin}
                    onChange={({ name, value }) => {
                      this.handleInputs({ name: name, value: value })
                    }}
                    validationError={this.validator.message('warehouse gstin', name, 'required')}
                    number={false}
                  />
                </div>
                </div>
        <hr />
          <button className="btn btn-primary"
            onClick={() => this.handleSubmit()}
            disabled={this.state.disabled}
          >Submit</button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    createWareHouse
  }, dispatch)
}

export const WareHouseCreate = connect(mapStateToProps, mapDispatchToProps)(WareHouseCreateClass)
