import React, { Component } from 'react';
import { ReactSelect } from '../common';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"

import { getActivityListDropdown, createTopActivity, getTopActivityList, updateTopActivity } from '../../redux/actions/topactivity';

import SimpleReactValidator from 'simple-react-validator';

export class TopActivityMappingModalClass extends Component {

    state = {
        activityDropdownOptions: [],
        activity_id: '',
        id: '',
    }

    componentWillReceiveProps({ activityId, mapId }) {

        let activity_id = "";

        let id = null;

        if (activityId) {

            activity_id = activityId

            id = mapId

        }

        this.setState({
            activity_id,
            id,
        })

    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message ">{message}</span>,
            autoForceUpdate: this,
            messages: {}
        });

    }

    componentDidMount() {

        let { activityDropdownList } = this.props;

        let activityDropdownOptions = this.state.activityDropdownOptions.slice(0)

        activityDropdownList.map((item) => {

            activityDropdownOptions.push({
                label: item.title,
                value: item.id,
            })

        })

        this.setState({
            activityDropdownOptions
        })

    }

    handleInputs = ({ value, name }) => {

        this.setState({
            [name]: value
        })


    }

    handleSubmit = () => {

        if (this.validator.allValid()) {

            if (!this.state.id) {

                this.props.createTopActivity({ activity_id: this.state.activity_id }).then(() => {

                    this.props.getTopActivityList({ pageNo: 1 });

                    this.props.toggle();

                })

            } else {

                this.props.updateTopActivity(this.state.id, { activity_id: this.state.activity_id }).then(() => {

                    this.props.getTopActivityList({ pageNo: 1 });

                    this.props.toggle();

                })

            }

        } else {

            this.validator.showMessages();

        }

    }

    render() {

        let { toggle } = this.props;

        let { activityDropdownOptions, activity_id } = this.state;

        console.log(activity_id, "asdasdadasdasdadasdad")

        return (
            <>
                <div className="slider-header p-4">
                    <div className="d-flex align-items-center">
                        <h5 className="sair-sec-title">Add Top Activity Mapping</h5>
                        <div className="ml-auto">
                            <i
                                className="icon-close font-md px-2 cursor-pointer"
                                onClick={toggle}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="slider-body p-4">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Activity</label>
                            <ReactSelect
                                isMulti={false}
                                options={activityDropdownOptions}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="activity_id"
                                value={activity_id}
                                disabled={false}
                            />
                            {this.validator.message('Activity', activity_id, 'required')}
                        </div>
                    </div>
                    <hr />
                    <button
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                    >Submit</button>
                </div>

            </>
        )

    }

}

const mapStateToProps = state => {
    return {
        activityDropdownList: state.topactivity.activityDropdownList,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getActivityListDropdown,
        createTopActivity,
        getTopActivityList,
        updateTopActivity
    }, dispatch)
};


let actions = ['getActivityListDropdown']

let component = LoaderWrapper(TopActivityMappingModalClass)({ actions })

export const TopActivityMappingModal = connect(mapStateToProps, mapDispatchToProps)(component)