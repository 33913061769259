import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { NormalInput } from "../../component/common";

export const Schema = Yup.object().shape({
  final_qty: Yup.string()
    .required("Required")
    .test("raneInvalid", "Invalid value", function (value) {
      if (!value) return true;
      const qtyRange = this.parent.qty_range;
      if (qtyRange.includes("-")) {
        const qtyRangea = qtyRange.split("-");
        const min = parseFloat(qtyRangea[0]);
        const max = parseFloat(qtyRangea[1]);
        return value >= min && value <= max;
      }
      return true;
    }),
  final_price: Yup.string()
    .required("Required")
    .test("raneInvalid", "Invalid value", function (value) {
      if (!value) return true;
      const priceRange = this.parent.price_range;
      if (priceRange.includes("-")) {
        const priceRanges = priceRange.split("-");
        const min = parseFloat(priceRanges[0]);
        const max = parseFloat(priceRanges[1]);
        return value >= min && value <= max;
      }
      return true;
    }),
});

const PartnerLeadItemRow = ({ item, index, onItemSubmit, onDelete }) => {
  const formikProps = useFormik({
    initialValues: {
      final_qty: item.final_qty || "",
      final_price: item.final_price || "",
      price_range: item.selected_variant.price_range,
      qty_range: item.selected_variant.qty_range,
      lead_id: item._id,
    },
    onSubmit: (values) => {
      onItemSubmit(values);
    },
    validationSchema: Schema,
  });
  const imgLink =
    item.product_info &&
    item.product_info.imgLinks &&
    item.product_info.imgLinks.length > 0
      ? item.product_info.imgLinks[0].link
      : "";

  const isConfirmed = item.status === "confirmed";

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <div className="d-flex">
          <div className="mr-10">
            <img
              className="w-90p h-110p"
              src={imgLink}
              alt={item.product_info && item.product_info.name}
            />
          </div>
          <div className="text-center">
            <p>{item.product_info && item.product_info.name}</p>
            <p className="mt-10 font-12">
              case of {item.wholesale_info ? item.wholesale_info.case_size : ""}
              pcs
            </p>
          </div>
        </div>
      </td>
      <td>{isConfirmed ? 'Yes' : 'No'}</td>
      <td>
        <div className="text-center">
          <p className="font-12">Case Qty.</p>
          <p className="font-16">{item.selected_variant.qty_range}</p>
        </div>
        <div className="mt-4 text-center">
          <p className="font-12">Apnaklub Price/Unit</p>
          <p className="font-16">{item.selected_variant.price_range}</p>
        </div>
      </td>
      <td>
        <div className="text-center w-120p">
          <label className="font-12 mb-2">Case Qty.</label>
          <NormalInput
            name="final_qty"
            value={formikProps.values.final_qty}
            className="form-control text-center text-dark-20"
            onChange={(e) => {
              formikProps.setFieldValue("final_qty", e.value);
            }}
            disabled={isConfirmed}
          />
          {formikProps.touched.final_qty && formikProps.errors.final_qty && (
            <span className="error-message font-md">
              {formikProps.errors.final_qty}
            </span>
          )}
        </div>
        <div className="text-center mt-4 w-120p">
          <label className="font-12 mb-2">Apnaklub Price/Unit</label>
          <NormalInput
            name="final_price"
            value={formikProps.values.final_price}
            className="form-control text-center text-dark-20"
            onChange={(e) => {
              formikProps.setFieldValue("final_price", e.value);
            }}
            disabled={isConfirmed}
          />
          {formikProps.touched.final_price &&
            formikProps.errors.final_price && (
              <span className="error-message font-md">
                {formikProps.errors.final_price}
              </span>
            )}
        </div>
      </td>
      <td>
        {/* <button className="btn btn-default">
          <i className="icon-pencil cursor-pointer" />
        </button> */}
        <button className="btn btn-default" onClick={onDelete}>
          <i className="icon-trash cursor-pointer" />
        </button>
        {!isConfirmed && (
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            onClick={formikProps.handleSubmit}
            disabled={isConfirmed}
          >
            Submit
          </button>
        )}
      </td>
    </tr>
  );
};

export default PartnerLeadItemRow;
