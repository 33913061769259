import React, { Component } from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"

import { getTopActivityCityList, deleteTopActivityCity } from '../../redux/actions/topactivitycity';

import { TableWrapper, SliderOverlay, ConfirmModal } from '../../component/common';

import { getSNo } from '../../services/helperFunctions';

import { TopActivityCityModal } from '../../component/TopActivityCity';

class TopActivityCityCLass extends Component {

    state = {
        headerDetails: [{
            label: "S.No",
        }, {
            label: "Activity Title",
        },
        // {
        //     label: "Traveller Description",
        // }, 
        {
            label: "City",
        }, {
            label: "Action",
        }],
        isOpen: false,
        activity_id: null,
        city_id: null,
        ConfirmModalOpenStatus: false,
        currentId: null,
        details: {}
    }

    handleFilter = (query, value, name) => {

        if (name == "filter") {
            this.setState({
                selectedFilter: query
            })

            query = {
                pageNo: 1,
                filter: query
            }
        }

        this.props.getTopActivityCityList(query)

    }

    handleAdd = () => {

        let details = Object.assign({}, this.state.details)

        details = {
            activity_id: "",
            city_id: "",
        }

        this.setState({
            details,
        })

        this.toggle('isOpen')

    }

    toggle = name => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }));
    }

    handleEdit = ({ id, activity_id, city_id }) => {

        let details = Object.assign({}, this.state.details)

        details = {
            activity_id,
            city_id,
            id,
        }

        this.setState({
            details
        })

        this.toggle('isOpen')

    }

    handleDeleteToggle = (id) => {

        this.setState({
            currentId: id
        })

        this.toggle("ConfirmModalOpenStatus")

    }

    handleDelete = () => {

        let { currentId } = this.state;

        this.props.deleteTopActivityCity(currentId).then(() => {

            this.props.getTopActivityCityList({ pageNo: 1 })

            this.toggle("ConfirmModalOpenStatus")

        })

    }

    render() {

        let { topActivityCityList } = this.props;

        let {
            list = [], pageMeta = {},
        } = topActivityCityList;

        let { headerDetails, isOpen, activity_id, city_id, details, ConfirmModalOpenStatus } = this.state;

        let IsEmpty = list.length > 0;

        return (
            <div>
                <div class="sair-pg-header">
                    <h4 class="sair-pg-title align-self-center">
                        <span class="pg-title-icon">
                            <span class="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>
                        Top Activity City

                    </h4>
                    <div className="col-auto ">
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={this.handleAdd}
                        >
                            Add Top Activity City
                        </button>
                    </div>
                </div>
                <section className="sair-sec-wrapper">
                    <div className="row">
                        <div className="col-sm">
                            <div className="row no-gutters">
                                <h5 className="sair-sec-title align-self-center">
                                    Top Activity City List
                                </h5>
                            </div>
                            <div className="table-wrap mt-3">
                                <TableWrapper
                                    headerDetails={headerDetails}
                                    isEmpty={IsEmpty}
                                    pageMeta={pageMeta}
                                    queryHandler={(query) => this.handleFilter(query)}
                                >
                                    {list.map((item, index) => {

                                        let {
                                            Activity,
                                            Category,
                                            activity_id,
                                            city_id,
                                            City,
                                            id,
                                        } = item;

                                        // let {
                                        //     // title: ActivityTitle,
                                        //     // traveller_des,
                                        // } = Activity;
                                        console.log(item, "sdasdasdad423423dasdasdas")

                                        let activityTitle = Activity && Activity.title ? Activity.title : "";

                                        let { city_name } = City;


                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {getSNo(pageMeta, index)}
                                                </td>
                                                <td>{activityTitle}</td>
                                                {/* <td>{traveller_des}</td> */}
                                                <td>{city_name}</td>
                                                <td>
                                                    <a
                                                        className="mr-25"
                                                        onClick={() => this.handleEdit({ id, activity_id, city_id })}
                                                    >
                                                        <i className="icon-pencil  text-primary cursor-pointer"
                                                        ></i>
                                                    </a>
                                                    <a >
                                                        <i
                                                            className="icon-trash txt-danger text-primary cursor-pointer"
                                                            onClick={e => this.handleDeleteToggle(id)}
                                                        ></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )

                                    })}
                                </TableWrapper>
                            </div>
                        </div>
                    </div>
                </section>
                <SliderOverlay isOpen={isOpen} toggle={() => this.toggle('isOpen')} size='30%'>
                    <div className="side-slider h-100 position-relative">
                        <div className="h-100 flex-with-center">
                            {console.log(activity_id, "sadasdadasdasd")}
                            <TopActivityCityModal
                                toggle={() => this.toggle('isOpen')}
                                details={details}
                            />
                        </div>
                    </div>
                </SliderOverlay>
                <ConfirmModal
                    keyName="ConfirmModalOpenStatus"
                    toggle={this.toggle}
                    isOpen={ConfirmModalOpenStatus}
                    handleSubmit={() => this.handleDelete()}
                    submitText='Delete'
                >
                    <h5>
                        Are you sure want to delete Top Activity City?
					</h5>
                </ConfirmModal>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        topActivityCityList: state.topactivitycity.topActivityCityList,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getTopActivityCityList,
        deleteTopActivityCity,
    }, dispatch)
};


let actions = ['getTopActivityCityList']

let component = LoaderWrapper(TopActivityCityCLass)({ actions })

export const TopActivityCity = connect(mapStateToProps, mapDispatchToProps)(component)