import React, { Component } from 'react';
import { NormalInput, FileUpload } from '../../component/common';

import SimpleReactValidator from 'simple-react-validator';

import { createBlog, getBlogDetails, updateBlog } from '../../redux/actions/blogs';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

export class AddBlogClass extends Component {

    state = {

        formDetails: {
            title: '',
            description: '',
            link: '',
            blog_image: '',
            image_name: '',
        },

    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message ">{message}</span>,
            autoForceUpdate: this,
            messages: {}
        });

    }

    componentDidMount() {

        let { match: { params: { id } } } = this.props;

        this.fileUpload = React.createRef();

        this.ImagePreview = this.ImagePreview.bind(this);

        if (id) {

            let formDetails = Object.assign({}, this.state.formDetails)

            this.props.getBlogDetails(id).then((data) => {

                formDetails = data;

                formDetails.image_name = data.blog_image;

                this.setState({
                    formDetails: data,
                })

            })

        }

    }

    handleInputs = ({ value, name }) => {

        let formDetails = Object.assign({}, this.state.formDetails)

        formDetails[name] = value

        this.setState({
            formDetails
        })


    }

    ImagePreview = ({ target: { files } }) => {

        let formDetails = Object.assign({}, this.state.formDetails)

        formDetails.blog_image = files[0];

        formDetails.image_name = files[0].name;

        this.setState({
            formDetails,
        })

    }

    handleSubmit = () => {

        if (this.validator.allValid()) {

            let {
                title,
                description,
                link,
                blog_image
            } = this.state.formDetails;

            let formData = new FormData();

            formData.append('title', title)

            formData.append('description', description)

            formData.append('link', link)

            formData.append('blog_image', blog_image)

            if (this.state.formDetails.id) {

                this.props.updateBlog(this.state.formDetails.id, formData).then(() => {

                    this.props.history.push('/blog/list')

                })

            } else {

                this.props.createBlog(formData).then(() => {

                    this.props.history.push('/blog/list')

                })

            }

        } else {

            this.validator.showMessages();

        }

    }

    render() {

        let { match: { params: { id } } } = this.props;

        let { formDetails } = this.state;

        let {
            title,
            description,
            link,
            image_name
        } = formDetails

        return (

            <>
                <div className="sair-pg-header">
                    <h4 className="sair-pg-title align-self-center">
                        <span className="pg-title-icon">
                            <span className="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>{id ? "Edit Blog" : "Add Blog"}
                    </h4>
                </div>
                <div className="sair-sec-wrapper">
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label>Title</label>
                            <NormalInput
                                value={title}
                                name="title"
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('Title', title, 'required')}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Description</label>
                            <textarea
                                value={description}
                                name="description"
                                onChange={({ target: { name, value } }) =>
                                    this.handleInputs({ name, value })
                                }
                                className="form-control"

                            ></textarea>
                            {this.validator.message('Description', description, 'required')}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Link</label>
                            <NormalInput
                                value={link}
                                name="link"
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('Link', link, 'required|url')}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Blog Image</label>
                            <FileUpload
                                fileUpload={this.fileUpload}
                                ImagePreview={(data) => this.ImagePreview(data)}
                                filename={image_name}
                            />
                            {this.validator.message('Blog Image', image_name, 'required')}
                        </div>
                    </div>
                    <hr />
                    <button
                        onClick={this.handleSubmit}
                        className="btn btn-primary"
                    >
                        Submit
                    </button>
                </div>
            </>

        )

    }

}

// const mapStateToProps = state => ({

// })

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        createBlog,
        getBlogDetails,
        updateBlog,
    }, dispatch)
};


export const AddBlog = connect(null, mapDispatchToProps)(AddBlogClass)