import { common, cityFestivalType } from "../../services/actionType"

const initialState = {
    cityFestivalList: {},
}

export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {

        case cityFestivalType.updateCityFestivalDetails:
            return {
                ...state,
                cityFestivalList: payload
            }

        default:
            return state

    }
}