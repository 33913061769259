import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getOrdersList } from "../../redux/actions/orders";
import { TableWrapper, Badge } from "../../component/common";
import { LoaderWrapper } from "../../HOC";
import { orders } from "../../services/apiVariables";

const Columns = [
  {
    label: "Order ID",
  },
  {
    label: "Reseller Info",
  },
  {
    label: "Customer Info",
  },
  {
    label: "Total Price",
  },
  {
    label: "Order Date",
  },
  {
    label: "Status List",
  },
  {
    label: "Payment Received",
  },
  {
    label: "Payment Mode",
  },
];

const download_invoice_url =
  process.env.REACT_APP_BASE_URL +
  "v1/admin/download-customer-invoice?orderId=";

const getJsDate = (date, type) => {
  if (date === undefined) {
    return "NA";
  }
  let d = new Date(date);
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours(),
    amPm = d.getHours() >= 12 ? "PM" : "AM",
    minutes = d.getMinutes();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (type === "dateTime") {
    return [year, month, day].join("-").concat(` ${hours}:${minutes} ${amPm}`);
  }
  return [year, month, day].join("-");
};

const requestStatus = (status) => {
  if (!status) {
    return <Badge type={1} label={"Online"} />;
  } else if (status) {
    return <Badge type={3} label={"COD"} />;
  }
};

export const PartnerOrdersList = ({ ordersList, getOrdersList, history }) => {
  let { list = [], pageMeta = {} } = ordersList;
  const params = new URLSearchParams(history.location.search || "");
  const userId = params.get("user_id");

  useEffect(() => {
    orders.getOrdersList.reset();
    getOrdersList({ userId, wholesale: true });
  }, []);

  const firstItem = list.length > 0 ? list[0] : null;

  const handleFilter = (query, value, name) => {
    getOrdersList(query);
  };

  return (
    <section className="sair-sec-wrapper">
      <div className="d-flex justify-content-between">
        <div>
          {firstItem && (
            <>
              <p>{`${firstItem.reseller_first_name} ${firstItem.reseller_last_name}`}</p>
              <p>{`Contact: ${firstItem.customerdetails.contact_no}`}</p>
            </>
          )}
        </div>
        <div>
          {firstItem && (
            <a
              className={`btn btn-outline-secondary`}
              href={`/#/wholesale/partner-leads-list?user_id=${firstItem.customerdetails.user_id}`}
            >
              Lead
            </a>
          )}
          {firstItem && (
            <a
              className={`ml-4 btn btn-primary`}
              href={`/#/wholesale/partner-orders-list?user_id=${firstItem.customerdetails.user_id}`}
            >
              Old Orders
            </a>
          )}
        </div>
      </div>

      <div className="table-wrap mt-3">
        <TableWrapper
          headerDetails={Columns}
          isEmpty={list.length > 0}
          pageMeta={pageMeta}
          queryHandler={(query) => handleFilter(query)}
        >
          {list.map((item, index) => {
            let {
              id,
              order_status,
              total_price,
              is_cod,
              commission_paid,
              reseller_email,
              reseller_first_name,
              reseller_last_name,
              created_at,
              PaymentDetails,
              customerdetails,
            } = item;
            let url = download_invoice_url + id;

            return (
              <tr key={index}>
                <td>{item.booking_id}</td>
                <td>
                  {reseller_first_name + " " + reseller_last_name}
                  <br />
                  {reseller_email}
                  <br />
                  {item.contact_no}
                </td>
                <td>
                  {customerdetails.name} <br /> {customerdetails.email} <br />{" "}
                  {customerdetails.contact_no}
                </td>
                <td>{total_price}</td>
                <td>{getJsDate(created_at)}</td>
                <td>
                  <span style={{ fontSize: 15 }}>Order :</span>{" "}
                  <span style={{ fontSize: 16, fontWeight: "bold" }}>
                    {order_status}
                  </span>
                  <br />
                </td>
                <td>{PaymentDetails !== null ? "Yes" : "No"}</td>
                <td>{requestStatus(is_cod)}</td>
                <td>
                  <button className="btn btn-primary btn-sm">
                    View Details
                  </button>
                </td>
                {order_status !== "Cancelled" && (
                  <td>
                    <button className="btn btn-primary btn-sm">Cancel</button>
                  </td>
                )}
                <td>
                  <a
                    href={url}
                    target="_blank"
                    className="btn btn-primary btn-sm"
                  >
                    Download Pdf
                  </a>
                </td>
                <td>
                  {order_status === "Confirmed" && (
                    <button type="button" className="btn btn-primary btn-sm">
                      Invoice
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </TableWrapper>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  ordersList: state.orders.ordersList,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrdersList,
    },
    dispatch
  );
};

const actions = ["getOrdersList"];
const component = LoaderWrapper(PartnerOrdersList)({ actions });

export const WholesalePartnerOrdersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
