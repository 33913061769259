import { specialDiscount } from "../../services/apiVariables";
import { discountType } from "../../services/actionType";
import { common } from "../../services/actionType";
import { Toast } from "../../services/toast";

export const getSpecialDicount =
  () =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });
      api(specialDiscount.getSpecialDiscount)
        .then(({ data }) => {
          resolve(true);
          dispatch({ type: discountType.setDiscount, payload: data });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const updateSpecialDiscount =
  (body) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });

      api({ ...specialDiscount.updateSpecialDiscount, body })
        .then(({ data }) => {
          resolve(true);
          dispatch({ type: common.updateLoader, payload: false });
          Toast({ type: "success", message: "Updated successfully" });
        })
        .catch(({ message, err }) => {
          Toast({ type: "error", message: err || message });
        });
    });
  };
