import { notificationType } from "../../services/actionType"


const initialState = {
	notificationLists: {}
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

	switch (type) {

		case notificationType.updateNotificationListDetails:
			return {
				...state,
				notificationLists: payload
			}
		default:
			return state

	}
}