import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal, ToggleCheckbox } from "../../component/common"
import { deleteCategoryById } from "../../redux/actions/reseller"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getWareHouseList } from "../../redux/actions/reseller"
import { getSNo } from "../../services/helperFunctions"
import { LoaderWrapper } from "../../HOC"
import { directive } from '@babel/types';
import { Link } from 'react-router-dom'
import _ from 'lodash'

class WareHouseListClass extends Component {

  state = {
    categories: [],
    headerDetails: [
      {
        label: 'S.No'
      },
      {
        label: 'Name',
      },
      {
        label: 'Email'
      },
      {
        label: 'contact'
      },
      {
        label: 'address'
      },
      {
        label: 'City',
      },
      {
        label: 'State',
      },
      {
        label: 'Pincode',
      },
      {
        label: 'Shiprocket Id',
      },
      {
        label: 'GSTIN',
      }
    ]
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  handleFilter = (query, value, name) => {
    this.props.getWareHouseList(query)
  }

  handleAddorEdit = (id) => {
    if (id) {
      this.props.history.push(`/reseller/warehouses/edit/${id}`)
    } else {
      this.props.history.push('/reseller/warehouses/create')
    }
  }

  handleDelete = (id) => {
    this.props.deleteCategoryById(id).then(() => {
      window.location.reload()
    })
  }

  render() {

    let { headerDetails } = this.state
    let { wareHouseList } = this.props
    let IsEmpty = wareHouseList && wareHouseList.list && wareHouseList.list.length > 0

    return (
      <div>
        {/* Header */}
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Warehouses
          </h4>
          <div className="col-auto ">
            {/* commented so can use later */}
            {/* <button className="btn btn-primary btn-sm"
            onClick={() => this.handleAddorEdit()}
          >Add Warehouse</button> */}
          </div>
        </div>

        {/* Content */}
        <section className="sair-sec-wrapper">
          <div className="row">
            <div className="col-sm">
              <div className="row no-gutters">
                <h5 className="sair-sec-title align-self-center">Warehouse List</h5>
              </div>
              <div className="table-wrap mt-3">
                <TableWrapper
                  headerDetails={headerDetails}
                  isEmpty={IsEmpty}
                  pageMeta={wareHouseList.pageMeta}
                  queryHandler={(query) => this.handleFilter(query)}
                >
                  {wareHouseList.list.map((item, index) => {
                    let { id, _id, name, email, contact, address_line_1, address_line_2, city, state, pincode, shiprocket_id, gstin } = item

                    return (
                      <tr
                        key={index}  >
                        <td>{_id}</td>
                        <td>{name}</td>
                        <td>{email}</td>
                        <td>{contact}</td>
                        <td>{address_line_1}
                          <br />
                          {address_line_2}
                        </td>
                        <td>{city}</td>
                        <td>{state}</td>
                        <td>{pincode}</td>
                        <td>{shiprocket_id}</td>
                        <td>{gstin}</td>
                      </tr>
                    )
                  })}
                </TableWrapper>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    wareHouseList: state.reseller.wareHouseList // data from reducer to categoryList
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getWareHouseList,
    deleteCategoryById
  }, dispatch)
};

let actions = ["getWareHouseList"]

let component = LoaderWrapper(WareHouseListClass)({ actions })

export const WareHouseList = connect(mapStateToProps, mapDispatchToProps)(component)
