import React, { Component, Children } from 'react'
import { NormalModal } from "../Modal"

export class ConfirmModal extends Component {


    renderFooter = () => {

        let { toggle, submitText = 'Submit', cancelText = 'Cancel', keyName, handleCancel, handleSubmit } = this.props

        return (
            <div>

                <button
                    className="px-3 btn btn-transparent border"
                    onClick={() => {

                        handleCancel && handleCancel()

                        toggle(keyName)
                    }}
                >
                    {cancelText}
                </button>
                <button
                    className="ml-3 btn orange-btn px-3"
                    onClick={handleSubmit}
                >
                    {submitText}
                </button>

            </div>
        )
    }

    render() {

        let { toggle, isOpen, keyName, children } = this.props

        return (
            <NormalModal isOpen={!!isOpen}
                toggle={() => toggle(keyName)}
                footerContent={this.renderFooter}
            >
                <div className="text-center">
                    {children}
                </div>
            </NormalModal>
        )
    }
}
