import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { Collapse } from 'reactstrap';
import { history } from "../../helpers"

export class sideNavClass extends Component {

	state = {
		currentCategoryIndex: null,
		sideBarOpen: true,
		sideNavDetails: []
	}

	componentDidMount() {
		let { sideNavDetails } = this.props

		this.setState({
			sideNavDetails
		})
	}

	isActive = (path) => {

		if (history.location.pathname == path.split(' ').join('')) {
			return true
		} else {
			return false
		}



	}

	handleNavigation = (e, index, path) => {
		e.preventDefault()

		this.setState(({ currentCategoryIndex }) => ({

			currentCategoryIndex: currentCategoryIndex === index && !path ? null : index
		}))

		if (path) {
			history.push(`${path.split(' ').join('')}`)
		}
	}

	render() {
		let { sideNavDetails, currentCategoryIndex } = this.state

		return (
			<nav className="sair-nav sair-nav-light">
				<div className="navbar-nav-wrap">
					<ul className="navbar-nav flex-column">
						{sideNavDetails.map(({ name, path, subCategory }, index) => (
							<li
								className={`nav-item sidenav-collapse ${currentCategoryIndex === index ? 'active' : ''}`}
								key={index}

							>
								<a
									className={`nav-link ${this.isActive(path) ? 'active' : ''}`}
									onClick={e => this.handleNavigation(e, index, path)}
								>
									<i className="ion ion-ios-keypad"></i>
									<span className="nav-link-text">{name}</span>
									{subCategory && <i className="ion ion-ios-arrow-down ml-auto"></i>}
								</a>
								{subCategory &&
									<Collapse isOpen={currentCategoryIndex == index}>

										<ul className="list-inline pb-2 m-0 sub-category">

											{subCategory.map(({ name, path }, key) => (

												<li key={name + key}>
													<a
														className={`${this.isActive(path) ? 'active' : ''}`}

														onClick={e => this.handleNavigation(e, index, path)}

													>{name}</a>
												</li>

											))}

										</ul>
									</Collapse>
								}
							</li>
						))}

					</ul>
				</div>
			</nav>
		)
	}
}

const mapStateToProps = state => {

	return {
		sideNavDetails: state.common.sideNavDetails,
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({

	}, dispatch)
};

export const SideNav = connect(mapStateToProps, mapDispatchToProps)(sideNavClass)
