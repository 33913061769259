import { offersType } from "../../services/actionType"


const initialState = {
    offerList: {},
    offerDetails: {},
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {

        case offersType.updateOfferDetails:
            return {
                ...state,
                offerList: payload
            }
        case offersType.updateSingleOfferDetails:
            return {
                ...state,
                offerDetails: payload
            }
        default:
            return state

    }
}