import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { NormalInput } from "../../component/common";

const DEFAULT_VARIANTS = [
  { qty_range: "", price_range: "" },
  { qty_range: "", price_range: "" },
  { qty_range: "", price_range: "" },
];

export const Schema = Yup.object().shape({
  case_size: Yup.string()
    .required("Required")
    .matches(/\d+(\.\d+)?$/, "Only number allowed"),
  case_mrp: Yup.string()
    .required("Required")
    .matches(/\d+(\.\d+)?$/, "Only number allowed"),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        qty_range: Yup.string()
          .required("Required")
          .test("raneInvalid", "Invalid range values", function (item) {
            if (!item) return true;
            const values = item.split("-");
            if (values.length !== 2) return false;
            const isValidNumbers =
              /\d+(\.\d+)?$/.test(values[0]) && /\d+(\.\d+)?$/.test(values[1]);
            if (!isValidNumbers) return false;
            if (parseFloat(values[1]) < parseFloat(values[0])) return false;
            return true;
          }),
        price_range: Yup.string()
          .required("Required")
          .test("raneInvalid", "Invalid range values", function (item) {
            if (!item) return true;
            const values = item.split("-");
            if (values.length !== 2) return false;
            const isValidNumbers =
              /\d+(\.\d+)?$/.test(values[0]) && /\d+(\.\d+)?$/.test(values[1]);
            if (!isValidNumbers) return false;
            if (parseFloat(values[1]) < parseFloat(values[0])) return false;
            return true;
          }),
      })
    )
    .min(3, "Required"),
});

const ProductItemRow = ({ item, index, onItemSubmit, onDelete }) => {
  const formikProps = useFormik({
    initialValues: {
      case_size: item.case_size || "",
      case_mrp: item.case_mrp || "",
      variants:
        item.variants && item.variants.length > 0
          ? item.variants
          : DEFAULT_VARIANTS,
    },
    onSubmit: (values) => {
      const variants = values.variants.map((variant, index) => ({
        ...variant,
        slNo: index + 1,
      }));
      onItemSubmit({ ...values, variants });
    },
    validationSchema: Schema,
  });
  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        {item.product_info && item.product_info[0] && item.product_info[0].name}
      </td>
      <td>{item.active ? "Yes" : "No"}</td>
      <td>
        <div className="w-120p">
          <NormalInput
            name="case_size"
            value={formikProps.values.case_size}
            className="form-control"
            onChange={(e) => {
              formikProps.setFieldValue("case_size", e.value);
            }}
          />
          {formikProps.touched.case_size && formikProps.errors.case_size && (
            <span className="error-message font-md">
              {formikProps.errors.case_size}
            </span>
          )}
        </div>
      </td>
      <td>
        <div className="w-120p">
          <NormalInput
            name="case_mrp"
            value={formikProps.values.case_mrp}
            className="form-control"
            onChange={(e) => {
              formikProps.setFieldValue("case_mrp", e.value);
            }}
          />
          {formikProps.touched.case_mrp && formikProps.errors.case_mrp && (
            <span className="error-message font-md">
              {formikProps.errors.case_mrp}
            </span>
          )}
        </div>
      </td>
      <td>
        <div className="w-120p">
          {formikProps.values.variants.map((_, i) => (
            <div>
              <NormalInput
                key={`PRODUCT_${item.product_id}_qty_${i}`}
                name={`variants[${i}].qty_range`}
                value={formikProps.values.variants[i].qty_range}
                className="form-control mb-2"
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `variants[${i}].qty_range`,
                    e.value
                  );
                }}
              />
              {formikProps.touched.variants &&
                formikProps.touched.variants[i] &&
                formikProps.errors.variants &&
                formikProps.errors.variants[i] &&
                formikProps.touched.variants[i].qty_range &&
                formikProps.errors.variants[i].qty_range && (
                  <span className="error-message font-md">
                    {formikProps.errors.variants[i].qty_range}
                  </span>
                )}
            </div>
          ))}
        </div>
      </td>
      <td>
        <div className="w-120p">
          {formikProps.values.variants.map((_, i) => (
            <div>
              <NormalInput
                key={`PRODUCT_${item.product_id}_price_${i}`}
                name={`variants[${i}].price_range`}
                value={formikProps.values.variants[i].price_range}
                className="form-control mb-2"
                onChange={(e) => {
                  formikProps.setFieldValue(
                    `variants[${i}].price_range`,
                    e.value
                  );
                }}
              />

              {formikProps.touched.variants &&
                formikProps.touched.variants[i] &&
                formikProps.errors.variants &&
                formikProps.errors.variants[i] &&
                formikProps.touched.variants[i].price_range &&
                formikProps.errors.variants[i].price_range && (
                  <span className="error-message font-md">
                    {formikProps.errors.variants[i].price_range}
                  </span>
                )}
            </div>
          ))}
        </div>
      </td>
      <td>
        {/* <button className="btn btn-default">
          <i className="icon-pencil cursor-pointer" />
        </button> */}
        <button className="btn btn-default" onClick={onDelete}>
          <i className="icon-trash cursor-pointer" />
        </button>
        {!item.active && (
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            onClick={formikProps.handleSubmit}
          >
            Submit
          </button>
        )}
      </td>
    </tr>
  );
};

export default ProductItemRow;
