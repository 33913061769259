import { blogType } from "../../services/actionType"

const initialState = {
    blogList: {}
}


export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {

        case blogType.updateBlogDetails:
            return {
                ...state,
                blogList: payload
            }

        default:
            return state

    }
}