

import { advertisementTypeOne } from "../../services/apiVariables"
import { advertisementTypeOneType } from "../../services/actionType"

import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"




export const getAdvertisementTypeOneListDetails = (query) => (dispatch, getState, { api }) => {

    
	return new Promise((resolve, reject) => {


		addQuery(query, advertisementTypeOne.getAdvertisementTypeOneListDetails)

		api(advertisementTypeOne.getAdvertisementTypeOneListDetails).then(({ data, message }) => {

			dispatch({ type: advertisementTypeOneType.updateAdvertisementTypeOneListDetails, payload: data })

			resolve(true)


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}



export const deleteAdvertisementTypeOne = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		advertisementTypeOne.deleteAdvertisementTypeOne.addParams = id;

		api(advertisementTypeOne.deleteAdvertisementTypeOne).then(({ data, message }) => {

			resolve(true)

			Toast({ type: 'success', message: message })

			dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message }))

		})

	})

}



export const getAdvertisementTypeOneDetailsById = (id) => (dispatch, getState, { api }) => {

    
	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		advertisementTypeOne.getAdvertisementTypeOneDetailsById.addParams = id;

		api(advertisementTypeOne.getAdvertisementTypeOneDetailsById).then(({ data }) => {

			dispatch({ type: advertisementTypeOneType.getAdvertisementTypeOneDetailsById, payload: data })

			resolve(data)

			dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {

			reject(message)

			dispatch({ type: common.updateLoader, payload: false })

		})



	})

}

export const createSliderAdvertisementImage = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		api({ ...advertisementTypeOne.addSliderAdvertisementImage, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message }))

		})

	})
}

export const addImage = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: advertisementTypeOneType.addImage, payload: body })

		api({ ...advertisementTypeOne.addImage, body }).then(({ data, message }) => {

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))


		})

	})
}


export const updateAdvertisementTypeOne = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		advertisementTypeOne.updateAdvertisementTypeOne.addParams = id;

		api({ ...advertisementTypeOne.updateAdvertisementTypeOne, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ error }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message :JSON.stringify(error)  }))

		})

	})
}


export const createAdvertisementTypeOne = (body) => (dispatch, getState, { api }) => {
    
    
	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		dispatch({ type: advertisementTypeOne.createAdvertisementTypeOne, payload: body })

		api({ ...advertisementTypeOne.createAdvertisementTypeOne, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: data })

			resolve(data)

		}).catch(({ error }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message :JSON.stringify(error)  }))


		})

	})
}




export const getCityListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, advertisementTypeOne.getCityListDetails)

		api(advertisementTypeOne.getCityListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: advertisementTypeOneType.updateCityListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}



export const getCategoryMappingListDetails = (city_id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })
		advertisementTypeOne.getCategoryMappingListDetails.addParams = city_id;


		api(advertisementTypeOne.getCategoryMappingListDetails).then(({ data, message }) => {

            resolve(true)
	
			dispatch({ type: advertisementTypeOneType.updateCategoryMappingListDetails, payload: data })

            dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {


			Toast({ type: 'error', message })

			resolve(false)

		})

	})

}


export const getActivityListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {


		addQuery(query, advertisementTypeOne.getActivityListDetails)

		api(advertisementTypeOne.getActivityListDetails).then(({ data, message }) => {

			dispatch({ type: advertisementTypeOneType.updateActivityListDetails, payload: data })

			resolve(true)


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}