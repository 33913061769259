import React, { Component } from 'react';
import { TableWrapper, SliderOverlay, ConfirmModal } from '../../component/common';

import { getOfferList, deleteOffer } from '../../redux/actions/offers';
import { getCityListDetails } from "../../redux/actions/master"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"
import { getSNo } from '../../services/helperFunctions';
//import { getActivityListDetails, deleteActivity } from "../../redux/actions/activity"
//import { getCategoryListDetails} from "../../redux/actions/master"
import {
	getCategoryMappingListDetails,
	getCategoryMappingDropDown,
} from "../../redux/actions/activity"
import { OfferModal } from '../../component/Offer';

export class OfferClass extends Component {

    state = {
        headerDetails: [{
            label: 'S.No',
        }, {
            label: 'Offer Name',
        }, {
            label: 'Offer Description',
        }, {
            label: 'offer coupen',
        }, {
            label: 'Action',
        }],
        currentDeleteId: '',
        ConfirmModalOpenStatus: false,
        isOpen: false,
        details: {},
    }

    handleFilter = (query, value, name) => {

        if (name == "filter") {
            this.setState({
                selectedFilter: query
            })

            query = {
                pageNo: 1,
                filter: query
            }
        }

        this.props.getOfferList(query)

    }

    toggle = name => {

        this.setState(prevState => ({
            [name]: !prevState[name]
        }));

    }

    handleDeleteToggle = (id) => {

        this.setState({
            currentDeleteId: id,
        })

        this.toggle('ConfirmModalOpenStatus')

    }

    handleDelete = () => {

        let { currentDeleteId } = this.state;

        this.props.deleteOffer(currentDeleteId).then(() => {

            this.props.getOfferList({ pageNo: 1 })

            this.toggle('ConfirmModalOpenStatus')

        })

    }

    handleAdd = (id) => {

        this.toggle('isOpen');

        if (id) {

            let { offerList} = this.props;

            let { list = [] } = offerList;

            let offerDetailsById = list.filter((item) => id === item.id)

            this.setState({
                details: {
                    id,
                    name: offerDetailsById[0].name,
                    description: offerDetailsById[0].description,
                    offer_coupen: offerDetailsById[0].offer_coupen,
                    offer_type: offerDetailsById[0].offer_type,
                    offer_value: offerDetailsById[0].offer_value,
                    offer_limit: offerDetailsById[0].offer_limit,
                    start_date: offerDetailsById[0].start_date,
                    end_date: offerDetailsById[0].end_date,
                    min_amount: offerDetailsById[0].min_amount,
                    max_discount: offerDetailsById[0].max_discount,
                    ids:offerDetailsById[0].ids,
                    applicable_type:offerDetailsById[0].applicable_type,
                    user_usage_limit:offerDetailsById[0].user_usage_limit,
                    min_orders:offerDetailsById[0].min_orders,
                    max_orders:offerDetailsById[0].max_orders
                }
            })

        } else {

            this.setState({
                details: {
                    name: "",
                    description: "",
                    offer_coupen: "",
                    offer_type: "",
                    offer_value: "",
                    offer_limit: "",
                    start_date: "",
                    end_date: "",
                    min_amount: "",
                }
            })

        }

    }

    render() {
        
        let { headerDetails, ConfirmModalOpenStatus, isOpen, details } = this.state;

        let { offerList = {},cityLists=[],categoryMappingDropDownLists=[]} = this.props;
        let { list = [], pageMeta = {} } = offerList;

        let IsEmpty = list.length > 0;

        return (

            <div>

                <div className="sair-pg-header">
                    <h4 className="sair-pg-title align-self-center">
                        <span className="pg-title-icon">
                            <span className="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>
                        Offers
                    </h4>
                    <div className="col-auto ">
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => this.handleAdd()}
                        >Add Offer</button>
                    </div>
                </div>

                <section className="sair-sec-wrapper">
                    <div className="row no-gutters">
                        <h5 className="sair-sec-title align-self-center">
                            Offers List
                        </h5>
                    </div>
                    <div className="table-wrap mt-3">
                        <TableWrapper
                            headerDetails={headerDetails}
                            queryHandler={(query) => this.handleFilter(query)}
                            pageMeta={pageMeta}
                            isEmpty={IsEmpty}
                        >
                            {list.map((item, index) => {

                                let {
                                    name,
                                    description,
                                    id,
                                    offer_coupen
                                } = item;

                                return (
                                    <tr key={index}>
                                        <td>{getSNo(pageMeta, index)}</td>
                                        <td>{name}</td>
                                        <td>{description}</td>
                                        <td>{offer_coupen}</td>
                                        
                                        <td>
                                            <a
                                                className="mr-25"
                                                onClick={() => this.handleAdd(id)}
                                            >
                                                <i className="icon-pencil  text-primary cursor-pointer"
                                                ></i>
                                            </a>
                                            <a
                                                onClick={() => this.handleDeleteToggle(id)}
                                            >
                                                <i className="icon-trash txt-danger text-primary cursor-pointer"></i>
                                            </a>
                                        </td>
                                    </tr>
                                )

                            })}
                        </TableWrapper>
                    </div>
                </section>
                <SliderOverlay isOpen={isOpen} toggle={() => this.toggle('isOpen')} size='30%'>
                    <div className="side-slider h-100 position-relative">
                        <div className="h-100 flex-with-center">
                            <OfferModal
                                toggle={() => this.toggle('isOpen')}
                                details={details}
                                cityLists={cityLists}
                                activityLists={categoryMappingDropDownLists.Activity}
                                categoryLists={categoryMappingDropDownLists.Category}
                            />
                        </div>
                    </div>
                </SliderOverlay>
                <ConfirmModal
                    keyName="ConfirmModalOpenStatus"
                    toggle={this.toggle}
                    isOpen={ConfirmModalOpenStatus}
                    handleSubmit={() => this.handleDelete()}
                    submitText='Delete'
                >
                    <h5>
                        Are you sure want to delete Top Activity Mapping?
					</h5>
                </ConfirmModal>
            </div>

        )

    }

}

const mapStateToProps = state => {
    return {
        offerList: state.offers.offerList,
        cityLists: state.master.cityLists,
		categoryMappingDropDownLists: state.activity.categoryMappingDropDownLists
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getOfferList,
        deleteOffer,
        getCityListDetails,
        getCategoryMappingDropDown
    }, dispatch)
};
let actions = ['getOfferList','getCityListDetails','getCategoryMappingDropDown']

let component = LoaderWrapper(OfferClass)({ actions })

export const Offer = connect(mapStateToProps, mapDispatchToProps)(component)