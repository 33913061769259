import React, { Component } from 'react'

export class ToggleCheckbox extends Component {


	render() {


		let { value = false, onClick } = this.props

		return (
			<div className="toggle toggle-light toggle-bg-primary toggle3"
			>

				<div className="toggle-slide toggle-select">
					<div className="toggle-inner"
						onClick={onClick}
					>
						<div className={`toggle-on ${value ? 'active' : ''}`} >
							Yes
						</div>
						<div className="toggle-blob" >
						</div>
						<div className={`toggle-off ${value ? '' : 'active'}`} >
							No
						</div>
					</div>
				</div>
			</div>
		)
	}
}