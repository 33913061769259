import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import SimpleReactValidator from 'simple-react-validator';
import { LoaderWrapper } from "../../HOC"
import { ContentMainHeader, ReactSelect, FileUpload } from "../../component/common"
import { createVendor, addDocument, getSingleVendor, editVendor, removeVendorDocument } from "../../redux/actions/vendor"
import { getServiceProviderListDetails } from "../../redux/actions/master"

export class createVendorClass extends Component {

	state = {
		vehicleTypeList: [
			{
				label: "Economy",
				value: "Economy"
			},
			{
				label: "Premium",
				value: "Premium"
			},
			{
				label: "Luxury",
				value: "Luxury"
			}
		],
		VendorData: {
			business_details: '',
			company_name: '',
			public_company_name: '',
			legal_company_name: '',
			property_name: '',
			email: '',
			phone_no: '',
			company_website: '',
			company_description: '',
			address: '',
			manage_booking: 'true',
			service_provider: [],
			vendorDocuments: [
				{
					documentKey: '',
					documentType: '',
				}
			],
		},


		tempFile: '',
		manageBookingOption: [
			{
				label: "Yes",
				value: "true"
			},
			{
				label: "No",
				value: "false"
			}
		],
		documentTypeLists: [
			{
				label: "CIN",
				value: "cin"
			},
			{
				label: "PAN",
				value: "pan"
			},
			{
				label: "TAN",
				value: "tan"
			},
			{
				label: "GST",
				value: "gst"
			},
			{
				label: "IATA registration",
				value: "iata"
			},
			{
				label: "Guide license",
				value: "guidlicense"
			}

		]

	}

	componentWillMount() {

		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
		this.validators = new SimpleReactValidator({
			element: message => {

				let messages = message.split('/');
				return (
					<span className="error-message font-md">
						{`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
					</span>
				)
			},

		});

	}

	componentDidMount() {

		this.fileUpload = React.createRef();
		this.ImagePreview = this.ImagePreview.bind(this);

		let { match: { params: { id } } } = this.props;

		if (id) {

			this.props.getSingleVendor(id);

		}

	}

	componentWillReceiveProps({ vendorDetails }) {

		let VendorData = Object.assign({}, this.state.VendorData);



		let {
			business_details,
			company_name,
			public_company_name,
			legal_company_name,
			property_name,
			email,
			phone_no,
			company_website,
			company_description,
			address,
			manage_booking,
			vendorDocument,
		}  = vendorDetails;

		let service_provider= []

		vendorDetails.vendorServiceProvider.map((data , index)=>{
			if(!service_provider.includes(data.service_id)){
				service_provider.push(data.service_id)
			}
		})

		vendorDocument.map((data , index)=>{
			vendorDocument[index].id = data.id.toString()
			vendorDocument[index].documentKey = data.document_file
			vendorDocument[index].documentType = data.document_type
		})


		VendorData = {
			business_details,
			company_name,
			public_company_name,
			legal_company_name,
			property_name,
			email,
			phone_no,
			company_website,
			company_description,
			address,
			manage_booking,
			service_provider,
			vendorDocuments: vendorDocument.length != 0 ? vendorDocument : [{
				documentKey: '',
				documentType: '',
			}],
		}

		this.setState({
			VendorData
		})

	}

	ImagePreview = (event, key, name, index) => {

		let { VendorData } = this.state
		let files = event.target.files[0]

		VendorData[key][index][name] = files

		let formData = new FormData()

		formData.append("document_file", files)

		this.props.addDocument(formData).then((data) => {

			VendorData[key][index][name] = data.documentKey

		})

		this.setState({
			tempFile: URL.createObjectURL(event.target.files[0]),
			VendorData,
		});

	}


	handleInputs = ({ target: { value, name } }) => {

		let VendorData = Object.assign({}, this.state.VendorData)

		VendorData[name] = value

		this.setState({
			VendorData
		})
	}

	handleSelectInputs = ({ value, name }) => {

		let VendorData = Object.assign({}, this.state.VendorData)
		VendorData[name] = value
		this.setState({
			VendorData
		})
	}

	handleMultipleInputs = (name, value, key, index) => {

		let VendorData = Object.assign({}, this.state.VendorData)

		let { documentTypeLists } = this.state

		VendorData[key][index][name] = value

		this.setState({
			VendorData
		})

	}

	handleUpload = ({ target: { value, name } }) => {

		let vendorDocuments = Object.assign({}, this.state.vendorDocuments)

		vendorDocuments[name] = value

		this.setState({
			vendorDocuments

		})

	}

	handleSubmit = () => {

		if (this.validator.allValid()) {

			let { VendorData } = this.state

			let body = {
				...VendorData
			}
			body.manage_booking = Boolean(VendorData.manage_booking)

			let { match: { params: { id } } } = this.props;

			if (!id) {

				this.props.createVendor(body).then(() => {
					let { history } = this.props
					history.push('/vendor/list')

				})

			} else {

				this.props.editVendor(id, body).then(() => {
					let { history } = this.props
					history.push('/vendor/list')

				})

			}


		} else {

			this.validator.showMessages();
		}
	}

	generateServiceProviderOption = (list) => {
		let options = []

		list.forEach(item => {
			options.push({
				label: item.name,
				value: item.id
			})

		})
		return options


	}

	deleteVendorDoc(data , index){
		
		let VendorData = Object.assign({}, this.state.VendorData)

		if(data.id){
			this.props.removeVendorDocument(data.id).then(()=>{
				VendorData.vendorDocuments.splice(index , 1)				
			})
		}else{
			VendorData.vendorDocuments.splice(index , 1)
		}

		this.setState({VendorData})

	}

	handleAddMultiple = (key) => {

		let VendorData = Object.assign({}, this.state.VendorData)

		if (key == "vendorDocuments") {

			let vendorDocuments = VendorData[key]

			let errorStatus = vendorDocuments.every((e, index) => {


				let err1 = this.validators.fieldValid(`/documentKey/${index}/`)

				let error = this.validators.fieldValid(`/documentType/${index}/`)


				if (!err1 && !error) {
					this.validators.showMessageFor(`/documentKey/${index}/`)
					this.validators.showMessageFor(`/documentType/${index}/`)
					return false
				}

				return true

			})

			if (errorStatus) {
				VendorData[key].push({
					documentKey: '',
					documentType: ''
				})

			}

		}

		this.setState({
			VendorData
		})
	}



	render() {

		let { tempFile, VendorData, documentTypeLists, manageBookingOption } = this.state

		let {
			business_details,
			company_name,
			public_company_name,
			legal_company_name,
			property_name,
			email,
			phone_no,
			company_website,
			company_description,
			address,
			manage_booking,
			service_provider,
			vendorDocuments
		} = VendorData

		let { serviceProviderLists = [], match = {} } = this.props

		let { params: { id } } = match;

		return (
			<>

				<ContentMainHeader title={`${id ? "Edit" : "Add"} Vendor`}></ContentMainHeader>

				<section className="sair-sec-wrapper">
					<div className="row">

						<div className="col-md-6 form-group">
							<label>Business detail</label>
							<input
								className={`form-control ${this.validator.message('business_details', business_details, 'required') ? 'inValid' : ''}`} id="business_details"
								placeholder=""
								name="business_details"
								value={business_details}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('Business detail', business_details, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Company name</label>
							<input
								className={`form-control ${this.validator.message('company_name', company_name, 'required') ? 'inValid' : ''}`} id="company_name"
								placeholder=""
								name="company_name"
								value={company_name}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('Company name', company_name, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label htmlFor="public_company_name">Public company name</label>
							<input
								className={`form-control ${this.validator.message('public_company_name', public_company_name, 'required') ? 'inValid' : ''}`} id="public_company_name"
								placeholder=""
								name="public_company_name"
								value={public_company_name}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('public company name', public_company_name, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label htmlFor="legal_company_name">Legal company name</label>
							<input
								className={`form-control ${this.validator.message('legal_company_name', legal_company_name, 'required') ? 'inValid' : ''}`} id="legal_company_name"
								placeholder=""
								name="legal_company_name"
								value={legal_company_name}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('legal_company_name', legal_company_name, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Property name</label>
							<input
								className={`form-control ${this.validator.message('property_name', property_name, 'required') ? 'inValid' : ''}`} id="property_name"
								placeholder=""
								name="property_name"
								value={property_name}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('property name', property_name, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label htmlFor="email">Email</label>
							<input
								className={`form-control ${this.validator.message('email', email, 'required|email') ? 'inValid' : ''}`} id="email"
								placeholder=""
								name="email"
								value={email}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('email', email, 'required|email')}
						</div>

						<div className="col-md-6 form-group">
							<label>Phone number</label>
							<input
								className={`form-control ${this.validator.message('phone number', phone_no, 'required|phone') ? 'inValid' : ''}`} id="phone_no"
								placeholder=""
								name="phone_no"
								value={phone_no}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('phone number', phone_no, 'required|phone')}
						</div>

						<div className="col-md-6 form-group">
							<label>Company website link</label>
							<input
								className={`form-control ${this.validator.message('company_website', company_website, 'required|url') ? 'inValid' : ''}`} id="company_website"
								placeholder=""
								name="company_website"
								value={company_website}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('company website', company_website, 'required|url')}
						</div>

						<div className="col-md-6 form-group">
							<label>Company Description</label>
							<input
								className={`form-control ${this.validator.message('company_description', company_description, 'required') ? 'inValid' : ''}`} id="company_description"
								placeholder=""
								name="company_description"
								value={company_description}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('city name', company_description, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Address</label>
							<input
								className={`form-control ${this.validator.message('address', address, 'required') ? 'inValid' : ''}`} id="address"
								placeholder=""
								name="address"
								value={address}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('address', address, 'required')}
						</div>

						{/* <div className="col-md-6 form-group">
							<label>Mangae booking</label>
							<input
								className={`form-control ${this.validator.message('manage_booking', manage_booking, 'required') ? 'inValid' : ''}`} id="manage_booking"
								placeholder=""
								name="manage_booking"
								value={manage_booking}
								type="text"
								onChange={this.handleInputs}

							/>
							{this.validator.message('manage booking', manage_booking, 'required')}
						</div> */}
						<div className="col-md-6 form-group">
							<label >Manage booking</label>
							<div className="row">
								{manageBookingOption.map((item, index) => {

									return (
										<div key={index} className="col-md-4 mt-15">
											<div className="custom-control custom-radio-btn">
												<label className=" "
												>
													<input
														class="custom-control-input"
														type="radio"
														name={`manage_booking`}
														value={item.value}
														checked={manage_booking} // .toLowerCase() === item.value
														key={manage_booking}
														id="manage_booking"
														onChange={this.handleInputs}
													/>
													{item.label}
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									)
								})}
								{this.validator.message('manage booking', manage_booking, 'required')}

							</div>
						</div>


						<div className="col-md-6 form-group ">
							<label >Service Provider</label>
							<ReactSelect
								isMulti={true}
								options={this.generateServiceProviderOption(serviceProviderLists.list)}
								handleChange={(keyName, data) => {
									this.handleSelectInputs({ name: keyName, value: data })
								}}
								keyName="service_provider"
								value={service_provider}
								disabled={false}
							/>
							{this.validator.message('Service Provider', service_provider, 'required')}
						</div>

					</div>

					<hr />
					<h6 className="mb-15 row">
						<div className="col-sm-6"> Documents </div>
						<div className="col-sm-6 text-right">
							<button className="btn btn-primary btn-sm"
								onClick={() => this.handleAddMultiple('vendorDocuments')}
							>

								Add document </button>
						</div>
					</h6>

					{vendorDocuments.map((item, index) => {
						let { documentType, documentKey } = item

						return (
							<div className="row">

								<div className="col-md-5 form-group">
									<label>Document type</label>

									<ReactSelect
										isMulti={false}
										options={documentTypeLists}
										handleChange={(name, value) => {
											this.handleMultipleInputs(name, value, "vendorDocuments", index)
										}}
										keyName="documentType"
										value={documentType}
										disabled={false}
									/>
									{this.validators.message(`/documentType/${index}/`, documentType, 'required')}
								</div>

								<div className="col-sm-6">
									<label>File </label>
									<FileUpload
										fileUpload={this.fileUpload}
										ImagePreview={(e) => {
											this.ImagePreview(e, "vendorDocuments", "documentKey", index)
										}}
										filename={documentKey}
										fileType=".png, .jpg, doc"
										tempFile={tempFile}
										preview={false}
									/>
									{this.validators.message(`/documentKey/${index}/`, documentKey, 'required')}
								</div>
								{index != 0 ?
								<div class="col-md-1 align-self-center pt-3">
									<i onClick={()=>{this.deleteVendorDoc(item, index)}} class="icon-trash text-danger cursor-pointer"></i>
								</div>
							:null}

							</div>

						)
					})}


					<button
						className="btn btn-primary mt-3"
						onClick={() => this.handleSubmit()}
					>
						Submit
					</button>

				</section>

			</>
		)
	}
}


const mapStateToProps = state => {

	return {
		serviceProviderLists: state.master.serviceProviderLists,
		vendorDetails: state.vendor.vendorDetails,
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createVendor,
		getServiceProviderListDetails,
		addDocument,
		getSingleVendor,
		editVendor,
		removeVendorDocument
	}, dispatch)
};

let actions = ['getServiceProviderListDetails']

let component = LoaderWrapper(createVendorClass)({ actions })



export const CreateVendor = connect(mapStateToProps, mapDispatchToProps)(component)
