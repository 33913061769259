import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Filters, ReactSelect } from "../../component/common";

import {
  getProductList,
  getProductNames,
  getWholesaleProductNames,
  createProduct,
  updateProduct,
  deleteProduct,
} from "../../redux/actions/wholesale";
import { TableWrapper } from "../../component/common";
import ProductItemRow from "./ProductItemRow";
import { LoaderWrapper } from '../../HOC';

const Columns = [
  {
    label: "S. No",
  },
  {
    label: "Name",
  },
  {
    label: "Active on APP",
  },
  {
    label: "Case Size",
  },
  {
    label: "MRP/Unit",
  },
  {
    label: "Case Qty.",
  },
  {
    label: "Apnaklub Price/Unit",
  },
  {
    label: "",
  },
];

export const Products = ({
  productList,
  productNames,
  getProductList,
  getProductNames,
  getWholesaleProductNames,
  createProduct,
  updateProduct,
  deleteProduct,
  history,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  let { list = [], pageMeta = {} } = productList;

  useEffect(() => {
    getProductList();
    getProductNames();
    getWholesaleProductNames();
  }, []);

  const onAddProducts = async () => {
    try {
      await createProduct(
        selectedProducts.map((selectedProduct) => ({
          product_id: selectedProduct,
          active: false,
        }))
      );
      setSelectedProducts([]);
      getProductList();
    } catch (e) {
      console.log("e");
    }
  };

  const onUpdateProduct = async (values, item) => {
    try {
      await updateProduct(item._id, {
        ...item,
        ...values,
        active: true,
      });
      getProductList(pageMeta);
    } catch (e) {
      console.log("e");
    }
  };

  const onDeleteProdcut = async (id) => {
    try {
      await deleteProduct(id);
      getProductList(pageMeta);
    } catch (e) {
      console.log("e");
    }
  };

  const handleFilter = (query) => {
    getProductList(query);
  };

  console.log("pageMeta", pageMeta);

  return (
    <section className="sair-sec-wrapper">
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary"
          onClick={() => history.push("/wholesale/products-list")}
        >
          Products
        </button>
        <button
          className="ml-4 btn btn-outline-secondary"
          onClick={() => history.push("/wholesale/leads-list")}
        >
          Leads
        </button>
      </div>
      <div className="row justify-content-between mt-4">
        <div className="col-sm-6">
          <div className="d-flex align-items-end">
            <div style={{ width: "100%" }}>
              <label>
                <b>Products</b>
              </label>
              <ReactSelect
                isMulti
                options={productNames}
                handleChange={(_, data) => {
                  setSelectedProducts(data);
                }}
                keyName="selectedProducts"
                value={selectedProducts}
              />
            </div>
            <div className="ml-4">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => onAddProducts()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <label>
            <b>Search by product name</b>
          </label>
          <Filters
            className="p-0"
            queryHandler={(query) => {}}
            isDropDownFilter={false}
          />
        </div>
      </div>
      <div className="table-wrap mt-3">
        <TableWrapper
          headerDetails={Columns}
          isEmpty={list.length > 0}
          pageMeta={pageMeta}
          queryHandler={(query) => handleFilter(query)}
        >
          {list.map((item, index) => {
            return (
              <ProductItemRow
                key={`PRODUCT_${index}`}
                item={item}
                index={pageMeta.limit * (pageMeta.page - 1) + index}
                onItemSubmit={(values) => onUpdateProduct(values, item)}
                onDelete={() => onDeleteProdcut(item._id)}
              />
            );
          })}
        </TableWrapper>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  productList: state.wholesale.productList,
  productNames: state.wholesale.productNames,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductList,
      getProductNames,
      createProduct,
      updateProduct,
      deleteProduct,
      getWholesaleProductNames,
    },
    dispatch
  );
};

const actions = ['getProductList']
const component = LoaderWrapper(Products)({ actions })

export const WholesaleProductList = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
