import React, { Component } from 'react';
import { TableWrapper } from '../../component/common';

import { getAllCustomers } from '../../redux/actions/common';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"

import { getSNo } from '../../services/helperFunctions';

class CustomerListClass extends Component {

    state = {
        headerDetails: [{
            label: 'S.No',
        }, {
            label: 'First Name',
        }, {
            label: 'Last Name',
        }, {
            label: 'Email',
        }, {
            label: 'Contact Number',
        }]
    }

    handleFilter = (query, value, name) => {

        if (name == "filter") {
            this.setState({
                selectedFilter: query
            })

            query = {
                pageNo: 1,
                filter: query
            }
        }

        console.log(query, "sdadas33123123123")

        this.props.getAllCustomers(query)

    }

    render() {

        let { headerDetails } = this.state;

        let { customerDetails = {} } = this.props;

        let { list = [], pageMeta = {} } = customerDetails;

        let IsEmpty = list.length > 0;

        return (

            <>
                <div className="sair-pg-header">
                    <h4 className="sair-pg-title align-self-center">
                        <span className="pg-title-icon">
                            <span className="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>Customer List
                    </h4>
                </div>
                <section className="sair-sec-wrapper">
                    <div className="row">
                        <div className="col-sm">
                            <div className="row no-gutters">
                                <h5 className="sair-sec-title align-self-center">
                                    Customer List
                                </h5>
                            </div>
                        </div>
                    </div>
                    <TableWrapper
                        headerDetails={headerDetails}
                        pageMeta={pageMeta}
                        queryHandler={(query) => this.handleFilter(query)}
                        isEmpty={IsEmpty}
                    >
                        {list.map((item, index) => {

                            let {
                                first_name,
                                last_name,
                                email,
                                contact_no,
                            } = item;

                            return (
                                <tr key={index}>
                                    <td>{getSNo(pageMeta, index)}</td>
                                    <td>{first_name ? first_name : "-"}</td>
                                    <td>{last_name ? last_name : "-"}</td>
                                    <td>{email ? email : "-"}</td>
                                    <td>{contact_no ? contact_no : "-"}</td>
                                </tr>
                            )

                        })}
                    </TableWrapper>
                </section>
            </>

        )

    }

}


const mapStateToProps = state => {
    return {
        customerDetails: state.common.customerDetails,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllCustomers,
    }, dispatch)
};


let actions = ['getAllCustomers']

let component = LoaderWrapper(CustomerListClass)({ actions })

export const CustomerList = connect(mapStateToProps, mapDispatchToProps)(component)