import React, { Component } from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { getVendorMappingList, getVendorMappingDropdownList, deleteVendorActivityMapping } from "../../redux/actions/vendorMapping"

import { LoaderWrapper } from '../../HOC';

import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal } from "../../component/common/"

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { getSNo } from '../../services/helperFunctions';

import { VendorMappingModal } from '../../component/VendorMapping';

export class VendorMappingClass extends Component {

    state = {
        headerDetails: [
            {
                label: 'S.No'
            },
            {
                label: 'Activity Title',
            },
            {
                label: 'Company name',
            },
            {
                label: 'Status',
            },
            {
                label: 'Action'
            },

        ],
        filter: [

            {
                label: 'Active',
                value: 1
            },
            {
                label: 'Deactive',
                value: 2
            }

        ],
        details: {},
        selectedFilter: 'Active',
        isOpen: false,
        ConfirmModalOpenStatus: false,
        currentId: null,
    }

    handleFilter = (query, value, name) => {

        if (name == "filter") {
            this.setState({
                selectedFilter: query
            })

            query = {
                pageNo: 1,
                filter: query
            }
        }

        this.props.getVendorMappingList(query)



    }

    requestStatus = (status) => {
        if (status === 0) {
            return <Badge
                type={2}
                label={"Deactive"}
            />
        }

        else if (status === 1) {
            return <Badge
                type={1}
                label={"Active"}
            />
        }

    }

    handleEdit = (index) => {

        let { vendorMappingList: { list } } = this.props

        let vendorMappingList = list[index]

        let details = {
            id: vendorMappingList.id,
            activity_id: vendorMappingList && vendorMappingList.Activity ? vendorMappingList.Activity.id : "",
            vendor_id: vendorMappingList && vendorMappingList.Vendor ? vendorMappingList.Vendor.id : "",
        }

        this.setState({
            isEdit: true,
            vendorMappingList,
            currentIndex: index,
            details,
        })

        this.toggle("isOpen")

    }

    toggle = name => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }));
    }


    handleAdd = () => {

        this.setState({
            details: null,
        })

        this.toggle("isOpen")

    }

    handleDeleteToggle = (id) => {

        this.setState({
            currentId: id
        })

        this.toggle("ConfirmModalOpenStatus")

    }

    handleDelete = () => {

        let { currentId } = this.state;

        this.props.deleteVendorActivityMapping(currentId).then(() => {
            this.props.getVendorMappingList({ pageNo: 1 })
            this.toggle("ConfirmModalOpenStatus")

        })

    }

    render() {

        let { filter, selectedFilter, headerDetails, isOpen, details, ConfirmModalOpenStatus } = this.state;

        let { vendorMappingList = {} } = this.props;

        let { list = [], pageMeta = {} } = vendorMappingList;

        let IsEmpty = list.length > 0;

        return (
            <>
                <div className="sair-pg-header">
                    <h4 className="sair-pg-title align-self-center">
                        <span className="pg-title-icon">
                            <span class="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>Vendor Activity Mapping
                    </h4>
                    <div className="col-auto ">
                        <button className="btn btn-primary btn-sm"
                            onClick={() => this.handleAdd()}
                        >Add Vendor Activity Mapping</button>
                    </div>
                </div>
                <section className="sair-sec-wrapper">
                    <div className="row">
                        <div className="col-sm">
                            <div className="row no-gutters">
                                <h5 className="sair-sec-title align-self-center">Vendor Activity Mapping List</h5>
                                <div className="ml-auto d-flex align-items-center">
                                    {/* <UncontrolledDropdown setActiveFromChild className="filter-dropdown">
                                        <DropdownToggle tag="a" className="custom-caret dropdown-toggle-link">
                                            Filter by: {selectedFilter}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {filter.map(({ label, value }, index) => {
                                                return (
                                                    <DropdownItem
                                                        onClick={e => this.handleFilter(label, value, "filter")}
                                                        key={index}
                                                    >
                                                        {label}
                                                    </DropdownItem>

                                                )
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}

                                    <div className="search-box position-relative  ml-3">

                                        {/* <Filters
                                            queryHandler={(query) => this.handleFilter(query)}
                                            isDropDownFilter={false}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="table-wrap mt-3">
                                <TableWrapper
                                    headerDetails={headerDetails}
                                    isEmpty={IsEmpty}
                                    pageMeta={pageMeta}
                                    queryHandler={(query) => this.handleFilter(query)}
                                >
                                    {list.map((item, index) => {

                                        let { Activity, Vendor, id } = item;

                                        let activity = Activity ? Activity : '';

                                        let { title } = activity;

                                        // let {
                                        //     business_details,
                                        //     company_name,
                                        //     email,
                                        //     phone_no,
                                        //     status,
                                        // } = Vendor;

                                        let companyName = Vendor && Vendor.company_name ? Vendor.company_name : "";

                                        let status = Vendor && Vendor.status ? Vendor.status : "";

                                        return (
                                            <tr key={index} >
                                                <td>
                                                    {getSNo(pageMeta, index)}
                                                </td>
                                                <td>
                                                    {title}
                                                </td>
                                                {/* <td>{business_details}</td> */}
                                                <td>{companyName}</td>
                                                {/* <td>{email}</td>
                                                <td>{phone_no}</td> */}
                                                <td>{this.requestStatus(status)}</td>
                                                <td>
                                                    <a className="mr-25"
                                                        onClick={e => this.handleEdit(index)}
                                                    >
                                                        <i className="icon-pencil  text-primary cursor-pointer"
                                                        ></i>
                                                    </a>
                                                    <a ><i
                                                        className="icon-trash txt-danger text-primary cursor-pointer"
                                                        onClick={e => this.handleDeleteToggle(id)}
                                                    ></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )

                                    })}
                                </TableWrapper>
                            </div>
                        </div>
                    </div>
                </section>
                <SliderOverlay isOpen={isOpen} toggle={() => this.toggle('isOpen')} size='30%'>
                    <div className="side-slider h-100 position-relative">
                        <div className="h-100 flex-with-center">
                            <VendorMappingModal
                                toggle={() => this.toggle('isOpen')}
                                details={details}
                            />
                        </div>
                    </div>
                </SliderOverlay>
                <ConfirmModal
                    keyName="ConfirmModalOpenStatus"
                    toggle={this.toggle}
                    isOpen={ConfirmModalOpenStatus}
                    handleSubmit={() => this.handleDelete()}
                    submitText='Delete'
                >
                    <h5>
                        Are you sure want to delete Vendor Mapping?
					</h5>
                </ConfirmModal>
            </>
        )

    }

}

const mapStateToProps = state => {

    return {
        vendorMappingList: state.vendorMapping.vendorMappingList
    }


};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getVendorMappingList,
        getVendorMappingDropdownList,
        deleteVendorActivityMapping,
    }, dispatch)
};


let actions = ['getVendorMappingList', 'getVendorMappingDropdownList']

let component = LoaderWrapper(VendorMappingClass)({ actions })

export const VendorMapping = connect(mapStateToProps, mapDispatchToProps)(component)