import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import SimpleReactValidator from 'simple-react-validator';
import { handleLogin } from "../../redux/actions/common"

export class LoginClass extends Component {

    state = {
        formDetails: {
            email: '',
            password: ''
        }
    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message font-md">{message}</span>,
            autoForceUpdate: this,
            messages: {
                email: 'Please enter valid email',
                name: 'Required',
                passwordNew: 'Please enter more than 5 character',
                password: 'Please enter the password'

            },
            validators: {
                confirmPassword: { // name the rule
                    message: "password doesn't maching", // give a message that will display when there is an error. :attribute will be replaced by the name you supply in calling it.
                    rule: function (val, params, validator) { // return true if it is succeeds and false it if fails validation. the testRegex method is available to give back a true/false for the regex and given value
                        // check that it is a valid IP address and is not blacklisted
                        return val == params
                    }
                }
            }
        });

        if (localStorage.getItem('AuthToken') && localStorage.getItem('basePage')) {

            let { history } = this.props

            history.push(localStorage.getItem('basePage'))

        }

    }

    handleInput = ({ target: { value, name } }) => {

        let formDetails = Object.assign({}, this.state.formDetails)

        formDetails[name] = value

        this.setState({
            formDetails
        })

    }


    handleSubmit = (e) => {

        e.preventDefault()

        if (this.validator.allValid()) {

            let { handleLogin, history } = this.props

            let { formDetails } = this.state


            handleLogin(formDetails).then((data) => {

                localStorage.setItem('basePage', '/dashboard/list')

                history.push('/dashboard/list')

            }).catch(({ message }) => {

                console.log(message)
            })
        } else {
            this.validator.showMessages()
        }



    }


    render() {

        let { email, password } = this.state.formDetails

        return (
            <>
                <form>
                    <h1 className="display-4 mb-10">Welcome Back :)</h1>
                    <p className="mb-30">Sign in to your account and enjoy unlimited perks.</p>
                    <div className="form-group">
                        <input
                            className="form-control"
                            placeholder="name@example.com"
                            value={email}
                            name="email"
                            onChange={this.handleInput}
                        />
                        {this.validator.message('email', email, 'required|email')}
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                className="form-control"
                                type="password"
                                placeholder="Password"
                                value={password}
                                name="password"
                                onChange={this.handleInput}
                            />
                            {this.validator.message('password', password, 'required')}
                            <div className="input-group-append">
                                <span className="input-group-text"><span className="feather-icon"><i data-feather="eye-off"></i></span></span>
                            </div>
                        </div>
                    </div>
                    <div className="custom-control custom-checkbox mb-25">
                        <input className="custom-control-input" id="same-address" type="checkbox" />
                        <label className="custom-control-label font-14" htmlFor="same-address">Keep me logged in</label>
                    </div>
                    <button className="btn btn-warning btn-block"
                        onClick={this.handleSubmit}
                    >Login</button>
                </form>
                <div className="my-4 text-right">
                    <Link to="/auth/forgotPassword" className="font-bold text-orange">Forgot Password</Link>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    userPermissionDetails: state.common.userPermissionDetails
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleLogin
    }, dispatch)
}

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginClass)