import { masterType } from "../../services/actionType"

const initialState = {
	cityLists: {},
	categoryLists: {},
	languageLists: {},
	cityDetails: {},
	driverDetailLists: {
		list: [
			{
				id: 1,
				name: 'Driver1',
				number: 9876543210,
				experience: 2,
				licenseId: 12345678,
				homeTown: "Chennai",
				description: "test description",
				status: 1

			},
			{
				id: 2,
				name: 'Driver2',
				number: 9876543211,
				experience: 1,
				licenseId: 12345679,
				homeTown: "Salem",
				description: "test description",
				status: 1

			},
			{
				id: 3,
				name: 'Driver3',
				number: 9876543212,
				experience: 2,
				licenseId: 12345678,
				homeTown: "Kerala",
				description: "test description",
				status: 1

			}
		],
		pageMeta: {
			limit: 10,
			page: 1,
			total: 8,
			totalPages: 1
		}

	},
	experienceGroupLists: {},
	travelExperienceGroupLists: {},
	physicalRestrictionLists: {},
	serviceProviderLists: {}
}


export default (state = Object.assign({}, initialState), { type, payload }) => {

	switch (type) {

		case masterType.updateCityListDetails:
			return {
				...state,
				cityLists: payload
			}
		case masterType.updateCityDetails:
			return {
				...state,
				cityDetails: payload
			}

		case masterType.updateCategoryListDetails:
			return {
				...state,
				categoryLists: payload
			}
		case masterType.updateExperienceGroupListDetails:
			return {
				...state,
				experienceGroupLists: payload

			}

		case masterType.updateTravelExperienceGroupListDetails:
			return {
				...state,
				travelExperienceGroupLists: payload

			}

		case masterType.updateLanguageListDetails:
			return {
				...state,
				languageLists: payload
			}

		case masterType.updatePhysicalRestrictionListDetails:
			return {
				...state,
				physicalRestrictionLists: payload
			}
		case masterType.updateServiceProviderListDetails:
			return {
				...state,
				serviceProviderLists: payload
			}
		case masterType.updatePickupDropListDetails:
			return {
				...state,
				pickupDropLocationLists: payload
			}
		case masterType.updateVehicleTypeListDetails:
			return {
				...state,
				vehicleTypeLists: payload
			}
		case masterType.updateStayTypeListDetails:
			return {
				...state,
				stayTypeLists: payload
			}
		case masterType.updateTicketNationalityTypeListDetails:
			return {
				...state,
				ticketNationalityTypeLists: payload
			}
		default:
			return state

	}
}