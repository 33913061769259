import React, { Component } from 'react';
import { NormalInput, ReactSelect, DateTime } from '../common';

import SimpleReactValidator from 'simple-react-validator';

import { hidePastDate, validateEndDate, _ } from "../../services/helperFunctions"

import { createOffer, getOfferList, updateOffer } from '../../redux/actions/offers';

import { LoaderWrapper } from "../../HOC"

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { thisExpression } from '@babel/types';

export class OfferModalClass extends Component {

    state = {
        formDetails: {
            name: "",
            description: "",
            offer_coupen: "",
            offer_type: "",
            offer_value: "",
            max_discount:null,
            offer_limit: "",
            start_date: "",
            end_date: "",
            min_amount: "",
            applicable_type:undefined,
            ids:[],
            user_usage_limit:"",
            min_orders:null,
            max_orders:null,
            errMsg:""
        },
        offerTypeOptions: [{
            label: "Percentage",
            value: "percentage",
        }, {
            label: "Amount",
            value: "amount",
        }]
        ,
        offerApplicableTypeOptions:[{
            label:"City",
            value:"city"
        },
        {
            label:"Category",
            value:"category"
        },
        {
            label:"Activity",
            value:"activity"
        },
        {
            label:"Cart",
            value:"cart"
        }
        ],
        offerTypeList:[

        ]
    }

    componentWillReceiveProps(props) {

        let { details } = props;

        this.setState({
            formDetails: details,
        })

    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message ">{message}</span>,
            autoForceUpdate: this,
            messages: {}
        });

    }

    handleInputs = ({ value, name }) => {

        let formDetails = Object.assign({}, this.state.formDetails);
        formDetails[name] = value;
        if(value==="cart"){
            formDetails['ids']=[]
        }
        this.setState({
            formDetails,
        })

    }

    handleSubmit = (id) => {
        if(this.state.formDetails.min_orders!=null && this.state.formDetails.max_orders!=null){
              if(this.state.formDetails.min_orders > this.state.formDetails.max_orders){
                this.setState({errMsg:"Min order amount should be less that max order amount"})
                return false
            }else{
                this.setState({errMsg:""})
            }
        }
        if(this.state.formDetails.offer_coupen.length < 6){
                this.setState({errLengthMsg:"Offer coupon should be of length 6"});
                return false;
            }else{
                    this.setState({errLengthMsg:""});
                }
        if(this.state.formDetails.offer_type==='percentage'){
            if(this.state.formDetails.offer_value >= 100){
                this.setState({errPercentMsg:"Offer percentage should be less than 100"});
                return false;
                }else{
                    this.setState({errPercentMsg:""});
                }
        }
        if (this.validator.allValid()) {
            if (id) {
                this.props.updateOffer(id, this.state.formDetails).then(() => {
                    this.props.getOfferList({ pageNo: 1 })

                    this.props.toggle();
                })
            } else {
                this.props.createOffer(this.state.formDetails).then(() => {

                    this.props.getOfferList({ pageNo: 1 })

                    this.props.toggle();

                    })
                }
        } else {

            this.validator.showMessages();

        }
    }

    render() {

        let { toggle,cityLists,activityLists,categoryLists} = this.props;
        let { formDetails = {}, offerTypeOptions,offerApplicableTypeOptions,offerTypeList} = this.state;
        let {
            id,
            name,
            description,
            offer_coupen,
            offer_type,
            offer_value,
            max_discount,
            offer_limit,
            start_date,
            end_date,
            min_amount,
            applicable_type,
            ids,
            user_usage_limit,
            min_orders,
            max_orders
        } = formDetails;
        let defaultIds=[];
        if(formDetails.applicable_type=='city'){
            offerTypeList.length=0;
            for(let i=0;i < cityLists.list.length;i++){
                offerTypeList.push(
                    {
                        'label':cityLists.list[i].city_name,
                        'value':cityLists.list[i].id,
                    })
            }
            if( ids && ids.length > 0){
                for(let j=0;j<ids.length;j++){
                    for(let x=0;x<offerTypeList.length;x++){
                        if(offerTypeList[x].id===ids[j]){
                        defaultIds.push({"label":offerTypeList[x].label,"value":offerTypeList[x].value})
                        }
                    }
                }
            }
        }
        if(formDetails.applicable_type=='activity'){
            offerTypeList.length=0;
            
            for(let i=0;i < activityLists.length;i++){
                
                offerTypeList.push(
                    {
                        'label':activityLists[i].title,
                        'value':activityLists[i].id,
                    })
            }
            if(ids && ids.length > 0){
                for(let j=0;j<ids.length;j++){
                    for(let x=0;x<offerTypeList.length;x++){
                        if(offerTypeList[x].id===ids[j]){
                        defaultIds.push({"label":offerTypeList[x].label,"value":offerTypeList[x].value})
                        }
                    }
                }
            }
        }
        if(formDetails.applicable_type=='category'){
            offerTypeList.length=0;
            for(let i=0;i < categoryLists.length;i++){
               
                offerTypeList.push(
                    {
                        'label':categoryLists[i].title,
                        'value':categoryLists[i].id,
                    })
            }
            if(ids && ids.length > 0){
                for(let j=0;j<ids.length;j++){
                    for(let x=0;x<offerTypeList.length;x++){
                        if(offerTypeList[x].id===ids[j]){
                        defaultIds.push({"label":offerTypeList[x].label,"value":offerTypeList[x].value})
                        }
                    }
                }
            }
        }
        if(formDetails.applicable_type=='cart'){
            offerTypeList.length=0;
        }
       
        return (
            <>
                <div className="slider-header p-4">
                    <div className="d-flex align-items-center">
                        <h5 className="sair-sec-title">{id ? 'Edit ' : 'Add '} Offer</h5>
                        <div className="ml-auto">
                            <i
                                className="icon-close font-md px-2 cursor-pointer"
                                onClick={toggle}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="slider-body p-4">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Name</label>
                            <NormalInput
                                type="text"
                                name="name"
                                value={name}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('name', name, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Description</label>
                            <textarea
                                type="text"
                                name="description"
                                value={description}
                                onChange={({ target: { name, value } }) => this.handleInputs({ name, value })}
                                className="form-control"
                            ></textarea>
                            {this.validator.message('description', description, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Offer Coupon</label>
                            <NormalInput
                                type="text"
                                name="offer_coupen"
                                value={offer_coupen}
                                onChange={this.handleInputs}
                                className="form-control"
                                disabled={this.state.formDetails.id ? true : false}
                            />
                            {this.validator.message('offer_coupen', offer_coupen, 'required')}
                            {this.state.errLengthMsg && <p className="text-danger">{this.state.errLengthMsg}</p>}
                           
                        </div>
                        {/* <div className="col-md-12 form-group">
                            <label>Offer Coupen</label>
                            <NormalInput
                                type="text"
                                name="offer_coupen"
                                value={offer_coupen}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('offer_coupen', offer_coupen, 'required')}
                        </div> */}
                        <div className="col-md-12 form-group">
                            <label>Offer Type</label>
                            <ReactSelect
                                isMulti={false}
                                options={offerTypeOptions}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="offer_type"
                                value={offer_type}
                                disabled={false}
                            />
                            {this.validator.message('offer_type', offer_type, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Offer Value</label>
                            <NormalInput
                                type="number"
                                name="offer_value"
                                value={offer_value}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.state.errPercentMsg && <p class="text-danger">{this.state.errPercentMsg}</p>}
                            {this.validator.message('offer_value', offer_value, 'required')}
                        </div>
                        {offer_type === 'percentage' && <div className="col-md-12 form-group">
                            <label>Max Discount Amount</label>
                            <NormalInput
                                type="number"
                                name="max_discount"
                                value={max_discount}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('max_discount', max_discount, 'required')}
                        </div>
                        }
                        <div className="col-md-12 form-group">
                            <label>Offer Limit</label>
                            <NormalInput
                                type="number"
                                name="offer_limit"
                                value={offer_limit}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('offer_limit', offer_limit, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Start Date</label>
                            <DateTime
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                placeholder="MM/DD/YYY"
                                timeFormat={false}
                                name="start_date"
                                value={start_date}
                                isValidDate={hidePastDate}
                                
                            />
                            {this.validator.message('start_date', start_date, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>End Date</label>
                            <DateTime
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                placeholder="MM/DD/YYY"
                                timeFormat={false}
                                name="end_date"
                                value={end_date}
                                isValidDate={hidePastDate}
                            />
                            {this.validator.message('end_date', end_date, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Min Amount</label>
                            <NormalInput
                                type="number"
                                name="min_amount"
                                value={min_amount}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('min_amount', min_amount, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Offer Applicable Type</label>
                            <ReactSelect
                                isMulti={false}
                                options={offerApplicableTypeOptions}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="applicable_type"
                                value={applicable_type}
                                disabled={false}
                            />
                            {this.validator.message('applicable_type', applicable_type, 'required')}
                        </div>
                        {applicable_type!==undefined && offerTypeList.length > 0 && <div className="col-md-12 form-group">
                            <label>{applicable_type}</label>
                            <ReactSelect
                                isMulti={true}
                                options={offerTypeList}
                                defaultValue={defaultIds}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="ids"
                                value={ids}
                                disabled={false}
                            />
                            {applicable_type !=='cart' && this.validator.message('ids', ids, 'required')}
                            {this.state.errMsg && <p>{this.state.errMsg}</p>}
                        </div>
                        }
                        <div className="col-md-12 form-group">
                            <label>User Usage Limit</label>
                            <NormalInput
                                type="number"
                                name="user_usage_limit"
                                value={user_usage_limit}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                            {this.validator.message('user_usage_limit', user_usage_limit, 'required')}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Min Orders</label>
                            <NormalInput
                                type="number"
                                name="min_orders"
                                value={min_orders}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Max Orders</label>
                            <NormalInput
                                type="number"
                                name="max_orders"
                                value={max_orders}
                                onChange={this.handleInputs}
                                className="form-control"
                            />
                        </div>
                        <div>
                            {this.state.errMsg}
                        </div>
                    </div>
                    <hr />
                    <button
                        onClick={() => this.handleSubmit(id)}
                        className="btn btn-primary"
                    >
                        Submit
                    </button>
                </div>
            </>
        )

    }

}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        createOffer,
        getOfferList,
        updateOffer
    }, dispatch)
};

let component = OfferModalClass

export const OfferModal = connect(null, mapDispatchToProps)(component)