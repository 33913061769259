import React, { Component } from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor, } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "../TextEditor/style.scss";

let options = {
    options: ['inline', 'link', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline'],
    },
    link:{
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        linkCallback: undefined
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify'],
    },

    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
        undo: { className: undefined },
        redo: { className: undefined },
    },
}

let optionsForAddons = {
    options: ['inline'],
    inline: {
        inDropdown: false,
        options: ['bold'],
    }
}

export class TextEditorActivity extends Component {

    constructor(props) {

        super(props);

        const html = '<p></p>';

        const contentBlock = htmlToDraft(html);

        if (contentBlock) {

            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

            const editorState = EditorState.createWithContent(contentState);

            this.state = {
                editorState,
                refresh: 0,
                getText: ""
            };

        }

    }

    componentDidMount() {
        this.getDataFromProps(this.props)
    }

    componentWillReceiveProps(props) {
        this.getDataFromProps(props)

    }

    getDataFromProps({ html }) {

        if (!html) {

            html = '<p></p>'
        }

        const contentBlock = htmlToDraft(html);

        if (contentBlock) {

            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

            const editorState = EditorState.createWithContent(contentState);


            this.setState({
                editorState
            })

        }

    }



    onEditorStateChange = (newText) => {
        let { maxChar } = this.props;
        let htmlText = newText.getCurrentContent();
        this.setState({getText:htmlText})
        let isMax = maxChar && htmlText.length > maxChar

        this.setState(({ refresh }) => ({
            editorState: isMax ? this.state.editorState : newText,
            ...(isMax ? { refresh: refresh + 1 } : '')
        }));

    }; 

    handleUpdateParent = (e, newText) => {
        let textHtml = this.state.getText;    
        this.props.handleTextEditor(draftToHtml(convertToRaw(textHtml)));
    }
    
    handleBlurParent = (e, newText)=>{
        let html_txt = draftToHtml(convertToRaw(newText.getCurrentContent()));
        this.props.handleTextEditorInput(html_txt);
    }

    render() {

        const { editorState, refresh } = this.state;

        let { disabled, maxChar, validationError, isAddon = false } = this.props

        return (
            <div className="position-relative">
                <div className={`${disabled ? 'usertype_overlay' : ''}`}></div>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper border"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    onBlur = {this.handleBlurParent}
                    onChange = {this.handleUpdateParent}
                    key={refresh}
                    toolbar={isAddon ? optionsForAddons : options}
                    stripPastedStyles={true}
                />
                <div className="d-flex">
                    <div className="pr-2">{validationError ? validationError : ''}</div>
                    {maxChar ?
                        <p className="text-right ml-auto">
                            {editorState.getCurrentContent().getPlainText().length}/{maxChar} Characters
                        </p>
                        : ""}
                </div>
            </div>
        );
    }
}
