import React, { Component } from 'react';
import { TableWrapper, SliderOverlay, NormalInput, FileUpload, ConfirmModal } from '../../component/common';

import { CityFestivalModal } from '../../component/CityFestival';

import { getCityFestivalList, getOneFestivalDetails, uploadCityfestivalImage, deleteCityFestival } from '../../redux/actions/cityfestival';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"
import { getSNo } from '../../services/helperFunctions';

export class CityFestivalClass extends Component {

    state = {
        action: 'add',
        headerDetails: [{
            label: 'S.No',
        }, {
            label: 'Title',
        }, {
            label: 'Image',
        }, {
            label: 'Action',
        }],
        isOpen: false,
        details: {
            formDetails: {
                title: '',
                festival_image: '',
                description: '',
                city_list_id: '',
                activity_id: [],
            },
        },
        ConfirmModalOpenStatus: false,
        deleteCityFestival: null,
        city_list_id: null
    }

    toggle = name => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }));
    }

    handleAdd = () => {

        this.toggle('isOpen')

    }

    handleFilter = (query, value, name) => {

        if (name == "filter") {
            this.setState({
                selectedFilter: query
            })

            query = {
                pageNo: 1,
                filter: query
            }
        }

        this.props.getCityFestivalList(query)

    }

    handleNavigation = (index, cityFestivalList) => {
        let oneData = [...cityFestivalList.list]
        oneData = oneData.find((x, i) => i === index)
        let id = oneData.city_list_id, title = oneData.title

        let query = {
            url_id: title.split(' ').join('%20') + '/' + id
        }
        this.props.getOneFestivalDetails(query).then((data) => {
            let details = { ...this.state.details }
            details.formDetails = {
                title: data.festival_title,
                festival_image : data.festival_image,
                festival_image_url : data.festival_image_url,
                description: data.festival_description,
                city_list_id: data.city_list_id,
                activity_id: data.activity.map(x => {
                    return { city_festival_id: x.id, activity_id: x.activity_id }
                })
            }

            this.setState({ details, action: 'edit' })
            this.toggle('isOpen')
        })

    }

    handleInputs = ({ value, name }) => {
        let formDetails = Object.assign({}, this.state.details.formDetails);
        formDetails[name] = value;
        this.setState({
            details: { ...this.state.details, formDetails }
        })
    }

    handleMultipleInputs = (index, { name, value }) => {

        let formDetails = Object.assign({}, this.state.details.formDetails)

        formDetails[name][index] = value;

        this.setState({
            details: { ...this.state.details, formDetails }
        })
    }

    handleMultipleInputsEdit = (index, { name, value }) => {
        let formDetails = Object.assign({}, this.state.details.formDetails)

        if(formDetails[name][index].city_festival_id){
            formDetails[name][index].activity_id = value 
        }else{
            formDetails[name][index] = { city_festival_id: null, activity_id: value };
        }

        this.setState({
            details: { ...this.state.details, formDetails }
        })
    }

    handleDeleteToggle = (title, city_list_id) => {
        const deleteCityFestival = title.split(" ").join("%20");
        this.setState({ deleteCityFestival, city_list_id })
        this.toggle('ConfirmModalOpenStatus')

    }

    handleDelete = () => {
        const { deleteCityFestival, city_list_id } = this.state
        this.props.deleteCityFestival(deleteCityFestival, city_list_id).then(() => {
            this.props.getCityFestivalList({ pageNo: 1 });
            this.toggle('ConfirmModalOpenStatus')
        })
    }

    componentDidMount() {

        this.fileUpload = React.createRef();
        this.ImagePreview = this.ImagePreview.bind(this);

    }

    ImagePreview = ({ target: { files } }) => {

        let formData = new FormData()

        formData.append("cityFestivalImage", files[0])

        this.props.uploadCityfestivalImage(formData).then((data) => {

            let formDetails = Object.assign({}, this.state.details.formDetails)

            let { festival_image, festival_image_url } = data;

            formDetails.festival_image = festival_image;
            formDetails.festival_image_url = festival_image_url

            this.setState({
                details: { ...this.state.details, formDetails }
            })

        })

    }

    stateReset = () => {
        const details = {
            formDetails: {
                title: '',
                festival_image: '',
                description: '',
                city_list_id: '',
                activity_id: [],
            },
        }

        this.setState({ details, action: 'add' })
    }

    toggleModalStatus = () => {
        this.stateReset()
        this.toggle('isOpen')
    }

    festivalData = (data, value) => {
        let formDetails = Object.assign({}, this.state.details.formDetails)

        formDetails[data.name] = data.value;

        this.setState({
            details: { ...this.state.details, formDetails }
        })
    }

    handleAddActivity = (formDetails) => {
        console.log('Here before setstate')
        const { details } = this.state
        details.formDetails = formDetails
        this.setState({
            details
        }, () => console.log('Here after SetState'))

    }

    render() {

        let { headerDetails, isOpen, details, ConfirmModalOpenStatus, action } = this.state;

        let { cityFestivalList = {} } = this.props;

        let { list = [], pageMeta = {} } = cityFestivalList;

        let IsEmpty = list.length > 0;
        return (

            <div>
                <div className="sair-pg-header">
                    <h4 className="sair-pg-title align-self-center">
                        <span className="pg-title-icon">
                            <span className="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>City Festival
                    </h4>
                    <div class="col-auto ">
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={this.handleAdd}
                        >Add City Festival</button>
                    </div>
                </div>
                <div className="sair-sec-wrapper">
                    <div className="row">
                        <div className="col-sm">
                            <div className="row no-gutters">
                                <h5 className="sair-sec-title align-self-center">
                                    City Festival List
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="table-wrap mt-3">
                        <TableWrapper
                            headerDetails={headerDetails}
                            isEmpty={IsEmpty}
                            pageMeta={pageMeta}
                            queryHandler={(query) => this.handleFilter(query)}
                        >

                            {list.map((item, index) => {

                                let {
                                    title,
                                    festival_image_url,
                                    city_list_id,
                                } = item;

                                return (
                                    <tr key={index}>
                                        <td>{getSNo(pageMeta, index)}</td>
                                        <td>{title}</td>
                                        <td><img src={festival_image_url} style={{ width: '50px' }} /></td>
                                        <td>
                                            <a className="mr-25"
                                                onClick={e => this.handleNavigation(index, cityFestivalList)}
                                            >
                                                <i className="icon-pencil  text-primary cursor-pointer"
                                                ></i>
                                            </a>
                                            <a ><i
                                                className="icon-trash txt-danger text-primary cursor-pointer"
                                                onClick={e => this.handleDeleteToggle(title, city_list_id)}
                                            ></i>
                                            </a>
                                        </td>
                                    </tr>
                                )

                            })}

                        </TableWrapper>
                    </div>

                </div>
                <SliderOverlay isOpen={isOpen} toggle={() => this.toggleModalStatus()} size='30%'>
                    <div className="side-slider h-100 position-relative">
                        <div className="h-100 flex-with-center">
                            <CityFestivalModal
                                toggle={() => this.toggle('isOpen')}
                                details={details}
                                action={action}
                                handleAddActivity={this.handleAddActivity}
                                stateReset={this.stateReset}
                                handleInputs={this.handleInputs}
                                handleMultipleInputs={this.handleMultipleInputs}
                                handleMultipleInputsEdit={this.handleMultipleInputsEdit}
                                festivalData={this.festivalData}
                                ImagePreview={this.ImagePreview}
                                getCityFestivalList={this.props.getCityFestivalList}
                            />
                        </div>
                    </div>
                </SliderOverlay>

                <ConfirmModal
                    keyName="ConfirmModalOpenStatus"
                    toggle={this.toggle}
                    isOpen={ConfirmModalOpenStatus}
                    handleSubmit={() => this.handleDelete()}
                    submitText='Delete'
                >
                    <h5>
                        Are you sure want to delete the festival?
                    </h5>
                </ConfirmModal>
            </div>
        )

    }

}

const mapStateToProps = state => {

    return {
        cityFestivalList: state.cityfestival.cityFestivalList
    }


};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getCityFestivalList,
        getOneFestivalDetails,
        uploadCityfestivalImage,
        deleteCityFestival
    }, dispatch)
};


let actions = ['getCityFestivalList']

let component = LoaderWrapper(CityFestivalClass)({ actions })

export const CityFestival = connect(mapStateToProps, mapDispatchToProps)(component)