import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useFormik } from "formik";
import moment from "moment";
import ReactDateTime from "react-datetime";
import * as Yup from "yup";

import { ConfirmModal } from "../../component/common";
import {
  updateSpecialDiscount,
  getSpecialDicount,
} from "../../redux/actions/discount";
import { LoaderWrapper } from "../../HOC";

export const Schema = Yup.object().shape({
  startDate: Yup.string()
    .required("Required")
    .test("raneInvalid", "Invalid start date", function (value) {
      if (!value) return true;
      const endDate = this.parent.endDate;
      if (!endDate) return true;
      return moment(value).isBefore(endDate);
    }),
  endDate: Yup.string()
    .required("Required")
    .test("raneInvalid", "Invalid end date", function (value) {
      if (!value) return true;
      const startDate = this.parent.startDate;
      if (!startDate) return true;
      return moment(value).isAfter(startDate);
    }),
});

const DiscountInfoComponent = ({
  discountInfo,
  getSpecialDicount,
  updateSpecialDiscount,
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const formikProps = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      onItemSubmit(values);
    },
    validationSchema: Schema,
  });

  useEffect(() => {
    getSpecialDicount();
  }, []);

  useEffect(() => {
    if (discountInfo) {
      formikProps.setFieldValue("startDate", discountInfo.start_date);
      formikProps.setFieldValue("endDate", discountInfo.end_date);
    }
  }, [discountInfo]);

  const onItemSubmit = async (values) => {
    try {
      await updateSpecialDiscount({
        startDate: values.startDate,
        endDate: values.endDate,
      });
      getSpecialDicount();
    } catch (error) {}
  };

  const onStopDeal = async () => {
    try {
      setIsConfirmOpen(false);
      await updateSpecialDiscount({
        startDate: discountInfo.start_date,
        endDate: discountInfo.start_date,
      });
      getSpecialDicount();
    } catch (error) {}
  };

  return (
    <section className="sair-sec-wrapper">
      <div className="table-wrap mt-3">
        <h4>
          Deal is:
          {discountInfo.start_date === discountInfo.end_date ? (
            <span className="text-danger">OFF</span>
          ) : (
            <span className="text-success">ON</span>
          )}
        </h4>
        <div className="row mt-10">
          <div className="col-md-6 form-group">
            <label>Start Date</label>
            <ReactDateTime
              inputProps={{
                className: "form-control form-control-md",
              }}
              value={formikProps.values.startDate}
              timeFormat="HH:mm:ss"
              dateFormat="YYYY-MM-DD"
              onChange={(date) => {
                formikProps.setFieldValue(
                  "startDate",
                  moment(date).format("YYYY-MM-DD HH:mm:ss")
                );
              }}
            />
            <span className="error-message font-md">
              {formikProps.touched.startDate && formikProps.errors.startDate
                ? formikProps.errors.startDate
                : ""}
            </span>
          </div>
          <div className="col-md-6 form-group">
            <label>End Date</label>
            <ReactDateTime
              inputProps={{
                className: "form-control form-control-md",
              }}
              value={formikProps.values.endDate}
              timeFormat="HH:mm:ss"
              dateFormat="YYYY-MM-DD"
              onChange={(date) => {
                formikProps.setFieldValue(
                  "endDate",
                  moment(date).format("YYYY-MM-DD HH:mm:ss")
                );
              }}
            />
            <span className="error-message font-md">
              {formikProps.touched.endDate && formikProps.errors.endDate
                ? formikProps.errors.endDate
                : ""}
            </span>
          </div>
          <div className="col-md-12 form-group">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={formikProps.handleSubmit}
            >
              Submit
            </button>
            <button
              className="btn btn-danger ml-15"
              onClick={() => setIsConfirmOpen(true)}
            >
              Stop Deal
            </button>
          </div>
        </div>
      </div>
      <ConfirmModal
        keyName="ConfirmModalOpenStatus"
        toggle={() => setIsConfirmOpen(!isConfirmOpen)}
        isOpen={isConfirmOpen}
        handleSubmit={onStopDeal}
        submitText="Stop"
      >
        <h5>Are you sure want to stop this Deal?</h5>
      </ConfirmModal>
    </section>
  );
};

const mapStateToProps = (state) => ({
  discountInfo: state.discount.info,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateSpecialDiscount,
      getSpecialDicount,
    },
    dispatch
  );
};

const actions = ["getSpecialDicount"];
const component = LoaderWrapper(DiscountInfoComponent)({ actions });

export const DiscountInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
