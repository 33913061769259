import { topActivity } from "../../services/apiVariables"
import { topActivityType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getTopActivityList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        addQuery(query, topActivity.getTopActivityList)

        api(topActivity.getTopActivityList).then(({ data, message }) => {

            dispatch({ type: topActivityType.updateTopActivityDetails, payload: data })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })


        })

    })
}


export const getActivityListDropdown = () => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api(topActivity.getActivityListDropdown).then(({ data, message }) => {

            dispatch({ type: topActivityType.updateTopActivityDropdownDetails, payload: data })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })


        })

    })
}

export const createTopActivity = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        api({ ...topActivity.createTopActivity, body }).then(({ data, message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            dispatch({ type: topActivityType.updateTopActivityDropdownDetails, payload: data })

            resolve(Toast({ type: 'success', message }))

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const updateTopActivity = (id, body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        topActivity.updateTopActivity.addParams = id;

        api({ ...topActivity.updateTopActivity, body }).then(({ data, message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            resolve(Toast({ type: 'success', message }))

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

export const deleteTopActivity = (id) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        dispatch({ type: common.updateLoader, payload: true })

        topActivity.deleteTopActivity.addParams = id;

        api(topActivity.deleteTopActivity).then(({ data, message }) => {

            dispatch({ type: common.updateLoader, payload: false })

            resolve(Toast({ type: 'success', message }))

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

