
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import SimpleReactValidator from 'simple-react-validator';
import { DateTime, ReactSelect, NormalInput, ToggleCheckbox, ConfirmModal, TextEditor } from "../../component/common"
import { hidePastDate, validateEndDate, _ } from "../../services/helperFunctions"
import moment from "moment"
import { FileUpload } from "../../component/common/fileUpload"
import { getAdvertisementTypeOneDetailsById, createAdvertisementTypeOne, updateAdvertisementTypeOne, getCityListDetails, addImage, createSliderAdvertisementImage, getCategoryMappingListDetails, getActivityListDetails} from "../../redux/actions/advertisementtypeone" ;
import { LoaderWrapper } from "../../HOC"
import { ReactAutoComplete } from '../../component/common';
import {uniqBy} from 'lodash';
class CreateAdvertisementTypeOneClass extends Component {
    state = {
        advertisementTypeOneDetails: {
            name: '',
            advertisement_type_one_image: '',
            advertisement_type_one_imageUrl: '',
            headline: '',
            additional_text: '',
            url: '',
            city_id: '',
            city_activity: '',
            top_activities_header: '',
            advertisement_type_one_top_activities: [
                {
                    activity_id: '',
                    top_activity_position: '',
                    deleted: true
                }
            ],
            other_activities_header: '',
            advertisement_type_one_other_activities: [
                {
                    activity_id: '',
                    other_activity_position: '',
                    deleted: true
                }
            ],
            advertisement_slider_images: [{
                image: "",
                image_url: "",
                image_position:""
              }],
            form_header: "",
            meta_title: "",
            meta_description: "",
            meta_keywords: "",
        },
        isError: false,
        isOpen: false,
        isEdit: false,
        tempFile: '',
        deleteKey: '',
        ConfirmModalOpenStatus: false,
    }
    
    componentWillMount() {    
        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message font-md">{message}</span>,
            autoForceUpdate: this,
            messages: {
                int: 'this :attribute required here'
            }
        });
        this.validators = new SimpleReactValidator({
            autoForceUpdate: this,
            element: message => {
                let messages = message.split('/');
                return (
                    <span className="error-message font-md">
                    {`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
                    </span>
                    )
                },
                
            });
        }
        
        componentDidMount() {
            let advertisementTypeOneDetails = Object.assign({}, this.state.advertisementTypeOneDetails)
            let { match: { params: { id } } } = this.props;
            
            if (id) {
                this.props.getAdvertisementTypeOneDetailsById(id).then((data) => {
                    let {
                        id,
                        name,
                        AdvertisementTypeOneImages,
                        headline,
                        additional_text,
                        url,
                        city_id,
                        city_activity,
                        top_activities_header,
                        other_activities_header,
                        AdvertisementSliderImages,
                        AdvertisementTypeOneTopActivities,
                        AdvertisementTypeOneOtherActivities,
                        form_header,
                        meta_title,
                        meta_description,
                        meta_keywords
                    } = data;
                    
                    let  advertisement_type_one_top_activities  = []
                    if (AdvertisementTypeOneTopActivities && AdvertisementTypeOneTopActivities.length) {            
                        AdvertisementTypeOneTopActivities.map((item) => {
                            advertisement_type_one_top_activities.push({
                                advertisement_type_one_top_activity_id: item.id,
                                activity_id: item.activity_id,
                                top_activity_position: item.top_activity_position,
                                deleted: false
                            })
                        })
                    } else {
                      advertisement_type_one_top_activities.push({
                            activity_id: '',
                            top_activity_position: '',
                            deleted: true
                        })
                    }

                    let  advertisement_type_one_other_activities  = []
                    if (AdvertisementTypeOneOtherActivities && AdvertisementTypeOneOtherActivities.length) {
                        AdvertisementTypeOneOtherActivities.map((item) => {
                            advertisement_type_one_other_activities.push({
                                advertisement_type_one_other_activity_id: item.id,
                                activity_id: item.activity_id,
                                other_activity_position: item.other_activity_position,
                                deleted: false
                            })
                        })
                    } else {
                        advertisement_type_one_other_activities.push({
                            activity_id:'',
                            other_activity_position:'',
                            deleted: true
                        })
                    }

                    let advertisement_slider_images = [];
                    if (AdvertisementSliderImages && AdvertisementSliderImages.length) {
                        AdvertisementSliderImages.map((item) => {
                            advertisement_slider_images.push({
                            image: item.image,
                            image_url: item.imageUrl,
                            image_id: item.id,
                            image_position: item.image_position,
                            deleted: false
                          })
                        })
              
                      } else {
              
                        advertisement_slider_images.push({
                          image: '',
                          image_url: '',
                          deleted: false,
                          image_position: ''   
                        })
              
                      }

                    advertisementTypeOneDetails = {
                        // id,
                        name,
                        advertisement_type_one_image: AdvertisementTypeOneImages.image,
                        advertisement_type_one_imageUrl: AdvertisementTypeOneImages.imageUrl,
                        advertisement_type_one_image_id: AdvertisementTypeOneImages.id,
                        headline,
                        additional_text,
                        url,
                        city_id,
                        city_activity,
                        advertisement_slider_images,
                        top_activities_header,
                        other_activities_header,
                        advertisement_type_one_top_activities,
                        advertisement_type_one_other_activities,
                        form_header,
                        meta_title,
                        meta_description,
                        meta_keywords
                    }
                    this.setState({
                        advertisementTypeOneDetails
                    })
                })
            }
        }
        
    
        handleDeleteToggle = (key, index) => {
            let { advertisementTypeOneDetails } = Object.assign({}, this.state)
            // activityDetails[key].splice(index, 1)
            if (advertisementTypeOneDetails[key].length) {
                advertisementTypeOneDetails[key].map((x, i) => {
                    if (i === index) {
                        x.deleted = true;
                        x=''
                    }
                })
            }
            
            this.setState({
                advertisementTypeOneDetails
            })
            
        }
        
        ImagePreview = (key, event) => {

            let files = event.target.files[0]
        
            let advertisementTypeOneDetails = Object.assign({}, this.state.advertisementTypeOneDetails)
        
            advertisementTypeOneDetails[key] = files
            let formData = new FormData()
        
            formData.append("advertisementTypeOneImage", files)

            this.props.addImage(formData).then((data) => {
        
              advertisementTypeOneDetails.advertisement_type_one_image = data.advertisement_type_one_image
        
              advertisementTypeOneDetails.advertisement_type_one_imageUrl = data.advertisement_type_one_image_url
        
        
            })

            this.setState({
                tempFile: URL.createObjectURL(files),
                advertisementTypeOneDetails,
              });

        }

        
        toggleDelete = (name) => {
            this.setState({
                [name]: !this.state[name]
            })
        }
        
        handleInputs = ({ value, name }) => {

            let advertisementTypeOneDetails = Object.assign({}, this.state.advertisementTypeOneDetails)
            advertisementTypeOneDetails[name] = value
            this.setState({
                advertisementTypeOneDetails
            })
            
            let {
                city_id,    
            } = { ...advertisementTypeOneDetails };
            
            //  check city id is not ull then get activity list for that city.
            if(city_id){
                this.props.getCategoryMappingListDetails(city_id).then((data) => {
                })
            }
           
        }
        
        handleSubmit = () => {
            let advertisementTypeOneDetails = Object.assign({}, this.state.advertisementTypeOneDetails)
            if (this.validator.allValid() && this.validators.allValid()) {
                if (advertisementTypeOneDetails.advertisement_type_one_top_activities && advertisementTypeOneDetails.advertisement_type_one_top_activities.length) {
                    if(advertisementTypeOneDetails.advertisement_type_one_top_activities[0].activity_id != ''){
                        advertisementTypeOneDetails.advertisement_type_one_top_activities.map(x => {
                            if( x.activity_id != ''){
                                x.activity_id = (x.activity_id)
                            }
                        })
                    }else{
                        advertisementTypeOneDetails.advertisement_type_one_top_activities= []
                    }
                }
                if (advertisementTypeOneDetails.advertisement_type_one_other_activities && advertisementTypeOneDetails.advertisement_type_one_other_activities.length) {
                    if(advertisementTypeOneDetails.advertisement_type_one_other_activities[0].activity_id != ''){
                        advertisementTypeOneDetails.advertisement_type_one_other_activities.map(x => {
                            if( x.activity_id != ''){
                                x.activity_id = (x.activity_id)
                            }
                        })
                    }else{
                        advertisementTypeOneDetails.advertisement_type_one_other_activities= []
                    }
                }
                
                let { match: { params: { id } } } = this.props;
                if (!id) {
                    let body = {
                        ...advertisementTypeOneDetails
                    }
                    this.props.createAdvertisementTypeOne(body).then((data) => {
                        let { history } = this.props
                        history.push('/advertisement_type_one/list')
                    })
                } else {
                    let {
                        name,
                        advertisement_type_one_image,
                        advertisement_type_one_imageUrl,
                        advertisement_type_one_image_id,
                        headline,
                        additional_text,
                        url,
                        city_id,
                        city_activity,
                        top_activities_header,
                        other_activities_header,
                        advertisement_slider_images,
                        advertisement_type_one_top_activities,
                        advertisement_type_one_other_activities,
                        form_header,
                        meta_title,
                        meta_description,
                        meta_keywords,
                    } = { ...advertisementTypeOneDetails };
                    
                    let body = {
                        name,headline,additional_text,url, city_id, advertisement_type_one_top_activities,advertisement_type_one_other_activities,
                          advertisement_type_one_images:{
                            advertisement_type_one_image,
                            advertisement_type_one_imageUrl,
                            advertisement_type_one_image_id
                        }, 
                        advertisement_slider_images,
                        top_activities_header,
                        other_activities_header,
                        form_header,
                        meta_title,
                        meta_description,
                        meta_keywords
                    }
                    this.props.updateAdvertisementTypeOne(id, body).then((data) => {
                        
                        let { history } = this.props
                        history.push('/advertisement_type_one/list')
                    })
                }
                
                this.validator.hideMessages();
                this.validators.hideMessages();
            } else {
                this.setState({
                    isError: true
                })
                this.validator.showMessages();
                this.validators.showMessages();
            }        
            
        }
        
        handleAddMultiple = (key) => {
            let advertisementTypeOneDetails = Object.assign({}, this.state.advertisementTypeOneDetails)
   
            if (key == "advertisement_type_one_top_activities" || key== "advertisement_type_one_other_activities") {
                 if(advertisementTypeOneDetails[key].length == 1 && advertisementTypeOneDetails[key][0].activity_id == ""){
                    advertisementTypeOneDetails[key][0].deleted = false
                    this.setState({advertisementTypeOneDetails});
                }
                else if(advertisementTypeOneDetails[key][(advertisementTypeOneDetails[key].length - 1)].activity_id === ""){
                    alert('please add the data')
                }else{
                    advertisementTypeOneDetails[key].push({
                        activity_id: '',
                        deleted: false
                       
                    })
                }
            }
            this.setState({
                advertisementTypeOneDetails
            })
        }
        
        handleMultiplePluginInput = (name, index, activity_id) => {
            
            let advertisementTypeOneDetails = Object.assign({}, this.state.advertisementTypeOneDetails);
            if(activity_id!=''){
                advertisementTypeOneDetails[name][index].activity_id = activity_id;            }
            this.setState({
                advertisementTypeOneDetails
            })
        }
        
       
  handleAddSliderImage = () => {

    let advertisementTypeOneDetails = Object.assign({}, this.state.advertisementTypeOneDetails);

    let errorStatus = advertisementTypeOneDetails.advertisement_slider_images.every((e, index) => {

        let error1 = this.validators.fieldValid(`/advertisement_slider_images/${index}/`)
  

        if (!error1) {
  
          this.validators.showMessageFor(`/advertisement_slider_images/${index}/`)
          return false
  
        }
        return true
  
      })

      if (errorStatus) {

        advertisementTypeOneDetails.advertisement_slider_images.push({
        image: "",
        image_url: "",
        image_position: ""
      })

      this.setState({
        advertisementTypeOneDetails,
      })

    }


  }

  handleSliderImage = (index, event) => {


    let files = event.target.files[0]

    let advertisementTypeOneDetails = _.cloneDeep(this.state.advertisementTypeOneDetails)

    let formData = new FormData()
    formData.append("advertisementSliderImage", files)
    let extension = files.name.split('.').pop();

        let extensions = ["webp", "jpg", "png"];
        let file_size = files.size;
      // Allow  image size is 3MB
        let size_obj = {
            other: 3072000
        }
        if (extensions.includes(extension)) {
            if (file_size <= size_obj['other']) {
                
                this.props.createSliderAdvertisementImage(formData).then(({ image, image_url }) => {
                    
                    advertisementTypeOneDetails.advertisement_slider_images[index].image = image;
                    
                    advertisementTypeOneDetails.advertisement_slider_images[index].image_url = image_url;

                    advertisementTypeOneDetails.advertisement_slider_images[index].image_position = "";
                    
                    this.setState({
                        advertisementTypeOneDetails
                    })
                })
                
            }
            else{
                alert('Allow file size for image is 3MB');
            }
            
            
        }else {
            alert("Allow file formats: jpg png webp");
        }
    };

    handleSliderImagePosition= (index, value) => {
        let advertisementTypeOneDetails = _.cloneDeep(this.state.advertisementTypeOneDetails)
        advertisementTypeOneDetails.advertisement_slider_images[index].image_position = value;
        
        this.setState({
            advertisementTypeOneDetails
        })
    }
 
    handleTopActivityPosition = (index, value) => {
        let advertisementTypeOneDetails = _.cloneDeep(this.state.advertisementTypeOneDetails)
        advertisementTypeOneDetails.advertisement_type_one_top_activities[index].top_activity_position = value;
        
        this.setState({
            advertisementTypeOneDetails
        })
    }
 
    handleOtherActivityPosition = (index, value) => {
        let advertisementTypeOneDetails = _.cloneDeep(this.state.advertisementTypeOneDetails)
        advertisementTypeOneDetails.advertisement_type_one_other_activities[index].other_activity_position = value;
        
        this.setState({
            advertisementTypeOneDetails
        })
    }
        render() {
            let {
                advertisementTypeOneDetails,tempFile
            } = this.state
            

            let {
      name,advertisement_type_one_image,advertisement_type_one_imageUrl,headline,additional_text, url, city_id,city_activity, top_activities_header, other_activities_header, advertisement_type_one_top_activities, advertisement_type_one_other_activities,
      advertisement_slider_images, form_header, meta_title, meta_description, meta_keywords
            } = advertisementTypeOneDetails

            let { cityLists = {}, activityLists={},mappingActivityLists={} } = this.props;
            
            let uniqueList=uniqBy(mappingActivityLists,(item)=>{
                return item.activity_id;
            });
            let { list = []} = cityLists;
            let finalTopactivityList = []      
            let finalOtheractivityList = []
            if(activityLists.list){
                for(let i=0;i<activityLists.list.length;i++){
                    if(activityLists.list[i].id){
                        finalOtheractivityList.push({
                            label: activityLists.list[i].title,
                            value: activityLists.list[i].id
                        })
                    }
                }
            }
          
            if(activityLists.list){
                for(let i=0;i<activityLists.list.length;i++){
                    if(activityLists.list[i].id){
                        finalTopactivityList.push({
                            label: activityLists.list[i].title,
                            value: activityLists.list[i].id
                        })
                    }
                }
            }
            

            let cityList = []
            list.forEach(item => {
                cityList.push({
                    label: item.city_name,
                    value: item.id
                })
            })
            
            return (
                <div>
                    <div className="sair-pg-header">
                        <h4 className="sair-pg-title align-self-center">
                            <span className="pg-title-icon">
                                <span className="feather-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" className="feather feather-align-left">
                                        <line x1="17" y1="10" x2="3" y2="10"></line>
                                        <line x1="21" y1="6" x2="3" y2="6"></line>
                                        <line x1="21" y1="14" x2="3" y2="14"></line>
                                        <line x1="17" y1="18" x2="3" y2="18"></line>
                                    </svg>
                                </span>
                            </span> Advertisement Landing Page
                        </h4>
                    </div>
                    <section className="sair-sec-wrapper">
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label>Name</label>
                                <NormalInput
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    value={name}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('Name', name, 'required')}
                                    number={false}
                                />
                            </div>

                            <div className="col-md-6 form-group">
                                <label >Advertisement Images</label>
                                <FileUpload
                                    fileUpload={this.fileUpload}
                                    ImagePreview={(e) => this.ImagePreview("advertisement_type_one_image", e)}
                                    filename={advertisement_type_one_image}
                                    tempFile={tempFile}
                                />
                                <img src={advertisement_type_one_imageUrl} className="mt-15 w-100" />
                                {this.validator.message('advertisement image', advertisement_type_one_image, 'required')}
                            </div>
                        </div>
                        <hr />
                        <div className="row">

                            <div className="col-md-6 form-group">
                                <label  >Headline</label>
                                <TextEditor
                                    handleTextEditor={html => {
                                        this.handleInputs({ name: 'headline', value: html })
                                    }}
                                    html={headline}
                                    disabled={false}
                                // maxChar={250}
                                />
                                {this.validator.message(' headline', headline, 'required')}
                            </div>
                            <div className="col-md-6 form-group">
                                <label  >Additional Text </label>
                                <TextEditor
                                    handleTextEditor={html => {
                                        this.handleInputs({ name: 'additional_text', value: html })
                                    }}
                                    html={additional_text}
                                    disabled={false}
                                // maxChar={250}
                                />
                                {this.validator.message(' additional text', additional_text, 'required')}
                            </div>

                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row mb-15">
                                    <div className="col-sm-6 d-flex align-items-end">

                                        <h6 className="">Slider Images</h6>
                                        <div className="ml-auto">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={this.handleAddSliderImage}
                                            >
                                                Add Slider Image
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">

                                    {advertisement_slider_images.filter(x => !x.deleted).map((item, index) => {

                                        let {
                                            image,
                                            image_url,
                                            image_position
                                        } = item;
                                        return (
                                            <div className="col-sm-6 mb-3" key={index}>
                                                <div className="row">
                                                    <div className={index !== 0 ? "col-sm-11" : "col-sm-12"}>
                                                        <FileUpload
                                                            fileUpload={this.fileUpload}
                                                            ImagePreview={(e) => this.handleSliderImage(index, e)}
                                                            filename={image}
                                                        />
                                                        <div className="col-md-6">
                                                            {image_url ? <img src={image_url} style={{ width: "100px", marginTop: "15px" }} /> : ""}
                                                        </div>
                                                        <div className="mt-15 col-md-6">
                                                            <NormalInput
                                                                className="form-control"
                                                                name="Slider Image Position"
                                                                type="number"
                                                                placeholder="position"
                                                                value={image_position}
                                                                onChange={({ name, value }) => {
                                                                    this.handleSliderImagePosition(index, value)
                                                                }}

                                                            />
                                                            {this.validator.message('slider position', image_position, 'required')
                                                            }

                                                        </div>
                                                    </div>
                                                    {index !== 0 ?
                                                        <div className="col-md-1 pt-2">
                                                            <i
                                                                className="icon-trash text-danger cursor-pointer"
                                                                onClick={e => this.handleDeleteToggle("advertisement_slider_images", index)}
                                                            ></i>
                                                        </div>
                                                        : ""}
                                                </div>
                                                {item ? this.validators.message(`/advertisement_slider_images/${index}/`, image, 'required') : ""}
                                            </div>
                                        )

                                    })}
                                </div>
                            </div>
                        </div>

                        <hr />


                        <div className="row">


                            <div className="col-md-6 form-group">
                                <label>URL</label>
                                <NormalInput
                                    className="form-control"
                                    name="url"
                                    type="text"
                                    value={url}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('url', url, 'required')}
                                    number={false}
                                />
                            </div>

                            <div className="col-md-6 form-group">
                                <label>City</label>
                                <ReactSelect
                                    isMulti={false}
                                    options={cityList}
                                    handleChange={(keyName, data) => {
                                        this.handleInputs({ name: keyName, value: data })
                                    }}
                                    keyName="city_id"
                                    value={city_id}
                                    disabled={false}
                                />
                                {this.validator.message('city', city_id, 'required')}

                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <label>Top activity header</label>
                                <NormalInput
                                    className="form-control"
                                    name="top_activities_header"
                                    type="text"
                                    value={top_activities_header}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    number={false}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Other activity header</label>
                                <NormalInput
                                    className="form-control"
                                    name="other_activities_header"
                                    type="text"
                                    value={other_activities_header}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    number={false}
                                />
                            </div>
                        </div>

                        <div className="row mt-15">
                            <div className="col-md-6">
                                <label>Form header</label>
                                <NormalInput
                                    className="form-control"
                                    name="form_header"
                                    type="text"
                                    value={form_header}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    number={false}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row mb-15">
                                    <div className="col-sm-6 d-flex align-items-end">

                                        <h6 className="">Top Activities</h6>
                                        <div className="ml-auto">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => this.handleAddMultiple('advertisement_type_one_top_activities')}
                                            >
                                                Add Top Activity
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">

                                    {advertisement_type_one_top_activities.filter(x => !x.deleted).map((item, index) => {

                                        let { activity_id, top_activity_position } = item;
                                        return (
                                            <div className="col-sm-6 mb-3" key={index}>
                                                <div className="row">
                                                    <div className={"col-sm-11"}>

                                                        <ReactSelect
                                                            isMulti={false}
                                                            options={finalTopactivityList}
                                                            handleChange={(name, data) => {
                                                                this.handleMultiplePluginInput("advertisement_type_one_top_activities", index, data)
                                                            }}
                                                            name="activity_id"
                                                            value={activity_id}
                                                            disabled={false}
                                                        />


                                                        {this.validator.message('top activity', activity_id, 'required')}
                                                    </div>
                                                    {
                                                        <div className="col-md-1 align-self-center">
                                                            <i
                                                                className="icon-trash text-danger cursor-pointer"
                                                                onClick={e => this.handleDeleteToggle("advertisement_type_one_top_activities", index)}
                                                            ></i>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="mt-15 col-md-6">
                                                        <NormalInput
                                                            className="form-control"
                                                            name="Top Activity Position"
                                                            type="number"
                                                            placeholder="position"
                                                            value={top_activity_position}
                                                            onChange={({ name, value }) => {
                                                                this.handleTopActivityPosition(index, value)
                                                            }}
                                                        />
                                                        {this.validator.message('top activity position', top_activity_position, 'required')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row mb-15">
                                        <div className="col-sm-6 d-flex align-items-end">

                                            <h6 className="">Other Activities</h6>
                                            <div className="ml-auto">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => this.handleAddMultiple('advertisement_type_one_other_activities')}
                                                >
                                                    Add Other Activity
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">

                                        {advertisement_type_one_other_activities.filter(x => !x.deleted).map((item, index) => {

                                            let { activity_id, other_activity_position } = item;
                                            return (
                                                <div className="col-sm-6 mb-3" key={index}>
                                                    <div className="row">
                                                        <div className="col-sm-11">
                                                            <ReactSelect
                                                                isMulti={false}
                                                                options={finalOtheractivityList}
                                                                handleChange={(name, data) => {
                                                                    this.handleMultiplePluginInput("advertisement_type_one_other_activities", index, data)
                                                                }}
                                                                name="activity_id"
                                                                value={activity_id}
                                                                disabled={false}
                                                            />
                                                            {this.validator.message('other activity', activity_id, 'required')}
                                                        </div>
                                                        <div className="col-md-1 align-self-center">
                                                            <i
                                                                className="icon-trash text-danger cursor-pointer"
                                                                onClick={e => this.handleDeleteToggle("advertisement_type_one_other_activities", index)}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mt-15 col-md-6">
                                                            <NormalInput
                                                                className="form-control"
                                                                name="Other Activity Position"
                                                                type="number"
                                                                placeholder="position"
                                                                value={other_activity_position}
                                                                onChange={({ name, value }) => {
                                                                    this.handleOtherActivityPosition(index, value)
                                                                }}
                                                            />
                                                            {this.validator.message('other activity position', other_activity_position, 'required')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <h6 className="mb-15 align-items-center justify-content-between">
                            <div> Meta Details </div>
                        </h6>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label >Meta Titles</label>
                                <NormalInput
                                    className="form-control"
                                    name="meta_title"
                                    type="text"
                                    value={meta_title}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('meta_title', meta_title, 'required')}
                                    number={false}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Meta Keywords</label>
                                <NormalInput
                                    className="form-control"
                                    name="meta_keywords"
                                    type="text"
                                    value={meta_keywords}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('meta_keywords', meta_keywords, 'required')}
                                    number={false}
                                />
                            </div>

                            <div className="col-md-6 form-group">
                                <label>Meta Description</label>
                                <textarea
                                    className="form-control"
                                    name="meta_description"
                                    onChange={(e) => {
                                        this.handleInputs({ name: e.target.name, value: e.target.value })
                                    }}
                                    value={meta_description}
                                />
                                {this.validator.message('meta_description', meta_description, 'required')}
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-primary"
                                    onClick={() => this.handleSubmit()}
                                >Submit
                                </button>
                            </div>
                        </div>
                    </section>
                </div>

                    
                    
                )
                    }
                }
    
    const mapStateToProps = state => {
        return {
            cityLists: state.advertisementtypeone.cityLists,
            activityLists: state.advertisementtypeone.activityLists,
            mappingActivityLists: state.advertisementtypeone.mappingActivityLists

        }
        
    };
                
    const mapDispatchToProps = dispatch => {
        return bindActionCreators({
            
            addImage,
            createAdvertisementTypeOne,
            // getActivityDropDown,
            getAdvertisementTypeOneDetailsById,
            updateAdvertisementTypeOne,
            getCityListDetails,
            createSliderAdvertisementImage,
            getCategoryMappingListDetails,
            getActivityListDetails
            
            
        }, dispatch)
    };
    
    
    let actions = ['getCityListDetails', 'getCategoryMappingListDetails', 'getActivityListDetails']
    let component = LoaderWrapper(CreateAdvertisementTypeOneClass)({ actions })
    
    
    export const CreateAdvertisementTypeOne = connect(mapStateToProps, mapDispatchToProps)(component)