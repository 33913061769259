import React, { Component } from 'react';
import { ReactSelect } from '../common';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"

import {
    getTopActivityCityDropdown,
    createTopActivityCity,
    getTopActivityCityList,
    updateTopActivityCity,
} from '../../redux/actions/topactivitycity';

import SimpleReactValidator from 'simple-react-validator';

export class TopActivityCityModalClass extends Component {

    state = {
        activityOptions: [],
        cityOptions: [],
        formDetails: {
            activity_id: '',
            city_id: '',
        }
    }

    componentWillReceiveProps({ details: { activity_id, city_id } }) {

        let formDetails = Object.assign({}, this.state.formDetails)

        formDetails = {
            activity_id,
            city_id,
        }

        this.setState({
            formDetails
        })

    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message ">{message}</span>,
            autoForceUpdate: this,
            messages: {}
        });

    }

    componentDidMount() {

        let { topActivityCityDropdown } = this.props;

        let activityOptions = this.state.activityOptions.slice(0)

        let cityOptions = this.state.cityOptions.slice(0)

        let { activity_list, city_list } = topActivityCityDropdown;

        activity_list.map((item) => {

            activityOptions.push({
                label: item.title,
                value: item.id,
            })

        })

        city_list.map((item) => {

            cityOptions.push({
                label: item.city_name,
                value: item.id,
            })

        })

        this.setState({
            activityOptions,
            cityOptions,
        })

    }

    handleInputs = ({ value, name }) => {

        let formDetails = Object.assign({}, this.state.formDetails);

        formDetails[name] = value;

        this.setState({
            formDetails
        })


    }

    handleSubmit = () => {

        if (this.validator.allValid()) {

            let { id } = this.props.details;

            if (id) {

                this.props.updateTopActivityCity(id, this.state.formDetails).then(() => {

                    this.props.getTopActivityCityList({ pageNo: 1 });

                    this.props.toggle();

                })

            } else {

                this.props.createTopActivityCity(this.state.formDetails).then(() => {

                    this.props.getTopActivityCityList({ pageNo: 1 });

                    this.props.toggle();

                })

            }

        } else {

            this.validator.showMessages();

        }

    }

    render() {

        let { toggle } = this.props;

        let { activityOptions, cityOptions, formDetails } = this.state;

        let { activity_id, city_id } = formDetails;

        return (
            <>
                <div className="slider-header p-4">
                    <div className="d-flex align-items-center">
                        <h5 className="sair-sec-title">Add Top Activity Mapping</h5>
                        <div className="ml-auto">
                            <i
                                className="icon-close font-md px-2 cursor-pointer"
                                onClick={toggle}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="slider-body p-4">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Activity</label>
                            <ReactSelect
                                isMulti={false}
                                options={activityOptions}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="activity_id"
                                value={activity_id}
                                disabled={false}
                            />
                            {this.validator.message('Activity', activity_id, 'required')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>City</label>
                            <ReactSelect
                                isMulti={false}
                                options={cityOptions}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="city_id"
                                value={city_id}
                                disabled={false}
                            />
                            {this.validator.message('City', city_id, 'required')}
                        </div>
                    </div>
                    <hr />
                    <button
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                    >Submit</button>
                </div>

            </>
        )

    }

}

const mapStateToProps = state => {
    return {
        topActivityCityDropdown: state.topactivitycity.topActivityCityDropdown,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getTopActivityCityDropdown,
        createTopActivityCity,
        getTopActivityCityList,
        updateTopActivityCity,
    }, dispatch)
};


let actions = ['getTopActivityCityDropdown']

let component = LoaderWrapper(TopActivityCityModalClass)({ actions })

export const TopActivityCityModal = connect(mapStateToProps, mapDispatchToProps)(component)