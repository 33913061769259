import { topCategoryType } from "../../services/actionType"

const initialState = {
    topCategoryList: [],
    topCategoryDropdown: [],
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {

        case topCategoryType.updateTopCategoryDetails:

            return {
                ...state,
                topCategoryList: payload
            }

        case topCategoryType.updateTopCategoryDropdown:

            return {
                ...state,
                topCategoryDropdown: payload
            }

        default:
            return state

    }
}