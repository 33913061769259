import React, { Component } from 'react'
import { FileUpload, ReactAutoComplete } from "../../component/common"

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import SimpleReactValidator from 'simple-react-validator';
import { createCity, getCityListDetails, editCity, cityImageUpload, getCityDetails } from "../../redux/actions/master"

import { TextEditor, DateTime } from '../../component/common';

import { hidePastDate } from "../../services/helperFunctions"

import _ from "lodash"

class AddCityClass extends Component {
	state = {
		cityDetails: {
			city_name: '',
			series_no: '',
			location: '',
			// city_image: '',
			latitude: '',
			longitude: '',
			traveller_info: '',
			description: '',
			cityImages: [{
				city_image: null,
				city_image_url: '',
			}],
			month_one: "",
			degree_one: "",
			month_two: "",
			degree_two: "",
			month_three: "",
			degree_three: "",
			month_four: "",
			degree_four: "",
			weather_time: "",
			currency: "",
			meta_title:"",
			meta_description:"",
			meta_keywords:"",
		},
		tempFile: '',
		isEdit: false
	}


	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
		this.validators = new SimpleReactValidator({
			element: message => {

				let messages = message.split('/');

				return (
					<span className="error-message font-md">
						{`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
					</span>
				)
			},

		});
	}

	componentDidMount() {

		let { match: { params: { id } } } = this.props;

		this.fileUpload = React.createRef();
		this.ImagePreview = this.ImagePreview.bind(this);

		if (id) {

			this.props.getCityDetails(id).then((data) => {

				let {
					id,
					city_name,
					series_no,
					location,
					latitude,
					longitude,
					traveller_info,
					description,
					CityImage = [],
					sub_title,
					month_one,
					degree_one,
					month_two,
					degree_two,
					month_three,
					degree_three,
					month_four,
					degree_four,
					weather_time,
					currency,
					meta_title,
					meta_description,
					meta_keywords,
				} = data;

				let cityDetails = {
					id,
					city_name,
					series_no: series_no.toString(),
					location,
					latitude,
					longitude,
					traveller_info,
					description,
					cityImages: [],
					sub_title,
					month_one,
					degree_one,
					month_two,
					degree_two,
					month_three,
					degree_three,
					month_four,
					degree_four,
					weather_time,
					currency,
					meta_title,
					meta_description,
					meta_keywords,
				}

				if (CityImage.length == 0) {
					cityDetails.cityImages = [];
					cityDetails.cityImages.push({
						city_image: null,
						city_image_url: '',
						deleted: false
					})

				} else {

					cityDetails.cityImages = [];

					CityImage.map((item) => {
						cityDetails.cityImages.push({
							city_image: item.city_image,
							city_image_url: item.city_image_url,
							city_image_id: item.id,
							deleted: false
						})

					})

				}

				this.setState({
					cityDetails
				})

			});

		}

	}

	componentWillReceiveProps(props) {

		let { modalDetails, isEdit } = props

		let { url = '' } = modalDetails

		let tempFile = ''

		if (url) {
			tempFile = url
		}

		// console.log(modalDetails, "asdasdadasdasd")

		this.setState({
			cityDetails: modalDetails,
			isEdit: isEdit,
			tempFile
		})
	}


	handleInputs = ({ target: { value, name } }) => {

		let cityDetails = Object.assign({}, this.state.cityDetails)

		// if (name === "series_no") {

		// 	cityDetails[name] = value === "" ? value : Number(value);

		// } else {

		// cityDetails[name] = value

		// }

		cityDetails[name] = value

		this.setState({
			cityDetails
		})


	}

	// ImagePreview = (index, event) => {

	// 	let cityDetails = this.state.cityDetails

	// 	cityDetails.cityImages[index].city_image = event.target.files[0]

	// 	this.setState({
	// 		tempFile: URL.createObjectURL(event.target.files[0]),
	// 		cityDetails,
	// 	});

	// }

	handleSubmit = () => {

		let cityDetails = Object.assign({}, this.state.cityDetails);

		let errorStatus = cityDetails.cityImages.every((e, index) => {

			let err1 = this.validators.fieldValid(`/city_image/${index}/`)

			if (!err1) {

				this.validators.showMessageFor(`/city_image/${index}/`)

				return false

			}

			return true;

		})

		if (this.validator.allValid() && errorStatus) { // this.validators.allValid()

			let { cityDetails, isEdit } = this.state

			let { match: { params: { id } } } = this.props;

			// this.validators = new SimpleReactValidator({

			// 	element: message => {

			// 		let messages = message.split('/');
			// 		return (
			// 			<span className="error-message font-md">

			// 				{`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}

			// 			</span>
			// 		)
			// 	},

			// });

			if (!id) {

				this.props.createCity(cityDetails).then(() => {

					this.props.history.push('/dashboard/city')

				})

			} else {

				console.log("adasdaudywuqeqwuwqdgadgahsdahsd")

				this.props.editCity(id, cityDetails).then(() => {

					this.props.history.push('/dashboard/city')

				})

			}

			this.validator.hideMessages();

		} else {

			this.setState({
				isError: true
			})

			this.validator.showMessages();

			this.validators.showMessages();

		}
	}

	handleLocation = (place) => {

		let { geometry: { location } } = place;

		let cityDetails = Object.assign({}, this.state.cityDetails);

		cityDetails.latitude = location.lat().toString();

		cityDetails.longitude = location.lng().toString();

		this.setState({
			cityDetails
		})

	}

	handleAdd = () => {

		let cityDetails = Object.assign({}, this.state.cityDetails);

		let errorStatus = cityDetails.cityImages.every((e, index) => {

			let err1 = this.validators.fieldValid(`/city_image/${index}/`)

			if (!err1) {

				this.validators.showMessageFor(`/city_image/${index}/`)

				return false

			}

			return true;

		})




		if (errorStatus) {

			cityDetails.cityImages.push({
				city_image: null,
				city_image_url: '',
			})

			this.setState({
				cityDetails
			})

		} else {
			this.setState({
				cityDetails
			})
		}

	}

	handleDelete = (index) => {

		let cityDetails = Object.assign({}, this.state.cityDetails);

		// cityDetails.cityImages.splice(index, 1)
		if (cityDetails.cityImages.length) {
			cityDetails.cityImages.map((x, i) => {
				if (i === index) {
					x.deleted = true
				}
			})
		}

		this.setState({
			cityDetails
		})

	}

	ImagePreview = (index, { target: { files } }) => {

		let cityDetails = _.cloneDeep(this.state.cityDetails)

		let formData = new FormData()

		formData.append("cityImage", files[0])

		this.props.cityImageUpload(formData).then(({ image, imageUrl }) => {

			cityDetails.cityImages[index].city_image = image;

			cityDetails.cityImages[index].city_image_url = imageUrl;

			this.setState({
				cityDetails
			})

		})


	}


	render() {

		let { isError, toggle, isEdit } = this.props

		let { match: { params: { id } } } = this.props;

		this.validator.purgeFields();

		let {
			cityDetails: {
				city_name,
				series_no,
				location,
				latitude,
				longitude,
				description,
				traveller_info,
				sub_title,
				cityImages,
				month_one,
				degree_one,
				month_two,
				degree_two,
				month_three,
				degree_three,
				month_four,
				degree_four,
				weather_time,
				currency,
				meta_title,
				meta_description,
				meta_keywords,
			},
			tempFile,
		} = this.state

		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="feather feather-align-left"><line x1="17" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="17" y1="18" x2="3" y2="18"></line></svg>
							</span>
						</span>{id ? "Edit" : "Add"} City
					</h4>
				</div>
				<section className="sair-sec-wrapper">
					{/* <div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {isEdit ? "Edit City" : "Add City"}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4"> */}
					<div className={`row ${isError ? "was-validated" : ""}`}>
						<div className="col-md-6 form-group">
							<label>Series No </label>
							<input
								className={`form-control ${this.validator.message('series no', series_no, 'required|alpha_num') ? 'inValid' : ''}`} id="series_no"
								placeholder=""
								name="series_no"
								value={series_no}
								type="text"
								onChange={this.handleInputs}


							/>
							{this.validator.message('series no', series_no, 'required|alpha_num')}
						</div>
						<div className="col-md-6 form-group">
							<label>City name</label>
							<input
								className={`form-control ${this.validator.message('city_name', city_name, 'required') ? 'inValid' : ''}`} id="city_name"
								placeholder=""
								name="city_name"
								value={city_name}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('city name', city_name, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label>Sub Title</label>
							<input
								className={`form-control ${this.validator.message('sub_title', sub_title, 'required') ? 'inValid' : ''}`}
								id="sub_title"
								placeholder=""
								name="sub_title"
								value={sub_title}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('sub_title', sub_title, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label>City Location</label>
							<input
								className={`form-control ${this.validator.message('location', location, 'required') ? 'inValid' : ''}`} id="location"
								placeholder=""
								name="location"
								value={location}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('location', location, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label>Traveller Info</label>
							<TextEditor
								handleTextEditor={html => {
									this.handleInputs({ target: { name: 'traveller_info', value: html } })
								}}
								html={traveller_info}
								disabled={false}
							// maxChar={250}
							/>
							{this.validator.message('traveller_info', traveller_info, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Description</label>
							<textarea
								value={description}
								name="description"
								onChange={this.handleInputs}
								className="form-control"
							></textarea>
							{this.validator.message('Description', description, 'required')}
						</div>

						<div className="col-md-12 form-group">
							<div className="row">
								<div className="col-sm-6">
									<label>Latitude</label>
									<input
										className={`form-control ${this.validator.message('latitude', latitude, 'required') ? 'inValid' : ''}`}
										placeholder=""
										name="latitude"
										value={latitude}
										type="number"
										onChange={this.handleInputs}
									/>
									{this.validator.message('latitude', latitude, 'required')}
								</div>
								<div className="col-sm-6">
									<label>Longitude</label>
									<input
										className={`form-control ${this.validator.message('longitude', longitude, 'required') ? 'inValid' : ''}`}
										placeholder=""
										name="longitude"
										value={longitude}
										type="number"
										onChange={this.handleInputs}
									/>
									{this.validator.message('longitude', longitude, 'required')}
								</div>
							</div>

						</div>

						<div className="col-md-6 form-group">
							<label>Month One</label>
							<input
								className={`form-control ${this.validator.message('month_one', month_one, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="month_one"
								value={month_one}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('month_one', month_one, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Degree One</label>
							<input
								className={`form-control ${this.validator.message('degree_one', degree_one, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="degree_one"
								value={degree_one}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('degree_one', degree_one, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Month Two</label>
							<input
								className={`form-control ${this.validator.message('month_two', month_two, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="month_two"
								value={month_two}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('month_two', month_two, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Degree Two</label>
							<input
								className={`form-control ${this.validator.message('degree_two', degree_two, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="degree_two"
								value={degree_two}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('degree_two', degree_two, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Month Three</label>
							<input
								className={`form-control ${this.validator.message('month_three', month_three, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="month_three"
								value={month_three}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('month_three', month_three, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Degree Three</label>
							<input
								className={`form-control ${this.validator.message('degree_three', degree_three, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="degree_three"
								value={degree_three}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('degree_three', degree_three, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Month Four</label>
							<input
								className={`form-control ${this.validator.message('month_four', month_four, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="month_four"
								value={month_four}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('month_four', month_four, 'required')}
						</div>

						<div className="col-md-6 form-group">
							<label>Degree Four</label>
							<input
								className={`form-control ${this.validator.message('degree_four', degree_four, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="degree_four"
								value={degree_four}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('degree_four', degree_four, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label>Time Zone</label>
							{/* <DateTime
								handleChange={(keyName, data) => {
									this.handleInputs({ target: { name: keyName, value: data } })
								}}
								name="weather_time"
								value={weather_time}
								isValidDate={hidePastDate}
							/> */}
							<input
								className={`form-control ${this.validator.message('weather_time', weather_time, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="weather_time"
								value={weather_time}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('weather_time', weather_time, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label>Currency</label>
							<input
								className={`form-control ${this.validator.message('currency', currency, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="currency"
								value={currency}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('currency', currency, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label>Meta Title</label>
							<input
								className={`form-control ${this.validator.message('meta_title', meta_title, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="meta_title"
								value={meta_title}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('meta_title', meta_title, 'required')}
						</div>
						<div className="col-md-6 form-group">
							<label>Meta Keywords</label>
							<input
								className={`form-control ${this.validator.message('meta_keywords', meta_keywords, 'required') ? 'inValid' : ''}`}
								placeholder=""
								name="meta_keywords"
								value={meta_keywords}
								type="text"
								onChange={this.handleInputs}
							/>
							{this.validator.message('meta_keywords', meta_keywords, 'required')}
						</div>
						<div className="col-md-6 form-group">
						<label>Meta Description</label>
							<textarea
								className="form-control"
								name="meta_description"
								onChange={this.handleInputs}
								value={meta_description}
							/>
							{this.validator.message('meta_description', meta_description, 'required')}
						</div>


						{/* <div className="col-md-6 form-group">
							<label>Category Images </label>

							<FileUpload
								fileUpload={this.fileUpload}
								ImagePreview={this.ImagePreview}
								filename={city_image}
								tempFile={tempFile}
							/>

							{this.validator.message('image', city_image, 'required')}

						</div> */}

					</div>

					<hr />

					<div className="row">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-6">
									<label className="d-flex align-items-center">
										City Images
										<button onClick={this.handleAdd} className="ml-auto btn btn-primary btn-sm">
											Add Image
										</button>
									</label>
								</div>
							</div>
						</div>

						{cityImages.filter(x => !x.deleted).map((item, index) => {

							let {
								city_image,
								city_image_url,
							} = item;

							return (
								<div className="col-md-6 form-group" key={index}>
									<div className="row ">
										<div className={`${index === 0 ? "col-md-12" : "col-md-11"}`}>
											<FileUpload
												fileUpload={this.fileUpload}
												ImagePreview={(data) => this.ImagePreview(index, data)}
												filename={city_image}
											// tempFile={city_image}
											/>

											<img src={city_image_url} style={{ width: "100px", marginTop: "15px" }} />

											{item ? this.validators.message(`/city_image/${index}/`, city_image, 'required') : ""}
										</div>

										{/* {this.validator.message('City Image', city_image, 'required')} */}
										{index !== 0 ?
											<div className="col-md-1 text-right pt-2">
												<i
													className="icon-trash text-danger cursor-pointer"
													onClick={() => this.handleDelete(index)}
												></i>
											</div>
											: ""}
									</div>
								</div>
							)

						})}


					</div>

					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>{isEdit ? "Update" : "Submit"}</button>
					{/* </div> */}
				</section>
			</div>
		)
	}
}


const mapStateToProps = state => ({
	cityDetails: state.master.cityDetails,
})

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createCity,
		getCityListDetails,
		editCity,
		cityImageUpload,
		getCityDetails,
	}, dispatch)
};


export const AddCity = connect(mapStateToProps, mapDispatchToProps)(AddCityClass)
