import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { createConfirmedLead } from "../../redux/actions/wholesale";
import { ReactSelect } from "../../component/common";
import AddProductLeadItem from "./AddProductLeadItem";

const AddProductlead = ({
  isOpen,
  onClose,
  productNames,
  createConfirmedLead,
  onLeadConfirmed,
  userId,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);

  const onAddProducts = () => {
    setAddedProducts(
      productNames.filter((product) => selectedProducts.includes(product._id))
    );
    setSelectedProducts([]);
  };

  const onConfirmLead = async (values, item) => {
    try {
      await createConfirmedLead({
        ...values,
        selected_variant: item.variants[0],
        user_id: userId,
        active: true,
      });
      setAddedProducts(
        addedProducts.filter(
          (addedProduct) => addedProduct._id !== values.wholesale_id
        )
      );
      onLeadConfirmed();
    } catch (e) {
      console.log("e");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size={"18"}
      className="modal-dialog-centered order-detail-model"
    >
      <ModalHeader
        //className={headerClass}
        toggle={() => onClose()}
      >
        Products
      </ModalHeader>
      <ModalBody className="order-detail-model-body">
        <div className="row justify-content-between mt-4 mb-10">
          <div className="col-sm-6">
            <div className="d-flex align-items-end">
              <div style={{ width: "100%" }}>
                <label>
                  <b>Products</b>
                </label>
                <ReactSelect
                  isMulti
                  options={productNames}
                  handleChange={(_, data) => {
                    setSelectedProducts(data);
                  }}
                  keyName="selectedProducts"
                  value={selectedProducts}
                />
              </div>
              <div className="ml-4">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onAddProducts()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        {addedProducts.map((selectedProduct) => (
          <AddProductLeadItem
            item={selectedProduct}
            key={`SELECTED_${selectedProduct._id}`}
            onItemSubmit={(values) => onConfirmLead(values, selectedProduct)}
          />
        ))}
        <div className="row mt-30 mb-20">
          <div className="col-sm-12">
            <p className="text-center">
              No products added. Please add products.
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  productNames: state.wholesale.wholesaleProductNames,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createConfirmedLead,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProductlead);
