export const common = {
	sideBarDetails: "SIDEBAR_DETAILS",
	updateUserDetails: 'UPDATE_USER_DETAILS',
	updateLoader: 'UPDATE_LOADER',
}

export const AuthType = {
	updateUserDetails: "UPDATE_USER_DETAILS",
	updateCustomerDetails: "UPDATE_CUSTOMER_DETAILS",
}


export const dashboardType = {
	dashboardList: "DASHBOARD_LIST",



}

export const purchaseOrderType = {
	createPurchaseOrder: "CREATE_PURCHASE_ORDER",
	listPurchaseOrders: "GET_PURCHASE_ORDER",
	cancelPurchaseOrder: "CANCEL_PURCHASE_ORDER",
	detailPurchaseOrder: "DETAIL_PURCHASE_ORDER"
}

export const masterType = {
	updateCityListDetails: "UPDATE_CITY_DETAILS",
	createCity: "CREATE_CITY",
	editCity: "EDIT_CITY",
	deleteCity: "DELETE_CITY",
	updateCityDetails: "UPDATE_CITY_DETAILS",

	updateCategoryListDetails: "UPDATE_CATEGORY_LIST_DETAILS",
	createCategory: "CREATE_CATEGORY",
	editCategory: "EDIT_CATEGORY",
	deleteCategory: "DELETE_CATEGORY",


	updateLanguageListDetails: "UPDATE_LANGUAGE_DETAILS",
	createLanguage: "CREATE_LANGUAGE",
	editLanguage: "EDIT_LANGUAGE",
	deleteLanguage: "DELETE_LANGUAGE",


	createDriver: "CREATE_DRIVER",

	createExperienceGroup: "CREATE_EXPERIENCE_GROUP",
	updateExperienceGroupListDetails: "UPDATE_EXPERIENCE_GROUP",
	editExperienceGroup: "EDIT_EXPERIENCE_GROUP",
	deleteExperienceGroup: "DELETE_EXPERIENCE_GROUP",

	createTravelExperienceGroup: "CREATE_TRAVEL_EXPERIENCE_GROUP",
	updateTravelExperienceGroupListDetails: "UPDATE_TRAVEL_EXPERIENCE_GROUP",
	editTravelExperienceGroup: "EDIT_TRAVEL_EXPERIENCE_GROUP",
	deleteTravelExperienceGroup: "DELETE_TRAVEL_EXPERIENCE_GROUP",


	updatePhysicalRestrictionListDetails: "UPDATE_PHYSICAL_RESTRICTION_DETAILS",
	createPhysicalRestriction: "CREATE_PHYSICAL_RESTRICTION",
	editPhysicalRestriction: "EDIT_PHYSICAL_RESTRICTION",
	deletePhysicalRestriction: "DELETE_PHYSICAL_RESTRICTION",

	createServiceProvider: "CREATE_SERVICE_PROVIDER",
	updateServiceProviderListDetails: "UPDATE_SERVICE_PROVIDER",
	editServiceProvider: "EDIT_SERVICE_PROVIDER",
	deleteServiceProvider: "DELETE_SERVICE_PROVIDER",

	createPickupDrop: "CREATE_PICKUP_DROP",
	updatePickupDropListDetails: "UPDATE_PICKUP_DROP",
	editPickupDrop: "EDIT_PICKUP_DROP",
	deletePickupDrop: "DELETE_PICKUP_DROP",

	createVehicleType: "CREATE_VEHICLE_TYPE",
	updateVehicleTypeListDetails: "UPDATE_VEHICLE_TYPE",
	editVehicleType: "EDIT_VEHICLE_TYPE",
	deleteVehicleType: "DELETE_VEHICLE_TYPE",

	createStayType: "CREATE_STAY_TYPE",
	updateStayTypeListDetails: "UPDATE_STAY_TYPE",
	editStayType: "EDIT_STAY_TYPE",
	deleteStayType: "DELETE_STAY_TYPE",

	createTicketNationalityType: "CREATE_TICKET_NATIONALITY_TYPE",
	updateTicketNationalityTypeListDetails: "UPDATE_TICKET_NATIONALITY_TYPE",
	editTicketNationalityType: "EDIT_TICKET_NATIONALITY_TYPE",
	deleteTicketNationalityType: "DELETE_TICKET_NATIONALITY_TYPE",
}


export const vendorType = {
	updateVendorListDetails: "UPDATE_VENDOR_LIST_DETAILS",
	createVendor: "CREATE_VENDOR",
	addDocument: "ADD_DOCUMENT",
	updateVendorMappingDetails: "UPDATE_VENDOR_MAPPING_DETAILS",
	updateVendorMappingDropdown: "UPDATE_VENDOR_MAPPING_DROPDOWN",
	updateSingleVendorDetails: 'UPDATE_SINGLE_VENDOR_DETAILS',
}

export const ordersType = {
	fetchOrders: 'FETCH_ORDERS',
	updateOrdersDetails: 'UPDATE_ORDERS_DETAILS',
	confirmOrder: 'CONFIRM_ORDER',
	updateSingleOrderDetail: 'UPDATE_SINGLE_ORDER_DETAIL',
	getVendorListDropdown: 'GET_VENDOR_LIST_DROPDOWN',
}

export const activityType = {
	updateActivityDropDown: "UPDATE_ACTIVITY_DROPDOWN",
	updateCategoryMappingListDetails: "UPDATE_CATEGORY_MAPPING",
	addImage: "ADD_IMAGE",
	addItenaryImage: "ADD_ITENARY_IMAGE",
	updateActivityListDetails: "UPDATE_ACTIVITY_LIST_DETAILS",
	getActivityDetailsById: "GET_ACTIVITY_DETAILS_BY_ID",
	createActivity: "CREATE_ACTIVITY",
	updateCategoryMappingDropDown: "UPDATE_CATEGORY_MAPPING_DROPDOWN",
	createCategoryMapping: "CREATE_CATEGORY_MAPPING"
}



export const advertisementTypeOneType = {
	updateAdvertisementTypeOneListDetails: "UPDATE_ADVERTISEMENT_TYPE_ONE_LIST_DETAILS",
	getAdvertisementTypeOneDetailsById: "GET_ADVERTISEMENT_TYPE_ONE_DETAILS_BY_ID",
	createAdvertisementTypeOne: "CREATE_ADVERTISEMENT_TYPE_ONE",
	updateCityListDetails: "UPDATE_CITY_DETAILS",
	addImage: "ADD_IMAGE",
	updateCategoryMappingListDetails: "UPDATE_CATEGORY_MAPPING_LIST_DETAILS",
	updateActivityListDetails: "UPDATE_ACTIVITY_LIST_DETAILS",

}

export const itineraryType = {
	updateItinerary: "UPDATE_ITINERARY",
	updateMasterData: "UPDATE_MASTER_DATA",
	saveItinerary: "SAVE_ITINERARY",
	updateItineraryListDetails: "UPDATE_ITINERARY_LIST_DETAILS",
	getItineraryDetailsById: "GET_ITINERARY_DETAILS_BY_ID",
	getCityWiseInfo: "GET_CITY_WISE_INFO",
	getCityNames: "GET_CITY_NAMES",
	getBase64Images: "GET_BASE_64_IMAGES"
}

export const resellerType = {
	addImage: "ADD_IMAGE",
	getCategories: "GET_CATEGORIES",
	getCategoryNames: "GET_CATEGORY_NAMES",
	getCategoryDetailsById: "GET_CATEGORY_DETAILS_BY_ID",
	editCategory: "EDIT_CATEGORY",
	createCategory: "CREATE_CATEGORY",
	deleteCategory: "DELETE_CATEGORY",
	getLevelTwoCategories: "GET_LEVEL_TWO_CATEGORIES",
	getLevelTwoCategoryNames: "GET_LEVEL_TWO_CATEGORY_NAMES",
	getLevelTwoCategoryDetailsById: "GET_LEVEL_TWO_CATEGORY_DETAILS_BY_ID",
	editLevelTwoCategory: "EDIT_LEVEL_TWO_CATEGORY",
	createLevelTwoCategory: "CREATE_LEVEL_TWO_CATEGORY",
	deleteLevelTwoCategory: "DELETE_LEVEL_TWO_CATEGORY",

	getLevelThreeCategories: "GET_LEVEL_THREE_CATEGORIES",
	getLevelThreeCategoryNames: "GET_LEVEL_THREE_CATEGORY_NAMES",
	getLevelThreeCategoryDetailsById: "GET_LEVEL_THREE_CATEGORY_DETAILS_BY_ID",
	editLevelThreeCategory: "EDIT_LEVEL_THREE_CATEGORY",
	createLevelThreeCategory: "CREATE_LEVEL_THREE_CATEGORY",
	deleteLevelThreeCategory: "DELETE_LEVEL_THREE_CATEGORY",

	getBrands: "GET_BRANDS",
	getBrandNames: "GET_BRAND_NAMES",
	getBrandDetailsById: "GET_BRAND_DETAILS_BY_ID",
	editBrand: "EDIT_BRAND",
	createBrand: "CREATE_BRAND",
	deleteBrand: "DELETE_BRAND",
	getProducts: "GET_PRODUCTS",
	getProductsNames: "GET_PRODUCTS_NAMES",
	getProductDetailsById: "GET_PRODUCT_DETAILS_BY_ID",
	editProduct: "EDIT_PRODUCT",
	createProduct: "CREATE_PRODUCT",
	deleteProduct: "DELETE_PRODUCT",
	getSellerNames: "GET_SELLER_NAMES",
	sellerList: "SELLER_LIST",
	editSeller: "EDIT_SELLER",
	commissionList: 'COMMISSION_LIST',
	getbannerList: 'BANNER_LIST',
	updateBanner: 'BANNER_UPDATE',
	getcustomerBannerList: 'CUSTOMER_BANNER_LIST',
	updatecustomerbannerList: 'CUSTOMER_BANNER_UPDATE',
	getWareHouseList: "GET_WAREHOUSE_LIST",
	getWareHouseNames: "GET_WAREHOUSE_NAMES",
	getGtmWareHouseNames: "GET_GTM_WAREHOUSE_NAMES",
	getWareHouseDetailsById: "GET_WAREHOUSE_DETAILS_BY_ID",
	createWareHouse: "CREATE_WAREHOUSE",
}


export const topActivityType = {
	updateTopActivityDetails: "UPDATE_TOP_ACTIVITY_DETAILS",
	updateTopActivityDropdownDetails: "UPDATE_TOP_ACTIVITY_DROPDOWN_DETAILS",
}

export const topActivityCityType = {
	updateTopActivityCityDetails: "UPDATE_TOP_ACTIVITY_CITY_DETAILS",
	updateTopActivityCityDropdown: "UPDATE_TOP_ACTIVITY_CITY_DROPDOWN",
}

export const topCategoryType = {
	updateTopCategoryDetails: "UPDATE_TOP_CATEGORY_DETAILS",
	updateTopCategoryDropdown: "UPDATE_TOP_CATEGORY_DROPDOWN",
	updateCityDropdown: "UPDATE_CITY_DROPDOWN",
}

export const blogType = {

	updateBlogDetails: "UPDATE_BLOG_DETAILS",

}

export const offersType = {
	updateOfferDetails: "UPDATE_OFFER_DETAILS",
	updateSingleOfferDetails: "UPDATE_SINGLE_OFFER_DETAILS",
}

export const cityFestivalType = {

	updateCityFestivalDetails: "UPDATE_CITY_FESTIVAL_DETAILS",

}

export const notificationType = {
	updateNotificationListDetails: "UPDATE_NOTIFICATION_LIST_DETAILS",
	createNotification: "CREATE_NOTIFICATION",
}

export const wholesaleType = {
	wholesaleProductList: "WHOLESALE_PRODUCT_LIST",
	wholesaleProductListFirstRequest: "WHOLESALE_PRODUCT_LIST_FIRST_REQUEST",
	wholesaleProductUpdate: "WHOLESALE_PRODUCT_UPDATE",
	wholesaleProductCreate: "WHOLESALE_PRODUCT_CREATE",
	wholesaleProductDelete: "WHOLESALE_PRODUCT_DELETE",
	wholesaleProductNames: "WHOLESALE_PRODUCT_NAMES",
	wholesaleAddProductNames: "WHOLESALE_ADD_PRODUCT_NAMES",

	wholesaleLeadList: "WHOLESALE_LEAD_LIST",
	wholesaleLeadListFirstRequest: "WHOLESALE_LEAD_LIST_FIRST_REQUEST",
	wholesaleLeadListByUser: "WHOLESALE_LEAD_LIST_BY_User",
	wholesaleLeadCreate: "WHOLESALE_LEAD_CREATE",
	wholesaleLeadConfirm: "WHOLESALE_LEAD_CONFIRM",
	wholesaleLeadCreateConfirm: "WHOLESALE_LEAD_CREATE_CONFIRM",
	wholesaleDeleteLead: "WHOLESALE_LEAD_DELETE",
}

export const discountType = {
	setDiscount: "SET_DISCOUNT",
}