import { master } from "../../services/apiVariables"
import { masterType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"

import { Toast } from "../../services/toast"

export const createCity = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createCity, payload: body })

		api({ ...master.createCity, body }).then(({ data, message }) => {


			dispatch({ type: masterType.createCity, payload: data })


			resolve(Toast({ type: 'success', message: data }))

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))

		})

	})
}

export const getCityListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getCityListDetails)

		api(master.getCityListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateCityListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const changeCityStatus = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		console.log(body)

		dispatch({ type: common.updateLoader, payload: true })

		api({ ...master.changeCityStatus, body }).then(({ data, message }) => {

			resolve(Toast({ type: 'success', message: data }))

			// dispatch({ type: masterType.cityStatusChange, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const editCity = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editCity.addParams = id;

		api({ ...master.editCity, body }).then(({ data, message }) => {

			dispatch({ type: master.editCity, payload: data })

			resolve(Toast({ type: 'success', message: data }))

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})
}

export const deleteCity = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteCity.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteCity).then(({ data, message }) => {

			dispatch({ type: masterType.deleteCity, payload: data });

			dispatch({ type: common.updateLoader, payload: false })

			resolve(Toast({ type: 'success', message: data }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}

export const getCityDetails = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.getCityDetails.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })

		api(master.getCityDetails).then(({ data, message }) => {

			dispatch({ type: masterType.updateCityDetails, payload: data });

			dispatch({ type: common.updateLoader, payload: false })

			resolve(data);

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}

export const getCategoryListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getCategoryListDetails)

		api(master.getCategoryListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateCategoryListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createCategory = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createCategory, payload: body })

		api({ ...master.createCategory, body }).then(({ data, message }) => {

			resolve(Toast({ type: 'success', message: data }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message })

			reject(false)

		})

	})
}

export const editCategory = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editCategory.addParams = id;

		api({ ...master.editCategory, body }).then(({ data, message }) => {

			dispatch({
				type: master.editCategory,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})
}

export const deleteCategory = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteCategory.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteCategory).then(({ data, message }) => {

			dispatch({ type: masterType.deleteCategory, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}



export const getLanguageListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getLanguageListDetails)

		api(master.getLanguageListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateLanguageListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createLanguage = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createLanguage, payload: body })

		api({ ...master.createLanguage, body }).then(({ data, message }) => {

			resolve(Toast({ type: 'success', message: data }))

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))

		})

	})
}

export const editLanguage = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editLanguage.addParams = id;

		api({ ...master.editLanguage, body }).then(({ data, message }) => {

			dispatch({
				type: master.editLanguage,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})
}

export const deleteLanguage = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteLanguage.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteLanguage).then(({ data, message }) => {

			dispatch({ type: masterType.deleteLanguage, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}

export const createDriver = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createDriver, payload: body })

		api({ ...master.createDriver, body }).then(({ data, message }) => {

			resolve(data)

		}).catch(({ message }) => {
			console.log(message)

			reject(false)

		})

	})
}


export const getExperienceGroupListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getExperienceGroupListDetails)

		api(master.getExperienceGroupListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateExperienceGroupListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createExperienceGroup = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createExperienceGroup, payload: body })

		api({ ...master.createExperienceGroup, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message })

		}).catch(({ message }) => {

			Toast({ type: 'error', message })

			reject(false)

		})

	})
}

export const editExperienceGroup = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editExperienceGroup.addParams = id;

		api({ ...master.editExperienceGroup, body }).then(({ data, message }) => {

			dispatch({
				type: master.editExperienceGroup,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})
}

export const deleteExperienceGroup = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteExperienceGroup.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteExperienceGroup).then(({ data, message }) => {

			dispatch({ type: masterType.deleteExperienceGroup, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}

/** travel experience group */

export const getTravelExperienceGroupListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getTravelExperienceGroupListDetails)

		api(master.getTravelExperienceGroupListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateTravelExperienceGroupListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createTravelExperienceGroup = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createTravelExperienceGroup, payload: body })

		api({ ...master.createTravelExperienceGroup, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message })

		}).catch(({ message }) => {

			Toast({ type: 'error', message })

			reject(false)

		})

	})
}

export const editTravelExperienceGroup = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editTravelExperienceGroup.addParams = id;

		api({ ...master.editTravelExperienceGroup, body }).then(({ data, message }) => {

			dispatch({
				type: master.editTravelExperienceGroup,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})
}

export const deleteTravelExperienceGroup = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteTravelExperienceGroup.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteTravelExperienceGroup).then(({ data, message }) => {

			dispatch({ type: masterType.deleteTravelExperienceGroup, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}


/** PhysicalRestriction */

export const getPhysicalRestrictionListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getPhysicalRestrictionListDetails)

		api(master.getPhysicalRestrictionListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updatePhysicalRestrictionListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createPhysicalRestriction = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createPhysicalRestriction, payload: body })

		api({ ...master.createPhysicalRestriction, body }).then(({ data, message }) => {

			resolve(Toast({ type: 'success', message: data }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message })

			reject(false)

		})

	})
}

export const editPhysicalRestriction = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editPhysicalRestriction.addParams = id;

		api({ ...master.editPhysicalRestriction, body }).then(({ data, message }) => {

			dispatch({
				type: master.editPhysicalRestriction,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})
}

export const deletePhysicalRestriction = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deletePhysicalRestriction.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deletePhysicalRestriction).then(({ data, message }) => {

			dispatch({ type: masterType.deletePhysicalRestriction, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}

/**Service Provider */

export const getServiceProviderListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getServiceProviderListDetails)

		api(master.getServiceProviderListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateServiceProviderListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createServiceProvider = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createServiceProvider, payload: body })

		api({ ...master.createServiceProvider, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message })

		}).catch(({ message }) => {

			Toast({ type: 'error', message })

			reject(false)

		})

	})
}

export const editServiceProvider = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editServiceProvider.addParams = id;

		api({ ...master.editServiceProvider, body }).then(({ data, message }) => {

			dispatch({
				type: master.editServiceProvider,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch(({ message }) => {

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})
}

export const deleteServiceProvider = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteServiceProvider.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteServiceProvider).then(({ data, message }) => {

			dispatch({ type: masterType.deleteServiceProvider, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}
//*******************Pickup and Drop Locations ****************************************

export const getPickupDropListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getPickupDropListDetails)

		api(master.getPickupDropListDetails).then(({ data, message }) => {
			resolve(true)

			dispatch({ type: masterType.updatePickupDropListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createPickupDrop = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createPickupDrop, payload: body })

		api({ ...master.createPickupDrop, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message })

		}).catch((err) => {
			
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })

			reject(false)

		})

	})
}

export const editPickupDrop = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editPickupDrop.addParams = id;

		api({ ...master.editPickupDrop, body }).then(({ data, message }) => {

			dispatch({
				type: master.editPickupDrop,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch((err) => {
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })

			reject(message)
		});

	})
}

export const deletePickupDrop = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deletePickupDrop.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deletePickupDrop).then(({ data, message }) => {

			dispatch({ type: masterType.deletePickupDrop, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})

}
//******************Vehicle Type ****************************************

export const getVehicleTypeDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getVehicleTypeListDetails)

		api(master.getVehicleTypeListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateVehicleTypeListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createVehicleType = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createVehicleType, payload: body })

		api({ ...master.createVehicleType, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message })

		}).catch((err) => {
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })

			reject(false)

		})

	})
}

export const editVehicleType = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editVehicleType.addParams = id;

		api({ ...master.editVehicleType, body }).then(({ data, message }) => {

			dispatch({
				type: master.editVehicleType,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch((err) => {
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })
			reject(message)
		});

	})
}

export const deleteVehicleType = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteVehicleType.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteVehicleType).then(({ data, message }) => {

			dispatch({ type: masterType.deleteVehicleType, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}
//******************Stay Type ****************************************

export const getStayTypeDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getStayTypeListDetails)

		api(master.getStayTypeListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateStayTypeListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createStayType = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createStayType, payload: body })

		api({ ...master.createStayType, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message })

		}).catch((err) => {
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })

			reject(false)

		})

	})
}
export const editStayType = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editStayType.addParams = id;

		api({ ...master.editStayType, body }).then(({ data, message }) => {

			dispatch({
				type: master.editStayType,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch((err) => {
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })
			reject(message)
		});

	})
}

export const deleteStayType = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteStayType.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteStayType).then(({ data, message }) => {

			dispatch({ type: masterType.deleteStayType, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}
//******************Ticket nationality Type ****************************************

export const getTicketNationalityTypeListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, master.getTicketNationalityTypeListDetails)

		api(master.getTicketNationalityTypeListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: masterType.updateTicketNationalityTypeListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createTicketNationalityType = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: masterType.createTicketNationalityType, payload: body })

		api({ ...master.createTicketNationalityType, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message })

		}).catch((err) => {
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })

			reject(false)

		})

	})
}
export const editTicketNationalityType = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.editTicketNationalityType.addParams = id;

		api({ ...master.editTicketNationalityType, body }).then(({ data, message }) => {

			dispatch({
				type: master.editTicketNationalityType,
				payload: data
			})

			resolve(Toast({ type: 'success', message: message }))

		}).catch((err) => {
			let message=err && err.err && err.err.message ? err.err.message : err.message
			Toast({ type: 'error', message })
			reject(message)
		});

	})
}

export const deleteTicketNationalityType = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		master.deleteTicketNationalityType.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })


		api(master.deleteTicketNationalityType).then(({ data, message }) => {

			dispatch({ type: masterType.deleteTicketNationalityType, payload: data });

			dispatch({ type: common.updateLoader, payload: false })


			resolve(Toast({ type: 'success', message: message }));

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}
export const cityImageUpload = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		api({ ...master.cityImageUpload, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: message })

			resolve(data);

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message);

		});

	})
}