



import { advertisementTypeOneType } from "../../services/actionType"

const initialState = {
    advertisementTypeOneLists: [],
    cityLists: {},
    mappingActivityLists: {},
    activityLists: [], 
    
}




export default (state = Object.assign({}, initialState), { type, payload }) => {

	switch (type) {

		
		case advertisementTypeOneType.updateAdvertisementTypeOneListDetails:
            return {
				...state,
				advertisementTypeOneLists: payload
            }
        case advertisementTypeOneType.getAdvertisementTypeOneDetailsById:
            return {
                    ...state,
                        avertisementTypeOneDetails: payload
                }
        case advertisementTypeOneType.updateCityListDetails:
            return {
                        ...state,
                        cityLists: payload
                    }
           
        case advertisementTypeOneType.updateCategoryMappingListDetails:
            return {
                ...state,
                mappingActivityLists: payload
            }
        case advertisementTypeOneType.updateActivityListDetails:
            return {
                ...state,
                activityLists: payload
            }
		default:
			return state

    }
    
}
