import React, { Component } from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { confirmOrder, getOrderDetail, getVendorListDropdown } from '../../redux/actions/orders';

import { LoaderWrapper } from '../../HOC';
import { ReactSelect } from '../../component/common';

class GtmConfirmOrderClass extends Component {

    state = {
        orderDetails: [],
        vendorList: [],
    }

    componentDidMount() {

        let { getOrderDetail, match: { params: { id } }, orderDetail = {}, vendorDropdownList = [] } = this.props;

        // let { Order = [] } = orderDetail;

        let orderDetails = this.state.orderDetails.slice(0)

        let vendorList = this.state.vendorList.slice(0)

        getOrderDetail(id).then(({ OrderDetails: Order = [] }) => {

            Order.map((item) => {

                orderDetails.push({
                    orderId: item.id,
                    vendorId: "",
                })

            })

        });

        vendorDropdownList.map((item) => {

            vendorList.push({
                label: item.company_name,
                value: item.id,
            })

        })

        this.setState({
            orderDetails,
            vendorList,
        })

    }

    handleSubmit = () => {

        let { orderDetails } = this.state

        let { match: { params: { id } } } = this.props;

        let body = {
            customerOrderId: id,
            orderDetails
        }

        this.props.confirmOrder(body)

    }

    handleInputs = ({ value, name }, index) => {

        let orderDetails = this.state.orderDetails.slice(0)

        orderDetails[index][name] = value

        this.setState({
            orderDetails
        })


    }

    render() {

        let { orderDetail = {} } = this.props;

        let { OrderDetails: Order = [] } = orderDetail;

        let {
            orderDetails = [],
            vendorList,
        } = this.state;

        return (
            <>
                <div className="sair-pg-header">
                    <h4 className="sair-pg-title align-self-center">
                        <span className="pg-title-icon">
                            <span className="feather-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>Confirm Order
                    </h4>
                </div>
                <section className="">{/* sair-sec-wrapper */}
                    <div className="row card-block">
                        {Order.map((item, index) => {

                            let {
                                title,
                                total_price,
                                traveller_des,
                            } = item;

                            return (
                                <div className="col-md-6" key={index}>
                                    <div className="card">
                                        <div className="card-header">{title}</div>
                                        <div className="card-body">
                                            {/* <div className="row mb-2">
                                                <div className="col-md-5">
                                                    <b>Title</b>
                                                </div>
                                                <div className="col-md-7">
                                                    {title}
                                                </div>
                                            </div> */}
                                            <div className="row mb-2">
                                                <div className="col-md-5">
                                                    <b>Total Price</b>
                                                </div>
                                                <div className="col-md-7">
                                                    {total_price}
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-5">
                                                    <b>Traveller Description</b>
                                                </div>
                                                <div className="col-md-7">
                                                    {traveller_des}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-md-5">
                                                    <b>Vendor</b>
                                                </div>
                                                <div className="col-md-7">
                                                    {console.log(orderDetails[index], "asdasdasdasd")}
                                                    <ReactSelect
                                                        isMulti={false}
                                                        options={vendorList}
                                                        handleChange={(keyName, data) => {
                                                            this.handleInputs({ name: keyName, value: data }, index)
                                                        }}
                                                        keyName="vendorId"
                                                        value={orderDetails[index] ? orderDetails[index].vendorId : ""}
                                                        disabled={false}
                                                    />
                                                    {/* {this.validator.message('Vendors', orderDetails[index].vendorId, 'required')} */}
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={this.handleSubmit}
                                                >
                                                    Confirm Order
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </section>
            </>
        )
    }

}

const mapStateToProps = state => ({
    orderDetail: state.orders.orderDetail,
    vendorDropdownList: state.orders.vendorDropdownList,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getOrderDetail,
        confirmOrder,
        getVendorListDropdown
    }, dispatch)
};

let actions = ['getVendorListDropdown']

let component = LoaderWrapper(GtmConfirmOrderClass)({ actions })

export const ConfirmOrderGtm = connect(mapStateToProps, mapDispatchToProps)(component)