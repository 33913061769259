import React, { Component } from 'react'
import { FileUpload, ReactAutoComplete, NormalInput, ReactSelect} from "../../component/common"
import { TextEditorActivity} from "../../component/common/TextEditorActivity"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { SAILMALL_BASE_URL } from "../../config/config"

import SimpleReactValidator from 'simple-react-validator';
import { addImage, getBrandDetailsById, editBrandDetailsById, createBrand, getCategoryNames,getSellerNames} from "../../redux/actions/reseller"

import { TextEditor, DateTime } from '../../component/common';

import { hidePastDate } from "../../services/helperFunctions"

import _ from "lodash"
import { log } from 'util';

class BrandCreateClass extends Component {
	state = {
		brandDetails: {
      name: '',
      imgLinks: [],
      shareLink: '',
      descripton: '',
      seller_id:"",
      sellerBusinessName:"",
      sellerBusinessAddr:{},
      sellerBusinessContact:"",
      sellerBusinessEmail:"",
      tag_line:"",
    },
  descState: {
    description: ""
  },
  categoryNames: [],
  sellerNames:[]
}

	componentWillMount() {
    this.descState = {}

    this.validator = new SimpleReactValidator({
      element: message => <span className="error-message font-md">{message}</span>,
      autoForceUpdate: this,
      messages: {
        int: 'this :attribute required here'
      }
    });

    this.handleInputs = ({ value, name }) => {
      let brandDetails = this.state.brandDetails
      let url;
      if (name === 'name') {
        brandDetails.name = value
      }
      else if (name == "description") {
        brandDetails["description"] = value;
        brandDetails["description"] = brandDetails["description"].length<this.descState.description.length ? this.descState.description : brandDetails["description"];
      }
      else {
        brandDetails[name] = value
      }
      
      this.setState({ brandDetails: brandDetails })
    }

    this.handleDescriptionInputs = ({value, name}) => {
      this.descState["description"] = value;
    }
	}
 
  componentDidMount() {
    this.props.getSellerNames().then((data) => {
      let sellerNames = data;
      this.setState({ sellerNames: sellerNames })
    })
    let { match: { params: { id } }, history } = this.props;
      if (id) {
        this.props.getBrandDetailsById(id).then((data) => {
          let brandDetails = data;
          if(!(brandDetails && brandDetails.hasOwnProperty('imgLinks'))){
            brandDetails['imgLinks']=[];
          }
          this.setState({ brandDetails,sellerDetailDisable:true })
        })
        
      } else {
        let brandDetails = this.state.brandDetails;
        this.setState({brandDetails:brandDetails})
      }
  }
  handleSelect = ({name, value }) => {
    let brandDetails = this.state.brandDetails;
    brandDetails[name]=value;
    this.setState({
      brandDetails: brandDetails,
    })
  }
  handleAddrInputs=  ({name, value }) => {
    let brandDetails = Object.assign({}, this.state.brandDetails)
    if(!brandDetails.sellerBusinessAddr){
      brandDetails.sellerBusinessAddr = {}
    }
    brandDetails.sellerBusinessAddr[name]=value;
    this.setState({
      brandDetails: brandDetails,
    })
  }
  handleSubmit() {
    let { brandDetails } = this.state
    let id = brandDetails._id
    if (id) {
      // EDIT FLOW
      if (this.validator.allValid()) {
        let body = {
          ...brandDetails
        }
        this.props.editBrandDetailsById(id, body).then((data) => {
          let { history } = this.props
          this.setState({ disabled: false })
          history.push('/reseller/brand')
        }).catch((ex) => {
          this.setState({ disabled: false })
        })
      }
      else {
        this.validator.showMessages();
      }
    } else {
      // CREATE FLOW
      if (this.validator.allValid()) {
        let body = {
          ...brandDetails
        }
        this.props.createBrand(body).then((data) => {
          let { history } = this.props
          this.setState({ disabled: false })
          history.push('/reseller/brand')
        }).catch((ex) => {
          this.setState({ disabled: false })
        })
      } else {
        this.validator.showMessages();
      }
    }
  }

  ImagePreview = (key, event) => {
    let files = event.target.files[0]
    let brandDetails = Object.assign({}, this.state.brandDetails)
    let formData = new FormData()
    formData.append("resellerImage", files)
    this.props.addImage(formData).then((data) => {
      brandDetails.imgLinks[0] = data.imageUrl
    })
    this.setState({brandDetails})
  }
  
  render() {
    let {sellerNames,sellerDetailDisable}=this.state
    let {name, description,sellerBusinessContact,sellerBusinessEmail,sellerBusinessName,sellerBusinessAddr={}, imgLinks, shareLink,seller_id,warehouses,tag_line} = this.state.brandDetails;
    let {address_line_1,address_line_2,city,state,pincode}= sellerBusinessAddr
    
    sellerNames = _.map(sellerNames,(seller) => {
      return {value: seller.id, label: seller.first_name}
    })
    let tempFile
    return (
      <div>
          {/* Header */}
          <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Shop
					</h4>
        </div>

        <div className="row">
            <div className="col-md-6 form-group">
              <label><b>Name</b></label>
              <NormalInput
                className="form-control"
                name="name"
                type="text"
                value={name}

                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Shop Title', name, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label><b>Seller Bussiness Name</b></label>
              {sellerBusinessName}
              <NormalInput
                className="form-control"
                name="sellerBusinessName"
                type="text"
                value={sellerBusinessName}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Shop bussiness name', sellerBusinessName, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label><b>Seller Bussiness contact</b></label>
              <NormalInput
                className="form-control"
                name="sellerBusinessContact"
                type="text"
                value={sellerBusinessContact}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Shop bussiness contact starts with 6-9 and have 10 character length', sellerBusinessContact, "required|regex:^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$")}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label><b>Seller Bussiness email</b></label>
              <NormalInput
                className="form-control"
                name="sellerBusinessEmail"
                type="text"
                value={sellerBusinessEmail}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Shop bussiness email', sellerBusinessEmail, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label><b>Image</b></label>
              <FileUpload
                fileUpload={this.fileUpload}
                ImagePreview={(e) => this.ImagePreview("brandImage", e)}
                filename={imgLinks && imgLinks[0]? imgLinks[0] : ""}
                tempFile={tempFile}
              />
              <img src={imgLinks && imgLinks[0]} style={{ width: "200px", marginTop: "15px" }} />
              {this.validator.message('image', imgLinks, 'required')}
            </div>
            <div className="col-md-6 form-group">
            <label><b>Choose Seller</b></label>
                <ReactSelect
                      isMulti={false}
                      options={sellerNames}
                      handleChange={(keyName, data) => {
                        this.handleSelect({ name: keyName, value: data })
                      }}
                      keyName="seller_id"
                      value={seller_id}
                      disabled={false}
                      />
            </div>
            <div className="col-md-6 form-group">
              <label><b>Description</b></label>

              <TextEditorActivity
                handleTextEditor={html => {
                  this.handleDescriptionInputs({ name: 'description', value: html })
                }}
                handleTextEditorInput={html => {
                  this.handleInputs({ name: 'description', value: html })
                }}
                html={this.state.brandDetails.description}
              />
            </div>
            <div className="col-md-6 form-group">
              <label><b>Business Tag Line</b></label>
              <NormalInput
                className="form-control"
                name="tag_line"
                type="text"
                value={tag_line}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Business Tag Line', tag_line, 'required')}
                number={false}
              />
            </div>
        </div>
        
        <h6 className="mb-15">
          <div>Seller Bussiness address</div>
        </h6>
        <div className="row">

        
            <div className="col-md-6 form-group">
                  <label >address line 1 </label>
                  <NormalInput
                    className="form-control"
                    name="address_line_1"
                    type="text"
                    value={address_line_1}
                    onChange={({ name, value }) => {
                      this.handleAddrInputs({ name: name, value: value })
                    }}
                    number={false}
                    validationError= {this.validator.message(`Seller Bussiness address `, address_line_1, 'min:12')}
                  />
                 
                </div>
                <div className="col-md-6 form-group">
                  <label >address line 2</label>
                  <NormalInput
                    className="form-control"
                    name="address_line_2"
                    type="text"
                    value={address_line_2}
                    onChange={({ name, value }) => {
                      this.handleAddrInputs({ name: name, value: value })
                    }}
                    number={false}
                    validationError= {this.validator.message(`/sairCostPrice/`, address_line_2, '')}
                  />
                  
                </div>
                <div className="col-md-6 form-group">
                  <label > city </label>
                  <NormalInput
                    className="form-control"
                    name="city"
                    type="text"
                    value={city}
                    onChange={({ name, value }) => {
                      this.handleAddrInputs({ name: name, value: value })
                    }}
                    number={false}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label > State</label>
                  <NormalInput
                    className="form-control"
                    name="state"
                    type="text"
                    value={state}
                    onChange={({ name, value }) => {
                      this.handleAddrInputs({ name: name, value: value })
                    }}
                    number={false}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label >pincode</label>
                  <NormalInput
                    className="form-control"
                    name="pincode"
                    type="text"
                    value={pincode}
                    onChange={({ name, value }) => {
                      this.handleAddrInputs({ name: name, value: value })
                    }}
                    number={false}
                    validationError= {this.validator.message(`/resellerCostPrice/`, pincode, '')}
                  />
                  
                </div>
               
            </div>
        <hr />
          <button className="btn btn-primary"
            onClick={() => this.handleSubmit()}
            disabled={this.state.disabled}
          >Submit</button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getBrandDetailsById,
    getCategoryNames,
    createBrand, 
    editBrandDetailsById,
    addImage,
    getSellerNames
  }, dispatch)
}

export const BrandCreate = connect(mapStateToProps, mapDispatchToProps)(BrandCreateClass)
