import { notification } from "../../services/apiVariables"
import { notificationType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getNotificationListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, notification.getNotificationListDetails)

		api(notification.getNotificationListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: notificationType.updateNotificationListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createNotificationRequest = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {

		dispatch({ type: notificationType.createNotification, payload: body })

		api({ ...notification.createNotification, body }).then(({ data, err, message }) => {

			if (err) {

				resolve(Toast({ type: 'error', message: err }))

			} else {

				resolve(Toast({ type: 'success', message: message }))

			}
		}).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))
            
        })
        
	})
}
