import { purchaseOrderType } from "../../services/actionType"

const initialState = {
    purchaseOrders: {},
    orderDetail: {},
}


export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {
        
        case purchaseOrderType.listPurchaseOrders:
            return {
                ...state,
                purchaseOrders: payload
            }
        case purchaseOrderType.updateSingleOrderDetail:
            return {
                ...state,
                orderDetail: payload
            }
        default:
            return state

    }
}