import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from 'lodash';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: "100%"
});

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: "",
      adult: 0,
      child: 0,
      openDeleteModal: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillReceiveProps({details, ItineraryData}){
    this.setState({items: details});
    if(ItineraryData){
      this.setState({adult: ItineraryData.adultCount, child: ItineraryData.childrenCount})
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    }, ()=>{
      let act_list = [];
      
      items.forEach(item => {
        act_list.push(item.id);
      })

      this.props.changeActivityList(act_list, this.props.dayIndex);
    });
  }

	toggleDelete = (name) => {
		this.setState({
			[name]: !this.state[name]
		})
    }

	toggle = item => {
		this.setState(prevState => ({
			[item]: !prevState[item]
		}))
	}

    deleteActivity = (act_id, dayIndex) =>{
        let items = Object.assign({}, this.state.items);
        let filteredArr = _.filter(items, (item)=>{
        return item.id != act_id
        })
        this.setState({items: filteredArr})
        let act_list = this.props.act_list
        let checkId = act_list.indexOf(act_id);
        if(checkId > -1){
        act_list.splice(checkId, 1);
        }
        this.props.changeActivityList(act_list, dayIndex);
        this.setState({openDeleteModal: false});
    }

    openDeleteActivity = () => {
        this.setState({openDeleteModal: true});
    }

    toggleDelete = (name) => {
            this.setState({
                [name]: !this.state[name]
            })
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        let { details } = this.props;
        let {adult, child, openDeleteModal} = this.state;
        return (
            <>
            {this.state.items!="" && this.state.items!= undefined ?
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.items.map((activity_detail, index) => (
                                    <>
                                        <Draggable key={"activity_detail-" + activity_detail.id} draggableId={"activity_detail-" + activity_detail.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                <label ><strong> {activity_detail.id} {activity_detail.title} {activity_detail.duration? ("(Duration: "+activity_detail.duration+"hrs.)"):""}</strong></label>
                                                    <label style={{ float: "right" }}><strong>Activity total price: </strong> {(Number(activity_detail.price) * Number(adult) + Number(activity_detail["child price"] * Number(child)))}</label>
                                                    {provided.placeholder}
                                                </div>)
                                            }
                                        </Draggable>
                                        <div className="row mt-4">
                                            <div className="col-md-6 form-group">
                                                <label ><strong>Title: </strong> {activity_detail.title}</label>
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label ><strong>Description:</strong> {activity_detail.description}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label ><strong>Adult Price: </strong>{activity_detail.price}</label>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label ><strong>Child Price: </strong> {activity_detail["child price"]}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label ><strong>Duration: </strong>{activity_detail.duration} hrs.</label>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                {activity_detail.closedDates ?
                                                    <label ><strong>Closed Dates: </strong> {(activity_detail.closedDates).join(',')}</label> : ""}
                                            </div>
                                        </div>
                                        <div className="row">
                                            {activity_detail.wikiLinks ?
                                                <div className="col-md-6 form-group">
                                                    {activity_detail.wikiLinks.length > 0 ? <label ><strong>Links: </strong>
                                                        {activity_detail.wikiLinks.map((url) => (
                                                            <p><a href={url} target="_blank">{url} </a></p>
                                                        ))}
                                                    </label> : ""}
                                                </div> : ""}

                                            <div className="col-md-6 form-group">
                                                <button className="btn btn-primary ml-30" onClick={() => this.deleteActivity(activity_detail.id, this.props.dayIndex)}>delete</button>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>:""}
            </>
        );
    }
}
