import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal, ToggleCheckbox } from "../../component/common"
import { getBrands, editBrandDetailsById } from "../../redux/actions/reseller"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getCategories } from "../../redux/actions/reseller"
import { getSNo } from "../../services/helperFunctions"
import { LoaderWrapper } from "../../HOC"
import { directive } from '@babel/types';
import {Link} from 'react-router-dom'
import _ from 'lodash'

class BrandPositionListClass extends Component {

  state = {
    categories: [], 
    headerDetails: [
			{
				label: 'S.No'
			},
			{
				label: 'Name',
			},
			{
				label: 'Position'
            },
            {
				label: 'Action'
			},
        ],
        isOpen:false
  }

  componentWillMount() {
   
  }

  componentDidMount() {
      let query={
        sortBy:'position',
        sortOrder:'desc',
        withPosition:true
      }
    this.props.getBrands(query)
  }

  handleFilter = (query, value, name) => {
		this.props.getBrands(query)
	}

  handleDelete = (id) => {
    this.props.editBrandDetailsById(id,{
        position:""
    }).then((data) => {
        this.setState({disabled:false})
        window.location.reload()
      }).catch((ex) => {
        this.setState({disabled:false})
    })
  }
  render() {

    let { headerDetails,isOpen,positionDetails,isEdit } = this.state
    let { brandList } = this.props
	let IsEmpty = brandList && brandList.list && brandList.list.length > 0

    return (
      <div>
        {/* Header */}
      <div className="sair-pg-header">
        <h4 className="sair-pg-title align-self-center">
          <span className="pg-title-icon">
            <span className="feather-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" className="feather feather-align-left">
                <line x1="17" y1="10" x2="3" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="17" y1="18" x2="3" y2="18"></line>
              </svg>
            </span>
          </span>Shop Positions
      </h4>
        <div className="col-auto ">
        <Link to="/reseller/brand/add-position"><button className="btn btn-primary btn-sm m-1"
            >Add Shop Positions</button></Link>
        <Link to="/reseller/brand"><button className="btn btn-primary btn-sm m-1"
            >Shops</button></Link>
        </div>
      </div>

      {/* Content */}
        <section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<div className="ml-auto d-flex align-items-center">
									{/* <div className="search-box position-relative  ml-3">
										<Filters
											queryHandler={(query) => this.handleFilter(query)}
											isDropDownFilter={false}
										/>
									</div> */}
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
                                    pageMeta={brandList.pageMeta}
                                    queryHandler={(query) => this.handleFilter(query)}
								>
									{brandList.list.map((item, index) => {
										let {id, _id, name, position} = item

										return (
											<tr
												key={index}  >
                        <td>{index + 1}</td>
												<td>{name}</td>
                                                <td>{position}</td>
												<td>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"
														onClick={e => this.handleDelete(_id)}
													></i>
													</a>
												</td>
											</tr>
										)
									})}
								</TableWrapper>
							</div>
						</div>
					</div>
				</section>
        </div>
    )
  }
}

const mapStateToProps = state => {
	return {
    brandList: state.reseller.brandList // data from reducer to categoryList
	}
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getBrands,
    editBrandDetailsById
  }, dispatch)
};

let actions = ["getBrands"]

let component = LoaderWrapper(BrandPositionListClass)({ actions })

export const BrandPositionList = connect(mapStateToProps, mapDispatchToProps)(component)
