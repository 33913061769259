import React from "react"
import "./style.scss"

export const Badge = ({ label, type, className = '' }) => {
    return <div className={`badge badge-${status(type)} ${className}`}>{label}</div>
}

const status = (type) => {
    switch (type) {
        case 1:
            return "success";
        case 2:
            return "danger";
        case 3:
            return "warning";
        case 4:
            return "greybtn";
        case 5:
            return "yellowbtn";
        case 6:
            return "bluebtn";
        case 7:
            return "bg-grey text-black";
        default:
            return "success";
    }
}