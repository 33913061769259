import { combineReducers } from "redux";
import common from "./common"
import dashboard from "./dashboard"
import master from "./master"
import vendor from "./vendor"
import activity from "./activity"
import topactivity from "./topactivity"
import vendorMapping from "./vendorMapping"
import orders from "./orders"
import topactivitycity from "./topactivitycity"
import topcategorymapping from "./topcategorymapping"
import blogs from "./blogs"
import offers from "./offers"
import cityfestival from "./cityfestival"
import advertisementtypeone from './advertisementtypeone';
import itinerary from './itinerary';
import reseller from './reseller'
import purchaseOrder from './purchaseOrder'
import notification from './notification'
import wholesale from './wholesale'
import discount from './discount'

export const reducers = combineReducers({
	common,
	dashboard,
	master,
	vendor,
	activity,
	topactivity,
	vendorMapping,
	orders,
	topactivitycity,
	topcategorymapping,
	blogs,
	offers,
	cityfestival,
	purchaseOrder,
	advertisementtypeone,
  	itinerary,
	reseller,
	notification,
	wholesale,
	discount
})