import { iternaryCreation } from '../../services/apiVariables';
import { itineraryType } from '../../services/actionType';
import { Toast } from "../../services/toast"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"

export const iternaryGenerate = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: itineraryType.updateItinerary, payload: body })
		api({ ...iternaryCreation.generate, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const getItineraryMasterData = (body) => (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
        dispatch({type: itineraryType.updateMasterData,  payload: body})
        api({...iternaryCreation.getMasterData, body}).then(({data,message}) => {
            Toast({type: 'success', message:  message});
            resolve(data);
        }).catch(({message})=>{
            reject(Toast({type: 'error', message}))
        })
    })
}

export const getBase64Images = (body) => (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
        dispatch({type: itineraryType.getBase64Images,  payload: body})
        api({...iternaryCreation.getBase64Images, body}).then(({data,message}) => {
            Toast({type: 'success', message:  message});
            resolve(data);
        }).catch(({message})=>{
            reject(Toast({type: 'error', message}))
        })
    })
}

export const getItineraryDetailsById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		iternaryCreation.getItineraryDetailsById.addParams = id;
		api(iternaryCreation.getItineraryDetailsById).then(({ data }) => {
			dispatch({ type: itineraryType.getItineraryDetailsById, payload: data })
			resolve(data)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const saveItinerary = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: itineraryType.saveItinerary, payload: body })
		api({ ...iternaryCreation.saveItinerary, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const getCityWiseInfo = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		addQuery(query, iternaryCreation.getCityWiseInfo)
		api(iternaryCreation.getCityWiseInfo).then(({ data, message }) => {
			resolve(data)
			dispatch({ type: itineraryType.getCityWiseInfo, payload: data })
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(( {err} ) => {
			Toast({ type: 'error',  message: err });
		})
	})
}

export const getConfigData = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, iternaryCreation.getConfigData)
		api(iternaryCreation.getConfigData).then(({ data, message }) => {
			dispatch({ type: itineraryType.updateItineraryListDetails, payload: data })
			resolve(true)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const getCityNames = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(iternaryCreation.getCityList).then(({ data, message }) => {
			dispatch({ type: itineraryType.getCityNames, payload: data })
			resolve(data)
	    }).catch(({ message }) => {
            Toast({ type: 'error', message })
		})
	})
}
