import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Header, SideNav, Footer } from "../component/layout"

class MainLayoutClass extends Component {

    state = {
        menuOpen: true,
    }

    handleNavigation = () => {

        this.setState({
            menuOpen: !this.state.menuOpen,
        })

    }

    render() {

        let { children, loading } = this.props

        let { menuOpen } = this.state;

        return (
            <div className={`sair-wrapper sair-vertical-nav ${menuOpen}`}>
                <Header handleNavigation={this.handleNavigation} />

                <section className="main-section">
                    <SideNav />
                    <div className="sair-pg-wrapper" >
                        <div className="container-fluid mt-xl-50 mt-sm-30 mt-15">
                            {children}
                        </div>

                        <div className="sair-footer-wrap container">
                            <Footer />
                        </div>
                    </div>

                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sideNavDetails: state.common.sideNavDetails

})



export const MainLayout = connect(mapStateToProps, null)(MainLayoutClass)

