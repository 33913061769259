import { cityfestival } from "../../services/apiVariables"
import { AuthType, common, cityFestivalType } from "../../services/actionType"

import { history } from "../../helpers"

import { Toast } from "../../services/toast"

import { addQuery } from "../../services/helperFunctions"

export const uploadCityfestivalImage = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        api({ ...cityfestival.uploadCityfestivalImage, body }).then(({ data, message }) => {

            Toast({ type: 'success', message: message })

            resolve(data)

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))


        })

    })
}


export const createCityfestival = (body) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        api({ ...cityfestival.createCityFestival, body }).then(({ data, message }) => {

            Toast({ type: 'success', message: message })

            resolve(data)

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))


        })

    })
}

export const updateCityFestival = (body) => (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
        api({ ...cityfestival.updateCityFestival, body }).then(({ data, message }) => {

            Toast({ type: 'success', message: message })

            resolve(data)

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))


        })
    })
}

export const deleteCityFestival = (cityFestTitle, cityId) => (dispatch, getState, { api }) => {
    return new Promise((resolve, reject) => {
        const query = { url_id: cityFestTitle + '/' + cityId }
        addQuery(query, cityfestival.deleteCityFestival)
        dispatch({ type: common.updateLoader, payload: true })
        api({ query, ...cityfestival.deleteCityFestival }).then(({ data, message }) => {
            Toast({ type: 'success', message: message })
            // dispatch({ type: cityFestivalType.updateCityFestivalDetails, payload: data })
            resolve(data)
            dispatch({ type: common.updateLoader, payload: false })
        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })
        })
        // console.log('deleteCityFestival', title)
    })
}

export const getCityFestivalList = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {

        addQuery(query, cityfestival.getCityFestivalList)

        dispatch({ type: common.updateLoader, payload: true })

        api(cityfestival.getCityFestivalList).then(({ data, message }) => {

            dispatch({ type: cityFestivalType.updateCityFestivalDetails, payload: data })

            // Toast({ type: 'success', message: message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}


export const getOneFestivalDetails = (query) => (dispatch, getState, { api }) => {

    return new Promise((resolve, reject) => {
        // console.log(query, 'hi')
        addQuery(query, cityfestival.getOneFestivalDetails)

        // console.log(cityfestival.getOneFestivalDetails, 'hi2')

        dispatch({ type: common.updateLoader, payload: true })

        api(cityfestival.getOneFestivalDetails).then(({ data, message }) => {

            // dispatch({ type: cityFestivalType.updateCityFestivalDetails, payload: data })

            // Toast({ type: 'success', message: message })

            resolve(data)

            dispatch({ type: common.updateLoader, payload: false })

        }).catch(({ message }) => {

            reject(Toast({ type: 'error', message }))

            dispatch({ type: common.updateLoader, payload: false })

        })

    })
}

