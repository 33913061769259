import React, { Component } from 'react'
import { FileUpload } from "../../component/common/fileUpload"

export class ActivityFeatures extends Component {



	ImagePreview = (event, index) => {

		let files = event.target.files[0]

		let { details, handleChange } = this.props

		details[index].image = files

		let formData = new FormData()

		formData.append("activityImage", files)

		this.props.addImage(formData).then((data) => {

			details[index].image = data.imageName

			details[index].imageUrl = data.imageUrl

		})

		handleChange(details)



	}



	handleInputs = (data, tempValue, index) => {

		var name, value, key

		let details = this.props.details.slice(0)

		let { handleChange } = this.props

		if (typeof data === 'object') {

			let { target: { name: fieldName, value: fieldValue } } = data

			name = fieldName

			value = fieldValue

			key = tempValue

		} else {

			name = data

			value = tempValue

			key = index

		}

		details[key][name] = value;

		handleChange(details)


	}

	handleAddOption = () => {

		let { details, handleChange, validator } = this.props

		let errorStatus = details.every((e, index) => {

			let err1 = validator.fieldValid(`/title/${index}/`)

			let error = validator.fieldValid(`/image/${index}/`)

			if (!err1 && !error) {
				validator.showMessageFor(`/title/${index}/`)
				validator.showMessageFor(`/image/${index}/`)
				return false
			}

			return true

		})

		if (errorStatus) {
			details.push({
				title: '',
				image: ''
			})

		}


		handleChange(details)




	}

	handleDeleteToggle = (key, index) => {

		let { details, handleChange } = this.props

		details.splice(index, 1)

		handleChange(details)
	}

	render() {
		let { details = [], disabled = false, tempFile, validator } = this.props;

		return (
			<>
				<h6 className="mb-15 row">
					<div className="col-sm-6"> Activity Features </div>
					<div className="col-sm-6 text-right">
						<button className="btn btn-primary btn-sm"
							onClick={this.handleAddOption}
						>

							Add Activity Features</button>
					</div>
				</h6>
				{details.map((features, index) => {
					let {
						title,
						image
					} = features
					return (
						<div className="row">
							<div className="col-md-6 form-group">
								<label >Title</label>
								<input
									type="text"
									className="form-control"
									value={title}
									name="title"
									onChange={e =>
										this.handleInputs(e, index)
									}
									disabled={disabled}
								/>
								{validator.message(`/title/${index}/`, title, 'required')}
							</div>
							<div className="col-md-5 form-group">
								<label for="firstName">Images</label>
								<FileUpload
									ImagePreview={(e) => this.ImagePreview(e, index)}
									filename={image}
									tempFile={tempFile}
								/>
								{validator.message(`/image/${index}/`, image, 'required')}

							</div>
							{index > 0 &&
								<div className="col-md-1 align-self-center pt-3">
									<i
										className="icon-trash text-danger cursor-pointer"
										onClick={e => this.handleDeleteToggle("activity_features", index)}
									></i>
								</div>
							}
						</div>
					)
				})}


			</>
		)
	}
}
