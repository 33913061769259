import { topActivityCityType } from "../../services/actionType"

const initialState = {
    topActivityCityList: [],
    topActivityCityDropdown: [],
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {

        case topActivityCityType.updateTopActivityCityDetails:

            return {
                ...state,
                topActivityCityList: payload
            }

        case topActivityCityType.updateTopActivityCityDropdown:

            return {
                ...state,
                topActivityCityDropdown: payload
            }

        default:
            return state

    }

}