import { discountType } from "../../services/actionType";

const initialState = {
  info: null,
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case discountType.setDiscount:
      return {
        ...state,
        info: payload,
      };
    default:
      return state;
  }
};
