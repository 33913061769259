import React from "react"
import "./style.scss"

export const InputSearch = ({ className = '', onChange, placeholder = 'Search', value }) => {
    return (

        <div className={`search-box position-relative ${className}`}>

            <input
                className="form-control"
                placeholder={placeholder}
                onChange={e => {
                    if (onChange) {
                        onChange(e)
                    }
                }}
                value={value}
            />
            <i className="icon-search"></i>

        </div>

    )
}