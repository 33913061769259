import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

class dashboardClass extends Component {
	render() {
		return (

			<div className="row">
				<div className="col-xl-12">
					<div className="sair-row">
						<div className="col-lg-3 col-md-6">
							<div className="card card-sm">
								<div className="card-body">
									<div className="d-flex justify-content-between mb-5">
										<div>
											<span className="d-block font-15 text-dark font-weight-500">Users</span>
										</div>
										<div>
											<span className="text-success font-14 font-weight-500">+10%</span>
										</div>
									</div>
									<div className="text-center">
										<span className="d-block display-4 text-dark mb-5">36.1K</span>
										<small className="d-block">172,458 Target Users</small>
									</div>
								</div>
							</div>

						</div>
						<div className="col-lg-3 col-md-6">
							<div className="card card-sm">
								<div className="card-body">
									<div className="d-flex justify-content-between mb-5">
										<div>
											<span className="d-block font-15 text-dark font-weight-500">Campaign Leads</span>
										</div>
										<div>
											<span className="text-success font-14 font-weight-500">+12.5%</span>
										</div>
									</div>
									<div className="text-center">
										<span className="d-block display-4 text-dark mb-5"><span className="counter-anim">168,856</span></span>
										<small className="d-block">472,458 Targeted</small>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="card card-sm">
								<div className="card-body">
									<div className="d-flex justify-content-between mb-5">
										<div>
											<span className="d-block font-15 text-dark font-weight-500">New Contacts</span>
										</div>
										<div>
											<span className="text-warning font-14 font-weight-500">-2.8%</span>
										</div>
									</div>
									<div className="text-center">
										<span className="d-block display-4 text-dark mb-5">73</span>
										<small className="d-block">100 Targeted</small>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="card card-sm">
								<div className="card-body">
									<div className="d-flex justify-content-between mb-5">
										<div>
											<span className="d-block font-15 text-dark font-weight-500">Win/Loss Ratio</span>
										</div>
										<div>
											<span className="text-danger font-14 font-weight-500">-75%</span>
										</div>
									</div>
									<div className="text-center">
										<span className="d-block display-4 text-dark mb-5">48:65</span>
										<small className="d-block">42:32 Targeted</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {

	return {

	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({

	}, dispatch)
};

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(dashboardClass)
