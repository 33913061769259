import { wholesale } from "../../services/apiVariables";
import { wholesaleType } from "../../services/actionType";
import { common } from "../../services/actionType";
import { Toast } from "../../services/toast";
import { addQuery } from "../../services/helperFunctions";

export const getProductList =
  (query) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: common.updateLoader, payload: true });
      if (!query) {
        wholesale.getProductsList.reset();
        dispatch({ type: wholesaleType.wholesaleProductListFirstRequest });
      }
      addQuery(query, wholesale.getProductsList);
      api(wholesale.getProductsList)
        .then(({ data, message }) => {
          resolve(true);
          dispatch({ type: wholesaleType.wholesaleProductList, payload: data });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const getProductNames =
  () =>
  (dispatch, _, { api }) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: common.updateLoader, payload: true });

      api(wholesale.getProductNames)
        .then(({ data, message }) => {
          resolve(true);
          const productNames = (data || []).map((item) => ({
            value: item._id,
            label: item.name,
          }));
          dispatch({
            type: wholesaleType.wholesaleProductNames,
            payload: productNames,
          });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const createProduct =
  (body) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: common.updateLoader, payload: true });

      api({ ...wholesale.productCreate, body })
        .then(({ data }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleProductCreate,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const updateProduct =
  (id, body) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: common.updateLoader, payload: true });
      wholesale.productUpdate.addParams = id;
      api({ ...wholesale.productUpdate, body })
        .then(({ data, message }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleProductCreate,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const deleteProduct =
  (id) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: common.updateLoader, payload: true });
      wholesale.productDelete.addParams = id;
      api({ ...wholesale.productDelete })
        .then(({ data, message }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleProductCreate,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const getLeadList =
  (query) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });
      if (!query) {
        wholesale.getLeadsList.reset();
        dispatch({ type: wholesaleType.wholesaleLeadListFirstRequest });
      }
      addQuery(query, wholesale.getLeadsList);
      api(wholesale.getLeadsList)
        .then(({ data }) => {
          resolve(true);
          dispatch({ type: wholesaleType.wholesaleLeadList, payload: data });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const getLeadListByUser =
  (query) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });
      addQuery(query, wholesale.getLeadsListByUser);
      api(wholesale.getLeadsListByUser)
        .then(({ data }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleLeadListByUser,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const createLead =
  (body) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });

      api({ ...wholesale.leadCreate, body })
        .then(({ data }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleLeadCreate,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const confirmLead =
  (body) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });

      api({ ...wholesale.leadConfirm, body })
        .then(({ data, message }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleLeadConfirm,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
          Toast({ type: "success", message: "Lead confirmed" });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const createConfirmedLead =
  (body) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });

      api({ ...wholesale.leadCreateConfirm, body })
        .then(({ data, message }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleLeadCreateConfirm,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
          Toast({ type: "success", message: "Lead created" });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const getWholesaleProductNames =
  () =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });

      api(wholesale.getWholesaleProductNames)
        .then(({ data, message }) => {
          resolve(true);
          const productNames = (data || []).map((item) => ({
            value: item._id,
            label:
              item.product_info && item.product_info.length > 0
                ? item.product_info[0].name
                : "",
            ...item,
          }));
          dispatch({
            type: wholesaleType.wholesaleAddProductNames,
            payload: productNames,
          });
          dispatch({ type: common.updateLoader, payload: false });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };

export const deleteLead =
  (id) =>
  (dispatch, _, { api }) => {
    return new Promise((resolve) => {
      dispatch({ type: common.updateLoader, payload: true });
      wholesale.deleteLead.addParams = id;
      api({ ...wholesale.deleteLead })
        .then(({ data, message }) => {
          resolve(true);
          dispatch({
            type: wholesaleType.wholesaleDeleteLead,
            payload: data,
          });
          dispatch({ type: common.updateLoader, payload: false });
          Toast({ type: "success", message: "Lead deleted" });
        })
        .catch(({ message }) => {
          Toast({ type: "error", message });
        });
    });
  };
