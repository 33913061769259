import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import SimpleReactValidator from 'simple-react-validator';
import { createStayType, getStayTypeDetails, editStayType } from "../../redux/actions/master"

class StayTypeModalClass extends Component {

	state = {
		stayTypeDetails: {
            title: '',

		},
		isEdit: false,
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentWillReceiveProps(props) {

		let { modalDetails, isEdit } = props
		this.setState({
			stayTypeDetails: modalDetails,
			isEdit: isEdit,
		})
	}

	handleInputs = ({ target: { value, name } }) => {

		let stayTypeDetails = Object.assign({}, this.state.stayTypeDetails)

		stayTypeDetails[name] = value

		this.setState({
			stayTypeDetails
		})


	}

	handleSubmit = () => {

		if (this.validator.allValid()) {

			let { stayTypeDetails: { title, id = '' }, isEdit } = this.state

			let body = {
                title: title,
                status:1
			}

			if (!isEdit) {

				this.props.createStayType(body).then(() => {

					this.props.getStayTypeDetails().then(() => {
						let temp = {
                            title: '',
						}

						this.setState({
							isEdit: false,
							stayTypeDetails: temp

						})
					})
				})
			} else {

				this.props.editStayType(id, body).then((message) => {

					this.props.getStayTypeDetails().then(() => {
						let temp = {
                            title: '',
						}

						this.setState({
							isEdit: false,
							stayTypeDetails: temp

						})
					})

				})
			}
			this.props.toggle()

			this.validator.hideMessages();

		} else {
			this.setState({
				isError: true
			})
			this.validator.showMessages();

		}
	}
	render() {

		let { isError, isEdit, toggle } = this.props

		let { stayTypeDetails} = this.state

		this.validator.purgeFields();
		let { title } = stayTypeDetails

		return (
			<section className="side-slider h-100">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {isEdit ? "Edit Stay Type" : "Add Stay Type"}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Stay Type</label>
							<input
								className={`form-control ${this.validator.message('title', title, 'required') ? 'inValid' : ''}`} id="title"
								placeholder=""
								name="title"
								value={title}
								type="text"
								onChange={this.handleInputs}


							/>
							{this.validator.message('title', title, 'required')}
						</div>

					</div>

					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>{isEdit ? "Update" : "Submit"}</button>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {

	return {}

};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createStayType,
		getStayTypeDetails,
		editStayType
	}, dispatch)
};



export const StayTypeModal = connect(mapStateToProps, mapDispatchToProps)(StayTypeModalClass)
