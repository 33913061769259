import { ordersType } from "../../services/actionType"


const initialState = {
    isLoading: false,
    ordersList: {},
    orderDetail: {},
    vendorDropdownList: {},
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {
        case ordersType.fetchOrders:
            return {
                ...state,
                isLoading: true
            }
        case ordersType.updateOrdersDetails:
            return {
                ...state,
                ordersList: payload,
                isLoading: false
            }
        case ordersType.updateSingleOrderDetail:
            return {
                ...state,
                orderDetail: payload
            }
        case ordersType.getVendorListDropdown:
            return {
                ...state,
                vendorDropdownList: payload
            }
        default:
            return state

    }
}