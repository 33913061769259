import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { ForgotPasswordApi } from "../../redux/actions/common"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import SimpleReactValidator from 'simple-react-validator';

export class ForgotPasswordClass extends Component {

    state = {
        emailId: "",
    }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message font-md">{message}</span>,
            autoForceUpdate: this,
            messages: {
                email: 'Please enter valid email',
            }
        });
    }

    handleInputs = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        })
    }

    sendLink = (e) => {

        e.preventDefault()

        if (this.validator.allValid()) {

            e.preventDefault()

            const { emailId } = this.state

            const { ForgotPasswordApi } = this.props

            ForgotPasswordApi({ email: emailId }).then(() => {

                this.props.history.push('/auth/login')
            })

        } else {

            this.validator.showMessages()

        }


    }

    render() {

        let { emailId } = this.state

        return (
            <>
                <h3 class="font-bold">Forgot Password</h3>
                <p class="mb-5">Let us know your email id, we’ll send you  a link to reset your password!</p>
                <form>
                    <div class="form-group mb-4">
                        <label class="font-bold">Email ID</label>
                        <input class="form-control" placeholder="name@example.com" name="emailId"
                            onChange={this.handleInputs}
                        />
                        {this.validator.message('email', emailId, 'email|required')}

                    </div>
                    <div class="pt-2">
                        <button class="btn btn-warning btn-block" onClick={(e) => this.sendLink(e)}
                        >
                            Send Link
                        </button>
                    </div>
                </form>
                <div class="my-4 text-center">
                    <Link to='/auth/login' class="font-bold text-body">Back to home</Link>
                </div>
            </>
        )
    }
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        ForgotPasswordApi
    }, dispatch)
}

export const ForgotPassword = connect(null, mapDispatchToProps)(ForgotPasswordClass)

