import React from 'react';

function ContentMainHeader(props) {
  return (
    <div className="sair-pg-header">
      <h4 className="sair-pg-title align-self-center">
        <span className="pg-title-icon">
          <span className="feather-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              className="feather feather-align-left"
            >
              <line x1="17" y1="10" x2="3" y2="10"></line>
              <line x1="21" y1="6" x2="3" y2="6"></line>
              <line x1="21" y1="14" x2="3" y2="14"></line>
              <line x1="17" y1="18" x2="3" y2="18"></line>
            </svg>
          </span>
        </span>
        {props.title}
      </h4>
      {props.children}
    </div>
  );
}

export {ContentMainHeader};