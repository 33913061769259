import { axiosInstance, logout } from "./utilities"

export var api = async function ({ method = "get", api, body, status = false, token = '', baseURL = "normal" }) {


	return await new Promise((resolve, reject) => {

		// setting token
		axiosInstance.defaults.headers.common['x-access-token'] = localStorage.getItem('AuthToken')
		axiosInstance[method](`${getMicroServiceURL(baseURL)}${api}`, (body ? body : "")).then((data) => {

			resolve(statusHelper(status, data))

		}).catch((error) => {

			try {

				if (error.response) {

					reject(statusHelper(status, error.response))

				} else {

					reject(error)

				}

			}

			catch (err) {

				reject(err)

			}

		})

	})
}


var statusHelper = (status, data) => {

	if (data.status == 401) {

		logout()

	}

	if (status) {
		return {
			status: data.status,
			...data.data
		}
	} else {
		return data.data
	}
}


// local api base url
let getMicroServiceURL = (baseURL) => {

	let finalURL = ''

	switch (baseURL) {
		case 'normal':
			finalURL = process.env.REACT_APP_BASE_URL || 'https://services.sair.in/' 
			break;

		default:
			break;
	}

	return finalURL

}


// staging api base url
// let getMicroServiceURL = (baseURL) => {

// 	let finalURL = ''

// 	switch (baseURL) {
// 		case 'normal':
// 			finalURL = 'http://13.235.122.145:2004/'
// 			break;


// 		default:
// 			break;
// 	}
// 	return finalURL

// 	// return process.env.REACT_APP_BASE_URL

// }




