import React, { Component } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  getPurchaseOrders,
  getDetailPurchaseOrder,
  cancelPurchaseOrder,
  downloadPurchaseOrder,
} from "../../redux/actions/purchaseOrder";
import { getWareHouseNames } from "../../redux/actions/reseller";

import { LoaderWrapper } from "../../HOC";
import { TableWrapper, ReactSelect, Filters } from "../../component/common";
import { getSNo } from "../../services/helperFunctions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import _ from "lodash";
import { purchaseOrder } from "../../services/apiVariables";
import { removeQuery } from "../../services/helperFunctions";

const download_purchase_url =
  process.env.REACT_APP_BASE_URL +
  "v1/admin/download-purchase-order?purchaseOrderId=";

class PurchaseOrdersListClass extends Component {
  state = {
    headerDetails: [
      {
        label: "S.No",
      },
      {
        label: "AWB",
      },
      {
        label: "Order Id",
      },
      {
        label: "logistic_partner",
      },
      {
        label: "Payment Mode",
      },
      {
        label: "Cod Amount",
      },
      {
        label: "Created At",
      },
    ],
    warehouseMappings: [],
    isOpen: false,
    warehouseOptions: [],
    searchByWarehouse: [],
    filterDateRange: null,
  };

  componentDidMount() {
    this.props.getWareHouseNames().then((data) => {
      const allVinculumIds = data
        .filter((item) => item.type === "vinretail")
        .map((item) => item._id);
      console.log("allVinculumIds", allVinculumIds);
      const warehouseOptions = _.map(data, (warehouse) => {
        return { value: warehouse._id, label: warehouse.name };
      });
      warehouseOptions.unshift({
        label: "All Vinculum",
        value: allVinculumIds.join(","),
      });
      this.setState({ warehouseOptions });
    });
  }

  handleFilter = (query, value, name) => {
    if (name == "filter") {
      this.setState({
        selectedFilter: query,
      });

      query = {
        pageNo: 1,
        filter: query,
      };
    }

    this.props.getPurchaseOrders(query);
  };

  handleSearchFilter = (query, type) => {
    let customQuery = {};
    if (query.search !== "") {
      if (type === "order_id") {
        this.setState({ searchByOrderid: query.search });
        customQuery["searchByOrderid"] = query.search;
      } else {
        this.setState({ searchByOrderid: query.search });
        customQuery["searchByOrderid"] = "";
      }
      this.props.getPurchaseOrders(customQuery);
    }
  };

  handleSearchByWarehouse = (warehouseId) => {
    const customQuery = {};
    const { searchByOrderid, filterDateRange } = this.state;
    if (searchByOrderid) {
      customQuery["searchByOrderid"] = searchByOrderid;
    }
    if (warehouseId && warehouseId.length > 0) {
      customQuery["searchByWarehouse"] = warehouseId.join(",");
    }
    if (filterDateRange && filterDateRange.length > 0) {
      customQuery["fromDate"] = moment(filterDateRange[0]).format("YYYY-MM-DD");
      customQuery["toDate"] = moment(filterDateRange[1]).format("YYYY-MM-DD");
    }
    this.setState({
      searchByWarehouse: warehouseId || [],
    });
    removeQuery(purchaseOrder.listPurchaseOrders);
    this.props.getPurchaseOrders(customQuery);
  };

  handleSearchByDates = (dates) => {
    const customQuery = {};
    const { searchByOrderid, searchByWarehouse } = this.state;
    if (searchByOrderid) {
      customQuery["searchByOrderid"] = searchByOrderid;
    }
    if (searchByWarehouse.length > 0) {
      customQuery["searchByWarehouse"] = searchByWarehouse.join(",");
    }
    if (dates && dates.length > 0) {
      customQuery["fromDate"] = moment(dates[0]).format("YYYY-MM-DD");
      customQuery["toDate"] = moment(dates[1]).format("YYYY-MM-DD");
    }
    this.setState({ filterDateRange: dates });
    removeQuery(purchaseOrder.listPurchaseOrders);
    this.props.getPurchaseOrders(customQuery);
  };

  handleSubmit = (orderData) => {
    this.setState({ orderData: orderData });
    this.setState({ isOpen: true });
    let query = {
      awb: orderData.awb,
    };
    if (orderData.awb) {
      this.props.getDetailPurchaseOrder(query).then((data) => {
        this.setState({
          order_details: data.order_details,
          tracking_details: data.tracking_details,
        });
      });
    } else {
      this.setState({
        order_details: "",
        tracking_details: "",
      });
    }
  };

  cancelOrder(orderData) {
    let body = {
      awb: orderData.awb,
      remarks: "Test order cancelled",
    };
    console.log("cancel order", body);
    this.props.cancelPurchaseOrder(body);
  }

  toggle = () => {
    this.setState({ isOpen: false });
  };

  getJsDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  downloadPurchaseOrder(id) {
    let query = {
      purchaseOrderId: id.toString(),
    };
    this.props.downloadPurchaseOrder(query);
  }

  render() {
    let { purchaseOrders } = this.props;
    let { list = [], pageMeta = {} } = purchaseOrders;
    let IsEmpty = list.length > 0;
    let {
      headerDetails,
      isOpen,
      loading,
      orderData,
      order_details,
      tracking_details,
      warehouseOptions,
      searchByWarehouse,
      filterDateRange,
    } = this.state;

    return (
      <>
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span class="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="feather feather-align-left"
                >
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>
            Purchase Orders
          </h4>
        </div>
        <section className="sair-sec-wrapper">
          <div className="row">
            <div className="col-sm">
              <div className="row">
                <div className="col-md-3 form-group">
                  <label>
                    <b>Search by Order ID</b>
                  </label>
                  <Filters
                    className="p-0"
                    queryHandler={(query) =>
                      this.handleSearchFilter(query, "order_id")
                    }
                    isDropDownFilter={false}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <label>
                    <b>Search by Warehouse</b>
                  </label>
                  <ReactSelect
                    isMulti
                    options={warehouseOptions}
                    handleChange={(_, warehouseId) => {
                      this.handleSearchByWarehouse(warehouseId);
                    }}
                    keyName="warehouseId"
                    value={searchByWarehouse}
                  />
                </div>
                <div className="col-md-4">
                  <label>
                    <b>Search by Dates</b>
                  </label>
                  <div className="form-group">
                    <DateRangePicker
                      onChange={this.handleSearchByDates}
                      value={filterDateRange}
                    />
                  </div>
                </div>
              </div>

              <div className="table-wrap mt-3">
                <TableWrapper
                  headerDetails={headerDetails}
                  isEmpty={IsEmpty}
                  pageMeta={pageMeta}
                  queryHandler={(query) => this.handleFilter(query)}
                >
                  {list.map((item, index) => {
                    let {
                      id,
                      awb,
                      order_id,
                      logistic_partner,
                      created_at,
                      is_cod,
                      api_payload,
                      booking_id,
                      cod_amount,
                    } = item;

                    let url = download_purchase_url + id;
                    return (
                      <tr key={index}>
                        <td>{getSNo(pageMeta, index)}</td>
                        <td>{awb ? awb : ""}</td>
                        <td>{booking_id ? booking_id : ""}</td>
                        <td>{logistic_partner ? logistic_partner : ""}</td>
                        <td>{is_cod ? "COD" : "ONLINE"}</td>
                        <td>{cod_amount ? cod_amount : "-"}</td>
                        <td>{this.getJsDate(created_at)}</td>
                        {/* <td>
                          {awb &&
                          <button
                            onClick={() => this.handleSubmit(item)}
                            className="btn btn-primary btn-sm"
                          >
                            View Details
                          </button>
                          }
                          
                        </td> */}
                        <td>
                          <div className=" container row">
                            <div className="col-md-4 ">
                              <a
                                href={url}
                                target="_blank"
                                className="btn btn-primary m-top"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </TableWrapper>
              </div>
            </div>
          </div>
          <div className="order-details">
            <Modal
              isOpen={isOpen}
              size={"18"}
              className="modal-dialog-centered order-detail-model"
            >
              <ModalHeader
                //className={headerClass}
                toggle={() => this.toggle()}
              >
                Order Details
              </ModalHeader>
              <div className="position-relative">
                <ModalBody className="order-detail-model-body">
                  <h6 className="text-color-gray">
                    AWB NO : #{orderData && orderData.awb}
                  </h6>
                  <br />
                  <h6>Product Details</h6>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 5,
                    }}
                  >
                    <div>client</div>
                    <div>{order_details && order_details.client_name}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 5,
                    }}
                  >
                    <div>Client id</div>
                    <div>{order_details && order_details.client_order_id}</div>
                  </div>

                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Created</th>
                          <th>location</th>
                          <th>remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tracking_details &&
                          tracking_details.length > 0 &&
                          tracking_details.map((track, index) => {
                            let { location, remarks, created } = track;
                            return (
                              <tr>
                                <td>{created}</td>
                                <td>{location}</td>
                                <td>{remarks}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className=" container row">
                    <div className="col-md-4 ">
                      <button
                        className="btn btn-danger m-top"
                        onClick={() => this.cancelOrder(orderData)}
                      >
                        Cancel Purchase Order
                      </button>
                    </div>
                  </div>
                </ModalBody>

                {loading ? (
                  <div class="upload-loader">
                    <div class="position-relative w-100 h-100">
                      <div class="loader-circle"></div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Modal>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  purchaseOrders: state.purchaseOrder.purchaseOrders,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPurchaseOrders,
      getDetailPurchaseOrder,
      cancelPurchaseOrder,
      downloadPurchaseOrder,
      getWareHouseNames,
    },
    dispatch
  );
};

let actions = ["getPurchaseOrders"];

let component = LoaderWrapper(PurchaseOrdersListClass)({ actions });

export const PurchaseOrdersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
