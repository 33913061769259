import { dashboard } from "../../services/apiVariables"
import { dashboardType } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"



export const createDriver = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {

		// dispatch({ type: dashboardType.createDriver, payload: body })

		api({ ...dashboard.createDriver, body }).then(({ data, message }) => {

			resolve(data)

		}).catch(({ message }) => {
			console.log(message)

			reject(false)

		})

	})
}

export const createExperienceGroup = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {

		dispatch({ type: dashboardType.createExperienceGroup, payload: body })

		api({ ...dashboard.createExperienceGroup, body }).then(({ data, message }) => {

			resolve(data)

		}).catch(({ message }) => {
			console.log(message)

			reject(false)

		})

	})
}

export const createPhysicalRestriction = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {

		dispatch({ type: dashboardType.createPhysicalRestriction, payload: body })

		api({ ...dashboard.createPhysicalRestriction, body }).then(({ data, message }) => {

			resolve(data)

		}).catch(({ message }) => {
			console.log(message)

			reject(false)

		})

	})
}