import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { ReactSelect } from "../common/ReactSelect";
import SimpleReactValidator from 'simple-react-validator';
import { createCategoryMapping, getCategoryMappingListDetails, updateActivityMapping } from "../../redux/actions/activity";

export class MappingActivityModalClass extends Component {

	state = {
		mappingDetails: {
			activity_id: '',
			category_id: '',
			city_list_id: ''

		},
		activityList: [],
		categoryList: [],
		cityList: [],
		isEdit: false,

	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}


	componentWillReceiveProps(props) {

		let { modalDetails, isEdit, categoryMappingDropDownLists: { Activity = [], Category = [], City = [] } } = props

		let { activityList, categoryList, cityList } = this.state

		Activity.forEach(item => {
			activityList.push({
				label: item.title,
				value: item.id
			})

		})
		Category.forEach(item => {
			categoryList.push({
				label: item.title,
				value: item.id
			})

		})
		City.forEach(item => {
			cityList.push({
				label: item.city_name,
				value: item.id
			})

		})

		this.setState({
			activityList,
			categoryList,
			cityList,
			mappingDetails: modalDetails,
			isEdit: isEdit,
		})
	}

	handleInputs = ({ value, name }) => {

		let mappingDetails = Object.assign({}, this.state.mappingDetails)

		mappingDetails[name] = value

		this.setState({
			mappingDetails
		})


	}

	handleSubmit = () => {

		if (this.validator.allValid()) {

			let { mappingDetails: { activity_id, category_id, city_list_id, id = '' }, isEdit } = this.state

			let body = {
				activity_id: activity_id,
				category_id: category_id,
				city_list_id: city_list_id
			}

			if (!id) {

				this.props.createCategoryMapping(body).then(() => {

					this.props.getCategoryMappingListDetails().then(() => {
						let temp = {
							name: '',
						}

						this.setState({
							isEdit: false,
							mappingDetails: temp

						})
					})
				})
			} else {

				this.props.updateActivityMapping(id, body).then(() => {

					this.props.getCategoryMappingListDetails().then(() => {
						let temp = {
							name: '',
						}

						this.setState({
							isEdit: false,
							mappingDetails: temp

						})
					})

				})

			}


			this.props.toggle()
			this.validator.hideMessages();

		} else {
			this.setState({
				isError: true
			})
			this.validator.showMessages();

		}
	}

	render() {

		let { isError, toggle, isEdit, dropDownLists: { activityList, categoryList, cityList } } = this.props

		let { mappingDetails: { activity_id, city_list_id, category_id } } = this.state

		this.validator.purgeFields();


		return (
			<section className="side-slider h-100">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {isEdit ? "Edit Mapping Activity " : "Add Mapping Activity "}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Activity</label>
							<ReactSelect
								isMulti={false}
								options={activityList}
								handleChange={(keyName, data) => {
									this.handleInputs({ name: keyName, value: data })
								}}
								keyName="activity_id"
								value={activity_id}
								disabled={false}
							/>
							{this.validator.message('activity id', activity_id, 'required')}
						</div>
						<div className="col-md-12 form-group">
							<label>Category</label>
							<ReactSelect
								isMulti={false}
								options={categoryList}
								handleChange={(keyName, data) => {
									this.handleInputs({ name: keyName, value: data })
								}}
								keyName="category_id"
								value={category_id}
								disabled={false}
							/>
							{this.validator.message('category id', category_id, 'required')}
						</div>

						<div className="col-md-12 form-group">
							<label>City</label>
							<ReactSelect
								isMulti={false}
								options={cityList}
								handleChange={(keyName, data) => {
									this.handleInputs({ name: keyName, value: data })
								}}
								keyName="city_list_id"
								value={city_list_id}
								disabled={false}
							/>
							{this.validator.message('city id', city_list_id, 'required')}
						</div>

					</div>

					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>{isEdit ? "Update" : "Submit"}</button>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {

	return {

		categoryMappingDropDownLists: state.activity.categoryMappingDropDownLists
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createCategoryMapping,
		getCategoryMappingListDetails,
		updateActivityMapping,
	}, dispatch)
};



export const MappingActivityModal = connect(mapStateToProps, mapDispatchToProps)(MappingActivityModalClass)

