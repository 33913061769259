import { purchaseOrder } from "../../services/apiVariables"
import { purchaseOrderType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
// import { history } from "../../helpers"
import { Toast } from "../../services/toast"


export const generatePurchaseOrder = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		api({ ...purchaseOrder.createPurchaseOrder, body }).then(({ data, message }) => {

			Toast({ type: 'success', message: message })

			resolve(data)
		}).catch(({ message, err }) => {

			reject(Toast({ type: 'error', message: err || message }))

		})

	})
}


export const getPurchaseOrders = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		addQuery(query, purchaseOrder.listPurchaseOrders)
		api({ ...purchaseOrder.listPurchaseOrders }).then(({ data, message }) => {
			dispatch({ type: purchaseOrderType.listPurchaseOrders, payload: data })
            dispatch({ type: common.updateLoader, payload: false })
			resolve(true)
		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))

		})

	})
}

export const cancelPurchaseOrder = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		api({ ...purchaseOrder.cancelPurchaseOrder ,body}).then(({ data, message }) => {

			dispatch({ type: purchaseOrderType.cancelPurchaseOrder, payload: data })

			Toast({ type: 'success', message: message })

			resolve(data)
		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))

		})

	})
}

export const getDetailPurchaseOrder = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		addQuery(query, purchaseOrder.detailPurchaseOrder)
		api({ ...purchaseOrder.detailPurchaseOrder }).then(({ data, message }) => {
			dispatch({ type: purchaseOrderType.detailPurchaseOrder, payload: data })

			Toast({ type: 'success', message: message })

			resolve(data)
		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))

		})

	})
}
export const downloadPurchaseOrder = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		addQuery(query, purchaseOrder.downloadPurchaseOrder)
		api({ ...purchaseOrder.downloadPurchaseOrder }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
            dispatch({ type: common.updateLoader, payload: false })
			resolve(data)
		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))

		})

	})
}