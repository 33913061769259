import React, { Component } from 'react'
import { FileUpload, ReactAutoComplete, NormalInput, ReactSelect} from "../../component/common"
import { TextEditorActivity} from "../../component/common/TextEditorActivity"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { SAILMALL_BASE_URL } from "../../config/config"

import SimpleReactValidator from 'simple-react-validator';
import {getSellerDetailsById, editSellerById} from "../../redux/actions/reseller"

import { TextEditor, DateTime } from '../../component/common';

import { hidePastDate } from "../../services/helperFunctions"

import _ from "lodash"

class SellerCreateClass extends Component {
	state = {
	sellerDetails: {},
}

	componentWillMount() {
    this.descState = {}

    this.validator = new SimpleReactValidator({
      element: message => <span className="error-message font-md">{message}</span>,
      autoForceUpdate: this,
      messages: {
        int: 'this :attribute required here'
      }
    });

    this.handleInputs = ({ value, name }) => {
      let sellerDetails = this.state.sellerDetails
      let url;
      if (name === 'name') {
        sellerDetails.name = value
      }
      else {
        sellerDetails[name] = value
      }
      
      this.setState({ sellerDetails: sellerDetails })
    }
	}
 
  componentDidMount() {
    let { match: { params: { id } }, history } = this.props;
      if (id) {
        this.props.getSellerDetailsById(id).then((data) => {
          let sellerDetails = data.data[0];
          this.setState({ sellerDetails })
        })
      }
  }

  handleSubmit() {
    let {sellerDetails} = this.state
    let id = sellerDetails.id
    if(id) {
      // EDIT FLOW
      if (this.validator.allValid()) {
        
      let body = {
        "gst":sellerDetails.gst,
        "pan":sellerDetails.pan,
        "address":sellerDetails.address
      }
      this.props.editSellerById(id,body).then((data) => {
        let { history } = this.props
        this.setState({disabled:false})
        history.push('/reseller/sellers')
      }).catch((ex) => {
        this.setState({disabled:false})
      })
    }
    else{
        this.validator.showMessages();
      }
    }
}

  ImagePreview = (key, event) => {
    let files = event.target.files[0]
    let brandDetails = Object.assign({}, this.state.brandDetails)
    let formData = new FormData()
    formData.append("resellerImage", files)
    this.props.addImage(formData).then((data) => {
      brandDetails.imgLinks[0] = data.imageUrl
    })
    this.setState({brandDetails})
  }
  
  render() {
    let {sellerDetails}=this.state
    let {first_name, last_name, email, gst,pan,address} =sellerDetails;
    let tempFile
    return (
      <div>
          {/* Header */}
          <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Seller Information
					</h4>
        </div>

        <div className="row">
            <div className="col-md-6 form-group">
              <label><b>GST Number</b></label>
              <NormalInput
                className="form-control"
                name="gst"
                type="text"
                value={gst}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Gst Number', gst, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label><b>PAN Number</b></label>
              <NormalInput
                className="form-control"
                name="pan"
                type="text"
                value={pan}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('PAN Number', pan, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label><b>Address</b></label>

              <NormalInput
                className="form-control"
                name="address"
                type="text"
                value={address}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('address', address, 'required')}
                number={false}
              />
            </div>
            
        </div>
        <hr />
          <button className="btn btn-primary"
            onClick={() => this.handleSubmit()}
            disabled={this.state.disabled}
          >Submit</button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    editSellerById,
    getSellerDetailsById
  }, dispatch)
}

export const SellerCreate = connect(mapStateToProps, mapDispatchToProps)(SellerCreateClass)
