import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import SimpleReactValidator from 'simple-react-validator';
import { DateTime, ReactSelect, NormalInput, ToggleCheckbox, ConfirmModal, TextEditor } from "../../component/common"
import { hidePastDate, validateEndDate, _ } from "../../services/helperFunctions"
import moment from "moment"
import { ActivityFeatures, TravellerInformation } from "../../component/activity"
import { FileUpload } from "../../component/common/fileUpload"
import { getActivityDetailsById, addImage, createActivity, updateActivity, createSliderActivityImage } from "../../redux/actions/activity" // getActivityDropDown

import { LoaderWrapper } from "../../HOC"

import { ReactAutoComplete } from '../../component/common';

class ViewActivityClass extends Component {
    state = {
        disabled: true,
        // activityDetails: {
        //   title: 'Activity Title',
        //   activity_image: 'activityImage/72c66fd038f1c4c8635166202_full-hd-desktop-wallpapers(copy).jpg',
        //   activity_imageUrl: 'activity_imageUrl',
        //   activity_start_date: '2019/10/18',
        //   activity_end_date: '2019/10/18',
        //   activity_start_age: '18',
        //   activity_end_age: '18',
        //   activity_off_day: ["sat", "sun"],
        //   activity_options: [
        //     {
        //       title: 'title',
        //       price: '200'
        //     }
        //   ],

        //   activity_details: [
        //     {
        //       icon: "language",
        //       title: "title",
        //       description: "description",
        //     }
        //   ],

        //   activity_time_slot: [
        //     {
        //       time: '1 hours',
        //       price: 500
        //     }
        //   ],

        //   activity_features: [
        //     {
        //       title: 'title',
        //       image: 'activityImage/72c66fd038f1c4c8635166202_full-hd-desktop-wallpapers(copy).jpg',
        //       imageUrl: 'imageUrl',
        //     }
        //   ],
        //   traveller_information: {
        //     title: 'traveller_information_title',
        //     height: 160,
        //     weight: 60,
        //     age: '50',
        //     gender: 'male'

        //   },
        //   way_of_reach: [
        //     {
        //       vehicle_type: 'vehicle_type',
        //       description: 'description'
        //     }
        //   ],
        //   varient_offer: 'varient_offer',
        //   physical_restriction: [1, 2],

        //   // Others

        //   attraction: 'attraction',
        //   activity_time: '3 hrs',
        //   unique_experience: '1',
        //   traveller_des: 'traveller_des',
        //   number_of_stops: '5',
        //   name_of_stops: ["name_of_stops", "name_of_stops1"],
        //   guest_bringing: 'guest_bringing',
        //   people_understand_language: 'people_understand_language',
        //   ticket_count: '100',
        //   experience_group: '5',
        //   traveler_enjoy_experience_group_id: '1',
        //   traveler_book_tickets: 'traveller_book_ticket',
        //   base_price: 500,
        //   child_price: 250,
        //   best_experience_time: 'best_experience_time',
        //   cancellation_type: 'cacelation_type',
        //   cancel_a_booking: 'cacelation_a_booking',
        //   traveller_separte_ticket: "23",
        //   price_your_product: 'per person',
        //   maximum_count_a_group: '10',
        //   advanced_notice_for_a_booking: 'advanced_notice_a_booking',
        //   ticket_offer: true,
        //   offer_guide: false,
        //   ticket_line_access: false,
        //   is_private_activity: false,
        //   license_required: false,
        //   license_certified: false,
        //   // activity_description: '',
        //   latitude: "80.242477",
        //   longitude: "13.059537",
        //   includes: "includes",
        //   information: "information",
        //   description: "description",
        //   itinerary: "itinerary",
        // },

        activityDetails: {
            title: '',
            activity_image: '',
            activity_imageUrl: '',
            activity_start_date: '',
            activity_end_date: '',
            // activity_start_age: '',
            // activity_end_age: '',
            activity_off_day: [],
            activity_options: [
                {
                    title: '',
                    price: ''
                }
            ],

            activity_details: [
                {
                    icon: "",
                    title: "",
                    description: "",
                }
            ],

            activity_time_slot: [
                {
                    time: '',
                    price: ''
                }
            ],

            // activity_features: [
            //   {
            //     title: '',
            //     image: '',
            //     imageUrl: 'imageUrl',
            //   }
            // ],
            // traveller_information: {
            //   title: '',
            //   height: '',
            //   weight: '',
            //   age: '',
            //   gender: ''

            // },
            way_of_reach: [
                {
                    vehicle_type: '',
                    description: ''
                }
            ],
            // varient_offer: '',
            // physical_restriction: [],

            // Others

            // attraction: '',
            // activity_time: '',
            // unique_experience: '',
            // traveller_des: '',
            // number_of_stops: '',
            // name_of_stops: [''],
            // guest_bringing: '',
            // people_understand_language: '',
            ticket_count: '',
            // experience_group: '',
            // traveler_enjoy_experience_group_id: '',
            // traveler_book_tickets: '',
            base_price: '',
            child_price: '',
            // best_experience_time: '',
            // cancellation_type: '',
            // cancel_a_booking: '',
            // traveller_separte_ticket: '',
            // price_your_product: '',
            maximum_count_a_group: '',
            // advanced_notice_for_a_booking: '',
            // ticket_offer: false,
            // offer_guide: false,
            // ticket_line_access: false,
            // is_private_activity: false,
            // license_required: false,
            // license_certified: false,
            // activity_description: '',
            latitude: "",
            longitude: "",
            includes: "",
            information: "",
            description: "",
            itinerary: "",

            // is_offer: false,
            // offer_percentage: '',
            taxi_price: '',
            slider_images: [{
                slider_image: "",
                slider_image_url: "",
            }],
            activity_date_off: [{
                date_off: '',
            }]
        },
        categoryList: [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' },
        ],
        activityIconOptions: [{
            value: 'language', label: 'Language'
        }, {
            value: 'time', label: 'Time'
        }, {
            value: 'ticket', label: 'Ticket'
        }, {
            value: 'price', label: 'Price'
        }, {
            value: 'cancel', label: 'Cancel'
        }],
        daysList: [],
        activityTimeList: [
            {
                "id": "1",
                "label": "1 hours",
                "value": "1hours"
            },
            {
                "id": "2",
                "label": "2 hours",
                "value": "2hours"
            },
            {
                "id": "3",
                "label": "3 hours",
                "value": "3hours"
            },
            {
                "id": "4",
                "label": "6 hours",
                "value": "6hours"
            },
            {
                "id": "5",
                "label": "8 hours",
                "value": "8hours"
            },
            {
                "id": "6",
                "label": "Full day (Pick from ride-sharing)",
                "value": "Fullday(Pick-from-ride-sharing)"
            }
        ],
        genderList: [
            {
                label: "Male",
                value: "male"
            },
            {
                label: "Female",
                value: "female"
            },
            {
                label: "Others",
                value: "others"
            }
        ],

        // variantOfferList: [
        //   {
        //     label: "Entrance ticket",
        //     value: "entrance_ticket"
        //   },
        //   {
        //     label: "Entrance with Guide",
        //     value: "entrance_with_guide"
        //   },
        //   {
        //     label: "Entrance with Private guide ",
        //     value: "entrance_with_private_guide"
        //   }
        // ],
        // physicalRestrictionList: [],
        activeTimeOptions: [{
            label: 'Less than 1 hour',
            value: '1 hr'
        }, {
            label: '1-2 Hours',
            value: '1-2 hrs'
        }, {
            label: 'More than 3 hours',
            value: '3 hrs'
        }],
        // experienceGroupOptions: [],
        // travelerEnjoyExperienceOptions: [],
        bestExperienceTimeOptions: [{
            label: 'Start time',
            value: "start_time",
        }, {
            label: 'Opening hours',
            value: "opening_time",
        }, {
            label: 'Cordinates start times',
            value: "cordinates_start_times",
        }],
        // priceYourProduct: [
        //   {
        //     label: 'Per person',
        //     value: "per_person",
        //   },
        //   {
        //     label: 'Per vehicle/group',
        //     value: "per_vehicle_group",
        //   }],
        isError: false,
        isOpen: false,
        isEdit: false,
        tempFile: '',
        deleteKey: '',
        ConfirmModalOpenStatus: false,
    }


    componentWillMount() {
        console.log("sasasasasasa");


        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message font-md">{message}</span>,
            autoForceUpdate: this,
            messages: {
                int: 'this :attribute required here'
            },

            validators: {
                endDateRequired: { // name the rule
                    message: "The :attribute must be at least 15 minutes longer than start time",
                    rule: (val, params) => validateEndDate(val, params)
                },
                validDate: {
                    message: "Enter a valid Date",
                    rule: (val) => {
                        return moment(val, 'MM/DD/YYYY hh:mm a', true).isValid() ||
                            moment(val, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
                            moment(val, 'YYYY-MM-DDTHH:mm:ss.000Z', true).isValid()
                    }
                }

            }
        });

        this.validators = new SimpleReactValidator({
            autoForceUpdate: this,
            element: message => {
                let messages = message.split('/');
                return (
                    <span className="error-message font-md">
                        {`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
                    </span>
                )
            },

        });

    }


    componentDidMount() {
        let { daysList, activityDropDownLists: { ExperienceGroup = [], TravelEnjoyExperience = [], PhysicalRestriction = [] }, history: { location } } = this.props

        // if (location.search) {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        let search = location.search.split('=')

        // let id = search[1]

        let { match: { params: { id } } } = this.props;

        if (id) {

            this.props.getActivityDetailsById(id).then((data) => {

                let {
                    id,
                    title,
                    ActivityImage,
                    ActivityDates,
                    ActivityDetails,
                    ActivityOptions,
                    ActivityTimeSlot,
                    WayOfReach,
                    ticket_count,
                    base_price,
                    child_price,
                    maximum_count_a_group,
                    latitude,
                    longitude,
                    includes,
                    information,
                    description,
                    itinerary,
                    taxi_price,
                    // SliderImages,
                    ActivitySliderImage,
                    ActivityDateOff,
                } = data;

                let activity_options = [];
                let activity_details = [];
                let activity_time_slot = [];
                let way_of_reach = [];
                let slider_images = [];
                let activity_date_off = [];

                if (ActivityDateOff && ActivityDateOff.length) {

                    ActivityDateOff.map((item) => {
                        activity_date_off.push({
                            activity_date_off_id: item.id,
                            date_off: item.date_off,
                            deleted: false
                        })

                    })

                } else {

                    activity_date_off.push({
                        date_off: "",
                        deleted: false
                    })

                }

                if (ActivitySliderImage && ActivitySliderImage.length) {
                    ActivitySliderImage.map((item) => {
                        slider_images.push({
                            slider_image: item.slider_image,
                            slider_image_url: item.slider_image_url,
                            slider_image_id: item.id,
                            deleted: false
                        })
                    })

                } else {

                    slider_images.push({
                        slider_image: '',
                        slider_image_url: '',
                        deleted: false,
                    })

                }

                if (ActivityOptions) {

                    ActivityOptions.map((item) => {

                        activity_options.push({
                            title: item.title,
                            price: Number(item.price),
                            option_id: item.id,
                            deleted: false
                        })

                    })

                } else {

                    activity_options.push({
                        title: '',
                        price: '',
                        deleted: false
                    })

                }

                if (ActivityDetails) {

                    ActivityDetails.map((item) => {

                        activity_details.push({
                            icon: item.icon,
                            title: item.title,
                            description: item.description,
                            deleted: false
                        })

                    })

                } else {

                    activity_details.push({
                        icon: "",
                        title: "",
                        description: "",
                        deleted: false
                    })

                }

                if (ActivityTimeSlot) {

                    ActivityTimeSlot.map((item) => {

                        activity_time_slot.push({
                            time_slot_id: item.id,
                            time: item.activity_time,
                            price: Number(item.add_on_price),
                            deleted: false
                        })

                    })

                } else {

                    activity_time_slot.push({
                        time: "",
                        price: "",
                        deleted: false
                    })

                }

                if (WayOfReach) {

                    WayOfReach.map((item) => {

                        way_of_reach.push({
                            vehicle_type: item.vechile_type,
                            description: item.description,
                            activity_way_id: item.id,
                            deleted: false
                        })

                    })

                } else {

                    way_of_reach.push({
                        vehicle_type: '',
                        description: '',
                        deleted: false
                    })

                }

                activityDetails = {
                    // id,
                    title,
                    activity_image: ActivityImage.image,
                    activity_imageUrl: ActivityImage.imageUrl,
                    activity_image_id: ActivityImage.id,
                    activity_date_id: ActivityDates.id,
                    activity_start_date: ActivityDates.start_date,
                    activity_end_date: ActivityDates.end_date,
                    activity_off_day: ActivityDates.offDay,
                    activity_options,
                    activity_details,
                    activity_time_slot,
                    way_of_reach,
                    ticket_count,
                    base_price: Number(base_price),
                    child_price: Number(child_price),
                    maximum_count_a_group,
                    latitude: latitude.toString(),
                    longitude: longitude.toString(),
                    includes,
                    information,
                    description,
                    itinerary,
                    taxi_price: Number(taxi_price),
                    slider_images,
                    activity_date_off,
                }

                this.setState({
                    activityDetails
                })

            })

        }

        // }

        this.setState({
            daysList,
        })


    }

    ImagePreview = (key, event) => {

        let files = event.target.files[0]

        let activityDetails = Object.assign({}, this.state.activityDetails)

        activityDetails[key] = files

        let formData = new FormData()

        formData.append("activityImage", files)

        this.props.addImage(formData).then((data) => {

            activityDetails[key] = data.image

            activityDetails.activity_imageUrl = data.imageUrl

        })

        this.setState({
            tempFile: URL.createObjectURL(files),
            activityDetails,
        });

    }

    handleDeleteToggle = (key, index) => {

        let { activityDetails } = Object.assign({}, this.state)

        // activityDetails[key].splice(index, 1)
        if (activityDetails[key].length) {
            activityDetails[key].map((x, i) => {
                if (i === index) {
                    x.deleted = true
                }
            })
        }

        this.setState({
            activityDetails
        })

    }


    handleDelete = () => {

        let { activityDetails, deleteKey } = Object.assign({}, this.state)

        activityDetails[deleteKey] = ""

        this.setState({
            activityDetails
        })

        this.toggleDelete("ConfirmModalOpenStatus")

    }

    toggleDelete = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }



    handleInputs = ({ value, name }) => {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        if (name === "base_price" || name === "child_price" || name === "maximum_count_a_group") {

            activityDetails[name] = value === "" ? "" : Number(value)

        }
        // else if (name === "offer_percentage") {

        //   activityDetails[name] = value <= 100 && value >= 0 ? value : activityDetails[name];

        // }
        else {

            activityDetails[name] = value

        }

        // if (name == "number_of_stops") {

        //   activityDetails.name_of_stops = [];

        //   for (let i = 0; i < value; i++) {

        //     activityDetails.name_of_stops.push('')

        //   }
        // }

        this.setState({

            activityDetails

        })


    }



    handlePluginInput = (keyName, data) => {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        activityDetails[keyName] = data

        this.setState({

            activityDetails

        })

    }


    handleMultipleInputs = (key, data, index) => {

        let { name, value } = data
        let activityDetails = Object.assign({}, this.state.activityDetails)

        if (name === "price") {

            activityDetails[key][index][name] = value === "" ? "" : Number(value)

        } else {

            activityDetails[key][index][name] = value

        }

        this.setState({
            activityDetails
        })


    }


    handleSubmit = () => {
        if (this.validator.allValid() && this.validators.allValid()) {
            let { activityDetails } = { ...this.state }
            if (activityDetails.activity_date_off && activityDetails.activity_date_off.length) {
                activityDetails.activity_date_off.map(x => {
                    x.date_off = moment(x.date_off).format('YYYY-MM-DD')
                })
            }
            activityDetails.activity_start_date = moment(activityDetails.activity_start_date).format('YYYY-MM-DD')
            activityDetails.activity_end_date = moment(activityDetails.activity_end_date).format('YYYY-MM-DD')
            let { match: { params: { id } } } = this.props;

            if (!id) {
                let body = {
                    ...activityDetails
                }
                this.props.createActivity(body).then((data) => {
                    let { history } = this.props
                    history.push('/activity/list')
                })

            } else {

                let {
                    title,
                    ticket_count,
                    base_price,
                    maximum_count_a_group,
                    description,
                    child_price,
                    offer_percentage,
                    includes,
                    information,
                    itinerary,
                    latitude,
                    longitude,
                    taxi_price,
                    activity_image,
                    activity_imageUrl,
                    activity_image_id,
                    activity_options,
                    activity_time_slot,
                    activity_date_id,
                    activity_start_date,
                    activity_end_date,
                    activity_off_day,
                    activity_detail,
                    way_of_reach,
                    slider_images,
                    activity_date_off,
                } = { ...activityDetails };
                if (way_of_reach && way_of_reach.length) {
                    way_of_reach = way_of_reach.map((x, i) => {
                        let data = { ...x }
                        data.vechile_type = data.vehicle_type
                        delete data.vehicle_type
                        return data
                    })
                }

                // delete way_of_reach.vehicle_type
                let body = {
                    activity: {
                        title,
                        ticket_count,
                        base_price,
                        maximum_count_a_group,
                        description,
                        child_price,
                        offer_percentage,
                        includes,
                        information,
                        itinerary,
                        latitude,
                        longitude,
                        taxi_price,
                    },
                    activity_date_off,
                    activity_image: {
                        activity_image,
                        activity_imageUrl,
                        activity_image_id,
                    },
                    activity_option: activity_options,
                    activity_time_slot,
                    activity_date: {
                        activity_date_id,
                        activity_start_date,
                        activity_end_date,
                        activity_off_day,
                    },
                    activity_detail,
                    way_of_reach,
                    slider_images,

                }

                this.props.updateActivity(id, body).then((data) => {

                    let { history } = this.props
                    history.push('/activity/list')

                })

            }

            this.validator.hideMessages();

            this.validators.hideMessages();

        } else {

            this.setState({
                isError: true
            })
            this.validator.showMessages();
            this.validators.showMessages();

        }
    }

    handleAddMultiple = (key) => {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        if (key == "activity_options") {

            let activity_options = activityDetails[key].slice(0)

            let errorStatus = activity_options.every((e, index) => {

                let err1 = this.validators.fieldValid(`/title/${index}/`)

                let error = this.validators.fieldValid(`/price/${index}/`)

                if (!err1 && !error) {
                    this.validators.showMessageFor(`/title/${index}/`)
                    this.validators.showMessageFor(`/price/${index}/`)
                    return false
                }

                return true

            })

            if (errorStatus) {
                activityDetails[key].push({
                    title: '',
                    price: ''
                })
            }



        } else if (key == "activity_time_slot") {

            let activity_time_slot = activityDetails[key].slice(0)

            let errorStatus = activity_time_slot.every((e, index) => {

                let err1 = this.validators.fieldValid(`/time/${index}/`)

                let error = this.validators.fieldValid(`/activity_time_price/${index}/`)

                if (!err1 && !error) {
                    this.validators.showMessageFor(`/time/${index}/`)
                    this.validators.showMessageFor(`/activity_time_price/${index}/`)
                    return false
                }

                return true

            })

            if (errorStatus) {
                activityDetails[key].push({
                    time: '',
                    price: ''
                })

            }

        } else if (key == "way_of_reach") {

            let way_of_reach = activityDetails[key].slice(0)

            let errorStatus = way_of_reach.every((e, index) => {

                let err1 = this.validators.fieldValid(`/vehicle_type/${index}/`)

                let error = this.validators.fieldValid(`/description/${index}/`)

                if (!err1 && !error) {
                    this.validators.showMessageFor(`/vehicle_type/${index}/`)
                    this.validators.showMessageFor(`/description/${index}/`)
                    return false
                }

                return true

            })

            if (errorStatus) {
                activityDetails[key].push({
                    vehicle_type: '',
                    description: ''
                })

            }

        } else if (key == "activity_date_off") {

            let activity_date_off = activityDetails[key].slice(0);

            let errorStatus = activity_date_off.every((e, index) => {

                let err1 = this.validators.fieldValid(`/activity_date_off/${index}/`);

                if (!err1) {

                    this.validators.showMessageFor(`/activity_date_off/${index}/`);

                    return false;

                }

                return true;

            })

            if (errorStatus) {

                activityDetails[key].push({
                    date_off: '',
                })

            }

        }

        this.setState({
            activityDetails
        })

    }




    handleDataObject = (key, data) => {

        let { activityDetails } = this.state

        activityDetails = Object.assign({}, activityDetails)

        activityDetails[key] = data

        this.setState({ activityDetails })

    }


    handleCheckbox = ({ target: { value, checked } }) => {

        value = parseInt(value)

        let { activityDetails } = Object.assign({}, this.state)

        // let index = activityDetails.physical_restriction.indexOf(value)

        // if (checked) {

        //   activityDetails.physical_restriction.push(value)

        // } else {

        //   activityDetails.physical_restriction.splice(index, 1)

        // }

        this.setState({ activityDetails })


    }

    handleArrayInputs = (name, value, index) => {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        activityDetails[name][index] = value

        this.setState({
            activityDetails
        })

    }

    // handleAddStop = () => {

    //   let activityDetails = Object.assign({}, this.state.activityDetails)

    //   let stopLength = activityDetails.name_of_stops.length;

    //   if (stopLength < Number(activityDetails.number_of_stops)) {

    //     activityDetails.name_of_stops.push('')

    //   }

    //   this.setState({
    //     activityDetails
    //   })

    // }

    // handleToggleCheckbox = (key) => {

    //   let activityDetails = Object.assign({}, this.state.activityDetails)

    //   activityDetails[key] = !activityDetails[key]

    //   if (key === "is_offer") {

    //     if (activityDetails[key]) {

    //       delete activityDetails.offer_percentage;

    //     } else {

    //       activityDetails.offer_percentage = "";

    //     }

    //   }

    //   this.setState({
    //     activityDetails
    //   })

    // }

    handleActivityDetails = (index, { name, value }) => {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        activityDetails.activity_details[index][name] = value;

        this.setState({
            activityDetails
        })

    }

    handleAddActivityDetails = () => {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        let activityDetailsLength = activityDetails.activity_details.length;

        if (activityDetailsLength < 5) {

            let errorStatus = activityDetails.activity_details.every((e, index) => {

                let error1 = this.validators.fieldValid(`/title/${index}/`)

                let error2 = this.validators.fieldValid(`/icon/${index}/`)

                let error3 = this.validators.fieldValid(`/description/${index}/`)

                if (!error1 && !error2 && !error3) {

                    this.validators.showMessageFor(`/title/${index}/`)

                    this.validators.showMessageFor(`/icon/${index}/`)

                    this.validators.showMessageFor(`/description/${index}/`)

                    return false
                }

                return true

            })

            if (errorStatus) {

                activityDetails.activity_details.push({
                    icon: '',
                    title: '',
                    description: '',
                });

            }

        }

        this.setState({
            activityDetails
        })

    }

    handleDeleteActivityDetails = (index) => {

        let activityDetails = Object.assign({}, this.state.activityDetails)

        // activityDetails.activity_details.splice(index, 1);
        activityDetails.activity_details.map((x, i) => {
            if (index === i) {
                x.deleted = true
            }
        })

        this.setState({
            activityDetails
        })

    }

    handleLocation = (place) => {

        let { geometry = {} } = place;

        let { location } = geometry;

        let activityDetails = Object.assign({}, this.state.activityDetails)

        if (location) {

            activityDetails.latitude = location.lat().toString();

            activityDetails.longitude = location.lng().toString();

        }

        this.setState({
            activityDetails
        })

    }

    handleAddSliderImage = () => {

        let activityDetails = Object.assign({}, this.state.activityDetails);

        let errorStatus = activityDetails.slider_images.every((e, index) => {

            let error1 = this.validators.fieldValid(`/SliderImage/${index}/`)

            if (!error1) {

                this.validators.showMessageFor(`/SliderImage/${index}/`)

                return false

            }

            return true

        })

        if (errorStatus) {

            activityDetails.slider_images.push({
                slider_image: "",
                slider_image_url: "",
            })

            this.setState({
                activityDetails,
            })

        }

    }

    handleSliderImage = (index, event) => {

        let files = event.target.files[0]

        let activityDetails = _.cloneDeep(this.state.activityDetails)

        let formData = new FormData()

        formData.append("activitySliderImage", files)

        this.props.createSliderActivityImage(formData).then(({ slider_image, slider_image_url }) => {

            activityDetails.slider_images[index].slider_image = slider_image;

            activityDetails.slider_images[index].slider_image_url = slider_image_url;

            this.setState({
                activityDetails
            })

        })

    }

    handleMultiplePluginInput = (name, index, date) => {

        let activityDetails = Object.assign({}, this.state.activityDetails);

        activityDetails[name][index].date_off = date;

        this.setState({
            activityDetails
        })

    }

    render() {

        let {
            bestExperienceTimeOptions,
            // experienceGroupOptions,
            activeTimeOptions,
            activityDetails,
            daysList,
            activityTimeList,
            genderList,
            // variantOfferList,
            // physicalRestrictionList,
            // travelerEnjoyExperienceOptions,
            // priceYourProduct,
            tempFile,
            ConfirmModalOpenStatus,
            activityIconOptions,
        } = this.state



        let {
            title,
            activity_image,
            activity_start_date,
            activity_end_date,
            // activity_start_age,
            // activity_end_age,
            activity_off_day,
            activity_options,
            activity_time_slot,
            // activity_features,
            // traveller_information,
            way_of_reach,
            // varient_offer,
            // physical_restriction,
            // attraction,
            // activity_time,
            // unique_experience,
            // traveller_des,
            // number_of_stops = [],
            // name_of_stops,
            // guest_bringing,
            // people_understand_language,
            ticket_count,
            // experience_group,
            // traveler_enjoy_experience_group_id,
            // traveler_book_tickets,
            base_price,
            child_price,
            // best_experience_time,
            // cancellation_type,
            // cancel_a_booking,
            // traveller_separte_ticket,
            // price_your_product,
            maximum_count_a_group,
            // advanced_notice_for_a_booking,
            // ticket_offer,
            // offer_guide,
            // ticket_line_access,
            // is_private_activity,
            // license_required,
            // license_certified,
            // activity_description,
            activity_details,
            includes,
            information,
            description,
            itinerary,
            latitude,
            longitude,
            // is_offer,
            // offer_percentage,
            taxi_price,
            slider_images,
            activity_date_off,
            activity_imageUrl,
        } = activityDetails

        return (
            <fieldset disabled={this.state.disabled}>
                <div>
                    <div className="sair-pg-header">
                        <h4 className="sair-pg-title align-self-center">
                            <span className="pg-title-icon">
                                <span className="feather-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" className="feather feather-align-left">
                                        <line x1="17" y1="10" x2="3" y2="10"></line>
                                        <line x1="21" y1="6" x2="3" y2="6"></line>
                                        <line x1="21" y1="14" x2="3" y2="14"></line>
                                        <line x1="17" y1="18" x2="3" y2="18"></line>
                                    </svg>
                                </span>
                            </span>Activity
					</h4>

                    </div>
                    <section className="sair-sec-wrapper">

                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label>Activity Title</label>
                                <NormalInput
                                    className="form-control"
                                    name="title"
                                    type="text"
                                    value={title}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('Activity title', title, 'required')}
                                    number={false}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label >Activity Images</label>
                                <FileUpload
                                    fileUpload={this.fileUpload}
                                    ImagePreview={(e) => this.ImagePreview("activity_image", e)}
                                    filename={activity_image}
                                    tempFile={tempFile}
                                />
                                <img src={activity_imageUrl} style={{ width: "100px", marginTop: "15px" }} />
                                {this.validator.message('image', activity_image, 'required')}
                            </div>
                            <div className="col-md-6 form-group">
                                <label  >Latitude</label>
                                <NormalInput
                                    className="form-control"
                                    name="latitude"
                                    type="number"
                                    value={latitude}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('Latitude', latitude, 'required')}
                                    number={false}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label  >Longitude</label>
                                <NormalInput
                                    className="form-control"
                                    name="longitude"
                                    type="number"
                                    value={longitude}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('Longitude', longitude, 'required')}
                                    number={false}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label  >Activity Description</label>


                                <TextEditor
                                    handleTextEditor={html => {
                                        this.handleInputs({ name: 'description', value: html })
                                    }}
                                    html={description}
                                    disabled={false}
                                // maxChar={250}
                                />
                                {this.validator.message('activity description', description, 'required')}
                            </div>

                            <div className="col-md-6 form-group">
                                <label  >Includes</label>
                                <TextEditor
                                    handleTextEditor={html => {
                                        this.handleInputs({ name: 'includes', value: html })
                                    }}
                                    html={includes}
                                    disabled={false}
                                // maxChar={250}
                                />
                                {this.validator.message('includes', includes, 'required')}
                            </div>
                            <div className="col-md-6 form-group">
                                <label  >Information</label>
                                <TextEditor
                                    handleTextEditor={html => {
                                        this.handleInputs({ name: 'information', value: html })
                                    }}
                                    html={information}
                                    disabled={false}
                                // maxChar={250}
                                />
                                {this.validator.message('information', information, 'required')}
                            </div>
                            <div className="col-md-6 form-group">
                                <label  >Itinerary</label>
                                <TextEditor
                                    handleTextEditor={html => {
                                        this.handleInputs({ name: 'itinerary', value: html })
                                    }}
                                    html={itinerary}
                                    disabled={false}
                                // maxChar={250}
                                />
                                {this.validator.message('itinerary', itinerary, 'required')}
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row mb-15">
                                    <div className="col-sm-6 d-flex align-items-end">

                                        <h6 className="">Slider Images</h6>
                                        <div className="ml-auto">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={this.handleAddSliderImage}
                                            >
                                                Add Slider Image
                    </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">

                                    {slider_images.filter(x => !x.deleted).map((item, index) => {

                                        let {
                                            slider_image,
                                            slider_image_url,
                                        } = item;

                                        return (
                                            <div className="col-sm-6 mb-3" key={index}>
                                                <div className="row">
                                                    <div className={index !== 0 ? "col-sm-11" : "col-sm-12"}>
                                                        <FileUpload
                                                            fileUpload={this.fileUpload}
                                                            ImagePreview={(e) => this.handleSliderImage(index, e)}
                                                            filename={slider_image}
                                                        // tempFile={tempFile}
                                                        />
                                                        {slider_image_url ? <img src={slider_image_url} style={{ width: "100px", marginTop: "15px" }} /> : ""}
                                                    </div>
                                                    {index !== 0 ?
                                                        <div className="col-md-1 pt-2">
                                                            <i
                                                                className="icon-trash text-danger cursor-pointer"
                                                                onClick={e => this.handleDeleteToggle("slider_images", index)}
                                                            ></i>
                                                        </div>
                                                        : ""}
                                                </div>
                                                {item ? this.validators.message(`/SliderImage/${index}/`, slider_image, 'required') : ""}
                                            </div>
                                        )

                                    })}
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-sm-6">
                                <h6 className="mb-15">Activity Details</h6>
                            </div>
                            <div className="col-sm-6 text-right">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={this.handleAddActivityDetails}
                                >
                                    Add Details
              </button>
                            </div>
                        </div>
                        {activity_details.filter(x => !x.deleted).map((item, index) => {

                            let {
                                icon,
                                title,
                                description,
                            } = item;

                            return (

                                <>
                                    <div className="row">
                                        <div className="col-md-11">
                                            <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <label>Title {index + 1}</label>
                                                    <NormalInput
                                                        className="form-control"
                                                        name="title"
                                                        type="text"
                                                        value={title}
                                                        onChange={({ name, value }) => {
                                                            this.handleActivityDetails(index, { name: name, value: value })
                                                        }}
                                                        validationError={this.validators.message(`/title/${index}/`, title, 'required')}
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label>Icon {index + 1}</label>
                                                    <ReactSelect
                                                        isMulti={false}
                                                        options={activityIconOptions}
                                                        handleChange={(keyName, data) => {
                                                            this.handleActivityDetails(index, { name: keyName, value: data })
                                                        }}
                                                        keyName="icon"
                                                        value={icon}
                                                        disabled={false}
                                                    />
                                                    {this.validators.message(`/icon/${index}/`, icon, 'required')}
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <label>Description {index + 1}</label>
                                                    <TextEditor
                                                        handleTextEditor={html => {
                                                            this.handleActivityDetails(index, { name: 'description', value: html })
                                                        }}
                                                        html={description}
                                                        disabled={false}
                                                    />
                                                    {this.validators.message(`/description/${index}/`, description, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 pb-3 align-self-end text-center">
                                            {index !== 0 ?
                                                < i
                                                    className="icon-trash text-danger cursor-pointer"
                                                    onClick={() => this.handleDeleteActivityDetails(index)}
                                                ></i>
                                                : ""}
                                        </div>
                                    </div>
                                </>

                            )

                        })}


                        <hr />


                        <h6 className="mb-15">Activity Date</h6>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label  >Activity start date</label>
                                <DateTime
                                    handleChange={(keyName, data) => {
                                        this.handleInputs({ name: keyName, value: data })
                                    }}
                                    name="activity_start_date"
                                    value={activity_start_date}
                                    isValidDate={hidePastDate}
                                />
                                {this.validator.message('Activity start date', activity_start_date, 'required')}
                            </div>
                            <div className="col-md-6 form-group">
                                <label  >Activity End date</label>
                                <DateTime
                                    handleChange={this.handlePluginInput}
                                    name="activity_end_date"
                                    value={activity_end_date}
                                    isValidDate={hidePastDate}
                                />
                                {this.validator.message('Activity End date', activity_end_date, 'required')}
                            </div>
                        </div>

                        <hr />
                        {/* <h6 className="mb-15">Activity Group Age</h6>
          <div className="row">
            <div className="col-md-6 form-group">
              <label  >start age</label>
              <NormalInput
                className="form-control"
                name="activity_start_age"
                type="number"
                value={activity_start_age}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('start age', activity_start_age, 'required|numeric')}
              />
            </div>
            <div className="col-md-6 form-group">
              <label >End age</label>
              <NormalInput
                className="form-control"
                type="number"
                name="activity_end_age"
                value={activity_end_age}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('end age', activity_end_age, 'required|numeric')}
              />

            </div>
          </div> */}


                        <h6 className="mb-15">Activity Off day</h6>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label>Days</label>
                                <ReactSelect
                                    isMulti={true}
                                    options={daysList}
                                    handleChange={(keyName, data) => {
                                        this.handleInputs({ name: keyName, value: data })
                                    }}
                                    keyName="activity_off_day"
                                    value={activity_off_day}
                                    disabled={false}
                                />
                                {this.validator.message('Days', activity_off_day, 'required')}
                            </div>
                        </div>

                        <hr />

                        <div>
                            <h6 className="mb-15 row">
                                <div className="col-sm-6">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">Activity Off date</div>
                                        <div className="col-sm-6 text-right">
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => this.handleAddMultiple('activity_date_off')}
                                            >
                                                Add options
                    </button>
                                        </div>
                                    </div>
                                </div>
                            </h6>
                            <div className="row">
                                {activity_date_off.filter(x => !x.deleted).map((item, index) => {

                                    let { date_off } = item;

                                    console.log(date_off, 'asdasdaisduaisdo87a9s8d78a9d78asd')

                                    return (

                                        <div key={index} className="col-sm-6 mb-3">

                                            <div className="row">

                                                <div className={index === 0 ? "col-sm-12" : "col-sm-11"}>

                                                    <DateTime
                                                        handleChange={(name, date) => this.handleMultiplePluginInput("activity_date_off", index, date)}
                                                        name="date_off"
                                                        value={date_off}
                                                        isValidDate={hidePastDate}
                                                    />

                                                </div>

                                                {index > 0 &&

                                                    <div className="col-md-1 align-self-center">
                                                        <i
                                                            className="icon-trash text-danger cursor-pointer"
                                                            onClick={e => this.handleDeleteToggle("activity_date_off", index)}
                                                        ></i>
                                                    </div>

                                                }

                                            </div>

                                            {this.validators.message(`/activity_date_off/${index}/`, date_off, 'required')}

                                        </div>

                                    )

                                })}
                            </div>

                        </div>


                        <hr />
                        <h6 className="mb-15 row">
                            <div className="col-sm-6"> Activity Options </div>
                            <div className="col-sm-6 text-right">
                                <button className="btn btn-primary btn-sm"
                                    onClick={() => this.handleAddMultiple('activity_options')}
                                >
                                    Add options
              </button>
                            </div>
                        </h6>
                        {activity_options.filter(x => !x.deleted).map((item, index) => {

                            let { title, price } = item

                            return (
                                <>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label >Title {index + 1} </label>
                                            <NormalInput
                                                className="form-control"
                                                type="text"
                                                name="title"
                                                value={title}
                                                onChange={(data) => {
                                                    this.handleMultipleInputs("activity_options", data, index)
                                                }}
                                                number={false}
                                            />
                                            {this.validators.message(`/title/${index}/`, title, 'required')}

                                        </div>
                                        <div className="col-md-5 form-group">
                                            <label >Price {index + 1}</label>
                                            <NormalInput
                                                className="form-control"
                                                type="number"
                                                name="price"
                                                value={price}
                                                onChange={(data) => {
                                                    this.handleMultipleInputs("activity_options", data, index)
                                                }}
                                            />
                                            {this.validators.message(`/price/${index}/`, price, 'required')}
                                        </div>
                                        {index > 0 &&
                                            <div className="col-md-1 align-self-center pt-3">
                                                <i
                                                    className="icon-trash text-danger cursor-pointer"
                                                    onClick={e => this.handleDeleteToggle("activity_options", index)}
                                                ></i>
                                            </div>
                                        }
                                    </div>

                                </>
                            )

                        })}
                        <hr />

                        <h6 className="mb-15 row">
                            <div className="col-sm-6"> Activity time slot </div>
                            <div className="col-sm-6 text-right">
                                <button className="btn btn-primary btn-sm"
                                    onClick={() => this.handleAddMultiple('activity_time_slot')}
                                >

                                    Add Activity time slot 	</button>
                            </div>
                        </h6>
                        {activity_time_slot.filter(x => !x.deleted).map((item, index) => {

                            let { time, price } = item

                            return (
                                <>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label >Title {index + 1} </label>
                                            {/* <ReactSelect
                      isMulti={false}
                      options={activityTimeList}
                      handleChange={(name, value) => {
                        this.handleMultipleInputs("activity_time_slot", { name, value }, index)
                      }}
                      keyName="time"
                      value={time}
                      disabled={false}
                    /> */}
                                            <NormalInput
                                                className="form-control"
                                                type="text"
                                                name="time"
                                                value={time}
                                                onChange={({ name, value }) => {

                                                    this.handleMultipleInputs("activity_time_slot", { name, value }, index)

                                                }}
                                            />
                                            {this.validators.message(`/time/${index}/`, time, 'required')}
                                        </div>
                                        <div className="col-md-5 form-group">
                                            <label >Price {index + 1}</label>
                                            <NormalInput
                                                className="form-control"
                                                type="number"
                                                name="price"
                                                value={price}
                                                onChange={(data) => {
                                                    this.handleMultipleInputs("activity_time_slot", data, index)
                                                }}

                                            />
                                            {this.validators.message(`/activity_time_price/${index}/`, price, 'required')}
                                        </div>
                                        {index > 0 &&
                                            <div className="col-md-1 align-self-center pt-3">
                                                <i
                                                    className="icon-trash text-danger cursor-pointer"
                                                    onClick={e => this.handleDeleteToggle("activity_time_slot", index)}
                                                ></i>
                                            </div>
                                        }
                                    </div>

                                </>
                            )

                        })}

                        {/* <hr />


          <ActivityFeatures
            details={activity_features}
            ImagePreview={this.ImagePreview}
            tempFile={tempFile}
            addImage={this.props.addImage}
            handleChange={(data) =>
              this.handleDataObject("activity_features", data)
            }
            disabled={false}
            validator={this.validators}
          /> */}



                        {/* <hr />
          <h6 className="mb-15 align-items-center justify-content-between">
            <div>  Traveller information  </div>
          </h6>

          <TravellerInformation
            details={traveller_information}
            genderList={genderList}
            handleChange={(data) =>
              this.handleDataObject("traveller_information", data)
            }
            disabled={false}
            validator={this.validator}
          /> */}

                        <hr />

                        <h6 className="mb-15 row">
                            <div className="col-sm-6"> Way of reach </div>
                            <div className="col-sm-6 text-right">
                                <button className="btn btn-primary btn-sm"
                                    onClick={() => this.handleAddMultiple('way_of_reach')}
                                >

                                    Add Way of reach 	</button>
                            </div>
                        </h6>


                        {way_of_reach.filter(x => !x.deleted).map((item, index) => {

                            let { vehicle_type, description } = item

                            return (
                                <>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label  >Vechile type</label>
                                            <NormalInput
                                                className="form-control"
                                                name="vehicle_type"
                                                type="text"
                                                value={vehicle_type}
                                                onChange={(data) => {
                                                    this.handleMultipleInputs("way_of_reach", data, index)
                                                }}
                                                number={false}
                                            />
                                            {this.validators.message(`/vehicle_type/${index}/`, vehicle_type, 'required')}
                                        </div>
                                        <div className="col-md-5 form-group">
                                            <label  >Description</label>
                                            <NormalInput
                                                className="form-control"
                                                name="description"
                                                type="text"
                                                value={description}
                                                onChange={(data) => {
                                                    this.handleMultipleInputs("way_of_reach", data, index)
                                                }}
                                                number={false}
                                            />
                                            {this.validators.message(`/description/${index}/`, description, 'required')}
                                        </div>
                                        {index > 0 &&
                                            <div className="col-md-1 align-self-center pt-3">
                                                <i
                                                    className="icon-trash text-danger cursor-pointer"
                                                    onClick={e => this.handleDeleteToggle("way_of_reach", index)}
                                                ></i>
                                            </div>
                                        }
                                    </div>
                                </>
                            )


                        })}



                        {/* <hr />
          <h6 className="mb-15 align-items-center justify-content-between">
            <div> variant offer </div>
          </h6>
          <div className="row">
            <div className="col-md-6 form-group">
              <label >Vehicle type</label>
              <ReactSelect
                isMulti={false}
                options={variantOfferList}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="varient_offer"
                value={varient_offer}
                disabled={false}
              />

              {this.validator.message('Vehicle type', varient_offer, 'required')}
            </div>
          </div> */}

                        {/* <hr />
          <h6 className="mb-15 align-items-center justify-content-between">
            <div> Physical Restriction </div>
          </h6>
          <div className="row">
            <div className="col-md-6 form-group">
              <label >Physical Restriction</label>

              <ReactSelect
                isMulti={true}
                options={physicalRestrictionList}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="physical_restriction"
                value={physical_restriction}
                disabled={false}
              />
              {this.validator.message('Vehicle type', physical_restriction, 'required')}

            </div>
          </div> */}
                        <hr />
                        <h6 className="mb-15 align-items-center justify-content-between">
                            <div> Others </div>
                        </h6>
                        {/* <div className="row"> */}
                        {/* <div className="col-md-6 form-group">
              <label>Attraction</label>

              <NormalInput
                className="form-control"
                type="text"
                name="attraction"
                value={attraction}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Attraction', attraction, 'required')}
                number={false}
              />

            </div> */}
                        {/* <div className="col-md-6 form-group">
              <label>Active time</label>

              <ReactSelect
                isMulti={false}
                options={activeTimeOptions}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="activity_time"
                value={activity_time}
                disabled={false}
              />
              {this.validator.message('activity_time', activity_time, 'required')}
            </div> */}
                        {/* <div className="col-md-6 form-group">
              <label>Unique Experience</label>
              <NormalInput
                className="form-control"
                type="text"
                name="unique_experience"
                value={unique_experience}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('unique_experience', unique_experience, 'required')}
              />
            </div> */}
                        {/* <div className="col-md-6 form-group">
              <label>Traveller description</label>
              <NormalInput
                className="form-control"
                type="text"
                name="traveller_des"
                value={traveller_des}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('traveller_des', traveller_des, 'required')}
                number={false}
              />
            </div> */}
                        {/* </div> */}
                        {/* <div className="row">
            <div className="col-md-6 form-group">
              <label >Number of stop</label>
              <NormalInput
                className="form-control"
                type="number"
                name="number_of_stops"
                value={number_of_stops}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('number_of_stops', number_of_stops, 'required')}
              />
            </div>
            {name_of_stops.map((item, index) => {

              return (
                <div className="col-md-6 form-group">
                  <label
                    className="d-flex align-items-center"
                  >
                    Name of stop {index + 1}
                  </label>

                  <NormalInput
                    className={`form-control ${index !== 0 ? "mt-3" : ""}`}
                    type="text"
                    name="name_of_stops"
                    value={item}
                    onChange={({ name, value }) => {
                      this.handleArrayInputs(name, value, index)
                    }}

                    key={index}
                  />

                  {this.validators.message(`/name_of_stops/${index}/`, name_of_stops, 'required')}



                </div>
              )
            })}

          </div> */}
                        <div className="row">
                            {/* <div className="col-md-6 form-group">
              <label >Guest Bringing</label>
              <NormalInput
                className="form-control"
                type="text"
                name="guest_bringing"
                value={guest_bringing}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('guest_bringing', guest_bringing, 'required')}
                number={false}
              />


            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label >People understand language</label>
              <NormalInput
                className="form-control"
                type="text"
                name="people_understand_language"
                value={people_understand_language}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('people_understand_language', people_understand_language, 'required')}
                number={false}
              />
            </div> */}
                            <div className="col-md-6 form-group">
                                <label >ticket count</label>
                                <NormalInput
                                    className="form-control"
                                    type="number"
                                    name="ticket_count"
                                    value={ticket_count}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('ticket_count', ticket_count, 'required')}
                                />
                            </div>
                            {/* <div className="col-md-6 form-group">
              <label >Experience Group</label>
              <ReactSelect
                isMulti={false}
                options={experienceGroupOptions}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="experience_group"
                value={experience_group}
                disabled={false}
              />

              {this.validator.message('experience_group', experience_group, 'required')}
            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label>Traveller enjoy experience</label>
              <ReactSelect
                isMulti={false}
                options={travelerEnjoyExperienceOptions}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="traveler_enjoy_experience_group_id"
                value={traveler_enjoy_experience_group_id}
                disabled={false}
              />
              {this.validator.message('traveler_enjoy_experience_group_id', traveler_enjoy_experience_group_id, 'required')}
            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label >Travel book ticket</label>
              <NormalInput
                className="form-control"
                type="text"
                name="traveler_book_tickets"
                value={traveler_book_tickets}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('traveler_book_tickets', traveler_book_tickets, 'required')}
                number={false}
              />
            </div> */}
                            <div className="col-md-6 form-group">
                                <label >Base price</label>
                                <NormalInput
                                    className="form-control"
                                    type="number"
                                    name="base_price"
                                    value={base_price}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('base_price', base_price, 'required')}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label >Child price</label>
                                <NormalInput
                                    className="form-control"
                                    type="number"
                                    name="child_price"
                                    value={child_price}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('child_price', child_price, 'required')}
                                />
                            </div>
                            {/* <div className="col-md-6 form-group">
              <label >Best experience time</label>

              <ReactSelect
                isMulti={false}
                options={bestExperienceTimeOptions}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="best_experience_time"
                value={best_experience_time}
                disabled={false}
              />
              {this.validator.message('best_experience_time', best_experience_time, 'required')}
            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label  >Cancelation type</label>
              <NormalInput
                className="form-control"
                type="text"
                name="cancellation_type"
                value={cancellation_type}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('cancellation_type', cancellation_type, 'required')}
                number={false}
              />
            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label >Cancel a booking</label>
              <NormalInput
                className="form-control"
                type="text"
                name="cancel_a_booking"
                value={cancel_a_booking}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('cancel_a_booking', cancel_a_booking, 'required')}
                number={false}
              />
            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label >Traveller separate ticket</label>
              <NormalInput
                className="form-control"
                type="text"
                name="traveller_separte_ticket"
                value={traveller_separte_ticket}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('traveller_separte_ticket', traveller_separte_ticket, 'required')}
                number={false}
              />
            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label  >Price your product</label>
              <ReactSelect
                isMulti={false}
                options={priceYourProduct}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="price_your_product"
                value={price_your_product}
                disabled={false}
              />
              {this.validator.message('price_your_product', price_your_product, 'required')}
            </div> */}
                            <div className="col-md-6 form-group">
                                <label >Maximum count a group</label>
                                <NormalInput
                                    className="form-control"
                                    type="number"
                                    name="maximum_count_a_group"
                                    value={maximum_count_a_group}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('maximum_count_a_group', maximum_count_a_group, 'required')}
                                />
                            </div>
                            {/* <div className="col-md-6 form-group">
              <label  >Advance notice for booking</label>
              <NormalInput
                className="form-control"
                type="text"
                name="advanced_notice_for_a_booking"
                value={advanced_notice_for_a_booking}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('advanced_notice_for_a_booking', advanced_notice_for_a_booking, 'required')}
                number={false}
              />

            </div> */}
                            {/* <div className="col-md-6 form-group">
              <label className="d-flex">
                <span className="mr-auto">Offer</span>
                <ToggleCheckbox keyName={"is_offer"}
                  value={is_offer}
                  onClick={() => this.handleToggleCheckbox("is_offer")}
                />
              </label>
              <NormalInput
                className="form-control"
                type="number"
                placeholder="Offer Percentage"
                name="offer_percentage"
                value={offer_percentage}
                disabled={!is_offer}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={is_offer ? this.validator.message('Offer Percentage', offer_percentage, 'required') : ""}
              />
            </div> */}

                            {/* <div className="col-md-6 form-group">
              <label className="d-flex">
                <span className="mr-auto">Offer</span>
                <ToggleCheckbox keyName={"is_offer"}
                  value={is_offer}
                  onClick={() => this.handleToggleCheckbox("is_offer")}
                />
              </label>
              <NormalInput
                className="form-control"
                type="number"
                placeholder="Offer Percentage"
                name="offer_percentage"
                value={offer_percentage}
                disabled={!is_offer}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={is_offer ? this.validator.message('Offer Percentage', offer_percentage, 'required') : ""}
              />
            </div> */}

                            <div className="col-md-6 form-group">
                                <label className="d-flex">
                                    <span className="mr-auto">Taxi Price</span>
                                </label>
                                <NormalInput
                                    className="form-control"
                                    type="number"
                                    name="taxi_price"
                                    value={taxi_price}
                                    onChange={({ name, value }) => {
                                        this.handleInputs({ name: name, value: value })
                                    }}
                                    validationError={this.validator.message('Taxi Price', taxi_price, 'required')}
                                />
                            </div>
                        </div>


                        {/* <div className="row  mb-3">

            <label className="mb-0 mr-3 col-md-2"  > Tickets offer </label>
            <ToggleCheckbox keyName={"ticket_offer"}
              value={ticket_offer}
              onClick={() => this.handleToggleCheckbox("ticket_offer")}
            />
          </div> */}
                        {/* <div className="row  mb-3">

            <label className="mb-0 mr-3 col-md-2"  > Offer Guide </label>
            <ToggleCheckbox
              value={offer_guide}
              onClick={() => this.handleToggleCheckbox("offer_guide")}
            />
          </div> */}

                        {/* <div className="row  mb-3">
            <label className="mb-0 mr-3 col-md-2"  > Ticket line access </label>
            <ToggleCheckbox
              value={ticket_line_access}
              onClick={() => this.handleToggleCheckbox("ticket_line_access")}
            />
          </div> */}


                        {/* <div className="row  mb-3">
            <label className="mb-0 mr-3 col-md-2" > Is private activity </label>
            <ToggleCheckbox
              value={is_private_activity}
              onClick={() => this.handleToggleCheckbox("is_private_activity")}
            />
          </div> */}

                        {/* <div className="row  mb-3">
            <label className="mb-0 mr-3 col-md-2"  > License Required </label>
            <ToggleCheckbox
              value={license_required}
              onClick={() => this.handleToggleCheckbox("license_required")}
            />
          </div> */}

                        {/* <div className="row  mb-3">
            <label className="mb-0 mr-3 col-md-2"  > License Certified </label>
            <ToggleCheckbox
              value={license_certified}
              onClick={() => this.handleToggleCheckbox("license_certified")}
            />
          </div> */}
                        <hr />

                        <button className="btn btn-primary"
                            onClick={() => this.handleSubmit()}
                        >Submit</button>
                    </section>

                    <ConfirmModal
                        keyName="ConfirmModalOpenStatus"
                        toggle={this.toggle}
                        isOpen={ConfirmModalOpenStatus}
                        handleSubmit={() => this.handleDelete()}
                        submitText='Delete'
                    >
                        <h5>
                            Are you sure want to Image?
					</h5>
                    </ConfirmModal>
                </div>


            </fieldset>)
    }
}

const mapStateToProps = state => {

    return {
        categoryLists: state.dashboard.categoryLists,
        daysList: state.common.daysList,
        activityDropDownLists: state.activity.activityDropDownLists
    }


};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        // addImage,
        createActivity,
        // getActivityDropDown,
        getActivityDetailsById,
        // updateActivity,
        // createSliderActivityImage,
    }, dispatch)
};


// let actions = ['getActivityDropDown']

let component = ViewActivityClass // LoaderWrapper(ViewActivityClass)({ actions })

export const ViewActivity = connect(mapStateToProps, mapDispatchToProps)(component)