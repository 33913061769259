import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, SliderOverlay } from "../../component/common/"
import { DriverDetailModal } from "../../component/master"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getSNo } from "../../services/helperFunctions"
import { createDriver } from "../../redux/actions/dashboard"


class DriverDetailClass extends Component {
	state = {
		headerDetails: [
			{
				label: 'S.No',
			},
			{
				label: 'Driver Name',
			},
			{
				label: 'Contact Number',
			},
			{
				label: 'Driver Experience',
			},
			{
				label: 'Status',
			},
			{
				label: 'Action'
			},

		],
		filter: [


			{
				label: 'Active',
				value: 1
			},
			{
				label: 'Pending',
				value: 2
			},
			{
				label: 'Approved',
				value: 3
			},
			{
				label: 'Rejected',
				value: 4
			},

		],
		driverDetailLists: {

		},
		selectedFilter: 'Active',
		driverDetails: {
			name: '',
			number: '',
			experience: '',
			status: '',
			image: ''

		},
		isError: false,
		isOpen: false,
		isEdit: false
	}


	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.setState({
			driverDetailLists: this.props.driverDetailLists
		})
	}


	requestStatus = (status) => {

		if (status === 1) {
			return <Badge
				type={1}
				label={"Active"}
			/>
		} else if (status === 2) {
			return <Badge
				type={2}
				label={"Pending"}
			/>
		}

	}

	handleFilter = (label, value, name) => {

		if (name == "filter") {
			this.setState({
				selectedFilter: label
			})
		}



	}

	handleInputs = ({ target: { value, name } }) => {

		let driverDetails = Object.assign({}, this.state.driverDetails)

		driverDetails[name] = value

		this.setState({
			driverDetails
		})


	}


	handleAdd = () => {
		this.setState({
			isOpen: true
		})
	}



	handleEdit = (index) => {

		let { driverDetailLists: { list } } = this.state

		let driverDetails = list[index]

		this.setState({
			isEdit: true,
			driverDetails,
			currentIndex: index

		})

		this.toggle()

	}

	toggle = () => {
		let { isOpen } = this.state
		this.setState({
			isOpen: !isOpen
		})
	}

	handleModal = () => {

		let temp = {
			name: '',
			number: '',
			experience: '',
			status: '',
			image: ''
		}

		this.setState({
			isError: false,
			isOpen: false,
			isEdit: false,
			driverDetails: temp

		})
	}

	handleSubmit = () => {

		if (this.validator.allValid()) {

			let { driverDetails } = this.state

			let body = {
				...driverDetails
			}
			this.props.createDriver(body).then(() => {

			})

			let temp = {
				name: '',
				number: '',
				experience: '',
				status: '',
				image: ''
			}

			this.setState({
				isError: false,
				isOpen: false,
				isEdit: false,
				driverDetails: temp

			})
			this.validator.hideMessages();

		} else {
			this.setState({
				isError: true
			})
			this.validator.showMessages();

		}
	}


	render() {

		let { headerDetails, filter, selectedFilter, driverDetails, isError, isOpen, isEdit } = this.state
		let { driverDetailLists } = this.props
		let IsEmpty = driverDetailLists.list.length > 0



		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Driver Detail
					</h4>
					<div className="col-auto ">
						<button className="btn btn-primary btn-sm"
							onClick={() => this.handleAdd()}
						>Add Driver</button>
					</div>
				</div>
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<h5 className="sair-sec-title align-self-center">Driver Detail List</h5>
								<div className="ml-auto d-flex align-items-center">
									{/* <UncontrolledDropdown setActiveFromChild className="filter-dropdown">
										<DropdownToggle tag="a" className="custom-caret dropdown-toggle-link">
											Filter by: {selectedFilter}
										</DropdownToggle>
										<DropdownMenu>
											{filter.map(({ label, value }, index) => {
												return (
													<DropdownItem
														onClick={e => this.handleFilter(label, value, "filter")}
														key={index}
													>
														{label}
													</DropdownItem>

												)
											})}
										</DropdownMenu>
									</UncontrolledDropdown> */}

									<div className="search-box position-relative  ml-3">

										{/* <Filters
											queryHandler={(query) => this.handleFilter(query)}
											isDropDownFilter={false}
										/> */}
									</div>
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									pageMeta={driverDetailLists.pageMeta}
									queryHandler={(query) => this.handleFilter(query)}


								>
									{driverDetailLists.list.map((item, index) => {

										console.log(driverDetailLists)
										let { name, number, experience, status } = item

										return (
											<tr
												key={index}  >
												<td>{getSNo({ limit: 10, page: 1 }, index)}</td>
												<td>{name}</td>
												<td>{number}</td>
												<td>{experience}</td>
												<td>{this.requestStatus(status)}</td>
												<td>
													<a className="mr-25"
														onClick={e => this.handleEdit(index)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"></i></a>
												</td>

											</tr>

										)

									})}

								</TableWrapper>
							</div>
						</div>
					</div>
				</section>

				<SliderOverlay isOpen={isOpen} toggle={this.handleModal} size='30%'>
					<div className="side-slider h-100 position-relative">
						<div className="h-100 flex-with-center">
							<DriverDetailModal
								isError={isError}
								toggle={this.handleModal}
								title={isEdit ? "Edit Driver Detail" : "Add Driver Detail"}
								modalDetails={driverDetails}
								handleInputs={this.handleInputs}
								validator={this.validator}
								handleSubmit={this.handleSubmit}
							/>
						</div>

					</div>
				</SliderOverlay>

			</div>
		)
	}
}

const mapStateToProps = state => {

	return {
		driverDetailLists: state.dashboard.driverDetailLists
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createDriver
	}, dispatch)
};

export const DriverDetail = connect(mapStateToProps, mapDispatchToProps)(DriverDetailClass)

