import React, { Component } from 'react';
import { ReactSelect } from '../common';

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { LoaderWrapper } from "../../HOC"

import { getTopCategoryDropdown, createTopCategory, getTopCategoryList, updateTopCategory } from '../../redux/actions/topcategorymapping';

import SimpleReactValidator from 'simple-react-validator';

import { ToggleCheckbox } from '../../component/common';

export class TopCategoryMappingModalClass extends Component {

    state = {
        categoryOptions: [],
        cityOptions: [],
        formDetails: {
            city_id: '',
            category_id: '',
            is_must_see: false,
            is_city_page: false,
        }
    }

    componentWillReceiveProps({ details }) {

        let { category_id, city_id, is_must_see, is_city_page } = details;

        let formDetails = Object.assign({}, this.state.formDetails)

        formDetails = {

            category_id,
            city_id,
            is_must_see,
            is_city_page,
        }

        this.setState({
            formDetails
        })

    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message ">{message}</span>,
            autoForceUpdate: this,
            messages: {}
        });

    }

    componentDidMount() {

        let { topCategoryDropdown } = this.props;

        let { category_list = [], city_list = [] } = topCategoryDropdown;

        let categoryOptions = this.state.categoryOptions.slice(0)

        let cityOptions = this.state.cityOptions.slice(0)

        category_list.map((item) => {

            categoryOptions.push({
                label: item.title,
                value: item.id,
            })

        })

        city_list.map((item) => {

            cityOptions.push({
                label: item.city_name,
                value: item.id,
            })

        })

        this.setState({
            categoryOptions,
            cityOptions
        })

    }

    handleInputs = ({ value, name }) => {

        let formDetails = Object.assign({}, this.state.formDetails);

        formDetails[name] = value;

        this.setState({
            formDetails
        })


    }

    handleSubmit = () => {

        if (this.validator.allValid()) {

            let { details } = this.props;

            let { editId } = details;

            if (!editId) {

                this.props.createTopCategory(this.state.formDetails).then(() => {

                    this.props.getTopCategoryList({ pageNo: 1 });

                    this.props.toggle();

                })

            } else {

                this.props.updateTopCategory(editId, this.state.formDetails).then(() => {

                    this.props.getTopCategoryList({ pageNo: 1 });

                    this.props.toggle();

                })

            }

        } else {

            this.validator.showMessages();

        }

    }

    render() {

        let { toggle } = this.props;

        let { categoryOptions, cityOptions, formDetails } = this.state;

        let { category_id, city_id, is_city_page, is_must_see } = formDetails;

        console.log(formDetails, "asdasdasdasdasdadasd")

        return (
            <>
                <div className="slider-header p-4">
                    <div className="d-flex align-items-center">
                        <h5 className="sair-sec-title">Add Top Category Mapping</h5>
                        <div className="ml-auto">
                            <i
                                className="icon-close font-md px-2 cursor-pointer"
                                onClick={toggle}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="slider-body p-4">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Category</label>
                            <ReactSelect
                                isMulti={false}
                                options={categoryOptions}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="category_id"
                                value={category_id}
                                disabled={false}
                            />
                            {this.validator.message('Category', category_id, 'required')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>City</label>
                            <ReactSelect
                                isMulti={false}
                                options={cityOptions}
                                handleChange={(keyName, data) => {
                                    this.handleInputs({ name: keyName, value: data })
                                }}
                                keyName="city_id"
                                value={city_id}
                                disabled={false}
                            />
                            {this.validator.message('City', city_id, 'required')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group d-flex">
                            <label className="mr-2">Must See</label>
                            <div className="ml-auto">
                                <ToggleCheckbox
                                    onClick={() => this.handleInputs({ name: "is_must_see", value: !is_must_see })}
                                    value={is_must_see}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group d-flex">
                            <label className="mr-2">Include in City Page</label>
                            <div className="ml-auto">
                                <ToggleCheckbox
                                    onClick={() => this.handleInputs({ name: "is_city_page", value: !is_city_page })}
                                    value={is_city_page}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <button
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                    >Submit</button>
                </div>

            </>
        )

    }

}

const mapStateToProps = state => {
    return {
        topCategoryDropdown: state.topcategorymapping.topCategoryDropdown,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getTopCategoryDropdown,
        createTopCategory,
        getTopCategoryList,
        updateTopCategory
    }, dispatch)
};


let actions = ['getTopCategoryDropdown']

let component = LoaderWrapper(TopCategoryMappingModalClass)({ actions })

export const TopCategoryMappingModal = connect(mapStateToProps, mapDispatchToProps)(component)