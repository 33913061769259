import React, { Component } from 'react'
import { FileUpload, ReactSelect, NormalInput} from "../../component/common"
import { TextEditorActivity} from "../../component/common/TextEditorActivity"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { SAILMALL_BASE_URL } from "../../config/config"

import SimpleReactValidator from 'simple-react-validator';
import { getCategoryDetailsById, addImage, editCategoryDetailsById, createCategory } from "../../redux/actions/reseller"

import { TextEditor, DateTime } from '../../component/common';

import { hidePastDate } from "../../services/helperFunctions"

import _ from "lodash"

class CategoryCreateClass extends Component {
	state = {
		categoryDetails: {
      name: '',
      descriptions: '',
      imgLinks: [],
      active: true
  },
  descState: {
    descriptions: ""
  },
  statusNames:[{
    'label':'True',
    'value':true
},
{
    'label':'False',
    'value':false
}
]
}

	componentWillMount() {
    this.descState = {}

    this.validator = new SimpleReactValidator({
      element: message => <span className="error-message font-md">{message}</span>,
      autoForceUpdate: this,
      messages: {
        int: 'this :attribute required here'
      }
    });

    this.handleInputs = ({ value, name }) => {
      let categoryDetails = this.state.categoryDetails
      let url;
      if (name === 'name') {
        categoryDetails.name = value
        // url = categoryDetails.name.trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'').toLowerCase().split(' ').join('-')
        // categoryDetails.shareLink = SAILMALL_BASE_URL + url
      }

      if (name === 'shareLink') {
        categoryDetails.shareLink = value
      }

      if (name == "descriptions") {
        categoryDetails["descriptions"] = value;
        categoryDetails["descriptions"] = categoryDetails["descriptions"].length<this.descState.descriptions.length ? this.descState.descriptions : categoryDetails["descriptions"];
      }
      
      this.setState({
        categoryDetails
      }, () => {
        console.log("current state ", this.state)
      })
    }

    this.handleDescriptionInputs = ({value, name}) => {
      this.descState["descriptions"] = value;
    }
	}

	componentDidMount() {
    let { match: { params: { id } }, history } = this.props;
    if(id) {
      this.props.getCategoryDetailsById(id).then((data) => {
        let categoryDetails = data;
        this.setState({categoryDetails})
      }).catch((ex) => {

      })
    }
  }

  handleSubmit() {
    let {categoryDetails} = this.state
    let id = categoryDetails._id
    if(id) {
      // EDIT FLOW
      let body = {
        ...categoryDetails
      }
      this.props.editCategoryDetailsById(id,body).then((data) => {
        let { history } = this.props
        this.setState({disabled:false})
        history.push('/reseller/category')
      }).catch((ex) => {
        this.setState({disabled:false})
      })
    } else {
      // CREATE FLOW
      let body = {
        ...categoryDetails
      }
      this.props.createCategory(body).then((data) => {
        let { history } = this.props
        this.setState({disabled:false})
        history.push('/reseller/category')
      }).catch((ex) => {
        this.setState({disabled:false})
      })
    }
  }

  ImagePreview = (key, event) => {
    let files = event.target.files[0]
    let categoryDetails = Object.assign({}, this.state.categoryDetails)
    let formData = new FormData()
    formData.append("resellerImage", files)
    this.props.addImage(formData).then((data) => {
      categoryDetails.imgLinks[0] = data.imageUrl
    })
    this.setState({categoryDetails})
  }

  render() {
    let {categoryDetails,statusNames} = this.state
    let {name, descriptions, imgLinks, active} = categoryDetails
    let tempFile = ""
    return (
      <div>
          {/* Header */}
          <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Category
					</h4>
        </div>

        <div className="row">
            <div className="col-md-6 form-group">
              <label><b>Name</b></label>
              <NormalInput
                className="form-control"
                name="name"
                type="text"
                value={name}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Category Title', name, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label >Image</label>
              <FileUpload
                fileUpload={this.fileUpload}
                ImagePreview={(e) => this.ImagePreview("category_image", e)}
                filename={imgLinks && imgLinks[0]? imgLinks[0] : ""}
                tempFile={tempFile}
              />
              <img src={imgLinks && imgLinks[0]} style={{ width: "200px", marginTop: "15px" }} />
              {this.validator.message('image', imgLinks, 'required')}
          </div>
            <div className="col-md-6 form-group">
            <label><b>Choose Active Status</b></label>
              <ReactSelect
                  isMulti={false}
                  options={statusNames}
                  handleChange={(keyName, data) => {
                    this.handleSelect({ name: keyName, value: data })
                  }}
                  keyName="active"
                  value={active}
                  disabled={false}
                  />
              {this.validator.message('Active Status', active, 'required')}
            </div>
            <div className="col-md-6 form-group">
              <label><b>Description</b></label>

              <TextEditorActivity
                handleTextEditor={html => {
                  this.handleDescriptionInputs({ name: 'descriptions', value: html })
                }}
                handleTextEditorInput={html => {
                  this.handleInputs({ name: 'descriptions', value: html })
                }}
                html={descriptions}
              />
              {this.validator.message('category description', descriptions, 'required')}
            </div>
        </div>
        <hr />
          <button className="btn btn-primary"
            onClick={() => this.handleSubmit()}
            disabled={this.state.disabled}
          >Submit</button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getCategoryDetailsById,
    addImage,
    editCategoryDetailsById, 
    createCategory 
  }, dispatch)
}

export const CategoryCreate = connect(mapStateToProps, mapDispatchToProps)(CategoryCreateClass)
