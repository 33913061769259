import React, { Component } from 'react'

import Autocomplete from 'react-google-autocomplete';

import './style.scss';

export class ReactAutoComplete extends Component {

    render() {

        const { handleChange } = this.props;

        return (
            <Autocomplete
                className="form-control"
                style={{ width: '100%' }}
                onPlaceSelected={(place) => {

                    handleChange(place)
                }}
            // types={['(regions)']}
            // componentRestrictions={{ country: "ru" }}
            />
        )

    }

}