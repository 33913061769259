import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal } from "../../component/common/"
import { TravelExperienceGroupModal } from "../../component/master"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { createTravelExperienceGroup, getTravelExperienceGroupListDetails, editTravelExperienceGroup, deleteTravelExperienceGroup } from "../../redux/actions/master"
import { getSNo } from "../../services/helperFunctions"

import { LoaderWrapper } from "../../HOC"


class TravelExperienceGroupClass extends Component {
	state = {
		headerDetails: [
			{
				label: 'S.No',
			},
			{
				label: 'Name Of The Group',
			},
			{
				label: 'Status',
			},
			{
				label: 'Action'
			},

		],
		filter: [


			{
				label: 'Active',
				value: 1
			},
			{
				label: 'Deactive',
				value: 4
			}

		],
		travelExperienceGroupLists: {

		},
		selectedFilter: 'Active',
		travelExperienceGroupDetails: {
			name_of_group: '',

		},
		isError: false,
		isOpen: false,
		isEdit: false,
		ConfirmModalOpenStatus: false,
		id: ''
	}


	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.setState({
			travelExperienceGroupLists: this.props.travelExperienceGroupLists
		})

	}


	requestStatus = (status) => {

		if (status === 1) {
			return <Badge
				type={1}
				label={"Active"}
			/>
		} else if (status === 2) {
			return <Badge
				type={2}
				label={"Deactive"}
			/>
		}

	}

	handleFilter = (query, value, name) => {

		if (name == "filter") {
			this.setState({
				selectedFilter: query
			})

			query = {
				pageNo: 1,
				filter: query
			}
		}

		this.props.getTravelExperienceGroupListDetails(query)



	}


	handleAdd = () => {

		let temp = {
			name_of_group: ''
		}
		this.setState({
			isOpen: true,
			travelExperienceGroupDetails: temp
		})
	}



	handleEdit = (index) => {

		let { travelExperienceGroupLists } = this.props

		let { list = [] } = travelExperienceGroupLists

		let travelExperienceGroupDetails = list[index]

		this.setState({
			isEdit: true,
			travelExperienceGroupDetails,
			currentIndex: index

		})

		this.toggle("isOpen")

	}

	toggle = item => {
		this.setState(prevState => ({
			[item]: !prevState[item]
		}));
	}

	handleModal = () => {

		let temp = {
			name_of_group: '',
		}

		this.setState({
			isError: false,
			isOpen: false,
			isEdit: false,
			travelExperienceGroupDetails: temp

		})
	}

	handleDeleteToggle = (id) => {

		this.setState({
			id
		})

		this.toggleDelete("ConfirmModalOpenStatus")

	}


	handleDelete = () => {
		let { id } = this.state

		this.props.deleteTravelExperienceGroup(id).then(() => {

			this.props.getTravelExperienceGroupListDetails()

			this.toggleDelete("ConfirmModalOpenStatus")

		})

	}

	toggleDelete = (name) => {
		this.setState({
			[name]: !this.state[name]
		})
	}




	render() {

		let { headerDetails, filter, selectedFilter, travelExperienceGroupDetails, isError, isOpen, isEdit, ConfirmModalOpenStatus } = this.state
		console.log(travelExperienceGroupDetails)
		let { travelExperienceGroupLists } = this.props
		let IsEmpty = travelExperienceGroupLists.list.length > 0


		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Travel Experience Group
					</h4>
					<div className="col-auto ">
						<button className="btn btn-primary btn-sm"
							onClick={() => this.handleAdd()}
						>Add Travel Experience Group</button>
					</div>
				</div>
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<h5 className="sair-sec-title align-self-center">Travel Experience Group List</h5>
								<div className="ml-auto d-flex align-items-center">
									{/* <UncontrolledDropdown setActiveFromChild className="filter-dropdown">
										<DropdownToggle tag="a" className="custom-caret dropdown-toggle-link">
											Filter by: {selectedFilter}
										</DropdownToggle>
										<DropdownMenu>
											{filter.map(({ label, value }, index) => {
												return (
													<DropdownItem
														onClick={e => this.handleFilter(label, value, "filter")}
														key={index}
													>
														{label}
													</DropdownItem>

												)
											})}
										</DropdownMenu>
									</UncontrolledDropdown> */}

									<div className="search-box position-relative  ml-3">

										{/* <Filters
											queryHandler={(query) => this.handleFilter(query)}
											isDropDownFilter={false}
										/> */}
									</div>
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									pageMeta={travelExperienceGroupLists.pageMeta}
									queryHandler={(query) => this.handleFilter(query)}


								>
									{travelExperienceGroupLists.list.map((item, index) => {
										let { id, name_of_group, status } = item

										return (
											<tr
												key={index}  >
												<td>{getSNo(travelExperienceGroupLists.pageMeta, index)}</td>
												<td>{name_of_group}</td>
												<td>{this.requestStatus(status)}</td>
												<td>
													<a className="mr-25"
														onClick={e => this.handleEdit(index)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"
														onClick={e => this.handleDeleteToggle(id)}
													></i></a>
												</td>

											</tr>

										)

									})}


								</TableWrapper>
							</div>
						</div>
					</div>
				</section>



				<SliderOverlay isOpen={isOpen} toggle={this.handleModal} size='30%'>
					<div className="side-slider h-100 position-relative">
						<div className="h-100 flex-with-center">
							<TravelExperienceGroupModal
								isError={isError}
								toggle={this.handleModal}
								isEdit={isEdit}
								modalDetails={travelExperienceGroupDetails}
							/>
						</div>

					</div>
				</SliderOverlay>

				<ConfirmModal
					keyName="ConfirmModalOpenStatus"
					toggle={this.toggle}
					isOpen={ConfirmModalOpenStatus}
					handleSubmit={() => this.handleDelete()}
					submitText='Delete'
				>
					<h5>
						Are you sure want to delete Category?
					</h5>
				</ConfirmModal>

			</div>
		)
	}
}

const mapStateToProps = state => {

	return {
		travelExperienceGroupLists: state.master.travelExperienceGroupLists
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createTravelExperienceGroup,
		getTravelExperienceGroupListDetails,
		editTravelExperienceGroup,
		deleteTravelExperienceGroup
	}, dispatch)
};

let actions = ['getTravelExperienceGroupListDetails']

let component = LoaderWrapper(TravelExperienceGroupClass)({ actions })


export const TravelExperienceGroup = connect(mapStateToProps, mapDispatchToProps)(component)

