import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import SimpleReactValidator from 'simple-react-validator';
import { createLanguage, getLanguageListDetails, editLanguage, deleteLanguage } from "../../redux/actions/master"

class LanguageModalClass extends Component {

	state = {
		languageDetails: {
			language: ''

		},
		tempFile: '',
		isEdit: false
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}



	componentWillReceiveProps(props) {

		let { modalDetails, isEdit } = props

		let { url = '' } = modalDetails

		let tempFile = ''

		if (url) {
			tempFile = url
		}

		this.setState({
			languageDetails: modalDetails,
			isEdit: isEdit,
			tempFile
		})
	}

	handleInputs = ({ target: { value, name } }) => {

		let languageDetails = Object.assign({}, this.state.languageDetails)

		languageDetails[name] = value

		this.setState({
			languageDetails
		})


	}



	handleSubmit = () => {

		if (this.validator.allValid()) {

			let { languageDetails, isEdit } = this.state

			let { id, language } = languageDetails

			let body = {
				language: language
			}

			if (!isEdit) {


				this.props.createLanguage(body).then(() => {

					this.props.getLanguageListDetails().then(() => {
						let temp = {
							language: '',
						}

						this.setState({
							isEdit: false,
							languageDetails: temp

						})
					})

				})

			} else {

				this.props.editLanguage(id, body).then((data) => {

					this.props.getLanguageListDetails().then(() => {
						let temp = {
							language: '',
						}

						this.setState({
							isEdit: false,
							languageDetails: temp

						})
					})
				})
			}


			this.props.toggle()

			this.validator.hideMessages();

		} else {
			this.setState({
				isError: true
			})
			this.validator.showMessages();

		}
	}


	render() {

		let { isError, isEdit, toggle } = this.props

		let { languageDetails } = this.state
		this.validator.purgeFields();

		let { language } = languageDetails

		return (
			<section className="side-slider h-100">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {isEdit ? "Edit Language" : "Add Language"}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
						<div className="col-md-12 form-group">
							<label>Language name</label>
							<input
								className={`form-control ${this.validator.message('name', language, 'required') ? 'inValid' : ''}`} id="name"
								placeholder=""
								name="language"
								value={language}
								type="text"
								onChange={this.handleInputs}


							/>
							{this.validator.message('name', language, 'required')}
						</div>


					</div>

					<hr />
					<button
						className="btn btn-primary"

						onClick={() => this.handleSubmit()}

					>{isEdit ? "Update" : "Submit"}</button>
				</div>
			</section>
		)
	}
}


const mapStateToProps = state => {

	return {}

};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createLanguage,
		getLanguageListDetails,
		editLanguage,

	}, dispatch)
};


export const LanguageModal = connect(mapStateToProps, mapDispatchToProps)(LanguageModalClass)


