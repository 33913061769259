import { wholesaleType } from "../../services/actionType";

const initialState = {
  productList: {},
  productNames: [],
  leadList: {},
  leadListByUser: {},
  wholesaleProductNames: [],
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case wholesaleType.wholesaleProductListFirstRequest:
      return {
        ...state,
        productList: {},
      };
    case wholesaleType.wholesaleLeadListFirstRequest:
      return {
        ...state,
        leadList: {},
      };
    case wholesaleType.wholesaleProductList:
      return {
        ...state,
        productList: payload,
      };
    case wholesaleType.wholesaleProductNames:
      return {
        ...state,
        productNames: payload,
      };
    case wholesaleType.wholesaleLeadList:
      return {
        ...state,
        leadList: payload,
      };
    case wholesaleType.wholesaleLeadListByUser:
      return {
        ...state,
        leadListByUser: payload,
      };
    case wholesaleType.wholesaleAddProductNames:
      return {
        ...state,
        wholesaleProductNames: payload,
      };
    default:
      return state;
  }
};
