const routers = [
    {
        path: '/',
        redirect: '/auth',
        exact: true,
    },

    {
        component: 'AuthLayout',
        path: '/auth',
        auth: false,
        exact: false,
        redirect: '/auth/login',
        childrens: [
            {
                component: 'Login',
                path: '/login',
                auth: false,
                exact: true
            },
            {
                component: 'ForgotPassword',
                path: '/forgotPassword',
                auth: false,
                exact: true
            },
            {
                component: 'ResetPassword',
                path: '/resetPassword',
                auth: false,
                exact: true
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/dashboard',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'Dashboard',
                path: '/list',
                auth: false,
                exact: true
            },
            {
                component: 'City',
                path: '/city',
                auth: false,
                exact: true
            },
            {
                component: 'AddCity',
                path: '/addcity',
                auth: false,
                exact: true
            },
            {
                component: 'AddCity',
                path: '/editcity/:id',
                auth: false,
                exact: true
            },
            {
                component: 'Category',
                path: '/category',
                auth: false,
                exact: true
            },
            {
                component: 'ServiceProvider',
                path: '/service-provider',
                auth: false,
                exact: true
            },
            {
                component: 'PickupAndDropLocation',
                path: '/pickup-drop-location',
                auth: false,
                exact: true
            },
            {
                component: 'VehicleType',
                path: '/vehicle-type',
                auth: false,
                exact: true
            },
            {
                component: 'StayType',
                path: '/stay-types',
                auth: false,
                exact: true
            },
            {
                component: 'TicketNationalityType',
                path: '/ticket-nationality-types',
                auth: false,
                exact: true
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/itinerary',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'Itinerary',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'ItineraryCreate',
                path: '/create',
                auth: false,
                exact: true,
            },
            {
                component: 'ItineraryCreate',
                path: '/edit/:id',
                auth: false,
                exact: true,
            },
            {
                component: 'GenerateItinerary',
                path: '/generate',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/activity',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'Activity',
                path: '/list',
                auth: false,
                exact: true
            },
            {
                component: 'MappingActivity',
                path: '/maping',
                auth: false,
                exact: true,
            },
            {
                component: 'ViewActivity',
                path: '/view/:id',
                auth: false,
                exact: true
            },
            {
                component: 'CreateActivity',
                path: '/create',
                auth: false,
                exact: true
            },
            {
                component: 'CreateActivity',
                path: '/edit/:id',
                auth: false,
                exact: true
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/advertisement_type_one',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'AdvertisementTypeOne',
                path: '/list',
                auth: false,
                exact: true
            },
            {
                component: 'CreateAdvertisementTypeOne',
                path: '/create',
                auth: false,
                exact: true
            },
            {
                component: 'CreateAdvertisementTypeOne',
                path: '/edit/:id',
                auth: false,
                exact: true
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/vendor',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'Vendor',
                path: '/list',
                auth: false,
                exact: true
            },
            {
                component: 'CreateVendor',
                path: '/create',
                auth: false,
                exact: true
            },
            {
                component: 'CreateVendor',
                path: '/edit/:id',
                auth: false,
                exact: true
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/vendor-mapping',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'VendorMapping',
                path: '/list',
                auth: false,
                exact: true

            }
        ],
    },
    {
        component: 'MainLayout',
        path: '/offer',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'Offer',
                path: '/list',
                auth: false,
                exact: true

            }
        ],
    },
    {
        component: 'MainLayout',
        path: '/partial-payment-link',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'PartialPaymentsLink',
                path: '/',
                auth: false,
                exact: true

            }
        ],
    },
    {
        component: 'MainLayout',
        path: '/topactivity',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'TopActivityMapping',
                path: '/list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/topactivitycity',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'TopActivityCity',
                path: '/list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/topcategorymapping',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'TopCategoryMapping',
                path: '/list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/orders',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'OrdersList',
                path: '/list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/gtmorders',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'OrdersListGtm',
                path: '/list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/discount',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'DiscountInfo',
                path: '/',
                auth: false,
                exact: true

            }
        ],
    },
    {
        component: 'MainLayout',
        path: '/code-run',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'CodeRun',
                path: '/',
                auth: false,
                exact: true

            }
        ],
    },
    {
        component: 'MainLayout',
        path: '/wholesale',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'WholesaleProductList',
                path: '/products-list',
                auth: false,
                exact: true,
            },
            {
                component: 'WholesaleLeadList',
                path: '/leads-list',
                auth: false,
                exact: true,
            },
            {
                component: 'WholesalePartnerLeadsList',
                path: '/partner-leads-list',
                auth: false,
                exact: true,
            },
            {
                component: 'WholesalePartnerOrdersList',
                path: '/partner-orders-list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/purchaseOrder',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'PurchaseOrdersList',
                path: '/list',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/customer',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'CustomerList',
                path: '/list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/order',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'ConfirmOrder',
                path: '/Confirm/:id',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/cityfestival',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'CityFestival',
                path: '/list',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/blog',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'BlogList',
                path: '/list',
                auth: false,
                exact: true,
            },
            {
                component: 'AddBlog',
                path: '/add',
                auth: false,
                exact: true,
            },
            {
                component: 'AddBlog',
                path: '/edit/:id',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/category',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'CategoryList',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'CategoryCreate',
                path: '/create',
                auth: false,
                exact: true,
            },
            {
                component: 'CategoryCreate',
                path: '/edit/:id',
                auth: false,
                exact: true,
            },
            {
                component: 'CategoryPositionList',
                path: '/position/',
                auth: false,
                exact: true,
            },
            {
                component: 'AddCategoryPosition',
                path: '/add-position/',
                auth: false,
                exact: true,
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/category-l2',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'CategoryLevelTwoList',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'CategoryLevelTwoCreate',
                path: '/create',
                auth: false,
                exact: true,
            },
            {
                component: 'CategoryLevelTwoCreate',
                path: '/edit/:id',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/category-l3',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'CategoryLevelThreeList',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'CategoryLevelThreeCreate',
                path: '/create',
                auth: false,
                exact: true,
            },
            {
                component: 'CategoryLevelThreeCreate',
                path: '/edit/:id',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/brand',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'BrandList',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'BrandCreate',
                path: '/create',
                auth: false,
                exact: true,
            },
            {
                component: 'BrandCreate',
                path: '/edit/:id',
                auth: false,
                exact: true,
            },
            {
                component: 'BrandPositionList',
                path: '/position/',
                auth: false,
                exact: true,
            },
            {
                component: 'AddBrandPosition',
                path: '/add-position/',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/warehouses',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'WareHouseList',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'WareHouseCreate',
                path: '/create',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/product',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'ProductList',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'ProductCreate',
                path: '/create',
                auth: false,
                exact: true,
            },
            {
                component: 'ProductCreate',
                path: '/edit/:id',
                auth: false,
                exact: true,
            },
            ,
            {
                component: 'ProductPositionList',
                path: '/position/',
                auth: false,
                exact: true,
            },
            {
                component: 'AddProductPosition',
                path: '/add-position/',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/sellers',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'SellerList',
                path: '/',
                auth: false,
                exact: true
            },
            {
                component: 'SellerCreate',
                path: '/edit/:id',
                auth: false,
                exact: true,
            }
        ]
    },
    {
        component: 'MainLayout',
        path: '/reseller/banner',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'BannerList',
                path: '/',
                auth: false,
                exact: true
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/customer-banner',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'CustomerBannerList',
                path: '/',
                auth: false,
                exact: true
            },
        ]
    },
    {
        component: 'MainLayout',
        path: '/notification',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'Notification',
                path: '/list',
                auth: false,
                exact: true
            },
            {
                component: 'CreateNotification',
                path: '/create',
                auth: false,
                exact: true
            }
        ]
    },

]

export default routers 