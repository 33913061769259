import React, { Component } from 'react'
import ReactDOM from "react-dom"
import "./style.scss"

export class SliderOverlay extends Component {

    render() {

        let { isOpen, toggle, children, size = '' } = this.props

        return ReactDOM.createPortal(
            <div className={`portal ${isOpen ? 'display-overlay edit-section-open' : ''} `}>
                <div className="slider-overlay " onClick={e => {
                    toggle && toggle()
                }} >
                </div>
                <div className="component-selection-wrapper">

                    <div className={`edit-overlay `} style={{ width: size }}>

                        {children}

                    </div>
                </div>
            </div>,
            document.getElementById('portal')
        );
    }
}
