import React, { Component } from 'react'
import { Pagination } from "../index"
import PropTypes from 'prop-types';

export class TableWrapper extends Component {

	state = {
		currentSortKeyDetails: null
	}

	getPlaceHolder = () => {

		let { placeHolder = 'No data found' } = this.props

		if (typeof placeHolder === 'function') {
			return placeHolder()
		}

		return <p className="text-center">{placeHolder}</p>

	}

	handlePagination = (pageNo) => {
		
		let { queryHandler, scrollProps = '' } = this.props
		if (queryHandler) {
			queryHandler({ pageNo })
		}

	}

	handleFilter = (sortKey) => {

		if (!sortKey) {
			return ''
		}

		let currentSortKeyDetails = Object.assign({}, this.state.currentSortKeyDetails)

		if (!currentSortKeyDetails || currentSortKeyDetails.sortKey != sortKey) {
			currentSortKeyDetails = {
				sortKey,
				orderBy: 'asc'
			}
		} else {
			if (currentSortKeyDetails.orderBy != 'desc') {

				currentSortKeyDetails.orderBy = 'desc'
			} else {
				currentSortKeyDetails = null
			}
		}

		this.setState({
			currentSortKeyDetails
		}, () => {
			this.handleFilterAPI()
		})

	}

	handleFilterAPI = () => {

		let { sortKey = null, orderBy = null } = this.state.currentSortKeyDetails || {}

		let { queryHandler } = this.props

		if (queryHandler) {

			let sort = {
				field: sortKey,
				orderBy
			}

			queryHandler({ sort })
		}

	}


	render() {
		
		let { headerDetails, children, pageMeta = {}, isEmpty = false, isLoading = false } = this.props
		let { sortKey: currentSortKey, orderBy = '' } = this.state.currentSortKeyDetails || {}

		return (
			<div>
				<div className="table-responsive">
					<table className="table table-hover">
						<thead>
							<tr>
								{headerDetails.map(({ label, className, sortKey = '', element }, index) => {
									return (
										<th className={className} key={index}>
											<div
												className={`d-flex align-items-center ${sortKey && 'cursor-pointer'} `}
												onClick={e => this.handleFilter(sortKey)}
											>
												{label}{element && element()}
												{sortKey ?
													<div className={`d-flex flex-column table-filter ml-2 mb-1 ${currentSortKey == sortKey && 'active-filter'}`}>
														<span className={`icon-sort-up ${currentSortKey == sortKey && orderBy == 'asc' && 'active'}`} />
														<span className={`icon-sort-down ${currentSortKey == sortKey && orderBy == 'desc' && 'active'}`} />
													</div>
													: ''}
											</div>
										</th>
									)
								})}
							</tr>
						</thead>
						<tbody>
							{children}
						</tbody>
					</table>
					{!isEmpty ? this.getPlaceHolder() : ""}
				</div>
				{pageMeta && <Pagination
					handleChange={this.handlePagination}
					pageMeta={pageMeta}
					isLoading={isLoading}
				/>}
			</div>
		)
	}
}

TableWrapper.propTypes = {
	placeHolder: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
	]),
	isEmpty: PropTypes.bool,
	headerDetails: PropTypes.array.isRequired,
	pageMeta: PropTypes.object,

}
