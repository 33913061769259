import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleReactValidator from 'simple-react-validator';
import { ContentMainHeader, DateTime, ReactSelect, SliderOverlay, NormalInput } from "../../component/common";
import { hidePastDate } from "../../services/helperFunctions";
import Accordion from 'react-bootstrap/Accordion';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import {ItineraryModal} from '../../component/itinerary/itineraryModal'
import transport from '../../assets/images/transport.png'
import { themeOptionList, interLocationVehicleList, stayOptionsList, mealOptionsList } from '../../component/itinerary/dropdownsList';
import { iternaryGenerate, getItineraryMasterData, saveItinerary, getItineraryDetailsById, getCityNames, getBase64Images } from '../../redux/actions/itinarary';
import { LoaderWrapper } from "../../HOC";
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image';
import ShortUniqueId from 'short-unique-id';
import logo from '../../assets/images/logo_sair.png';
import html2canvas from 'html2canvas';
import jsPDF from'jspdf';
import _ from 'lodash';
import Logo from "../../assets/images/logo.png";
import App from '../../component/itinerary/dragAndDrop';
import moment from 'moment';

var div_height = 20;
var tmp;
var activity_total_price_each_day = 0;
var act_index=0;
var open_stay_tab = 'master_stay';
var disable_pickupdrop = false;
class ItineraryCreateClass extends Component {
    
    constructor(){
        super();
        tmp=this;
    }

    state = {
        add_master_activity: false,
        add_custom_activity: false,
        add_custom_stay_option: false,
        add_master_stay_option: true,
        loader_preview: false,
        disable_preview: true,
        open_tab : 'master_stay',
        cityList: [],
        city_image: "",
        city_banner_image: "",
        activity_images: [],
        activity_images_img: [],
        isOpen: false,
        activity_id: "",
        hotelBase64Images: [],
        hotelTitle: "",
        custom_activity: {
            id: "",
            title: "",
            "child price": '',
            price: '',
            description: "",
            total_price: ""
        },
        custom_activity_data: [],
        custom_activity_add: {
            id: "",
            title: "",
            "child price": '',
            price: '',
            description: "",
            total_price: ""
        },
        custom_stay_option_data: {
            id: '',
            custom_stay_type: {id: '', name: ''},
            custom_hotel_type: {id: '', name: ''},
            custom_room_single_share: {
                id: '', 
                name: 'Single Share',
                count: 0,
                price_per_room: 0
            },
            custom_room_double_share: {
                id: '', 
                name: 'Double Share',
                count: 0,
                price_per_room: 0
            },
            custom_room_tripple_share: {
                id: '', 
                name: 'Tripple Share',
                count: 0,
                price_per_room: 0
            },
            custom_meal_price: {
                id: '',
                name: '',
                price_per_person: ''
            },
            image_url: [],
        },
        loader: false,
        loader_image: false,
        parent_ref_id: "",
        popupOpen: false,
        popupOpenImg: false,
        ref_id: '',
        ItineraryData: {
            cities: "",
            theme: "1",
            adultCount: 1,
            childrenCount: 0,
            intentsityLevel: "Medium",
            mealId: "",
            activity_date: "",
            stayTypeId: "",
            city_options: [{
                name: '',
                noOfDays: ''
            }]
        },
        stayOptionType: {
            staytype: "",
            hoteltype: "",
            roomtype: "",
            capacity: "",
            staytypelabel: "",
            hoteltypelabel: "",
            roomtypelabel: [],
            room_price: 0,
            meal_price: 0,
            mealtype: "",
            total_price: 0,
            mealDescription: {
                type_meal: "",
                meal_price: 0
            },
            room_options : [

            ]
        },
        transportOptionType: {
            intra_location : {
                type: "",
                labelname: '',
                count: 0,
                price: 0,
                capacity: '',
                full_day_price: 0,
                no_of_full_days: 0,
                half_day_price: 0,
                no_of_half_days: 0,
                pick_up_drop: '',
                pick_up_drop_price: 0,
                pickup_drop_price: 0
            },
            s_d_inter_location: {
                type: "",
                source: "",
                destination: "",
                price: 0,
                labelname: ''
            },
            d_s_inter_location: {
                type: "",
                source: "",
                destination: "",
                price: 0,
                labelname: ''
            }
        },
        activityOptionType: {
            itinerary: [],
            activities: [{
                id: "",
                title: "",
                description: "",
                price: "",
                child_price: ""
            }]

        },
        totalPrice: {
            total_itinerary_price: 0,
            total_price_per_person: 0,
            display_total_price: false,
            edit_price: false
        },
        hotelDropdown: [],
        roomDropdown: [],
        mealDropdown: [],
        count: 0,
        activityLevelLists: [
            { id: 1, label:'Low (fewer no of activites)', value: 'Low' },
            { id: 2, label:'Medium ( moderate level of activies)', value: 'Medium' },
            { id: 3, label:'High( high no of activites)', value: 'High' }
        ],
        mealOption: [
			{
				label: "Yes",
				value: "true"
			},
			{
				label: "No",
				value: "false"
			}
		],
        hotelTypeLists: [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
        ],
        intraLocationPickupDropOptions: [
            { value: 'select...', label: 'Select...'},
            { value: 'pickup', label: 'Pickup'},
            { value: 'drop', label: 'Drop'},
            { value: 'pickup_drop', label: 'Pickup & Drop'},
        ],
        openToggle: false,
        stay_option: "",
        transport_option: "",
        response_data: {},
        transport_response_data: {},
        activity_response_data: {},
        masterData: {},
        additionalData:{}
    }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
        });
		this.validators = new SimpleReactValidator({
			element: message => {
				let messages = message.split('/');
				return (
					<span className="error-message font-md">
						{`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
					</span>
				)
			},
		});
    }

    componentDidMount(){
        let { match: { params: { id } }, history } = this.props;
        
        this.props.getCityNames().then((data)=>{
            if(data.length > 0){
                let cityData = data;
                let cityList = [];
                cityData.forEach((item, index)=>{
                    cityList.push({
                        id: index,
                        label: item,
                        value: item 
                    })
                })
                this.setState({cityList: cityList});      
            }
        });
        open_stay_tab = "custom_stay"
        if(id){
            this.props.getItineraryDetailsById(id).then((data) => {
                let {
                    inputs
                } = data;
                open_stay_tab = data.config.cities[0].stayResponse.add_custom_stay_option ? "custom_stay" : "master_stay";
               let { ItineraryData } = this.state;
               let parent_ref = history.location.search;
               parent_ref = parent_ref.length == 7 ? parent_ref.slice(1,7) : "";
               ItineraryData = inputs;
               this.setState({ItineraryData: ItineraryData, parent_ref_id: parent_ref}); 
               let inclusions = []
               if(data.config.inclusions){
                   inclusions = data.config.inclusions;
               }
               let exclusions = []
               if(data.config.exclusions){
                exclusions = data.config.exclusions;
               } 
               if(data.config.cities[0].stayResponse){
                      let response_data = data.config.cities[0].stayResponse;
                      
                      this.setState({response_data: response_data}, () => {
                        
                        let stayOptionType = Object.assign({}, this.state.stayOptionType);
                          let response_data = Object.assign({}, this.state.response_data);
                          let add_custom_stay_option = this.state;
                          stayOptionType.staytype = response_data.stayType;
                          stayOptionType.hoteltype = response_data.hotel;
                          stayOptionType.roomtype = response_data.rooms[0] ? response_data.rooms[0].id : "0" ;
                          stayOptionType.mealtype = response_data.meal.meal_type;
                          stayOptionType.meal_price = response_data.meal.meal_price;
                          let custom_stay_option_data = response_data.custom_stay_option_data;
                          stayOptionType.room_options = response_data.room_options;
                          add_custom_stay_option = response_data.add_custom_stay_option;
                          if(add_custom_stay_option==true){
                              this.setState({open_tab: "custom_stay", add_custom_stay_option: true, custom_stay_option_data, add_master_stay_option: false});
                          }
                          this.setState({stayOptionType:stayOptionType});
                          let cityList = [];
                          if(this.state.ItineraryData.city_options.length>0){
                            let cityArray = this.state.ItineraryData.city_options;
                            
                            for(let i=0; i<cityArray.length; i++){
                                cityList.push(cityArray[i].name);
                            } 
                        }
                        this.props.getItineraryMasterData({cities: cityList}).then((data) => {
                            this.setState({masterData: data.cityInfo, additionalData:data.metaInfo}, ()=> {
                                
                                let additionalData = this.state.additionalData;
                                additionalData[0].inclusions = inclusions;
                                additionalData[0].exclusions = exclusions;
                                this.setState({additionalData});
                                if(!this.state.add_custom_stay_option){
                                    this.handleStayOption({value: this.state.stayOptionType.staytype, name: "staytype"});
                                    this.handleHotelOption({value: this.state.stayOptionType.hoteltype, name: "hoteltype"});
                                    this.handleRoomOption({value: this.state.stayOptionType.roomtype, name: "roomtype", updateTotal: "updateTotal", room_options: this.state.stayOptionType.room_options});
                                }
                            });
                          })
                      })
                  }
                  if(data.config.cities[0].transportResponse){
                    let response_data = data.config.cities[0].transportResponse;
                    
                    this.setState({transport_response_data: response_data}, () => {
                        let transportOptionType = Object.assign({}, this.state.transportOptionType);
                        let transport_response_data = Object.assign({}, this.state.transport_response_data);
                        transportOptionType.intra_location = transport_response_data.intra_location;
                        transportOptionType.s_d_inter_location = transport_response_data.s_d_inter_location;
                        transportOptionType.d_s_inter_location = transport_response_data.d_s_inter_location;
                        this.setState({transportOptionType:transportOptionType});
                    })
                }
                if(data.config.cities[0].activityResponse){
                    let response_data = data.config.cities[0].activityResponse;
                    this.setState({activity_response_data: response_data}, () => {
                        let activityOptionType = Object.assign({}, this.state.activityOptionType);
                        let activity_response_data = Object.assign({}, this.state.activity_response_data);
                        activityOptionType = activity_response_data;
                        this.setState({activityOptionType});
                    })
                }
                if(data.config.cities[0].custom_data){
                    let response_data = data.config.cities[0].custom_data;
                    if(response_data != undefined){
                        this.setState({custom_activity: response_data, custom_activity_add: response_data, custom_activity_data: response_data})
                    }
                }
                if(data.config.cities[0].totalPrice){
                    this.setState({totalPrice: data.config.cities[0].totalPrice, disable_preview: false});
                }
            })
        }
    }

    handleInputs = ({ target: { value, name } }) => {
		let ItineraryData = Object.assign({}, this.state.ItineraryData);
        
        if(name === "adultCount" || name === "childrenCount" || name === "noOfDays") {
            ItineraryData[name] = value === "" ? "" : Number(value);
        }
        else {
		ItineraryData[name] = value;
        }
		this.setState({
			ItineraryData
		})
    };

    handleAddCustomStayOptionImages =({target: {value, name}}) => {
        let custom_stay_option_data = Object.assign({}, this.state.custom_stay_option_data);
        custom_stay_option_data.image_url.push(value);
        this.setState({custom_stay_option_data});
    }

    handleTotalPrice = ({target:{value, name}}) => {
        let totalPrice = Object.assign({}, this.state.totalPrice);
        if(name == "display_total_price"){
            totalPrice[name] = !totalPrice[name];
        }
        else{
            totalPrice[name] = value;
            totalPrice["edit_price"] = true;
        }
        this.setState({totalPrice});
    }

    handleDateInput = ({ value, name }) =>{
        let ItineraryData = Object.assign({}, this.state.ItineraryData);
        value = moment(value).local().format('YYYY-MM-DD');
        ItineraryData[name] = value;
        
		this.setState({
			ItineraryData
		})
    }

    handleSelectInputs = ({ value, name }) => {
		let ItineraryData = Object.assign({}, this.state.ItineraryData)
        ItineraryData[name] = value;
		this.setState({
			ItineraryData
        },()=>{
            if(name == "cities"){
                if(this.state.ItineraryData.cities != ""){
                    let query = {cities: this.state.ItineraryData.cities}
                    let cityArray = this.state.ItineraryData.cities;
                    this.props.getItineraryMasterData({cities: [cityArray]}).then((data) => {
                            this.setState({masterData: data.cityInfo});
                            this.setState({additionalData: data.metaInfo});
                    })
                }
            }
        })
    }
    handleCitySelectInputs = ({ value, name, index }) => {
        let ItineraryData = Object.assign({}, this.state.ItineraryData)
        ItineraryData["city_options"][index][name] = value;
		this.setState({
			ItineraryData
        }, ()=>{
            this.getMasterData();
        })
    }

    getMasterData = () => {
        if(this.state.ItineraryData.city_options.length>0){
            let cityArray = this.state.ItineraryData.city_options;
            let cityList = [];
            for(let i=0; i<cityArray.length; i++){
                cityList.push(cityArray[i].name);
            } 
            this.props.getItineraryMasterData({cities: cityList}).then((data) => {
                    this.setState({masterData: data.cityInfo});
                    this.setState({additionalData: data.metaInfo});
            })
        }
    }

    handleStayOption = ({ value, name } ) => {
        let stayOptionType = Object.assign({}, this.state.stayOptionType);
        let totalPrice = Object.assign({}, this.state.totalPrice);
        stayOptionType[name] = value;
        stayOptionType["hoteltype"] = "";
        stayOptionType["roomtype"] = "";
        stayOptionType["capacity"] = "";
        totalPrice["edit_price"] =  false;
        let stayTypeDetails = _.filter(this.state.masterData[0].stay,(item)=>{
            return parseInt(item.id) === parseInt(value)
        })[0]; //It will return object instead of array

        stayOptionType["staytypelabel"] = stayTypeDetails.type;
        
        let hotelData = stayTypeDetails && stayTypeDetails.hotels ? stayTypeDetails.hotels : [];
        stayOptionType["hoteltypelabel"] = hotelData[0].name;
        let  hotelDropdown  = []
        hotelData.forEach(item => {
            hotelDropdown.push({
                label: item.name,
                value: item.id
            })
        });
        this.setState({hotelDropdown: hotelDropdown});
		this.setState({
			stayOptionType, totalPrice
		});
    }

    handleHotelOption= ({ value, name } ) => {
        let stayOptionType = Object.assign({}, this.state.stayOptionType);
        let totalPrice = Object.assign({}, this.state.totalPrice);
        stayOptionType[name] = value;
        totalPrice["edit_price"] =  false;
        let stay = this.state.masterData[0].stay;
        let stayData = _.filter(stay,(item)=>{
            return parseInt(item.id) === parseInt(stayOptionType.staytype)
        })[0];
        stayOptionType["staytypelabel"] = stayData.type;
        let hotelData = _.filter(stayData.hotels, (item)=>{
            return parseInt(item.id) === parseInt(value)
        })
        stayOptionType["hoteltypelabel"] = hotelData[0].name;
        let roomData = hotelData[0].rooms;
        let roomDropdown = _.map(roomData, (item)=>{
            return {
                label: item.description,
                value: item.id
            }
        })
        
        let mealData = hotelData[0].meal;
        _.remove(mealData, (item)=>{
            return item.price == 'NA' || item.price=='';
        })

        let mealDropdown = _.map(mealData, (item)=>{
            let new_label = item.type;
            return {
                label: new_label,
                value: item.id
            }
        })

        mealDropdown.push({label: "Breakfast Only", value: "3"});
    
		this.setState({
			stayOptionType, roomDropdown, mealDropdown, totalPrice
		})
	}

    handleRoomOption = ({ value, name, updateTotal, room_options }) => {
        let stayOptionType = Object.assign({}, this.state.stayOptionType);
        stayOptionType[name] = value;
        let totalPrice = Object.assign({}, this.state.totalPrice);
        totalPrice["edit_price"] =  false;
        let stay = this.state.masterData[0].stay;
        let stayData = _.filter(stay,(item)=>{
            return parseInt(item.id) === parseInt(stayOptionType.staytype)
        })[0];
        let hotelData = _.filter(stayData.hotels, (item)=>{
            return parseInt(item.id) === parseInt(stayOptionType.hoteltype)
        })

        for(let i=0; i<room_options.length; i++){
            let roomData = _.filter(hotelData[0].rooms, (item)=>{
                return parseInt(item.id) === parseInt(room_options[i].id)
            })[0];
            room_options[i]["price"] = roomData.price;
            if(room_options[i].count == 0){
                room_options[i]["price"] = 0;
            }
            room_options[i]["description"] = roomData.description;
            room_options[i]["capacity"] = roomData.capacity;
            if(room_options[i].count!=0){
                stayOptionType.roomtypelabel.push({name: room_options[i]["description"], count: room_options[i].count});
            }
        }
        stayOptionType.room_options  = room_options;
        let roomData = _.filter(hotelData[0].rooms, (item)=>{
            return parseInt(item.id) === parseInt(value)
        });
        stayOptionType["staytypelabel"] = stayData.type;
        stayOptionType["hoteltypelabel"] = hotelData[0].name;
        
        let room_price = roomData[0].price;
        let capacity = roomData[0].capacity;
        stayOptionType["room_price"] = room_price;
        stayOptionType["capacity"] = capacity;
		this.setState({
			stayOptionType, totalPrice
		}, () => {
            if(updateTotal == undefined){
                this.updateTotalPrice();
            }
        })
    }

    handleIntraLocationOption = ({value, name}) => {
        let transportOptionType = Object.assign({}, this.state.transportOptionType);
        let transport = this.state.masterData[0].transport.intraLocation;
        let transportData = _.filter(transport, (item)=> {
            return parseInt(item.id) == parseInt(value)
        })[0];
        let full_day_price = transportData.dayPrice;
        let half_day_price = transportData.halfDayPrice;
        let pickupdrop = transportData.pickupDrop;

        let capacity = Number(transportData.maxPax);
        transportOptionType["intra_location"]["capacity"] = Number(transportData.maxPax);
        let total_people = Number(this.state.ItineraryData.adultCount) + Number(this.state.ItineraryData.childrenCount)
        transportOptionType["intra_location"]["count"] = Math.ceil(total_people/capacity);
        transportOptionType["intra_location"]["full_day_price"] = Number(full_day_price);
        transportOptionType["intra_location"]["half_day_price"] = Number(half_day_price);
        transportOptionType["intra_location"]["pick_up_drop_price"] = pickupdrop  != "NA" &&pickupdrop!="" &&  !isNaN(pickupdrop) ? Number(pickupdrop):0;
        disable_pickupdrop = pickupdrop=="NA" ? true : false;
        let totalPrice = Object.assign({}, this.state.totalPrice);
        totalPrice["edit_price"] =  false;
        transportOptionType["intra_location"]["labelname"] = transportData.vehicle;
        this.setState({transportOptionType, totalPrice}, ()=>{
            this.updateTotalPrice();
        });
    }

    handleMealOption = ({ value, name } ) => {
        let stayOptionType = Object.assign({}, this.state.stayOptionType);
        stayOptionType[name] = value;
        let stay = this.state.masterData[0].stay;
        let stayData = _.filter(stay,(item)=>{
            return parseInt(item.id) === parseInt(stayOptionType.staytype)
        })[0];

        let hotelData = _.filter(stayData.hotels, (item)=>{
            return parseInt(item.id) === parseInt(stayOptionType.hoteltype)
        })
        let mealData = _.filter(hotelData[0].meal, (item)=>{
            return parseInt(item.id) === parseInt(value)
        })
        
        let meal_price = mealData && mealData[0] && mealData[0].price || 0;
        stayOptionType["meal_price"] = meal_price;
        let totalPrice = Object.assign({}, this.state.totalPrice);
        totalPrice["edit_price"] =  false;

		this.setState({
			stayOptionType, totalPrice
		}, ()=>{
            this.updateTotalPrice();
        })
    }

    handlePickupDropOption = ({value, name, type, options}) => {
        let transportOptionType = Object.assign({}, this.state.transportOptionType); 
        transportOptionType[type][name] = value;
        
        if(value=="select..."){
            transportOptionType[type]["pickup_drop_price"] = 0;
        }
        else if(value=="pickup" || value=="drop"){
            transportOptionType[type]["pickup_drop_price"] = Number(transportOptionType[type]["pick_up_drop_price"]);
        }
        else{
            transportOptionType[type]["pickup_drop_price"] = 2 * Number(transportOptionType[type]["pick_up_drop_price"]);
        }
        this.setState({transportOptionType}, ()=>{
            this.updateTotalPrice();
        })
    }
    
    handleVehicleOption = ({value, name, type, options}) => {
        let transportOptionType = Object.assign({}, this.state.transportOptionType);
        transportOptionType[type][name] = value;
        if(type=="s_d_inter_location" || type=="d_s_inter_location"){
            transportOptionType[type]["labelname"] = _.filter(options,(item)=>{
                return item.value == value;
            })[0].label
        }
        if(type == "intra_location"){
            let vehicle_capacity = _.filter(options, (item)=>{
                return item.value == value
            })[0].capacity;

            let full_day_price = _.filter(options, (item)=>{
                return item.value == value
            })[0].full_day_price;
            
            let half_day_price = _.filter(options, (item)=>{
                return item.value == value
            })[0].half_day_price;
            
            let vehicle_name = _.filter(options, (item)=>{
                return item.value == value
            })[0].label;
            transportOptionType[type]["labelname"] = vehicle_name;
            transportOptionType[type]["capacity"] = vehicle_capacity;
            let total_people = Number(this.state.ItineraryData.adultCount) + Number(this.state.ItineraryData.childrenCount)
            transportOptionType[type]["count"] = vehicle_capacity!=0 ? Math.ceil(total_people/vehicle_capacity) : 0;
            transportOptionType[type]["half_day_price"] = half_day_price;
            transportOptionType[type]["full_day_price"] = full_day_price;
        }
        let totalPrice = Object.assign({}, this.state.totalPrice);
        totalPrice["edit_price"] =  false;
        this.setState({transportOptionType, totalPrice}, ()=>{
            this.updateTotalPrice();
        });
    }

    handleTransportOption = ({target: { value, name }}) => {
        let transportOptionType = Object.assign({}, this.state.transportOptionType);
        transportOptionType["s_d_inter_location"][name] = value;

        let totalPrice = Object.assign({}, this.state.totalPrice);
        totalPrice["edit_price"] =  false;
        this.setState({transportOptionType, totalPrice}, ()=>{
            this.updateTotalPrice();
        });  
	}

    handleDSTransportOption = ({target: { value, name }}) => {
        let transportOptionType = Object.assign({}, this.state.transportOptionType);
        transportOptionType["d_s_inter_location"][name] = value;

        let totalPrice = Object.assign({}, this.state.totalPrice);
        totalPrice["edit_price"] =  false;
        this.setState({transportOptionType, totalPrice}, ()=>{
            this.updateTotalPrice();
        });  
	}

    toDataUrl=(url) => {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                resolve(reader.result);
            }
            reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }
  
    handleSubmit = () => {
        if (this.validator.allValid()) {
            let ItineraryData  = Object.assign({}, this.state.ItineraryData);
            if(ItineraryData.activity_date == ''){
                ItineraryData.activity_date = moment(new Date()).local().format('YYYY-MM-DD');;
            }
            ItineraryData.cities = ItineraryData.city_options
            this.props.iternaryGenerate(ItineraryData).then((data_generated)=>{
                let data = data_generated[0];
                this.setState({disable_preview: false});
                if(data.stayResponse){
                    let response_data = data.stayResponse;
                    this.setState({response_data: response_data}, () => {
                        let stayOptionType = Object.assign({}, this.state.stayOptionType);
                        let response_data = Object.assign({}, this.state.response_data);
                        stayOptionType.staytype = response_data.stayType;
                        stayOptionType.hoteltype = response_data.hotel;
                        stayOptionType.roomtype = response_data.rooms[0] ? response_data.rooms[0].id : "0" ;
                        let room_options = response_data.rooms;
                        let room_ids = ["1", "2", "3"];
                        stayOptionType.mealtype = response_data.meal.id;
                        for(let i=0; i<room_ids.length;i++){
                            let room_item = room_options.filter(item => {
                                return item.id == room_ids[i]
                            })[0];
                            if(room_item == undefined){
                                room_options.push({id: room_ids[i], count: 0});
                            }
                        }
                        stayOptionType.room_options = room_options;
                        this.setState({stayOptionType:stayOptionType},()=>{
                            this.handleStayOption({value: this.state.stayOptionType.staytype, name: "staytype"});
                            this.handleHotelOption({value: this.state.stayOptionType.hoteltype, name: "hoteltype"});
                            this.handleRoomOption({value: this.state.stayOptionType.roomtype, name: "roomtype", room_options: this.state.stayOptionType.room_options});
                        });
                    })
                }
                if(data.transportResponse){
                    this.setState({transport_response_data: data.transportResponse}, () => {
                        let transportOptionType = Object.assign({}, this.state.transportOptionType);
                        let transport_response_data = Object.assign({}, this.state.transport_response_data);
                        transportOptionType.intra_location["type"] = transport_response_data.intraLocation.id;
                        transportOptionType.intra_location["labelname"] = transport_response_data.intraLocation.vehicle;
                        this.setState({transportOptionType : transportOptionType}, ()=>{
                            this.handleIntraLocationOption({value: this.state.transportOptionType.intra_location.type, name: "type"});
                        });
                    })
                }
                if(data.activityResponse){
                    this.setState({activity_response_data: data.activityResponse}, () => {
                        let activity_response_data = Object.assign({}, this.state.activity_response_data);
                        
                        this.setState({activityOptionType : activity_response_data}, ()=>{
                            let topRankedSightSeeingActivityId = data.topRankedSightSeeingActivityId;
                            let itinerary = this.state.activityOptionType.            itinerary
                            if(itinerary.length >1){
                                let checkId = itinerary[1].activityIds.indexOf(topRankedSightSeeingActivityId);
                                if(checkId>-1){
                                    let transportOptionType = this.state.transportOptionType;
                                    transportOptionType.intra_location["no_of_full_days"] = 1;
                                    this.setState({transportOptionType},()=>{
                                        this.findTotalPrice();
                                    })
                                }
                            } 
                            activity_total_price_each_day = 0;
                            this.findTotalPrice();
                        });
                    })
                }
            })
        }
        else{
            this.validator.showMessages();
        }       
    };

    saveConfig = () => {
        let { stayOptionType, ItineraryData, ref_id, transportOptionType, activityOptionType, custom_activity, totalPrice, add_custom_stay_option, custom_stay_option_data, additionalData, custom_activity_data } = this.state;
        let { staytype = "", hoteltype = "", roomtype = "", capacity = 0, mealtype="", meal_price="", room_options } = stayOptionType;
        let { adultCount = 0, childrenCount = 0 } = ItineraryData;
        let { intra_location, s_d_inter_location,d_s_inter_location } = transportOptionType;
        let stayResponse = {};
        stayResponse.stayType = staytype;
        stayResponse.hotel = hoteltype;
        stayResponse.meal = {meal_type:mealtype, meal_price: meal_price};
        stayResponse.custom_stay_option_data = custom_stay_option_data;
        stayResponse.add_custom_stay_option = add_custom_stay_option;
        stayResponse.room_options = room_options;
        let room_data = []
        let count = 0
        if(capacity != 0){
           count =  (Number(adultCount) + Number(childrenCount)) / Number(capacity);
           count = Math.ceil(count);
        }
        if(roomtype != ""){
            room_data.push({id: roomtype, count: count });  
        }
        if(ItineraryData.activity_date == ''){
            ItineraryData.activity_date = moment(new Date()).local().format('YYYY-MM-DD');
        }
        stayResponse.rooms = room_data;  
        let inclusions = additionalData[0] && additionalData[0].inclusions ? additionalData[0].inclusions : "";
        let exclusions = additionalData[0] && additionalData[0].exclusions ? additionalData[0].exclusions : "";
        let config_data = [];
        config_data = {config: {cities: [{city_name: ItineraryData.city_options[0].name, stayResponse: stayResponse, transportResponse: transportOptionType, activityResponse: activityOptionType, custom_data:custom_activity_data, totalPrice: totalPrice}], inclusions: inclusions, exclusions: exclusions}, ref_id: ref_id, parent_ref_id: this.state.parent_ref_id, inputs: ItineraryData };

        return this.props.saveItinerary(config_data).then((id)=>{
        }).catch((e)=>{
            this.setState({loader: false, loader_image: false})
        })
    }

    async generatePDF() {
        let { popupOpen, popupOpenImg, masterData, loader_preview, stayOptionType } = this.state;
        let uid = new ShortUniqueId();
        uid = uid.randomUUID(6);
        this.setState({ ref_id: uid, loader_preview: true });

        let stay = this.state.masterData[0].stay;
        let stayData = _.filter(stay, (item) => {
            return parseInt(item.id) === parseInt(this.state.stayOptionType.staytype)
        })[0];
        let hotelData = _.filter(stayData.hotels, (item) => {
            return parseInt(item.id) === parseInt(this.state.stayOptionType.hoteltype)
        })
        let hoteldetail = hotelData[0];
        let hotelTitle = hoteldetail.name;
        let hotelBase64Images = hoteldetail.base64Pics || [];
        let { activityOptionType } = this.state;
        let city_name = this.state.ItineraryData.city_options[0].name;
        let hotel_name = hotelTitle.toLowerCase();
        hotel_name = hotel_name.replace(/ /g, "_");
        let room_options = stayOptionType.room_options;
        let roomtypelabel = [];
        for(let i=0; i<room_options.length; i++){
            if(room_options[i].count!=0){
                roomtypelabel.push({name: room_options[i]["description"], count: room_options[i].count});
            }
        }
        stayOptionType.roomtypelabel = roomtypelabel;
        this.setState({stayOptionType});

        let s3_url = "https://itinerary-creator-images.s3.ap-south-1.amazonaws.com/" + city_name + "/" + city_name;
        
        let url_img_arr = [], url_arr =[];
        let act_img_len = 0, activity_images_img=[], activity_images=[], act_list_img_url=[];
        activityOptionType.itinerary.forEach((day) => {
            let act_list = day.activityIds;
            if(act_img_len < 5){
                act_list.forEach((item) => {
                    if(act_img_len<5 && (item+"").slice(0,6)!="custom"){
                        url_img_arr.push(s3_url + "_activity_" + item + "_jpeg.png");
                        url_arr.push(s3_url + "_activity_" + item + "_jpeg.png");
                        act_img_len = Number(act_img_len) + 1;
                    }
                })
            }
        })
        if (act_img_len < 5) {
            masterData[0].activities.forEach(item => {
                if(act_img_len<5 && (item.id+"").slice(0,6) != "custom"){
                    url_img_arr.push(s3_url + "_activity_" + item.id + "_jpeg.png");
                    url_arr.push(s3_url + "_activity_" + item.id + "_jpeg.png");
                    act_img_len = Number(act_img_len) + 1;                
                }
            })
        }
        activityOptionType.itinerary.forEach((day) => {
            let act_list = day.activityIds;
            let act_url_arr = []
            act_list.forEach((item) => {
                if((item+"").slice(0,6)!="custom"){
                    url_arr.push(s3_url + "_activity_" + item + "_pdf_1.png");
                    url_arr.push(s3_url + "_activity_" + item + "_pdf_2.png");
                    act_url_arr.push(s3_url + "_activity_" + item + "_pdf_1.png");
                    act_url_arr.push(s3_url + "_activity_" + item + "_pdf_2.png");
                }
            })
            act_list_img_url.push(act_url_arr);
        })
        let city_image_url = s3_url + "_cityimage.png";
        let city_banner_img_url = s3_url + "_banner_pdf.png";

        let {add_custom_stay_option, custom_stay_option_data} = this.state;
        let hotel_img_url_1 = s3_url + "_hotel_" + hotel_name + "_exterior.png";
        let hotel_img_url_2 = s3_url + "_hotel_" + hotel_name + "_bedroom.png";

        if(add_custom_stay_option == true){
            stayOptionType.staytype = "";
            stayOptionType.hoteltype = "";
            stayOptionType.roomtype = "";
            for(let i=0; i<stayOptionType.room_options.length;i++){
                stayOptionType.room_options[i].count = 0;
            }
            stayOptionType.room_price = 0;
            stayOptionType.meal_price = 0;
            stayOptionType.mealtype = 0;
            this.setState({stayOptionType});
            hotel_img_url_1 = custom_stay_option_data.image_url && custom_stay_option_data.image_url[0] || "";
            hotel_img_url_2 = custom_stay_option_data.image_url && custom_stay_option_data.image_url[1] || "";
        }
        url_arr.push(city_image_url)
        url_arr.push(city_banner_img_url)
        url_arr.push(hotel_img_url_1)
        url_arr.push(hotel_img_url_2)
        let city_image = "" , city_banner_image = "", hotelBase64ImagesUrls=[];
        this.props.getBase64Images({urls: url_arr}).then((data)=>{
            city_image = _.filter(data, (base64item) => {
                return base64item.url == city_image_url;
            })[0].base64Image;
            city_banner_image = _.filter(data, (base64item) => {
                return base64item.url == city_banner_img_url;
            })[0].base64Image;
            let hotel_img_1 = _.filter(data, (base64item) => {
                return base64item.url == hotel_img_url_1;
            })[0].base64Image;
            let hotel_img_2 = _.filter(data, (base64item) => {
                return base64item.url == hotel_img_url_2;
            })[0].base64Image;
            hotelBase64ImagesUrls.push(hotel_img_1);
            hotelBase64ImagesUrls.push(hotel_img_2);
            
            for(let i=0; i<5;i++){
                let img_preview_img = _.filter(data, (base64item) => {
                    return base64item.url == url_img_arr[i];
                })[0].base64Image;
                activity_images_img.push(img_preview_img);
            }
            for(let i=0; i<act_list_img_url.length;i++){
                let act_img_list_a = [];
                for(let j=0;j<act_list_img_url[i].length;j=j+2){
                    let img_preview_img = _.filter(data, (base64item) => {
                        return base64item.url == act_list_img_url[i][j];
                    })[0].base64Image;
                    let img_preview_img2 = _.filter(data, (base64item) => {
                        return base64item.url == act_list_img_url[i][j+1];
                    })[0].base64Image;
                    act_img_list_a.push({img1:img_preview_img, img2:img_preview_img2}); 
                }
                activity_images.push(act_img_list_a);
            }
            this.setState({ hotelBase64Images: hotelBase64ImagesUrls, hotelTitle, city_image, city_banner_image, activity_images, activity_images_img, loader_preview: false }, () => {
                this.setState({ popupOpen: !popupOpen, popupOpenImg: !popupOpenImg }, () => {
                    if (document.querySelector(".preview-scroll")) {
                        document.querySelector(".preview-scroll").scrollIntoView(true);
                    }
                });
            })
        })
    }

    generateImage = () => {
        let {popupOpenImg, popupOpen} = this.state;
        this.setState({popupOpen: !popupOpen, popupOpenImg: !popupOpenImg});
    }
    
    backPDF = () => {
        let {popupOpenImg, popupOpen} = this.state;
        this.setState({popupOpen: !popupOpen, popupOpenImg: !popupOpenImg})
    }

    backImage = () => {
        let {popupOpenImg} = this.state;
        this.setState({popupOpenImg: !popupOpenImg});
    }

    downloadPDF = () => {
        let promise1 = this.saveConfig();
        this.setState({ loader: true });
        const pdf = new jsPDF('p', 'pt', 'a4', true);
        promise1.then((
        )=>{
            let img_height = document.querySelector('.itinerary-node').offsetHeight;
            return htmlToImage.toPng(document.querySelector(".itinerary-node"), { quality: 0.6, width: 1000, height: img_height, logging: true })
        })
        .then((canvas) => {
                pdf.addImage(canvas, 'PNG', 20, 0);
                div_height = 20;
                let parentDiv = [];
                let subDiv = [];
                let promiseArr = [];
                let promiseSubArr = [];
                let divHeightSubArr = [];
                let divHeightParentArr = [];

                for (let j = 0; j < this.state.activityOptionType.itinerary.length; j++) {
                    let day = this.state.activityOptionType.itinerary[j];
                    let act_list = day.activityIds;
                    for (let i = 0; i < act_list.length; i++) {
                        let actItem = act_list[i];
                        if (document.querySelector('.activity-' + actItem)) {
                            let act_height = document.querySelector('.activity-' + actItem).offsetHeight;
                            if (div_height + act_height < 1100) {
                                subDiv.push(document.querySelector('.activity-' + actItem));
                                let canvas = html2canvas(document.querySelector('.activity-' + actItem), {useCORS: true, allowTaint : true,logging: true})
                                promiseSubArr.push(canvas);
                                divHeightSubArr.push(div_height);
                                div_height = div_height + act_height + 20;
                            }
                            else {
                                parentDiv.push(subDiv);
                                promiseArr.push(promiseSubArr);
                                divHeightParentArr.push(divHeightSubArr);
                                div_height = 20;
                                subDiv = [];
                                promiseSubArr = []
                                divHeightSubArr = []
                                divHeightSubArr.push(div_height);
                                div_height = div_height + act_height +20;
                                subDiv.push(document.querySelector('.activity-' + actItem));
                                let canvas = html2canvas(document.querySelector('.activity-' + actItem), {useCORS: true, allowTaint : true,})
                                promiseSubArr.push(canvas);
                            }
                        }
                    };
                }

                if (div_height == 20) {
                    parentDiv.push(subDiv);
                    promiseArr.push(promiseSubArr);
                    divHeightParentArr.push(divHeightSubArr);
                    promiseSubArr = []
                    divHeightSubArr = []
                    div_height = 20;
                }
                for (let index = 0; index < this.state.activityOptionType.suggestedActivities.length; index++) {
                    let item = this.state.activityOptionType.suggestedActivities[index];
                    let actItem = item;
                    if (document.querySelector('.activity-' + actItem)) {
                        let act_height = document.querySelector('.activity-' + actItem).offsetHeight;
                        if (div_height + act_height < 1100) {
                            subDiv.push(document.querySelector('.activity-' + actItem));
                            let canvas = html2canvas(document.querySelector('.activity-' + actItem), {useCORS: true, allowTaint : true,})
                            promiseSubArr.push(canvas);
                            divHeightSubArr.push(div_height);
                            div_height = div_height + act_height+20;
                        }
                        else {
                            parentDiv.push(subDiv);
                            promiseArr.push(promiseSubArr);
                            divHeightParentArr.push(divHeightSubArr);
                            div_height = 20;
                            subDiv = [];
                            promiseSubArr = []
                            divHeightSubArr = []
                            divHeightSubArr.push(div_height);
                            div_height = div_height + act_height+20;
                            subDiv.push(document.querySelector('.activity-' + actItem));
                            let canvas = html2canvas(document.querySelector('.activity-' + actItem), {useCORS: true, allowTaint : true,})
                            promiseSubArr.push(canvas);
                        }
                    };
                }
                parentDiv.push(subDiv);
                promiseArr.push(promiseSubArr);
                divHeightParentArr.push(divHeightSubArr);
                promiseSubArr = []
                divHeightSubArr = []
                div_height = 20;
                let additionalData = Object.assign({}, this.state.additionalData);
                additionalData = additionalData[0];
                let arrInfo = ["inclusions", "exclusions", "termsAndConditions", "additionalInfo",
                    "cancellationPolicies", "aboutUs", "benefitOfBookingWithSair"]
                arrInfo.forEach((add_item) => {
                    if (document.querySelector("." + add_item) && additionalData[add_item].length > 0) {
                        let inclusion_height = document.querySelector('.' + add_item).offsetHeight;
                        if (div_height + inclusion_height < 1100) {

                            let canvas = html2canvas(document.querySelector('.' + add_item), {useCORS: true, allowTaint : true,})
                            promiseSubArr.push(canvas);
                            divHeightSubArr.push(div_height);
                            div_height = div_height + inclusion_height+20;
                        }
                        else {
                            promiseArr.push(promiseSubArr);
                            divHeightParentArr.push(divHeightSubArr);
                            div_height = 20;
                            promiseSubArr = []
                            divHeightSubArr = []
                            divHeightSubArr.push(div_height);
                            div_height = div_height + inclusion_height+20;
                            let canvas = html2canvas(document.querySelector('.' + add_item), {useCORS: true, allowTaint : true,})
                            promiseSubArr.push(canvas);
                        }
                    }
                })
                promiseArr.push(promiseSubArr);
                divHeightParentArr.push(divHeightSubArr);
                return Promise.all(_.flattenDepth(promiseArr, 1)).then((canvasArr) => {
                    let k = 0;
                    for (let i = 0; i < divHeightParentArr.length; i++) {
                        pdf.addPage();
                        for (let j = 0; j < divHeightParentArr[i].length; j++) {
                            let height = divHeightParentArr[i][j];
                            height = 3 / 4 * height
                            let img = canvasArr[k].toDataURL('image/png');
                            pdf.addImage(img, 'PNG', 10, Number(height));
                            k++;
                        }
                    }
                });
            })
            .then(() => {
                pdf.save(this.state.ItineraryData.city_options[0].name + "_ki_sair_" + this.state.ref_id + '.pdf');
                this.setState({ loader: false });
            })
            .catch((err) => {
                console.error('oops, something went wrong!', err);
                this.setState({ loader: false });
            })
    }   

    downloadImage = () => {
        this.setState({loader_image: true}, ()=>{
            var node = document.querySelector('.itinerary-node-image');
            let promise3 = htmlToImage.toPng(node, { quality: 0.6, width: 1000, height: 1800 })
            .then(function (dataUrl) {
                download(dataUrl, 'itinerary.png');
            })
            Promise.all([promise3]).then((result) => {
                this.setState({loader_image: false});    
            }).catch((error)=>{
                console.error('oops, soething went wrong', error);
            })
        });
    }

    changeActivityList = (act_list, dayIndex) => {
        let activityOptionType = Object.assign({}, this.state.activityOptionType);
        let itinerary = activityOptionType.itinerary;
        itinerary[dayIndex].activityIds = act_list;
        this.setState({activityOptionType}, ()=>{
            activity_total_price_each_day = 0;
            this.findTotalPrice();
        });
    }

    calPrice = (elem) => {
        let ItineraryData = Object.assign({}, this.state.ItineraryData);
        let {adultCount, childrenCount} = ItineraryData;
        let price = Number(adultCount)  * Number(elem.price) + Number(childrenCount)   * Number(elem["child price"]);
        activity_total_price_each_day = Number(activity_total_price_each_day) + price;
        this.setState({edit_price: false});
    }

    findTotalPrice=()=>{
        let masterData = Object.assign({}, this.state.masterData);
        let activityDropdown = masterData[0].activities;
        this.state.activityOptionType.itinerary.map((item, dayIndex)=>{
            let act_list = item.activityIds;
            let act_details = []
            act_list.forEach((activityId) => {
                let resId = (activityId+"").slice(0, 6);
                if(resId != "custom"){
                    let elem = _.filter(activityDropdown, (actItem)=> {
                        return actItem.id == activityId;
                    })[0];
                    if(elem) {
                        this.calPrice(elem);
                        act_details.push(elem);
                    }
                }
                else{
                    let custom_activity_data = this.state.custom_activity_data;
                    let custom_elem = _.filter(custom_activity_data, (custom_item)=>{
                        return custom_item.id == activityId;
                    })[0]
                    this.calPrice(custom_elem);
                    act_details.push(custom_elem);
                }
            })
        })

        let {custom_stay_option_data, add_custom_stay_option} = this.state;
        let totalPrice = Object.assign({}, this.state.totalPrice);
        let ItineraryData = Object.assign({}, this.state.ItineraryData);
        let stayOptionType = Object.assign({}, this.state.stayOptionType);
        let transportOptionType = Object.assign({}, this.state.transportOptionType);
        let {s_d_inter_location, d_s_inter_location, intra_location} = transportOptionType;
        let {adultCount, childrenCount, city_options} = ItineraryData;
        let {capacity, room_price, meal_price, room_options} = stayOptionType;
        let master_stay_total_price = 0;
        let master_room_total_price = 0;
        let count_nights = Number(city_options[0].noOfDays-1) == 0 ? 1 : Number(city_options[0].noOfDays-1);
        room_options.forEach(item => {
            master_room_total_price = master_room_total_price + Number(item.count) * Number(item.price) * count_nights;
        })
        let master_meal_total_price = 0;
        master_meal_total_price = Number(meal_price) * Number(adultCount);
        master_stay_total_price = master_room_total_price + master_meal_total_price;
        
        let custom_stay_total_price = 0;
        let { custom_stay_type, custom_hotel_type, custom_room_single_share, custom_room_double_share, custom_room_tripple_share, custom_meal_price} = custom_stay_option_data;
        let custom_room_total_price = (Number(custom_room_single_share.count) * Number(custom_room_single_share.price_per_room) + Number(custom_room_double_share.count) * Number(custom_room_double_share.price_per_room) + Number(custom_room_tripple_share.count) * Number(custom_room_tripple_share.price_per_room)) * Number(Number(ItineraryData.city_options[0].noOfDays)-1);
        let custom_total_meal_price = 0;
        custom_total_meal_price = Number(custom_meal_price.price_per_person) * Number(ItineraryData.adultCount);
        custom_stay_total_price = custom_room_total_price + custom_total_meal_price;
        let total_amount = ( add_custom_stay_option ? custom_stay_total_price :  master_stay_total_price )+ (Number(intra_location["full_day_price"]) * Number(intra_location["no_of_full_days"]) + Number(intra_location["half_day_price"]) * Number(intra_location["no_of_half_days"]) + Number(intra_location["pickup_drop_price"]))*Number(intra_location["count"]) + Number(s_d_inter_location["price"]) + Number(d_s_inter_location["price"]);            
        let total_itinerary_price = total_amount + activity_total_price_each_day;
        let total_price_per_person = Math.ceil(total_itinerary_price / Number(adultCount));
        totalPrice.total_itinerary_price = total_itinerary_price;
        totalPrice.total_price_per_person = total_price_per_person;
        this.setState({totalPrice});
                
    }

    addMasterActivity = (key) => {
        if(key == "master"){
            let add_master_activity = this.state.add_master_activity;
            add_master_activity = true;
            this.setState({add_master_activity});
            let add_custom_activity = this.state.add_custom_activity;
            add_custom_activity = false;
            this.setState({add_custom_activity});
        }
        else{
            let add_custom_activity = this.state.add_custom_activity;
            add_custom_activity = true;
            this.setState({add_custom_activity});
            let add_master_activity = this.state.add_master_activity;
            add_master_activity = false;
            this.setState({add_master_activity});
        }
    }

    addCustomStayOption = (key) => {        
        if(key == "master_stay"){
            this.setState({add_master_stay_option: true, add_custom_stay_option: false}, ()=>{
                this.updateTotalPrice();
            });
        }
        else{
            this.setState({add_master_stay_option: false, add_custom_stay_option: true, open_tab: key}, ()=>{
                this.updateTotalPrice();
            });
        }
    }

    addCustomActivity = (key) => {
        let add_custom_activity = this.state.add_custom_activity;
        add_custom_activity = !add_custom_activity;
        this.setState({add_custom_activity});
    }

    handleAddMasterActivity = ({name, value, dayIndex}) => {
        this.setState({activity_id: value});
    }

    handleAddCustomActivity = ({target: { value, name }}) => {
        let custom_activity = Object.assign({}, this.state.custom_activity);
        let custom_activity_add = Object.assign({}, this.state.custom_activity_add);
        custom_activity[name] = value;
        custom_activity_add[name] = value;
        this.setState({custom_activity, custom_activity_add});
    }

    handleIntraLocationTransportOption = ({target: {value, name}}) => {
        let transportOptionType = Object.assign({}, this.state.transportOptionType);
        transportOptionType.intra_location[name] = value;
        this.setState({transportOptionType}, ()=>{
            this.updateTotalPrice();
        });
    }

    checkInfo = ()=>{
        this.setState({isOpen: true});
    }

    handleModal = () =>{
        this.setState({isOpen: false});
    }

    closeInfoModal = () => {
        this.setState({setIsOpen: false});
    }

    handleNavigation = () => {
		let { history } = this.props
		history.push('/itinerary')
	}
    
    handleAddMasterActivity2 = ({value, dayIndex}) => {
        let activityOptionType = Object.assign({}, this.state.activityOptionType);
        let flag=0;
        let day;
        activityOptionType.itinerary.forEach((item) => {
            let checkId = item.activityIds.indexOf(value);
            if(checkId > -1){
                flag=1;
                day = item.day;
            }
        })
        if(flag == 1){
            alert("This activty alreay exists in day " + day);
        }
        else{
            activityOptionType.itinerary[dayIndex].activityIds.push(value);
            this.setState({activityOptionType}, ()=>{
                activity_total_price_each_day = 0;
                this.findTotalPrice();
                let add_master_activity = this.state.add_master_activity;
                add_master_activity = false;
                this.setState({add_master_activity, activity_id: ""});
            })
        }
    }

    handleAddCustomActivity2 = ({value, dayIndex}) => {
        let uid = new ShortUniqueId();
        uid = uid.randomUUID(6);
        uid = "custom" + uid;
        let custom_activity = Object.assign({}, this.state.custom_activity);
        let custom_activity_add = Object.assign({}, this.state.custom_activity_add);
        let custom_activity_data = this.state.custom_activity_data;
        custom_activity["id"] = uid;
        custom_activity_add["id"] = uid;
        custom_activity_data.push(custom_activity);
        
        this.setState({custom_activity, custom_activity_data});
        let activityOptionType = Object.assign({}, this.state.activityOptionType);
        activityOptionType.itinerary[dayIndex].activityIds.push(uid);
        this.setState({activityOptionType}, ()=>{
            activity_total_price_each_day = 0;
            this.findTotalPrice();
            let add_custom_activity = this.state.add_custom_activity;
            add_custom_activity = false;
            this.setState({add_custom_activity, custom_activity_add}, ()=>{
                custom_activity_add.id = '';
                custom_activity_add.title = '';
                custom_activity_add["child price"] = '';
                custom_activity_add.price = '';
                custom_activity_add.description = '';
                this.setState({custom_activity_add});
            });
        });
    }

    updateTotalPrice = () => {
        let {stayOptionType, activityOptionType, transportOptionType, ItineraryData, totalPrice, custom_stay_option_data, add_custom_stay_option} = this.state;
        let {capacity, room_price, meal_price, room_options} = stayOptionType;
        let {s_d_inter_location, d_s_inter_location,intra_location} = transportOptionType;
        let {adultCount, childrenCount, city_options} = ItineraryData;
        let {total_itinerary_price, total_price_per_person, display_total_price, edit_price} = totalPrice;

        let master_stay_total_price = 0;
        let master_room_total_price = 0;
        let count_nights = Number(Number(city_options[0].noOfDays)-1) == 0 ? 1 : Number(Number(city_options[0].noOfDays)-1);
        room_options.forEach(item => {
            master_room_total_price = Number(master_room_total_price) + Number(item.count) * Number(item.price) * Number(count_nights);
        })
        let master_meal_total_price = 0;
        master_meal_total_price = Number(meal_price) * Number(adultCount);
        master_stay_total_price = master_room_total_price + master_meal_total_price;

        let custom_stay_total_price = 0;
        let { custom_stay_type, custom_hotel_type, custom_room_single_share, custom_room_double_share, custom_room_tripple_share, custom_meal_price} = custom_stay_option_data;
        let custom_room_total_price = (Number(custom_room_single_share.count) * Number(custom_room_single_share.price_per_room) + Number(custom_room_double_share.count) * Number(custom_room_double_share.price_per_room) + Number(custom_room_tripple_share.count) * Number(custom_room_tripple_share.price_per_room)) * Number(Number(ItineraryData.city_options[0].noOfDays)-1);
        let custom_total_meal_price = 0;
        custom_total_meal_price = Number(custom_meal_price.price_per_person) * Number(ItineraryData.adultCount);
        custom_stay_total_price = custom_room_total_price + custom_total_meal_price;
        
        let total_room_price = add_custom_stay_option ? custom_stay_total_price : master_stay_total_price;
        
        let total_amount = Number(total_room_price) + (Number(intra_location["full_day_price"]) * Number(intra_location["no_of_full_days"]) + Number(intra_location["half_day_price"]) * Number(intra_location["no_of_half_days"]) + Number(intra_location["pickup_drop_price"]))*Number(intra_location["count"]) + Number(s_d_inter_location["price"])  + Number(d_s_inter_location["price"]);

        total_itinerary_price = total_amount + activity_total_price_each_day;
        total_price_per_person = Math.ceil(total_itinerary_price / Number(adultCount));
        totalPrice.total_itinerary_price = total_itinerary_price;
        totalPrice.total_price_per_person = total_price_per_person;   
        this.setState({totalPrice});
    }

    handleMultipleRoomOption = ({ value, name, index }) => {
        let stayOptionType = Object.assign({}, this.state.stayOptionType)
        stayOptionType["room_options"][index][name] = value.value;
        
		this.setState({
			stayOptionType
        })
    }

    handleAddMultiple = (key) => {
        if (key == "city_options") {
            let ItineraryData = this.state.ItineraryData;
            ItineraryData[key].push({
                name: '',
                noOfDays: ''
            })
            this.setState({
                ItineraryData
            })
        }
    }

    handleMultipleInputs = (key, data, index) => {
        let { name, value } = data
        let ItineraryData = Object.assign({}, this.state.ItineraryData)
        ItineraryData[key][index][name] = value === "" ? "" : Number(value)
        this.setState({
            ItineraryData
        })
    }

    handleAddCustomStayOption = ({ type, value, name }) => {
        let custom_stay_option_data = Object.assign({}, this.state.custom_stay_option_data);
        let stayOptionType = Object.assign({}, this.state.stayOptionType);
		custom_stay_option_data[type][name] = value.value;
		this.setState({
			custom_stay_option_data
		}, ()=>{
                this.updateTotalPrice();
            })
    }

    handleInclusions = ({type, value, name, index}) => {
        let additionalData = this.state.additionalData;
        additionalData[0][type][index].description = value.value;
        this.setState({additionalData}); 
    }

    handleDeleteInclusions = (type, index) => {
        let additionalData = this.state.additionalData;
        additionalData[0][type].splice(index, 1);
        this.setState({additionalData});
    }

    handleAddInclusion = (type) => {
        let additionalData = this.state.additionalData;
        additionalData[0][type].push({id: '', description: ''});
        this.setState({additionalData});
    }

    render() {
        let { ItineraryData, activityLevelLists, stayOptionType, masterData, popupOpen, transportOptionType, ref_id="", add_master_activity, add_custom_activity, activity_id, custom_activity, custom_activity_add, additionalData, totalPrice, activityOptionType,isOpen, cityList, city_image, city_banner_image, activity_images, hotelBase64Images, loader_preview, activity_images_img, add_custom_stay_option, custom_stay_option_data, add_master_stay_option, mealDropdown, intraLocationPickupDropOptions, custom_activity_data } = this.state;
        let { cities, theme, adultCount, childrenCount, intentsityLevel, mealId, activity_date, stayTypeId, city_options } = ItineraryData;
        let {total_itinerary_price, total_price_per_person, display_total_price, edit_price} = totalPrice;
        let stay_dropdown = [];
        let transport_dropdown = [];
        let activities_dropdown = [];
        let hotelTypeOption = [];
        let activityDropdown = [];    
        let hotelDesc = "";
        let roomTypeOption = [];
        let intraLocationVehicleOptions = []
        let addActivtyList = []
        let inclusions=[], exclusions=[], termsAndConditions=[], additionalInfo=[], cancellationPolicies=[], aboutUs=[], benefitOfBookingWithSair=[];
        let { staytype, hoteltype, roomtype, roomDescription, mealDescription , capacity, room_price, meal_price, mealtype, total_price, staytypelabel, roomtypelabel, hoteltypelabel, room_options} = stayOptionType;
        if(mealtype=="" || mealtype==undefined ){
            mealtype="3"
        }
        if(masterData.length > 0){
            let { stay=[], transport=[] } = masterData[0];
            stay.forEach(item => {
                stay_dropdown.push({
                    label: item.type,
                    value: item.id
                })
            })
            activityDropdown = masterData[0].activities;
            addActivtyList = [];
            if(activityDropdown){
                activityDropdown.forEach(item => {
                    addActivtyList.push({
                        label: item.id + " " + item.title,
                        value: item.id
                    })
                })
            }
            
            let intraLocationVehicleList = _.get(transport, "intraLocation");
            intraLocationVehicleOptions = _.map(intraLocationVehicleList, (item)=>{
                return {
                    label: item.vehicle,
                    value: item.id,
                    capacity: item.maxPax,
                    full_day_price: item.dayPrice,
                    half_day_price: item.halfDayPrice
                }
            })
            intraLocationVehicleOptions.unshift({label: 'Select...',
                value: '5',
                capacity: 0,
                full_day_price: 0,
                half_day_price: 0})
        }
        if(additionalData.length>0){
            inclusions = additionalData[0].inclusions;
            exclusions = additionalData[0].exclusions;
            termsAndConditions = additionalData[0].termsAndConditions;
            additionalInfo = additionalData[0].additionalInfo;
            cancellationPolicies = additionalData[0].cancellationPolicies;
            aboutUs = additionalData[0].aboutUs;
            benefitOfBookingWithSair = additionalData[0].benefitOfBookingWithSair;
            let hotels = additionalData[0].hotels;
            if(hoteltype!=""){
                hotelDesc = _.filter(hotels, (hotelItem)=>{
                    return hotelItem.id == hoteltype;
                })
                hotelDesc = hotelDesc[0].description;
            }
        }
        let interLocationVehicleOptions = []
        interLocationVehicleOptions = _.map(interLocationVehicleList, (item)=>{
            return {
                label: item.label,
                value: item.id
            }
        })
        //theme dropdown
        let themeList = [];
        themeOptionList.forEach(item => {
            themeList.push({
                label: item.label,
                value: item.id
            })
        })
        //stayoption dropdown
        let stay_option_dropdown = [];
        stayOptionsList.forEach(item => {
            stay_option_dropdown.push({
                label: item.label,
                value: item.id
            })
        })
        //food type
        let mealOptions_dropdown = [];
        mealOptionsList.forEach(item => {
            mealOptions_dropdown.push({
                label: item.label,
                value: item.id
            })
        })

        let stayOption = [];
        stayOptionsList.forEach(item => {
            stayOption.push({
                label: item.label,
                value: item.id
            })
        })
        
        let { intra_location, s_d_inter_location, d_s_inter_location } = transportOptionType;        
        let room_count = capacity != 0 ? (Math.ceil(Number(adultCount)/capacity)) : "";
        let image_data = {};
        image_data["city"] = ItineraryData.city_options[0].name;
        let { city } = image_data;
        city = city ? city.toUpperCase() : '';
        let noOfDays = city_options[0].noOfDays;
        noOfDays = Number(noOfDays);
        
        let count_nights = Number(noOfDays-1) == 0 ? 1 : Number(noOfDays-1); 
        let master_stay_total_price = 0;
        let master_room_total_price = 0;
        room_options.forEach(item => {
            master_room_total_price = master_room_total_price + Number(item.count) * Number(item.price) * count_nights;
        })
        let master_meal_total_price = 0;
        master_meal_total_price = Number(meal_price) * Number(adultCount);
        master_stay_total_price = master_room_total_price + master_meal_total_price;

        let suggested_act_details = [];
        if(activityOptionType && activityOptionType.suggestedActivities && activityOptionType.suggestedActivities.length>0){
            let suggestedActivities = this.state.activityOptionType.suggestedActivities;
            let elem = _.filter(activityDropdown, (actItem)=> {
                return actItem.id == suggestedActivities[0];
            })[0];
            let elem2 = _.filter(activityDropdown, (actItem)=> {
                return actItem.id == suggestedActivities[1];
            })[0];
            if(elem) {
                suggested_act_details.push(elem);
            }
            if(elem2) {
                suggested_act_details.push(elem2);
            }
        }
        let custom_stay_total_price = 0;
        let { custom_stay_type, custom_hotel_type, custom_room_single_share, custom_room_double_share, custom_room_tripple_share, custom_meal_price} = custom_stay_option_data;
        let custom_room_total_price = (Number(custom_room_single_share.count) * Number(custom_room_single_share.price_per_room) + Number(custom_room_double_share.count) * Number(custom_room_double_share.price_per_room) + Number(custom_room_tripple_share.count) * Number(custom_room_tripple_share.price_per_room)) * Number(Number(ItineraryData.city_options[0].noOfDays)-1);
        let custom_total_meal_price = 0;
        custom_total_meal_price = Number(custom_meal_price.price_per_person) * Number(ItineraryData.adultCount);
        custom_stay_total_price = custom_room_total_price + custom_total_meal_price;
        
        let custom_room_label_1 = add_custom_stay_option==true && custom_stay_option_data.custom_room_single_share.count!=0 ? custom_stay_option_data.custom_room_single_share.count+"x"+custom_stay_option_data.custom_room_single_share.name:"";

        let custom_room_label_2 = add_custom_stay_option==true && custom_stay_option_data.custom_room_double_share.count!=0 ? custom_stay_option_data.custom_room_double_share.count+"x"+custom_stay_option_data.custom_room_double_share.name:"";

        let custom_room_label_3 = add_custom_stay_option==true && custom_stay_option_data.custom_room_tripple_share.count!=0 ? custom_stay_option_data.custom_room_tripple_share.count+"x"+custom_stay_option_data.custom_room_tripple_share.name:"";
        let master_room_label_1 = add_master_stay_option==true && roomtypelabel.length>0 && roomtypelabel[0].count!=0 ? roomtypelabel[0].count+"x"+roomtypelabel[0].name:"";
        let master_room_label_2 = add_master_stay_option==true && roomtypelabel.length>1 && roomtypelabel[1].count!=0 ? roomtypelabel[1].count+"x"+roomtypelabel[1].name:"";
        let master_room_label_3 = add_master_stay_option==true && roomtypelabel.length>2 && roomtypelabel[2].count!=0 ? roomtypelabel[2].count+"x"+roomtypelabel[2].name:"";
        
        return (
        <>
            {popupOpen ?
                <div className="preview-scroll">
                    <div className="itinerary-content">
                        <div className="itinerary-base">
                            <div className="popup-buttons">
                                <div className="btn-right">
                                    {this.state.loader ?
                                        <button className="btn btn-primary mt-3 display-flex" disabled >Downloading ... </button> : <button className="btn btn-primary mt-3 display-flex" onClick={() => this.downloadPDF()}>Download PDF</button>}
                                    {this.state.loader_image ?
                                        <button className="btn btn-primary mt-3 display-flex" disabled >Downloading ... </button> : <button className="btn btn-primary mt-3 display-flex" onClick={() => this.downloadImage()}>Download Image</button>}
                                    <button className="btn btn-primary mt-3 ml-3 " onClick={() => this.backPDF()}>Back</button>
                                </div>
                            </div>
                            <div className="itinerary-node-image justify-itinerary">
                                <div className="popup-buttons">
                                    <div className="btn-right">
                                        {/* <button className="btn btn-primary mt-3 display-flex"  onClick={() => this.downloadImage()}>Download Image</button> */}
                                        {/* <button className="btn btn-primary mt-3 ml-3" onClick={() => this.backImage()}>Back</button> */}
                                    </div>
                                </div>
                                <div className="itin-img-background">
                                    <div className="itin-img-wrap">
                                        <div className="background-img-main" style={{ "background-image": `url('${city_image}')` }}>
                                            <div className="custom-sair-brand">
                                                <p className="img-header">{city} KI</p>
                                                <img className="d-inline-block mr-5" src={logo} alt="brand" />
                                                <p className="ref-txt text-center">Ref#: {ref_id}</p>
                                            </div>
                                        </div>
                                        <div className="custom-pack-image">
                                            <div className="col-sm-6">
                                                <img className="left-img" src={activity_images_img[0]} alt="" />
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="row img-row-right">
                                                    <div className="col-md-12 mr-0 pr-0">
                                                        <img className="first-img" src={activity_images_img[1]} alt="" />
                                                        <img className="first-img-2" src={activity_images_img[2]} alt="" />
                                                    </div>
                                                </div>
                                                <div className="row sec-row">
                                                    <div className="col-md-12 mr-0 pr-0">
                                                        <img className="first-img" src={activity_images_img[3]} alt="" />
                                                        <img className="first-img-2" src={activity_images_img[4]} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 inclusion-details">
                                            <div className="inclusion-name">INCLUSIONS</div>
                                            {inclusions.length > 0 ?
                                                <div className="inclusions1 mt-20 mb-20">
                                                    {inclusions.map((inclusion_item, index) => {
                                                        let str = inclusion_item.description;
                                                        let is_breakfast = str.includes("Breakfast");
                                                        let res = is_breakfast ? str.replace('n day(s)', noOfDays-1 + " day(s) ") : str.replace('n day(s)', noOfDays + " day(s) ");
                                                        let new_res = res.replace('n night(s)', Number(noOfDays-1) == 0 ? '' :  + Number(noOfDays-1)+" night(s) ");
                                                        return (
                                                            <div className={`inclusions-${inclusion_item.id}`}>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <ul>
                                                                            <li className="itinerary-sub-text-left pt-10">  <div class="border-round"><span class="dot"></span></div><strong>{new_res}</strong></li>  </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div> : ""}
                                        </div>
                                        <div className="col-sm-12 custom-itin-heading light-bg-div mb-4">
                                            <div className="custom-package-name">
                                                <div className="header-txt">
                                                    <p>{city} TOUR</p>
                                                </div>
                                                <div className="header-sub-txt">
                                                    {noOfDays == 1 ? <p>{noOfDays} DAY(S)</p> : <p>{noOfDays} DAY(S) & {noOfDays - 1} NIGHT(S)</p>}
                                                </div>
                                            </div>
                                            <div className="custom-package-details">
                                                <div className="header-txt">
                                                    {display_total_price ? <p>INR {total_itinerary_price}</p> : <p>INR {total_price_per_person}</p>}

                                                </div>
                                                <div className="header-sub-txt-2">
                                                    {display_total_price ? <p>TOTAL PRICE  <br /> NO HIDDEN CHARGES</p> : <p>PER PERSON  <br /> NO HIDDEN CHARGES</p>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <div class="container itinerary-node-2 justify-itinerary">
                            <div className="itinerary-node" id="viewport">
                                    <div className="row">
                                        <div className="col-md-8 mt-4">
                                            <div className="text-center">
                                                <p className="ref-txt">Ref#: {ref_id}</p>
                                                <img src={Logo} height="70px" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="header-bg">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {city_image != '' ? <img src={city_image} width="100%" height="120px" crossorigin="anonymous"/> : ''}
                                            </div>
                                            <div className="col-md-5">
                                                <div className="header-txt">
                                                    <p> {city} TOUR </p>
                                                </div>
                                                <div className="header-sub-txt">
                                                    {noOfDays == 1 ? <p>{noOfDays} DAY(S)</p> : <p>{noOfDays} DAY(S) & {noOfDays - 1} NIGHT(S)</p>}

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="header-txt">
                                                    {display_total_price ? <p>INR {total_itinerary_price}</p> : <p>INR {total_price_per_person}</p>}
                                                </div>
                                                <div className="header-sub-txt-2">
                                                    {display_total_price ? <p>TOTAL PRICE <br /> NO HIDDEN CHARGES</p> : <p>PER PERSON <br /> NO HIDDEN CHARGES</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="header-2-bg">
                                        <div className="row">
                                            <div className="col-md-5 pl-30">
                                                <div className="header-2-txt">
                                            <p> {add_custom_stay_option ? custom_stay_option_data.custom_stay_type.name.toUpperCase():staytypelabel.toUpperCase()}</p>
                                                </div>
                                                <div className="header-sub-txt">
                                                    <p> {add_custom_stay_option ? custom_stay_option_data.custom_hotel_type.name.toUpperCase():hoteltypelabel.toUpperCase()} </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 pl-30">
                                                <div className="header-2-txt">
                                                    <p> STAY SHARING </p>
                                                </div>
                                                <div className="header-sub-txt">
                                                    <p>
                                                        {custom_room_label_1 + " " + custom_room_label_2 + " " + custom_room_label_3}
                                                    </p>
                                                
                                                    <p>    
                                                        {master_room_label_1 + " " + master_room_label_2 + " " + master_room_label_3}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {this.state.hotelBase64Images.length > 0 ?
                                                    <img src={this.state.hotelBase64Images[0]} width="100%" height="120px" /> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="header-bg">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <img src={transport} width="100%" height="120px" />
                                            </div>
                                            <div className="col-md-4">
                                                <div className="header-2-txt">
                                                    <p> SIGHTSEEING  </p>
                                                </div>
                                                <div className="header-sub-txt">
                                                    <p>{("By " + intra_location["labelname"]).toUpperCase()} </p>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="header-2-txt-hotel">
                                                    <p>FLIGHT/TRAIN/BUS</p>
                                                </div>
                                                <div className="header-sub-txt" style={{ textAlign: "right", marginRight: "20px" }}>
                                                    <p>{s_d_inter_location["labelname"] ? s_d_inter_location["labelname"].toUpperCase() : "NOT INCLUDED"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="row">
                                            <div className="col-md-8">

                                                {city_banner_image != '' ? <img src={city_banner_image} width="725" height="150" /> : ''}
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-3">
                                            <div className="col-md-3">
                                                <p className="itinerary-bg"> ITINERARY </p>
                                            </div>
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-3">
                                                <div className="itinerary-text">
                                                    <p> CITIES </p>
                                                </div>
                                                <div className="city-sub-txt">
                                                    <p> {city} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="itinerary-text">
                                                    <p>DAY 1 <br /> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-6">
                                                <div className="itinerary-text-2">
                                                    <p> {this.state.hotelTitle}  </p>
                                                </div>
                                                <div className="itinerary-sub-text">
                                                    <p>{hotelDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.hotelBase64Images.length > 0 ?
                                            <div className="row mt-2">
                                                <div className="col-md-2">
                                                </div>
                                                <div className="col-md-3">
                                                    <img src={this.state.hotelBase64Images[0]} width="100%" height="170px" />
                                                </div>
                                                <div className="col-md-3">
                                                    <img src={this.state.hotelBase64Images[1]} width="100%" height="170px" />

                                                </div>
                                            </div> : 
                                        ""}
                                    </div>
                               
                                </div>
                                {this.state.activityOptionType.itinerary.map((item, dayIndex) => {
                                    let act_list = item.activityIds;
                                    let act_details = []
                                    
                                    act_list.forEach((activityId) => {
                                        let resId = (activityId + "").slice(0, 6);
                                        if (resId != "custom") {
                                            let elem = _.filter(activityDropdown, (actItem) => {
                                                return actItem.id == activityId;
                                            })[0];
                                            if (elem) {

                                                act_details.push(elem);
                                            }
                                        }
                                        else {
                                            let custom_activity_data = this.state.custom_activity_data;
                                            let custom_elem = _.filter(custom_activity_data, (custom_item)=>{
                                                return custom_item.id == activityId;
                                            })[0]
                                            act_details.push(custom_elem);
                                        }
                                    })
                                    
                                    return act_list.length > 0 ? act_details.map((item_act, index) => {
                                        return (<div className={`activity-${item_act.id}`}>
                                            {dayIndex != 0 && index == 0 ? <div className="row mt-20"> <div className="col-md-2 "><div className="itinerary-text"> Day {dayIndex + 1} <br />  </div></div></div> : ""}
                                            <div className="row mt-20">
                                                <div className="col-md-2">
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="itinerary-text-2">
                                                        <p> {item_act.title} {item_act.duration?"(Duration: "+ item_act.duration+"hrs.)":""} </p>
                                                    </div>
                                                    {item_act.price > 0 ? "Adult price: " + item_act.price + ", Child price: " + item_act["child price"] +  " (Included)" : ""}
                                                    <div className="itinerary-sub-text">
                                                        <p> {item_act.description} </p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="row mt-20">
                                                <div className="col-md-2">
                                                </div>
                                                {activity_images[dayIndex][index] ?
                                                    <div className="col-md-3">
                                                        <img src={activity_images[dayIndex][index]["img1"]} width="100%" height="150px" />
                                                    </div>
                                                    : ""}
                                                {activity_images[dayIndex][index] ?
                                                    <div className="col-md-3">
                                                        <img src={activity_images[dayIndex][index]["img2"]} width="100%" height="150px" />
                                                    </div> : 
                                                ""}
                                            </div>
                                        </div>
                                        )
                                    }) : ""
                                })}
                                <div className="suggested">    
                                {
                                    suggested_act_details.map((item, index) => {
                                        return (<div className={`activity-${item.id}`}>
                                            {index == 0 ? <div className="row mt-4 page2-div-3">
                                                <div className="col-md-5">
                                                    <span className="itinerary-bg-2"> OPTIONAL ACTIVITIES
                                                    (Not Included)</span>
                                                </div>
                                            </div> : ""}
                                            <div className="row mt-20">
                                                <div className="col-md-2">
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="itinerary-text-2">
                                                        <p> {item.title}  </p>
                                                    </div>
                                                    <div className="itinerary-sub-text">
                                                        <p> {item.description} </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-20">
                                                <div className="col-md-2">
                                                </div>

                                                {item.base64Pictures && item.base64Pictures.length > 0 && item.base64Pictures[0] ?
                                                    <div className="col-md-3">
                                                        <img src={item.base64Pictures[0]} width="100%" height="150px" />
                                                    </div>
                                                    : ""}
                                                {item.base64Pictures && item.base64Pictures.length > 0 && item.base64Pictures[1] ?
                                                    <div className="col-md-3">
                                                        <img src={item.base64Pictures[1]} width="100%" height="150px" />
                                                    </div> : ""}
                                            </div>
                                        </div>
                                        )
                                    })
                                }</div>
                                {inclusions.length > 0 ?
                                    <div className="inclusions mt-20">
                                        {inclusions.map((inclusion_item, index) => {
                                            let str = inclusion_item.description;
                                            let is_breakfast = str.includes("Breakfast");
                                            let res = is_breakfast ? str.replace('n day(s)', noOfDays-1 + " day(s) ") : str.replace('n day(s)', noOfDays + " day(s) ");
                                            let new_res = res.replace('n night(s)', Number(noOfDays-1) == 0 ? '' :  + Number(noOfDays-1)+" night(s) ");
                                            
                                            return (
                                                <div className={`inclusions-${inclusion_item.id}`}>
                                                    {index == 0 ? <div className="row"><div className="col-md-2"><p className="itinerary-bg-3 mb-20"> INCLUSIONS </p></div></div> : ""}
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li className="itinerary-sub-text pt-10">  <div class="border-round"><span class="dot"></span></div><strong>{new_res}</strong></li>      
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div> : 
                                ""}

                                {exclusions.length > 0 ?
                                    <div className="exclusions mt-20">
                                        {exclusions.map((inclusion_item, index) => {
                                            let str = inclusion_item.description;
                                            let res = str.replace('n day(s)', noOfDays + " day(s)");
                                            return (
                                                <div className={`exclusions-${inclusion_item.id}`}>
                                                    {index == 0 ? <div className="row"><div className="col-md-2"><p className="itinerary-bg-4 mb-20"> EXCLUSIONS </p></div></div> : ""}
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li className="itinerary-sub-text pt-10"><div class="border-round"><span class="dot"></span></div><strong>{res}</strong></li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div> : ""
                                }
                                {termsAndConditions.length > 0 ?
                                    <div className="termsAndConditions mt-20">
                                        {termsAndConditions.map((inclusion_item, index) => {
                                            let str = inclusion_item.description;
                                            return (
                                                <div className={`termsAndConditions-${inclusion_item.id}`}>
                                                    {index == 0 ? <div className="row"><div className="col-md-2"><p className="itinerary-bg-5 mb-20"> TERMS AND CONDITIONS </p></div></div> : ""}
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-6">

                                                            <ul>
                                                                <li className="itinerary-sub-text pt-10"><div class="border-round"><span class="dot"></span></div><strong>{str}</strong></li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div> : ""
                                }
                                {additionalInfo.length > 0 ?
                                    <div className="additionalInfo mt-20">
                                        {additionalInfo.map((inclusion_item, index) => {
                                            let str = inclusion_item.description;
                                            return (
                                                <div className={`additionalInfo-${inclusion_item.id}`}>
                                                    {index == 0 ? <div className="row"><div className="col-md-2"><p className="itinerary-bg-6 mb-20"> ADDITIONAL INFORMATION </p></div></div> : ""}
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-6">

                                                            <ul>
                                                                <li className="itinerary-sub-text pt-10"><div class="border-round"><span class="dot"></span></div><strong>{str}</strong></li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div> : ""
                                }
                                {cancellationPolicies.length > 0 ?
                                    <div className="row cancellationPolicies mt-20">
                                        {cancellationPolicies.map((inclusion_item, index) => {
                                            let str = inclusion_item.description;
                                            return (
                                                <div className={`cancellationPolicies-${inclusion_item.id}`}>
                                                    {index == 0 ? <div className="row"><div className="col-md-2"><p className="itinerary-bg-7 mb-20"> CANCELLATION POLICY </p></div></div> : ""}
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-6">

                                                            <ul>
                                                                <li className="itinerary-sub-text pt-10"><div class="border-round"><span class="dot"></span></div><strong>{str}</strong></li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : ""
                                }
                                {aboutUs.length > 0 ?
                                    <div className="aboutUs mt-20">
                                        {aboutUs.map((inclusion_item, index) => {
                                            let str = inclusion_item.description;
                                            return (
                                                <div className={`aboutUs-${inclusion_item.id}`}>
                                                    {index == 0 ? <div className="row"><div className="col-md-2"><p className="itinerary-bg-8 mb-20"> ABOUT US </p></div></div> : ""}
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li className="itinerary-sub-text pt-10"><div class="border-round"><span class="dot"></span></div><strong>{str}</strong></li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : ""
                                }
                                {benefitOfBookingWithSair.length > 0 ?
                                    <div className="benefitOfBookingWithSair mt-20">
                                        {benefitOfBookingWithSair.map((inclusion_item, index) => {
                                            let str = inclusion_item.description;
                                            return (
                                                <div className={`benefitOfBookingWithSair-${inclusion_item.id}`}>
                                                    {index == 0 ? <div className="row"><div className="col-md-2"><p className="itinerary-bg-9 mb-20"> BENEFITS OF BOOKING WITH SAIR </p></div></div> : ""}
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li className="itinerary-sub-text pt-10"><div class="border-round"><span class="dot"></span></div><strong>{str}</strong></li>  
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div> : ""
            }
            <ContentMainHeader title="Create Itinerary"></ContentMainHeader>
            <App />

            <section className="sair-sec-wrapper itinerary-section">
                <div className="row">
                    <div className="col-md-12">
                        {popupOpen ? "" : <button className="btn btn-primary mt-3 display-flex check-info"  onClick={() => this.handleNavigation()}>itinerary List</button> }
                    </div>
                </div>

                <SliderOverlay isOpen={isOpen} toggle={this.handleModal} size='80%'>
                    <div className="side-slider h-100 position-relative">
                        <div className="h-100 flex-with-center">
                            <ItineraryModal
                                toggle={this.handleModal}
                            //modalDetails={details}
                            />
                        </div>

                    </div>
                </SliderOverlay>

                {/* <div className="row">
                    <div className="col-md-6"> City Options </div>
                    <div className="col-md-6 text-right">
                        <button className="btn btn-primary btn-sm"
                            onClick={() => this.handleAddMultiple('city_options')}
                        >
                        Add City
                        </button>
                    </div>
                    </div> */}
                    {city_options.filter(x => !x.deleted).map((item, index) => {
                        let {name="", noOfDays} = item;
                        return (<>
                            <div className="row">
                                <div className="col-md-6 form-group">
                                <label>Destination {index+1} <span className="required">{" *"}</span></label>
                                <ReactSelect
                                    isMulti={false}
                                    options={cityList}
                                    handleChange={(keyName, data) => {
                                        this.handleCitySelectInputs({ name: keyName, value: data, index: index })
                                    }}
                                    keyName="name"
                                    value={name}
                                    disabled={false}
                                />
                                </div>

                                    <div className="col-md-6 form-group">
                                        <label >No of Days <span className="required">{" *"}</span></label>
                                        <NormalInput
                                            className="form-control"
                                            type="number"
                                            name="noOfDays"
                                            value={noOfDays}
                                            onChange={(data) => {
                                                this.handleMultipleInputs("city_options", data, index)
                                            }}
                                            
                                        />
                                        {this.validator.message('No of Days', noOfDays, 'required')}
                                    </div>
                            </div>
                        </>)
                    })}
                    <div className="row">

                    <div className="col-md-6 form-group">
                        <label>Theme <span className="required">{" *"}</span></label>
                        <ReactSelect
                            isMulti={false}
                            options={themeList}
                            handleChange={(keyName, data) => {
                                this.handleSelectInputs({ name: keyName, value: data })
                            }}
                            keyName="theme"
                            value={theme}
                            disabled={false}
                        />
                        {this.validator.message('Theme', theme, 'required')}
                    </div>

                    <div className="col-md-6 form-group">
                        <label>Activity Level</label>
                        <ReactSelect
                            isMulti={false}
                            options={activityLevelLists}
                            handleChange={(keyName, data) => {
                                this.handleSelectInputs({ name: keyName, value: data })
                            }}
                            keyName="intentsityLevel"
                            value={intentsityLevel}
                            disabled={false}
                        />
                    </div>

                    <div className="col-md-6 form-group">
                        <label >No of Adults (>= 5Yrs.) <span className="required">{" *"}</span></label>
                        <input
                            className="form-control"
                            id="adultCount"
                            type="number"
                            name="adultCount"
                            value={adultCount}
                            onChange={this.handleInputs}
                        />
                        {this.validator.message('No of Adults', adultCount, 'required')}
                    </div>

                    <div className="col-md-6 form-group">
                        <label >No of Children (below 5Yrs.) <span className="required">{" *"}</span></label>
                        <input
                            className="form-control"
                            id="childrenCount"
                            type="number"
                            name="childrenCount"
                            value={childrenCount}
                            onChange={this.handleInputs}
                        />
                    </div>

                    <div className="col-md-6 form-group">
                        <label>Activity Date <span className="required">{" *"}</span> </label>
                        <DateTime
                            handleChange={(name, value) => {
                                this.handleDateInput({ name: name, value: value })
                            }
                            }
                            name="activity_date"
                            value={activity_date}
                            isValidDate={hidePastDate}
                            timeFormat={false}
                            placeholder=""
                        />
                    </div>

                    <div className="col-md-6 form-group">
                        <label>Stay Type</label>
                        <ReactSelect
                            isMulti={false}
                            options={stayOption}
                            handleChange={(keyName, data) => {
                                this.handleSelectInputs({ name: keyName, value: data })
                            }}
                            keyName="stayTypeId"
                            value={stayTypeId}
                            disabled={false}
                        />
                    </div>

                    <div className="col-md-6 form-group">
                        <label >Food Required</label>
                        <ReactSelect
                            isMulti={false}
                            options={mealOptions_dropdown}
                            handleChange={(keyName, data) => {
                                this.handleSelectInputs({ name: keyName, value: data })
                            }}
                            keyName="mealId"
                            value={mealId}
                            disabled={false}
                        />
                    </div>

                </div>

                <button
                    className="btn btn-primary mt-3"
                    onClick={() => this.handleSubmit()}
                >
                    Get Recommendation
                </button>

                <div className="collapsable-itinerary collapsable-itinerary-stay">
                    <Accordion defaultActiveKey="1">
                        <div className="mt-3">
                            <Card>
                                <Card.Header eventKey="0">
                                    Stay Option
                                    <Accordion.Toggle variant="link" eventKey="0" className="add-accordin">
                                            Total Price: {add_custom_stay_option? custom_stay_total_price : master_stay_total_price}
                                            <i className="ion ml-3">+</i>
                                    </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                    Do you want to add stay option ? Create your custom stay option.
                                    <div className="mb-30">
                                        <Tabs defaultActiveKey={open_stay_tab} transition={false} id="noanim-tab-example" onSelect={this.addCustomStayOption}>
                                            <Tab eventKey="custom_stay" title="Custom Stay Option">
                                                {add_custom_stay_option ?
                                                <>
                                                    <div className="row mt-40">
                                                        <div className="col-md-6 form-group">
                                                            <label>Stay Type</label>
                                                            
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="custom_stay_type"
                                                                    value={custom_stay_option_data["custom_stay_type"]["name"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_stay_type", value: data, name: "name"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Hotel Type</label>
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="custom_hotel_type"
                                                                    value={custom_stay_option_data["custom_hotel_type"]["name"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_hotel_type", value: data, name: "name"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Room Type</label>
                                                            <NormalInput
                                                                    className="form-control bg-light-gray"
                                                                    type="text"
                                                                    name="custom_room_single_share"
                                                                    value={custom_stay_option_data["custom_room_single_share"]["name"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_single_share", value: data, name: "name"})
                                                                    }}
                                                                    readonly="readonly"
                                                            />
                                                            
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Room Count</label>
                                                            
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="custom_room_single_share"
                                                                    value={custom_stay_option_data["custom_room_single_share"]["count"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_single_share", value: data, name: "count"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Price Per Room</label>
                                                        <NormalInput
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="custom_room_single_share"
                                                                    value={custom_stay_option_data["custom_room_single_share"]["price_per_room"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_single_share", value: data, name: "price_per_room"})
                                                                    }}
                                                            />
                                                        </div>
                                                        </div>
                                                        <div className="row mt-40">
                                                        <div className="col-md-6 form-group">
                                                            <label>Room Type 2</label>
                                                            <NormalInput
                                                                    className="form-control bg-light-gray"
                                                                    type="text"
                                                                    name="custom_room_double_share"
                                                                    value={custom_stay_option_data["custom_room_double_share"]["name"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_double_share", value: data, name: "name"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Room Count 2</label>
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="custom_room_double_share"
                                                                    value={custom_stay_option_data["custom_room_double_share"]["count"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_double_share", value: data, name: "count"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Price Per Room 2</label>
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="custom_room_double_share"
                                                                    value={custom_stay_option_data["custom_room_double_share"]["price_per_room"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_double_share", value: data, name: "price_per_room"})
                                                                    }}
                                                            />
                                                        </div>
                                                        </div>
                                                        <div className="row mt-40">
                                                        <div className="col-md-6 form-group">
                                                            <label>Room Type 3</label>
                                                            <NormalInput
                                                                    className="form-control bg-light-gray"
                                                                    type="text"
                                                                    name="custom_room_tripple_share"
                                                                    value={custom_stay_option_data["custom_room_tripple_share"]["name"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_tripple_share", value: data, name: "name"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Room Count 3</label>
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="custom_room_tripple_share"
                                                                    value={custom_stay_option_data["custom_room_tripple_share"]["count"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_tripple_share", value: data, name: "count"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Price Per Room 3</label>
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="custom_room_tripple_share"
                                                                    value={custom_stay_option_data["custom_room_tripple_share"]["price_per_room"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type: "custom_room_tripple_share", value:data, name:"price_per_room"})
                                                                    }}
                                                            />
                                                        </div>
                                                        </div>
                                                        <div className="row mt-40">
                                                        <div className="col-md-6 form-group">
                                                            <label>Meal Options</label>
                                                            
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="custom_meal_price"
                                                                    value={custom_stay_option_data["custom_meal_price"]["name"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type:"custom_meal_price", value:data, name:"name"})
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Meal Price Per person</label>
                                                            
                                                            <NormalInput
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="custom_meal_price"
                                                                    value={custom_stay_option_data["custom_meal_price"]["price_per_person"]}
                                                                    onChange={(data) => {
                                                                        this.handleAddCustomStayOption({type:"custom_meal_price", value:data, name:"price_per_person"})
                                                                    }}
                                                            />
                                                        </div>
                                                        </div>
                                                        <div className="row mt-40">
                                                        <div className="col-md-6 form-group">
                                                            <label>Image url 1</label>
                                                            <input
                                                                className="form-control"
                                                                id="image_url"
                                                                type="text"
                                                                name="image_url"
                                                                value={custom_stay_option_data["image_url"][0]}
                                                                onChange={this.handleAddCustomStayOptionImages}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <label>Image url 2</label>
                                                            <input
                                                                className="form-control"
                                                                id="image_url"
                                                                type="text"
                                                                name="image_url"
                                                                value={custom_stay_option_data["image_url"][1]}
                                                                onChange={this.handleAddCustomStayOptionImages}
                                                            />
                                                        </div>
                                                    <div className="col-md-6 form-group mt-3">
                                                        <label ><strong>Total Price: {custom_stay_total_price}</strong></label>

                                                    </div>
                                                        </div>
                                                    </> : ""}
                                            </Tab>

                                            <Tab eventKey="master_stay" title="Master Stay Option">
                                                {add_master_stay_option ?
                                                <>
                                                    <div className="row mt-40">
                                                    <div className="col-md-6 form-group">
                                                        <label >Stay Type</label>
                                                        <ReactSelect
                                                            isMulti={false}
                                                            options={stay_dropdown}
                                                            handleChange={(keyName, data) => {
                                                                this.handleStayOption({ name: keyName, value: data })
                                                            }}
                                                            keyName="staytype"
                                                            value={staytype}
                                                            disabled={false}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <label >Hotel Type</label>

                                                        <ReactSelect
                                                            isMulti={false}
                                                            options={this.state.hotelDropdown}
                                                            handleChange={(keyName, data) => {
                                                                this.handleHotelOption({ name: keyName, value: data })
                                                            }}
                                                            keyName="hoteltype"
                                                            value={hoteltype}
                                                            disabled={false}
                                                        />
                                                    </div>
                                                    </div>
                                                    {room_options.filter(x => !x.deleted).map((item, index) => {
                                                        let {id, count, price} = item;
                                                        return (<>
                                                            <div className="row">
                                                                <div className="col-md-6 form-group">
                                                                <label>Room Type {index+1} <span className="required">{" *"}</span></label>
                                                                <ReactSelect
                                                                    isMulti={false}
                                                                    options={this.state.roomDropdown}
                                                                    handleChange={(keyName, data) => {
                                                                        this.handleMultipleRoomOption({ name: keyName, value: data, index: index })
                                                                    }}
                                                                    keyName="id"
                                                                    value={id}
                                                                    disabled={false}
                                                                />
                                                                </div>

                                                                <div className="col-md-6 form-group">
                                                                    <label >Room Count <span className="required">{" *"}</span></label>
                                                                    <NormalInput
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="count"
                                                                        value={count}
                                                                        onChange={(data) => {
                                                                            this.handleMultipleRoomOption({name:"count", value: data, index:index})
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6 form-group">
                                                                    <label >Price per Room</label>
                                                                    <NormalInput
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="room_price"
                                                                        value={price}
                                                                        onChange={(data) => {
                                                                            this.handleMultipleRoomOption({name:"price", value: data, index:index})
                                                                        }}
                                                                    />
                                                                </div>
                                                                
                                                            </div>
                                                        </>)
                                                    })}
                                                
                                                <div className="row">
                                                    
                                                    <div className="col-md-6 form-group">
                                                        <label >Meal Type</label>
                                                        <ReactSelect
                                                            isMulti={false}
                                                            options={this.state.mealDropdown}
                                                            handleChange={(keyName, data) => {
                                                                this.handleMealOption({ name: keyName, value: data })
                                                            }}
                                                            keyName="mealtype"
                                                            value={mealtype}
                                                            disabled={false}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <label >Price per Person</label>
                                                        <input
                                                            className="form-control bg-light-gray"
                                                            id="meal_price"
                                                            type="text"
                                                            name="meal_price"
                                                            value={meal_price}
                                                            readonly="readonly"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 form-group mt-3">
                                                        <label ><strong>Total Price: {master_stay_total_price}</strong></label>

                                                    </div>
                                                </div></> : ""}
                                            </Tab>
                                            
                                        </Tabs>
                                    </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </div>
                    </Accordion>
                </div>
                <div className="collapsable-itinerary collapsable-itinerary-transport">
                    <Accordion>
                        <div className="mt-3">
                            <Card>
                                <Card.Header eventKey="0">
                                    Transport Options
                                <Accordion.Toggle variant="link" eventKey="0" className="add-accordin">
                                        Total Price: {(Number(intra_location["full_day_price"]) * Number(intra_location["no_of_full_days"]) + Number(intra_location["half_day_price"]) * Number(intra_location["no_of_half_days"]) + Number(intra_location["pickup_drop_price"]))*Number(intra_location["count"]) + Number(s_d_inter_location["price"]) + Number(d_s_inter_location["price"])}
                                        <i className="ion ml-3">+</i>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <h5>Inter location</h5>
                                        <h6 className="mt-20">From Source to destination</h6>
                                        <div className="row mt-4">
                                            <div className="col-md-6 form-group">
                                                <label >Type</label>
                                                <ReactSelect
                                                    isMulti={false}
                                                    options={interLocationVehicleOptions}
                                                    handleChange={(keyName, data) => {
                                                        this.handleVehicleOption({ name: keyName, value: data, type: "s_d_inter_location", options: interLocationVehicleOptions })
                                                    }}
                                                    keyName="type"
                                                    value={s_d_inter_location["type"]}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label >Source</label>

                                                <input
                                                    className="form-control"
                                                    id="source"
                                                    type="text"
                                                    name="source"
                                                    value={s_d_inter_location["source"]}
                                                    onChange={this.handleTransportOption}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label >Destination</label>
                                                <input
                                                    className="form-control"
                                                    id="destination"
                                                    type="text"
                                                    name="destination"
                                                    value={s_d_inter_location["destination"]}
                                                    onChange={this.handleTransportOption}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Total Price</label>

                                                <input
                                                    className="form-control"
                                                    id="price"
                                                    type="text"
                                                    name="price"
                                                    value={s_d_inter_location["price"]}
                                                    onChange={this.handleTransportOption}
                                                />
                                            </div>
                                        </div>
                                        
                                        <h6>From destination to source</h6>
                                        <div className="row mt-4">
                                            <div className="col-md-6 form-group">
                                                <label >Type</label>
                                                <ReactSelect
                                                    isMulti={false}
                                                    options={interLocationVehicleOptions}
                                                    handleChange={(keyName, data) => {
                                                        this.handleVehicleOption({ name: keyName, value: data, type: "d_s_inter_location", options: interLocationVehicleOptions })
                                                    }}
                                                    keyName="type"
                                                    value={d_s_inter_location["type"]}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label >Source</label>

                                                <input
                                                    className="form-control"
                                                    id="source"
                                                    type="text"
                                                    name="source"
                                                    value={d_s_inter_location["source"]}
                                                    onChange={this.handleDSTransportOption}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label >Destination</label>
                                                <input
                                                    className="form-control"
                                                    id="destination"
                                                    type="text"
                                                    name="destination"
                                                    value={d_s_inter_location["destination"]}
                                                    onChange={this.handleDSTransportOption}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Total Price</label>

                                                <input
                                                    className="form-control"
                                                    id="price"
                                                    type="text"
                                                    name="price"
                                                    value={d_s_inter_location["price"]}
                                                    onChange={this.handleDSTransportOption}
                                                />
                                            </div>
                                        </div>
                                        
                                        <h5>Intra location</h5>
                                        <div className="row mt-4">
                                            <div className="col-md-6 form-group">
                                                <label >Type</label>
                                                <ReactSelect
                                                    isMulti={false}
                                                    options={intraLocationVehicleOptions}
                                                    handleChange={(keyName, data) => {
                                                        this.handleVehicleOption({ name: keyName, value: data, type: "intra_location", options: intraLocationVehicleOptions })
                                                    }}
                                                    keyName="type"
                                                    value={intra_location["type"]}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>No. of vehicle</label>

                                                <input
                                                    className="form-control bg-light-gray"
                                                    id="count"
                                                    type="text"
                                                    name="count"
                                                    value={intra_location["count"]}
                                                    readonly="readonly"
                                                />
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label>Capacity</label>

                                                <input
                                                    className="form-control bg-light-gray"
                                                    id="count"
                                                    type="text"
                                                    name="capacity"
                                                    value={intra_location["capacity"]}
                                                    readonly="readonly"
                                                />
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Full day price</label>
                                                <input
                                                    className="form-control bg-light-gray"
                                                    id="count"
                                                    type="text"
                                                    name="full_day_price"
                                                    value={intra_location["full_day_price"]}
                                                    readonly="readonly"
                                                />
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label>No. of full days</label>

                                                <input
                                                    className="form-control"
                                                    id="no_of_full_days"
                                                    type="text"
                                                    name="no_of_full_days"
                                                    value={intra_location["no_of_full_days"]}
                                                    onChange={this.handleIntraLocationTransportOption}
                                                />
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label>Half day price</label>
                                                <input
                                                    className="form-control bg-light-gray"
                                                    id="count"
                                                    type="text"
                                                    name="half_day_price"
                                                    value={intra_location["half_day_price"]}
                                                    readonly="readonly"
                                                />
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label>No. of half days</label>

                                                <input
                                                    className="form-control"
                                                    id="no_of_half_days"
                                                    type="text"
                                                    name="no_of_half_days"
                                                    value={intra_location["no_of_half_days"]}
                                                    onChange={this.handleIntraLocationTransportOption}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label >Pickup Drop Options</label>
                                                <ReactSelect
                                                    isMulti={false}
                                                    options={intraLocationPickupDropOptions}
                                                    handleChange={(keyName, data) => {
                                                        this.handlePickupDropOption({ name: keyName, value: data, type: "intra_location", options: intraLocationPickupDropOptions })
                                                    }}
                                                    keyName="pick_up_drop"
                                                    value={intra_location["pick_up_drop"]}
                                                    disabled={disable_pickupdrop}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Price</label>
                                                <input
                                                    className="form-control bg-light-gray"
                                                    id="count"
                                                    type="text"
                                                    name="pickup_drop_price"
                                                    value={intra_location["pickup_drop_price"]}
                                                    readonly="readonly"
                                                />
                                            </div>

                                            <div className="col-md-6 form-group  mt-40 pl-20">
                                                <label ><strong>Total Price: {(Number(intra_location["full_day_price"]) * Number(intra_location["no_of_full_days"]) + Number(intra_location["half_day_price"]) * Number(intra_location["no_of_half_days"]) + Number(intra_location["pickup_drop_price"]))*Number(intra_location["count"]) + Number(s_d_inter_location["price"]) + Number(d_s_inter_location["price"])}</strong></label>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </div>
                    </Accordion>
                </div>
                
                {this.state.activityOptionType.itinerary.map((item, dayIndex) => {
                    let act_list = item.activityIds;
                    let act_details = []
                    act_list.forEach((activityId) => {
                        let resId = (activityId + "").slice(0, 6);
                        if (resId != "custom") {
                            let elem = _.filter(activityDropdown, (actItem) => {
                                return actItem.id == activityId;
                            })[0];
                            if (elem) {

                                act_details.push(elem);
                            }
                        }
                        else {
                            let custom_activity_data = this.state.custom_activity_data;
                            let custom_elem = _.filter(custom_activity_data, (custom_item)=>{
                                return custom_item.id == activityId;
                            })[0]
                            act_details.push(custom_elem);
                        }
                    })
                    return (
                        <div>
                           {dayIndex == 0 ?  <h6 className="mb-15 row">
                                <div className="col-sm-6 mt-4"> Itinerary</div>
                            </h6>: ""}
                            <div className="collapsable-itinerary">
                                <Accordion>
                                    <div className="mt-3">
                                            <Card>
                                                <Card.Header>
                                                    Day {dayIndex + 1}
                                                    <Accordion.Toggle variant="link" eventKey={dayIndex} className="add-accordin">
                                                        <i className="ion ml-auto">+</i>
                                                    </Accordion.Toggle>


                                                </Card.Header>

                                                <Accordion.Collapse eventKey={dayIndex}>
                                                    <Card.Body>
                                                        Do you want to add activity ? Choose from master or create your custom activity.
                                                        <div className="mb-30">
                                                            <Tabs defaultActiveKey="master" transition={false} id="noanim-tab-example" onSelect={this.addMasterActivity}>
                                                                <Tab eventKey="master" title="Master">
                                                                    {add_master_activity ?
                                                                        <div className="row mt-40">
                                                                            <div className="col-md-6">
                                                                                <ReactSelect
                                                                                    isMulti={false}
                                                                                    options={addActivtyList}
                                                                                    handleChange={(keyName, data) => {
                                                                                        this.handleAddMasterActivity({ name: keyName, value: data, dayIndex: dayIndex })
                                                                                    }}
                                                                                    keyName="activity_id"
                                                                                    value={activity_id}
                                                                                    disabled={false}
                                                                                />
                                                                            </div>
                                                                            <button className="btn btn-primary ml-30 mb-30" onClick={() => this.handleAddMasterActivity2({ value: activity_id, dayIndex: dayIndex })}>Add Activity</button>

                                                                        </div> : ""}
                                                                </Tab>
                                                                <Tab eventKey="custom" title="Custom">
                                                                    {add_custom_activity ?
                                                                        <div className="row mt-40">
                                                                            <div className="col-md-6 form-group">
                                                                                <label>Title</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    id="title"
                                                                                    type="text"
                                                                                    name="title"
                                                                                    value={custom_activity_add["title"]}
                                                                                    onChange={this.handleAddCustomActivity}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 form-group">
                                                                                <label>Description</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    id="description"
                                                                                    type="text"
                                                                                    name="description"
                                                                                    value={custom_activity_add["description"]}
                                                                                    onChange={this.handleAddCustomActivity}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 form-group">
                                                                                <label>Adult Price</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    id="price"
                                                                                    type="text"
                                                                                    name="price"
                                                                                    value={custom_activity_add["price"]}
                                                                                    onChange={this.handleAddCustomActivity}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 form-group">
                                                                                <label>Child Price</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    id="child_price"
                                                                                    type="text"
                                                                                    name="child price"
                                                                                    value={custom_activity_add["child price"]}
                                                                                    onChange={this.handleAddCustomActivity}
                                                                                />
                                                                            </div>


                                                                            <button className="btn btn-primary ml-30 mb-30" onClick={() => this.handleAddCustomActivity2({ value: custom_activity, dayIndex: dayIndex })}>Add Activity</button>

                                                                        </div> : ""}
                                                                </Tab>

                                                            </Tabs>
                                                        </div>
                                                        {act_details.length > 0 ? <App details={act_details} act_list={act_list} dayIndex={dayIndex} ItineraryData={ItineraryData} changeActivityList={this.changeActivityList} /> : ""}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </div>
                                </Accordion>
                            </div>
                        </div>
                    )
                })}
                 <div className="collapsable-itinerary collapsable-itinerary-transport">
                    <Accordion>
                        <div className="mt-3">
                            <Card>
                                <Card.Header eventKey="0">
                                    Inclusion Exclusion Options
                                <Accordion.Toggle variant="link" eventKey="0" className="add-accordin">
                                    <i className="ion ml-3">+</i>
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <h5>Inclusions</h5>
                                        <div className="row">
                                            <div className="col-md-12 text-right">
                                                <button className="btn btn-primary btn-sm"
                                                    onClick={() => this.handleAddInclusion('inclusions')}
                                                >
                                                    Add Inclusion
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-4">
                                            {inclusions.map((inclusion_item, index) => {
                                                let str = inclusion_item.description;
                                                let is_breakfast = str.includes("Breakfast");
                                                let res = is_breakfast ? str.replace('n day(s)', noOfDays-1 + " day(s) ") : str.replace('n day(s)', noOfDays + " day(s) ");;
                                                let new_res = res.replace('n night(s)', Number(noOfDays-1) == 0 ? '' :  + Number(noOfDays-1)+" night(s) ");
                                                return( 
                                                <>
                                                    <div className="col-md-5 mt-20">
                                                        <NormalInput
                                                            className="form-control"
                                                            type="text"
                                                            name="inclusion"
                                                            value={new_res}
                                                            onChange={(data) => {
                                                                this.handleInclusions({type: "inclusions", value: data, index: index})
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1 mt-20 align-self-center pt-3">
                                                        <i
                                                        className="icon-trash text-danger cursor-pointer delete-inclusion"
                                                        onClick={e => this.handleDeleteInclusions("inclusions", index)}
                                                        ></i>
                                                    </div>
                                              </>)
                                            })}
                                            
                                        </div>
                                        <hr/>
                                        <h5 className="mt-20">Exclusions</h5>
                                        <div className="row">
                                            <div className="col-md-12 text-right">
                                                <button className="btn btn-primary btn-sm"
                                                    onClick={() => this.handleAddInclusion('exclusions')}
                                                >
                                                    Add Exclusion
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            {exclusions.map((exclusion_item, index) => {
                                                let str = exclusion_item.description;
                                                let res = str.replace('n day(s)', noOfDays + " day(s) ");
                                                let new_res = res.replace('n night(s)', Number(noOfDays-1) == 0 ? '' : Number(noOfDays-1) + " night(s) ");
                                                
                                                return( 
                                                <>
                                                    <div className="col-md-5 mt-20">
                                                        <NormalInput
                                                            className="form-control"
                                                            type="text"
                                                            name="exclusion"
                                                            value={new_res}
                                                            onChange={(data) => {
                                                                this.handleInclusions({type: "exclusions", value: data, index: index})
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1 mt-20 align-self-center pt-3">
                                                        <i
                                                        className="icon-trash text-danger cursor-pointer delete-inclusion"
                                                        onClick={e => this.handleDeleteInclusions("exclusions", index)}
                                                        ></i>
                                                    </div>
                                              </>)
                                            })}
                                            
                                        </div>
                                        
                                        </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </div>
                    </Accordion>
                </div>
               
                <div className="row mt-30">
                <div className="col-md-12">
                    <p><b>Add GUIDE price Rs1600/day if needed</b></p>
                </div>
                </div>
                <div className="row mt-15">
                    <div className="col-md-4">
                        <label>Total Price: </label>
                        <input
                            className="form-control"
                            id="total_itinerary_price"
                            type="number"
                            name="total_itinerary_price"
                            value={total_itinerary_price}
                            onChange={this.handleTotalPrice}
                        />
                    </div>
                    <div className="col-md-4">
                        <label>Total Price Per Person: </label>
                        <input
                            className="form-control"
                            id="total_price_per_person"
                            type="number"
                            name="total_price_per_person"
                            value={total_price_per_person}
                            onChange={this.handleTotalPrice}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>Display total price </label>
                        <input
                            className="form-control"

                            id="display_total_price"
                            type="checkbox"
                            name="display_total_price"
                            value={display_total_price}
                            onChange={this.handleTotalPrice}
                        />
                    </div>
                </div>
                {this.state.disable_preview ? <button className="btn btn-primary mt-3" disabled >Preview</button> : loader_preview ? <button className="btn btn-primary mt-3" disabled>Preview...</button> : <button className="btn btn-primary mt-3" onClick={() => this.generatePDF()}>Preview</button>}
                {this.state.disable_preview ? <button className="btn btn-primary mt-3 ml-3" disabled>Convert to Activity</button> : <button className="btn btn-primary mt-3 ml-3" disabled>Convert to Activity</button>}
            </section>
        </>
        );
    }
}

const mapStateToProps = state => {
    return { 
        itineraryData: state.itinerary.itineraryData,
        itineraryMasterData: state.itinerary.itineraryMasterData,
        cityNames: state.itinerary.cityList
    }
};
            
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        iternaryGenerate, saveItinerary, getItineraryDetailsById, getItineraryMasterData,getCityNames, getBase64Images
    }, dispatch)
};

let actions = ['getCityNames']
let component = LoaderWrapper(ItineraryCreateClass)({ actions })

export const ItineraryCreate = connect(mapStateToProps, mapDispatchToProps)(component)
