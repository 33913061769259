import { topActivityType } from "../../services/actionType"

const initialState = {
    topActivityList: [],
    activityDropdownList: [],
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

    switch (type) {

        case topActivityType.updateTopActivityDetails:
            return {
                ...state,
                topActivityList: payload
            }
        case topActivityType.updateTopActivityDropdownDetails:
            return {
                ...state,
                activityDropdownList: payload
            }

        default:
            return state

    }
}