import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal, ToggleCheckbox } from "../../component/common"
import { createCatalogue, deleteProductById, getProducts, getBrandNames, getSellerNames, getProductsBySearch, editProductDetailsById } from "../../redux/actions/reseller"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getCategories } from "../../redux/actions/reseller"
import { getSNo } from "../../services/helperFunctions"
import { LoaderWrapper } from "../../HOC"
import { directive } from '@babel/types';
import { ReactSelect } from '../../component/common/ReactSelect'
import _ from 'lodash'

class ProductListClass extends Component {

	state = {
		products: [],
		brandNames: [],
		sellerNames: [],
		brand: "",
		sellerId: "",
		approvalStatus: "",
		headerDetails: [
			{
				label: 'S.No'
			},
			{
				label: 'Name',
			},
			//commented sp can use late
			// {
			// 	label: 'Hide in App'
			// },

			{
				label: 'Images Upload Status',
			},
			{
				label: 'Status'
			},
		],
	}

	componentWillMount() {

	}

	componentDidMount() {
		this.props.getBrandNames().then((data) => {
			let brandNames = _.map(data, (brand) => {
				return { value: brand._id, label: brand.name }
			})
			brandNames.push({ label: "All", value: '' })
			this.setState({ brandNames: brandNames })
		})
		this.props.getSellerNames().then((data) => {
			let sellerNames = _.map(data, (seller) => {
				return { value: seller.id, label: seller.first_name }
			})
			sellerNames.push({ label: "Admin", value: 'admin' })
			sellerNames.push({ label: "All", value: '' })
			this.setState({ sellerNames: sellerNames })
		})
		let brand = localStorage.getItem('brand') ? localStorage.getItem('brand') : this.state.brand;
		let sellerId = localStorage.getItem('sellerId') ? localStorage.getItem('sellerId') : this.state.sellerId;
		let approvalStatus = localStorage.getItem('approvalStatus') ? localStorage.getItem('approvalStatus') : this.state.sellerId;
		let query = {
			pageNo: 1
		};
		query['sellerId'] = sellerId;
		query['brand'] = brand;
		query['approvalStatus'] = approvalStatus;
		this.props.getProducts(query)
		this.setState({
			brand, sellerId, approvalStatus
		})

	}

	handleSearchFilter = (query, value, name) => {
		if (query.searchText !== "") {
			this.setState({ searchText: query.searchText })
			this.props.getProductsBySearch(query)
		} else {
			let query = {
				pageNo: 1
			};
			this.props.getProducts(query)
		}
	}
	handleTopFilter = () => {
		let query = {
			pageNo: 1
		};
		query['sellerId'] = this.state.sellerId;
		query['brand'] = this.state.brand;
		query['approvalStatus'] = this.state.approvalStatus;

		this.props.getProducts(query)
	}
	handleFilter = (query) => {

		let brand = localStorage.getItem('brand') ? localStorage.getItem('brand') : this.state.brand;
		let sellerId = localStorage.getItem('sellerId') ? localStorage.getItem('sellerId') : this.state.sellerId;
		let approvalStatus = localStorage.getItem('approvalStatus') ? localStorage.getItem('approvalStatus') : this.state.sellerId;
		query['sellerId'] = sellerId;
		query['brand'] = brand;
		query['approvalStatus'] = approvalStatus;
		query['searchText'] = this.state.searchText;
		query['search'] = this.state.search;
		if (this.state.search !== "" && this.state.search !== undefined) {
			this.props.getProductsBySearch(query)
		} else {
			this.props.getProducts(query)
		}
	}

	handleSelect = ({ name, value }) => {
		this.setState({
			[name]: value
		}, this.setInLocaStorage(name, value))
	}
	handleAddorEdit = (id) => {
		if (id) {
			this.props.history.push(`/reseller/product/edit/${id}`)
		} else {
			this.props.history.push('/reseller/product/create')
		}
	}
	setInLocaStorage = (name, value) => {
		if (name === 'brand' || name === 'sellerId' || name === 'approvalStatus') {
			window.localStorage.setItem(name, value)
		}
	}
	handleDelete = (id) => {
		this.props.deleteProductById(id).then(() => {
			window.location.reload()
		})
	}
	toggleStatusChange = (id, stat) => {
		let productHideInAppStatus = {}
		productHideInAppStatus._id = id;
		productHideInAppStatus.status = !stat
		this.setState({
			productHideInAppStatus
		})
		this.toggle('ConfirmModalChangeProductHideInAppStatus')
	}

	changeStatus = () => {
		const { productHideInAppStatus: { _id, status } } = this.state
		const body = {
			_id: _id,
			hideOnApp: status
		}
		this.props.editProductDetailsById(_id, body).then(() => {
			window.location.reload();
		})
	}
	toggle = item => {
		this.setState(prevState => ({
			[item]: !prevState[item]
		}));
	}
	requestStatus = (status) => {

		if (status === 1) {
			return <Badge
				type={1}
				label={"Images Uploaded"}
			/>
		} else if (status === 0) {
			return <Badge
				type={2}
				label={"No images"}
			/>
		}

	}
	createCatalogue = () => {
		this.props.createCatalogue()
	}

	render() {

		let { headerDetails, brandNames, brand, sellerId, approvalStatus, sellerNames, ConfirmModalChangeProductHideInAppStatus } = this.state
		let { productList } = this.props
		let IsEmpty = productList && productList.list && productList.list.length > 0
		let statusList = [
			{ value: 'pending', label: "Pending" },
			{ value: 'approved', label: "Approved" },
			{ value: 'rejected', label: "Rejected" },
			{ value: '', label: "All" }
		];
		return (
			<div>
				{/* Header */}
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Product List
					</h4>
					<div className="col-auto ">
						{/* commented so can be used later */}
						{/* <button className="btn btn-primary btn-sm"
							onClick={() => this.createCatalogue()}
						>Create catalogue</button> */}
						<Link to="/reseller/product/position"><button className="btn btn-primary btn-sm m-1"

						>Product Positions</button></Link>
						{/* commented so can be used later */}
						{/* <button className="btn btn-primary btn-sm"
            onClick={() => this.handleAddorEdit()}
          >Add Product</button> */}
					</div>
				</div>

				{/* Content */}
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<div className="col-md-3 form-group">
									<label><b>Seller Name</b></label>
									<ReactSelect
										isMulti={false}
										options={sellerNames}
										handleChange={(keyName, data) => {
											this.handleSelect({ name: keyName, value: data })
										}}
										keyName="sellerId"
										value={sellerId}
										disabled={false}
									/>
								</div>
								<div className="col-md-2 form-group ml-1">
									<label><b>Brand Name</b></label>
									<ReactSelect
										isMulti={false}
										options={brandNames}
										handleChange={(keyName, data) => {
											this.handleSelect({ name: keyName, value: data })
										}}
										keyName="brand"
										value={brand}
										disabled={false}
									/>
								</div>
								<div className="col-md-2 form-group ml-1">
									<label><b>Status</b></label>
									<ReactSelect
										isMulti={false}
										options={statusList}
										handleChange={(keyName, data) => {
											this.handleSelect({ name: keyName, value: data })
										}}
										keyName="approvalStatus"
										value={approvalStatus}
										disabled={false}
									/>
								</div>
								<div className="col-md-1 ">
									<br />
									<button className="btn btn-primary btn-sm mt-10 ml-5" onClick={() => this.handleTopFilter()}>Filter</button>
								</div>
								<div className="col-md-3 form-group">
									<label><b>Search by product name</b></label>
									<Filters
										className="p-0"
										queryHandler={(query) => this.handleSearchFilter(query)}
										isDropDownFilter={false}
									/>
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									pageMeta={productList && productList.pageMeta}
									queryHandler={(query) => this.handleFilter(query)}
								>
									{productList && productList.list && productList.list.map((item, index) => {
										let { id, _id, name, status, hideOnApp, shareLink, activityId, active, enableResellingSupport, imgLinks } = item

										return (
											<tr
												key={index}  >
												<td>{index + 1}</td>
												<td>{name}</td>
												{/* commented sp can use late */}
												{/* <td><ToggleCheckbox value={hideOnApp} onClick={() => this.toggleStatusChange(_id, hideOnApp)} /></td> */}

												<td>{this.requestStatus(imgLinks && imgLinks.length > 0 ? 1 : 0)}</td>
												<td style={{ width: 200 }}>
													<span>
														<i style={{ fontWeight: 200, color: "#666" }}>{status ? 'Admin approval- ' + status : 'Admin approval-NA'}</i>
													</span>
													<br />
													<span>
														<i style={{ fontWeight: 200, color: "#666" }}>{active == undefined ? 'Seller Activation- NA' : 'Seller Activation-' + active}</i>
													</span>
													<br />
													<span>
														<i style={{ fontWeight: 200, color: "#666" }}>{hideOnApp === undefined ? 'Hide In App- NA' : 'Hide In App- ' + hideOnApp}</i>
													</span>
													<br />
													<span>
														<i style={{ fontWeight: 200, color: "#666" }}>{enableResellingSupport === undefined ? 'Reselling Support Status- NA' : 'Reselling Support Status-' + enableResellingSupport}</i>
													</span>

												</td>
												<td>
													{/* commented so it can use later */}
													{/* <a className="mr-25"
														onClick={e => this.handleAddorEdit(_id)}
													>
														<i className="icon-pencil text-primary cursor-pointer"
														></i>
													</a> */}
													{/* <a>
														<i
															className="icon-trash txt-danger text-primary cursor-pointer"
															onClick={e => this.handleDelete(_id)}
														></i>
													</a> */}
												</td>

											</tr>
										)
									})}
								</TableWrapper>
							</div>
						</div>
					</div>
				</section>
				<ConfirmModal
					isOpen={ConfirmModalChangeProductHideInAppStatus}
					keyName="ConfirmModalChangeProductHideInAppStatus"
					toggle={() => {
						let { productHideInAppStatus } = this.state
						productHideInAppStatus._id = null;
						productHideInAppStatus.status = null;
						this.setState({
							productHideInAppStatus
						}, () => this.toggle('ConfirmModalChangeProductHideInAppStatus'))
					}}
					handleSubmit={() => this.changeStatus()}
				>
					<h5>Are you sure you want to change the hide in app status?</h5>
				</ConfirmModal>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		productList: state.reseller.productList, // data from reducer to productLis
	}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getProducts,
		deleteProductById,
		getBrandNames,
		getSellerNames,
		getProductsBySearch,
		editProductDetailsById,
		createCatalogue,
	}, dispatch)
};

let actions = []

let component = LoaderWrapper(ProductListClass)({ actions })

export const ProductList = connect(mapStateToProps, mapDispatchToProps)(component)
