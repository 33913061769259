import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { getGtmWareHouseNames } from "../../redux/actions/reseller";
import {
  updatePaymentStatus,
  cancelOrder,
  getOrderRemarks,
  addOrderRemarks,
  getOrderInventory,
  generateInvoiceByOrderId,
  updateOrderDocStatus,
  saveDraftOrder,
  getGtmOrdersList
} from "../../redux/actions/orders";

import { generatePurchaseOrder } from "../../redux/actions/purchaseOrder";
import { LoaderWrapper } from "../../HOC";
import {
  TableWrapper,
  Badge,
  ReactSelect,
  Filters,
  NormalInput,
} from "../../component/common";
import { website_url } from "../../config/config";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import moment from "moment";
import { Toast } from "../../services/toast";
import { orders } from "../../services/apiVariables";
import { sumBy, map, chunk } from "lodash";
import Select from 'react-select';

const download_invoice_url =
  process.env.REACT_APP_BASE_URL +
  "v1/admin/download-customer-invoice?orderId=";

class GtmOrdersListClass extends Component {
  state = {
    headerDetails: [
      // {
      //   label: 'Checkbox'
      // },
      {
        label: "Order ID",
      },
      {
        label: "Reseller Info",
      },
      {
        label: "Customer Info",
      },
      {
        label: "Total Price",
      },
      {
        label: "Order Date",
      },
      {
        label: "Status List",
      },
      {
        label: "Payment Received",
      },
      {
        label: "Payment Mode",
      },
    ],
    warehouseNames: [],
    warehouse_id: undefined,
    isOpen: false,
    isCancelModalOpen: false,
    payment_orders: [],
    finalQuantityMapping: [],
    orderTracking: [],
    cartoonList: [],
    inventoryObj: [],
    orderRemarksModal: false,
    remark: {},
    invoicePassword: "",
    isInvoicesVisible: false,
    tractArray: [],
    waitingForApproval: false,
    searchBySellMoreType: false,
    selectedWareHouseName: '',
    autoPush: false,
  };

  handleFilter = (query, value, name) => {
    if (name == "filter") {
      this.setState({
        selectedFilter: query,
      });

      query = {
        pageNo: 1,
        filter: query,
      };
    }

    console.log('filter by', query);

    this.props.getGtmOrdersList(query);
  };

  componentDidMount() {
    this.props.getGtmOrdersList({ searchByEmail: '', searchByContact: '', searchByOrder: '', searchByStatus: '', searchByWarehouse: '', bareilly: true, });
    this.props.getGtmWareHouseNames().then((data) => {
      data.sort(function (x, y) { return x.id === 211 ? -1 : 0; });
      this.setState({ warehouseNames: data });
    });
  }

  handleSearchFilter = (query, type) => {
    console.log('handleSearchFilter', query, type);
    let customQuery = {};
    if (query.search !== "") {
      if (type === "email") {
        this.setState({ searchByEmail: query.search });
        customQuery["searchByEmail"] = query.search;
      } else {
        // this.setState({ searchByEmail: "" });
        customQuery["searchByEmail"] = "";
      }
      if (type === "phone") {
        this.setState({ searchByContact: query.search });
        customQuery["searchByContact"] = query.search;
      } else {
        // this.setState({ searchByContact: query.search });
        customQuery["searchByContact"] = "";
      }
      if (type === "order_id") {
        this.setState({ searchByOrder: query.search });
        customQuery["searchByOrder"] = query.search;
      } else {
        // this.setState({ searchByOrder: query.search });
        customQuery["searchByOrder"] = "";
      }
      if (type === "warehouse") {
        this.setState({ selectedWareHouseName: query.label })
        customQuery["bareilly"] = query.value;
      } else {
        customQuery["bareilly"] = "";
      }
      if (type === "status") {
        this.setState({ searchByStatus: query.search });
        customQuery["searchByStatus"] = query.search;
      } else {
        // this.setState({ searchByStatus: query.search });
        customQuery["searchByStatus"] = "";
      }
      if (type === "sell_more") {
        this.setState({ searchBySellMoreType: query.search.value });
        customQuery["sellmore"] = query.search.value;
      }
      if (type === "autopush") {
        this.setState({ autoPush: query.search.value });
        customQuery["autopush"] = query.search.value;
        customQuery["bareilly"] = true;
      }
      console.log('serach query', customQuery, query, type);
      this.props.getGtmOrdersList(customQuery);
    }
  };

  copyToClipBoard = () => {
    /* Get the text field */
    var copyText = document.getElementById("payment_link");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
  };

  requestStatus = (is_cod, is_credit_payment) => {
    if (is_cod) {
      return <Badge type={3} label={"COD"} />;
    } else if (is_credit_payment) {
      return <div style={{ backgroundColor: 'skyblue', borderRadius: 3, color: 'white', fontSize: 13, padding: 2, textAlign: 'center' }}>Credit</div>
    } else {
      return <Badge type={1} label={"Online"} />;
    }
  };

  handleSubmit = async (order) => {
    this.setState({
      isOpen: true,
      finalQty: "",
      warehouseId: "",
      productVariantSize: "",
      addToCartoonErr: "",
      pushOrderErr: "",
      tractArray: [],
    });
    await this.getSingleOrderInfo(order.id).then((orderDataObj) => {
      if (orderDataObj) {
        let orderData = orderDataObj[0];
        let { delivery_price, cod_charges } = orderData;
        const { warehouseNames } = this.state;
        let payment_link =
          website_url + "customer-payment-link?order_id=" + orderData.id;
        this.setState({ orderData: orderData }, () =>
          this.getRemarks(orderData.id)
        );
        this.setState(
          {
            orderData: orderData,
            payment_link,
            isOpen: true,
            cartoonList: [],
            waitingForApproval: orderData.doc_status || "N",
          },
          () => {
            this.getOrderInventory(orderData.id);
          }
        );
        let productList = [];
        let boxWisePayload = [];

        if (
          orderData &&
          orderData.OrderDetails &&
          orderData.OrderDetails[0] &&
          orderData.OrderDetails[0].details.basketDetails.products.length > 0
        ) {
          productList =
            orderData.OrderDetails[0].details.basketDetails.products;
          boxWisePayload = orderData.OrderDetails[0].details.boxWisePayload;
        }
        const productListForSelect = productList.map((product) => {
          return {
            value: `${product.id}_${product.variant_no ? product.variant_no : "NA"
              }_${product.size_no ? product.size_no : "NA"}`,
            label: `${product.details.name}${product.variant ? `-${product.variant.name}` : ""
              }`,
          };
        });
        const updatedProductList = productList.map((product) => {
          //Product wise ware house
          let [productWarehouse] = warehouseNames.filter((item) => {
            return item._id === product.details.warehouse_id;
          });
          //get the updated ware house from cartoon if found
          boxWisePayload &&
            Object.entries(boxWisePayload).map(([key, value]) => {
              const [productForWareHouse] = value.filter(
                (cartoonProduct) => cartoonProduct.productId === product.id
              );
              if (productForWareHouse) {
                productWarehouse = productForWareHouse.warehouse;
              }
            });
          //updating it to product list
          product["productWarehouse"] = productWarehouse;
          return product;
        });
        const { PaymentDetails, total_price } = orderData;
        let paymentAmount;
        if (PaymentDetails) {
          if (PaymentDetails.payment_amount === 0) {
            paymentAmount = total_price;
          } else {
            paymentAmount = PaymentDetails.payment_amount;
          }
        } else {
          paymentAmount = total_price;
        }
        const finalTotal = productList.reduce((accumulator, product) => {
          const notExistFinalQty = !("finalQuantity" in product);
          const noOfUnits =
            product.variant && product.variant.no_of_unit
              ? product.variant.no_of_unit
              : 1;
          let price =
            product.final_price *
            noOfUnits *
            (!notExistFinalQty ? product.finalQuantity : product.quantity);
          return (accumulator += parseFloat(price));
        }, 0);
        const resellerCommission =
          orderData.OrderDetails[0].details.resellerCommissionAmount;
        cod_charges = cod_charges ? cod_charges : 0;
        const finalTotalAmount =
          finalTotal + parseFloat(cod_charges) + parseFloat(delivery_price);

        let cartoonList = [];
        let purchaseDrafts = [];
        if (
          orderData &&
          orderData.OrderDetails &&
          orderData.OrderDetails[0] &&
          orderData.OrderDetails[0].details &&
          orderData.OrderDetails[0].details.draft &&
          orderData.OrderDetails[0].details.draft.length > 0
        ) {
          purchaseDrafts = orderData.OrderDetails[0].details.draft;
        }
        if (
          orderData &&
          orderData.purchaseDraft &&
          orderData.purchaseDraft[0]
        ) {
          purchaseDrafts = orderData.purchaseDraft[0].draft;
        }
        if (purchaseDrafts.length > 0) {
          cartoonList = purchaseDrafts.map((item, index) => {
            const value = item.map((box) => {
              const product = productList.find(
                (productItem) => productItem.id === box.sku
              );
              const warehouse = warehouseNames.find(
                (warehouse) => warehouse._id === box.warehouse_id
              );
              return {
                productId: box.sku,
                quantity: box.quantity,
                warehouseId: box.warehouse_id,
                name: product.details.name,
                variant: product.variant,
                variantNo: product.variant_no,
                noOfUnit: product.no_of_unit,
                warehouse,
              };
            });
            return {
              key: `box${index + 1}`,
              value,
            };
          });
        }
        if (
          orderData &&
          orderData.OrderDetails &&
          orderData.OrderDetails[0] &&
          orderData.OrderDetails[0].details &&
          orderData.OrderDetails[0].details.boxWisePayload
        ) {
          cartoonList = Object.values(
            orderData.OrderDetails[0].details.boxWisePayload
          ).map((item, index) => ({
            key: `box${index + 1}`,
            value: item,
          }));
        }

        this.setState({
          disabled: false,
          productList: updatedProductList,
          paymentAmount,
          productListForSelect,
          finalTotalAmount,
          cartoonList,
        });
      } else {
        alert("something went wrong try again later.");
      }
    });
  };

  getSingleOrderInfo = async (orderId) => {
    this.setState({
      loadingSingleOrderDetail: true,
    });
    let API_URL = `${process.env.REACT_APP_BASE_URL}api/v1/admin/orders/${orderId}`;
    const token = localStorage.getItem("AuthToken");
    const apiRes = await fetch(API_URL, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      method: "GET",
    });
    const dataFromApi = await apiRes.json();
    this.setState({
      loadingSingleOrderDetail: false,
    });
    if (
      dataFromApi.status == 200 &&
      dataFromApi.data &&
      dataFromApi.data.list
    ) {
      return dataFromApi.data.list;
    }
  };

  getOrderInventory(orderId) {
    this.setState({ fetchingStockInfo: true });
    this.props.getOrderInventory(orderId).then((data) => {
      this.setState({ inventoryObj: data, fetchingStockInfo: false });
    });
  }

  purchaseOrder(order_id) {
    let body = {};
    const { warehouseMappings, cartoonList, orderData } = this.state;
    body.orderId = order_id;
    if (!orderData.is_wholesale) {
      body.warehouseMappings = warehouseMappings;
      body.finalQuantityMapping = Object.fromEntries(
        cartoonList.map((cartoon) => Object.values(cartoon))
      );
    }
    this.props
      .generatePurchaseOrder(body)
      .then((data) => {
        this.setState({ loading: false });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
      });
    this.setState({ disabled: true, loading: true });
  }

  pushOrder(orderId) {
    // let body = {};
    const { cartoonList } = this.state;
    // body.orderId = orderId;
    // if (!orderData.is_wholesale) {
    //   body.warehouseMappings = warehouseMappings;
    //   body.finalQuantityMapping = Object.fromEntries(
    //     cartoonList.map((cartoon) => Object.values(cartoon))
    //   );
    // }

    const emptyCartoon = cartoonList.find(
      (cartoon) => cartoon.value.length === 0
    );
    if (emptyCartoon) {
      this.setState({
        pushOrderErr: "You have empty cartoon. Please add products to it.",
      });
      return;
    }
    this.setState({
      pushOrderErr: "",
    });

    const body = cartoonList.map((cartoon) => {
      return cartoon.value.map((item) => ({
        sku: item.productId,
        quantity: item.quantity,
        warehouse_id: item.warehouseId,
        variant_no: item.variantNo,
      }));
    });
    this.props
      .saveDraftOrder(orderId, body)
      .then((data) => {
        this.setState({ loading: false });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
      });
    this.setState({ disabled: true, loading: true });
  }

  toggle = () => {
    this.setState({
      isOpen: false,
    });
    setTimeout(() => {
      this.setState({
        cartoonList: [],
        inventoryObj: [],
        orderRemarksModal: false,
        remark: {},
        waitingForApproval: false,
        orderData: {
          OrderDetails: [],
          purchaseDraft: [],
          purchaseOrders: [],
        },
      });
    }, 500);
  };

  toggleCancelModal = () => {
    this.setState({ isCancelModalOpen: false });
  };

  toggleTrackingHistoryModal = () => {
    this.setState({ showTrackingHistoryModal: false });
  };

  showCancelModal = (orderData) => {
    this.setState({ orderId: orderData.id, isCancelModalOpen: true });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleRemarksChange = ({ name, value }) => {
    let remark = Object.assign({}, this.state.remark);
    remark[name] = value;
    this.setState({ remark });
  };
  cancelOrder = () => {
    let { reason, remarks } = this.state;
    this.setState({ errMessage: "" });
    reason = typeof reason === "string" && reason.length > 0 ? reason : false;
    remarks =
      typeof remarks === "string" && remarks.length > 0 ? remarks : false;

    if (!reason) {
      this.setState({ errMessage: "Reason required!" });
    } else {
      this.setState({ errMessage: "" });
    }
    if (reason) {
      let body = {
        orderId: this.state.orderId,
        cancellationInfo: {
          reason: reason,
          remarks: remarks,
        },
      };
      this.props.cancelOrder(body).then((response) => {
        if (response.status === 200) {
          this.setState({
            cancelSucMessage: "Order Cancelled successfully!",
            isCancelModalOpen: false,
            reason: "",
            remarks: "",
          });
          this.props.getGtmOrdersList();
        } else {
          this.setState({ errMessage: "Order cancellation failed!" });
        }
      });
      this.setState({ OrderCancelInProgress: true });
    }
  };

  getJsDate = (date, type) => {
    if (date === undefined) {
      return "NA";
    }
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours(),
      amPm = d.getHours() >= 12 ? "PM" : "AM",
      minutes = d.getMinutes();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (type === "dateTime") {
      return [year, month, day]
        .join("-")
        .concat(` ${hours}:${minutes} ${amPm}`);
    }
    return [day, month, year].join("-").concat(` ${hours}:${minutes} ${amPm}`);
  };

  handleModalCancel(name) {
    this.setState({ [name]: false });
  }

  openPDF(order_id) {
    window.location.href =
      "http://localhost:2004/admin/downloadOneOrder/" + order_id;
  }

  handleFinalQtyChange = (data, value) => {
    const { quantityMappings } = this.state;
    let updatedQuantityMappings =
      quantityMappings.length > 0 &&
      quantityMappings.map((item) => {
        if (
          data.id === item.id &&
          data.variant_no === item.variant_no &&
          data.size_no === item.size_no
        ) {
          item.finalQuantity = value;
        }
        return item;
      });
    this.setState({ quantityMappings: updatedQuantityMappings });
  };

  paymentStatusUpdate = () => {
    this.setState({ paymentStatusUpdateMsg: "", sucMessage: "" });
    let { payment_orders } = this.state;
    if (payment_orders && payment_orders.length > 0) {
      this.setState({ loading: true });
      this.props
        .updatePaymentStatus({
          status: true,
          orderIds: payment_orders,
        })
        .then((response) => {
          this.setState({
            loading: false,
            sucMessage: "Payment status updated!",
          });
          setTimeout(() => window.location.reload(), 1500);
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      this.setState({ paymentStatusUpdateMsg: "Choose orders to update!" });
    }
  };

  handlePaymentCheckBox = (order_id) => {
    let { payment_orders } = this.state;
    let exist =
      payment_orders &&
      payment_orders.length > 0 &&
      payment_orders.find((order) => order == order_id);
    if (exist) {
      payment_orders =
        payment_orders &&
        payment_orders.length > 0 &&
        payment_orders.filter((order) => {
          return order != order_id;
        });
    } else {
      payment_orders.push(order_id);
    }
    this.setState({ payment_orders });
  };

  viewTrackingModel = (trackingData, productDetails, orderStatus) => {
    this.setState({ showTrackingHistoryModal: true });
    let orderTracking = [];
    let awbArray = [];
    let etd;
    trackingData.map((box, index) => {
      if (box.client_sku_id_arr && box.client_sku_id_arr.length > 0) {
        let [skuArr] = box.client_sku_id_arr.filter(
          (sku) => sku.client_sku_id === productDetails.id
        );
        if (skuArr) {
          awbArray.push(box.awb);
          orderTracking.push({
            awb: box.awb,
            qty: skuArr.quantity,
            trackHistory: box.orderTracking,
          });
          etd = box.etd === "-" ? box.etd : this.getJsDate(box.etd);
        }
      }
    });
    let awb_object = {
      awb_numbers: awbArray,
    };
    this.getTrackingInfo(awb_object);
    this.setState({ orderTracking, etd, trackingOrderStatus: orderStatus });
  };

  getTrackingInfo = async (awb = null) => {
    if (!awb || !awb.awb_numbers) return;
    const API_URL =
      "https://services-prod-gateway.apnaklub.com/api/v1/admin/orders/track-sfx-orders";
    const chunks = chunk(awb.awb_numbers, 50);

    const promises = chunks.map((chunk) => {
      return fetch(API_URL, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token 1323d93f2a090c55051573d5db2409d7df896244",
        },
        method: "POST",
        body: JSON.stringify({ awb_numbers: chunk }),
      });
    });

    Promise.all(promises)
      .then((results) => Promise.all(results.map((r) => r.json())))
      .then((responses) => {
        let tracksArray = [];
        responses.map((responseJson) => {
          let trackInfoArray = responseJson.data
          for (const key in trackInfoArray) {
            if (key.length > 5) {
              tracksArray.push(trackInfoArray[key][0].client_request);
            }
          }
        });
        this.setState({
          tractArray: tracksArray,
        });
      });
  };

  //Handle Cartoon functionality
  handleCartoonAdd = () => {
    let { cartoonList } = this.state;
    if (cartoonList.length === 0) {
      cartoonList.push({ key: "box1", value: [] });
    } else {
      const latestCartoon = cartoonList[cartoonList.length - 1];
      if (latestCartoon.value.length > 0) {
        const key = parseInt(
          latestCartoon.key.substring(3, latestCartoon.key.length)
        );
        cartoonList.push({ key: `box${key + 1}`, value: [] });
      } else {
        this.setState({
          addToCartoonErr:
            "Please add products to current cartoon ,before creating new cartoon!",
        });
      }
    }
    this.setState({ cartoonList });
  };

  handleInputSelect = ({ name, value }) => {
    if (name === "product") {
      this.setState({ productVariantSize: value });
    } else if (name === "finalQty") {
      this.setState({ finalQty: value });
    } else if (name === "warehouseId") {
      this.setState({ warehouseId: value });
    }
  };

  addToCartoon = () => {
    this.setState({ addToCartoonErr: "" });
    const {
      finalQty,
      warehouseId,
      productList,
      warehouseNames,
      productListForSelect,
      inventoryObj,
    } = this.state;
    let { productVariantSize, cartoonList } = this.state;

    const [productDetails] = productList.filter((product) => {
      const uniqueProduct = `${product.id}_${product.variant_no ? product.variant_no : "NA"
        }_${product.size_no ? product.size_no : "NA"}`;
      return uniqueProduct === productVariantSize;
    });

    productVariantSize = productVariantSize.split("_");
    const [productId, variantNo, sizeNo] = productVariantSize;

    const [warehouse] = warehouseNames.filter((item) => {
      return item._id === warehouseId;
    });

    if (
      (warehouse.type === "vinretail" &&
        productDetails.productWarehouse.type !== "vinretail") ||
      (productDetails.productWarehouse.type === "vinretail" &&
        warehouse.type !== "vinretail")
    ) {
      this.setState({
        addToCartoonErr: "Product and warehouse type does not match.",
      });
      return;
    }
    if (productDetails && productDetails.variant) {
      let isPriceError = false;
      if (!productDetails.variant.resellerCostPrice) {
        isPriceError = true;
      }
      if (
        productDetails.variant.resellerCostPrice &&
        parseInt(productDetails.variant.resellerCostPrice) <= 0
      ) {
        isPriceError = true;
      }
      if (isPriceError) {
        this.setState({
          addToCartoonErr: "Product price should be greater than zero.",
        });
        return;
      }
    }
    const productData = {
      productId,
      variantNo,
      variant: productDetails.variant ? productDetails.variant : null,
      sizeNo,
      size: productDetails.size ? productDetails.size : null,
      warehouseId,
      quantity: parseInt(finalQty),
      name: productDetails.details.name,
      warehouse: warehouse,
      noOfUnit: productDetails.no_of_unit,
    };

    const warehouseLoc = warehouse.vinculum_loc_code;
    const wareshouseCartoonsQty = warehouseLoc
      ? cartoonList
        .filter((cartoon) => {
          const cartoonProd = cartoon.value.find(
            (cp) => cp.productId === productId
          );
          if (!cartoonProd) return false;
          return cartoonProd.warehouse.vinculum_loc_code === warehouseLoc;
        })
        .reduce((total, cartoon) => {
          const cartoonProd = cartoon.value.find(
            (cp) => cp.productId === productId
          );
          return (total += cartoonProd.quantity);
        }, 0) + parseInt(finalQty)
      : parseInt(finalQty);
    const inventory =
      inventoryObj && inventoryObj.length > 0
        ? inventoryObj.find((i) => i.id === productId)
        : null;

    if (
      (warehouseLoc === "OCK" &&
        wareshouseCartoonsQty >
        inventory.vinculumStockInfo.totalQuantity_OCK) ||
      (warehouseLoc === "DLA" &&
        wareshouseCartoonsQty > inventory.vinculumStockInfo.totalQuantity_DLA) ||
      (warehouseLoc === "DLA" &&
        wareshouseCartoonsQty > inventory.vinculumStockInfo.totalQuantity_SHR) ||
      (warehouseLoc === "DLA" &&
        wareshouseCartoonsQty > inventory.vinculumStockInfo.totalQuantity_FSB) ||
      (warehouseLoc === "DLA" &&
        wareshouseCartoonsQty > inventory.vinculumStockInfo.totalQuantity_BRL) ||
      (warehouseLoc === "DLA" &&
        wareshouseCartoonsQty > inventory.vinculumStockInfo.totalQuantity_KAN) ||
      (warehouseLoc === "DLA" &&
        wareshouseCartoonsQty > inventory.vinculumStockInfo.totalQuantity_GRK) ||
      (warehouseLoc === "DLA" &&
        wareshouseCartoonsQty > inventory.vinculumStockInfo.totalQuantity_VRN)
    ) {
      this.setState({
        addToCartoonErr: "Quantity is more than available warehouse quantity.",
      });
      return;
    }

    if (cartoonList && cartoonList.length === 0) {
      this.setState({
        addToCartoonErr: "Please create new cartoon ,before adding to it!",
      });
      return;
    }
    const firstCartoon = cartoonList[cartoonList.length - 1];
    if (firstCartoon.value.length > 0) {
      const firstProduct = firstCartoon.value[0];
      if (
        (firstProduct.warehouse.type !== "vinretail" &&
          warehouse.type === "vinretail") ||
        (firstProduct.warehouse.type === "vinretail" &&
          warehouse.type !== "vinretail")
      ) {
        this.setState({
          addToCartoonErr:
            "Warehouse type not matching for the product added in the cartoon",
        });
        return;
      }
      if (firstProduct.warehouse._id !== warehouseId) {
        this.setState({
          addToCartoonErr: "Please select same warehouse for the box",
        });
        return;
      }
    }
    //Get the added quantity from all cartoons
    const quantity = cartoonList.reduce((acc, item) => {
      const [productDetails] = item.value.filter(
        (product) =>
          product.productId === productId &&
          product.variantNo === variantNo &&
          product.sizeNo === sizeNo
      );
      return (acc += productDetails ? parseInt(productDetails.quantity) : 0);
    }, 0);
    //validate against the order qty
    if (
      quantity + parseInt(finalQty) <=
      productDetails.quantity * productDetails.no_of_unit
    ) {
      let latestCartoon = cartoonList[cartoonList.length - 1];
      const [productDetails] = latestCartoon.value.filter(
        (product) =>
          product.productId === productId &&
          product.variantNo === variantNo &&
          product.sizeNo === sizeNo
      );
      if (productDetails) {
        latestCartoon.value = cartoonList[cartoonList.length - 1].value.map(
          (product) => {
            if (
              product.productId === productId &&
              product.variantNo === variantNo &&
              product.sizeNo === sizeNo
            ) {
              product.quantity += parseInt(finalQty);
            }
            return product;
          }
        );
      } else {
        latestCartoon.value.push(productData);
      }
      cartoonList[cartoonList.length - 1] = latestCartoon;
      this.setState({ cartoonList, finalQty: "", warehouseId: "" });
    } else {
      const value = [productId, variantNo, sizeNo].join("_");
      const filteredProductsForSelect = productListForSelect.filter(
        (product) => product.value !== value
      );
      if (quantity === productDetails.quantity) {
        this.setState({ productListForSelect: filteredProductsForSelect });
      }
      this.setState({
        addToCartoonErr: `All quantities of this product added to cartoon or 
            order quantity is less than entered quantity`,
      });
    }
  };

  getAvailableQty = () => {
    const { productVariantSize, productList, cartoonList } = this.state;
    const productDetails = productList.find((product) => {
      const uniqueProduct = `${product.id}_${product.variant_no ? product.variant_no : "NA"
        }_${product.size_no ? product.size_no : "NA"}`;
      return uniqueProduct === productVariantSize;
    });
    const [productId, variantNo, sizeNo] = productVariantSize.split("_");
    const quantity = (cartoonList || []).reduce((acc, item) => {
      const productDetails = item.value.find(
        (product) =>
          product.productId === productId &&
          product.variantNo === variantNo &&
          product.sizeNo === sizeNo
      );
      return (acc += productDetails ? parseInt(productDetails.quantity) : 0);
    }, 0);
    if (productDetails)
      return productDetails.quantity * productDetails.no_of_unit - quantity;
    return 0;
  };

  editProductFromCartoon = (product, cartoonName) => {
    const { productId, variantNo, sizeNo, name, variant } = product;

    const { cartoonList, productListForSelect } = this.state;
    const value = [productId, variantNo, sizeNo].join("_");
    let updatedCartoonList = cartoonList.map((cartoon) => {
      if (cartoonName === cartoon.key) {
        cartoon.value = cartoon.value.filter((product) => {
          const { productId, variantNo, sizeNo } = product;
          const cartoonUniqueProduct = [productId, variantNo, sizeNo].join("_");
          return cartoonUniqueProduct !== value;
        });
      }
      return cartoon;
    });
    const [filteredProductsForSelect] = productListForSelect.filter(
      (product) => product.value === value
    );
    if (!filteredProductsForSelect) {
      const productForSelect = { value, label: `${name}-${variant.name}` };
      productListForSelect.push(productForSelect);
    }
    updatedCartoonList = updatedCartoonList
      .filter((cartoon) => cartoon.value && cartoon.value.length > 0)
      .map((cartoon, index) => ({ ...cartoon, key: `box${index + 1}` }));
    this.setState({
      cartoonList: updatedCartoonList,
      productListForSelect,
      finalQty: product.quantity,
      productVariantSize: `${product.productId}_${product.variantNo}_${product.sizeNo}`,
      warehouseId: product.warehouseId,
    });
  };

  removeProductFromCartoon = (product, cartoonName) => {
    const { productId, variantNo, sizeNo, name, variant } = product;
    const { cartoonList, productListForSelect } = this.state;
    const value = [productId, variantNo, sizeNo].join("_");
    let updatedCartoonList = cartoonList.map((cartoon) => {
      if (cartoonName === cartoon.key) {
        cartoon.value = cartoon.value.filter((product) => {
          const { productId, variantNo, sizeNo } = product;
          const cartoonUniqueProduct = [productId, variantNo, sizeNo].join("_");
          return cartoonUniqueProduct !== value;
        });
      }
      return cartoon;
    });
    const [filteredProductsForSelect] = productListForSelect.filter(
      (product) => product.value === value
    );
    if (!filteredProductsForSelect) {
      const productForSelect = { value, label: `${name}-${variant.name}` };
      productListForSelect.push(productForSelect);
    }
    updatedCartoonList = updatedCartoonList
      .filter((cartoon) => cartoon.value && cartoon.value.length > 0)
      .map((cartoon, index) => ({ ...cartoon, key: `box${index + 1}` }));
    this.setState({ cartoonList: updatedCartoonList, productListForSelect });
  };

  getRemarks(id) {
    this.props
      .getOrderRemarks(id)
      .then((response) => {
        let data = response.data;
        this.setState({ orderRemarks: data });
      })
      .catch((error) => {
        console.log("error", error);
      });
    this.setState({ orderRemarksModal: false });
  }

  showRemarkModal(id) {
    this.props
      .getOrderRemarks(id)
      .then((response) => {
        let data = response.data;
        this.setState({ orderRemarks: data });
      })
      .catch((error) => {
        console.log("error", error);
      });
    this.setState({ orderRemarksModal: true });
  }

  handleRemarkSubmit(id) {
    let remark = this.state.remark;
    if (remark.person_name) {
      this.props
        .addOrderRemarks(id, remark)
        .then((response) => {
          let data = response.data;
          this.setState({ orderRemarks: data });
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  onInvoiceClick = (orderId) => {
    this.props
      .generateInvoiceByOrderId(orderId)
      .then(() => {
        Toast({ type: "success", message: "Invoice generated successfully." });
      })
      .catch((error) => {
        Toast({
          type: "error",
          message: typeof error.err === "string" ? error.err : error.message,
        });
      });
  };

  onInvoiceShow = () => {
    const { invoicePassword } = this.state;
    if (invoicePassword === "Sair@123") {
      this.setState({
        invoicePassword: "",
        isInvoicesVisible: true,
      });
    } else {
      alert("Invalid Password");
    }
  };

  render() {
    let { ordersList, isLoading, userId } = this.props;

    let { list = [], pageMeta = {} } = ordersList;

    let IsEmpty = list.length > 0;
    let {
      disabled,
      headerDetails,
      isOpen,
      loading,
      orderData,
      payment_link,
      productList,
      paymentStatusUpdateMsg,
      sucMessage,
      isCancelModalOpen,
      reason,
      remarks,
      errMessage,
      cancelSucMessage,
      warehouseNames,
      showTrackingHistoryModal,
      orderTracking,
      etd,
      trackingOrderStatus,
      cartoonList,
      paymentAmount,
      productListForSelect,
      productVariantSize,
      finalQty,
      warehouseId,
      addToCartoonErr,
      finalTotalAmount,
      orderRemarksModal,
      orderRemarks,
      remark,
      inventoryObj,
      invoicePassword,
      isInvoicesVisible,
      tractArray,
      fetchingStockInfo,
      waitingForApproval,
      pushOrderErr,
      searchBySellMoreType,
      autoPush
    } = this.state;
    let {
      PaymentDetails = [],
      Customer = {},
      payment_country,
      is_cod,
      is_credit_payment,
      credit_payment_charges,
      details,
      delivery_price,
      cod_charges,
      adv_cod_amt_disc,
      adv_cod_amt,
      prepaid_disc,
      use_wallet,
      wallet_balance_after,
      wallet_balance_before,
      order_status,
      total_price,
      spl_disc_amt = 0,
      activation_disc_amt,
      coupon_info,
    } = orderData ? orderData : {};
    cod_charges = parseFloat(cod_charges);
    let warehouseSelect = map(warehouseNames, (warehouse) => {
      return { value: warehouse._id, label: warehouse.name };
    });

    let warehouseSelectFilter = map(warehouseNames, (warehouse) => {
      return { value: warehouse._id, label: warehouse.name, city: warehouse.city };
    });

    let discountAmount = is_cod ? adv_cod_amt_disc : prepaid_disc;
    if (order_status === "Confirmed") {
      discountAmount = discountAmount + (coupon_info ? coupon_info.couponDiscount : 0.0);
    }
    const prepaidAmount = PaymentDetails ? PaymentDetails.payment_amount : 0;

    const finaltotalPrice =
      order_status === "Confirmed"
        ? parseFloat(cod_charges) +
        parseFloat(delivery_price) +
        sumBy(productList, (item) => {
          return item.unitWiseFinalQuantity
            ? item.unitWiseFinalQuantity * item.final_price
            : item.finalQuantity * item.no_of_unit * item.final_price;
        }) -
        parseFloat(discountAmount) -
        parseFloat(activation_disc_amt) -
        parseFloat(spl_disc_amt)
        : parseFloat(total_price) - parseFloat(discountAmount);

    const finalPendingAmount = is_cod
      ? finaltotalPrice -
      (parseFloat(prepaidAmount) +
        (use_wallet
          ? parseFloat(wallet_balance_before) -
          parseFloat(wallet_balance_after)
          : 0)).toFixed(2)
      : 0;

    const isConfimredOrder = order_status === "Confirmed" ? true : false;
    const isOrderStatusDisabled = ["Pushed", "Confirmed", "Cancelled"].includes(
      order_status
    );
    const isDocStatusVerified = waitingForApproval === "V";
    const isConfirmed = order_status === "Confirmed";

    return (
      <>
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span class="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="feather feather-align-left"
                >
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>
            New GTM Orders
          </h4>
        </div>
        <section className="sair-sec-wrapper">
          <div className="row">
            <div className="col-sm">
              <div className="row">
                <div className="col-md-3 form-group d-flex align-items-center">
                  <h5 className="sair-sec-title align-self-center">
                    Show Invoices
                  </h5>
                </div>
                <div className="col-md-3 form-group">
                  <label>Enter Password:</label>
                  <NormalInput
                    type="password"
                    name="invoicePassword"
                    value={invoicePassword}
                    className="form-control"
                    onChange={({ name, value }) => {
                      this.setState({ [name]: value });
                    }}
                  />
                </div>
                <div className="col-md-3 form-group d-flex align-items-center">
                  <button
                    onClick={this.onInvoiceShow}
                    className="btn btn-primary btn-sm"
                  >
                    Show Invoices
                  </button>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-md-3 form-group">
                  <label>
                    <b>Search by Reseller Email</b>
                  </label>
                  <Filters
                    className="p-0"
                    queryHandler={(query) =>
                      this.handleSearchFilter(query, "email")
                    }
                    isDropDownFilter={false}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <label>
                    <b>Search by Reseller Phone</b>
                  </label>
                  <Filters
                    className="p-0"
                    queryHandler={(query) =>
                      this.handleSearchFilter(query, "phone")
                    }
                    isDropDownFilter={false}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <label>
                    <b>Search by Order ID</b>
                  </label>
                  <Filters
                    className="p-0"
                    queryHandler={(query) =>
                      this.handleSearchFilter(query, "order_id")
                    }
                    isDropDownFilter={false}
                  />
                </div>
                <div className="col-md-3">
                  <label>Search by Warehouse</label>
                  {warehouseSelectFilter && (

                    <Select
                      value={'selectedWareHouseName'}
                      onChange={entry => { this.handleSearchFilter(entry, 'warehouse') }}
                      options={warehouseSelectFilter}
                    />
                  )}
                </div>
                <div className="col-md-2 form-group">
                  <Filters
                    queryHandler={(query) =>
                      this.handleSearchFilter(query, "status")
                    }
                    isDropDownFilter={true}
                    value={"status"}
                  />
                </div>
                <div className="col-md-1 form-group">

                  <label>
                    <b>Autopush</b>
                  </label>
                  &nbsp;
                  <input
                    name="type"
                    type="checkbox"
                    checked={autoPush}
                    onChange={(e) => {
                      this.handleSearchFilter(
                        { search: { value: e.target.checked } },
                        "autopush"
                      );
                    }}
                  />
                </div>
                {/* <div className="ml-auto d-flex align-items-center">
                  <button className="btn btn-primary btn-sm"
                    onClick={() => this.paymentStatusUpdate()} disabled={loading ? true : false}
                  >Update Payment Status</button>
                  {paymentStatusUpdateMsg && <p className="text-danger">{paymentStatusUpdateMsg}</p>}
                  {sucMessage && <p className="text-success">{sucMessage}</p>}
                </div> */}
              </div>

              <div className="table-wrap mt-3">
                <TableWrapper
                  headerDetails={headerDetails}
                  isEmpty={IsEmpty}
                  pageMeta={pageMeta}
                  queryHandler={(query) => this.handleFilter(query)}
                  isLoading={isLoading}
                >
                  {list.map((item, index) => {
                    let {
                      id,
                      order_status,
                      total_price,
                      is_cod,
                      is_credit_payment,
                      commission_paid,
                      reseller_email,
                      reseller_first_name,
                      reseller_last_name,
                      created_at,
                      PaymentDetails,
                      customerdetails,
                    } = item;
                    let url = download_invoice_url + id;

                    return (
                      <tr key={index}>
                        {/* <td>
                          {order_status !== "Cancelled" ? (
                            commission_paid ? (
                              "Commission Paid"
                            ) : (
                              <input
                                type="checkbox"
                                onChange={() => this.handlePaymentCheckBox(id)}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </td> */}
                        <td>
                          {item.booking_id}
                          {item.is_wholesale && (
                            <Badge type={1} label="Wholesale" />
                          )}
                          {item.warehouse_id != null && (
                            <Badge type={3} label="Gtm" />
                          )}
                          {item.autopush_live != null && item.autopush_live && (
                            <Badge type={3} label="AutoPush" />
                          )}
                          {item.is_gtm_ecom && (
                            <Badge type={3} label="Gtm+Ecom" />
                          )}
                        </td>
                        <td>
                          {reseller_first_name + " " + reseller_last_name}
                          <br />
                          {reseller_email}
                          <br />
                          {item.contact_no}
                        </td>
                        {customerdetails && <td>
                          {customerdetails.name} <br /> {customerdetails.email}{" "}
                          <br /> {customerdetails.contact_no}
                        </td>}
                        <td>{total_price}</td>
                        <td>{this.getJsDate(created_at)}</td>
                        <td>
                          <span style={{ fontSize: 15 }}>Order :</span>{" "}
                          <span style={{ fontSize: 16, fontWeight: "bold" }}>
                            {order_status}
                          </span>
                          <br />
                          {/* following info is not comming in api rightr now */}
                          {/* <span style={{ fontSize: 15 }}>Delivery :</span> <span style={{ fontSize: 16, fontWeight: 'bold' }}>{delivery_status}</span>
                          <br />
                          <span style={{ fontSize: 15 }}>Commission :</span><span style={{ fontSize: 16, fontWeight: 'bold' }}>{commission_status}</span> */}
                        </td>
                        <td>{PaymentDetails !== null ? "Yes" : "No"}</td>
                        <td>{this.requestStatus(is_cod, is_credit_payment)}</td>
                        <td>
                          <button
                            onClick={() => this.handleSubmit(item)}
                            className="btn btn-primary btn-sm"
                          >
                            View Details
                          </button>
                        </td>
                        {order_status !== "Cancelled" && !item.is_wholesale && (
                          <td>
                            <button
                              onClick={() => this.showCancelModal(item)}
                              className="btn btn-primary btn-sm"
                            >
                              Cancel
                            </button>
                          </td>
                        )}
                        <td>
                          {!item.is_wholesale && (
                            <a
                              href={url}
                              target="_blank"
                              className="btn btn-primary btn-sm"
                            >
                              Download Pdf
                            </a>
                          )}
                        </td>
                        <td>
                          {order_status === "Confirmed" &&
                            isInvoicesVisible &&
                            !item.is_wholesale && (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.onInvoiceClick(item.id)}
                              >
                                Invoice
                              </button>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </TableWrapper>
              </div>
            </div>
          </div>
          <div className="order-details">
            <Modal
              isOpen={isOpen}
              size={"18"}
              className="modal-dialog-centered order-detail-model"
            >
              <ModalHeader
                //className={headerClass}
                toggle={() => this.toggle()}
              >
                Order Details
              </ModalHeader>
              {this.state.loadingSingleOrderDetail ? (
                <h3>Loading....</h3>
              ) : (
                <div className="position-relative">
                  <ModalBody className="order-detail-model-body">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="text-color-gray">
                          Order id : #{orderData && orderData.booking_id}
                        </h6>
                        <h6 className="text-color-gray">
                          Order status : {orderData && orderData.order_status}
                        </h6>
                        <h6 className="text-color-gray">
                          commission status :{" "}
                          {orderData && orderData.commission_status}
                        </h6>
                        <h6 className="text-color-gray">
                          Delivery status :{" "}
                          {orderData && orderData.delivery_status}
                        </h6>
                        <h6 className="text-color-gray">
                          City :{" "}
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .city_town}
                        </h6>
                        <h6 className="text-color-gray">
                          State :{" "}
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .state}
                        </h6>
                        <br />
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.showRemarkModal(orderData.id)}
                        >
                          Remarks
                        </button>
                        <div className="form-group">
                          <label>Document Status :</label>
                          {(orderData && typeof orderData.customer_flags === "undefined") || (orderData && orderData.customer_flags && orderData.customer_flags.skip_user_kyc_2 === true) ?
                            <div>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="doc-status"
                                    value="N"
                                    checked={waitingForApproval === "N"}
                                    onChange={(e) => {
                                      this.setState({
                                        waitingForApproval: e.target.value,
                                      });
                                      this.props.updateOrderDocStatus(
                                        orderData.id,
                                        {
                                          doc_status: e.target.value,
                                        }
                                      );
                                    }}
                                    disabled={
                                      isOrderStatusDisabled || isDocStatusVerified
                                    }
                                  />{" "}
                                  New
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="doc-status"
                                    value="W"
                                    checked={waitingForApproval === "W"}
                                    onChange={(e) => {
                                      this.setState({
                                        waitingForApproval: e.target.value,
                                      });
                                      this.props.updateOrderDocStatus(
                                        orderData.id,
                                        {
                                          doc_status: e.target.value,
                                        }
                                      );
                                    }}
                                    disabled={
                                      isOrderStatusDisabled || isDocStatusVerified
                                    }
                                  />{" "}
                                  Waiting
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="doc-status"
                                    value="V"
                                    checked={waitingForApproval === "V"}
                                    onChange={(e) => {
                                      this.setState({
                                        waitingForApproval: e.target.value,
                                      });
                                      this.props.updateOrderDocStatus(
                                        orderData.id,
                                        {
                                          doc_status: e.target.value,
                                        }
                                      );
                                    }}
                                    disabled={
                                      isOrderStatusDisabled || isDocStatusVerified
                                    }
                                  />{" "}
                                  Verified
                                </Label>
                              </FormGroup>
                            </div> :
                            <p>{orderData ? orderData.customer_flags.kyc_verified : 'NA'}</p>
                          }
                        </div>
                      </div>

                      <div className="container col-md-6">
                        {orderRemarks && orderRemarks.length > 0 && (
                          <div>
                            <table>
                              <tr>
                                <th colSpan="4">
                                  <span className="remark-header">
                                    Timestamp
                                  </span>
                                </th>
                                <th colSpan="4">
                                  <span className="remark-header">
                                    Person Name
                                  </span>
                                </th>
                                <th colSpan="4">
                                  <span className="remark-header">Message</span>
                                </th>
                              </tr>
                              {orderRemarks.map((item) => {
                                return (
                                  <tr>
                                    <td colSpan="4">
                                      <span className="remark-body">
                                        {moment(item.created_at).format(
                                          "YYYY-MM-DD HH:mm"
                                        )}
                                        :
                                      </span>
                                    </td>
                                    <td colSpan="4">
                                      <span className="remark-body">
                                        {item.person_name} :
                                      </span>
                                    </td>
                                    <td colSpan="4">
                                      <span className="remark-body">
                                        {item.message}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                    {orderData && orderData.document_type && (
                      <>
                        <h6 className="mt-1">Document Details</h6>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>
                            {orderData && orderData.reseller_first_name}{" "}
                            {orderData && orderData.reseller_last_name}
                            <br />
                            {orderData && orderData.contact_no}
                            <br />
                            {orderData && orderData.document_type}
                            <br />({orderData && orderData.document_number})
                          </div>
                          <div>
                            <a
                              href={
                                orderData &&
                                orderData.document_url &&
                                orderData.document_url.replace("$1", "?")
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                style={{ width: 100, height: 100 }}
                                src={
                                  orderData &&
                                  orderData.document_url &&
                                  orderData.document_url.replace("$1", "?")
                                }
                                alt="Document"
                              />
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row mb-10">
                      <div className="col-md-3">
                        {orderData &&
                          orderData.purchaseOrders &&
                          orderData.purchaseOrders.length === 0 &&
                          !orderData.is_wholesale &&
                          orderData.order_status !== "Cancelled" && (
                            <button
                              className="btn btn-primary"
                              onClick={() => this.handleCartoonAdd()}
                              disabled={
                                !isDocStatusVerified || isOrderStatusDisabled || orderData.autopush_live === true
                              }
                            >
                              Add New Cartoon
                            </button>
                          )}
                      </div>
                      <div className="col-md-3">
                        <button
                          className="btn btn-primary ml-10 d-flex align-items-center"
                          onClick={() => this.getOrderInventory(orderData.id)}
                          disabled={fetchingStockInfo}
                        >
                          {fetchingStockInfo && (
                            <div className="loader-circle mr-10"></div>
                          )}
                          Refresh Stock
                        </button>
                      </div>
                    </div>
                    {orderData &&
                      orderData.purchaseOrders &&
                      orderData.purchaseOrders.length === 0 &&
                      !orderData.is_wholesale &&
                      orderData.order_status !== "Cancelled" && (
                        <div>
                          <h6>Choose Products and Qty for shipment</h6>
                          <div>
                            <div className="container row">
                              <div className="col-md-4">
                                <label>Choose Product</label>
                                {productListForSelect && (
                                  <ReactSelect
                                    isMulti={false}
                                    options={productListForSelect}
                                    handleChange={(keyName, data) => {
                                      this.handleInputSelect({
                                        name: "product",
                                        value: data,
                                      });
                                    }}
                                    value={productVariantSize}
                                    disabled={false}
                                  />
                                )}
                              </div>
                              <div className="col-md-3">
                                <label>Quantity</label>
                                <input
                                  value={finalQty}
                                  onChange={(e) =>
                                    this.handleInputSelect({
                                      name: "finalQty",
                                      value: e.target.value,
                                    })
                                  }
                                  className="form-control"
                                  type="text"
                                />
                                {productVariantSize ? (
                                  <small>{`Available quantity: ${this.getAvailableQty()}`}</small>
                                ) : null}
                              </div>
                              <div className="col-md-3">
                                <label>Choose Warehouse</label>
                                {warehouseSelect && (
                                  <ReactSelect
                                    isMulti={false}
                                    options={warehouseSelect}
                                    handleChange={(keyName, data) => {
                                      this.handleInputSelect({
                                        name: keyName,
                                        value: data,
                                      });
                                    }}
                                    keyName="warehouseId"
                                    value={warehouseId}
                                    disabled={false}
                                  />
                                )}
                              </div>

                              <div className="col-md-2 mt-5">
                                <br />
                                <button
                                  className="btn btn-primary"
                                  onClick={() => this.addToCartoon()}
                                  disabled={
                                    isOrderStatusDisabled ||
                                    !isDocStatusVerified || orderData.autopush_live === true
                                  }
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            {addToCartoonErr && (
                              <p className="text-danger">{addToCartoonErr}</p>
                            )}
                          </div>

                          <div className="cartoon-handle-container">
                            {cartoonList && cartoonList.length > 0 && (
                              <div>
                                <h6>Cartoon and Product Details</h6>
                                <div>
                                  {cartoonList.map((cartoon, index) => {
                                    return (
                                      <div>
                                        <h3>{cartoon.key}</h3>
                                        <div className="cartoon-box">
                                          {cartoon &&
                                            cartoon.value &&
                                            cartoon.value.length > 0 &&
                                            cartoon.value.map((cartoonItem) => {
                                              const {
                                                name,
                                                variant,
                                                size,
                                                quantity,
                                                warehouse,
                                              } = cartoonItem;
                                              const {
                                                _id,
                                                name: wareHouseName,
                                              } = warehouse;
                                              return (
                                                <div
                                                  key={index}
                                                  className="row"
                                                >
                                                  <div className="col-md-4">
                                                    <p>{name}</p>
                                                    <p>
                                                      {variant
                                                        ? variant.name
                                                        : ""}
                                                    </p>
                                                    <p>
                                                      {size ? size.name : ""}
                                                    </p>
                                                  </div>
                                                  <div className="col-md-3">
                                                    Quantity:{quantity}
                                                  </div>
                                                  <div className="col-md-3">
                                                    {warehouse && (
                                                      <span>
                                                        {_id} || {wareHouseName}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="col-md-2">
                                                    {!isOrderStatusDisabled &&
                                                      isDocStatusVerified && (
                                                        <>
                                                          <i
                                                            className="fa fa-edit"
                                                            onClick={() =>
                                                              this.editProductFromCartoon(
                                                                cartoonItem,
                                                                cartoon.key
                                                              )
                                                            }
                                                          />
                                                          &nbsp;&nbsp;
                                                          <i
                                                            className="fa fa-trash"
                                                            onClick={() =>
                                                              this.removeProductFromCartoon(
                                                                cartoonItem,
                                                                cartoon.key
                                                              )
                                                            }
                                                          />
                                                        </>
                                                      )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    <br />
                    <br />

                    <h6>Product Details</h6>

                    {productList &&
                      productList.length > 0 &&
                      productList.map((product, index) => {
                        let image_url = "";
                        if (Array.isArray(product.details.imgLinks)) {
                          if (product.details.imgLinks.length > 0) {
                            if (product.details.imgLinks[0].link) {
                              image_url = product.details.imgLinks[0].link;
                            } else {
                              image_url = product.details.imgLinks[0];
                            }
                          }
                        } else {
                          if (product.variant && product.variant.imageIndex) {
                            const index = parseInt(product.variant.imageIndex);
                            if (product.details.imgLinks[index]) {
                              image_url = product.details.imgLinks[index].link;
                            }
                          }
                        }
                        const { productWarehouse } = product;
                        let inventory =
                          inventoryObj.length > 0 ? inventoryObj[index] : {};

                        const finalQuantity = !isConfimredOrder
                          ? product.quantity * product.no_of_unit
                          : product.unitWiseFinalQuantity
                            ? product.unitWiseFinalQuantity
                            : product.finalQuantity * product.no_of_unit;

                        const productWarehouses = [];
                        cartoonList.map((cartoon) => {
                          cartoon.value.map((cartoonItem) => {
                            if (cartoonItem.productId === product.id) {
                              const isExist = productWarehouses.find(
                                (productWarehouse) =>
                                  productWarehouse._id ===
                                  cartoonItem.warehouse._id
                              );
                              if (!isExist)
                                productWarehouses.push(cartoonItem.warehouse);
                            }
                          });
                        });

                        return (
                          <div key={index}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                padding: 5,
                              }}
                            >
                              <div>
                                <img
                                  style={{ width: 80, height: 80 }}
                                  src={image_url}
                                  alt="product"
                                />
                              </div>
                              <div>
                                <p>{product.details.name}</p>
                                <p>
                                  {product.variant ? product.variant.name : ""}{" "}
                                  {product.size ? (
                                    <p>size:{product.size.name} </p>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span>
                                    Qty:{" "}
                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {product.quantity}(
                                      {orderData.is_wholesale
                                        ? product.no_of_unit
                                        : product.quantity *
                                        product.no_of_unit}{" "}
                                      units){" "}
                                    </span>
                                  </span>
                                  <span>
                                    FinalQty:{" "}
                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {orderData.is_wholesale
                                        ? product.quantity * product.no_of_unit
                                        : finalQuantity}{" "}
                                      units
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>
                                  Product unit Price:{" "}
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ₹{product.final_price.toFixed(4)}
                                  </span>
                                </p>
                                <p>
                                  Product MRP:{" "}
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ₹{product.details.brandPrice}
                                  </span>
                                </p>
                                <p>
                                  Final Product Price:{" "}
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ₹{finalQuantity * product.final_price.toFixed(4)}
                                  </span>
                                </p>
                              </div>
                              <div className="warehouse-select">
                                {orderData &&
                                  orderData.purchaseOrders &&
                                  orderData.purchaseOrders.length !== 0 &&
                                  orderData.order_status !== "Cancelled" && (
                                    <div className="container">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          this.viewTrackingModel(
                                            orderData.purchaseOrders,
                                            product,
                                            orderData.order_status
                                          )
                                        }
                                        disabled={disabled}
                                      >
                                        View Tracking History
                                      </button>
                                    </div>
                                  )}
                              </div>
                            </div>
                            {!orderData.is_wholesale && (
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th align="center" colSpan={2}>
                                      Vin Available
                                    </th>
                                    <th align="center" colSpan={2}>
                                      Vin Committed
                                    </th>
                                    {/* todo not needed now */}
                                    {/* <th align="center" colSpan={3}>
                                      Ak Available
                                    </th> */}
                                    {/* <th align="center" rowSpan={2}>
                                      Ak Vin committed
                                    </th>
                                    <th align="center" rowSpan={2}>
                                      AK Placed
                                    </th> */}
                                  </tr>
                                  <tr>
                                    <th align="center">Delhi</th>
                                    <th align="center">Kolkata</th>


                                    <th align="center">Delhi</th>
                                    <th align="center">Kolkata</th>

                                    {/* todo not needed now */}
                                    {/* <th align="center">AK</th>
                                    <th align="center">Delhi</th>
                                    <th align="center">Kolkata</th> */}

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_DLA}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_OCK}
                                    </td>


                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_DLA}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_OCK}
                                    </td>

                                    {/* todo not needed now */}
                                    {/* <td
                                      align="center"
                                      className={
                                        inventory &&
                                          inventory.apnaKlubStockInfo &&
                                          inventory.apnaKlubStockInfo
                                            .localQuantity <= 0
                                          ? "red-bold-rule"
                                          : ""
                                      }
                                    >
                                      {inventory &&
                                        inventory.apnaKlubStockInfo &&
                                        inventory.apnaKlubStockInfo
                                          .localQuantity}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.apnaKlubStockInfo
                                          .localQuantityDLA}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.apnaKlubStockInfo &&
                                        inventory.apnaKlubStockInfo
                                          .localQuantityOCK}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.apnaKlubStockInfo &&
                                        inventory.apnaKlubStockInfo
                                          .commitedQuantity}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.apnaKlubStockInfo &&
                                        inventory.apnaKlubStockInfo.localPlaced}
                                    </td> */}
                                  </tr>
                                </tbody>
                              </table>
                            )}
                            {!orderData.is_wholesale && (
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th align="center" colSpan={3}>
                                      Vin Available
                                    </th>
                                    <th align="center" colSpan={3}>
                                      Vin Committed
                                    </th>
                                    {/* todo not needed now */}
                                    {/* <th align="center" rowSpan={2}>
                                      Ak Vin committed
                                    </th>
                                    <th align="center" rowSpan={2}>
                                      AK Placed
                                    </th> */}
                                  </tr>
                                  <tr>
                                    <th align="center">GTM_Sharanpur</th>
                                    <th align="center">GTM_Faizabad</th>
                                    <th align="center">GTM_Bareilly</th>
                                    <th align="center">GTM_Sharanpur</th>
                                    <th align="center">GTM_Faizabad</th>
                                    <th align="center">GTM_Bareilly</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>

                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_SHR}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_FSB}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_BRL}
                                    </td>

                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_SHR}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_FSB}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_BRL}
                                    </td>

                                    {/* todo not needed now */}
                                    {/* <td align="center">
                                      {inventory &&
                                        inventory.apnaKlubStockInfo &&
                                        inventory.apnaKlubStockInfo
                                          .commitedQuantity}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.apnaKlubStockInfo &&
                                        inventory.apnaKlubStockInfo.localPlaced}
                                    </td> */}
                                  </tr>
                                </tbody>
                              </table>
                            )}


                            {!orderData.is_wholesale && (
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th align="center" colSpan={3}>
                                      Vin Available
                                    </th>
                                    <th align="center" colSpan={3}>
                                      Vin Committed
                                    </th>
                                    {/* todo not needed now */}
                                    {/* <th align="center" rowSpan={2}>
                                      Ak Vin committed
                                    </th>
                                    <th align="center" rowSpan={2}>
                                      AK Placed
                                    </th> */}
                                  </tr>
                                  <tr>
                                    <th align="center">GTM_Kanpur</th>
                                    <th align="center">GTM_Gorakhpur</th>
                                    <th align="center">Varanasi-GTM-Wholesale</th>
                                    <th align="center">GTM_Kanpur</th>
                                    <th align="center">GTM_Gorakhpur</th>
                                    <th align="center">Varanasi-GTM-Wholesale</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>

                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_KAN}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_GRK}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .totalQuantity_VRN}
                                    </td>


                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_KAN}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_GRK}
                                    </td>
                                    <td align="center">
                                      {inventory &&
                                        inventory.vinculumStockInfo &&
                                        inventory.vinculumStockInfo
                                          .committedQty_VRN}
                                    </td>

                                  </tr>
                                </tbody>
                              </table>
                            )}
                            {isConfirmed && (
                              <div>
                                <p>Shipping from:</p>
                              </div>
                            )}
                            {isConfirmed &&
                              productWarehouses.map(
                                (productWarehouse, index) => (
                                  <div key={`PRODUCT_WAREHOUSE_${index}`}>
                                    <span>
                                      {productWarehouse._id} ||{" "}
                                      {productWarehouse.shiprocket_id} ||{" "}
                                      {productWarehouse.name} ||{" "}
                                      {productWarehouse.contact} ||{" "}
                                      {productWarehouse.city} ||{" "}
                                      {productWarehouse.address_line_1} ||{" "}
                                      {productWarehouse.pincode}{" "}
                                    </span>
                                  </div>
                                )
                              )}
                            {!isConfirmed && (
                              <div>
                                {productWarehouse && (
                                  <span>
                                    {productWarehouse._id} ||{" "}
                                    {productWarehouse.shiprocket_id} ||{" "}
                                    {productWarehouse.name} ||{" "}
                                    {productWarehouse.contact} ||{" "}
                                    {productWarehouse.city} ||{" "}
                                    {productWarehouse.address_line_1} ||{" "}
                                    {productWarehouse.pincode}{" "}
                                  </span>
                                )}
                              </div>
                            )}
                            <br />
                          </div>
                        );
                      })}

                    <div className="payment-view-right-detail">
                      <h6>Payment Details</h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Total Delivery Cost</div>
                        <div>₹ {Number(delivery_price).toFixed(2)}</div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Total Cod Charges</div>
                        <div>₹ {Number(cod_charges).toFixed(2)}</div>
                      </div>
                      {spl_disc_amt && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>Special discount</div>
                          <div>₹ {Number(spl_disc_amt).toFixed(2)}</div>
                        </div>
                      )}
                      {coupon_info && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>
                            Coupon Discount
                            <div>
                              ({coupon_info.couponList && coupon_info.couponList.map(txt => <span style={{ fontSize: 16, fontWeight: "bold" }}>
                                {txt} ,
                              </span>)})
                            </div>
                          </div>
                          <div>₹ {Number(coupon_info.couponDiscount).toFixed(2)}</div>
                        </div>
                      )}
                      {activation_disc_amt && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>Activation discount</div>
                          <div>₹ {Number(activation_disc_amt).toFixed(2)}</div>
                        </div>
                      )}
                      {prepaid_disc && !is_cod && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>Prepaid Amount discount</div>
                          <div>₹ {Number(prepaid_disc).toFixed(2)}</div>
                        </div>
                      )}
                      {adv_cod_amt_disc && is_cod && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>Advance COD Amount discount</div>
                          <div>₹ {Number(adv_cod_amt_disc).toFixed(2)}</div>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Amount from Wallet</div>
                        <div>
                          ₹{" "}
                          {Number(
                            wallet_balance_before - wallet_balance_after
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Prapaid Amount</div>
                        <div>₹ {Number(prepaidAmount).toFixed(2)}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Total Amount</div>
                        <div>₹ {Number(finaltotalPrice).toFixed(2)}</div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Amount Pending</div>
                        <div>₹{Number(finalPendingAmount).toFixed(2)}</div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Payment Mode</div>
                        <div>{is_credit_payment ? "Credit Payment" : is_cod ? "Cash On Delivery" : "Online"}</div>
                      </div>
                      {credit_payment_charges != null && (<div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Credit Payment Charges</div>
                        <div>{credit_payment_charges}</div>
                      </div>)}
                      {PaymentDetails !== null && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>Payment Received</div>
                          <div>Yes</div>
                        </div>
                      )}
                      {orderData &&
                        orderData.OrderDetails[0] &&
                        orderData.OrderDetails[0].details && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: 5,
                            }}
                          >
                            <div>Reseller Commission</div>
                            <div>
                              {Number(
                                orderData.OrderDetails[0].details
                                  .resellerCommissionAmount
                              ).toFixed(2)}
                            </div>
                          </div>
                        )}
                      {!is_cod &&
                        orderData &&
                        orderData.OrderDetails[0] &&
                        orderData.OrderDetails[0].details &&
                        orderData.OrderDetails[0].details.refund_amount ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 5,
                          }}
                        >
                          <div>Refund Amount</div>
                          <div>
                            {orderData.OrderDetails[0].details.refund_amount}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <h6 className="mt-4">Customer Info</h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Name</div>
                        <div>
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .name}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Email id</div>
                        <div>
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .email}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Contact</div>
                        <div>
                          {payment_country}{" "}
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .contact_no}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <div>Shipping Address</div>
                      </div>
                      <div>
                        <div>
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .address}{" "}
                          ,
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .addressLine1}{" "}
                          ,
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .addressLine2}
                          ,
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .pincode}
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .city_town}{" "}
                          ,
                          {orderData &&
                            orderData.OrderDetails[0] &&
                            orderData.OrderDetails[0].details &&
                            orderData.OrderDetails[0].details.customerDetails &&
                            orderData.OrderDetails[0].details.customerDetails
                              .state}
                        </div>
                      </div>
                      {details && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: 5,
                            }}
                          >
                            <div>Payment Received By</div>
                            <div>{details.receivedBy}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: 5,
                            }}
                          >
                            <div>Transaction Number</div>
                            <div>{details.transactionNumber}</div>
                          </div>
                        </>
                      )}
                      <br />

                      {PaymentDetails == null && (
                        <div className="row">
                          <>
                            <div className="col-md-8 form-group">
                              <label className="text-center">
                                Partial Payment Link
                              </label>
                              <input
                                id="payment_link"
                                onChange={() => { }}
                                type="text"
                                className="form-control"
                                value={payment_link}
                              />
                            </div>
                            <div className="col-md-4 form-group">
                              <button
                                className="btn btn-primary m-top"
                                onClick={() => this.copyToClipBoard()}
                              >
                                Copy Payment Link
                              </button>
                            </div>
                          </>
                        </div>
                      )}
                    </div>
                    {orderData && orderData.cancellation_info && (
                      <div>
                        <h6>Cancellation Info</h6>
                        <p>Reason : {orderData.cancellation_info.reason}</p>
                        <p>Remarks: {orderData.cancellation_info.remarks}</p>
                      </div>
                    )}
                    <div className="container row">
                      <div className="col-md-4 ">
                        <button
                          className="btn btn-primary m-top m-left"
                          onClick={() => this.pushOrder(orderData.id)}
                          disabled={
                            disabled ||
                            !isDocStatusVerified ||
                            isOrderStatusDisabled ||
                            orderData.autopush_live === true
                          }
                        >
                          Push Order
                        </button>
                      </div>
                    </div>

                    {pushOrderErr && (
                      <p className="text-danger">{pushOrderErr}</p>
                    )}
                  </ModalBody>

                  {loading ? (
                    <div class="upload-loader">
                      <div class="position-relative w-100 h-100">
                        <div class="loader-circle"></div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Modal>
            <Modal
              isOpen={isCancelModalOpen}
              size={"12"}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={() => this.toggleCancelModal()}>
                Cancel Order
              </ModalHeader>
              <div className="position-relative">
                <ModalBody className="order-detail-model-body">
                  <div className="row">
                    <div className="col-md-5">
                      <b>Cancel Reason</b>
                    </div>
                    <div className="col-md-7">
                      <textarea
                        className="form-control"
                        type="text"
                        value={reason}
                        onChange={(e) =>
                          this.handleChange("reason", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-5">
                      <label>Remarks</label>
                    </div>
                    <div className="col-md-7">
                      <input
                        className="form-control"
                        type="text"
                        value={remarks}
                        onChange={(e) =>
                          this.handleChange("remarks", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <button
                      className="btn btn-primary"
                      disabled={this.state.OrderCancelInProgress}
                      onClick={() => this.cancelOrder()}
                    >
                      Cancel
                    </button>
                  </div>
                  {cancelSucMessage && (
                    <p className="text-success">{cancelSucMessage}</p>
                  )}
                  {errMessage && <p className="text-danger">{errMessage}</p>}
                </ModalBody>
              </div>
            </Modal>
            <Modal
              isOpen={showTrackingHistoryModal}
              size={"12"}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={() => this.toggleTrackingHistoryModal()}>
                Tracking History
              </ModalHeader>
              <div className="position-relative">
                <ModalBody className="order-detail-model-body">
                  {tractArray &&
                    tractArray
                      .filter((item) => !!item)
                      .map((item) => {
                        return (
                          <div>
                            {<h5>AWB Status: {item.awb_number}</h5>}
                            {<h5>Order Status: {item.order_status}</h5>}
                            {
                              <h5>
                                Updated at:{" "}
                                {
                                  item.delivery_request_state_histories[0]
                                    .updated_at
                                }
                              </h5>
                            }
                            {
                              <h5>
                                State:{" "}
                                {item.delivery_request_state_histories[0].state}
                              </h5>
                            }
                            {
                              <h5>
                                Scan:{" "}
                                {item.delivery_request_state_histories[0].scan}
                              </h5>
                            }
                            <hr
                              style={{
                                color: "grey",
                                backgroundColor: "grey",
                                height: 1,
                              }}
                            />
                          </div>
                        );
                      })}

                  {orderTracking &&
                    orderTracking.length === 0 &&
                    trackingOrderStatus !== "Cancelled" && (
                      <p className="no-tracking-history">
                        No Tracking history available!
                      </p>
                    )}
                </ModalBody>
              </div>
            </Modal>
            <Modal
              isOpen={orderRemarksModal}
              size={"18"}
              className="modal-dialog-centered order-remarks-model"
            >
              <ModalHeader
                toggle={() => this.handleModalCancel("orderRemarksModal")}
              >
                Order Remarks
              </ModalHeader>
              <div className="position-relative">
                <ModalBody className="order-detail-model-body">
                  {orderRemarks && orderRemarks.length > 0 && (
                    <div>
                      <table>
                        <tr>
                          <th>Timestamp</th>
                          <th colSpan="4">Person Name</th>
                          <th colSpan="4">Message</th>
                        </tr>
                        {orderRemarks.map((item) => {
                          return (
                            <tr>
                              <td colSpan="4">
                                {moment(item.created_at).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                                :{" "}
                              </td>
                              <td colSpan="4">{item.person_name} : </td>
                              <td colSpan="4">{item.message}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-4">
                      <input
                        value={remark.person_name}
                        placeholder="Person Name"
                        onChange={(e) =>
                          this.handleRemarksChange({
                            name: "person_name",
                            value: e.target.value,
                          })
                        }
                        className="form-control"
                        type="text"
                      />
                    </div>
                    <div className="col-8">
                      <input
                        value={remark.message}
                        placeholder="Message"
                        onChange={(e) =>
                          this.handleRemarksChange({
                            name: "message",
                            value: e.target.value,
                          })
                        }
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-primary mt-5"
                      onClick={() => this.handleRemarkSubmit(orderData.id)}
                      disabled={disabled}
                    >
                      Add Remarks
                    </button>
                  </div>
                </ModalBody>
              </div>
            </Modal>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ordersList: state.orders.ordersList,
  isLoading: state.orders.isLoading,
  userId: state.common.userDetails.id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGtmOrdersList,
      generatePurchaseOrder,
      updatePaymentStatus,
      getGtmWareHouseNames,
      cancelOrder,
      getOrderRemarks,
      addOrderRemarks,
      getOrderInventory,
      generateInvoiceByOrderId,
      updateOrderDocStatus,
      saveDraftOrder,
    },
    dispatch
  );
};

let actions = ["getGtmOrdersList"];

let component = LoaderWrapper(GtmOrdersListClass)({ actions });

export const OrdersListGtm = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
