import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, SliderOverlay, ConfirmModal } from "../../component/common/"
import { getSNo } from "../../services/helperFunctions"
import { getConfigData } from "../../redux/actions/itinarary"
import { LoaderWrapper } from "../../HOC"


class itineraryClass extends Component {
	state = {
		ref_id: '',
		itineraryLists: [],
		headerDetails: [
			{
				label: 'S.No',
			},
			{
				label: 'Ref. Id',
			},
			{
				label: 'Parent Ref. Id',
			}
		],
		filter: [
			{
				label: 'Active',
				value: 1
			},
			{
				label: 'Deactive',
				value: 4
			}
		],
		activityStatusChange: {
			activityId: "",
			status: null
		},
		selectedFilter: 'Active',
		ConfirmModalOpenStatus: false,
		ConfirmModalChangeActiveStatus: false,
		deleteIndex: null
	}

	componentDidMount() {
		this.setState({
			itineraryLists: this.props.itineraryLists
		})
	} 

	requestStatus = (status) => {
		if (status === 1) {
			return <Badge
				type={1}
				label={"Active"}
			/>
		} else if (status === 0) {
			return <Badge
				type={2}
				label={"Inactive"}
			/>
		}
	}

	handleFilter = (query, value, name) => {
		if (name == "filter") {
			this.setState({
				selectedFilter: query
			})
			query = {
				search: query
			}
        }
		this.props.getConfigData(query)
	}

	handleRefFilter = (query, value, name) => {
        let queryParams = {filter : query, pageNo: 1}
		this.props.getConfigData(queryParams)
	}
	
	handleRefId = ({target: {value, name}}) => {
		this.setState({ref_id: value});
	}

	toggle = item => {
		this.setState(prevState => ({
			[item]: !prevState[item]
		}));
	}

	handleDeleteToggle = (deleteIndex) => {
		this.setState({
			deleteIndex
		})

		this.toggleDelete("ConfirmModalOpenStatus")
	}

	handleDelete = () => {
		let { deleteIndex } = this.state
		this.props.deleteActivity(deleteIndex).then(() => {
			this.props.getConfigData()
			this.toggleDelete("ConfirmModalOpenStatus")
		})
	}

	toggleStatusChange = (id, stat) => {
		let activityStatusChange = {}
		activityStatusChange.activityId = id;
		activityStatusChange.status = stat
		this.setState({
			activityStatusChange
		})
		this.toggle('ConfirmModalChangeActiveStatus')
	}

	changeStatus = () => {
		const { activityStatusChange: { activityId, status } } = this.state
		const body = {
			activityId: activityId,
			status: status === 1 ? 0 : 1
		}
		this.props.changeActivityStatus(body).then(() => {
			this.props.getConfigData()
			this.toggle('ConfirmModalChangeActiveStatus')
		})
	}

	toggleDelete = (name) => {
		this.setState({
			[name]: !this.state[name]
		})
	}

	handleNavigation = () => {
		let { history } = this.props
		history.push('/itinerary/create')
	}

	handleEdit = (id, ref_id) => {
		let { history, match } = this.props
		history.push(`/itinerary/edit/${id}/?${ref_id}`)
	}

	render() {
		let { headerDetails, ConfirmModalOpenStatus, ConfirmModalChangeActiveStatus, ref_id } = this.state
		let { itineraryLists } = this.props;
		let IsEmpty = itineraryLists.list.length > 0;		
		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Itinerary
					</h4>
					<div className="col-auto ">
						<button className="btn btn-primary btn-sm"
							onClick={() => this.handleNavigation()}
						>Generate Itinerary</button>
					</div>
				</div>
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<h5 className="sair-sec-title align-self-center">Itineraries</h5>
								<div className="ml-auto d-flex align-items-center">
									<div className="search-box position-relative  ml-3 display-flex">
										  <input
                                                className="form-control"
                                                id="ref_id"
                                                type="text"
                                                name="ref_id"
                                                value={ref_id}
                                                onChange={this.handleRefId}
                                            />
											
                                            <button className="btn btn-primary btn-sm ml-4"
                                                onClick={() => this.handleRefFilter(ref_id)}
                                            >Search</button>				
									</div>
								</div>
							</div>
							 <div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									pageMeta={itineraryLists.pageMeta}
									queryHandler={(query) => this.handleFilter(query)}
								>
									{itineraryLists.list.map((item, index) => {
										let { id, ref_id, parent_ref_id } = item
										return (
											<tr
												key={index}  >
                                                <td>{getSNo(itineraryLists.pageMeta, index)}</td>
												<td>{ref_id}</td>
												<td>{parent_ref_id ? parent_ref_id : "-"}</td>
												<td>
													<a className="mr-25"
														onClick={e => this.handleEdit(id, ref_id)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
												</td>
											</tr>
										)
									})}
								</TableWrapper>
							</div> 
						</div>
					</div>
				</section>

				<ConfirmModal
					isOpen={ConfirmModalChangeActiveStatus}
					keyName="ConfirmModalChangeActiveStatus"
					toggle={() => {
						let { activityStatusChange } = this.state
						activityStatusChange.activityId = null;
						activityStatusChange.status = null;
						this.setState({
							activityStatusChange
						}, () => this.toggle('ConfirmModalChangeActiveStatus'))
					}}
					handleSubmit={() => this.changeStatus()}
				>
					<h5>Are you sure you want to change the Activity's status?</h5>
				</ConfirmModal>

				<ConfirmModal
					keyName="ConfirmModalOpenStatus"
					toggle={this.toggle}
					isOpen={ConfirmModalOpenStatus}
					handleSubmit={() => this.handleDelete()}
					submitText='Delete'
				>
					<h5>
						Are you sure want to delete Activity?
					</h5>
				</ConfirmModal>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		itineraryLists: state.itinerary.itineraryLists
	}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getConfigData
	}, dispatch)
};

let actions = ['getConfigData']
let componentNew = LoaderWrapper(itineraryClass)({ actions })

export const Itinerary = connect(mapStateToProps, mapDispatchToProps)(componentNew)
