import { activity } from "../../services/apiVariables"
import { activityType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"


export const getActivityDropDown = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {


		api({ ...activity.getActivityDropDown }).then(({ data, message }) => {

			dispatch({ type: activityType.updateActivityDropDown, payload: data })

			// Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))


		})

	})
}


export const addImage = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: activityType.addImage, payload: body })

		api({ ...activity.addImage, body }).then(({ data, message }) => {

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))


		})

	})
}

export const addItenaryImage = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: activityType.addItenaryImage, payload: body })

		api({ ...activity.addItenaryImage, body }).then(({ data, message }) => {

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))


		})

	})
}

export const createSliderActivityImage = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		api({ ...activity.addSliderActivityImage, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message }))

		})

	})
}

export const changeActivityStatus = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		// addQuery(query, activity.getActivityListDetails)
		dispatch({ type: common.updateLoader, payload: true })

		api({ ...activity.changeActivityStatus, body }).then(({ data, message }) => {

			// 	dispatch({ type: activityType.updateActivityListDetails, payload: data })

			resolve(Toast({ type: 'success', message: data }))
			dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {
			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message })

		})

	})

}

export const getActivityListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {


		addQuery(query, activity.getActivityListDetails)

		api(activity.getActivityListDetails).then(({ data, message }) => {

			dispatch({ type: activityType.updateActivityListDetails, payload: data })

			resolve(true)


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}


export const getActivityDetailsById = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		activity.getActivityDetailsById.addParams = id;

		api(activity.getActivityDetailsById).then(({ data }) => {

			dispatch({ type: activityType.getActivityDetailsById, payload: data })

			resolve(data)

			dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {

			reject(message)

			dispatch({ type: common.updateLoader, payload: false })

		})



	})

}

export const deleteActivity = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		activity.deleteActivity.addParams = id;

		api(activity.deleteActivity).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message: message })

			dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message }))

		})

	})

}


export const createActivity = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		dispatch({ type: activityType.createActivity, payload: body })

		api({ ...activity.createActivity, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: data })

			resolve(data)

		}).catch(({ error }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message: JSON.stringify(error) }))


		})

	})
}

export const updateActivity = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		activity.updateActivity.addParams = id;

		api({ ...activity.updateActivity, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ error }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message: JSON.stringify(error) }))

		})

	})
}

/** MApping activity list */

export const getCategoryMappingListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, activity.getCategoryMappingListDetails)

		api(activity.getCategoryMappingListDetails).then(({ data, message }) => {


			dispatch({ type: common.updateLoader, payload: false })

			dispatch({ type: activityType.updateCategoryMappingListDetails, payload: data })

			resolve(true)


		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message })

			resolve(false)

		})

	})

}

export const getCategoryMappingDropDown = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {


		api({ ...activity.getCategoryMappingDropDown }).then(({ data, message }) => {

			dispatch({ type: activityType.updateCategoryMappingDropDown, payload: data })

			resolve(data)

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))


		})

	})
}

export const createCategoryMapping = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		dispatch({ type: activityType.createCategoryMapping, payload: body })

		api({ ...activity.createCategoryMapping, body }).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'success', message: data })

			resolve(data)

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message }))


		})

	})
}

export const deleteActivityMapping = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		activity.deleteCategoryMapping.addParams = id;

		api(activity.deleteCategoryMapping).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message: message })

			dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message }))

		})

	})

}


export const updateActivityMapping = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		activity.updateCategoryMapping.addParams = id;

		api({ ...activity.updateCategoryMapping, body }).then(({ data, message }) => {

			resolve(data)

			Toast({ type: 'success', message: message })

			dispatch({ type: common.updateLoader, payload: false })

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			reject(Toast({ type: 'error', message }))

		})

	})

}