import { itineraryType } from "../../services/actionType"
const initialState = {
    itineraryData: [],
    itineraryMasterData: [],
    itineraryLists: [],
    itineraryDetails: [],
    cityInfo: [],
    cityList: [],
    base64Images: []   
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

	switch (type) {
		case itineraryType.updateItinerary:
            return {
				...state,
				itineraryData: payload
            }
        case itineraryType.updateMasterData:
            return {
                ...state,
                itineraryMasterData: payload
            }
        case itineraryType.getBase64Images:
            return {
                ...state,
                base64Images: payload
            }
        case itineraryType.getCityWiseInfo:
            return {
                ...state,
                cityInfo: payload
            }
        case itineraryType.updateItineraryListDetails:
            return {
                ...state,
                itineraryLists: payload
            }
        case itineraryType.getCityNames:
            return {
                ...state,
                cityList: payload
            }
        case itineraryType.getItineraryDetailsById:
            return {
                ...state,
                itineraryDetails: payload
            }
            
        default:
			return state

    }
}
