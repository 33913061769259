import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { FileUpload, ReactAutoComplete, NormalInput, ReactSelect} from "../../component/common"
import { TextEditorActivity} from "../../component/common/TextEditorActivity"
import SimpleReactValidator from 'simple-react-validator';
import {createBrand, getCategories ,addImage} from "../../redux/actions/reseller";

export class AddBrandModalClass extends Component {

	state = {
		brandDetails: {
            name: '',
            imgLinks: [],
            shareLink: '',
            descripton: '',
            seller_id:'',
        },
        descState: {
            description: ""
        },
        categoryNames: []
    }

	componentWillMount() {
        this.descState={};
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
        });
        this.handleInputs = ({ value, name }) => {
            let brandDetails = this.state.brandDetails
            let url;
            if (name === 'name') {
              brandDetails.name = value
            }
            else if (name == "description") {
              brandDetails["description"] = value;
              brandDetails["description"] = brandDetails["description"].length<this.descState.description.length ? this.descState.description : brandDetails["description"];
            }
            else {
              brandDetails[name] = value
            }
            
            this.setState({ brandDetails: brandDetails })
          }
      
          this.handleDescriptionInputs = ({value, name}) => {
            this.descState["description"] = value;
          }
	}


	componentWillReceiveProps(props) {
		let { modalDetails, isEdit,} = props
		this.setState({
			sellerId: modalDetails && modalDetails.sellerId,
			isEdit: isEdit,
		})
	}
    ImagePreview = (key, event) => {
        let files = event.target.files[0]
        let brandDetails = Object.assign({}, this.state.brandDetails)
        let formData = new FormData()
        formData.append("resellerImage", files)
        this.props.addImage(formData).then((data) => {
          brandDetails.imgLinks[0] = data.imageUrl
        })
        this.setState({brandDetails})
      }
	handleSubmit = () => {
        let {brandDetails} = this.state
		if (this.validator.allValid()) {

            let body = {
                ...brandDetails
              }
              body.seller_id=this.state.sellerId;
              this.props.createBrand(body).then((data) => {
                let { history } = this.props
                this.setState({disabled:false})
                this.props.updateShopName(body.seller_id)
              }).catch((ex) => {
                this.setState({disabled:false})
              })
            this.props.toggle()
			this.validator.hideMessages();

		} else {
			this.setState({
				isError: true
			})
			this.validator.showMessages();

		}
	}

	render() {
        let {isError, toggle, isEdit, } =this.state;
        let {name, description, imgLinks, shareLink} = this.state.brandDetails;
        let tempFile
		this.validator.purgeFields();

		return (
			<section className="side-slider h-100">
				<div className="slider-header p-4">
					<div className="d-flex align-items-center">
						<h5 className="sair-sec-title"> {isEdit ? "Edit Mapping Activity " : "Add Mapping Activity "}</h5>
						<div className="ml-auto">
							<i className="icon-close font-md px-2 cursor-pointer"
								onClick={toggle}
							></i>
						</div>
					</div>
				</div>
				<div className="slider-body p-4">
					<div className={`row no-gutters ${isError ? "was-validated" : ""}`}>
                    <div className="col-md-12 form-group">
              <label><b>Name</b></label>
              <NormalInput
                className="form-control"
                name="name"
                type="text"
                value={name}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Brand Title', name, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-12 form-group">
              <label><b>Image</b></label>
              <FileUpload
                fileUpload={this.fileUpload}
                ImagePreview={(e) => this.ImagePreview("brandImage", e)}
                filename={imgLinks && imgLinks[0]? imgLinks[0] : ""}
                tempFile={tempFile}
              />
              <img src={imgLinks && imgLinks[0]} style={{ width: "200px", marginTop: "15px" }} />
              {this.validator.message('image', imgLinks, 'required')}
            </div>
            <div className="col-md-12 form-group">
              <label><b>Description</b></label>

              <TextEditorActivity
                handleTextEditor={html => {
                  this.handleDescriptionInputs({ name: 'description', value: html })
                }}
                handleTextEditorInput={html => {
                  this.handleInputs({ name: 'description', value: html })
                }}
                html={this.state.brandDetails.description}
              />
              {this.validator.message('category description', description, 'required')}
            </div>
        </div>
        <hr />
          <button className="btn btn-primary"
            onClick={() => this.handleSubmit()}
            disabled={this.state.disabled}
          >Submit</button>
				</div>
			</section>
		)
	}
}

const mapStateToProps = state => {

	return {

		
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		createBrand,
        getCategories,
        addImage
	}, dispatch)
};



export const AddBrandModal = connect(mapStateToProps, mapDispatchToProps)(AddBrandModalClass)

