import React, { Component } from 'react'


// this component will allow file and image both.

export class FileVideoUpload extends Component {
	render() {
		let { ImagePreview, fileUpload, filename, tempFile, fileType = ".png, .jpg , .jpeg,  .webp, .mp4", preview = false } = this.props

		if (typeof filename == "string" || filename == null) {
			filename = filename
		} else {
			filename = filename.name
		}

		if (!tempFile) {
			tempFile = filename
		}

		return (
			<>
				{tempFile && filename != "null" && preview == true &&
					<div className="filepreview" >
						<img src={tempFile} className="img-fluid" />
					</div>
				}
				<div className="fileinput fileinput-new input-group"
					data-provides="fileinput">
					<div className="input-group-prepend">
						<span className="input-group-text">Upload</span>
					</div>
					<div className="form-control text-truncate" data-trigger="fileinput"><i
						className="glyphicon glyphicon-file fileinput-exists"></i> <span
							className="fileinput-filename" >{filename}</span>
					</div>
					<span className="input-group-append">
						<span className=" btn btn-primary btn-file">
							<span className="fileinput-new">Select file</span>

							<input type="file" name="file"
								accept={fileType}
								onChange={ImagePreview}
								ref={fileUpload}
							/>
						</span>

					</span>
				</div>
			</>
		)
	}
}
