import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, ReactSelect, SliderOverlay, ConfirmModal, ToggleCheckbox } from "../../component/common"
import { deleteCategoryLevelTwoById, getCategoriesLevelTwo, getCategoryNames } from "../../redux/actions/reseller"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getSNo } from "../../services/helperFunctions"
import { LoaderWrapper } from "../../HOC"
import { directive } from '@babel/types';
import { Link } from 'react-router-dom'
import _ from 'lodash'

class CategoryLevelTwoListClass extends Component {

	state = {
		categories: [],
		categoryNames: [],
		category: "",
		headerDetails: [
			{
				label: 'S.No'
			},
			{
				label: 'Name',
			},
			{
				label: 'Description'
			},
			{
				label: 'Active',
			}
		]
	}

	componentWillMount() {

	}

	componentDidMount() {
		this.props.getCategoryNames().then((data) => {
			let categoryNames = _.map(data, (category) => {
				return { value: category._id, label: category.name }
			})
			categoryNames.push({ label: "All", value: '' })
			this.setState({ categoryNames: categoryNames })
		})
		let category = localStorage.getItem('category') ? localStorage.getItem('category') : this.state.category;
		let query = {
			pageNo: 1
		};
		query['category'] = category;
		this.props.getCategoriesLevelTwo(query)
	}
	handleSelect = ({ name, value }) => {
		this.setState({
			[name]: value
		}, this.setInLocaStorage(name, value))
	}
	setInLocaStorage = (name, value) => {
		if (name === 'category') {
			window.localStorage.setItem(name, value)
		}
	}

	handleSearchFilter = (query, value, name) => {
		if (query.searchText !== "") {
			this.setState({ searchText: query.searchText })
			this.props.getCategoriesLevelTwo(query)
		}
	}
	handleTopFilter = () => {
		let query = {
			pageNo: 1
		};
		query['category'] = this.state.category;
		this.props.getCategoriesLevelTwo(query)
	}
	handleFilter = (query) => {
		this.props.getCategoriesLevelTwo(query)
	}

	handleAddorEdit = (id) => {
		if (id) {
			this.props.history.push(`/reseller/category-l2/edit/${id}`)
		} else {
			this.props.history.push('/reseller/category-l2/create')
		}
	}

	handleDelete = (id) => {
		this.props.deleteCategoryLevelTwoById(id).then(() => {
			window.location.reload()
		})
	}
	requestStatus = (status) => {
		if (!status) {
			return <Badge
				type={2}
				label={"False"}
			/>
		}

		else if (status) {
			return <Badge
				type={1}
				label={"True"}
			/>
		}

	}
	render() {

		let { headerDetails, categoryNames, category } = this.state
		let { categoryLevelTwoList } = this.props
		let IsEmpty = categoryLevelTwoList && categoryLevelTwoList.list && categoryLevelTwoList.list.length > 0

		return (
			<div>
				{/* Header */}
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>L2 Category
					</h4>
					<div className="col-auto ">
						{/* commented so can use later */}
						{/* <button className="btn btn-primary btn-sm"
            onClick={() => this.handleAddorEdit()}
          >Add Category L2</button> */}
					</div>
				</div>

				{/* Content */}
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<div className="col-md-3 form-group ml-2">
									<label><b>Category Name</b></label>
									<ReactSelect
										isMulti={false}
										options={categoryNames}
										handleChange={(keyName, data) => {
											this.handleSelect({ name: keyName, value: data })
										}}
										keyName="category"
										value={category}
										disabled={false}
									/>
								</div>
								<div className="col-md-2">
									<br />
									<button className="btn btn-primary btn-sm mt-10 ml-20" onClick={() => this.handleTopFilter()}>Filter</button>
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									pageMeta={categoryLevelTwoList && categoryLevelTwoList.pageMeta}
									queryHandler={(query) => this.handleFilter(query)}
								>
									{categoryLevelTwoList && categoryLevelTwoList.list.map((item, index) => {
										let { id, _id, name, descriptions, active } = item

										return (
											<tr
												key={index}  >
												<td>{index + 1}</td>
												<td>{name}</td>
												<td>{descriptions}</td>
												<td> {this.requestStatus(active)}</td>
												{/* commented so can use later */}
												{/* <td>
													<a className="mr-25"
														onClick={e => this.handleAddorEdit(_id)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"
														onClick={e => this.handleDelete(_id)}
													></i>
													</a>
												</td> */}
											</tr>
										)
									})}
								</TableWrapper>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		categoryLevelTwoList: state.reseller.categoryLevelTwoList // data from reducer to categoryList
	}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getCategoriesLevelTwo,
		deleteCategoryLevelTwoById,
		getCategoryNames
	}, dispatch)
};

let actions = ["getCategoriesLevelTwo"]

let component = LoaderWrapper(CategoryLevelTwoListClass)({ actions })

export const CategoryLevelTwoList = connect(mapStateToProps, mapDispatchToProps)(component)
