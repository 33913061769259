import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import _ from "lodash";
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal } from "../../component/common"
import {TicketNationalityTypeModal } from "../../component/master"
import SimpleReactValidator from 'simple-react-validator';
import { getTicketNationalityTypeListDetails, deleteTicketNationalityType } from "../../redux/actions/master"

import { LoaderWrapper } from "../../HOC"


class TicketNationalityTypeClass extends Component {
	state = {
		headerDetails: [
			{
				label: 'S.No',
			},
			{
				label: 'Ticket Nationality Type',
			},
			{
				label: 'Status',
			},
			{
				label: 'Action'
			},
		],
		filter: [
			{
				label: 'Active',
				value: 1
			},
			{
				label: 'Deactive',
				value: 0
			}

		],
		ticketNationalityLists: {

		},
		selectedFilter: 'Active',
		ticketNationalityDetails: {
			title: '',

		},
		isError: false,
		isOpen: false,
		isEdit: false,
		ConfirmModalOpenStatus: false,
		id: ''
	}


	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: message => <span className="error-message ">{message}</span>,
			autoForceUpdate: this,
			messages: {}
		});
	}

	componentDidMount() {
		this.setState({
			ticketNationalityLists: this.props.ticketNationalityLists
		})

	}


	requestStatus = (status) => {

		if (status === 1) {
			return <Badge
				type={1}
				label={"Active"}
			/>
		} else if (status === 2) {
			return <Badge
				type={2}
				label={"Deactive"}
			/>
		}

	}
	handleAdd = () => {

		let temp = {
			name: ''
		}
		this.setState({
			isOpen: true,
			ticketNationalityDetails: temp
		})
	}

	handleEdit = (id,index) => {

		let {ticketNationalityLists} = this.props;
		
		let ticketNationalityDetails = _.filter(ticketNationalityLists,(item)=>{
				return parseInt(item.id) === parseInt(id)
		})[0];//It will return object instead of array
		this.setState({
			isEdit: true,
			ticketNationalityDetails,
			currentIndex: index

		})

		this.toggle("isOpen")

	}

	toggle = item => {
		this.setState(prevState => ({
			[item]: !prevState[item]
		}));
	}

	handleModal = () => {

		let temp = {
			name: '',
		}

		this.setState({
			isError: false,
			isOpen: false,
			isEdit: false,
			ticketNationalityDetails: temp

		})
	}

	handleDeleteToggle = (id) => {

		this.setState({
			id
		})

		this.toggleDelete("ConfirmModalOpenStatus")

	}


	handleDelete = () => {
		let { id } = this.state

		this.props.deleteStayType(id).then(() => {

			this.props.getStayTypeDetails()

			this.toggleDelete("ConfirmModalOpenStatus")

		})

	}

	toggleDelete = (name) => {
		this.setState({
			[name]: !this.state[name]
		})
	}



	render() {

		let { headerDetails, filter, selectedFilter, ticketNationalityDetails, isError, isOpen, isEdit, ConfirmModalOpenStatus } = this.state
		let { ticketNationalityLists } = [];
		ticketNationalityLists= _.filter(this.props.ticketNationalityLists,(ticketNationalityType)=>{
			return ticketNationalityType.status===1;
		})
		let IsEmpty = ticketNationalityLists.length > 0

		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Ticket Nationality Type
					</h4>
					<div className="col-auto ">
						<button className="btn btn-primary btn-sm"
							onClick={() => this.handleAdd()}
						>Add Ticket Nationality Type</button>
					</div>
				</div>
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<h5 className="sair-sec-title align-self-center">Ticket Nationality List</h5>
								<div className="ml-auto d-flex align-items-center">
								

									<div className="search-box position-relative  ml-3">

										
									</div>
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									>
									{ticketNationalityLists.map((item, index) => {
										let { id, title, status } = item

										return (
											<tr
												key={index}  >
												<td>{index+1}</td>
												<td>{title}</td>
												<td>{this.requestStatus(status)}</td>
												<td>
													<a className="mr-25"
														onClick={e => this.handleEdit(id,index)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"
														onClick={e => this.handleDeleteToggle(id)}
													></i></a>
												</td>

											</tr>

										)

									})}


								</TableWrapper>
							</div>
						</div>
					</div>
				</section>



				<SliderOverlay isOpen={isOpen} toggle={this.handleModal} size='30%'>
					<div className="side-slider h-100 position-relative">
						<div className="h-100 flex-with-center">
							<TicketNationalityTypeModal
								isError={isError}
								toggle={this.handleModal}
								isEdit={isEdit}
								modalDetails={ticketNationalityDetails}
							/>
						</div>

					</div>
				</SliderOverlay>

				<ConfirmModal
					keyName="ConfirmModalOpenStatus"
					toggle={this.toggle}
					isOpen={ConfirmModalOpenStatus}
					handleSubmit={() => this.handleDelete()}
					submitText='Delete'
				>
					<h5>
						Are you sure want to delete Stay Type?
					</h5>
				</ConfirmModal>

			</div>
		)
	}
}

const mapStateToProps = state => {

	return {
		ticketNationalityLists: state.master.ticketNationalityTypeLists
	}

};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getTicketNationalityTypeListDetails,
		deleteTicketNationalityType
	}, dispatch)
};

let actions = ['getTicketNationalityTypeListDetails']

let component = LoaderWrapper(TicketNationalityTypeClass)({ actions })


export const TicketNationalityType = connect(mapStateToProps, mapDispatchToProps)(component)