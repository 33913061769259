import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal, ToggleCheckbox } from "../../component/common"
import { ReactSelect } from '../../component/common/ReactSelect'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { getBrands, deleteBrandById, getSellerNames, getCategoryNames } from "../../redux/actions/reseller"
import { getSNo } from "../../services/helperFunctions"
import { LoaderWrapper } from "../../HOC"
import { directive } from '@babel/types';

import _ from 'lodash'

class BrandListClass extends Component {

  state = {
    brands: [],
    categoryNames: [],
    sellerNames: [],
    sellerId: "",
    category: "",
    headerDetails: [
      {
        label: 'S.No'
      },
      {
        label: 'Name',
      },
      {
        label: 'Description'
      },
      {
        label: 'Starting Price',
      },
      {
        label: 'Link',
      },
      // commented so can use later 
      // {
      //   label: 'Edit / Delete'
      // }
    ]
  }


  componentWillMount() {

  }

  componentDidMount() {
    this.props.getCategoryNames().then((data) => {
      let categoryNames = _.map(data, (category) => {
        return { value: category._id, label: category.name }
      })
      categoryNames.push({ label: "All", value: '' })
      this.setState({ categoryNames: categoryNames })
    })
    this.props.getSellerNames().then((data) => {
      let sellerNames = _.map(data, (seller) => {
        return { value: seller.id, label: seller.first_name }
      })
      sellerNames.push({ label: "Admin", value: 'admin' })
      sellerNames.push({ label: "All", value: '' })
      this.setState({ sellerNames: sellerNames })
    })
    let category = localStorage.getItem('category') ? localStorage.getItem('category') : this.state.category;
    let sellerId = localStorage.getItem('sellerId') ? localStorage.getItem('sellerId') : this.state.sellerId;
    let query = {
      pageNo: 1
    };
    query['sellerId'] = sellerId;
    query['category'] = category;
    this.props.getBrands(query)
    this.setState({
      category, sellerId
    })
  }

  handleAddorEdit = (id) => {
    if (id) {
      this.props.history.push(`/reseller/brand/edit/${id}`)
    } else {
      this.props.history.push('/reseller/brand/create')
    }
  }

  handleSearchFilter = (query, value, name) => {
    this.props.getBrands(query)
  }
  handleTopFilter = () => {
    let query = {
      pageNo: 1
    };
    query['sellerId'] = this.state.sellerId;
    query['category'] = this.state.category;
    this.props.getBrands(query)
  }
  handleFilter = (query) => {
    this.props.getBrands(query)
  }
  handleSelect = ({ name, value }) => {
    this.setState({
      [name]: value
    }, this.setInLocaStorage(name, value))
  }
  setInLocaStorage = (name, value) => {
    if (name === 'category' || name === 'sellerId') {
      window.localStorage.setItem(name, value)
    }
  }

  handleDelete = (id) => {
    this.props.deleteBrandById(id).then(() => {
      window.location.reload()
    })
  }

  render() {
    let { headerDetails, sellerNames, sellerId, categoryNames, category } = this.state
    let { brandList } = this.props
    let IsEmpty = brandList && brandList.list && brandList.list.length > 0
    return (
      <div>
        {/* Header */}
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Shop
          </h4>
          <div className="col-auto ">
            <Link to="/reseller/brand/position"><button className="btn btn-primary btn-sm m-1"

            >Shop Positions</button></Link>
            {/* commented so can use later */}
            {/* <button className="btn btn-primary btn-sm"
              onClick={() => this.handleAddorEdit()}
            >Add Shop</button> */}
          </div>
        </div>
        {/* Content */}
        <section className="sair-sec-wrapper">
          <div className="row">
            <div className="col-sm">
              <div className="row no-gutters">
                <div className="col-md-3 form-group">
                  <label><b>Seller Name</b></label>
                  <ReactSelect
                    isMulti={false}
                    options={sellerNames}
                    handleChange={(keyName, data) => {
                      this.handleSelect({ name: keyName, value: data })
                    }}
                    keyName="sellerId"
                    value={sellerId}
                    disabled={false}
                  />
                </div>
                <div className="col-md-3 form-group ml-2">
                  <label><b>Category Name</b></label>
                  <ReactSelect
                    isMulti={false}
                    options={categoryNames}
                    handleChange={(keyName, data) => {
                      this.handleSelect({ name: keyName, value: data })
                    }}
                    keyName="category"
                    value={category}
                    disabled={false}
                  />
                </div>
                <div className="col-md-2">
                  <br />
                  <button className="btn btn-primary btn-sm mt-10 ml-20" onClick={() => this.handleTopFilter()}>Filter</button>
                </div>
                <div className="col-md-3 form-group">
                  <label>Search by shop name</label>
                  <Filters
                    className="pt-0"
                    queryHandler={(query) => this.handleSearchFilter(query)}
                    isDropDownFilter={false}
                  />
                </div>
              </div>
              <div className="table-wrap mt-3">
                <TableWrapper
                  headerDetails={headerDetails}
                  isEmpty={IsEmpty}
                  pageMeta={brandList && brandList.pageMeta}
                  queryHandler={(query) => this.handleFilter(query)}
                >
                  {brandList && brandList.list && brandList.list.map((item, index) => {
                    let { id, _id, name, description, shareLink, startingPrice } = item
                    return (
                      <tr
                        key={index}  >
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        <td>{description}</td>
                        <td>{startingPrice}</td>
                        <td>{shareLink}</td>
                        {/* commented so can use later */}
                        {/* <td>
                          <a className="mr-25"
                            onClick={e => this.handleAddorEdit(_id)}
                          >
                            <i className="icon-pencil  text-primary cursor-pointer"
                            ></i>
                          </a>
                          <a ><i
                            className="icon-trash txt-danger text-primary cursor-pointer"
                            onClick={e => this.handleDelete(_id)}
                          ></i>
                          </a>
                        </td> */}

                      </tr>
                    )
                  })}
                </TableWrapper>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    brandList: state.reseller.brandList // data from reducer to brandList
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getBrands,
    deleteBrandById,
    getSellerNames,
    getCategoryNames
  }, dispatch)
};

let actions = []

let component = LoaderWrapper(BrandListClass)({ actions })

export const BrandList = connect(mapStateToProps, mapDispatchToProps)(component)
