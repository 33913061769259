import React from 'react';
import {getCityWiseInfo} from '../../redux/actions/itinarary'
import {ReactSelect} from '../common';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import _ from 'lodash';
import { getCityNames } from '../../redux/actions/itinarary';
import { LoaderWrapper } from "../../HOC";

class ItineraryModalClass extends React.Component {
    state = {
        cities: [],
        city_name: "",
        cityData: "",
        cityList: []
    }

    componentDidMount(){
        this.props.getCityNames().then((data)=>{
            if(data.length > 0){
                let cityData = data;
                let cityList = [];
                cityData.forEach((item, index)=>{
                    cityList.push({
                        id: index,
                        label: item,
                        value: item 
                    })
                })
                this.setState({cityList: cityList});      
            }
        });
    }

    handleSelectInputs = ({ value, name }) => {
		let city_name = value
		
		this.setState({
			city_name
        },()=>{
            if(name == "city_name"){
                if(this.state.city_name != ""){
                    city_name = this.state.city_name;
                    let query = {cities: city_name}
                    let queryparams = {
                        filter: query
                    }
                    this.props.getCityWiseInfo(queryparams).then((data) => {
                        this.setState({cityData: data}, ()=>{
                            let cityData = this.state.cityData;                            
                        });
                    })
                }
            }
        })
    }

    renderTableData() {
        let cityData = this.state.cityData;
        if(cityData!=""){
            return cityData.map((rowItem, index) => {
                return (
                <>
                    {
                        index == 0 ? <tr className="text-center"><td></td><td></td>
                        {rowItem.activityIds.map((actId)=>{
                            return <td className="table-border">{actId}</td>
                        })}
                        </tr>:""
                    }
                    <tr className="text-center">
                        <th colspan="2" className="table-border">{rowItem.sourceActivityId}</th>
                        {rowItem.travelInfo.map((colItem, colIndex) => {
                            return (<td className="table-border">
                            <td>{colItem.duration}<br/>{colItem.distance!='NA'?colItem.distance:""}</td>
                            </td>
                            )
                        })}
                    </tr>
                </>) 
            })
        }
    }

    render(){
        let { city_name, cityList } = this.state;
        return(
            <section>
                <div className="row">
                    <div className="col-md-10 mt-20 ml-20">
                        <label>Destination <span className="required">{" *"}</span></label>
                        <ReactSelect
                            isMulti={false}
                            options={cityList}
                            handleChange={(keyName, data) => {
                                this.handleSelectInputs({name: keyName, value: data})
                            }}
                            keyName="city_name"
                            value={city_name}
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">                       
                        <table id='itinerary' className="itinerary-table">
                            {this.renderTableData()}
                        </table>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        cityData: state.itinerary.cityInfo,
        cityNames: state.itinerary.cityList
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getCityWiseInfo, getCityNames
    }, dispatch)
}

let actions = ['getCityNames']
let component = LoaderWrapper(ItineraryModalClass)({ actions })

export const ItineraryModal = connect(mapStateToProps, mapDispatchToProps)(component);