import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getLeadListByUser,
  confirmLead,
  createLead,
  getWholesaleProductNames,
  deleteLead,
} from "../../redux/actions/wholesale";
import { TableWrapper } from "../../component/common";
import PartnerLeadItemRow from "./PartnerLeadItemRow";
import AddProductLead from "./AddProductLead";
import { LoaderWrapper } from "../../HOC";

const Columns = [
  {
    label: "S. No",
  },
  {
    label: "Product",
  },
  {
    label: "Confirmed",
  },
  {
    label: "Variant",
  },
  {
    label: "Final Price",
  },
  {
    label: "",
  },
];

export const PartnerLeadsList = ({
  leadListByUser,
  getLeadListByUser,
  confirmLead,
  getWholesaleProductNames,
  history,
  deleteLead,
}) => {
  const [isModalVisible, setModalVisible] = useState(0);
  let { list = [], pageMeta = {} } = leadListByUser;
  const params = new URLSearchParams(history.location.search || "");
  const userId = params.get("user_id");

  useEffect(() => {
    getWholesaleProductNames();
    getLeadListByUser({ user_id: userId });
  }, []);

  const onUpdateProduct = async (values, item) => {
    try {
      await confirmLead({
        ...values,
        active: true,
      });
      getLeadListByUser({ user_id: userId });
    } catch (e) {
      console.log("e");
    }
  };

  const onDeleteLead = async (id) => {
    try {
      await deleteLead(id);
      getLeadListByUser({ user_id: userId });
    } catch (e) {
      console.log("e");
    }
  };

  const firstItem = list.length > 0 ? list[0] : null;

  return (
    <section className="sair-sec-wrapper">
      <div className="d-flex justify-content-between">
        <div>
          {firstItem && (
            <>
              <p>{`${firstItem.user.first_name} ${firstItem.user.last_name}`}</p>
              <p>{`Contact: ${firstItem.user.contact_no}`}</p>
            </>
          )}
        </div>
        <div>
          <button
            className={`btn btn-primary`}
            onClick={() => setModalVisible(true)}
          >
            Add New Product
          </button>
        </div>
        <div>
          {firstItem && (
            <a
              className={`btn btn-primary`}
              href={`/#/wholesale/partner-leads-list?user_id=${firstItem.user.id}`}
            >
              Lead
            </a>
          )}

          {firstItem && (
            <a
              className={`ml-4 btn btn-outline-secondary`}
              href={`/#/wholesale/partner-orders-list?user_id=${firstItem.user.id}`}
            >
              Old Orders
            </a>
          )}
        </div>
      </div>
      <div className="table-wrap mt-3">
        <TableWrapper
          headerDetails={Columns}
          isEmpty={list.length > 0}
          pageMeta={pageMeta}
          queryHandler={(query) => this.handleFilter(query)}
        >
          {list.map((item, index) => {
            return (
              <PartnerLeadItemRow
                key={`LEAD_${index}`}
                item={item}
                index={index}
                onItemSubmit={(values) => onUpdateProduct(values, item)}
                onDelete={() => onDeleteLead(item._id)}
              />
            );
          })}
        </TableWrapper>
      </div>
      <AddProductLead
        isOpen={isModalVisible}
        onClose={() => setModalVisible(false)}
        onLeadConfirmed={() => getLeadListByUser(userId)}
        userId={userId}
      />
    </section>
  );
};

const mapStateToProps = (state) => ({
  leadListByUser: state.wholesale.leadListByUser,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLeadListByUser,
      confirmLead,
      createLead,
      getWholesaleProductNames,
      deleteLead,
    },
    dispatch
  );
};

const actions = ["getLeadListByUser"];
const component = LoaderWrapper(PartnerLeadsList)({ actions });

export const WholesalePartnerLeadsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
