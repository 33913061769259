import { vendorType } from "../../services/actionType"


const initialState = {
	vendorLists: {},
	vendorDetails: {},
}

export default (state = Object.assign({}, initialState), { type, payload }) => {

	switch (type) {

		case vendorType.updateVendorListDetails:
			return {
				...state,
				vendorLists: payload
			}
		case vendorType.updateSingleVendorDetails:
			return {
				...state,
				vendorDetails: payload
			}

		default:
			return state

	}
}