import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import SimpleReactValidator from 'simple-react-validator';
import { DateTime, ReactSelect, NormalInput, ToggleCheckbox, ConfirmModal } from "../../component/common"
import { hidePastDate, validateEndDate, _ } from "../../services/helperFunctions"
import moment from "moment"
import {TextEditorActivity} from "../../component/common/TextEditorActivity";
import { ActivityFeatures, TravellerInformation } from "../../component/activity"
import { FileUpload } from "../../component/common/fileUpload"
import { FileVideoUpload } from "../../component/common/fileVideoUpload";
import { getActivityDetailsById, addImage, addItenaryImage, createActivity, updateActivity, createSliderActivityImage } from "../../redux/actions/activity" // getActivityDropDown
import {getPickupDropListDetails,getVehicleTypeDetails,getStayTypeDetails,getTicketNationalityTypeListDetails} from '../../redux/actions/master'

import { LoaderWrapper } from "../../HOC"

import { ReactAutoComplete } from '../../component/common';
import {filesize} from '../../config/config.js'

class CreateActivityClass extends Component {
  state = {
    activityDetails: {
      title: '',
      activity_image: '',
      activity_imageUrl: '',
      itinerary_image: '',
      itinerary_image_url: '',
      is_activity_date_single:false,
      activity_start_date: '',
      activity_end_date: '',
      activity_off_day: [],
      activity_options: [
        {
          title: '',
          price: '',
          child_price: ''
        }
      ],
      activity_details: [
        {
          icon: "",
          title: "",
          description: "",
        }
      ],
      is_activity_time_single:false,
      activity_time_slot: [
        {
          time: '',
          price: ''
        }
      ],
      way_of_reach: [
        {
          vehicle_type: '',
          description: ''
        }
      ],
      transport_options: {
        isAddon:false,
        pick_up_drop_options: [
          {
            title: "",
            vehicleTitle:"",
            price:"",
            transportPreselect:false
          }
        ]
      },
      guide_details: 
      {
        isAddon:false,
        guides:[
          {
            title: '',
            price: '',
            preselect:false
          }
        ],
      },
      stay_options: 
      {
        isAddon:false,
        stay_options:[
          {
            stayType: '',
            shareType:'',
            capacity:'',
            price: '',
            description:'',
            preselect:false
          }
        ],
      },  
      ticket_options: 
      {
        isAddon:false,
        options:[
          {
            title: "",
            adult_price: '',
            child_price: '',
            preselect: false
          }
        ],
      },
      shopping_options: 
      {
        isAddon:false,
        options:[
          {
            title: "",
            price: '',
            preselect: false
          }
        ],
      },
      food_options: 
      {
        isAddon:false,
        options:[
          {
            title: "",
            price: '',
            preselect: false
          }
        ],
      },
      ticket_count: '',
      base_price: '',
      child_price: '',
      maximum_count_a_group: '',
      latitude: "",
      longitude: "",
      includes: "",
      information: "",
      description: "",
      itinerary: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      taxi_price: '',
      slider_images: [
        {
          slider_image: "",
          slider_image_url: "",
          slider_image_position:""
        }],
        activity_date_off: [{
          date_off: '',
        }]
      },
      categoryList: [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ],
      activityIconOptions: [{
          value: 'language', label: 'Language'
        }, {
          value: 'time', label: 'Time'
        }, {
          value: 'ticket', label: 'Ticket'
        }, {
          value: 'price', label: 'Price'
        }, {
          value: 'cancel', label: 'Cancel'
      }],
      daysList: [],
      activityTimeList: [
        {
          "id": "1",
          "label": "1 hours",
          "value": "1hours"
        },
        {
          "id": "2",
          "label": "2 hours",
          "value": "2hours"
        },
        {
          "id": "3",
          "label": "3 hours",
          "value": "3hours"
        },
        {
          "id": "4",
          "label": "6 hours",
          "value": "6hours"
        },
        {
          "id": "5",
          "label": "8 hours",
          "value": "8hours"
        },
        {
          "id": "6",
          "label": "Full day (Pick from ride-sharing)",
          "value": "Fullday(Pick-from-ride-sharing)"
        }
      ],
      genderList: [
        {
          label: "Male",
          value: "male"
        },
        {
          label: "Female",
          value: "female"
        },
        {
          label: "Others",
          value: "others"
        }
      ],
      activeTimeOptions: [{
        label: 'Less than 1 hour',
        value: '1 hr'
      }, {
        label: '1-2 Hours',
        value: '1-2 hrs'
      }, {
        label: 'More than 3 hours',
        value: '3 hrs'
      }],
      bestExperienceTimeOptions: [{
        label: 'Start time',
        value: "start_time",
      }, {
        label: 'Opening hours',
        value: "opening_time",
      }, {
        label: 'Cordinates start times',
        value: "cordinates_start_times",
      }],
      isError: false,
      isOpen: false,
      isEdit: false,
      tempFile: '',
      deleteKey: '',
      ConfirmModalOpenStatus: false,
  }

  descState = {
    includes: "",
    information: "",
    description: "",
    itinerary: "",
    detail_description:[
      {
        icon: "",
        title: "",
        description: ""
      },
    ]
  }


  componentWillMount() {

    this.validator = new SimpleReactValidator({
      element: message => <span className="error-message font-md">{message}</span>,
      autoForceUpdate: this,
      messages: {
        int: 'this :attribute required here'
      },

      validators: {
        endDateRequired: { // name the rule
          message: "The :attribute must be at least 15 minutes longer than start time",
          rule: (val, params) => validateEndDate(val, params)
        },
        validDate: {
          message: "Enter a valid Date",
          rule: (val) => {
            return moment(val, 'MM/DD/YYYY hh:mm a', true).isValid() ||
              moment(val, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
              moment(val, 'YYYY-MM-DDTHH:mm:ss.000Z', true).isValid()
          }
        }

      }
    });

    this.validators = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => {
        let messages = message.split('/');
        return (
          <span className="error-message font-md">
            {`${messages[0]} ${messages[1]} ${messages[messages.length - 1]}`}
          </span>
        )
      },

    });

  }

  getDateDiffDays = (date1,date2) => {
    return moment(date1).diff(moment(date2), 'days')
  }
  componentDidMount() {
    let { daysList, activityDropDownLists: { ExperienceGroup = [], TravelEnjoyExperience = [], PhysicalRestriction = [] }, history: { location } } = this.props

    // if (location.search) {

    let activityDetails = Object.assign({}, this.state.activityDetails)

    let search = location.search.split('=')

    // let id = search[1]

    let { match: { params: { id } } } = this.props;

    if (id) {

      this.props.getActivityDetailsById(id).then((data) => {
        let {
          id,
          title,
          ActivityImage,
          ActivityDates,
          ActivityDetails,
          ActivityOptions,
          ActivityTimeSlot,
          WayOfReach,
          ticket_count,
          base_price,
          child_price,
          maximum_count_a_group,
          itinerary_image,
          itinerary_image_url,
          latitude,
          longitude,
          includes,
          information,
          description,
          meta_title,
          meta_description,
          meta_keywords,
          itinerary,
          taxi_price,
          
          // SliderImages,
          ActivitySliderImage,
          ActivityDateOff,
          ActivityTransportOptions,
          ActivityGuideDetails,
          ActivityStayOptions,
          ActivityTicketOptions,
          ActivityShoppingOptions,
          ActivityFoodOptions,
        } = data;
        
        let activity_options = [];
        let activity_details = [];
        let activity_time_slot = [];
        let way_of_reach = [];
        let transportOptions = [];
        let slider_images = [];
        let activity_date_off = [];
        let transport_options = {};
        let guide_details = {};
        let stay_options = {};
        let ticket_options = {};
        let shopping_options = {};
        let food_options = {};
        if (ActivityDateOff && ActivityDateOff.length) {

          ActivityDateOff.map((item) => {
            activity_date_off.push({
              activity_date_off_id: item.id,
              date_off: item.date_off,
              deleted: false
            })

          })

        } else {

          activity_date_off.push({
            date_off: "",
            deleted: false
          })

        }

        if (ActivitySliderImage && ActivitySliderImage.length) {
          ActivitySliderImage.map((item) => {
            if(item.slider_image != null){
              slider_images.push({
                slider_image: item.slider_image,
                slider_image_url: item.slider_image_url,
                slider_image_id: item.id,
                slider_image_position: item.slider_image_position,
                deleted: false
              })
            }
          })

        } else {

          slider_images.push({
            slider_image: '',
            slider_image_url: '',
            slider_image_position: '',
            deleted: false
          })

        }

        if (ActivityOptions) {

          ActivityOptions.map((item) => {

            activity_options.push({
              title: item.title,
              price: Number(item.price),
              child_price: Number(item.child_price),
              option_id: item.id,
              deleted: false
            })

          })

        } else {

          activity_options.push({
            title: '',
            price: '',
            child_price: '',
            deleted: false
          })

        }

        if (ActivityDetails) {

          ActivityDetails.map((item) => {

            activity_details.push({
              activity_detail_id: item.id,
              icon: item.icon,
              title: item.title,
              description: item.description,
              deleted: false
            })

          })

        } else {

          activity_details.push({
            icon: "",
            title: "",
            description: "",
            deleted: false
          })

        }
        
        if (ActivityTimeSlot) {
         
          ActivityTimeSlot.map((item) => {

            activity_time_slot.push({
              time_slot_id: item.id,
              time: item.activity_time,
              price: Number(item.add_on_price),
              deleted: false
            })

          })

        } else {

          activity_time_slot.push({
            time: "",
            price: "",
            deleted: false
          })

        }

        if (WayOfReach) {

          WayOfReach.map((item) => {

            way_of_reach.push({
              vehicle_type: item.vechile_type,
              description: item.description,
              activity_way_id: item.id,
              deleted: false
            })

          })

        } else {

          way_of_reach.push({
            vehicle_type: '',
            description: '',
            deleted: false
          })

        }
        transport_options['isAddon']=ActivityTransportOptions && ActivityTransportOptions.transport_options.isAddon ? 
        ActivityTransportOptions.transport_options.isAddon : false;
        let tOptions=[];
        if(ActivityTransportOptions && ActivityTransportOptions.transport_options.hasOwnProperty('pick_up_drop_options')){
        ActivityTransportOptions.transport_options.pick_up_drop_options.map((pick_up_drop_option) => {
          
          pick_up_drop_option.vehicles.map((item)=>{
            tOptions.push({
              "title":pick_up_drop_option.title,
              "vehicleTitle":item.title,
              "price":item.price,
              "transportPreselect":item.preselect ? item.preselect :false,
            })
          })
        });
        transport_options['pick_up_drop_options']=tOptions;
        }else{
          transport_options['pick_up_drop_options']=
            [{
              "title":"",
              "vehicleTitle":"",
              "price":"",
              "transportPreselect":false,
            }]
        }
        transport_options['id']=ActivityTransportOptions && ActivityTransportOptions.id ? ActivityTransportOptions.id : "";
         if(ActivityGuideDetails && ActivityGuideDetails.guide_details && Object.keys(ActivityGuideDetails.guide_details).length > 0 ){
          guide_details=ActivityGuideDetails.guide_details;
          guide_details && guide_details.guides && guide_details.guides.map((item)=>{
            return item.preselect ?  item.preselect : false
          });
        }else{
          guide_details['guides']=[{
            "title":"",
            "price":"",
            "preselect":false
          }]
          guide_details['isAddon']=false;
        }
        guide_details['id']=ActivityGuideDetails && ActivityGuideDetails.id ? ActivityGuideDetails.id : "";
        
        stay_options['isAddon']=ActivityStayOptions && ActivityStayOptions.stay_options && ActivityStayOptions.stay_options.isAddon ? 
        ActivityStayOptions.stay_options.isAddon : false;
        if(ActivityStayOptions && ActivityStayOptions.stay_options && Object.keys(ActivityStayOptions.stay_options).length > 0){
          stay_options=ActivityStayOptions.stay_options;
          stay_options.stay_options && stay_options.stay_options.length > 0 && stay_options.stay_options.map((item)=>{
            return item.preselect ?  item.preselect : false
          });
        }else{
          stay_options['stay_options']=[{
            stayType: '',
            shareType:'',
            capacity:'',
            price: '',
            description:'',
            preselect:false
          }]
          stay_options['isAddon']=false;
        }
        stay_options['id']=ActivityStayOptions && ActivityStayOptions.id ? ActivityStayOptions.id : "";
        
        if(ActivityTicketOptions && ActivityTicketOptions.ticket_options && Object.keys(ActivityTicketOptions.ticket_options).length > 0){
          ticket_options=ActivityTicketOptions.ticket_options;
          ticket_options.options && ticket_options.options.length > 0 && ticket_options.options.map((item)=>{
            return item.preselect ?  item.preselect : false
          });
        }else{
          ticket_options['options']=[{
            "title":"",
            "adult_price":"",
            "chile_price":"",
            "preselect":false
          }]
          ticket_options['isAddon']=false;
        }
        ticket_options['id']=ActivityTicketOptions && ActivityTicketOptions.id ? ActivityTicketOptions.id : "";
        
        if(ActivityShoppingOptions && ActivityShoppingOptions.shopping_options && Object.keys(ActivityShoppingOptions.shopping_options).length >0){
          shopping_options=ActivityShoppingOptions.shopping_options;
          shopping_options.options && shopping_options.options.length > 0 && shopping_options.options.map((item)=>{
            return item.preselect ?  item.preselect : false
          });
        }else{
          shopping_options['options']=[{
            "title":"",
            "price":"",
            "preselect":false
          }]
          shopping_options['isAddon']=false;
        }
        shopping_options['id']=ActivityShoppingOptions && ActivityShoppingOptions.id ? ActivityShoppingOptions.id : "";
       
        if(ActivityFoodOptions && ActivityFoodOptions.food_options && Object.keys(ActivityFoodOptions.food_options).length > 0){
          food_options=ActivityFoodOptions.food_options;
          food_options.options && food_options.options.length > 0 && food_options.options.map((item)=>{
            return item.preselect ?  item.preselect : false
          });
        }else{
          food_options['options']=[{
            "title":"",
            "price":"",
            "preselect":false
          }]
          food_options['isAddon']=false;
        }
        food_options['id']=ActivityFoodOptions && ActivityFoodOptions.id ? ActivityFoodOptions.id : "";
        //Get activity has srart and end date same (if same means single day activity or fixed departure)
        let diffDays=this.getDateDiffDays(ActivityDates.start_date,ActivityDates.end_date)
        activityDetails = {
          // id,
          title,
          activity_image: ActivityImage.image,
          activity_imageUrl: ActivityImage.imageUrl,
          activity_image_id: ActivityImage.id,
          activity_date_id: ActivityDates.id,
          is_activity_date_single:diffDays === 0 ? true : false, //Hide activity end date as difference in days is 0
          activity_start_date: ActivityDates.start_date,
          activity_end_date: ActivityDates.end_date,
          activity_off_day: ActivityDates.offDay,
          activity_options,
          activity_details,
          is_activity_time_single: (activity_time_slot.length === 1 &&  Number(activity_time_slot[0].price) === 0) ? true : false,
          activity_time_slot,
          way_of_reach,
          transportOptions,
          ticket_count,
          base_price: Number(base_price),
          child_price: Number(child_price),
          maximum_count_a_group,
          itinerary_image,
          itinerary_image_url,
          latitude: latitude.toString(),
          longitude: longitude.toString(),
          includes,
          information,
          description,
          meta_title,
          meta_description,
          meta_keywords,
          itinerary,
          taxi_price: Number(taxi_price),
          slider_images,
          activity_date_off,
          transport_options,
          guide_details,
          stay_options,
          ticket_options,
          shopping_options,
          food_options
        }
        this.setState({
          activityDetails
        })

      })

    }
    this.setState({
      daysList,
    })
  }

  addItenaryImage = (key, event) => {
    let files = event && event.target && event.target.files[0]

    let activityDetails = Object.assign({}, this.state.activityDetails)

    activityDetails[key] = files

    let formData = new FormData()

    formData.append("itinerary_image", files)

    this.props.addItenaryImage(formData).then((data) => {
      activityDetails[key] = data.itinerary_image

      activityDetails.itinerary_image_url = data.itinerary_image_url
      this.setState({
        tempFile: URL.createObjectURL(files),
        activityDetails,
      })
    })
  }
  ImagePreview = (key, event) => {

    let files = event.target.files[0]

    let activityDetails = Object.assign({}, this.state.activityDetails)

    activityDetails[key] = files

    let formData = new FormData()

    formData.append("activityImage", files)

    this.props.addImage(formData).then((data) => {

      activityDetails[key] = data.image

      activityDetails.activity_imageUrl = data.imageUrl


    })

    this.setState({
      tempFile: URL.createObjectURL(files),
      activityDetails,
    });

  }

  handleDeleteToggle = (key, index) => {
    let { activityDetails } = Object.assign({}, this.state)
    if (activityDetails[key].length) {
      activityDetails[key].map((x, i) => {
        if (i === index) {
          x.deleted = true
        }
      })
    }
    this.setState({
      activityDetails
    })
  }

  handleDelete = () => {
    let { activityDetails, deleteKey } = Object.assign({}, this.state)
    activityDetails[deleteKey] = ""
    this.setState({
      activityDetails
    })
    this.toggleDelete("ConfirmModalOpenStatus")
  }

  toggleDelete = (name) => {
    this.setState({
      [name]: !this.state[name]
    })
  }


  handleDescriptionInputs = ({value, name}) => {
    this.descState[name] = value;
  }

  handleInputs = ({ value, name }) => {
    if (name === 'activity_off_day' && value === null) {
      value = []
    }
    let activityDetails = Object.assign({}, this.state.activityDetails)

    if (name === "base_price" || name === "child_price" || name === "maximum_count_a_group") {
      activityDetails[name] = value === "" ? "" : Number(value)
    }
    else {
        activityDetails[name] = value;
        activityDetails["description"] = activityDetails["description"].length<this.descState.description.length ? this.descState.description : activityDetails["description"];
        activityDetails["includes"] = activityDetails["includes"].length<this.descState.includes.length ? this.descState.includes : activityDetails["includes"];
        activityDetails["information"] = activityDetails["information"].length<this.descState.information.length ? this.descState.information : activityDetails["information"];
        activityDetails["itinerary"] = activityDetails["itinerary"].length<this.descState.itinerary.length ? this.descState.itinerary : activityDetails["itinerary"];
      
    }
    this.setState({
      activityDetails
    })
  }

  handlePluginInput = (keyName, data) => {
    let activityDetails = Object.assign({}, this.state.activityDetails)
    activityDetails[keyName] = data
    this.setState({
      activityDetails
    })
  }
  handleMultipleInputs = (key, data, index) => {
    let { name, value } = data
    
    let activityDetails = Object.assign({}, this.state.activityDetails)

    if (name === "price") {
      if(key === "guide_details"){
        activityDetails[key]['guides'][index][name] = value === "" ? "" : Number(value)
      }else{
        activityDetails[key][index][name] = value === "" ? "" : Number(value)
      }
    } else {
      if(key === "guide_details"){
        activityDetails[key]['guides'][index][name] = value
      }else{
        activityDetails[key][index][name] = value
      }
    }
    this.setState({
      activityDetails
    })
  }
  handleSubmit = () => {
    
    if (this.validator.allValid() && this.validators.allValid()) {
      this.setState({disabled:true})
      let activityDetails = Object.assign({}, this.state.activityDetails)
      
      if(this.descState.includes != ""){
        activityDetails.includes = this.descState.includes;
      }

      if(this.descState.information != ""){
        activityDetails.information = this.descState.information;
      }

      if(this.descState.description != ""){
        activityDetails.description = this.descState.description;
      }
      if(this.descState.itinerary != ""){
        activityDetails.itinerary = this.descState.itinerary;
      }

      let activityDetails_length = this.descState.detail_description.length;
      for(let ind=0; ind< activityDetails_length;ind++)
      {
        if(this.descState.detail_description[ind].description != "")
        {
          activityDetails.activity_details[ind].description = this.descState.detail_description[ind].description;
        }
      }

      if (activityDetails.activity_date_off && activityDetails.activity_date_off.length) {
        if (activityDetails.activity_date_off[0].date_off != '') {
          activityDetails.activity_date_off.map(x => {
            if (x.date_off != '') {
              x.date_off = moment(x.date_off).format('YYYY-MM-DD')
            }
          })
        } else {
          activityDetails.activity_date_off = []
        }
      }
      if(activityDetails.is_activity_date_single){
        activityDetails.activity_start_date = moment(activityDetails.activity_start_date).format('YYYY-MM-DD')
        activityDetails.activity_end_date = activityDetails.activity_start_date
      }else{
        activityDetails.activity_start_date = moment(activityDetails.activity_start_date).format('YYYY-MM-DD')
        activityDetails.activity_end_date = moment(activityDetails.activity_end_date).format('YYYY-MM-DD')
      }
      if(activityDetails.is_activity_time_single){
        if(activityDetails.activity_time_slot && activityDetails.activity_time_slot.length > 0){
         activityDetails.activity_time_slot[0].price=0
        }
      }
      let data=_.groupBy(activityDetails.transport_options.pick_up_drop_options, "title");
      let transportOptionsArr=[];
      transportOptionsArr=Object.entries(data).map((value)=>{
      let vehicleArr=value[1].map((item)=>{
        return {"title":item.vehicleTitle,"price":item.price,"preselect":item.transportPreselect}
      })
      return {"title":value[0],"vehicles":vehicleArr}
     })
     
      activityDetails.transport_options.pick_up_drop_options=transportOptionsArr;
      if(activityDetails.guide_details.guides[0].title==""){
        activityDetails.guide_details={id:_.get(this.state,'activityDetails.guide_details.id')}
      }
      if(activityDetails.transport_options.pick_up_drop_options.length > 0 && activityDetails.transport_options.pick_up_drop_options[0].title==""){
        activityDetails.transport_options={id:_.get(this.state,'activityDetails.transport_options.id')}
      }
      if(activityDetails.stay_options.isAddon===false && activityDetails.stay_options.stay_options[0].description==""){
        activityDetails.stay_options={id:_.get(this.state,'activityDetails.stay_options.id')}
      }
      if( activityDetails.ticket_options.options[0].title==""){
        activityDetails.ticket_options={id:_.get(this.state,'activityDetails.ticket_options.id')}
      }
      if(activityDetails.shopping_options.options[0].title==""){
        activityDetails.shopping_options={id:_.get(this.state,'activityDetails.shopping_options.id')}
      }
      if(activityDetails.food_options.options[0].title==""){
        activityDetails.food_options={id:_.get(this.state,'activityDetails.food_options.id')}
      }
      let { match: { params: { id } } } = this.props;
      if (!id) {

        let body = {
          ...activityDetails
        }
        this.props.createActivity(body).then((data) => {
          let { history } = this.props
          this.setState({disabled:false})
          history.push('/activity/list')
        }).catch((ex) => {
          this.setState({disabled:false})
        })

      } else {

        let {
          title,
          ticket_count,
          base_price,
          maximum_count_a_group,
          child_price,
          offer_percentage,
          includes,
          description,
          information,
          itinerary,
          latitude,
          longitude,
          itinerary_image,
          itinerary_image_url,
          meta_title,
          meta_description,
          meta_keywords,
          taxi_price,
          activity_image,
          activity_imageUrl,
          activity_image_id,
          activity_options,
          activity_time_slot,
          activity_date_id,
          activity_start_date,
          activity_end_date,
          activity_off_day,
          activity_details,
          way_of_reach,
          transport_options,
          guide_details,
          stay_options,
          ticket_options,
          shopping_options,
          food_options,
          slider_images,
          activity_date_off,
        } = { ...activityDetails };
        if (way_of_reach && way_of_reach.length) {
          way_of_reach = way_of_reach.map((x, i) => {
            let data = { ...x }
            data.vechile_type = data.vehicle_type
            delete data.vehicle_type
            return data
          })
        }

        // delete way_of_reach.vehicle_type
        let body = {
          activity: {
            title,
            ticket_count,
            base_price,
            maximum_count_a_group,
            description,
            child_price,
            offer_percentage,
            includes,
            information,
            itinerary_image,
            itinerary_image_url,
            itinerary,
            latitude,
            longitude,
            meta_title,
            meta_description,
            meta_keywords,
            taxi_price,
          },
          activity_date_off,
          activity_image: {
            activity_image,
            activity_imageUrl,
            activity_image_id,
          },
          activity_option: activity_options,
          activity_time_slot,
          activity_date: {
            activity_date_id,
            activity_start_date,
            activity_end_date,
            activity_off_day,
          },
          activity_details,
          way_of_reach,
          transport_options,
          guide_details,
          ticket_options,
          slider_images,
          stay_options,
          shopping_options,
          food_options
        }
        this.props.updateActivity(id, body).then((data) => {

          let { history } = this.props
          history.push('/activity/list')
          this.setState({disabled:false})
        }).catch((ex)=>{
          this.setState({disabled:false})
        })

      }

      this.validator.hideMessages();

      this.validators.hideMessages();
    } else {

      this.setState({
        isError: true
      })
      this.validator.showMessages();
      this.validators.showMessages();

    }
  }

  handleAddMultiple = (key) => {

    let activityDetails = Object.assign({}, this.state.activityDetails)

    if (key == "activity_options") {

      let activity_options = activityDetails[key].slice(0)

      let errorStatus = activity_options.every((e, index) => {

        let err1 = this.validators.fieldValid(`/title/${index}/`)
        let error = this.validators.fieldValid(`/price/${index}/`)
        if (!err1 && !error) {
          this.validators.showMessageFor(`/title/${index}/`)
          this.validators.showMessageFor(`/price/${index}/`)
          return false
        }

        return true

      })

      if (errorStatus) {
        activityDetails[key].push({
          title: '',
          price: '',
          child_price: ''
        })
      }



    } else if (key == "activity_time_slot") {

      let activity_time_slot = activityDetails[key].slice(0)

      let errorStatus = activity_time_slot.every((e, index) => {

        let err1 = this.validators.fieldValid(`/time/${index}/`)

        let error = this.validators.fieldValid(`/activity_time_price/${index}/`)

        if (!err1 && !error) {
          this.validators.showMessageFor(`/time/${index}/`)
          this.validators.showMessageFor(`/activity_time_price/${index}/`)
          return false
        }

        return true

      })

      if (errorStatus) {
        activityDetails[key].push({
          time: '',
          price: ''
        })

      }

    } else if (key == "way_of_reach") {

      let way_of_reach = activityDetails[key].slice(0)

      let errorStatus = way_of_reach.every((e, index) => {

        let err1 = this.validators.fieldValid(`/vehicle_type/${index}/`)

        let error = this.validators.fieldValid(`/description/${index}/`)

        if (!err1 && !error) {
          this.validators.showMessageFor(`/vehicle_type/${index}/`)
          this.validators.showMessageFor(`/description/${index}/`)
          return false
        }

        return true

      })

      if (errorStatus) {
        activityDetails[key].push({
          vehicle_type: '',
          description: ''
        })

      }

    } else if (key == "activity_date_off") {

      let activity_date_off = activityDetails[key]


      if (activityDetails[key][(activityDetails[key].length - 1)].date_off === "") {
        alert('please add the data')
      } else {

        activityDetails[key].push({
          date_off: '',
        })

      }
    }

    this.setState({
      activityDetails
    })

  }




  handleDataObject = (key, data) => {

    let { activityDetails } = this.state

    activityDetails = Object.assign({}, activityDetails)

    activityDetails[key] = data

    this.setState({ activityDetails })

  }


  handleCheckbox = ({ target: { value, checked } }) => {
    value = parseInt(value)
    let { activityDetails } = Object.assign({}, this.state)
    this.setState({ activityDetails })
  }

  handleArrayInputs = (name, value, index) => {
    let activityDetails = Object.assign({}, this.state.activityDetails)
    activityDetails[name][index] = value
    this.setState({
      activityDetails
    })
  }

  handleToggleCheckbox = (key,index) => {
    let activityDetails = Object.assign({}, this.state.activityDetails)
    if (key === "isAddon") {
      activityDetails.guide_details.isAddon= !activityDetails.guide_details.isAddon
    }
    if (key === "transportIsAddon") {
      activityDetails.transport_options.isAddon= !activityDetails.transport_options.isAddon
    }
    if (key === "stayOptionIsAddon") {
      activityDetails.stay_options.isAddon= !activityDetails.stay_options.isAddon
    }
    if (key === "ticketOptionIsAddon") {
      activityDetails.ticket_options.isAddon= !activityDetails.ticket_options.isAddon
    }
    if (key === "shoppingOptionIsAddon") {
      activityDetails.shopping_options.isAddon= !activityDetails.shopping_options.isAddon
    }
    if (key === "foodOptionIsAddon") {
      activityDetails.food_options.isAddon= !activityDetails.food_options.isAddon
    }
    //Activity has only single date 
    if(key === "is_activity_date_single"){
      activityDetails.is_activity_date_single = !activityDetails.is_activity_date_single 
    }
    //Activity has only single time slot 
    if(key === "is_activity_time_single"){
      activityDetails.is_activity_time_single = !activityDetails.is_activity_time_single 
    }
    if(key === "preselect") {
      let guides=activityDetails.guide_details.guides.map((guide,i)=>{
        if(i===index){
        guide.preselect=!guide.preselect
          return guide;
        }else{
          return guide;
        }
      })
      activityDetails.guide_details.guides=guides
    }
    if (key === "transportPreselect") {
      let options=activityDetails.transport_options.pick_up_drop_options.map((pickDropOption,i)=>{
        if(i===index){
          pickDropOption.transportPreselect=!pickDropOption.transportPreselect
          return pickDropOption;
        }else{
          return pickDropOption;
        }
      })
      activityDetails.transport_options.pick_up_drop_options=options
    }
    if (key === "stayOptionPreselect") {
      let options=activityDetails.stay_options.stay_options.map((stayOption,i)=>{
        if(i===index){
          stayOption.preselect=!stayOption.preselect
          return stayOption;
        }else{
          return stayOption;
        }
      })
      activityDetails.stay_options.stay_options=options
    }
    if (key === "ticketOptionPreselect") {
      let options=activityDetails.ticket_options.options.map((ticketOption,i)=>{
        if(i===index){
          ticketOption.preselect=!ticketOption.preselect
          return ticketOption;
        }else{
          return ticketOption;
        }
      })
      activityDetails.ticket_options.options=options
    }
    if (key === "shoppingOptionPreselect") {
      let options=activityDetails.shopping_options.options.map((shoppingOption,i)=>{
        if(i===index){
          shoppingOption.preselect=!shoppingOption.preselect
          return shoppingOption;
        }else{
          return shoppingOption;
        }
      })
      activityDetails.shopping_options.options=options
    }
    if (key === "foodOptionPreselect") {
      let options=activityDetails.food_options.options.map((foodOption,i)=>{
        if(i===index){
          foodOption.preselect=!foodOption.preselect
          return foodOption;
        }else{
          return foodOption;
        }
      })
      activityDetails.food_options.options=options
    }
    this.setState({
      activityDetails
    })
  }

  handleActivityDetails = (index, { name, value }) => {
    let activityDetails = Object.assign({}, this.state.activityDetails)
    activityDetails.activity_details[index][name] = value;
    this.setState({
      activityDetails
    })

    
  }
  handleActivityDetailsDescription = (index, {value, name}) => {

    let activityDetails = Object.assign({}, this.state.activityDetails)
    activityDetails.activity_details[index][name] = value;
    this.descState.detail_description = activityDetails.activity_details;
    
  }

  handleAddActivityDetails = () => {
    let activityDetails = Object.assign({}, this.state.activityDetails)
    let activityDetailsLength = activityDetails.activity_details.length;
    if (activityDetailsLength < 5) {
        let errorStatus = activityDetails.activity_details.every((e, index) => {
        let error1 = this.validators.fieldValid(`/title/${index}/`)
        let error2 = this.validators.fieldValid(`/icon/${index}/`)
        let error3 = this.validators.fieldValid(`/description/${index}/`)
        if (!error1 && !error2 && !error3) {
          this.validators.showMessageFor(`/title/${index}/`)
          this.validators.showMessageFor(`/icon/${index}/`)
          this.validators.showMessageFor(`/description/${index}/`)
          return false
        }
        return true
      })

      if (errorStatus) {
        activityDetails.activity_details.push({
          icon: '',
          title: '',
          description: '',
        });
      }

    }

    this.setState({
      activityDetails
    })

  }

  handleDeleteActivityDetails = (index) => {
    let activityDetails = Object.assign({}, this.state.activityDetails)
    activityDetails.activity_details.map((x, i) => {
      if (index === i) {
        x.deleted = true
      }
    })
    this.setState({
      activityDetails
    })
  }
///Handle Transport Options
handleTransportOptions = (index, { name, value }) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.transport_options.pick_up_drop_options[index][name] = value;
  this.setState({
    activityDetails
  })
}
handleAddTransportOptions = () => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  let transportOptionsLength = activityDetails.transport_options.pick_up_drop_options.length;
  if (transportOptionsLength < 10) {
     activityDetails.transport_options.pick_up_drop_options.push({
      title: '',
      vehicleTitle: '',
      price: '',
      transportPreselect:false
    });
  }
  this.setState({
    activityDetails
  })
}

handleDeleteTransportOptions = (index) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.transport_options.pick_up_drop_options.splice(index, 1);
  this.setState({
    activityDetails
  })
}
//Handle Transport option ends here

//Handle Guide Options
handleGuideOptions = (index, { name, value }) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.guide_details.guides[index][name] = value;
  this.setState({
    activityDetails
  })
}
handleAddGuideOptions = () => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  let guidesLength = activityDetails.guide_details.guides.length;
  if (guidesLength < 10) {
      activityDetails.guide_details.guides.push({
        title: '',
        price: ''
      });
  }
  this.setState({
    activityDetails
  })
}
handleDeleteGuideOptions = (index) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.guide_details.guides.splice(index, 1);
  this.setState({
    activityDetails
  })
}
//Handle ends Guide Options

//Handle Stay Options
handleStayOptions = (index, { name, value }) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.stay_options.stay_options[index][name] = value;
  this.setState({
    activityDetails
  })
}
handleAddStayOptions = () => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  let stayOptionsLength = activityDetails.stay_options.stay_options.length;
  if (stayOptionsLength < 10) {
    activityDetails.stay_options.stay_options.push({
      stayType: '',
      shareType:'',
      description:'',
      capacity: '',
      price: '',
      stayPreselect:false
    });
  }
  this.setState({
  activityDetails
  })
}

handleDeleteStayOptions = (index) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.stay_options.stay_options.splice(index, 1);
  this.setState({
    activityDetails
  })
}
//Handle Stay option ends here

//Handle Ticket Options
handleTicketOptions = (index, { name, value }) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.ticket_options.options[index][name] = value;
  this.setState({
    activityDetails
  })
}

handleAddTicketOptions = () => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  let ticketsOptionsLength = activityDetails.ticket_options.options.length;
    if (ticketsOptionsLength < 10) {
      activityDetails.ticket_options.options.push({
        title: '',
        adult_price: '',
        child_price: '',
        preselect:false
      });
    }
  this.setState({
    activityDetails
  })
}

handleDeleteTicketOptions = (index) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.ticket_options.options.splice(index, 1);
  this.setState({
    activityDetails
  })
}
//Handle ends ticket Options

//Handle Shopping Options
handleShoppingOptions = (index, { name, value }) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.shopping_options.options[index][name] = value;
  this.setState({
    activityDetails
  })
}
handleAddShoppingOptions = () => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  let shoppingOptionsLength = activityDetails.shopping_options.options.length;
  if(shoppingOptionsLength < 10) {
    activityDetails.shopping_options.options.push({
      title: '',
      price:'',
      preselect:false
    });
  }
  this.setState({
    activityDetails
  })
}

handleDeleteShoppingOptions = (index) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.shopping_options.options.splice(index, 1);
  this.setState({
    activityDetails
  })
}
//Handle ends Shopping Options

//Handle food Options
handleFoodOptions = (index, { name, value }) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.food_options.options[index][name] = value;
  this.setState({
    activityDetails
  })
}
handleAddFoodOptions = () => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  let foodOptionsLength = activityDetails.food_options.options.length;
  if(foodOptionsLength < 10) {
    activityDetails.food_options.options.push({
      title: '',
      price:'',
      preselect:false
    });
  }
  this.setState({
    activityDetails
  })
}

handleDeleteFoodOptions = (index) => {
  let activityDetails = Object.assign({}, this.state.activityDetails)
  activityDetails.food_options.options.splice(index, 1);
  this.setState({
    activityDetails
  })
}
//Handle ends food Options

  handleLocation = (place) => {

    let { geometry = {} } = place;

    let { location } = geometry;

    let activityDetails = Object.assign({}, this.state.activityDetails)

    if (location) {

      activityDetails.latitude = location.lat().toString();

      activityDetails.longitude = location.lng().toString();

    }

    this.setState({
      activityDetails
    })

  }

  handleAddSliderImage = () => {

    let activityDetails = Object.assign({}, this.state.activityDetails);

    let errorStatus = activityDetails.slider_images.every((e, index) => {

      let error1 = this.validators.fieldValid(`/SliderImage/${index}/`)

      if (!error1) {

        this.validators.showMessageFor(`/SliderImage/${index}/`)

        return false

      }

      return true

    })

    if (errorStatus) {

      activityDetails.slider_images.push({
        slider_image: "",
        slider_image_url: ""
      })

      this.setState({
        activityDetails,
      })

    }

  }

  handleSliderImage = (index, event) => {

    let files = event.target.files[0]

    let activityDetails = _.cloneDeep(this.state.activityDetails)

    let formData = new FormData()

    formData.append("activitySliderImage", files)
    let extension = files.name.split('.').pop();

        let supported_extensions = ["webp", "jpg", "jpeg", "png", "mp4"];
        let file_size = files.size;
      // Allowed video size 20MB and image is 3MB
        let size_obj = filesize;
        if (supported_extensions.includes(extension)) {
            if (extension == "mp4" && file_size <= size_obj['mp4'] || (file_size <= size_obj['other'])) {
                
                this.props.createSliderActivityImage(formData).then(({ slider_image, slider_image_url }) => {
                    
                    activityDetails.slider_images[index].slider_image = slider_image;
                    activityDetails.slider_images[index].slider_image_url = slider_image_url;
                    activityDetails.slider_images[index].slider_image_position = "";
                    
                    this.setState({
                        activityDetails
                    })  
                })   
            }
            else{
                alert('Allowed file size for image is 3MB and for video is 20MB');
            }
        }else {
            alert("Allowed file formats: mp4 jpg jpeg png webp");
        }   
    };
    
   handleSliderImagePosition= (index, value) => {
        let activityDetails = _.cloneDeep(this.state.activityDetails)       
        activityDetails.slider_images[index].slider_image_position = value;
        
        this.setState({
            activityDetails
        })
    }

  handleMultiplePluginInput = (name, index, date) => {
    let activityDetails = Object.assign({}, this.state.activityDetails);

    activityDetails[name][index].date_off = date;

    this.setState({
      activityDetails
    })

  }

  render() {
    let pickupDropDataLists =_.filter(this.props.pickupDropLocationLists,(pickupDropLocation)=>{
      return pickupDropLocation.status===1;
    })
    let vehicleTypeDataLists =_.filter(this.props.vehicleTypeLists,(vehicleType)=>{
      return vehicleType.status===1;
    })
    let pickupDropLists = pickupDropDataLists.map(data=>{
      return {"value":data.title,"label":data.title}
    })
    pickupDropLists.unshift({"value":"",label:"Select"});
    let vehicleTypeLists = vehicleTypeDataLists.map(data=>{
      return {"value":data.title,"label":data.title}
    })
    vehicleTypeLists.unshift({"value":"",label:"Select"});
    let stayTypeLists=this.props.stayTypeLists.map(data=>{
      return {"value":data.title,"label":data.title}
    })
    stayTypeLists.unshift({"value":"",label:"Select"});
    let sharedTypeList =[
      {"value":"",label:"Select"},
      {
      "value":"Private","label":"Private"
    },{
      "value":"Shared","label":"Shared"
    }];
    let ticketTypeList=this.props.ticketTypeList && this.props.ticketTypeList.length > 0 && this.props.ticketTypeList.map((data)=>{
      return {"value":data.title,"label":data.title}
    })
    ticketTypeList.unshift({"value":"",label:"Select"})
    let {
      bestExperienceTimeOptions,
      activeTimeOptions,
      activityDetails,
      daysList,
      activityTimeList,
      genderList,
      tempFile,
      ConfirmModalOpenStatus,
      activityIconOptions,
    } = this.state



    let {
      title,
      activity_image,
      activity_start_date,
      is_activity_date_single,
      activity_end_date,
      itinerary_image,
      itinerary_image_url,
      activity_off_day,
      activity_options,
      is_activity_time_single,
      activity_time_slot,
      way_of_reach,
      transport_options,
      guide_details,
      stay_options,
      ticket_options,
      shopping_options,
      food_options,
      ticket_count,
      base_price,
      child_price,
      maximum_count_a_group,
      activity_details,
      includes,
      information,
      description,
      itinerary,
      latitude,
      longitude,
      meta_title,
      meta_description,
      meta_keywords,
      taxi_price,
      slider_images,
      activity_date_off,
      activity_imageUrl,
    } = activityDetails
    return (
      <div>
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Activity
					</h4>

        </div>
        <section className="sair-sec-wrapper">

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Activity Title</label>
              <NormalInput
                className="form-control"
                name="title"
                type="text"
                value={title}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Activity title', title, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label >Activity Images</label>
              <FileUpload
                fileUpload={this.fileUpload}
                ImagePreview={(e) => this.ImagePreview("activity_image", e)}
                filename={activity_image}
                tempFile={tempFile}
              />
              <img src={activity_imageUrl} style={{ width: "100px", marginTop: "15px" }} />
              {this.validator.message('image', activity_image, 'required')}
            </div>
            <div className="col-md-12 form-group">
              <label >Itenary Images</label>
              <FileUpload
                fileUpload={this.fileUpload}
                ImagePreview={(e) => this.addItenaryImage("itinerary_image", e)}
                filename={itinerary_image}
                tempFile={tempFile}
              />
              <img src={itinerary_image_url} style={{ width: "100px", marginTop: "15px" }} />   
            </div>
            <div className="col-md-6 form-group">
              <label  >Latitude</label>
              <NormalInput
                className="form-control"
                name="latitude"
                type="number"
                value={latitude}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Latitude', latitude, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label  >Longitude</label>
              <NormalInput
                className="form-control"
                name="longitude"
                type="number"
                value={longitude}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Longitude', longitude, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label  >Activity Description</label>


              <TextEditorActivity
                handleTextEditor={html => {
                  this.handleDescriptionInputs({ name: 'description', value: html })
                }}
                handleTextEditorInput={html => {
                  this.handleInputs({ name: 'description', value: html })
                }}
                html={description}
                disabled={false}
              />
              {this.validator.message('activity description', description, 'required')}
            </div>

            <div className="col-md-6 form-group">
              <label  >Includes</label>
              <TextEditorActivity
                handleTextEditor={html => {
                  this.handleDescriptionInputs({ name: 'includes', value: html })
                }}
                handleTextEditorInput={html => {
                  this.handleInputs({ name: 'includes', value: html })
                }}
                html={includes}
                disabled={false}
              />
              {this.validator.message('includes', includes, 'required')}
            </div>
            <div className="col-md-6 form-group">
              <label  >Information</label>
              <TextEditorActivity
                handleTextEditor={html => {
                  this.handleDescriptionInputs({ name: 'information', value: html })
                }}
                handleTextEditorInput={html => {
                  this.handleInputs({ name: 'information', value: html })
                }}
                html={information}
                disabled={false}
              />
              {this.validator.message('information', information, 'required')}
            </div>
            <div className="col-md-6 form-group">
              <label  >Itinerary</label>
              <TextEditorActivity
                handleTextEditor={html => {
                  this.handleDescriptionInputs({ name: 'itinerary', value: html })
                }}
                handleTextEditorInput={html => {
                  this.handleInputs({ name: 'itinerary', value: html })
                }}
                html={itinerary}
                disabled={false}
              />
              {this.validator.message('itinerary', itinerary, 'required')}
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-sm-12">
              <div className="row mb-15">
                <div className="col-sm-6 d-flex align-items-end">

                  <h6 className="">Slider Images</h6>
                  <div className="ml-auto">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={this.handleAddSliderImage}
                    >
                      Add Slider Image
                    </button>
                  </div>

                </div>
              </div>
              <div className="row">

                {slider_images.filter(x => !x.deleted).map((item, index) => {
                  let {
                    slider_image,
                    slider_image_url,
                    slider_image_position
                  } = item;
                  let extension = slider_image_url.split('.').pop();
                  return (
                    <div className="col-sm-6 mb-3" key={index}>
                      <div className="row">
                        <div className={index !== 0 ? "col-sm-11" : "col-sm-12"}>
                          <FileVideoUpload
                            fileUpload={this.fileUpload}
                            ImagePreview={(e) => this.handleSliderImage(index, e)}
                            filename={slider_image && slider_image.includes('https') ? slider_image : slider_image_url}
                          />
                          <div className="col-md-6">
                            {extension == "mp4" ?(<i className="fa fa-play-circle fa-play-size" ></i>) : (slider_image_url ? <img src={slider_image_url} className="img-preview" /> : "")}
                          </div>
                          <div className="col-md-6">
                            <NormalInput
                                className="form-control"
                                name="Slider Image Position"
                                type="number"
                                value={slider_image_position}
                                onChange={( { name, value } ) => {
                                    this.handleSliderImagePosition(index, value)
                                }}
                            />
                          </div>  
                        </div>
                        {index !== 0 ?
                          <div className="col-md-1 pt-2">
                            <i
                              className="icon-trash text-danger cursor-pointer"
                              onClick={e => this.handleDeleteToggle("slider_images", index)}
                            ></i>
                          </div>
                          : ""}
                      </div>
                      {item ? this.validators.message(`/SliderImage/${index}/`, slider_image_url, 'required') : ""}
                    </div>
                  )

                })}
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-sm-6">
              <h6 className="mb-15">Activity Details</h6>
            </div>
            <div className="col-sm-6 text-right">
              <button
                className="btn btn-primary btn-sm"
                onClick={this.handleAddActivityDetails}
              >
                Add Details
              </button>
            </div>
          </div>
          {activity_details.filter(x => !x.deleted).map((item, index) => {

            let {
              icon,
              title,
              description,
            } = item;

            return (

              <>
                <div className="row">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Title {index + 1}</label>
                        <NormalInput
                          className="form-control"
                          name="title"
                          type="text"
                          value={title}
                          onChange={({ name, value }) => {
                            this.handleActivityDetails(index, { name: name, value: value })
                          }}
                          validationError={this.validators.message(`/title/${index}/`, title, 'required')}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Icon {index + 1}</label>
                        <ReactSelect
                          isMulti={false}
                          options={activityIconOptions}
                          handleChange={(keyName, data) => {
                            this.handleActivityDetails(index, { name: keyName, value: data })
                          }}
                          keyName="icon"
                          value={icon}
                          disabled={false}
                        />
                        {this.validators.message(`/icon/${index}/`, icon, 'required')}
                      </div>
                      <div className="col-md-12 form-group">
                        <label>Description {index + 1}</label>
                        <TextEditorActivity
                          handleTextEditor={html => {
                            this.handleActivityDetailsDescription(index, { name: 'description', value: html })
                          }}
                          handleTextEditorInput={html => {
                            this.handleActivityDetails(index, { name: 'description', value: html })
                          }}
                          html={description}
                          disabled={false}
                        />
                        {this.validators.message(`/description/${index}/`, description, 'required')}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 pb-3 align-self-end text-center">
                    {index !== 0 ?
                      < i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={() => this.handleDeleteActivityDetails(index)}
                      ></i>
                      : ""}
                  </div>
                </div>
              </>

            )

          })}


          <hr />


          <h6 className="mb-15">Activity Date</h6>
          <label>
                <span className="mr-auto">Is activity has single date?</span>
                <ToggleCheckbox keyName={"is_activity_date_single"}
                  value={is_activity_date_single}
                  onClick={() => this.handleToggleCheckbox("is_activity_date_single")}
                />
          </label>
          <div className="row">
            <div className="col-md-6 form-group">
              <label  >Activity start date</label>
              <DateTime
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                name="activity_start_date"
                value={activity_start_date}
                isValidDate={hidePastDate}
              />
              {this.validator.message('Activity start date', activity_start_date, 'required')}
            </div>
            <div className="col-md-6 form-group">
              {!activityDetails.is_activity_date_single &&
                <>
                  <label  >Activity End date</label>
                  <DateTime
                    handleChange={this.handlePluginInput}
                    name="activity_end_date"
                    value={activity_end_date}
                    isValidDate={hidePastDate}
                  />
                  {
                    activityDetails.is_activity_date_single && 
                    this.validator.message('Activity End date', activity_end_date, 'required')
                  }
                </>
              }
            </div>
          </div>

          <hr />

          <h6 className="mb-15">Activity Off day</h6>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Days</label>
              <ReactSelect
                isMulti={true}
                options={daysList}
                handleChange={(keyName, data) => {
                  this.handleInputs({ name: keyName, value: data })
                }}
                keyName="activity_off_day"
                value={activity_off_day}
                disabled={false}
              />
            </div>
          </div>

          <hr />

          <div>
            <h6 className="mb-15 row">
              <div className="col-sm-6">
                <div className="row align-items-center">
                  <div className="col-sm-6">Activity Off date</div>
                  <div className="col-sm-6 text-right">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.handleAddMultiple('activity_date_off')}
                    >
                      Add options
                    </button>
                  </div>
                </div>
              </div>
            </h6>
            <div className="row">
              {activity_date_off.filter(x => !x.deleted).map((item, index) => {

                let { date_off } = item;


                return (

                  <div key={index} className="col-sm-6 mb-3">

                    <div className="row">

                      <div className={index === 0 ? "col-sm-12" : "col-sm-11"}>

                        <DateTime
                          handleChange={(name, date) => this.handleMultiplePluginInput("activity_date_off", index, date)}
                          name="date_off"
                          value={date_off}
                          isValidDate={hidePastDate}
                        />

                      </div>

                      {index > 0 &&

                        <div className="col-md-1 align-self-center">
                          <i
                            className="icon-trash text-danger cursor-pointer"
                            onClick={e => this.handleDeleteToggle("activity_date_off", index)}
                          ></i>
                        </div>

                      }

                    </div>

                  </div>

                )

              })}
            </div>

          </div>


          <hr />
          <h6 className="mb-15 row">
            <div className="col-sm-6"> Activity Options </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddMultiple('activity_options')}
              >
                Add options
              </button>
            </div>
          </h6>
          {activity_options.filter(x => !x.deleted).map((item, index) => {

            let { title, price, child_price } = item

            return (
              <>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label> Option {index + 1 } </label>
                    <NormalInput
                      className="form-control"
                      type="text"
                      name="title"
                      value={title}
                      onChange={(data) => {
                        this.handleMultipleInputs("activity_options", data, index)
                      }}
                      number={false}
                    />
                    {this.validators.message(`/title/${index}/`, title, 'required')}

                  </div>
                  <div className="col-md-3 form-group">
                    <label >{index==0 ? 'Price': 'Price'+index}</label>
                    <NormalInput
                      className="form-control"
                      type="number"
                      name="price"
                      value={price}
                      onChange={(data) => {
                        this.handleMultipleInputs("activity_options", data, index)
                      }}
                    />
                    {this.validators.message(`/price/${index}/`, price, 'required')}
                  </div>
                  <div className="col-md-2 form-group">
                    <label >{index==0 ? 'Child Price': 'Child Price'+index}</label>
                    <NormalInput
                      className="form-control"
                      type="number"
                      name="child_price"
                      value={child_price}
                      onChange={(data) => {
                        this.handleMultipleInputs("activity_options", data, index)
                      }}
                    />
                  </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteToggle("activity_options", index)}
                      ></i>
                    </div>
                  }
                </div>

              </>
            )

          })}
          <hr />

          <h6 className="mb-15 row">
            <div className="col-sm-6"> 
            <h6 class="">Activity time slot</h6>
              <label>
                  <span className="mr-auto">Is activity has single time slot?</span>
                  <ToggleCheckbox keyName={"is_activity_time_single"}
                    value={is_activity_time_single}
                    onClick={() => this.handleToggleCheckbox("is_activity_time_single")}
                  />
              </label>
            </div>
            <div className="col-sm-6 text-right">
              {!is_activity_time_single && 
                <button className="btn btn-primary btn-sm"
                  onClick={() => this.handleAddMultiple('activity_time_slot')}
                >
                  Add Activity time slot 	
                </button>
              }
            </div>
          </h6>
          {activity_time_slot.filter(x => !x.deleted).map((item, index) => {

            let { time, price } = item

            return (
              <>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label >Title {index + 1} </label>
                    <NormalInput
                      className="form-control"
                      type="text"
                      name="time"
                      value={time}
                      onChange={({ name, value }) => {

                        this.handleMultipleInputs("activity_time_slot", { name, value }, index)

                      }}
                    />
                    {this.validators.message(`/time/${index}/`, time, 'required')}
                  </div>
                  {!is_activity_time_single && 
                    <div className="col-md-5 form-group">
                      <label >Price {index + 1}</label>
                      <NormalInput
                        className="form-control"
                        type="number"
                        name="price"
                        value={price}
                        onChange={(data) => {
                          this.handleMultipleInputs("activity_time_slot", data, index)
                        }}

                      />
                      {is_activity_time_single && 
                        this.validators.message(`/activity_time_price/${index}/`, price, 'required')
                      }
                    </div>
                  }
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteToggle("activity_time_slot", index)}
                      ></i>
                    </div>
                  }
                </div>

              </>
            )

          })}

          <hr />

          <h6 className="mb-15 row">
            <div className="col-sm-6"> Way of reach </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddMultiple('way_of_reach')}
              >

                Add Way of reach 	</button>
            </div>
          </h6>


          {way_of_reach.filter(x => !x.deleted).map((item, index) => {

            let { vehicle_type, description } = item

            return (
              <>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label  >Vechile type</label>
                    <NormalInput
                      className="form-control"
                      name="vehicle_type"
                      type="text"
                      value={vehicle_type}
                      onChange={(data) => {
                        this.handleMultipleInputs("way_of_reach", data, index)
                      }}
                      number={false}
                    />
                    {this.validators.message(`/vehicle_type/${index}/`, vehicle_type, 'required')}
                  </div>
                  <div className="col-md-5 form-group">
                    <label  >Description</label>
                    <NormalInput
                      className="form-control"
                      name="description"
                      type="text"
                      value={description}
                      onChange={(data) => {
                        this.handleMultipleInputs("way_of_reach", data, index)
                      }}
                      number={false}
                    />
                    {this.validators.message(`/description/${index}/`, description, 'required')}
                  </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteToggle("way_of_reach", index)}
                      ></i>
                    </div>
                  }
                </div>
              </>
            )


          })}
          <hr />
          <h6 className="mb-15 align-items-center justify-content-between">
            <div> Others </div>
          </h6>
          <div className="row">
            <div className="col-md-6 form-group">
              <label >Ticket count</label>
              <NormalInput
                className="form-control"
                type="number"
                name="ticket_count"
                value={ticket_count}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('ticket_count', ticket_count, 'required')}
              />
            </div>
            <div className="col-md-6 form-group">
              <label >Base price</label>
              <NormalInput
                className="form-control"
                type="number"
                name="base_price"
                value={base_price}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('base_price', base_price, 'required')}
              />
            </div>
            <div className="col-md-6 form-group">
              <label >Child price</label>
              <NormalInput
                className="form-control"
                type="number"
                name="child_price"
                value={child_price}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('child_price', child_price, 'required')}
              />
            </div>
            <div className="col-md-6 form-group">
              <label >Maximum count a group</label>
              <NormalInput
                className="form-control"
                type="number"
                name="maximum_count_a_group"
                value={maximum_count_a_group}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('maximum_count_a_group', maximum_count_a_group, 'required')}
              />
            </div>
            <div className="col-md-6 form-group">
              <label className="d-flex">
                <span className="mr-auto">Taxi Price</span>
              </label>
              <NormalInput
                className="form-control"
                type="number"
                name="taxi_price"
                value={taxi_price}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('Taxi Price', taxi_price, 'required')}
              />
            </div>
          </div>
          <h6 className="mb-15 align-items-center justify-content-between">
            <div> Meta Details </div>
          </h6>
          <div className="row">
            <div className="col-md-6 form-group">
              <label >Meta Titles</label>
              <NormalInput
                className="form-control"
                name="meta_title"
                type="text"
                value={meta_title}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('meta_title', meta_title, 'required')}
                number={false}
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Meta Keywords</label>
              <NormalInput
                className="form-control"
                name="meta_keywords"
                type="text"
                value={meta_keywords}
                onChange={({ name, value }) => {
                  this.handleInputs({ name: name, value: value })
                }}
                validationError={this.validator.message('meta_keywords', meta_keywords, 'required')}
                number={false}
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Meta Description</label>
              <textarea
                className="form-control"
                name="meta_description"
                onChange={(e) => {
                  this.handleInputs({ name: e.target.name, value: e.target.value })
                }}
                value={meta_description}
              />
              {this.validator.message('meta_description', meta_description, 'required')}
            </div>
          </div>
          

          <h6 className="mb-15 row">
            <div className="col-sm-6"> Transport Option </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddTransportOptions()}
              >Add Transport Option</button>
            </div>
          </h6>
          <label>
                <span className="mr-auto">Is Addon</span>
                <ToggleCheckbox keyName={"transportIsAddon"}
                  value={transport_options.isAddon}
                  onClick={() => this.handleToggleCheckbox("transportIsAddon")}
                />
          </label>

          {transport_options.pick_up_drop_options.filter(x => !x.deleted).map((item, index) => {

            let { title, vehicleTitle,price } = item

            return (
              <>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label  >Pickup/Drop option {index + 1}</label>
                    <ReactSelect
                          isMulti={false}
                          options={pickupDropLists}
                          handleChange={(keyName, data) => {
                            this.handleTransportOptions(index, { name: keyName, value: data })
                          }}
                          keyName="title"
                          value={title}
                          disabled={false}
                        />
                    {this.validators.message(`/title/${index}/`, title, '')}
                  </div>
                  <div className="col-md-6 form-group">
                    <label  >Vehicle Type {index + 1}</label>
                    <ReactSelect
                          isMulti={false}
                          options={vehicleTypeLists}
                          handleChange={(keyName, data) => {
                            this.handleTransportOptions(index, { name: keyName, value: data })
                          }}
                          keyName="vehicleTitle"
                          value={vehicleTitle}
                          disabled={false}
                        />

                    {this.validators.message(`/vehicleTitle/${index}/`, vehicleTitle, '')}
                    
                  </div>
                  <div className="col-md-6 form-group">
                  <label >Price {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="price"
                    type="text"
                    value={price}
                    onChange={({ name, value }) => {
                      this.handleTransportOptions(index, { name: name, value: value })
                    }}
                    number={false}
                  />
                  {this.validators.message(`/price/${index}/`, price, '')}
                </div>
                <div className="col-md-5 form-group">
                  <label>
                    <span className="mr-auto">Is Preselected?</span>
                    <ToggleCheckbox keyName={"transportPreselect"}
                      value={item.transportPreselect}
                      onClick={() => this.handleToggleCheckbox("transportPreselect",index)}
                    />
                  </label>
                </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteTransportOptions(index)}
                      ></i>
                    </div>
                  }
                </div>
              </>
            )


          })}
          <hr />
          <h6 className="mb-15 row">
            <div className="col-sm-6"> Guide Options </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddGuideOptions()}
              >
                Add Guide options
              </button>
            </div>
          </h6>

          <label>
                <span className="mr-auto">Is Addon</span>
                <ToggleCheckbox keyName={"isAddon"}
                  value={guide_details.isAddon}
                  onClick={() => this.handleToggleCheckbox("isAddon")}
                />
          </label>
          {guide_details && guide_details.guides && guide_details.guides.filter(x => !x.deleted).map((item, index) => {

            let { title, price,preselect } = item

            return (
              <>
                <div className="row">
                  <div className="col-md-3 form-group">
                    <label>
                        <span className="mr-auto">Is Preselected?</span>
                        <br/>
                        <br/>
                        <ToggleCheckbox keyName={"preselect"}
                          value={preselect}
                          onClick={() => this.handleToggleCheckbox("preselect",index)}
                        />
                    </label>
                  </div>
                  <div className="col-md-4 form-group">
                    <label> Option {index + 1 } </label>
                    <NormalInput
                      className="form-control"
                      type="text"
                      name="title"
                      value={title}
                      onChange={(data) => {
                        this.handleMultipleInputs("guide_details", data, index)
                      }}
                      number={false}
                    />
                    {this.validators.message(`/guideTitle/${index}/`, title, '')}

                  </div>
                  <div className="col-md-4 form-group">
                    <label >Price{index+1}</label>
                    <NormalInput
                      className="form-control"
                      type="number"
                      name="price"
                      value={price}
                      onChange={(data) => {
                        this.handleMultipleInputs("guide_details", data, index)
                      }}
                    />
                    {this.validators.message(`/guidePrice/${index}/`, price, '')}
                  </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteGuideOptions(index)}
                      ></i>
                    </div>
                  }
                </div>

              </>
            )

          })}
          <hr />
          <h6 className="mb-15 row">
            <div className="col-sm-6"> Stay Option </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddStayOptions()}
              >Add Stay Option</button>
            </div>
          </h6>
          <label>
                <span className="mr-auto">Is Addon</span>
                <ToggleCheckbox keyName={"stayOptionIsAddon"}
                  value={stay_options.isAddon}
                  onClick={() => this.handleToggleCheckbox("stayOptionIsAddon")}
                />
          </label>

          {stay_options && stay_options.stay_options && stay_options.stay_options.filter(x => !x.deleted).map((item, index) => {

            let { stayType, shareType,price,description,capacity,preselect} = item

            return (
              <>
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label  >Room Type</label>
                    <ReactSelect
                          isMulti={false}
                          options={stayTypeLists}
                          handleChange={(keyName, data) => {
                            this.handleStayOptions(index, { name: keyName, value: data })
                          }}
                          keyName="stayType"
                          value={stayType}
                          disabled={false}
                          
                        />
                  </div>
                  <div className="col-md-4 form-group">
                  <label >Description {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="description"
                    type="text"
                    value={description}
                    onChange={({ name, value }) => {
                      this.handleStayOptions(index, { name: name, value: value })
                    }}
                    number={false}
                  />
                </div>
                  <div className="col-md-4 form-group">
                    <label>Shared Type {index + 1}</label>
                    <ReactSelect
                          isMulti={false}
                          options={sharedTypeList}
                          handleChange={(keyName, data) => {
                            this.handleStayOptions(index, { name: keyName, value: data })
                          }}
                          keyName="shareType"
                          value={shareType}
                          disabled={false}
                        />
                  </div>
                  <div className="col-md-4 form-group">
                  <label >Capacity {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="capacity"
                    type="text"
                    value={capacity}
                    onChange={({ name, value }) => {
                      this.handleStayOptions(index, { name: name, value: value })
                    }}
                    number={true}
                  />
                </div>
                  <div className="col-md-4 form-group">
                  <label >Price {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="price"
                    type="text"
                    value={price}
                    onChange={({ name, value }) => {
                      this.handleStayOptions(index, { name: name, value: value })
                    }}
                    number={true}
                  />
                </div>
                <div className="col-md- form-group">
                  <label>
                    <span className="mr-auto">Is Preselected?</span>
                    <br/>
                    <br/>
                    <ToggleCheckbox keyName={"stayOptionPreselect"}
                      value={item.preselect}
                      onClick={() => this.handleToggleCheckbox("stayOptionPreselect",index)}
                    />
                  </label>
                </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteStayOptions(index)}
                      ></i>
                    </div>
                  }
                </div>
              </>
            )


          })}
          <hr />
          <h6 className="mb-15 row">
            <div className="col-sm-6"> Ticket Option </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddTicketOptions()}
              >Add Ticket Option</button>
            </div>
          </h6>
          <label>
                <span className="mr-auto">Is Addon</span>
                <ToggleCheckbox keyName={"ticketOptionIsAddon"}
                  value={ticket_options.isAddon}
                  onClick={() => this.handleToggleCheckbox("ticketOptionIsAddon")}
                />
          </label>

          {ticket_options && ticket_options.options && ticket_options.options.filter(x => !x.deleted).map((item, index) => {

            let {   title, adult_price, child_price, preselect} = item

            return (
              <>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label  >Ticket Type</label>
                    <ReactSelect
                          isMulti={false}
                          options={ticketTypeList}
                          handleChange={(keyName, data) => {
                            this.handleTicketOptions(index, { name: keyName, value: data })
                          }}
                          keyName="title"
                          value={title}
                          disabled={false}
                          
                        />
                  </div>
                  <div className="col-md-6 form-group">
                  <label >Adult price {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="adult_price"
                    type="text"
                    value={adult_price}
                    onChange={({ name, value }) => {
                      this.handleTicketOptions(index, { name: name, value: value })
                    }}
                    number={true}
                  />
                </div>
                 
                  <div className="col-md-6 form-group">
                  <label >Child Price {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="child_price"
                    type="text"
                    value={child_price}
                    onChange={({ name, value }) => {
                      this.handleTicketOptions(index, { name: name, value: value })
                    }}
                    number={true}
                  />
                </div>
                <div className="col-md-5 form-group">
                  <label>
                    <span className="mr-auto">Is Preselected?</span>
                    <br/>
                    <br/>
                    <ToggleCheckbox keyName={"ticketOptionPreselect"}
                      value={item.preselect}
                      onClick={() => this.handleToggleCheckbox("ticketOptionPreselect",index)}
                    />
                  </label>
                </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteTicketOptions(index)}
                      ></i>
                    </div>
                  }
                </div>
              </>
            )


          })}
          <hr />
          <h6 className="mb-15 row">
            <div className="col-sm-6"> Shopping Option </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddShoppingOptions()}
              >Add Shopping Option</button>
            </div>
          </h6>
          <label>
                <span className="mr-auto">Is Addon</span>
                <ToggleCheckbox keyName={"shoppingOptionIsAddon"}
                  value={shopping_options.isAddon}
                  onClick={() => this.handleToggleCheckbox("shoppingOptionIsAddon")}
                />
          </label>

          {shopping_options && shopping_options.options && shopping_options.options.filter(x => !x.deleted).map((item, index) => {

            let {   title, price, preselect} = item

            return (
              <>
                <div className="row">
                  <div className="col-md-4 form-group">
                  <label >Title {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="title"
                    type="text"
                    value={title}
                    onChange={({ name, value }) => {
                      this.handleShoppingOptions(index, { name: name, value: value })
                    }}
                    number={false}
                  />
                </div>
                 
                  <div className="col-md-4 form-group">
                  <label > Price {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="price"
                    type="text"
                    value={price}
                    onChange={({ name, value }) => {
                      this.handleShoppingOptions(index, { name: name, value: value })
                    }}
                    number={true}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <label>
                    <span className="mr-auto">Is Preselected?</span>
                    <br/>
                    <br/>
                    <ToggleCheckbox keyName={"shoppingOptionPreselect"}
                      value={item.preselect}
                      onClick={() => this.handleToggleCheckbox("shoppingOptionPreselect",index)}
                    />
                  </label>
                </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteShoppingOptions(index)}
                      ></i>
                    </div>
                  }
                </div>
              </>
            )


          })}
           <hr />
          <h6 className="mb-15 row">
            <div className="col-sm-6"> Food Option </div>
            <div className="col-sm-6 text-right">
              <button className="btn btn-primary btn-sm"
                onClick={() => this.handleAddFoodOptions()}
              >Add Food Option</button>
            </div>
          </h6>
          <label>
                <span className="mr-auto">Is Addon</span>
                <ToggleCheckbox keyName={"foodOptionIsAddon"}
                  value={food_options.isAddon}
                  onClick={() => this.handleToggleCheckbox("foodOptionIsAddon")}
                />
          </label>

          {food_options && food_options.options && food_options.options.filter(x => !x.deleted).map((item, index) => {

            let {   title, price, preselect} = item

            return (
              <>
                <div className="row">
                  <div className="col-md-4 form-group">
                  <label >Title {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="title"
                    type="text"
                    value={title}
                    onChange={({ name, value }) => {
                      this.handleFoodOptions(index, { name: name, value: value })
                    }}
                    number={false}
                  />
                  {this.validators.message(`/title/${index}/`, title, '')}
                </div>
                 
                  <div className="col-md-4 form-group">
                  <label > Price {index + 1}</label>
                  <NormalInput
                    className="form-control"
                    name="price"
                    type="text"
                    value={price}
                    onChange={({ name, value }) => {
                      this.handleFoodOptions(index, { name: name, value: value })
                    }}
                    number={true}
                  />
                  {this.validators.message(`/price/${index}/`, price, '')}
                </div>
                <div className="col-md-3 form-group">
                  <label>
                    <span className="mr-auto">Is Preselected?</span>
                    <br/>
                    <br/>
                    <ToggleCheckbox keyName={"foodOptionPreselect"}
                      value={item.preselect}
                      onClick={() => this.handleToggleCheckbox("foodOptionPreselect",index)}
                    />
                  </label>
                </div>
                  {index > 0 &&
                    <div className="col-md-1 align-self-center pt-3">
                      <i
                        className="icon-trash text-danger cursor-pointer"
                        onClick={e => this.handleDeleteFoodOptions(index)}
                      ></i>
                    </div>
                  }
                </div>
              </>
            )
          })}
          <hr />
          <button className="btn btn-primary"
            onClick={() => this.handleSubmit()}
            disabled={this.state.disabled}
          >Submit</button>
        </section>

        <ConfirmModal
          keyName="ConfirmModalOpenStatus"
          toggle={this.toggle}
          isOpen={ConfirmModalOpenStatus}
          handleSubmit={() => this.handleDelete()}
          submitText='Delete'
        >
          <h5>
            Are you sure want to Image?
					</h5>
        </ConfirmModal>
      </div>

    )
  }
}

const mapStateToProps = state => {

  return {
    categoryLists: state.dashboard.categoryLists,
    daysList: state.common.daysList,
    activityDropDownLists: state.activity.activityDropDownLists,
    pickupDropLocationLists:state.master.pickupDropLocationLists,
    vehicleTypeLists:state.master.vehicleTypeLists,
    stayTypeLists:state.master.stayTypeLists,
    ticketTypeList:state.master.ticketNationalityTypeLists
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    addImage,
    createActivity,
    addItenaryImage,
    getActivityDetailsById,
    updateActivity,
    createSliderActivityImage,
    getPickupDropListDetails,
    getVehicleTypeDetails,
    getStayTypeDetails,
    getTicketNationalityTypeListDetails
  }, dispatch)
};

let actions = ['getPickupDropListDetails','getVehicleTypeDetails','getStayTypeDetails','getTicketNationalityTypeListDetails']

let component = LoaderWrapper(CreateActivityClass)({ actions })

export const CreateActivity = connect(mapStateToProps, mapDispatchToProps)(component)