import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { getBanners, addImage,updateBanner} from "../../redux/actions/reseller"
import { LoaderWrapper } from "../../HOC"
import { FileUpload, ReactSelect, NormalInput,SliderOverlay} from "../../component/common"

class BannerListClass extends Component {

  state = {
    bannerList:[],
  }
  componentDidMount(){
    this.props.getBanners().then((data) => {

			this.setState({ bannerList: data })
		})
  }

  ImagePreview = (key, event,index) => {
    let files = event.target.files[0]
    let bannerList = Object.assign([], this.state.bannerList)
    let formData = new FormData()
    formData.append("resellerImage", files)
    this.props.addImage(formData).then((data) => {
      bannerList[index].image_url=data.imageUrl
      this.setState({bannerList: bannerList})
    })
  }

  handlePositionOptions =(index, { name, value }) => {
    let bannerList = Object.assign([], this.state.bannerList)
      bannerList[index].position = value;
      this.setState({
        bannerList
      })
  }
  handlePageUrlOptions =(index, { name, value }) => {
    let bannerList = Object.assign([], this.state.bannerList)
      bannerList[index].page_url = value;
      this.setState({
        bannerList
      })
  }

  handleSubmit() {
    let bannerList= this.state.bannerList
    
    this.props.updateBanner(bannerList).then((data) => {
      this.setState({
        bannerList:data
      })
    }).catch((ex) => {
      this.setState({disabled:false})
    })
    
  }


  render() {
    let tempFile = ""
    let {bannerList,imageTypes} = this.state
    return (
      <div>
        {/* Header */}
        <div className="sair-pg-header">
          <h4 className="sair-pg-title align-self-center">
            <span className="pg-title-icon">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" stroke="currentColor" className="feather feather-align-left">
                  <line x1="17" y1="10" x2="3" y2="10"></line>
                  <line x1="21" y1="6" x2="3" y2="6"></line>
                  <line x1="21" y1="14" x2="3" y2="14"></line>
                  <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>
              </span>
            </span>Banner
          </h4>
        </div>
        {/* Content */}
        <section className="sair-sec-wrapper">
            <div>Banner</div>
            <h6 className="mb-15 row">
              <div className="col-sm-6">Images</div>
              <div className="col-sm-6 text-right">
              </div>
            </h6>
            {bannerList && bannerList.length > 0 ? bannerList.map((item, index) => {
              let {image_url, position,page_url} = item
              return (
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label ><b> Product Image </b> Index: {index}</label>
                    <FileUpload
                      fileUpload={this.fileUpload}
                      ImagePreview={(e) => this.ImagePreview("product image", e,index)}
                      filename={image_url}
                      tempFile={tempFile}
                    />
                    <img src={image_url} style={{ width: "200px", marginTop: "15px" }} />
                  </div>
                  <div className="col-md-3 form-group">
                    <label >Reseller banner position</label>
                    <NormalInput
                        className="form-control"
                        name="position"
                        type="text"
                        value={position}
                        onChange={({ name, value }) => {
                          this.handlePositionOptions(index, { name: name, value: value })
                        }}
                      />
                  </div>
                  <div className="col-md-3 form-group">
                    <label >Reseller page url</label>
                      <NormalInput
                        className="form-control"
                        name="pageUrl"
                        type="text"
                        value={page_url}
                        onChange={({ name, value }) => {
                          this.handlePageUrlOptions(index, { name: name, value: value })
                        }}
                      />
                  </div>
                </div>
              )
            })
            :""}
        </section>
        <hr />
          <button className="btn btn-primary"
            onClick={() => this.handleSubmit()}
            disabled={this.state.disabled}
          >Submit</button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    bannerList: state.reseller.bannerList // data from reducer to brandList
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getBanners,
    addImage,
    updateBanner
  }, dispatch)
};

let actions = []

let component = LoaderWrapper(BannerListClass)({ actions })

export const BannerList = connect(mapStateToProps, mapDispatchToProps)(component)
