import React, { Component } from 'react';

import { Badge, ConfirmModal } from '../../component/common';

import { LoaderWrapper } from "../../HOC"

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";

import { getBlogListDetails, deleteBlog } from '../../redux/actions/blogs';
import { TableWrapper } from '../../component/common';
import { getSNo } from '../../services/helperFunctions';

export class BlogListClass extends Component {

    state = {
        headerDetails: [{
            label: 'S.No',
        }, {
            label: 'Title',
        }, {
            label: 'Image',
        }, {
            label: 'Description',
        }, {
            label: 'Status',
        }, {
            label: 'Action',
        }],
        isOpen: false,
        currentId: null,
    }

    handleNavigation = (id) => {

        if (id) {

            this.props.history.push(`/blog/edit/${id}`)

        } else {

            this.props.history.push('/blog/add')

        }

    }

    requestStatus = (status) => {

        if (status === 1) {
            return <Badge
                type={1}
                label={"Active"}
            />
        } else if (status === 2) {
            return <Badge
                type={2}
                label={"Deactive"}
            />
        }

    }

    toggle = item => {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
    }

    handleDeleteToggle = (id) => {

        this.toggle('isOpen')

        this.setState({
            currentId: id,
        })

    }

    handleSubmit = () => {

        let { currentId } = this.state;

        this.props.deleteBlog(currentId).then(() => {

            this.props.getBlogListDetails().then(() => {

                this.toggle('isOpen')

            })

        })

    }

    render() {

        let { headerDetails, isOpen } = this.state;

        let { blogList = {} } = this.props;

        let { list = [], pageMeta = {} } = blogList;

        let IsEmpty = list.length > 0;

        return (
            <div>
                <div className="sair-pg-header">
                    <h4 className="sair-pg-title align-self-center">
                        <span className="pg-title-icon">
                            <span className="feather-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" className="feather feather-align-left">
                                    <line x1="17" y1="10" x2="3" y2="10"></line>
                                    <line x1="21" y1="6" x2="3" y2="6"></line>
                                    <line x1="21" y1="14" x2="3" y2="14"></line>
                                    <line x1="17" y1="18" x2="3" y2="18"></line>
                                </svg>
                            </span>
                        </span>Blog
					</h4>
                    <div className="col-auto ">
                        <button className="btn btn-primary btn-sm"
                            onClick={() => this.handleNavigation()}
                        >Add Blog</button>
                    </div>
                </div>
                <div className="sair-sec-wrapper">
                    <div className="row">
                        <div className="col-sm">
                            <div className="table-wrap mt-3">
                                <TableWrapper
                                    headerDetails={headerDetails}
                                    pageMeta={pageMeta}
                                    isEmpty={IsEmpty}
                                    queryHandler={this.props.getBlogListDetails}
                                >
                                    {list.map((item, index) => {

                                        let {
                                            title,
                                            blog_imageurl,
                                            description,
                                            status,
                                            id,
                                        } = item;

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {getSNo(pageMeta, index)}
                                                </td>
                                                <td>
                                                    {title}
                                                </td>
                                                <td>
                                                    <img src={blog_imageurl} style={{ width: '100px' }} />
                                                </td>
                                                <td>
                                                    {description}
                                                </td>
                                                <td>
                                                    {this.requestStatus(status)}
                                                </td>
                                                <td>
                                                    <a className="mr-25"
                                                        onClick={e => this.handleNavigation(id)}
                                                    >
                                                        <i className="icon-pencil  text-primary cursor-pointer"
                                                        ></i>
                                                    </a>
                                                    <a ><i
                                                        className="icon-trash txt-danger text-primary cursor-pointer"
                                                        onClick={e => this.handleDeleteToggle(id)}
                                                    ></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )

                                    })}
                                </TableWrapper>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    keyName="isOpen"
                    toggle={this.toggle}
                    isOpen={isOpen}
                    handleSubmit={() => this.handleSubmit()}
                    submitText='Delete'
                >
                    <h5>
                        Are you sure want to delete Blog?
					</h5>
                </ConfirmModal>
            </div>
        )

    }

}

const mapStateToProps = state => {

    return {
        blogList: state.blogs.blogList
    }


};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getBlogListDetails,
        deleteBlog,
    }, dispatch)
};


let actions = ['getBlogListDetails']

let component = LoaderWrapper(BlogListClass)({ actions })

export const BlogList = connect(mapStateToProps, mapDispatchToProps)(component)