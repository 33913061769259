import { dashboardType } from "../../services/actionType"

const initialState = {
	cityLists: {
		list: [
			{
				id: 1,
				seriesNo: "Test001",
				cityName: 'Chennai',
				status: 1

			},
			{
				id: 2,
				seriesNo: "Test002",
				cityName: 'Salem',
				status: 2

			},
			{
				id: 3,
				seriesNo: "Test003",
				cityName: 'Kerala',
				status: 2

			}
		],
		pageMeta: {
			chunk: 10,
			page: 1,
			total: 1,
			totalPages: 1
		}

	},
	categoryLists: {
		list: [
			{
				id: 1,
				title: 'Chennai',
				status: 1

			},
			{
				id: 2,
				title: 'Salem',
				status: 2

			},
			{
				id: 3,
				title: 'Kerala',
				status: 2

			}
		],
		pageMeta: {
			chunk: 10,
			page: 1,
			total: 1,
			totalPages: 1
		}

	},
	languageLists: {
		list: [
			{
				id: 1,
				name: 'Tamil',
				status: 1

			},
			{
				id: 2,
				name: 'English',
				status: 2

			},
			{
				id: 3,
				name: 'Hindi',
				status: 2

			}
		],
		pageMeta: {
			chunk: 10,
			page: 1,
			total: 1,
			totalPages: 1
		}

	},
	driverDetailLists: {
		list: [
			{
				id: 1,
				name: 'Driver1',
				number: 9876543210,
				experience: 2,
				licenseId: 12345678,
				homeTown: "Chennai",
				description: "test description",
				status: 1

			},
			{
				id: 2,
				name: 'Driver2',
				number: 9876543211,
				experience: 1,
				licenseId: 12345679,
				homeTown: "Salem",
				description: "test description",
				status: 1

			},
			{
				id: 3,
				name: 'Driver3',
				number: 9876543212,
				experience: 2,
				licenseId: 12345678,
				homeTown: "Kerala",
				description: "test description",
				status: 1

			}
		],
		pageMeta: {
			chunk: 10,
			page: 1,
			total: 1,
			totalPages: 1
		}

	},
	experienceGroupLists: {
		list: [
			{
				id: 1,
				name: 'Experience1',
				status: 1

			},
			{
				id: 2,
				name: 'Experience2',
				status: 1

			},
			{
				id: 3,
				name: 'Experience3',
				status: 1

			}
		],
		pageMeta: {
			chunk: 10,
			page: 1,
			total: 1,
			totalPages: 1
		}

	},
	physicalRestrictionLists: {
		list: [
			{
				id: 1,
				name: 'Name1',
				status: 1

			},
			{
				id: 2,
				name: 'Name2',
				status: 1

			},
			{
				id: 3,
				name: 'Name3',
				status: 1

			}
		],
		pageMeta: {
			chunk: 10,
			page: 1,
			total: 1,
			totalPages: 1
		}

	}
}


export default (state = Object.assign({}, initialState), { type, payload }) => {
	switch (type) {

		case dashboardType.cityListDetails:
			return {
				...state,
				cityLists: payload
			}
		default:
			return state

	}
}