import { vendor } from "../../services/apiVariables"
import { vendorType } from "../../services/actionType"
import { common } from "../../services/actionType"
import { addQuery } from "../../services/helperFunctions"
import { history } from "../../helpers"
import { Toast } from "../../services/toast"

export const getVendorListDetails = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: common.updateLoader, payload: true })

		addQuery(query, vendor.getVendorListDetails)

		api(vendor.getVendorListDetails).then(({ data, message }) => {

			resolve(true)

			dispatch({ type: vendorType.updateVendorListDetails, payload: data })

			dispatch({ type: common.updateLoader, payload: false })


		}).catch(({ message }) => {

			Toast({ type: 'error', message })

		})

	})

}

export const createVendor = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {

		dispatch({ type: vendorType.createVendor, payload: body })

		api({ ...vendor.createVendor, body }).then(({ data, err, message }) => {

			if (err) {

				resolve(Toast({ type: 'error', message: err }))

			} else {

				resolve(Toast({ type: 'success', message: message }))

			}


		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))


		})

	})
}

export const addDocument = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		dispatch({ type: vendorType.addDocument, payload: body })

		api({ ...vendor.addDocument, body }).then(({ data, message }) => {

			Toast({ type: 'success', message: message })

			resolve(data)

		}).catch(({ message }) => {

			reject(Toast({ type: 'error', message }))


		})

	})
}

export const editVendor = (id, body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		vendor.editVendor.addParams = id;

		api({ ...vendor.editVendor, body }).then(({ data, message }) => {

			dispatch({ type: vendor.editVendor, payload: data })

			resolve(Toast({ type: 'success', message: data }))

		}).catch(({ message, error }) => {

			dispatch({ type: common.updateLoader, payload: false })

			if (error) {

				error.map((item, index) => {

					Toast({ type: 'error', message: item })

				})

			} else {

				Toast({ type: 'error', message: message })

			}

			reject(message)
		});

	})
}

export const getSingleVendor = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		vendor.getSingleVendor.addParams = id;

		api(vendor.getSingleVendor).then(({ data, message }) => {

			dispatch({ type: vendorType.updateSingleVendorDetails, payload: data })

			resolve(data)

			// Toast({ type: 'success', message: data })

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})

}

export const removeVendorDocument = (id) => (dispatch, getState, { api }) => {
	console.log(id ,"api")
	return new Promise((resolve, reject) => {

		vendor.removeVendorDocument.addParams = id;

		api(vendor.removeVendorDocument).then(({ data, message }) => {

			// dispatch({ type: vendorType.updateSingleVendorDetails, payload: data })

			resolve(data)

			// Toast({ type: 'success', message: data })

		}).catch(({ message }) => {

			// dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})

}


export const deleteVendor = (id) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {

		vendor.deleteVendor.addParams = id;

		dispatch({ type: common.updateLoader, payload: true })

		api(vendor.deleteVendor).then(({ data, message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			resolve(data)

			Toast({ type: 'success', message: message })

		}).catch(({ message }) => {

			dispatch({ type: common.updateLoader, payload: false })

			Toast({ type: 'error', message: message })

			reject(message)
		});

	})

}

