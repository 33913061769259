import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class NormalModal extends React.Component {


    render() {

        var {
            children,
            isOpen,
            toggle,
            className = '',
            verticalCenter = true,
            footerContent = '',
            headerClass = '',
            bodyClass = '',
            footerClass = '',
            toggleKey,
            loading = false,
            renderHeader = ''
        } = this.props




        return (

            <Modal isOpen={isOpen} toggle={toggle} className={`${verticalCenter && 'modal-dialog-centered'} ${className}`} >

                <ModalHeader
                    className={headerClass}
                    toggle={toggle}
                >
                    {renderHeader ? renderHeader() : ''}
                    {/* <span className="icon-close font-sm" onClick={e => toggle(toggleKey)} /> */}
                </ModalHeader>
                <div className="position-relative">
                    <ModalBody
                        className={bodyClass}
                    >
                        {children}



                    </ModalBody>

                    {footerContent ? <ModalFooter
                        className={footerClass}
                    >
                        {footerContent()}
                    </ModalFooter> : ''}

                    {loading ? <div class="upload-loader">
                        <div class="position-relative w-100 h-100">
                            <div class="loader-circle"></div>
                        </div>
                    </div> : ''}

                </div>

            </Modal>

        )
    }
}

