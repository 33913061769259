import React, { Component } from 'react'
import { UpdateResetPassword, VerifyPasswordChangeToken } from '../../redux/actions/common'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import SimpleReactValidator from 'simple-react-validator';

export class ResetPasswordClass extends Component {

    state = {
        token: "",
        password: "",
        confirmpassword: "",
        confirm: false
    }

    componentWillMount() {

        this.validator = new SimpleReactValidator({
            element: message => <span className="error-message font-md">{message}</span>,
            autoForceUpdate: this,
            validators: {
                confirmPassword: { // name the rule
                    message: "password doesn't maching", // give a message that will display when there is an error. :attribute will be replaced by the name you supply in calling it.
                    rule: function (val, params, validator) { // return true if it is succeeds and false it if fails validation. the testRegex method is available to give back a true/false for the regex and given value
                        // check that it is a valid IP address and is not blacklisted
                        return params && val == params
                    }
                }
            }
        });

    }

    componentDidMount() {
        const { token } = this.props.match.params
        const { VerifyPasswordChangeToken } = this.props

        // VerifyPasswordChangeToken({ token }).then(() => {
        //     this.setState({
        //         token
        //     })
        // })
    }

    hangleInputs = ({ target: { name, value } }) => {

        this.setState({
            [name]: value
        })
    }

    updatePassword = (e) => {

        e.preventDefault()

        const { password, token } = this.state

        e.preventDefault()

        if (this.validator.allValid()) {

            const { UpdateResetPassword } = this.props
            UpdateResetPassword({ token, password }).then(() => {
                this.props.history.push('/auth/login')
            })

        } else {
            this.validator.showMessages()
        }
    }

    render() {
        const { confirm, password, confirmpassword } = this.state

        return (
            <>
                <h3 class="font-bold">Reset Password</h3>
                <p class="mb-5">Setup a new password</p>
                <form class="text-left">
                    <div class="form-group mb-4">
                        <label class="font-bold">New Password</label>
                        <input
                            name="password"
                            class="form-control"
                            type="password"
                            value={password}
                            onChange={(e) => this.hangleInputs(e)}
                        />
                        {this.validator.message('passwordNew', password, 'min:6|required')}
                    </div>

                    <div class="form-group mb-4">
                        <label class="font-bold">Confirm New Password</label>
                        <input
                            class="form-control "
                            name="confirmpassword"
                            type="password"
                            onChange={(e) => this.hangleInputs(e)}
                            value={confirmpassword}
                        />
                        {this.validator.message('confirmPassword', confirmpassword, `confirmPassword:${password}|required`)}
                    </div>

                    <div class="pt-4">
                        <button
                            class="btn btn-warning btn-block"
                            disabled={!password || !confirmpassword}
                            onClick={(e) => this.updatePassword(e)}
                        >
                            Save</button>
                    </div>
                </form>
                <div class="my-4 text-center">
                    <Link to='/auth/login' class="font-bold text-body">Back to home</Link>
                </div>
            </>

        )
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        UpdateResetPassword,
        VerifyPasswordChangeToken,
    }, dispatch)
}

export const ResetPassword = connect(null, mapDispatchToProps)(ResetPasswordClass)