import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { TableWrapper, Badge, Filters, SliderOverlay, ConfirmModal } from "../../component/common/"
import { CityModal } from "../../component/master"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { createVendor, getVendorListDetails, deleteVendor } from "../../redux/actions/vendor"
import { getSNo } from "../../services/helperFunctions"

import { LoaderWrapper } from "../../HOC"

class vendorClass extends Component {

	state = {
		headerDetails: [
			{
				label: 'S.No'
			},
			{
				label: 'Business Details',
			},
			{
				label: 'Company name',
			},
			{
				label: 'Email'
			},
			{
				label: 'Phone No'
			},
			{
				label: 'Status',
			},
			{
				label: 'Action'
			},

		],
		filter: [

			{
				label: 'Active',
				value: 1
			},
			{
				label: 'Deactive',
				value: 2
			}

		],
		vendorLists: {},
		selectedFilter: 'Active',
		isOpen: false,
		currentVendor: null,
	}

	componentWillMount() {

	}

	componentDidMount() {

		this.setState({
			vendorLists: this.props.vendorLists
		})

	}

	requestStatus = (status) => {
		if (status === 0) {
			return <Badge
				type={2}
				label={"Deactive"}
			/>
		}

		else if (status === 1) {
			return <Badge
				type={1}
				label={"Active"}
			/>
		}

	}

	handleFilter = (query, value, name) => {

		this.props.getVendorListDetails(query)

	}

	handleNavigation = (id) => {

		let { history } = this.props

		if (id) {

			history.push(`/vendor/edit/${id}`)

		} else {

			history.push('/vendor/create')

		}

	}

	toggle = item => {
		this.setState(prevState => ({
			[item]: !prevState[item]
		}));
	}

	handleDeleteToggle = (id) => {

		this.setState({
			currentVendor: id,
		})

		this.toggle('isOpen')

	}

	handleDelete = () => {

		let { currentVendor } = this.state;

		this.props.deleteVendor(currentVendor).then(() => {

			this.props.getVendorListDetails({ pageNo: 1 })

			this.toggle('isOpen')

		})

	}

	render() {

		let { headerDetails, filter, selectedFilter, isOpen } = this.state
		let { vendorLists } = this.props
		let IsEmpty = vendorLists.list.length > 0

		return (
			<div>
				<div className="sair-pg-header">
					<h4 className="sair-pg-title align-self-center">
						<span className="pg-title-icon">
							<span className="feather-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
									fill="none" stroke="currentColor" className="feather feather-align-left">
									<line x1="17" y1="10" x2="3" y2="10"></line>
									<line x1="21" y1="6" x2="3" y2="6"></line>
									<line x1="21" y1="14" x2="3" y2="14"></line>
									<line x1="17" y1="18" x2="3" y2="18"></line>
								</svg>
							</span>
						</span>Vendor
					</h4>
					<div className="col-auto ">
						<button className="btn btn-primary btn-sm"
							onClick={() => this.handleNavigation()}
						>Add Vendor</button>
					</div>
				</div>
				<section className="sair-sec-wrapper">
					<div className="row">
						<div className="col-sm">
							<div className="row no-gutters">
								<h5 className="sair-sec-title align-self-center">Vendor List</h5>
								<div className="ml-auto d-flex align-items-center">
									{/* <UncontrolledDropdown setActiveFromChild className="filter-dropdown">
										<DropdownToggle tag="a" className="custom-caret dropdown-toggle-link">
											Filter by: {selectedFilter}
										</DropdownToggle>
										<DropdownMenu>
											{filter.map(({ label, value }, index) => {
												return (
													<DropdownItem
														onClick={e => this.handleFilter(label, value, "filter")}
														key={index}
													>
														{label}
													</DropdownItem>

												)
											})}
										</DropdownMenu>
									</UncontrolledDropdown> */}

									<div className="search-box position-relative  ml-3">

										{/* <Filters
											queryHandler={(query) => this.handleFilter(query)}
											isDropDownFilter={false}
										/> */}
									</div>
								</div>
							</div>
							<div className="table-wrap mt-3">
								<TableWrapper
									headerDetails={headerDetails}
									isEmpty={IsEmpty}
									pageMeta={vendorLists.pageMeta}
									queryHandler={(query) => this.handleFilter(query)}


								>
									{vendorLists.list.map((item, index) => {
										let { id, business_details, company_name, email, phone_no, status } = item

										return (
											<tr
												key={index}  >
												<td>{getSNo(vendorLists.pageMeta, index)}</td>
												<td>{business_details}</td>
												<td>{company_name}</td>
												<td>{email}</td>
												<td>{phone_no}</td>
												<td>{this.requestStatus(status)}</td>
												<td>
													<a className="mr-25"
														onClick={e => this.handleNavigation(id)}
													>
														<i className="icon-pencil  text-primary cursor-pointer"
														></i>
													</a>
													<a ><i
														className="icon-trash txt-danger text-primary cursor-pointer"
														onClick={e => this.handleDeleteToggle(id)}
													></i>
													</a>
												</td>

											</tr>

										)

									})}


								</TableWrapper>
							</div>
						</div>
					</div>
				</section>
				<ConfirmModal
					keyName="isOpen"
					toggle={this.toggle}
					isOpen={isOpen}
					handleSubmit={() => this.handleDelete()}
					submitText='Delete'
				>
					<h5>
						Are you sure want to delete Vendor?
					</h5>
				</ConfirmModal>
			</div>
		)
	}
}

const mapStateToProps = state => {

	return {
		vendorLists: state.vendor.vendorLists
	}


};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		getVendorListDetails,
		createVendor,
		deleteVendor
	}, dispatch)
};


let actions = ['getVendorListDetails']

let component = LoaderWrapper(vendorClass)({ actions })

export const Vendor = connect(mapStateToProps, mapDispatchToProps)(component)

