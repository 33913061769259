import { Reseller } from '../../services/apiVariables';
import { resellerType } from '../../services/actionType';
import { Toast } from "../../services/toast"
import { common } from "../../services/actionType"
import { addQuery, removeQuery } from "../../services/helperFunctions"

export const addImage = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: resellerType.addImage, payload: body })
		api({ ...Reseller.addImage, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const getCategories = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		addQuery(query, Reseller.categoryList)
		// let api=Reseller.categoryList.api+"?sortBy=position&sortOrder=desc&withPosition=true";
		api(Reseller.categoryList).then(({ data, message }) => {
			dispatch({ type: resellerType.getCategories, payload: data })
			resolve(true)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})

	})
}

export const getCategoryDetailsById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.categoryDetails.addParams = id;
		api(Reseller.categoryDetails).then(({ data }) => {
			dispatch({ type: resellerType.getCategoryDetailsById, payload: data })
			resolve(data)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
export const editCategoryDetailsById = (id, body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.editCategory.addParams = id;
		api({ ...Reseller.editCategory, body }).then(({ data }) => {
			dispatch({ type: resellerType.editCategory, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const createCategory = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: resellerType.createCategory, payload: body })
		api({ ...Reseller.addCategory, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const deleteCategoryById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.deleteCategory.addParams = id;
		api(Reseller.deleteCategory).then(({ data }) => {
			dispatch({ type: resellerType.deleteCategory, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
//Category Level2

export const getCategoriesLevelTwo = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		addQuery(query, Reseller.categoryLevelTwoList)
		api(Reseller.categoryLevelTwoList).then(({ data, message }) => {
			dispatch({ type: resellerType.getLevelTwoCategories, payload: data })
			resolve(true)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})

	})
}

export const getCategoryLevelTwoDetailsById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.categoryLevelTwoDetails.addParams = id;
		api(Reseller.categoryLevelTwoDetails).then(({ data }) => {
			dispatch({ type: resellerType.getLevelTwoCategoryDetailsById, payload: data })
			resolve(data)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
export const editCategoryLevelTwoDetailsById = (id, body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.editCategoryLevelTwo.addParams = id;
		api({ ...Reseller.editCategoryLevelTwo, body }).then(({ data }) => {
			dispatch({ type: resellerType.editLevelTwoCategory, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const createCategoryLevelTwo = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: resellerType.createLevelTwoCategory, payload: body })
		api({ ...Reseller.addCategoryLevelTwo, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const deleteCategoryLevelTwoById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.deleteCategoryLevelTwo.addParams = id;
		api(Reseller.deleteCategoryLevelTwo).then(({ data }) => {
			dispatch({ type: resellerType.deleteLevelTwoCategory, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
//Category Level3

export const getCategoriesLevelThree = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		addQuery(query, Reseller.categoryLevelThreeList)
		api(Reseller.categoryLevelThreeList).then(({ data, message }) => {
			dispatch({ type: resellerType.getLevelThreeCategories, payload: data })
			resolve(true)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})

	})
}

export const getCategoryLevelThreeDetailsById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.categoryLevelThreeDetails.addParams = id;
		api(Reseller.categoryLevelThreeDetails).then(({ data }) => {
			dispatch({ type: resellerType.getLevelThreeCategoryDetailsById, payload: data })
			resolve(data)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
export const editCategoryLevelThreeDetailsById = (id, body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.editCategoryLevelThree.addParams = id;
		api({ ...Reseller.editCategoryLevelThree, body }).then(({ data }) => {
			dispatch({ type: resellerType.editLevelThreeCategory, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const createCategoryLevelThree = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: resellerType.createLevelThreeCategory, payload: body })
		api({ ...Reseller.addCategoryLevelThree, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const deleteCategoryLevelThreeById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.deleteCategoryLevelThree.addParams = id;
		api(Reseller.deleteCategoryLevelThree).then(({ data }) => {
			dispatch({ type: resellerType.deleteLevelThreeCategory, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
export const getBrands = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		removeQuery(Reseller.brandList);
		addQuery(query, Reseller.brandList);
		api(Reseller.brandList).then(({ data, message }) => {
			dispatch({ type: resellerType.getBrands, payload: data })
			resolve(true)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const getBrandNames = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, Reseller.brandNames);
		api(Reseller.brandNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getBrandNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getSellerNames = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.sellerNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getSellerNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getProductNames = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.productNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getProductsNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getCategoryNames = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.categoryNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getCategoryNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getCategoryLevelTwoNames = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.categoryLevelTwoNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getLevelTwoCategoryNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getCategoryLevelThreeNames = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.categoryLevelThreeNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getLevelThreeCategoryNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getBrandDetailsById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.brandDetails.addParams = id;
		api(Reseller.brandDetails).then(({ data }) => {
			dispatch({ type: resellerType.getBrandDetailsById, payload: data })
			resolve(data)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const editBrandDetailsById = (id, body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.editBrand.addParams = id;
		api({ ...Reseller.editBrand, body }).then(({ data }) => {
			dispatch({ type: resellerType.editBrand, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const createBrand = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: resellerType.createBrand, payload: body })
		api({ ...Reseller.addBrand, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const deleteBrandById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.deleteBrand.addParams = id;
		api(Reseller.deleteBrand).then(({ data }) => {
			dispatch({ type: resellerType.deleteBrand, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

// Banner
export const getBanners = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.bannerList).then(({ data, message }) => {
			dispatch({ type: resellerType.getbannerList, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const updateBanner = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api({ ...Reseller.updateBanner, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			dispatch({ type: resellerType.getbannerList, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
// Banner
export const getCustomerBanners = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.customerBannerList).then(({ data, message }) => {
			dispatch({ type: resellerType.getcustomerBannerList, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const updateCustomerBanner = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api({ ...Reseller.UpdateCustomerBanner, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			dispatch({ type: resellerType.getcustomerBannerList, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const getProducts = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		removeQuery(Reseller.productList);
		addQuery(query, Reseller.productList)
		api(Reseller.productList).then(({ data, message }) => {
			dispatch({ type: resellerType.getProducts, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getProductsBySearch = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		removeQuery(Reseller.productListBySearch);
		addQuery(query, Reseller.productListBySearch)
		api(Reseller.productListBySearch).then(({ data, message }) => {
			dispatch({ type: resellerType.getProducts, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getProductDetailsById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.productDetails.addParams = id;
		api(Reseller.productDetails).then(({ data }) => {
			dispatch({ type: resellerType.getProductDetailsById, payload: data })
			resolve(data)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const editProductDetailsById = (id, body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.editProduct.addParams = id;
		api({ ...Reseller.editProduct, body }).then(({ data }) => {
			dispatch({ type: resellerType.editProduct, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const createProduct = (body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: resellerType.createProduct, payload: body })
		api({ ...Reseller.addProduct, body }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}

export const deleteProductById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.deleteProduct.addParams = id;
		api(Reseller.deleteProduct).then(({ data }) => {
			dispatch({ type: resellerType.deleteProduct, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}

export const getSellerList = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, Reseller.sellerList)
		api(Reseller.sellerList).then(({ data, message }) => {
			dispatch({ type: resellerType.sellerList, payload: data })
			resolve(true)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}
export const getSellerDetailsById = (id) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.sellerDetails.addParams = id;
		api(Reseller.sellerDetails).then(({ data }) => {
			//dispatch({ type: resellerType.getSellerDetailsById, payload: data })
			resolve(data)
			//dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			//dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
export const editSellerById = (id, body) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: common.updateLoader, payload: true })
		Reseller.editSeller.addParams = id;
		api({ ...Reseller.editSeller, body }).then(({ data }) => {
			dispatch({ type: resellerType.editSeller, payload: data })
			resolve(true)
			dispatch({ type: common.updateLoader, payload: false })
		}).catch(({ message }) => {
			reject(message)
			dispatch({ type: common.updateLoader, payload: false })
		})
	})
}
export const getCommissionListRequest = (query) => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, Reseller.commissionList)
		api(Reseller.commissionList).then(({ data, message }) => {
			dispatch({ type: resellerType.commissionList, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const getWareHouseList = (query) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		addQuery(query, Reseller.wareHouseList)
		api(Reseller.wareHouseList).then(({ data, message }) => {

			dispatch({ type: resellerType.getWareHouseList, payload: data })
			resolve(true)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})

	})
}

export const createWareHouse = (body) => (dispatch, getState, { api }) => {

	return new Promise((resolve, reject) => {
		api({ ...Reseller.addWareHouse, body }).then(({ data, message }) => {
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const getWareHouseNames = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.wareHouseNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getWareHouseNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}


export const getGtmWareHouseNames = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api(Reseller.wareGtmHouseNames).then(({ data, message }) => {
			dispatch({ type: resellerType.getGtmWareHouseNames, payload: data })
			resolve(data)
		}).catch(({ message }) => {
			Toast({ type: 'error', message })
		})
	})
}

export const createCatalogue = () => (dispatch, getState, { api }) => {
	return new Promise((resolve, reject) => {
		api({ ...Reseller.createCatalogue }).then(({ data, message }) => {
			Toast({ type: 'success', message: message })
			resolve(data)
		}).catch(({ message }) => {
			reject(Toast({ type: 'error', message }))
		})
	})
}